import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type DocumentViewerSidebarClassKey = 'root';
export type DocumentViewerSidebarClasses = Record<
  DocumentViewerSidebarClassKey,
  string
>;

export const documentViewerSidebarClasses: DocumentViewerSidebarClasses =
  generateUtilityClasses('DocumentViewerSidebar', ['root']);
