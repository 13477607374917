import { Types } from '@allganize/alli-sdk-interfaces';
import { IcGenerate } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css, keyframes } from '@emotion/react';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

const ellipsisAnimation = keyframes`
  0% {
    content: '.';
  }
  25% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '.';
  }
`;

interface GeneratingStateProps extends React.HTMLAttributes<HTMLDivElement> {
  state: Types.SearchContextStep;
}

export const GeneratingState = forwardRef<HTMLDivElement, GeneratingStateProps>(
  ({ state, ...other }, ref) => {
    const theme = useTheme();

    return (
      <div data-testid="generating-state-message" {...other} ref={ref}>
        <IcGenerate
          color="primary"
          css={css`
            vertical-align: middle;
          `}
        />

        <Text
          variant="body12"
          component="span"
          css={css`
            color: ${theme.palette.text.secondary};

            &:after {
              display: inline-block;
              content: '...';
              animation: ${ellipsisAnimation} 1s infinite;
              min-width: 12px;
            }
          `}
        >
          <FormattedMessage
            id={`SearchContextStep.${state}`}
            defaultMessage={state || 'Preparing'}
            description={`SearchContextStep.${state}`}
          />
        </Text>
      </div>
    );
  },
);
