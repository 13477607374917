import { DraftTextSizePlugin } from '@allganize/draft-text-format-plugin';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { HeadingProps } from 'react-markdown/lib/ast-to-react';
import { markdownClasses } from '../markdown/markdown-classes';

export const H1 = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & HeadingProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, level, ...other } = props;

  return (
    <div
      css={[
        DraftTextSizePlugin.sizes['header-one'],
        css`
          margin: 16px 0;
        `,
      ]}
      {...other}
      ref={ref}
      className={clsx(markdownClasses.h1, other.className)}
    />
  );
});

export const H2 = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & HeadingProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, level, ...other } = props;

  return (
    <div
      css={[
        DraftTextSizePlugin.sizes['header-two'],
        css`
          margin: 16px 0;
        `,
      ]}
      {...other}
      ref={ref}
      className={clsx(markdownClasses.h2, other.className)}
    />
  );
});

export const H3 = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & HeadingProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, level, ...other } = props;

  return (
    <div
      css={[
        DraftTextSizePlugin.sizes['header-three'],
        css`
          margin: 16px 0;
        `,
      ]}
      {...other}
      ref={ref}
      className={clsx(markdownClasses.h3, other.className)}
    />
  );
});

export const H4 = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & HeadingProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, level, ...other } = props;

  return (
    <div
      css={[
        DraftTextSizePlugin.sizes['header-four'],
        css`
          margin: 16px 0;
        `,
      ]}
      {...other}
      ref={ref}
      className={clsx(markdownClasses.h4, other.className)}
    />
  );
});

export const H5 = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & HeadingProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, level, ...other } = props;

  return (
    <div
      css={[
        DraftTextSizePlugin.sizes['header-five'],
        css`
          margin: 16px 0;
        `,
      ]}
      {...other}
      ref={ref}
      className={clsx(markdownClasses.h5, other.className)}
    />
  );
});

export const H6 = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & HeadingProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, level, ...other } = props;

  return (
    <div
      css={[
        DraftTextSizePlugin.sizes['header-six'],
        css`
          margin: 16px 0;
        `,
      ]}
      {...other}
      ref={ref}
      className={clsx(markdownClasses.h6, other.className)}
    />
  );
});
