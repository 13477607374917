import { useId } from '@allganize/hooks';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef, useCallback, useMemo } from 'react';
import { Clock, ClockProps } from '../clock';
import { useMeridiemMode } from '../internals/hooks/date-helpers-hooks';
import { useClockReferenceDate } from '../internals/hooks/use-clock-reference-date';
import { PickerSelectionState } from '../internals/hooks/use-picker';
import { useLocaleText, useNow, useUtils } from '../internals/hooks/use-utils';
import { useControlledValueWithTimezone } from '../internals/hooks/use-value-with-timezone';
import { useViews } from '../internals/hooks/use-views';
import { TimeViewWithMeridiem } from '../internals/models';
import {
  convertValueToMeridiem,
  createIsAfterIgnoreDatePart,
} from '../internals/utils/time-utils';
import { singleItemValueManager } from '../internals/utils/value-managers';
import { TimeView } from '../models';
import { PickerViewRoot } from '../picker-view-root';
import { PickersArrowSwitcher } from '../pickers-arrow-switcher';
import { getHourNumbers, getMinutesNumbers } from './clock-numbers';
import { timeClockClasses } from './time-clock-classes';
import { TimeClockProps } from './time-clock-type-map';

const TIME_CLOCK_DEFAULT_VIEWS: TimeView[] = ['hours', 'minutes'];

interface TimeClockType {
  <TView extends TimeViewWithMeridiem = TimeView>(
    props: React.PropsWithoutRef<TimeClockProps<TView>> &
      React.RefAttributes<HTMLDivElement>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

// @ts-expect-error overridable component
export const TimeClock: TimeClockType = forwardRef((props, ref) => {
  const utils = useUtils<Date>();
  const {
    ampm = utils.is12HourCycleInCurrentLocale(),
    ampmInClock = false,
    autoFocus,
    classes,
    slots,
    slotProps,
    value: valueProp,
    defaultValue,
    referenceDate: referenceDateProp,
    disableIgnoringDatePartForTimeValidation = false,
    maxTime,
    minTime,
    disableFuture,
    disablePast,
    minutesStep = 1,
    shouldDisableTime,
    showViewSwitcher,
    onChange,
    view: inView,
    views = TIME_CLOCK_DEFAULT_VIEWS,
    openTo,
    onViewChange,
    focusedView,
    onFocusedViewChange,
    className,
    disabled,
    readOnly,
    timezone: timezoneProp,
    ...other
  } = props;

  const { value, handleValueChange, timezone } = useControlledValueWithTimezone(
    {
      name: 'TimeClock',
      timezone: timezoneProp,
      value: valueProp,
      defaultValue,
      onChange,
      valueManager: singleItemValueManager,
    },
  );

  const valueOrReferenceDate = useClockReferenceDate({
    value,
    referenceDate: referenceDateProp,
    utils,
    props,
    timezone,
  });

  const localeText = useLocaleText<Date>();
  const now = useNow<Date>(timezone);

  const { view, setView, previousView, nextView, setValueAndGoToNextView } =
    useViews({
      view: inView,
      views,
      openTo,
      onViewChange,
      onChange: handleValueChange,
      focusedView,
      onFocusedViewChange,
    });

  const { meridiemMode, handleMeridiemChange } = useMeridiemMode(
    valueOrReferenceDate,
    ampm,
    setValueAndGoToNextView,
  );

  const isTimeDisabled = useCallback(
    (rawValue: number, viewType: TimeView) => {
      const isAfter = createIsAfterIgnoreDatePart(
        disableIgnoringDatePartForTimeValidation,
        utils,
      );
      const shouldCheckPastEnd =
        viewType === 'hours' ||
        (viewType === 'minutes' && views.includes('seconds'));

      const containsValidTime = ({
        start,
        end,
      }: {
        start: Date;
        end: Date;
      }) => {
        if (minTime && isAfter(minTime, end)) {
          return false;
        }

        if (maxTime && isAfter(start, maxTime)) {
          return false;
        }

        if (disableFuture && isAfter(start, now)) {
          return false;
        }

        if (disablePast && isAfter(now, shouldCheckPastEnd ? end : start)) {
          return false;
        }

        return true;
      };

      const isValidValue = (timeValue: number, step = 1) => {
        if (timeValue % step !== 0) {
          return false;
        }

        if (shouldDisableTime) {
          switch (viewType) {
            case 'hours':
              return !shouldDisableTime(
                utils.setHours(valueOrReferenceDate, timeValue),
                'hours',
              );
            case 'minutes':
              return !shouldDisableTime(
                utils.setMinutes(valueOrReferenceDate, timeValue),
                'minutes',
              );

            case 'seconds':
              return !shouldDisableTime(
                utils.setSeconds(valueOrReferenceDate, timeValue),
                'seconds',
              );

            default:
              return false;
          }
        }

        return true;
      };

      switch (viewType) {
        case 'hours': {
          const valueWithMeridiem = convertValueToMeridiem(
            rawValue,
            meridiemMode,
            ampm,
          );
          const dateWithNewHours = utils.setHours(
            valueOrReferenceDate,
            valueWithMeridiem,
          );
          const start = utils.setSeconds(
            utils.setMinutes(dateWithNewHours, 0),
            0,
          );
          const end = utils.setSeconds(
            utils.setMinutes(dateWithNewHours, 59),
            59,
          );

          return (
            !containsValidTime({ start, end }) ||
            !isValidValue(valueWithMeridiem)
          );
        }

        case 'minutes': {
          const dateWithNewMinutes = utils.setMinutes(
            valueOrReferenceDate,
            rawValue,
          );
          const start = utils.setSeconds(dateWithNewMinutes, 0);
          const end = utils.setSeconds(dateWithNewMinutes, 59);

          return (
            !containsValidTime({ start, end }) ||
            !isValidValue(rawValue, minutesStep)
          );
        }

        case 'seconds': {
          const dateWithNewSeconds = utils.setSeconds(
            valueOrReferenceDate,
            rawValue,
          );
          const start = dateWithNewSeconds;
          const end = dateWithNewSeconds;

          return !containsValidTime({ start, end }) || !isValidValue(rawValue);
        }

        default:
          throw new Error('not supported');
      }
    },
    [
      ampm,
      valueOrReferenceDate,
      disableIgnoringDatePartForTimeValidation,
      maxTime,
      meridiemMode,
      minTime,
      minutesStep,
      shouldDisableTime,
      utils,
      disableFuture,
      disablePast,
      now,
      views,
    ],
  );

  const selectedId = useId();

  const viewProps = useMemo<
    Pick<ClockProps, 'onChange' | 'viewValue' | 'children'>
  >(() => {
    switch (view) {
      case 'hours': {
        const handleHoursChange = (
          hourValue: number,
          isFinish?: PickerSelectionState,
        ) => {
          const valueWithMeridiem = convertValueToMeridiem(
            hourValue,
            meridiemMode,
            ampm,
          );
          setValueAndGoToNextView(
            utils.setHours(valueOrReferenceDate, valueWithMeridiem),
            isFinish,
          );
        };

        return {
          onChange: handleHoursChange,
          viewValue: utils.getHours(valueOrReferenceDate),
          children: getHourNumbers({
            value,
            utils,
            ampm,
            onChange: handleHoursChange,
            getClockNumberText: localeText.hoursClockNumberText,
            isDisabled: hourValue =>
              disabled || isTimeDisabled(hourValue, 'hours'),
            selectedId,
          }),
        };
      }

      case 'minutes': {
        const minutesValue = utils.getMinutes(valueOrReferenceDate);
        const handleMinutesChange = (
          minuteValue: number,
          isFinish?: PickerSelectionState,
        ) => {
          setValueAndGoToNextView(
            utils.setMinutes(valueOrReferenceDate, minuteValue),
            isFinish,
          );
        };

        return {
          viewValue: minutesValue,
          onChange: handleMinutesChange,
          children: getMinutesNumbers({
            utils,
            value: minutesValue,
            onChange: handleMinutesChange,
            getClockNumberText: localeText.minutesClockNumberText,
            isDisabled: minuteValue =>
              disabled || isTimeDisabled(minuteValue, 'minutes'),
            selectedId,
          }),
        };
      }

      case 'seconds': {
        const secondsValue = utils.getSeconds(valueOrReferenceDate);
        const handleSecondsChange = (
          secondValue: number,
          isFinish?: PickerSelectionState,
        ) => {
          setValueAndGoToNextView(
            utils.setSeconds(valueOrReferenceDate, secondValue),
            isFinish,
          );
        };

        return {
          viewValue: secondsValue,
          onChange: handleSecondsChange,
          children: getMinutesNumbers({
            utils,
            value: secondsValue,
            onChange: handleSecondsChange,
            getClockNumberText: localeText.secondsClockNumberText,
            isDisabled: secondValue =>
              disabled || isTimeDisabled(secondValue, 'seconds'),
            selectedId,
          }),
        };
      }

      default:
        throw new Error('You must provide the type for ClockView');
    }
  }, [
    view,
    utils,
    value,
    ampm,
    localeText.hoursClockNumberText,
    localeText.minutesClockNumberText,
    localeText.secondsClockNumberText,
    meridiemMode,
    setValueAndGoToNextView,
    valueOrReferenceDate,
    isTimeDisabled,
    selectedId,
    disabled,
  ]);

  return (
    <PickerViewRoot
      css={css`
        display: flex;
        flex-direction: column;
        position: relative;
      `}
      {...other}
      ref={ref}
      className={clsx(timeClockClasses.root, classes?.root, className)}
    >
      <Clock
        autoFocus={autoFocus ?? !!focusedView}
        ampmInClock={ampmInClock && views.includes('hours')}
        value={value}
        type={view as TimeView}
        ampm={ampm}
        minutesStep={minutesStep}
        isTimeDisabled={isTimeDisabled}
        meridiemMode={meridiemMode}
        handleMeridiemChange={handleMeridiemChange}
        selectedId={selectedId}
        disabled={disabled}
        readOnly={readOnly}
        {...viewProps}
      />

      {showViewSwitcher && (
        <PickersArrowSwitcher
          css={css`
            position: absolute;
            right: 12px;
            top: 15px;
          `}
          className={clsx(
            timeClockClasses.arrowSwitcher,
            classes?.arrowSwitcher,
          )}
          slots={slots}
          slotProps={slotProps}
          onGoToPrevious={() => setView(previousView!)}
          isPreviousDisabled={!previousView}
          previousLabel={localeText.openPreviousView}
          onGoToNext={() => setView(nextView!)}
          isNextDisabled={!nextView}
          nextLabel={localeText.openNextView}
        />
      )}
    </PickerViewRoot>
  );
});
