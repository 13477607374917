import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  Routes,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  useRoutes,
} from 'react-router-dom';

import { environment } from './environments/environment';

Sentry.init({
  dsn: environment.sentry.dsn,
  enabled: environment.sentry.enabled,
  environment: import.meta.env.MODE,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: ['INVALID_PERMISSION', 'INVALID_TOKEN'],
});

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
export const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);
export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
