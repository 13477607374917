export const dark = {
  50: 'hsla(225, 14%, 98%, 1)',
  100: 'hsla(225, 14%, 95%, 1)',
  200: 'hsla(225, 14%, 85%, 1)',
  300: 'hsla(225, 14%, 75%, 1)',
  400: 'hsla(225, 14%, 65%, 1)',
  500: 'hsla(225, 14%, 55%, 1)',
  600: 'hsla(225, 14%, 45%, 1)',
  700: 'hsla(225, 14%, 35%, 1)',
  800: 'hsla(225, 14%, 25%, 1)',
  900: 'hsla(225, 14%, 15%, 1)',
};
