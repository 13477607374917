import { AgentQueryTypes } from '@allganize/alli-interfaces';
import { Chip } from '@allganize/ui-chip';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { FormHelperText, FormLabel } from '@allganize/ui-form';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { IcClose, IcUploadFile } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import { acceptFiles, acceptImageFiles } from './accept-files';
import {
  KnowledgeBase,
  maxFileSize,
  UploadFileContext,
} from '../contexts/upload-file-context';
import { OutputContext } from '../contexts/output-context';
import { UploadBlockModal } from '../upload-block-dialog/upload-block-modal';

interface DocumentUploadInputProps {
  id: string | number;
  label: string;
  inputType: AgentQueryTypes.SingleActionInputType;
  required?: boolean;
  onChange: (...event: any[]) => void;
}

export const DocumentUploadInput: FunctionComponent<
  DocumentUploadInputProps
> = ({ id, label, inputType, required, onChange, ...others }) => {
  const theme = useTheme();
  const { loading } = useContext(OutputContext);
  const {
    knowledgeBases,
    uploadBlock,
    uploadFile,
    deleteFile,
    setError,
    closeUploadBlockDialog,
  } = useContext(UploadFileContext);
  const knowledgeBase = knowledgeBases[id] as KnowledgeBase | undefined;
  const status = useMemo(() => {
    if (
      knowledgeBase &&
      knowledgeBase.__typename !== 'GoogleDocsKnowledgeBase'
    ) {
      if (
        knowledgeBase.loading ||
        knowledgeBase.processState === AgentQueryTypes.ProcessStateEnum.INIT ||
        knowledgeBase.processState === AgentQueryTypes.ProcessStateEnum.PARSING
      ) {
        return 'loading';
      }

      if (
        knowledgeBase.processState ===
          AgentQueryTypes.ProcessStateEnum.COMPLETED ||
        knowledgeBase.processState ===
          AgentQueryTypes.ProcessStateEnum.RETRYING ||
        knowledgeBase.processState ===
          AgentQueryTypes.ProcessStateEnum.PARSING_FAIL
      ) {
        return 'finished';
      }
    }
    return null;
  }, [knowledgeBase]);

  useEffect(() => {
    if (
      knowledgeBase &&
      knowledgeBase.__typename !== 'GoogleDocsKnowledgeBase' &&
      knowledgeBase.processState === AgentQueryTypes.ProcessStateEnum.COMPLETED
    ) {
      onChange({ value: knowledgeBase.id });
    }
  }, [knowledgeBase, onChange]);

  return (
    <div {...others}>
      <FormLabel
        required={required}
        css={css`
          display: block;
        `}
      >
        {label}
      </FormLabel>
      {status === 'finished' ? (
        <Chip
          label={knowledgeBase?.title}
          disabled={loading}
          onDelete={() => {
            deleteFile(id);
            onChange();
          }}
          deleteIcon={<IcClose tabIndex={0} />}
        />
      ) : (
        <Fragment>
          <Dropzone
            multiple={false}
            accept={
              inputType === AgentQueryTypes.SingleActionInputType.IMAGE_BASIC ||
              inputType === AgentQueryTypes.SingleActionInputType.IMAGE_ADVANCED
                ? acceptImageFiles
                : acceptFiles
            }
            onDropRejected={rejected => {
              if (rejected.length > 0) {
                setError(
                  id,
                  <FormattedMessage
                    id="single-action.document-upload-input.invalid-file-error-message"
                    defaultMessage="Invalid files rejected: {files}"
                    description="single action document upload input file upload invalid file rejected error text"
                    values={{
                      files: rejected.map(f => f.file.name).join(', '),
                    }}
                  />,
                );
              }
            }}
            onDrop={accepted => {
              if (accepted.length > 0) {
                uploadFile(id, accepted[0]);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: ${theme.radius.sm}px;
                  padding: 10px;
                  background-color: ${theme.palette.backgroundInteractive
                    .graySubtleAlpha.default};
                  cursor: pointer;

                  &:hover {
                    background-color: ${theme.palette.backgroundInteractive
                      .graySubtleAlpha.hover};
                  }

                  &:active {
                    background-color: ${theme.palette.backgroundInteractive
                      .graySubtleAlpha.pressed};
                  }
                `}
                {...getRootProps()}
              >
                <div
                  css={css`
                    height: 20px;
                    margin-right: 8px;
                  `}
                >
                  {status === 'loading' ? (
                    <CircularProgress size="xs" />
                  ) : (
                    <IcUploadFile />
                  )}
                </div>
                <Text variant="subtitle14">
                  {status === 'loading' ? (
                    <FormattedMessage
                      id="single-action.document-upload-input.dropzone-uploading-text"
                      defaultMessage="Uploading File, Please Wait..."
                      description="single action document upload input dropzone uploading text"
                    />
                  ) : (
                    <FormattedMessage
                      id="single-action.document-upload-input.dropzone-description-text"
                      defaultMessage="Drag and drop files here or <highlight>Browse files</highlight>"
                      description="single action document upload input dropzone description text"
                      values={{
                        highlight: (chunks: ReadonlyArray<React.ReactNode>) => (
                          <span
                            css={css`
                              text-decoration: underline;
                              cursor: pointer;
                            `}
                          >
                            {chunks}
                          </span>
                        ),
                      }}
                    />
                  )}
                </Text>
                <input
                  {...getInputProps({
                    name: 'files',
                  })}
                />
              </div>
            )}
          </Dropzone>
          <FormHelperText>
            <FormattedMessage
              id="single-action.document-upload-input.dropzone-max-file-size"
              defaultMessage="Max file size: {maxSize}"
              description="single action document upload input dropzone max file size help text"
              values={{
                maxSize: maxFileSize,
              }}
            />
          </FormHelperText>
        </Fragment>
      )}
      {knowledgeBase?.error && (
        <FormHelperText error>{knowledgeBase.error}</FormHelperText>
      )}
      <UploadBlockModal
        open={uploadBlock !== null}
        onClose={closeUploadBlockDialog}
        docLimit={uploadBlock?.docLimit ?? 0}
        docNum={uploadBlock?.docNum ?? 0}
      />
    </div>
  );
};
