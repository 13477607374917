import {
  inputLabelClasses as muiInputLabelClasses,
  InputLabelClassKey as MuiInputLabelClassKey,
} from '@mui/material/InputLabel';
import { omit } from 'lodash-es';

export type InputLabelClassKey = Exclude<
  MuiInputLabelClassKey,
  'sizeSmall' | 'shrink' | 'animated' | 'filled' | 'outlined' | 'standard'
>;
export type InputLabelClasses = Record<InputLabelClassKey, string>;

export const inputLabelClasses: InputLabelClasses = omit(muiInputLabelClasses, [
  'sizeSmall',
  'shrink',
  'animated',
  'filled',
  'outlined',
  'standard',
]);
