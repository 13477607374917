import { OverridableComponent } from '@allganize/types';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import MuiPaper from '@mui/material/Paper';
import { alpha, getOverlayAlpha } from '@mui/material/styles';
import { forwardRef } from 'react';
import { PaperTypeMap } from './paper-type-map';

// @ts-expect-error overridable component
export const Paper: OverridableComponent<PaperTypeMap> = forwardRef(
  (props, ref) => {
    const theme = useTheme();
    const {
      elevation = 1,
      square = false,
      variant = 'elevation',
      ...other
    } = props;

    return (
      <MuiPaper
        data-testid="paper"
        css={[
          css`
            background-color: ${theme.palette.background.paper};
            color: ${theme.palette.text.primary};
            transition: ${theme.transitions.create('box-shadow')};
          `,
          !square &&
            css`
              border-radius: ${theme.radius.sm}px;
            `,
          variant === 'outlined' &&
            css`
              border: 1px solid ${theme.palette.divider};
            `,
          variant === 'elevation' &&
            css`
              box-shadow: ${theme.elevations[elevation]};

              ${theme.palette.mode === 'dark' &&
              css`
                background-image: linear-gradient(
                  ${alpha(
                    theme.palette.common.white,
                    Number.parseFloat(getOverlayAlpha(elevation)),
                  )},
                  ${alpha(
                    theme.palette.common.white,
                    Number.parseFloat(getOverlayAlpha(elevation)),
                  )}
                );
              `}
            `,
        ]}
        elevation={variant === 'outlined' ? 0 : elevation}
        square={square}
        variant={variant}
        {...other}
        ref={ref}
      />
    );
  },
);
