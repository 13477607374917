import {
  BaseDatePickerProps as MuiBaseDatePickerProps,
  useDatePickerDefaultizedProps as useMuiDatePickerDefaultizedProps,
} from '@mui/x-date-pickers/DatePicker/shared';
import {
  DateCalendarSlotsComponent,
  DateCalendarSlotsComponentsProps,
} from '../date-calendar';
import {
  DatePickerToolbar,
  DatePickerToolbarProps,
  ExportedDatePickerToolbarProps,
} from '../date-picker-toolbar';
import { DateViewRendererProps } from '../date-view-renderers';
import { PickerViewRendererLookup } from '../internals/hooks/use-picker';
import { BaseDateValidationProps, DefaultizedProps } from '../internals/models';
import { UncapitalizeObjectKeys } from '../internals/utils/slots-migration';
import { LocalizedComponent } from '../locales/utils/pickers-locale-text-api';
import { DateView } from '../models';

export interface BaseDatePickerSlotsComponent
  extends DateCalendarSlotsComponent {
  /**
   * Custom component for the toolbar rendered above the views.
   * @default DatePickerToolbar
   */
  Toolbar?: React.JSXElementConstructor<DatePickerToolbarProps>;
}

export interface BaseDatePickerSlotsComponentsProps
  extends DateCalendarSlotsComponentsProps {
  toolbar?: ExportedDatePickerToolbarProps;
}

export interface BaseDatePickerProps
  extends Omit<
    MuiBaseDatePickerProps<Date>,
    | 'components'
    | 'componentsProps'
    | 'slots'
    | 'slotProps'
    | 'sx'
    | 'viewRenderers'
  > {
  /**
   * Overridable component slots.
   * @default {}
   */
  slots?: UncapitalizeObjectKeys<BaseDatePickerSlotsComponent>;
  /**
   * The props used for each component slot.
   * @default {}
   */
  slotProps?: BaseDatePickerSlotsComponentsProps;
  /**
   * Define custom view renderers for each section.
   * If `null`, the section will only have field editing.
   * If `undefined`, internally defined view will be the used.
   */
  viewRenderers?: Partial<
    PickerViewRendererLookup<
      Date | null,
      DateView,
      DateViewRendererProps<DateView>,
      // eslint-disable-next-line @typescript-eslint/ban-types
      {}
    >
  >;
}

type UseDatePickerDefaultizedProps<Props extends BaseDatePickerProps> =
  LocalizedComponent<
    DefaultizedProps<
      Props,
      'views' | 'openTo' | keyof BaseDateValidationProps<Date>
    >
  >;

export const useDatePickerDefaultizedProps = <
  Props extends BaseDatePickerProps,
>(
  props: Props,
  name: string,
): UseDatePickerDefaultizedProps<Props> =>
  // @ts-expect-error internal component
  useMuiDatePickerDefaultizedProps<Date, Props>(
    {
      ...props,
      slots: {
        toolbar: DatePickerToolbar,
        ...props.slots,
      },
    },
    name,
  );
