import { LOCALES, Locale, defaultLocale } from '../i18n/locales';

const getInitialLocale = (locale: string = defaultLocale): Locale => {
  const formattedLocale = locale.split('-')[0];
  if (LOCALES.includes(formattedLocale as any)) {
    return formattedLocale as Locale;
  }
  return defaultLocale;
};

interface Nav extends Navigator {
  userLanguage?: string;
  browserLanguage?: string;
  systemLanguage?: string;
}

export const getBrowserLocale = (nav: Nav): Locale =>
  getInitialLocale(
    nav.languages?.[0] ||
      nav.language ||
      nav.userLanguage ||
      nav.browserLanguage ||
      nav.systemLanguage,
  );
