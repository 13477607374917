import { css } from '@emotion/react';
import { useTheme } from '@allganize/ui-theme';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useRef } from 'react';
import { highlightLayerClasses } from './highlight-layer-classes';
import { HighlightLayerProps } from './highlight-layer-type-map';
import { draw } from './highlight-layer-utils';

export const HighlightLayer: FunctionComponent<HighlightLayerProps> = ({
  classes,
  highlights,
  width,
  height,
  blendMode,
  ...others
}) => {
  const theme = useTheme();
  const highlightColor = theme.palette.background.textHighlight;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    draw(context, highlights, highlightColor);
  }, [canvasRef, highlights, width, height, highlightColor]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      className={clsx(
        highlightLayerClasses.root,
        classes?.root,
        others.className,
      )}
      css={[
        css`
          pointer-events: none;
        `,
        blendMode &&
          css`
            mix-blend-mode: multiply;
          `,
      ]}
      {...others}
    />
  );
};
