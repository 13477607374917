import { useConversationList } from '@allganize/alli-sdk/hooks/use-conversation-list';
import { useAssistantCampaign } from '@allganize/alli-sdk/project/use-assistant-campaign';
import { useEventCallback } from '@allganize/hooks';
import { IconButton, iconButtonClasses } from '@allganize/ui-button';
import { IcClose } from '@allganize/ui-icons';
import {
  ListItemButton,
  ListItemButtonProps,
  listItemButtonClasses,
} from '@allganize/ui-list';
import styled from '@emotion/styled';
import { generateUtilityClass } from '@mui/material';
import { MouseEvent, forwardRef, useMemo, useState } from 'react';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { Link, useMatch } from 'react-router-dom';
import { analytics } from '../../analytics';
import { DeleteConversationModal } from '../modal/delete-conversation-modal';

export const listItemSize = 54;

const itemTimestampClassName = generateUtilityClass(
  'history-item-list',
  'item-timestamp',
);

const catchOnClickEvent = (e: MouseEvent) => {
  // catch click event to prevent unnecessary navigation
  e.preventDefault();
  e.stopPropagation();
};

const ItemWrapper = styled(ListItemButton)`
  border-radius: ${props => props.theme.radius.sm}px;
  display: grid;
  grid-template-areas: 'type lastChatTime' 'title title';
  grid-template-columns: 1fr auto;
  gap: 4px;
  min-height: ${listItemSize}px;

  &:hover {
    grid-template-areas: 'type deleteBtn' 'title deleteBtn';

    > .${iconButtonClasses.root} {
      display: inline-flex;
    }

    > .${itemTimestampClassName} {
      display: none;
    }
  }

  &.${listItemButtonClasses.selected} {
    &,
    &:hover {
      background-color: ${props =>
        props.theme.palette.unstable_background.white};
      cursor: auto;
    }
  }
`;

const ItemType = styled.div`
  grid-area: type;
  ${props => props.theme.typography.subtitle12};
  color: ${props => props.theme.palette.foreground.secondary};
`;

const ItemTimestamp = styled.div`
  grid-area: lastChatTime;
  ${props => props.theme.typography.subtitle12};
  color: ${props => props.theme.palette.foreground.placeholder};
`;

const ItemTitle = styled.div`
  grid-area: title;
  ${props => props.theme.typography.body14};
  color: ${props => props.theme.palette.foreground.default};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DeleteBtn = styled(IconButton)`
  grid-area: deleteBtn;
  display: none;
  margin-right: -4px;
`;

type ConversationsQuery = ReturnType<typeof useConversationList>['data'];
type ConversationNode = NonNullable<
  NonNullable<
    NonNullable<ConversationsQuery['conversations']>['edges'][number]
  >['node']
>;

interface HistoryListItemProps extends ListItemButtonProps {
  data: Pick<
    ConversationNode,
    'id' | 'lastChatTime' | 'llmApp' | 'title' | 'shareUrl'
  >;
}

const HistoryListItemBase = forwardRef<HTMLDivElement, HistoryListItemProps>(
  ({ data, selected, ...others }: HistoryListItemProps, ref) => {
    const { lastChatTime, llmApp } = data;
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const { isAssistantCampaign } = useAssistantCampaign();
    const conversationType = useMemo(
      () => (isAssistantCampaign(llmApp?.campaign?.id) ? 'chat' : 'app'),
      [llmApp?.campaign?.id, isAssistantCampaign],
    );
    const handleOpenModal = useEventCallback((e: MouseEvent) => {
      analytics.track('lnb::conversation-history__delete-button.click', {
        conversationId: data.id,
        appId: data.llmApp?.id,
        conversationType,
      });
      catchOnClickEvent(e);
      setOpenDeleteModal(true);
    });
    const handleCloseModal = useEventCallback((e: MouseEvent) => {
      catchOnClickEvent(e);
      setOpenDeleteModal(false);
    });
    const handleClick = useEventCallback(() => {
      analytics.track('lnb::conversation-history.click', {
        appId: data.llmApp?.id,
        conversationType,
        conversationId: data.id,
      });
    });
    const handleConfirm = useEventCallback(() => {
      analytics.track('lnb::conversation-history__popup-delete-button.click', {
        appId: data.llmApp?.id,
        conversationType,
        conversationId: data.id,
      });
    });

    return (
      <ItemWrapper
        {...others}
        selected={selected}
        ref={ref}
        onClick={handleClick}
      >
        <ItemType>
          {conversationType === 'app' ? (
            <FormattedMessage
              id="AppMarket.LNB.MenuList.History.Type.App"
              defaultMessage="App"
              description="app market lnb history list app type text"
            />
          ) : (
            <FormattedMessage
              id="AppMarket.LNB.MenuList.History.Type.Chat"
              defaultMessage="Chat"
              description="app market lnb history list chat type text"
            />
          )}
        </ItemType>
        {lastChatTime && (
          <ItemTimestamp className={itemTimestampClassName}>
            <FormattedRelativeTime
              value={(lastChatTime - Date.now()) / 1000}
              numeric="auto"
              updateIntervalInSeconds={60}
            />
          </ItemTimestamp>
        )}
        <ItemTitle>{data.title}</ItemTitle>
        <DeleteBtn shape="rounded" variant="ghost" onClick={handleOpenModal}>
          <IcClose />
        </DeleteBtn>
        <DeleteConversationModal
          open={openDeleteModal}
          conversationId={data.id}
          onClose={handleCloseModal}
          onClick={catchOnClickEvent}
          onConfirm={handleConfirm}
        />
      </ItemWrapper>
    );
  },
);

export const HistoryListItem = ({
  selected,
  ...others
}: HistoryListItemProps) => {
  const match = useMatch(`/conversations/${others.data.id}`);

  if (match) {
    return <HistoryListItemBase selected {...others} />;
  }

  return (
    <Link to={`/conversations/${others.data.id}`}>
      <HistoryListItemBase {...others} />
    </Link>
  );
};
