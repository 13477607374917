import { useTheme } from '@allganize/ui-theme';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import { PickersSlideTransition as MuiPickersSlideTransition } from '@mui/x-date-pickers/DateCalendar/PickersSlideTransition';
import { FunctionComponent } from 'react';
import { PickersSlideTransitionProps } from './pickers-slide-transition-type-map';

export const PickersSlideTransition: FunctionComponent<
  PickersSlideTransitionProps
> = props => {
  const theme = useTheme();
  const muiTheme = useMuiTheme();

  return (
    <ThemeProvider theme={{ ...muiTheme, transitions: theme.transitions }}>
      <MuiPickersSlideTransition {...props} />
    </ThemeProvider>
  );
};
