import { useState } from 'react';
import { useEventCallback } from '../use-event-callback/use-event-callback';
import { ContentRect, UseMeasureOptions, useMeasure } from './use-measure';

export const useMeasureState = <T extends HTMLElement>(
  options: UseMeasureOptions,
) => {
  const { onResize } = options;

  const [contentRect, setContentRect] = useState<ContentRect>({});

  const handleResize = useEventCallback((cr: ContentRect) => {
    setContentRect(cr);
    onResize?.(cr);
  });

  const internal = useMeasure<T>({ ...options, onResize: handleResize });
  return { ...internal, contentRect };
};
