import { DraftLinkContext } from '@allganize/draft-link-plugin';
import clsx from 'clsx';
import { FunctionComponent, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import { components } from '../components/components';
import { remarkGfm } from '../plugins/remark-gfm';
import { markdownClasses } from './markdown-classes';

interface MarkdownProps extends ReactMarkdownOptions {}

export const Markdown: FunctionComponent<MarkdownProps> = props => {
  const draftLink = useContext(DraftLinkContext);

  const linkTarget = (href: string) => {
    return draftLink.getLinkTarget(href);
  };

  return (
    <ReactMarkdown
      components={components}
      disallowedElements={[
        'script',
        'link',
        'meta',
        'title',
        'style',
        'input',
        'textarea',
      ]}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]}
      linkTarget={linkTarget}
      {...props}
      className={clsx(markdownClasses.root, props.className)}
    />
  );
};
