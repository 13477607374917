import createCache, { Options } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { memoize } from 'lodash-es';
import { FunctionComponent } from 'react';

memoize.Cache = WeakMap;
export const memoizedCreateCache = memoize(createCache);

interface EmotionCacheProviderProps {
  options: Options;
  children?: React.ReactNode;
}

export const EmotionCacheProvider: FunctionComponent<
  EmotionCacheProviderProps
> = ({ options, children }) => {
  const cache = memoizedCreateCache(options);

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};
