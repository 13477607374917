import {
  menuItemClasses as muiMenuItemClasses,
  MenuItemClassKey as MuiMenuItemClassKey,
} from '@mui/material/MenuItem';
import { omit } from 'lodash-es';

export type MenuItemClassKey = Exclude<
  MuiMenuItemClassKey,
  'dense' | 'divider' | 'gutters'
>;
export type MenuItemClasses = Record<MenuItemClassKey, string>;

export const menuItemClasses: MenuItemClasses = omit(muiMenuItemClasses, [
  'dense',
  'divider',
  'gutters',
]);
