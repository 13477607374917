import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type ChatContentRowClassKey =
  | 'root'
  | 'avatar'
  | 'content'
  | 'horizontalMargin'
  | 'alignLeft'
  | 'alignRight';

export type ChatContentRowClasses = Record<ChatContentRowClassKey, string>;

export const chatContentRowClasses: ChatContentRowClasses =
  generateUtilityClasses('ChatContentRow', [
    'root',
    'avatar',
    'content',
    'horizontalMargin',
    'alignLeft',
    'alignRight',
  ]);
