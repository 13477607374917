import { FormControlState } from '@mui/material/FormControl';

export const formControlState = <
  K extends keyof FormControlState,
  T extends Partial<Pick<FormControlState, K>>,
>({
  props,
  states,
  muiFormControl,
}: {
  props: T;
  states: K[];
  muiFormControl?: FormControlState;
}) => {
  return states.reduce<Partial<T>>((acc, state) => {
    let result: Partial<T> = {
      ...acc,
      [state]: props[state],
    };

    if (muiFormControl) {
      if (typeof props[state] === 'undefined') {
        result = {
          ...result,
          [state]: muiFormControl[state],
        };
      }
    }

    return result;
  }, {});
};
