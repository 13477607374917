import { Divider } from '@allganize/ui-divider';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { ReactMarkdownProps } from 'react-markdown/lib/ast-to-react';

export const HorizontalRule = forwardRef<
  HTMLHRElement,
  React.HTMLAttributes<HTMLHRElement> & ReactMarkdownProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, ...other } = props;

  return (
    <Divider
      css={css`
        margin: 16px 0;
      `}
      {...other}
      ref={ref}
    />
  );
});
