import { alpha } from '@mui/material/styles';
import { parseToHsl } from 'polished';

export const grayToAlpha = (color: string) => {
  const hsl = parseToHsl(color);

  if (hsl.hue !== 0 || hsl.saturation !== 0) {
    throw new Error(
      `grayToAlpha() expects a gray color, but received ${color}.`,
    );
  }

  const lightness = Math.round(hsl.lightness * 100) / 100;
  return alpha('#000', 1 - lightness);
};
