import {
  getListItemIconUtilityClass,
  listItemIconClasses as muiListItemIconClasses,
  ListItemIconClassKey as MuiListItemIconClassKey,
} from '@mui/material/ListItemIcon';

export type ListItemIconClassKey = MuiListItemIconClassKey | 'disabled';
export type ListItemIconClasses = Record<ListItemIconClassKey, string>;

export const listItemIconClasses: ListItemIconClasses = {
  ...muiListItemIconClasses,
  disabled: getListItemIconUtilityClass('disabled'),
};
