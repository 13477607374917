import { Button } from '@allganize/ui-button';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { PickersToolbarText } from '../pickers-toolbar-text';
import { pickersToolbarButtonClasses } from './pickers-toolbar-button-classes';
import { PickersToolbarButtonProps } from './pickers-toolbar-button-type-map';

export const PickersToolbarButton = forwardRef<
  HTMLButtonElement,
  PickersToolbarButtonProps
>((props, ref) => {
  const {
    align,
    selected,
    textClassName,
    value,
    variant,
    classes,
    width,
    ...other
  } = props;

  return (
    <Button
      data-testid="pickers-toolbar-button"
      css={[
        css`
          text-transform: none;
        `,
        width &&
          css`
            width: ${width}px;
          `,
      ]}
      variant="ghost"
      {...other}
      ref={ref}
      className={clsx(
        pickersToolbarButtonClasses.root,
        classes?.root,
        other.className,
      )}
    >
      <PickersToolbarText
        align={align}
        className={textClassName}
        variant={variant}
        value={value}
        selected={selected}
      />
    </Button>
  );
});
