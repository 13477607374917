import { IconButton as UiIconButton } from '@allganize/ui-button';
import { IcClose } from '@allganize/ui-icons';
import { InputAdornment } from '@allganize/ui-input';
import { css } from '@emotion/react';
import { useSlotProps } from '@mui/base/utils';
import { Fragment } from 'react';
import {
  FieldSlotsComponents,
  FieldSlotsComponentsProps,
} from '../internals/hooks/use-field';
import { useLocaleText } from '../internals/hooks/use-utils';
import { FieldsInputFieldProps } from '../internals/models';

interface UseClearableFieldProps<
  TFieldProps extends FieldsInputFieldProps,
  TInputProps extends { endAdornment?: React.ReactNode } | undefined,
  TFieldSlots extends FieldSlotsComponents,
  TFieldSlotsComponentsProps extends FieldSlotsComponentsProps,
> {
  clearable: boolean;
  fieldProps: TFieldProps;
  InputProps: TInputProps;
  onClear: React.MouseEventHandler<HTMLButtonElement>;
  slots?: {
    [K in keyof TFieldSlots as Uncapitalize<K & string>]: TFieldSlots[K];
  };
  slotProps?: TFieldSlotsComponentsProps;
}

export const useClearableField = <
  TFieldProps extends FieldsInputFieldProps,
  TInputProps extends { endAdornment?: React.ReactNode } | undefined,
  TFieldSlotsComponents extends FieldSlotsComponents,
  TFieldSlotsComponentsProps extends FieldSlotsComponentsProps,
>({
  clearable,
  fieldProps: forwardedFieldProps,
  InputProps: ForwardedInputProps,
  onClear,
  slots,
  slotProps,
}: UseClearableFieldProps<
  TFieldProps,
  TInputProps,
  TFieldSlotsComponents,
  TFieldSlotsComponentsProps
>) => {
  const localeText = useLocaleText();
  const clearButtonClassName = 'clearButton';

  const IconButton = slots?.clearButton ?? UiIconButton;
  // The spread is here to avoid this bug mui/material-ui#34056
  const { ownerState, ...iconButtonProps } = useSlotProps({
    elementType: IconButton,
    externalSlotProps: slotProps?.clearButton,
    ownerState: {},
    className: clearButtonClassName,
    additionalProps: {
      title: localeText.fieldClearLabel,
    },
  });
  const EndClearIcon = slots?.clearIcon ?? IcClose;
  const endClearIconProps = useSlotProps({
    elementType: EndClearIcon,
    externalSlotProps: slotProps?.clearIcon,
    ownerState: {},
  });

  const InputProps = {
    ...ForwardedInputProps,
    endAdornment: (
      <Fragment>
        {clearable && (
          <InputAdornment
            css={
              ForwardedInputProps?.endAdornment &&
              css`
                margin-right: -4px;
              `
            }
            position="end"
          >
            <IconButton {...iconButtonProps} onClick={onClear}>
              <EndClearIcon fontSize="small" {...endClearIconProps} />
            </IconButton>
          </InputAdornment>
        )}
        {ForwardedInputProps?.endAdornment}
      </Fragment>
    ),
  };

  const fieldProps: TFieldProps = {
    css: css`
      .${clearButtonClassName} {
        opacity: 1;
      }

      @media (pointer: fine) {
        .${clearButtonClassName} {
          opacity: 0;
        }

        &:hover,
        &:focus-within {
          .${clearButtonClassName} {
            opacity: 1;
          }
        }
      }
    `,
    ...forwardedFieldProps,
  };

  return { InputProps, fieldProps };
};
