import { css, useTheme } from '@emotion/react';
import { forwardRef } from 'react';

export const AlliModalContainer = forwardRef<HTMLDivElement>((props, ref) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        position: relative;
        z-index: ${theme.modalContainer.zIndex};
      `}
      id={theme.modalContainer.id}
      ref={ref}
    />
  );
});
