export const white = {
  50: 'hsla(0, 0%, 100%, 0.05)',
  100: 'hsla(0, 0%, 100%, 0.1)',
  200: 'hsla(0, 0%, 100%, 0.2)',
  300: 'hsla(0, 0%, 100%, 0.3)',
  400: 'hsla(0, 0%, 100%, 0.4)',
  500: 'hsla(0, 0%, 100%, 0.5)',
  600: 'hsla(0, 0%, 100%, 0.6)',
  700: 'hsla(0, 0%, 100%, 0.7)',
  800: 'hsla(0, 0%, 100%, 0.8)',
  900: 'hsla(0, 0%, 100%, 0.9)',
};
