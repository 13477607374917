import { ButtonBase, ExtendButtonBase } from '@allganize/ui-button';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef, useContext } from 'react';
import { AccordionContext } from '../accordion/accordion-context';
import { accordionSummaryClasses } from './accordion-summary-classes';
import { AccordionSummaryTypeMap } from './accordion-summary-type-map';

// @ts-expect-error overridable component
export const AccordionSummary: ExtendButtonBase<AccordionSummaryTypeMap> =
  forwardRef((props, ref) => {
    const {
      children,
      classes,
      className,
      expandIcon,
      focusVisibleClassName,
      onClick,
      ...other
    } = props;
    const theme = useTheme();
    const { disabled = false, expanded, toggle } = useContext(AccordionContext);

    const handleChange = (event: React.MouseEvent<HTMLDivElement>) => {
      if (toggle) {
        toggle(event);
      }
      if (onClick) {
        onClick(event);
      }
    };

    return (
      <ButtonBase
        data-testid="accordion-summary"
        css={css`
          display: flex;
          min-height: 48px;
          padding: ${theme.spacing(0, 2)};
          transition: ${theme.transitions.create(
            ['min-height', 'background-color'],
            { duration: theme.transitions.duration.shortest },
          )};

          &.${accordionSummaryClasses.focusVisible} {
            background-color: ${theme.palette.action.focus};
          }

          &.${accordionSummaryClasses.disabled} {
            opacity: ${theme.palette.action.disabledOpacity};
          }

          &:hover:not(.${accordionSummaryClasses.disabled}) {
            cursor: pointer;
          }
        `}
        disabled={disabled}
        component="div"
        aria-expanded={expanded}
        className={clsx(
          accordionSummaryClasses.root,
          {
            [accordionSummaryClasses.expanded]: expanded,
            [accordionSummaryClasses.disabled]: disabled,
          },
          classes?.root,
          {
            [classes?.expanded ?? '']: expanded,
            [classes?.disabled ?? '']: disabled,
          },
          className,
        )}
        focusVisibleClassName={clsx(
          accordionSummaryClasses.focusVisible,
          classes?.focusVisible,
          focusVisibleClassName,
        )}
        onClick={handleChange}
        {...other}
        ref={ref}
      >
        <div
          css={css`
            display: flex;
            flex-grow: 1;
            margin: 12px 0;
          `}
          className={clsx(
            accordionSummaryClasses.content,
            { [accordionSummaryClasses.expanded]: expanded },
            classes?.content,
            { [classes?.expanded ?? '']: expanded },
          )}
        >
          {children}
        </div>

        {expandIcon && (
          <div
            css={css`
              display: flex;
              color: ${theme.palette.action.active};
              transform: rotate(0deg);
              transition: ${theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              })};

              &.${accordionSummaryClasses.expanded} {
                transform: rotate(180deg);
              }
            `}
            className={clsx(
              accordionSummaryClasses.expandIconWrapper,
              { [accordionSummaryClasses.expanded]: expanded },
              classes?.expandIconWrapper,
              { [classes?.expanded ?? '']: expanded },
            )}
          >
            {expandIcon}
          </div>
        )}
      </ButtonBase>
    );
  });
