import { Text } from '@allganize/ui-text';
import clsx from 'clsx';
import { forwardRef, useMemo } from 'react';
import { useLocaleText, useUtils } from '../internals/hooks/use-utils';
import { resolveDateFormat } from '../internals/utils/date-utils';
import { PickersToolbar } from '../pickers-toolbar';
import { datePickerToolbarClasses } from './date-picker-toolbar-classes';
import { DatePickerToolbarProps } from './date-picker-toolbar-type-map';

export const DatePickerToolbar = forwardRef<
  HTMLDivElement,
  DatePickerToolbarProps
>((props, ref) => {
  const {
    classes,
    value,
    isLandscape,
    onChange,
    toolbarFormat,
    toolbarPlaceholder = '––',
    views,
    ...other
  } = props;
  const utils = useUtils<Date>();
  const localeText = useLocaleText<Date>();

  const dateText = useMemo(() => {
    if (!value) {
      return toolbarPlaceholder;
    }

    const formatFromViews = resolveDateFormat(
      utils,
      { format: toolbarFormat, views },
      true,
    );

    return utils.formatByString(value, formatFromViews);
  }, [value, toolbarFormat, toolbarPlaceholder, utils, views]);

  return (
    <PickersToolbar
      data-testid="date-picker-toolbar"
      toolbarTitle={localeText.datePickerToolbarTitle}
      isLandscape={isLandscape}
      {...other}
      ref={ref}
      className={clsx(
        datePickerToolbarClasses.root,
        classes?.root,
        other.className,
      )}
    >
      <Text
        variant="title14"
        align={isLandscape ? 'left' : 'center'}
        className={clsx(datePickerToolbarClasses.title, classes?.title)}
      >
        {dateText}
      </Text>
    </PickersToolbar>
  );
});
