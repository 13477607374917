import { Grow } from '@allganize/ui-transition';
import MuiPopover from '@mui/material/Popover';
import { forwardRef } from 'react';
import { PopoverPaper, PopoverRoot } from './popover-slots';
import { PopoverProps } from './popover-type-map';

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  (props, ref) => {
    const { elevation = 3, slots, ...other } = props;

    return (
      <MuiPopover
        data-testid="popover"
        elevation={elevation}
        TransitionComponent={Grow}
        {...other}
        ref={ref}
        slots={{
          root: PopoverRoot,
          paper: PopoverPaper,
          ...slots,
        }}
      />
    );
  },
);

export { getOffsetLeft, getOffsetTop } from '@mui/material/Popover';
