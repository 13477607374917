import { Fade } from '@allganize/ui-transition';
import { FunctionComponent } from 'react';
import {
  PickersModalDialogContent,
  PickersModalDialogRoot,
} from './pickers-modal-dialog-slots';
import { PickersModalDialogProps } from './pickers-modal-dialog-type-map';

export const PickersModalDialog: FunctionComponent<
  React.PropsWithChildren<PickersModalDialogProps>
> = props => {
  const { children, onDismiss, open, slots, slotProps } = props;

  const Dialog = slots?.dialog ?? PickersModalDialogRoot;
  const Transition = slots?.mobileTransition ?? Fade;

  return (
    <Dialog
      data-testid="pickers-modal-dialog"
      open={open}
      onClose={onDismiss}
      {...slotProps?.dialog}
      TransitionComponent={Transition}
      TransitionProps={slotProps?.mobileTransition}
      PaperComponent={slots?.mobilePaper}
      PaperProps={slotProps?.mobilePaper}
    >
      <PickersModalDialogContent>{children}</PickersModalDialogContent>
    </Dialog>
  );
};
