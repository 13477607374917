import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type KnowledgeBaseQueueClassKey =
  | 'root'
  | 'cardContainer'
  | 'card'
  | 'description'
  | 'actions';

export type KnowledgeBaseQueueClasses = Record<
  KnowledgeBaseQueueClassKey,
  string
>;

export const knowledgeBaseQueueClasses: KnowledgeBaseQueueClasses =
  generateUtilityClasses('KnowledgeBaseQueue', [
    'root',
    'cardContainer',
    'card',
    'description',
    'actions',
  ]);
