import { createContext } from 'react';

export interface IframeContextValue {
  document: typeof document;
  window: typeof window;
}

export const IframeContext = createContext<IframeContextValue>({
  document,
  window,
});
