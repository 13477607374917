import { Paper } from '@allganize/ui-paper';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { DocumentViewerPageWrapperProps } from './document-viewer-page-wrapper-type-map';

export const DocumentViewerPageWrapper = forwardRef<
  HTMLDivElement,
  DocumentViewerPageWrapperProps
>((props, ref) => {
  return (
    <Paper
      css={css`
        position: relative;
        border-radius: 0;
        padding: 0;
        display: inline-block;
      `}
      elevation={2}
      {...props}
      ref={ref}
    />
  );
});
