import { EditorState } from '@allganize/draft-input';
import { getSelectionForInlineStyle } from './get-selection-for-inline-style';
import { toggleInlineStyle } from './toggle-inline-style';

export const toggleInlineStyleForSelection = (
  editorState: EditorState,
  inlineStyle: string,
  forceSelection = false,
) => {
  const newSelection = getSelectionForInlineStyle(editorState);
  const styleApplied = toggleInlineStyle(
    editorState,
    inlineStyle,
    newSelection,
  );

  const selected = forceSelection
    ? EditorState.forceSelection(styleApplied, editorState.getSelection())
    : EditorState.acceptSelection(styleApplied, editorState.getSelection());

  return EditorState.setInlineStyleOverride(
    selected,
    styleApplied.getInlineStyleOverride(),
  );
};
