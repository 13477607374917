import { useEventCallback } from '@allganize/hooks';
import { raf } from '@allganize/utils-timeout';
import { useRef } from 'react';

interface UseChatQueueOptions<T> {
  timeout?: number;
  onPop?(edges: T[]): void;
}

export const useChatQueue = <T>({
  timeout = 100,
  onPop,
}: UseChatQueueOptions<T> = {}) => {
  const queueRef = useRef<T[]>([]);
  const timeoutIdRef = useRef<number | null>(null);

  const getQueue = () => queueRef.current;

  const stop = useEventCallback(() => {
    if (timeoutIdRef.current !== null) {
      window.clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  });

  const clear = () => {
    queueRef.current = [];
  };

  const flush = () => {
    const edges = queueRef.current;

    if (edges.length > 0) {
      onPop?.(edges);
    }

    clear();
    stop();
    return edges;
  };

  const dequeue = () => {
    const edge = queueRef.current.shift();

    if (!edge) {
      return;
    }

    onPop?.([edge]);
    return edge;
  };

  const start = useEventCallback(() => {
    if (timeoutIdRef.current !== null) {
      return;
    }

    const update = async () => {
      const edge = dequeue();

      if (!edge) {
        timeoutIdRef.current = null;
        return;
      }

      await raf();
      timeoutIdRef.current = window.setTimeout(update, timeout);
    };

    update();
  });

  const push = (...edges: T[]) => {
    if (edges.length === 0) {
      return;
    }

    queueRef.current.push(...edges);
    start();
  };

  return {
    clear,
    dequeue,
    flush,
    getQueue,
    push,
    start,
    stop,
  };
};
