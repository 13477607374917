import { useCombinedRef, useId } from '@allganize/hooks';
import { FormGroup } from '@allganize/ui-form';
import RadioGroupContext from '@mui/material/RadioGroup/RadioGroupContext';
import { useControlled } from '@mui/material/utils';
import clsx from 'clsx';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { radioGroupClasses } from './radio-group-classes';
import { RadioGroupProps } from './radio-group-type-map';

/**
 * The Radio Group allows the user to select one option from a set.
 */
export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  (props, ref) => {
    const {
      actions,
      classes,
      defaultValue,
      name: nameProp,
      onChange,
      value: valueProp,
      ...other
    } = props;
    const rootRef = useRef<HTMLDivElement>(null);
    const handleRef = useCombinedRef(rootRef, ref);
    const reactId = useId();
    const name = nameProp || `radio-group-${reactId}`;
    const [value, setValueState] = useControlled({
      controlled: valueProp,
      default: defaultValue,
      name: 'RadioGroup',
    });

    const contextValue = useMemo(
      () => ({
        name,
        onChange(event: React.ChangeEvent<HTMLInputElement>) {
          setValueState(event.target.value);

          if (onChange) {
            onChange(event, event.target.value);
          }
        },
        value,
      }),
      [name, onChange, setValueState, value],
    );

    useImperativeHandle(
      actions,
      () => ({
        focus: () => {
          let input = rootRef.current?.querySelector(
            'input:not(:disabled):checked',
          );

          if (!input) {
            input = rootRef.current?.querySelector('input:not(:disabled)');
          }

          if (input) {
            (input as HTMLInputElement).focus();
          }
        },
      }),
      [],
    );

    return (
      <RadioGroupContext.Provider value={contextValue}>
        <FormGroup
          data-testid="radio-group"
          role="radiogroup"
          {...other}
          ref={handleRef}
          classes={{
            ...classes,
            root: clsx(radioGroupClasses.root, classes?.root),
            error: clsx(radioGroupClasses.error, classes?.error),
            row: clsx(radioGroupClasses.row, classes?.row),
          }}
        />
      </RadioGroupContext.Provider>
    );
  },
);
