import { useCombinedRef, useId, useWindow } from '@allganize/hooks';
import { Truncate } from '@allganize/truncate';
import { IconButton } from '@allganize/ui-button';
import {
  FileDropzone,
  FileList,
  FileListItem,
  FileListItemIcon,
  FileListItemText,
} from '@allganize/ui-file-input';
import { FormControl, FormHelperText } from '@allganize/ui-form';
import { IcClose, IcOpenInNew, IcUpload } from '@allganize/ui-icons';
import { InputLabel } from '@allganize/ui-input';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { noop } from 'lodash-es';
import { forwardRef, useEffect, useMemo } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ChatFileFragment } from '../graphql/fragments/chat-file-fragment';
import { FormValue } from './use-form-value-form';

export const getFilename = (
  file: ChatFileFragment | File | null,
  win?: typeof globalThis,
): string | null => {
  if (!file) {
    return null;
  }

  if ((win && file instanceof win.File) || file instanceof File) {
    return file.name;
  }

  if (file.metaInfo) {
    try {
      const parsed = JSON.parse(file.metaInfo);
      return parsed?.filename || parsed?.name;
    } catch {
      // nothing to do
    }
  }

  return file.url || null;
};

interface FileFormValueProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<TFieldValues>;
  readOnly?: boolean;
  value: FormValue;
}

// custom typing in order to make component generic
interface FileFormValueType {
  <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >(
    props: React.PropsWithoutRef<FileFormValueProps<TFieldValues, TName>> &
      React.RefAttributes<HTMLDivElement>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

// @ts-expect-error overridable component
export const FileFormValue: FileFormValueType = forwardRef(
  ({ field, fieldState, formState, readOnly, value }, ref) => {
    const theme = useTheme();
    const reactId = useId();
    const { ref: rootRef, window: win } = useWindow();
    const combinedRef = useCombinedRef(rootRef, ref);
    const id = `file-field-${reactId}`;
    const helperTextId = id ? `${id}-helper-text` : undefined;
    const inputLabelId = value.display && id ? `${id}-label` : undefined;
    const filename = getFilename(field.value, win ?? undefined) ?? undefined;
    const { cleanup, url } = useMemo(() => {
      if (!field.value) {
        return {
          cleanup: noop,
          url: null,
        };
      }

      if (
        (win && field.value instanceof win.File) ||
        field.value instanceof File
      ) {
        return {
          cleanup: URL.revokeObjectURL,
          url: URL.createObjectURL(field.value),
        };
      }

      return {
        cleanup: noop,
        url: field.value.url,
      };
    }, [field.value, win]);

    useEffect(() => {
      return () => {
        if (url) {
          cleanup(url);
        }
      };
    }, [cleanup, url]);

    const handleDropAccepted = (files: File[]) => {
      const file = files[0];
      field.onChange(file ?? null);
    };

    const handleRemove = () => {
      field.onChange(null);
    };

    return (
      <FormControl
        fullWidth
        required={value.mandatory}
        error={fieldState.invalid}
        disabled={readOnly}
        ref={combinedRef}
      >
        {value.display !== null && value.display !== '' && (
          <InputLabel htmlFor={id} id={inputLabelId}>
            {value.display}
          </InputLabel>
        )}

        <FileDropzone
          disabled={readOnly}
          inputProps={{
            name: field.name,
            'aria-describedby': helperTextId,
          }}
          inputRef={field.ref}
          maxFiles={1}
          onDropAccepted={handleDropAccepted}
          onBlur={field.onBlur}
          css={css`
            padding: 12px;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
          `}
          dragOverlay={
            <div
              css={css`
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
              `}
            >
              <IcUpload
                css={css`
                  margin-bottom: 4px;
                `}
              />

              <Text variant="body14">
                <FormattedMessage
                  id="form.file.placeholder.drag"
                  defaultMessage="Drop file here"
                  description="File input placeholder dragging state"
                />
              </Text>
            </div>
          }
        >
          <IcUpload
            css={css`
              margin-bottom: 4px;
            `}
          />

          <Text variant="body14">
            <FormattedMessage
              id="form.file.placeholder"
              defaultMessage="Click or drag & drop{br}a file to upload"
              description="File input placeholder"
              values={{
                br: <br />,
              }}
            />
          </Text>
        </FileDropzone>

        {fieldState.error?.message && !field.value && (
          <FormHelperText id={helperTextId}>
            {fieldState.error.message}
          </FormHelperText>
        )}

        {field.value && (
          <FileList
            css={css`
              padding-bottom: 0;
            `}
          >
            <FileListItem
              secondaryAction={
                readOnly ? (
                  <IconButton
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                    edge="end"
                    href={url ?? undefined}
                    download={filename}
                  >
                    <IcOpenInNew />
                  </IconButton>
                ) : (
                  <IconButton size="small" edge="end" onClick={handleRemove}>
                    <IcClose />
                  </IconButton>
                )
              }
            >
              <FileListItemIcon />

              <FileListItemText
                primary={<Truncate clamp={1}>{filename}</Truncate>}
                primaryTextProps={{
                  title: filename,
                }}
                secondary={
                  fieldState.error?.message && (
                    <div
                      css={css`
                        color: ${theme.palette.error.main};
                      `}
                    >
                      {fieldState.error?.message}
                    </div>
                  )
                }
                secondaryTextProps={{
                  id: helperTextId,
                }}
              />
            </FileListItem>
          </FileList>
        )}
      </FormControl>
    );
  },
);
