import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type PdfPageClassKey =
  | 'root'
  | 'document'
  | 'page'
  | 'canvas'
  | 'textContent'
  | 'message'
  | 'messageError'
  | 'messageNoData'
  | 'messageLoading'
  | 'highlight'
  | 'loading'
  | 'error'
  | 'textLayer'
  | 'annotationLayer';
export type PdfPageClasses = Record<PdfPageClassKey, string>;

export const pdfPageClasses: PdfPageClasses = {
  ...generateUtilityClasses('PdfPage', [
    'root',
    'document',
    'page',
    'highlight',
    'loading',
    'error',
  ]),
  canvas: 'react-pdf__Page__canvas',
  textContent: 'react-pdf__Page__textContent',
  message: 'react-pdf__message',
  messageError: 'react-pdf__message--error',
  messageNoData: 'react-pdf__message--no-data',
  messageLoading: 'react-pdf__message--loading',
  textLayer: 'textLayer',
  annotationLayer: 'annotationLayer',
};
