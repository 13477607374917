import { useTheme } from '@allganize/ui-theme';
import { useSlotProps } from '@mui/base/utils';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import { forwardRef } from 'react';
import { useClearableField } from '../hooks/use-clearable-field';
import { InputField as InternalInputField } from './date-time-field-slots';
import {
  DateTimeFieldProps,
  DateTimeFieldSlotsComponent,
  DateTimeFieldSlotsComponentsProps,
} from './date-time-field-type-map';
import { useDateTimeField } from './use-date-time-field';

export const DateTimeField = forwardRef<HTMLDivElement, DateTimeFieldProps>(
  (props, ref) => {
    const { slots, slotProps, InputProps, inputProps, ...other } = props;
    const theme = useTheme();
    const muiTheme = useMuiTheme();
    const ownerState = props;

    const InputField = slots?.inputField ?? InternalInputField;
    const {
      inputRef: externalInputRef,
      ...inputFieldProps
    }: DateTimeFieldProps = useSlotProps<
      typeof InputField,
      DateTimeFieldSlotsComponentsProps['inputField'],
      DateTimeFieldProps,
      DateTimeFieldProps
    >({
      elementType: InputField,
      externalSlotProps: slotProps?.inputField,
      externalForwardedProps: other,
      ownerState,
    });

    // TODO: Remove when mui/material-ui#35088 will be merged
    inputFieldProps.inputProps = {
      ...inputProps,
      ...inputFieldProps.inputProps,
    };
    inputFieldProps.InputProps = {
      ...InputProps,
      ...inputFieldProps.InputProps,
    };

    const {
      ref: inputRef,
      onPaste,
      onKeyDown,
      inputMode,
      readOnly,
      clearable,
      onClear,
      ...fieldProps
    } = useDateTimeField<typeof inputFieldProps>({
      props: inputFieldProps,
      inputRef: externalInputRef,
    });

    const { InputProps: ProcessedInputProps, fieldProps: processedFieldProps } =
      useClearableField<
        typeof fieldProps,
        typeof fieldProps.InputProps,
        DateTimeFieldSlotsComponent,
        DateTimeFieldSlotsComponentsProps
      >({
        onClear,
        clearable,
        fieldProps,
        InputProps: fieldProps.InputProps,
        slots,
        slotProps,
      });

    return (
      <ThemeProvider theme={{ ...muiTheme, direction: theme.direction }}>
        <InputField
          data-testid="date-time-field"
          ref={ref}
          {...processedFieldProps}
          InputProps={{ ...ProcessedInputProps, readOnly }}
          inputProps={{
            ...fieldProps.inputProps,
            inputMode,
            onPaste,
            onKeyDown,
            ref: inputRef,
          }}
        />
      </ThemeProvider>
    );
  },
);
