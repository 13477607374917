import {
  useDraftToolbarEditorStateGetter,
  useDraftToolbarEditorStateSetter,
  useDraftToolbarFocus,
} from '@allganize/draft-toolbar-plugin';
import { useCombinedRef, useId } from '@allganize/hooks';
import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { IcColorize } from '@allganize/ui-icons';
import { Popover, PopoverOrigin } from '@allganize/ui-modal';
import { Tooltip } from '@allganize/ui-tooltip';
import { raf } from '@allganize/utils-timeout';
import { uniq } from 'lodash-es';
import { Fragment, forwardRef, memo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getSelectedInlineStyles } from '../utils/get-selected-inline-styles';
import { selectionHasDefaultColor } from '../utils/selection-has-default-color';
import { toggleInlineStyleForSelection } from '../utils/toggle-inline-style-for-selection';
import { ColorPalette } from './color-palette';
import { colors, defaultColor } from './default-colors';

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const transformOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

interface DraftToolbarTextColorButtonProps extends IconButtonProps {}

export const DraftToolbarTextColorButton = memo(
  forwardRef<HTMLButtonElement, DraftToolbarTextColorButtonProps>(
    (props, ref) => {
      const focus = useDraftToolbarFocus();
      const getEditorState = useDraftToolbarEditorStateGetter();
      const setEditorState = useDraftToolbarEditorStateSetter();
      const [popoverOpen, setPopoverOpen] = useState(false);
      const popoverId = useId();
      const buttonRef = useRef<HTMLButtonElement>(null);
      const combinedRef = useCombinedRef(buttonRef, ref);
      const styles = getSelectedInlineStyles(getEditorState());
      const hasDefaultColor = selectionHasDefaultColor(getEditorState());
      const colorStyles = uniq(
        [
          ...styles
            .filter(style => style.startsWith('color-'))
            .map(style => style.slice('color-'.length)),
          hasDefaultColor ? defaultColor : '',
        ].filter(Boolean),
      );
      const colorValue =
        colorStyles.length === 0 ? [defaultColor] : colorStyles;

      const closePopover = () => {
        setPopoverOpen(false);
        focus();
      };

      const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.preventDefault();
        setPopoverOpen(prev => !prev);
        props.onClick?.(ev);
      };

      const isColorSelected = (color: string): boolean => {
        if (colorStyles.some(style => style === color)) {
          return true;
        }

        if (color === defaultColor) {
          return colorStyles.length === 0;
        }

        return false;
      };

      const handleChange = async (color: string) => {
        setEditorState(
          toggleInlineStyleForSelection(
            getEditorState(),
            `color-${color}`,
            true,
          ),
        );

        await raf();
        closePopover();
      };

      return (
        <Fragment>
          <Tooltip
            title={
              <FormattedMessage
                id="TextArea.TextColorButton"
                defaultMessage="Text color"
                description="Text area text color button tooltip text"
              />
            }
          >
            <IconButton
              aria-owns={popoverOpen ? popoverId : undefined}
              aria-haspopup="true"
              size="small"
              {...props}
              ref={combinedRef}
              onClick={handleClick}
            >
              <IcColorize fontSize="small" />
            </IconButton>
          </Tooltip>

          <Popover
            id={popoverId}
            open={popoverOpen}
            anchorEl={buttonRef.current}
            onClose={closePopover}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
          >
            <ColorPalette
              value={colorValue}
              options={colors}
              isOptionSelected={isColorSelected}
              onChange={handleChange}
            />
          </Popover>
        </Fragment>
      );
    },
  ),
);
