import {
  ContentBlock,
  ContentState,
  DraftDecorator,
  DraftPlugin,
} from '@allganize/draft-input';
import { DraftLinkPluginOptions } from '../draft-link-plugin';
import { linkify } from '../utils/linkify';
import { DraftAutoLink, DraftAutoLinkProps } from './draft-auto-link';

export interface DraftAutoLinkPluginOptions extends DraftLinkPluginOptions {}

export class DraftAutoLinkPlugin extends DraftPlugin {
  public static extractLinks(text: string) {
    return linkify.match(text);
  }

  public static strategy(
    block: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
  ) {
    const links = DraftAutoLinkPlugin.extractLinks(block.getText());

    if (links) {
      for (const link of links) {
        callback(link.index, link.lastIndex);
      }
    }
  }

  constructor(private options: DraftAutoLinkPluginOptions = {}) {
    super();
  }

  public getDecorators(): DraftDecorator<DraftAutoLinkProps>[] | null {
    return [
      {
        strategy: DraftAutoLinkPlugin.strategy,
        component: DraftAutoLink,
        props: {
          classes: this.options.classes,
          extractor: DraftAutoLinkPlugin.extractLinks,
        },
      },
    ];
  }
}
