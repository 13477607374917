import { Types } from '@allganize/alli-sdk-interfaces';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  accordionSummaryClasses,
} from '@allganize/ui-accordion';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { IcArrowExpandMore, IcCheckCircle } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import {
  FunctionComponent,
  Fragment,
  lazy,
  Suspense,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { IntermediateStepFragment } from '../graphql/fragments/intermediate-step-fragment';

const Markdown = lazy(() =>
  import('@allganize/markdown').then(module => ({ default: module.Markdown })),
);

interface IntermediateStepProps
  extends Omit<AccordionProps, 'children' | 'defaultExpanded'> {
  intermediateSteps: Array<IntermediateStepFragment>;
  state: Types.SearchContextStep;
}

export const IntermediateStep: FunctionComponent<IntermediateStepProps> = ({
  intermediateSteps,
  state,
  ...other
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [icon, message] = useMemo(() => {
    const currentStatusMessage = intermediateSteps.find(
      step => !step.completed,
    )?.statusMessage;

    if (currentStatusMessage) {
      return [
        <CircularProgress
          size="xs"
          css={css`
            margin-right: ${theme.spacing(1)};
          `}
        />,
        currentStatusMessage,
      ];
    }

    if (state !== Types.SearchContextStep.COMPLETE) {
      return [
        <IcCheckCircle
          color="primary"
          css={css`
            margin-right: ${theme.spacing(1)};
          `}
        />,
        intermediateSteps[intermediateSteps.length - 1]?.statusMessage || '',
      ];
    }

    return [
      null,
      <FormattedMessage
        id="intermediate-step.complete"
        defaultMessage="complete"
        description="intermediate step complete status text"
      />,
    ];
  }, [intermediateSteps, state, theme]);

  return (
    <Accordion
      {...other}
      expanded={expanded}
      onChange={(_, _expanded) => {
        setExpanded(_expanded);
      }}
      css={css`
        background-color: transparent;
        &::before {
          display: none;
        }

        & + & {
          margin-top: 16px;
        }
      `}
    >
      <AccordionSummary
        key="summary"
        expandIcon={<IcArrowExpandMore />}
        css={css`
          min-height: 32px;
          padding: 0;
          justify-content: flex-start;

          .${accordionSummaryClasses.content} {
            display: flex;
            align-items: center;
            margin: 6px 0;
            margin-right: 4px;
            flex-grow: 0;
          }
        `}
      >
        {icon}

        <Text variant="body14" component="span">
          {message}
        </Text>
      </AccordionSummary>
      <AccordionDetails
        css={css`
          padding: 16px 16px 8px 0;
        `}
      >
        {intermediateSteps.map((step, index) => {
          if (!step.markdownMessage && !step.statusMessage) {
            return null;
          }

          return (
            <Fragment key={step.id}>
              {step.statusMessage && (
                <div
                  css={[
                    css`
                      display: flex;
                      align-items: center;
                    `,
                    index > 0 &&
                      css`
                        margin-top: ${theme.spacing(2)};
                      `,
                  ]}
                >
                  {step.completed ? (
                    <IcCheckCircle color="primary" />
                  ) : (
                    <CircularProgress size="xs" />
                  )}
                  <Text
                    variant="body14"
                    css={css`
                      margin-left: ${theme.spacing(1)};
                    `}
                  >
                    {step.statusMessage}
                  </Text>
                </div>
              )}
              {step.markdownMessage && (
                <Suspense fallback={null}>
                  <Markdown
                    css={css`
                      margin-left: ${theme.spacing(1)};
                      margin-top: ${theme.spacing(2)};
                      padding-left: ${theme.spacing(2)};
                      border-left: 1px solid
                        ${theme.palette.border.divider.default};
                    `}
                  >
                    {step.markdownMessage}
                  </Markdown>
                </Suspense>
              )}
            </Fragment>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
