import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type FileDropzoneClassKey =
  | 'root'
  | 'dragOverlay'
  | 'input'
  | 'dragAccept'
  | 'dragActive'
  | 'dragReject'
  | 'fileDialogActive'
  | 'focused';
export type FileDropzoneClasses = Record<FileDropzoneClassKey, string>;

export const fileDropzoneClasses: FileDropzoneClasses = generateUtilityClasses(
  'FileDropzone',
  [
    'root',
    'dragOverlay',
    'input',
    'dragAccept',
    'dragActive',
    'dragReject',
    'fileDialogActive',
    'focused',
  ],
);
