import { useCallback, useRef } from 'react';
import { useEnhancedEffect } from '../use-enhanced-effect/use-enhanced-effect';
import { useEventCallback } from '../use-event-callback/use-event-callback';
import { useUnmount } from '../use-unmount/use-unmount';
import { useWindow } from '../use-window/use-window';

type MeasurementType = 'client' | 'offset' | 'scroll' | 'bounds' | 'margin';

interface TopLeft {
  readonly top: number;
  readonly left: number;
}

interface BottomRight {
  readonly bottom: number;
  readonly right: number;
}

interface Dimensions {
  readonly width: number;
  readonly height: number;
}

type Margin = TopLeft & BottomRight;

type Rect = TopLeft & Dimensions;

type BoundingRect = Dimensions & Margin;

export interface ContentRect {
  client?: Rect;
  offset?: Rect;
  scroll?: Rect;
  bounds?: BoundingRect;
  margin?: Margin;
  entry?: any;
}

type MeasureTypes = Partial<Record<MeasurementType, boolean>>;

const measureTypes: MeasurementType[] = [
  'client',
  'offset',
  'scroll',
  'bounds',
  'margin',
];

const getTypes = (props: MeasureTypes) => {
  const allowedTypes: MeasurementType[] = [];

  measureTypes.forEach(type => {
    if (props[type]) {
      allowedTypes.push(type);
    }
  });

  return allowedTypes;
};

const getContentRect = (
  node: HTMLElement,
  types: MeasurementType[],
): ContentRect => {
  const calculations: ContentRect = {};

  if (types.indexOf('client') > -1) {
    calculations.client = {
      top: node.clientTop,
      left: node.clientLeft,
      width: node.clientWidth,
      height: node.clientHeight,
    };
  }

  if (types.indexOf('offset') > -1) {
    calculations.offset = {
      top: node.offsetTop,
      left: node.offsetLeft,
      width: node.offsetWidth,
      height: node.offsetHeight,
    };
  }

  if (types.indexOf('scroll') > -1) {
    calculations.scroll = {
      top: node.scrollTop,
      left: node.scrollLeft,
      width: node.scrollWidth,
      height: node.scrollHeight,
    };
  }

  if (types.indexOf('bounds') > -1) {
    const rect = node.getBoundingClientRect();
    calculations.bounds = {
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
      width: rect.width,
      height: rect.height,
    };
  }

  if (types.indexOf('margin') > -1) {
    const styles = getComputedStyle(node);
    calculations.margin = {
      top: styles ? Number.parseInt(styles.marginTop, 10) : 0,
      right: styles ? Number.parseInt(styles.marginRight, 10) : 0,
      bottom: styles ? Number.parseInt(styles.marginBottom, 10) : 0,
      left: styles ? Number.parseInt(styles.marginLeft, 10) : 0,
    };
  }

  return calculations;
};

export interface UseMeasureOptions extends MeasureTypes {
  onResize?(contentRect: ContentRect): void;
}

export const useMeasure = <T extends HTMLElement>(
  options: UseMeasureOptions,
) => {
  const { onResize } = options;
  const animationFrameIdRef = useRef<number | null>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const nodeRef = useRef<T | null>(null);
  const { window: contentWindow, ref } = useWindow();

  const clearResizeObserver = useEventCallback(() => {
    if (resizeObserverRef.current !== null) {
      resizeObserverRef.current.disconnect();
      resizeObserverRef.current = null;
    }
  });

  const clearAnimation = useEventCallback(() => {
    if (animationFrameIdRef.current !== null) {
      contentWindow?.cancelAnimationFrame(animationFrameIdRef.current);
      animationFrameIdRef.current = null;
    }
  });

  const handleResize = useEventCallback((cr: ContentRect) => {
    onResize?.(cr);
  });

  const measure = useEventCallback((entries?: ResizeObserverEntry[]) => {
    if (!nodeRef.current || !contentWindow) {
      return;
    }

    const cr = getContentRect(nodeRef.current, getTypes(options));

    if (entries) {
      cr.entry = entries[0].contentRect;
    }

    animationFrameIdRef.current = contentWindow?.requestAnimationFrame(() => {
      if (resizeObserverRef.current !== null) {
        handleResize(cr);
      }
    });
  });

  const subscribe = useEventCallback(() => {
    resizeObserverRef.current =
      contentWindow &&
      'ResizeObserver' in contentWindow &&
      contentWindow.ResizeObserver
        ? new contentWindow.ResizeObserver(measure)
        : new ResizeObserver(measure);

    if (nodeRef.current !== null) {
      resizeObserverRef.current.observe(nodeRef.current);
      handleResize(getContentRect(nodeRef.current, getTypes(options)));
    }
  });

  useEnhancedEffect(() => {
    subscribe();

    return () => {
      clearResizeObserver();
    };
  }, [subscribe, clearResizeObserver]);

  useUnmount(() => {
    clearAnimation();
    clearResizeObserver();
  });

  const handleRef = useCallback(
    (node: T | null) => {
      if (resizeObserverRef.current !== null && nodeRef.current !== null) {
        resizeObserverRef.current.unobserve(nodeRef.current);
      }

      nodeRef.current = node;
      ref(node);

      if (resizeObserverRef.current !== null && nodeRef.current !== null) {
        resizeObserverRef.current.observe(nodeRef.current);
      }
    },
    [ref],
  );

  return {
    measureRef: handleRef,
    measure,
  };
};
