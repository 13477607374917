import {
  useDraftToolbarEditorStateGetter,
  useDraftToolbarEditorStateSetter,
} from '@allganize/draft-toolbar-plugin';
import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { IcFormatClear } from '@allganize/ui-icons';
import { Tooltip } from '@allganize/ui-tooltip';
import { forwardRef, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { getSelectedInlineStyles } from '../utils/get-selected-inline-styles';
import { removeInlineStyles } from '../utils/remove-inline-styles';

interface DraftToolbarRemoveFormattingButtonProps extends IconButtonProps {}

export const DraftToolbarRemoveFormattingButton = memo(
  forwardRef<HTMLButtonElement, DraftToolbarRemoveFormattingButtonProps>(
    (props, ref) => {
      const getEditorState = useDraftToolbarEditorStateGetter();
      const setEditorState = useDraftToolbarEditorStateSetter();

      const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.preventDefault();
        const editorState = getEditorState();
        setEditorState(
          removeInlineStyles(editorState, getSelectedInlineStyles(editorState)),
        );
        props.onClick?.(ev);
      };

      return (
        <Tooltip
          title={
            <FormattedMessage
              id="TextArea.RemoveFormattingButton.Tooltip"
              defaultMessage="Clear Formatting"
              description="Text area clear formatting button tooltip text"
            />
          }
        >
          <IconButton size="small" {...props} ref={ref} onClick={handleClick}>
            <IcFormatClear fontSize="small" />
          </IconButton>
        </Tooltip>
      );
    },
  ),
);
