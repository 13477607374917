import { css } from '@emotion/react';
import clsx from 'clsx';
import { GroupBase, SingleValueProps } from 'react-select';
import { selectClasses } from './select-classes';

export const SingleValue = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: SingleValueProps<Option, IsMulti, Group>,
) => {
  const { children, className, getClassNames, innerProps } = props;

  return (
    <div
      css={css`
        grid-area: 1 / 1 / 2 / 3;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 2px;
      `}
      {...innerProps}
      className={clsx(
        selectClasses.singleValue,
        getClassNames('singleValue', props),
        className,
        innerProps?.className,
      )}
    >
      {children}
    </div>
  );
};
