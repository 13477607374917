import { OverridableComponent } from '@allganize/types';
import MuiTableBody from '@mui/material/TableBody';
import { forwardRef } from 'react';
import { TableBodyTypeMap } from './table-body-type-map';

// @ts-expect-error overridable component
export const TableBody: OverridableComponent<TableBodyTypeMap> = forwardRef(
  (props, ref) => {
    return <MuiTableBody data-testid="table-body" {...props} ref={ref} />;
  },
);
