import {
  listItemButtonClasses as muiListItemButtonClasses,
  ListItemButtonClassKey as MuiListItemButtonClassKey,
} from '@mui/material/ListItemButton';
import { omit } from 'lodash-es';

export type ListItemButtonClassKey = Exclude<
  MuiListItemButtonClassKey,
  'dense' | 'gutters' | 'divider'
>;
export type ListItemButtonClasses = Record<ListItemButtonClassKey, string>;

export const listItemButtonClasses: ListItemButtonClasses = omit(
  muiListItemButtonClasses,
  ['dense', 'gutters', 'divider'],
);
