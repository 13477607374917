import { useTheme } from '@allganize/ui-theme';
import { ClassNames, keyframes } from '@emotion/react';
import clsx from 'clsx';
import { camelCase } from 'lodash-es';
import { FunctionComponent } from 'react';
import {
  ToastContainer as ToastifyContainer,
  ToastContainerProps as ToastifyContainerProps,
} from 'react-toastify';
import { ToastTransition } from '../toast-transition';
import { ToastClasses, toastClasses } from './toast-container-classes';

const trackProgress = keyframes`
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
`;

interface ToastContainerProps
  extends Omit<
    ToastifyContainerProps,
    | 'className'
    | 'closeOnClick'
    | 'draggable'
    | 'draggableDirection'
    | 'draggablePercent'
    | 'hideProgressBar'
    | 'progress'
    | 'rtl'
    | 'theme'
    | 'stacked'
  > {
  className?: string;
  classes?: Partial<ToastClasses>;
}

const ToastContainerBase: FunctionComponent<ToastContainerProps> = props => {
  const theme = useTheme();
  const { className, classes, ...other } = props;

  return (
    <ToastifyContainer
      closeButton={false}
      closeOnClick={false}
      draggable={false}
      hideProgressBar
      theme="colored"
      rtl={theme.direction === 'rtl'}
      transition={ToastTransition}
      {...other}
      className={context =>
        clsx(
          toastClasses.root,
          context?.position &&
            toastClasses[
              camelCase(
                `position-${context?.position}`,
              ) as keyof typeof toastClasses
            ],
          { [toastClasses.rtl]: context?.rtl },
          classes?.root,
          context?.position &&
            classes?.[
              camelCase(`position-${context?.position}`) as keyof typeof classes
            ],
          { [classes?.rtl ?? '']: context?.rtl },
          className,
        )
      }
      toastClassName={context =>
        clsx(
          toastClasses.toast,
          context?.type &&
            toastClasses[
              camelCase(`toast-${context?.type}`) as keyof typeof toastClasses
            ],
          classes?.toast,
          context?.type &&
            classes?.[
              camelCase(`toast-${context?.type}`) as keyof typeof classes
            ],
        )
      }
      progressClassName={context =>
        clsx(
          toastClasses.progress,
          context?.defaultClassName?.includes('animated') &&
            toastClasses.progressAnimated,
          context?.defaultClassName?.includes('controlled') &&
            toastClasses.progressControlled,
          classes?.progress,
          context?.defaultClassName?.includes('animated') &&
            classes?.progressAnimated,
          context?.defaultClassName?.includes('controlled') &&
            classes?.progressControlled,
        )
      }
      bodyClassName={context => clsx(toastClasses.body, classes?.body)}
    />
  );
};

export const ToastContainer: FunctionComponent<ToastContainerProps> = props => {
  const theme = useTheme();
  const { classes, ...other } = props;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <ToastContainerBase
          icon={false}
          {...other}
          classes={{
            ...classes,
            root: cx(
              css`
                z-index: ${theme.zIndex.snackbar};
                transform: translate3d(0, 0, ${theme.zIndex.snackbar}px);
                position: fixed;
                width: 360px;
                box-sizing: border-box;
              `,
              classes?.root,
            ),
            positionTopLeft: cx(
              css`
                top: 20px;
                left: 20px;
              `,
              classes?.positionTopLeft,
            ),
            positionTopCenter: cx(
              css`
                top: 20px;
                left: 50%;
                transform: translateX(-50%);
              `,
              classes?.positionTopCenter,
            ),
            positionTopRight: cx(
              css`
                top: 20px;
                right: 20px;
              `,
              classes?.positionTopRight,
            ),
            positionBottomLeft: cx(
              css`
                bottom: 20px;
                left: 20px;
              `,
              classes?.positionBottomLeft,
            ),
            positionBottomCenter: cx(
              css`
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
              `,
              classes?.positionBottomCenter,
            ),
            positionBottomRight: cx(
              css`
                bottom: 20px;
                right: 20px;
              `,
              classes?.positionBottomRight,
            ),
            toast: cx(
              css`
                position: relative;
                touch-action: none;
                box-sizing: border-box;
                margin-bottom: 8px;
                border-radius: ${theme.radius.xs}px;
                background-color: ${theme.palette.background.paper};
                color: ${theme.palette.text.primary};
                border-left: 4px solid currentColor;
                box-shadow: ${theme.elevations[3]};
                overflow: hidden;
                cursor: default;
                direction: ${theme.direction === 'rtl' ? 'rtl' : 'ltr'};
                z-index: 0;
              `,
              classes?.toast,
            ),
            toastDefault: cx(
              css`
                color: ${theme.palette.text.primary};
              `,
              classes?.toastDefault,
            ),
            toastInfo: cx(
              css`
                color: ${theme.palette.info.main};
              `,
              classes?.toastInfo,
            ),
            toastSuccess: cx(
              css`
                color: ${theme.palette.success.main};
              `,
              classes?.toastSuccess,
            ),
            toastWarning: cx(
              css`
                color: ${theme.palette.warning.main};
              `,
              classes?.toastWarning,
            ),
            toastError: cx(
              css`
                color: ${theme.palette.error.main};
              `,
              classes?.toastError,
            ),
            progress: cx(
              css`
                background: currentColor;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 4px;
                z-index: ${theme.zIndex.snackbar};
                opacity: 0.7;
                transform-origin: left;
                border-bottom-left-radius: ${theme.radius.sm}px;
                [data-hidden='true'] & {
                  opacity: 0;
                }
              `,
              theme.direction === 'rtl' &&
                css`
                  right: 0;
                  left: initial;
                  transform-origin: right;
                  border-bottom-left-radius: 0;
                  border-bottom-right-radius: ${theme.radius.sm}px;
                `,
              classes?.progress,
            ),
            progressAnimated: cx(
              css`
                animation: ${trackProgress} linear 1 forwards;
              `,
              classes?.progressAnimated,
            ),
            progressControlled: cx(
              css`
                transition: transform 0.2s;
              `,
              classes?.progressControlled,
            ),
          }}
        />
      )}
    </ClassNames>
  );
};
