import { forwardRef } from 'react';
import { GroupBase } from 'react-select';
import ReactAsyncCreatable from 'react-select/async-creatable';
import { SelectRef } from '../select';
import { AsyncCreatableProps } from './async-creatable-type-map';
import { useSelect } from '../select/use-select';

interface AsyncCreatableType {
  <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: React.PropsWithoutRef<AsyncCreatableProps<Option, IsMulti, Group>> &
      React.RefAttributes<SelectRef<Option, IsMulti, Group>>,
  ): React.ReactNode;

  readonly $$typeof: symbol;
  displayName?: string;
}

// @ts-expect-error overridable component
export const AsyncCreatable: AsyncCreatableType = forwardRef(
  <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: AsyncCreatableProps<Option, IsMulti, Group>,
    ref: React.ForwardedRef<SelectRef<Option, IsMulti, Group>>,
  ) => {
    const selectProps = useSelect(props);

    return (
      <ReactAsyncCreatable<Option, IsMulti, Group> {...selectProps} ref={ref} />
    );
  },
);
