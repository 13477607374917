import './fonts.css';
import { createTheme } from '@allganize/alli-sdk/styles/theme';
import { ThemeProvider as BaseThemeProvider } from '@allganize/ui-theme';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { FunctionComponent, useMemo } from 'react';

interface ThemeProviderProps {
  children?: React.ReactNode;
  themeColor?: string;
}

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({
  children,
  themeColor,
}) => {
  const theme = useMemo(() => {
    if (!themeColor) return createTheme();
    return createTheme({
      primary: themeColor,
      bgColor: themeColor,
    });
  }, [themeColor]);

  return (
    <BaseThemeProvider theme={theme}>
      <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
    </BaseThemeProvider>
  );
};
