import { DraftDecoratorComponentProps } from '@allganize/draft-input';
import linkifyIt from 'linkify-it';
import { forwardRef } from 'react';
import { DraftLink, DraftLinkProps } from '../draft-link-plugin';

export interface DraftAutoLinkProps extends DraftLinkProps {
  extractor?(text: string): linkifyIt.Match[] | null;
}

export const DraftAutoLink = forwardRef<
  HTMLAnchorElement,
  DraftDecoratorComponentProps & DraftAutoLinkProps
>((props, ref) => {
  const { extractor, decoratedText, ...other } = props;
  const links = extractor?.(decoratedText);
  const href = links?.[0]?.url ?? undefined;

  return (
    <DraftLink decoratedText={decoratedText} href={href} {...other} ref={ref} />
  );
});
