import { useCallback, useRef } from 'react';
import { useEnhancedEffect } from '../use-enhanced-effect/use-enhanced-effect';

export const useEventCallback = <T extends (...args: any[]) => any>(
  fn: T,
): T => {
  const ref = useRef(fn);

  useEnhancedEffect(() => {
    ref.current = fn;
  });

  const cb = useCallback((...args: any[]) => ref.current(...args), []);
  return cb as T;
};
