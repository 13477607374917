import { Button } from '@allganize/ui-button';
import { IcError, IcRefresh } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
`;

export interface ChatListErrorStateProps {
  className?: string;
  onRetry?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ChatListErrorState: FunctionComponent<ChatListErrorStateProps> = ({
  className,
  onRetry,
}) => {
  const theme = useTheme();

  return (
    <Root className={className}>
      <IcError
        css={css`
          color: ${theme.palette.error.main};
          margin-bottom: 4px;
        `}
        fontSize="large"
      />

      <Text variant="title14">
        <FormattedMessage
          id="chat-list.error-state.title"
          defaultMessage="Something went wrong!"
          description="chat list error state title text"
        />
      </Text>

      <Text variant="body14">
        <FormattedMessage
          id="chat-list.error-state.description"
          defaultMessage="Please try again."
          description="chat list error state description text"
        />
      </Text>

      {onRetry && (
        <Button
          css={css`
            margin-top: 12px;
          `}
          color="default"
          variant="outlined"
          startIcon={<IcRefresh />}
          onClick={onRetry}
        >
          <FormattedMessage
            id="actions.retry"
            defaultMessage="Retry"
            description="chat list error state action text"
          />
        </Button>
      )}
    </Root>
  );
};
