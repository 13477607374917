import { KnowledgeBase } from '@allganize/knowledge-base-assistant';
import { AssistantDocumentFallbackClasses } from './assistant-document-fallback-classes';

export const enum AssistantDocumentUploadSource {
  KnowledgeBase = 'KnowledgeBase',
  Computer = 'Computer',
}

export interface AssistantDocumentFallbackProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'defaultValue'> {
  /**
   * Override or extend the styles applied to the component.
   */
  classes?: Partial<AssistantDocumentFallbackClasses>;
  defaultValue: Record<string, KnowledgeBase>;
  onApply?: (knowledgeBasesIds: (string | number)[]) => void;
  onOpen?: () => void;
  onAdd?: () => void;
  onUpload?: () => void;
}
