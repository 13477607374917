import { useContext } from 'react';
import invariant from 'tiny-invariant';
import { AlliClient } from '../core/alli-client/alli-client';
import { AlliClientContext } from './alli-client-context';

export const useAlliClient = (): AlliClient => {
  const { client } = useContext(AlliClientContext);
  invariant(client, 'AlliClient is not available');
  return client;
};
