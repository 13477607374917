/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import {
  SingleActionInputKnowledgeBaseFragment_CSVKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_GoogleDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_HWPKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_ImageKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSExcelKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSPPTKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_OldMSDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_PDFKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_TextKnowledgeBase_,
} from '../fragments/single-action-input-knowledge-base-fragment';
import { ErrorFragment } from '../fragments/error-fragment';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UploadKnowledgeFileWithTokenMutationVariables =
  UserQueryTypes.Exact<{
    file?: UserQueryTypes.InputMaybe<UserQueryTypes.Scalars['Upload']['input']>;
    fileName?: UserQueryTypes.InputMaybe<
      UserQueryTypes.Scalars['String']['input']
    >;
    filePath?: UserQueryTypes.InputMaybe<
      UserQueryTypes.Scalars['String']['input']
    >;
    where: UserQueryTypes.ProjectWhereUniqueInput;
    accessToken?: UserQueryTypes.InputMaybe<
      UserQueryTypes.Scalars['String']['input']
    >;
  }>;

export type UploadKnowledgeFileWithTokenMutation = {
  __typename: 'UserMutation';
} & {
  uploadKnowledgeFileWithToken: UserQueryTypes.Maybe<
    { __typename: 'UploadKnowledgeFileWithToken' } & {
      knowledgeBase: UserQueryTypes.Maybe<
        | ({
            __typename: 'CSVKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_CSVKnowledgeBase_)
        | ({
            __typename: 'GoogleDocsKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_GoogleDocsKnowledgeBase_)
        | ({
            __typename: 'HWPKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_HWPKnowledgeBase_)
        | ({
            __typename: 'ImageKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_ImageKnowledgeBase_)
        | ({
            __typename: 'MSDocsKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_MSDocsKnowledgeBase_)
        | ({
            __typename: 'MSExcelKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_MSExcelKnowledgeBase_)
        | ({
            __typename: 'MSPPTKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_MSPPTKnowledgeBase_)
        | ({
            __typename: 'OldMSDocsKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_OldMSDocsKnowledgeBase_)
        | ({
            __typename: 'PDFKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_PDFKnowledgeBase_)
        | ({
            __typename: 'TextKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_TextKnowledgeBase_)
      >;
      duplicates: UserQueryTypes.Maybe<
        Array<
          UserQueryTypes.Maybe<
            | ({
                __typename: 'CSVKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_CSVKnowledgeBase_)
            | ({
                __typename: 'GoogleDocsKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_GoogleDocsKnowledgeBase_)
            | ({
                __typename: 'HWPKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_HWPKnowledgeBase_)
            | ({
                __typename: 'ImageKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_ImageKnowledgeBase_)
            | ({
                __typename: 'MSDocsKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_MSDocsKnowledgeBase_)
            | ({
                __typename: 'MSExcelKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_MSExcelKnowledgeBase_)
            | ({
                __typename: 'MSPPTKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_MSPPTKnowledgeBase_)
            | ({
                __typename: 'OldMSDocsKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_OldMSDocsKnowledgeBase_)
            | ({
                __typename: 'PDFKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_PDFKnowledgeBase_)
            | ({
                __typename: 'TextKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_TextKnowledgeBase_)
          >
        >
      >;
      errors: UserQueryTypes.Maybe<
        Array<UserQueryTypes.Maybe<{ __typename: 'Error' } & ErrorFragment>>
      >;
    }
  >;
};

export const UploadKnowledgeFileWithTokenMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadKnowledgeFileWithTokenMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filePath' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectWhereUniqueInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accessToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadKnowledgeFileWithToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filePath' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filePath' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accessToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accessToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'knowledgeBase' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SingleActionInputKnowledgeBaseFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'duplicates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SingleActionInputKnowledgeBaseFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ErrorFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleActionInputKnowledgeBaseFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KnowledgeBase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GoogleDocsKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TextKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ImageKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MSDocsKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PDFKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MSExcelKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MSPPTKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OldMSDocsKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CSVKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'HWPKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ErrorFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'field' } },
          { kind: 'Field', name: { kind: 'Name', value: 'info' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadKnowledgeFileWithTokenMutation,
  UploadKnowledgeFileWithTokenMutationVariables
>;
