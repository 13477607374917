import {
  ContentBlock,
  EditorState,
  Modifier,
  SelectionState,
} from '@allganize/draft-input';
import {
  useDraftToolbarEditorStateGetter,
  useDraftToolbarEditorStateSetter,
} from '@allganize/draft-toolbar-plugin';
import { zodResolver } from '@hookform/resolvers/zod';
import { OrderedSet } from 'immutable';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, defineMessages, useIntl } from 'react-intl';
import { z } from 'zod';
import { DraftLinkPlugin } from '../draft-link-plugin';
import { createLinkAtSelection } from '../utils/create-link-at-selection';

export interface DraftToolbarLinkFormValues {
  url: string;
}

const defaultDraftToolbarLinkFormValues = {
  url: '',
};

const errorMessages = defineMessages({
  'url-required': {
    id: 'TextArea.LinkButton.URL.errors.required',
    defaultMessage: 'URL is a required field',
    description: 'Text area insert link button URL required error message',
  },
});

const createValidationSchema = (intl: IntlShape) => {
  return z.object({
    url: z
      .string({
        required_error: intl.formatMessage(errorMessages['url-required']),
        invalid_type_error: intl.formatMessage(errorMessages['url-required']),
      })
      .min(1, intl.formatMessage(errorMessages['url-required'])),
  }) satisfies z.ZodSchema<DraftToolbarLinkFormValues>;
};

export interface UseDraftToolbarLinkFormOptions {
  defaultValues?: DraftToolbarLinkFormValues;
  onSubmit?(): void;
}

export const useDraftToolbarLinkForm = ({
  defaultValues = defaultDraftToolbarLinkFormValues,
  onSubmit,
}: UseDraftToolbarLinkFormOptions) => {
  const intl = useIntl();
  const getEditorState = useDraftToolbarEditorStateGetter();
  const setEditorState = useDraftToolbarEditorStateSetter();
  const validationSchema = useMemo(() => createValidationSchema(intl), [intl]);

  const form = useForm<DraftToolbarLinkFormValues>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const { reset, setError } = form;

  const submit = async (values: DraftToolbarLinkFormValues) => {
    try {
      const editorState = getEditorState();
      const selection = editorState.getSelection();
      const contentState = editorState.getCurrentContent();

      if (selection.isCollapsed()) {
        const block = contentState.getBlockForKey(selection.getAnchorKey());
        let style: OrderedSet<string> | null = null;

        if (block) {
          style = block.getInlineStyleAt(selection.getAnchorOffset());
        }

        const newOffset = selection.getAnchorOffset() + values.url.length;
        const newSelection = selection
          .set('anchorOffset', newOffset)
          .set('focusOffset', newOffset) as SelectionState;

        const newContentState = contentState.createEntity(
          DraftLinkPlugin.ENTITY_TYPE,
          'MUTABLE',
          { url: values.url },
        );
        const entityKey = newContentState.getLastCreatedEntityKey();
        const newContentStateWithKink = Modifier.insertText(
          newContentState,
          selection,
          values.url,
          style || undefined,
          entityKey,
        );

        setEditorState(
          EditorState.forceSelection(
            EditorState.push(
              editorState,
              newContentStateWithKink,
              'insert-characters',
            ),
            newSelection,
          ),
        );

        onSubmit?.();
        reset();
        return;
      }

      const styles: string[] = [];
      const startKey = selection.getStartKey();
      const startBlock = contentState.getBlockForKey(startKey);
      const endKey = selection.getEndKey();

      if (startKey === endKey) {
        for (
          let i = selection.getStartOffset();
          i < selection.getEndOffset();
          i += 1
        ) {
          const style = startBlock.getInlineStyleAt(i).toArray();
          styles.push(...style);
        }
      } else {
        const endBlock = contentState.getBlockForKey(endKey);
        const blocks = contentState.getBlocksAsArray();
        const startBlockIndex = blocks.indexOf(startBlock);
        const endBlockIndex = blocks.indexOf(endBlock);
        const selectedBlocks: ContentBlock[] = blocks.slice(
          startBlockIndex,
          endBlockIndex + 1,
        );

        selectedBlocks.forEach(block => {
          const blockSize = block.getLength();
          let blockStart = 0;
          let blockEnd = blockSize;

          if (block === startBlock) {
            blockStart = selection.getStartOffset();
          } else if (block === endBlock) {
            blockEnd = selection.getEndOffset();
          }

          for (let i = blockStart; i < blockEnd; i += 1) {
            const style = block.getInlineStyleAt(i).toArray();
            styles.push(...style);
          }
        });
      }

      const newContentState = styles.reduce(
        (cs, style) => Modifier.applyInlineStyle(cs, selection, style),
        contentState,
      );

      const isBackwardSelection = selection.getIsBackward();

      // Ref anchor and focus -> https://developer.mozilla.org/en-US/docs/Web/API/Selection#glossary
      // We're swapping offset along with key of anchor and focus to handle multi line link insertion.
      // Related GitHub Issue ref: https://github.com/facebookarchive/draft-js/issues/2314
      const newSelection = isBackwardSelection
        ? selection.merge({
            anchorKey: selection.getFocusKey(),
            anchorOffset: selection.getFocusOffset(),
            focusKey: selection.getAnchorKey(),
            focusOffset: selection.getAnchorOffset(),
            isBackward: false,
          })
        : selection;

      const newEditorState = createLinkAtSelection(
        EditorState.forceSelection(
          EditorState.push(editorState, newContentState, 'change-inline-style'),
          newSelection,
        ),
        values.url,
      );
      setEditorState(newEditorState);
      onSubmit?.();
      reset();
    } catch (err) {
      if (err instanceof Error) {
        setError('root', { message: err.message });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }

      setError('root', {
        message: intl.formatMessage({
          id: 'MallyError.UNKNOWN',
          defaultMessage: 'Something went wrong. Please try again later.',
          description: 'MallyError.UNKNOWN',
        }),
      });
    }
  };

  return { form, submit };
};
