import { css } from '@emotion/react';
import clsx from 'clsx';
import { GroupBase, IndicatorsContainerProps } from 'react-select';
import { selectClasses } from './select-classes';

export const IndicatorsContainer = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: IndicatorsContainerProps<Option, IsMulti, Group>,
) => {
  const { children, className, getClassNames, innerProps, theme } = props;

  return (
    <div
      css={css`
        align-items: center;
        align-self: flex-start;
        display: flex;
        flex-shrink: 0;
        padding-left: 4px;
        padding-right: 12px;
        min-height: calc(${theme.spacing.controlHeight}px - 2px);
      `}
      {...innerProps}
      className={clsx(
        selectClasses.indicatorsContainer,
        getClassNames('indicatorsContainer', props),
        className,
      )}
    >
      {children}
    </div>
  );
};
