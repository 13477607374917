import { BlockRendererProps } from '@allganize/draft-input';
import { forwardRef } from 'react';
import { DraftVideoClasses } from './draft-video-classes';
import { DraftVideoBase } from './draft-video-base';

export interface DraftVideoBlockProps {
  classes?: Partial<DraftVideoClasses>;
  src?: string | null;
}

export const DraftVideo = forwardRef<
  HTMLDivElement,
  BlockRendererProps<DraftVideoBlockProps>
>((props, ref) => {
  const { blockProps } = props;

  return (
    <DraftVideoBase data-testid="draft-video" src={blockProps?.src} ref={ref} />
  );
});
