import React, { useEffect, useState } from 'react';

let globalId = 0;
const idPrefix = 'alli-id-';

/**
 * @todo remove this hook
 */
const useGlobalId = (): string => {
  const [id, setDefaultId] = useState<string | undefined>();

  useEffect(() => {
    if (id == null) {
      // Fallback to this default id when possible.
      // Use the incrementing value for client-side rendering only.
      // We can't use it server-side.
      // If you want to use random values please consider the Birthday Problem: https://en.wikipedia.org/wiki/Birthday_problem
      globalId += 1;
      setDefaultId(`${idPrefix}${globalId}`);
    }
  }, [id]);

  return id ?? idPrefix;
};

export const useId =
  typeof React['useId'] === 'function' ? React.useId : useGlobalId;
