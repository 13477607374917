import { Button, IconButton, LoadingButton } from '@allganize/ui-button';
import { Tooltip } from '@allganize/ui-tooltip';
import { IcCopy, IcEdit, IcReplay } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { Fragment, FunctionComponent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCopyToClipboard } from 'react-use';

import { finalOutputClasses } from './final-output-classes';
import { FinalOutputActionsProps } from './final-output-type-map';
import { DownloadFileDialog } from '../download-file-dialog/download-file-dialog';

export const FinalOutputActions: FunctionComponent<FinalOutputActionsProps> = ({
  classes,
  output,
  updated,
  updating,
  onCopy,
  onEdit,
  onRevert,
  downloadFileDialogComponent,
}) => {
  const intl = useIntl();
  const disabled = !output || updating;

  const [open, setOpen] = useState(false);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const [, copyToClipboard] = useCopyToClipboard();
  const copy = () => {
    copyToClipboard(output || '');
    onCopy();
  };

  const downloadFileDialog = () =>
    downloadFileDialogComponent ? (
      downloadFileDialogComponent(open, closeDialog)
    ) : (
      <DownloadFileDialog open onClose={closeDialog} />
    );

  const editButtonLabel = intl.formatMessage({
    id: 'single-action.final-output.edit',
    defaultMessage: 'Edit',
    description: 'single action final output edit tooltip text',
  });

  const copyButtonLabel = intl.formatMessage({
    id: 'single-action.final-output.copy',
    defaultMessage: 'Copy',
    description: 'single action final output copy tooltip text',
  });

  return (
    <Fragment>
      {open && downloadFileDialog()}
      <div
        className={clsx(finalOutputClasses.actions, classes?.actions)}
        css={css`
          display: flex;
          margin-top: 24px;
        `}
      >
        {updated && (
          <LoadingButton
            onClick={onRevert}
            disabled={disabled}
            loading={updating}
            startIcon={<IcReplay />}
            variant="outlined"
          >
            <FormattedMessage
              id="single-action.final-output.revert"
              defaultMessage="Revert to initial output"
              description="single action final output revert button text"
            />
          </LoadingButton>
        )}
        <div
          css={css`
            flex-grow: 1;
          `}
        />
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 12px;
          `}
        >
          <Tooltip placement="top" title={editButtonLabel}>
            <span>
              <IconButton
                variant="outlined"
                shape="rounded"
                size="medium"
                onClick={onEdit}
                disabled={disabled}
                aria-label={editButtonLabel}
              >
                <IcEdit fontSize="medium" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip placement="top" title={copyButtonLabel}>
            <span>
              <IconButton
                variant="outlined"
                shape="rounded"
                size="medium"
                onClick={copy}
                disabled={disabled}
                aria-label={copyButtonLabel}
              >
                <IcCopy />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              disabled ? (
                <FormattedMessage
                  id="single-action.final-output.download-file-tooltip"
                  defaultMessage="You can download the results when preview screen or using the app."
                  description="single action final output download file tooltip text"
                />
              ) : (
                ''
              )
            }
          >
            <span>
              <Button
                variant="outlined"
                onClick={openDialog}
                disabled={disabled}
                size="large"
              >
                <FormattedMessage
                  id="single-action.final-output.download-file"
                  defaultMessage="Download file"
                  description="single action final output download file button text"
                />
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
    </Fragment>
  );
};
