import { createSvgIcon } from '@allganize/ui-icons';

export const IcFileFallback = createSvgIcon(
  <>
    <path
      d="M4.8 22.5469H19.1077C19.9361 22.5469 20.6077 21.8753 20.6077 21.0469V7.69264C20.6077 7.29885 20.4528 6.92085 20.1765 6.64024L15.5099 1.90072C15.228 1.61437 14.8429 1.45312 14.4411 1.45312H4.8C3.97158 1.45312 3.3 2.1247 3.3 2.95312V21.0469C3.3 21.8753 3.97158 22.5469 4.8 22.5469Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.8625 2.95312C3.8625 2.43536 4.28224 2.01562 4.8 2.01562H14.4411C14.4631 2.01562 14.485 2.0164 14.5068 2.01793V5.57812C14.5068 6.71721 15.4302 7.64062 16.5693 7.64062H20.0438C20.0447 7.6579 20.0452 7.67525 20.0452 7.69264V21.0469C20.0452 21.5646 19.6255 21.9844 19.1077 21.9844H4.8C4.28224 21.9844 3.8625 21.5646 3.8625 21.0469V2.95312ZM19.2645 6.51562L15.6318 2.82621V5.57812C15.6318 6.09589 16.0515 6.51562 16.5693 6.51562H19.2645ZM4.8 0.890625C3.66092 0.890625 2.7375 1.81404 2.7375 2.95312V21.0469C2.7375 22.186 3.66092 23.1094 4.8 23.1094H19.1077C20.2468 23.1094 21.1702 22.186 21.1702 21.0469V7.69264C21.1702 7.15117 20.9573 6.63142 20.5774 6.24559L15.9108 1.50607C15.5231 1.11234 14.9936 0.890625 14.4411 0.890625H4.8Z"
      fill="black"
      fillOpacity="0.2"
    />
  </>,
  'ic-file-fallback',
);
