import {
  getListItemTextUtilityClass,
  listItemTextClasses as muiListItemTextClasses,
  ListItemTextClassKey as MuiListItemTextClassKey,
} from '@mui/material/ListItemText';
import { omit } from 'lodash-es';

export type ListItemTextClassKey =
  | Exclude<MuiListItemTextClassKey, 'dense' | 'inset'>
  | 'disabled'
  | 'insetSmall'
  | 'insetLarge';
export type ListItemTextClasses = Record<ListItemTextClassKey, string>;

export const listItemTextClasses: ListItemTextClasses = {
  ...omit(muiListItemTextClasses, ['dense', 'inset']),
  insetSmall: getListItemTextUtilityClass('insetSmall'),
  insetLarge: getListItemTextUtilityClass('insetLarge'),
  disabled: getListItemTextUtilityClass('disabled'),
};
