import { resolveComponentProps } from '@mui/base/utils';
import { forwardRef } from 'react';
import { DateField } from '../date-field';
import { useDatePickerDefaultizedProps } from '../date-picker/date-picker.shared';
import { renderDateViewCalendar } from '../date-view-renderers';
import { useMobilePicker } from '../internals/hooks/use-mobile-picker';
import { PickerViewRendererLookup } from '../internals/hooks/use-picker';
import { useLocaleText, useUtils } from '../internals/hooks/use-utils';
import { resolveDateFormat } from '../internals/utils/date-utils';
import { extractValidationProps } from '../internals/utils/validation/extract-validation-props';
import { validateDate } from '../internals/utils/validation/validate-date';
import { singleItemValueManager } from '../internals/utils/value-managers';
import { DateView } from '../models';
import { MobileDatePickerProps } from './mobile-date-picker-type-map';

export const MobileDatePicker = forwardRef<
  HTMLDivElement,
  MobileDatePickerProps
>((inProps, ref) => {
  const localeText = useLocaleText<Date>();
  const utils = useUtils<Date>();

  // Props with the default values common to all date pickers
  const defaultizedProps = useDatePickerDefaultizedProps<MobileDatePickerProps>(
    inProps,
    'MuiMobileDatePicker',
  );

  const viewRenderers: PickerViewRendererLookup<
    Date | null,
    DateView,
    any,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  > = {
    day: renderDateViewCalendar,
    month: renderDateViewCalendar,
    year: renderDateViewCalendar,
    ...defaultizedProps.viewRenderers,
  };

  // Props with the default values specific to the mobile variant
  const props = {
    ...defaultizedProps,
    viewRenderers,
    format: resolveDateFormat(utils, defaultizedProps, false),
    slots: {
      field: DateField,
      ...defaultizedProps.slots,
    },
    slotProps: {
      ...defaultizedProps.slotProps,
      field: (ownerState: any) => ({
        ...resolveComponentProps(defaultizedProps.slotProps?.field, ownerState),
        ...extractValidationProps(defaultizedProps),
        ref,
      }),
      toolbar: {
        hidden: false,
        ...defaultizedProps.slotProps?.toolbar,
      },
    },
  };

  // @ts-expect-error internal prop
  const { renderPicker } = useMobilePicker<DateView, typeof props>({
    props,
    valueManager: singleItemValueManager,
    valueType: 'date',
    getOpenDialogAriaText: localeText.openDatePickerDialogue,
    validator: validateDate,
  });

  return renderPicker();
});
