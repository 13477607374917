import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { documentViewerToolbarHeight } from './constants';
import { documentViewerToolbarSpacerClasses } from './document-viewer-toolbar-spacer-classes';
import { DocumentViewerToolbarSpacerProps } from './document-viewer-toolbar-spacer-type-map';

export const DocumentViewerToolbarSpacer = forwardRef<
  HTMLDivElement,
  DocumentViewerToolbarSpacerProps
>((props, ref) => {
  const { classes, ...other } = props;

  return (
    <div
      data-testid="document-viewer-toolbar-spacer"
      css={css`
        height: ${documentViewerToolbarHeight}px;
      `}
      {...other}
      ref={ref}
      className={clsx(
        documentViewerToolbarSpacerClasses.root,
        classes?.root,
        other.className,
      )}
    />
  );
});
