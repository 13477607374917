import { ListItemClassKey } from '@allganize/ui-list';
import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type FileListItemClassKey = ListItemClassKey;
export type FileListItemClasses = Record<FileListItemClassKey, string>;

export const fileListItemClasses: FileListItemClasses = generateUtilityClasses(
  'FileListItem',
  ['root', 'alignItemsFlexStart', 'container', 'padding', 'secondaryAction'],
);
