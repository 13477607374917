import {
  TableSortLabelClassKey as MuiTableSortLabelClassKey,
  tableSortLabelClasses as muiTableSortLabelClasses,
} from '@mui/material/TableSortLabel';

export type TableSortLabelClassKey = MuiTableSortLabelClassKey;
export type TableSortLabelClasses = Record<TableSortLabelClassKey, string>;

export const tableSortLabelClasses: TableSortLabelClasses =
  muiTableSortLabelClasses;
