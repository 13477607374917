import { EditorState } from '@allganize/draft-input';
import { uniq } from 'lodash-es';
import { getSelectedBlocks } from './get-selected-blocks';

export const getSelectedInlineStyles = (editorState: EditorState): string[] => {
  const selection = editorState.getSelection();

  if (selection.isCollapsed()) {
    try {
      return editorState.getCurrentInlineStyle().toArray();
    } catch (err) {
      return [];
    }
  }

  const selectedBlocks = getSelectedBlocks(
    editorState.getCurrentContent(),
    selection,
  );
  let result: string[] = [];

  if (selectedBlocks.length > 0) {
    selectedBlocks.forEach((block, i) => {
      let start = 0;
      let end = block.getLength();

      if (i === 0) {
        start = selection.getStartOffset();
      }

      if (i === selectedBlocks.length - 1) {
        end = selection.getEndOffset();
      }

      for (let j = start; j < end; j += 1) {
        result = [...result, ...block.getInlineStyleAt(j).toArray()];
      }
    });
  }

  return uniq(result);
};
