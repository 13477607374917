import { CircularProgress } from '@allganize/ui-circular-progress';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { Root } from '../components/root';

export const LoadingState = () => {
  return (
    <Root className="single-action__loading-state">
      <CircularProgress
        size="xl"
        css={css`
          margin-top: 32px;
          margin-bottom: 12px;
        `}
      />
      <Text variant="title16">
        <FormattedMessage
          id="single-action.loading-state.description"
          defaultMessage="Just a second"
          description="single action loading screen description"
        />
      </Text>
    </Root>
  );
};
