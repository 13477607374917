import { getWindow } from './get-window';
import { isDocumentElement } from './is-document-element';

export const normalizedHeight = (el: HTMLElement | typeof window): number => {
  if (isDocumentElement(el)) {
    return getWindow(el).innerHeight;
  }

  return el.clientHeight;
};
