import { UserQueryTypes } from '@allganize/alli-interfaces';
import { TypingContext } from '@allganize/alli-sdk-chat';
import { Types } from '@allganize/alli-sdk-interfaces';
import { ContentState } from '@allganize/draft-input';
import { useWindow } from '@allganize/hooks';
import { FileDropzoneProps } from '@allganize/ui-file-input';
import { AlliGraphQLError } from '@allganize/utils-graphql';
import { zodResolver } from '@hookform/resolvers/zod';
import { format, isValid } from 'date-fns';
import { filesize } from 'filesize';
import { compact, some } from 'lodash-es';
import { useContext, useEffect, useMemo } from 'react';
import { FieldPath, useForm } from 'react-hook-form';
import { IntlShape, defineMessages, useIntl } from 'react-intl';
import { z } from 'zod';
import { analytics } from '../analytics';
import { IntentValueType } from '../chat-form/chat-form-intent-selector';
import {
  CONVERSATION_STATE_ENDED,
  CONVERSATION_STATE_WAIT_USER_ANSWER,
} from '../constants/conversation';
import { mallyErrors } from '../i18n/mally-errors';
import { useChatFormAutocomplete } from './use-chat-form-autocomplete';
import { useSendChat } from './use-send-chat';
import { ConversationQuery } from '../graphql/queries/conversation-query';
import { ChatsQuery } from '../graphql/queries/chats-query';
import { useAssistantCampaign } from '../project/use-assistant-campaign';
import { useProject } from '../project/use-project';

import type { KnowledgeBase } from '@allganize/knowledge-base-assistant';

export interface ChatFormValues {
  message: string;
  file: File | null;
  date: Date | null;
  intent?: IntentValueType;
  knowledgeBases?: KnowledgeBase[];
}

type ChatFormErrorFieldName = FieldPath<ChatFormValues> | 'root';

const errorFieldNames: ChatFormErrorFieldName[] = [
  'root',
  'message',
  'file',
  'date',
  'knowledgeBases',
];

const isErrorFieldName = (field: any): field is ChatFormErrorFieldName =>
  errorFieldNames.includes(field);

const defaultValues: ChatFormValues = {
  message: '',
  file: null,
  date: null,
};

const errorMessages = defineMessages({
  fieldRequired: {
    id: 'form.errors.required',
    defaultMessage: 'This field is required.',
    description: 'Field required error message',
  },
  fieldInvalidFormat: {
    id: 'form.errors.invalid-format',
    defaultMessage: 'Invalid format',
    description: 'Field invalid format error message',
  },
  fileExtensions: {
    id: 'form.file.errors.type',
    defaultMessage:
      'Invalid file type. Allowed file types include the following: {extensions}',
    description: 'File extensions error message',
  },
  maxFileSize: {
    id: 'form.file.errors.max',
    defaultMessage: 'File too large (Max. {size})',
    description: 'File too large error message',
  },
});

export const dateFormat = 'yyyy-MM-dd';

const enabledFields: Record<keyof ChatFormValues, Types.ChatInputType[]> = {
  message: [
    Types.ChatInputType.BOOLEAN,
    Types.ChatInputType.NUMBER,
    Types.ChatInputType.STRING,
  ],
  file: [Types.ChatInputType.FILE, Types.ChatInputType.IMAGE],
  date: [Types.ChatInputType.DATETIME],
  intent: [
    Types.ChatInputType.BOOLEAN,
    Types.ChatInputType.NUMBER,
    Types.ChatInputType.STRING,
    Types.ChatInputType.FILE,
    Types.ChatInputType.IMAGE,
    Types.ChatInputType.DATETIME,
  ], // always enabled
  knowledgeBases: [
    Types.ChatInputType.BOOLEAN,
    Types.ChatInputType.NUMBER,
    Types.ChatInputType.STRING,
    Types.ChatInputType.FILE,
    Types.ChatInputType.IMAGE,
    Types.ChatInputType.DATETIME,
  ], // always enabled
};

export interface ChatFormValidation {
  file: {
    extensions?: string[];
    maxSize: {
      default: number;
      image: number;
    };
  };
}

interface ChatFormValidationExtended extends ChatFormValidation {
  nextInputType: Types.ChatInputType[];
  customValidation?: {
    regex: string;
    message?: string;
  };
}

const createValidationSchema = (
  intl: IntlShape,
  options: ChatFormValidationExtended,
  win?: typeof globalThis,
) => {
  const FileKlass = win?.File ?? File;
  const messageFieldEnabled =
    options.nextInputType.length === 0 ||
    options.nextInputType.findIndex(type =>
      enabledFields.message.includes(type),
    ) >= 0;
  const fileFieldEnabled =
    options.nextInputType.findIndex(type =>
      enabledFields.file.includes(type),
    ) >= 0;
  const imageFileOnly =
    fileFieldEnabled &&
    !options.nextInputType.includes(Types.ChatInputType.FILE);
  const dateFieldEnabled =
    options.nextInputType.findIndex(type =>
      enabledFields.date.includes(type),
    ) >= 0;
  const messageFieldOnly =
    messageFieldEnabled &&
    options.nextInputType.findIndex(
      type => !enabledFields.message.includes(type),
    ) < 0;
  const messageNumberOnly =
    messageFieldEnabled &&
    options.nextInputType.length > 0 &&
    options.nextInputType.findIndex(
      type => type !== Types.ChatInputType.NUMBER,
    ) < 0;
  const fileFieldOnly =
    fileFieldEnabled &&
    options.nextInputType.findIndex(
      type => !enabledFields.file.includes(type),
    ) < 0;
  const dateFieldOnly =
    dateFieldEnabled &&
    options.nextInputType.findIndex(
      type => !enabledFields.date.includes(type),
    ) < 0;

  return z
    .object({
      message: z.string().refine(schema => {
        if (!messageNumberOnly) {
          return true;
        }

        if (!schema) {
          return true;
        }

        const numeric = Number(schema);
        return !Number.isNaN(numeric);
      }, intl.formatMessage(errorMessages['fieldInvalidFormat'])),
      file: z
        .union([z.instanceof(File), z.instanceof(FileKlass)], {
          invalid_type_error: intl.formatMessage(
            errorMessages['fieldInvalidFormat'],
          ),
        })
        .nullable()
        .refine(schema => {
          if (!schema) {
            return true;
          }

          if (!imageFileOnly) {
            return true;
          }

          return schema.type.startsWith('image/');
        }, intl.formatMessage(errorMessages['fieldInvalidFormat']))
        .refine(schema => {
          if (!schema) {
            return true;
          }

          if (
            !(options.file.extensions && options.file.extensions.length > 0)
          ) {
            return true;
          }

          const extension = schema.name.split('.').pop();
          return (
            extension &&
            options.file.extensions.includes(`.${extension.toLowerCase()}`)
          );
        }, intl.formatMessage(errorMessages['fileExtensions'], { type: options.file.extensions?.join(', ') }))
        .refine(
          schema => {
            if (!fileFieldEnabled) {
              return true;
            }

            if (!schema) {
              return true;
            }

            if (schema.type.startsWith('image/')) {
              return schema.size <= options.file.maxSize.image;
            }

            return schema.size <= options.file.maxSize.default;
          },
          schema => {
            if (schema?.type.startsWith('image/')) {
              return {
                message: intl.formatMessage(errorMessages['maxFileSize'], {
                  size: filesize(options.file.maxSize.image, {
                    output: 'string',
                    locale: intl.locale,
                    localeOptions: intl.formats.date,
                  }),
                }),
              };
            }

            return {
              message: intl.formatMessage(errorMessages['maxFileSize'], {
                size: filesize(options.file.maxSize.default, {
                  output: 'string',
                  locale: intl.locale,
                  localeOptions: intl.formats.date,
                }),
              }),
            };
          },
        ),
      date: z
        .date()
        .nullable()
        .refine(schema => {
          if (!dateFieldEnabled) {
            return true;
          }

          if (!schema) {
            return true;
          }

          return isValid(schema);
        }, intl.formatMessage(errorMessages['fieldInvalidFormat'])),
      intent: z.string().optional().nullable(),
      knowledgeBases: z
        .object({
          id: z.union([z.string(), z.number()]),
          type: z.string(),
          fileName: z.string(),
          description: z.string().optional().nullable(),
        })
        .array()
        .optional()
        .nullable(),
    })
    .refine(
      schema => {
        if (!messageFieldEnabled && !fileFieldEnabled && !dateFieldEnabled) {
          return true;
        }

        if (fileFieldEnabled && schema.file !== null) {
          return true;
        }

        if (dateFieldEnabled && schema.date !== null) {
          return true;
        }

        if (messageFieldEnabled && schema.message.replace(/\s/g, '')) {
          return true;
        }

        return false;
      },
      {
        path: (fileFieldOnly && ['file']) ||
          (dateFieldOnly && ['date']) ||
          (messageFieldOnly && ['message']) || ['root'],
        message: intl.formatMessage(errorMessages['fieldRequired']),
      },
    )
    .refine(
      schema => {
        if (!options.customValidation) {
          return true;
        }

        const regExp = new RegExp(options.customValidation.regex);

        if (dateFieldEnabled && schema.date !== null) {
          return regExp.test(format(schema.date, dateFormat));
        }

        if (messageFieldEnabled) {
          return regExp.test(schema.message);
        }

        return true;
      },
      schema => {
        return {
          path:
            dateFieldEnabled && schema.date !== null ? ['date'] : ['message'],
          message:
            options.customValidation?.message ??
            intl.formatMessage(errorMessages['fieldInvalidFormat']),
        };
      },
    );
};

export interface UseChatFormOptions {
  validation: ChatFormValidation;
  conversation: ConversationQuery['conversation'];
  chatListData?: ChatsQuery;
}

export const useChatForm = ({
  validation,
  conversation,
  chatListData,
}: UseChatFormOptions) => {
  const intl = useIntl();
  const { project } = useProject();
  const { ref: formRef, window: win } = useWindow();
  const { sendChat } = useSendChat();
  const { isAssistantCampaign } = useAssistantCampaign();

  const conversationEnded =
    conversation?.state &&
    CONVERSATION_STATE_ENDED.includes(conversation.state);
  const waitUserAnswer =
    conversation?.state &&
    CONVERSATION_STATE_WAIT_USER_ANSWER.includes(conversation.state);
  const hasNonCompletedEdges = chatListData?.chats?.edges.some(
    edge =>
      edge?.node?.__typename === 'BotChat' && edge.node.completed === false,
  );
  const lastEdge = chatListData?.chats?.edges
    ? chatListData.chats.edges[chatListData.chats.edges.length - 1]
    : null;
  const lastChat = lastEdge?.node;
  const allowKnowledgeBaseFile =
    lastChat?.__typename === 'BotChat' ? lastChat.allowKnowledgeBaseFile : null;
  const nextInputType = useMemo(
    () =>
      lastChat && lastChat.__typename !== 'EventChat'
        ? compact(lastChat.nextInputType)
        : [],
    [lastChat],
  );
  const messageFieldEnabled =
    nextInputType.length === 0 ||
    nextInputType.findIndex(type => enabledFields.message.includes(type)) >= 0;
  const fileFieldEnabled =
    nextInputType.findIndex(type => enabledFields.file.includes(type)) >= 0;
  const dateFieldEnabled =
    nextInputType.findIndex(type => enabledFields.date.includes(type)) >= 0;
  const assistantEnabled =
    isAssistantCampaign(conversation?.llmApp?.campaign?.id) &&
    !project?.useCustomAssistantEntryLlmApp;
  const disabled =
    conversationEnded ||
    hasNonCompletedEdges ||
    !(messageFieldEnabled || fileFieldEnabled || dateFieldEnabled);
  const isGenerating =
    lastChat?.__typename === 'BotChat' &&
    !!lastChat.generatingState &&
    lastChat.generatingState !== UserQueryTypes.SearchContextStep.COMPLETE;

  const validationExtended = useMemo<ChatFormValidationExtended>(
    () => ({
      ...validation,
      file: {
        ...validation.file,
        extensions: (
          allowKnowledgeBaseFile?.extensions ?? validation.file.extensions
        )?.map(ext => ext.toLowerCase()),
        maxSize:
          allowKnowledgeBaseFile?.maxSize === null ||
          typeof allowKnowledgeBaseFile?.maxSize === 'undefined'
            ? validation.file.maxSize
            : {
                default: allowKnowledgeBaseFile.maxSize,
                image: allowKnowledgeBaseFile.maxSize,
              },
      },
      nextInputType,
      customValidation:
        lastChat?.__typename === 'BotChat' &&
        lastChat.saveAsVariable?.validationCustom
          ? {
              regex: lastChat.saveAsVariable.validationCustom,
              message:
                lastChat.saveAsVariable.validationFailedMessage ?? undefined,
            }
          : undefined,
    }),
    [allowKnowledgeBaseFile, lastChat, nextInputType, validation],
  );

  const validationSchema = useMemo(
    () =>
      zodResolver(
        createValidationSchema(intl, validationExtended, win ?? undefined),
      ),
    [intl, validationExtended, win],
  );

  const form = useForm<ChatFormValues>({
    mode: 'all',
    defaultValues,
    resolver: validationSchema,
  });

  const {
    formState: { isSubmitting },
    resetField,
    setError,
    setValue,
    watch,
  } = form;
  const formDisabled = disabled || isSubmitting || isGenerating;
  const message = watch('message');

  const { onTyping } = useContext(TypingContext);

  useEffect(() => {
    const subscription = watch(values => onTyping(some(values, v => !!v)));
    return () => subscription.unsubscribe();
  }, [onTyping, watch]);

  const acceptImage = nextInputType.includes(Types.ChatInputType.IMAGE);
  const acceptFile = nextInputType.includes(Types.ChatInputType.FILE);
  const acceptImageOnly = acceptImage && !acceptFile;
  const accept = acceptImageOnly ? { 'image/*': [] } : undefined;

  const dropzoneOptions = {
    accept,
    maxFiles: 1,
    noClick: true,
  } satisfies FileDropzoneProps;

  const submit = async (values: ChatFormValues) => {
    try {
      if (!conversation) {
        // conversation not found
        throw new Error(intl.formatMessage(mallyErrors.UNKNOWN));
      }

      analytics.track(
        'conversation-detail::chat-input__send-chat-button.click',
        {
          conversationId: conversation.id,
          appId: conversation.llmApp?.id,
        },
      );

      const createdAt = Date.now();

      let message = values.message;

      if (dateFieldEnabled && values.date !== null) {
        message = format(values.date, dateFormat);
      }

      const result = await sendChat(
        {
          knowledgeBaseIds: values.knowledgeBases?.map(kb => kb.id),
          fallbackAction:
            values.intent === 'default' ? undefined : values.intent,
          conversationId: conversation.id,
          message: message || undefined,
          mediaInput: values.file?.type.startsWith('image/')
            ? {
                file: values.file,
                mediaType: Types.MediaTypeEnum.IMAGE,
                metaInfo: JSON.stringify({
                  lastModified: values.file.lastModified,
                  lastModifiedDate: (values.file as any).lastModifiedDate,
                  name: values.file.name,
                  size: values.file.size,
                  type: values.file.type,
                }),
              }
            : undefined,
          fileInput:
            !values.file || values.file?.type.startsWith('image/')
              ? undefined
              : {
                  file: values.file,
                  metaInfo: JSON.stringify({
                    lastModified: values.file.lastModified,
                    lastModifiedDate: (values.file as any).lastModifiedDate,
                    name: values.file.name,
                    size: values.file.size,
                    type: values.file.type,
                  }),
                },
        },
        [
          {
            __typename: 'UserChat',
            id: `optimistic-${createdAt}`,
            message,
            messageContentState: ContentState.createFromText(message, '\n'),
            createdAt,
            nextInputType: [],
            useAutoComplete: false,
            hidden: null,
            knowledgeBases:
              values.knowledgeBases?.map(kb => ({
                __typename: kb.type as any,
                id: kb.id,
                title: kb.fileName,
              })) ?? null,
            media: values.file?.type.startsWith('image/')
              ? {
                  __typename: 'Media',
                  id: `optimistic-${createdAt}-media`,
                  mediaType: 'IMAGE',
                  url: URL.createObjectURL(values.file),
                  metaInfo: JSON.stringify({
                    lastModified: values.file.lastModified,
                    lastModifiedDate: (values.file as any).lastModifiedDate,
                    name: values.file.name,
                    size: values.file.size,
                    type: values.file.type,
                  }),
                  width: null,
                  height: null,
                }
              : null,
            file:
              !values.file || values.file.type.startsWith('image/')
                ? null
                : {
                    __typename: 'File',
                    id: `optimistic-${createdAt}-file`,
                    fileId: `optimistic-${createdAt}-file`,
                    url: URL.createObjectURL(values.file),
                    metaInfo: JSON.stringify({
                      lastModified: values.file.lastModified,
                      lastModifiedDate: (values.file as any).lastModifiedDate,
                      name: values.file.name,
                      size: values.file.size,
                      type: values.file.type,
                    }),
                    createdAt: new Date(createdAt).toISOString(),
                    modifiedAt: new Date(createdAt).toISOString(),
                  },
            formValues: null,
          },
        ],
      );

      if (!result?.data) {
        throw new Error(intl.formatMessage(mallyErrors.UNKNOWN));
      }

      if ('trySendChat' in result.data) {
        if (result.data.trySendChat?.chat) {
          resetField('message');
          resetField('file');
          resetField('date');
          resetField('knowledgeBases');
          return;
        }

        if (
          result.data.trySendChat?.errors &&
          result.data.trySendChat.errors.length > 0
        ) {
          throw new AlliGraphQLError(result.data.trySendChat.errors);
        }

        throw new Error(intl.formatMessage(mallyErrors.UNKNOWN));
      }

      if (result.data.sendChat?.chat) {
        resetField('message');
        resetField('file');
        resetField('date');
        resetField('knowledgeBases');
        return;
      }

      if (
        result.data.sendChat?.errors &&
        result.data.sendChat.errors.length > 0
      ) {
        throw new AlliGraphQLError(result.data.sendChat.errors);
      }

      throw new Error(intl.formatMessage(mallyErrors.UNKNOWN));
    } catch (err) {
      if (err instanceof AlliGraphQLError) {
        const errorGroups = err.groupErrorsByField({
          intl,
          isErrorField: isErrorFieldName,
        });

        // Set the errors in the form using setError
        Object.entries(errorGroups).forEach(([field, errors]) => {
          setError(field as ChatFormErrorFieldName, {
            message: errors?.[0].message,
          });
        });

        return;
      }

      if (err instanceof Error) {
        setError('root', { message: err.message });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }

      setError('root', {
        message: intl.formatMessage(mallyErrors.UNKNOWN),
      });
    }
  };

  const autocomplete = useChatFormAutocomplete({
    message,
    messageFieldEnabled,
    formDisabled,
    setValue,
  });

  const knowledgeBases = watch('knowledgeBases');
  const assistantPlaceholder = knowledgeBases?.length
    ? intl.formatMessage({
        id: 'chat-form.assistant-knowledge-base.placeholder',
        defaultMessage: 'How can I assist you with the document?',
        description:
          'assistant chat form placeholder when knowledge base is selected',
      })
    : intl.formatMessage({
        id: 'chat-form.assistant.placeholder',
        defaultMessage: 'What task can I help you with?',
        description: 'assistant chat form placeholder',
      });

  const placeholder = assistantEnabled
    ? assistantPlaceholder
    : (lastChat?.__typename === 'BotChat' ||
        lastChat?.__typename === 'CarouselChat') &&
      lastChat.placeholder;

  return {
    autocomplete,
    dateFieldEnabled,
    disabled,
    dropzoneOptions,
    fileFieldEnabled,
    assistantEnabled,
    form,
    formDisabled,
    formRef,
    messageFieldEnabled,
    nextInputType,
    placeholder,
    submit,
    waitUserAnswer,
    isGenerating,
  };
};
