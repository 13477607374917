import { OverridableComponent } from '@allganize/types';
import MuiModal from '@mui/material/Modal';
import { forwardRef } from 'react';
import { ModalBackdrop, ModalRoot } from './modal-slots';
import { ModalTypeMap } from './modal-type-map';

// @ts-expect-error overridable component
export const Modal: OverridableComponent<ModalTypeMap> = forwardRef(
  (props, ref) => {
    const { slots, ...other } = props;

    return (
      <MuiModal
        data-testid="modal"
        {...other}
        ref={ref}
        slots={{
          root: ModalRoot,
          backdrop: ModalBackdrop,
          ...slots,
        }}
      />
    );
  },
);
