import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type ChatOptionInfoListItemClassKey = 'root' | 'carousel';
export type ChatOptionInfoListItemClasses = Record<
  ChatOptionInfoListItemClassKey,
  string
>;

export const chatOptionInfoListItemClasses: ChatOptionInfoListItemClasses =
  generateUtilityClasses('ChatOptionInfoListItem', ['root', 'carousel']);
