import { Types } from '@allganize/alli-sdk-interfaces';
import { ApolloQueryResult } from '@apollo/client/core';
import { createContext } from 'react';
import { ProjectFragment } from '../graphql/fragments/project-fragment';
import { ProjectQuery } from '../graphql/queries/project-query';
import { ThemeOptions } from '../styles/theme';

export interface ProjectContextValue {
  project: Types.Maybe<ProjectFragment>;
  refetch(): Promise<ApolloQueryResult<ProjectQuery>>;
  themeOptions: Pick<
    ThemeOptions,
    | 'alignment'
    | 'primary'
    | 'horizontalPadding'
    | 'verticalPadding'
    | 'messagePreview'
    | 'messenger'
    | 'bgColor'
  >;
}

export const ProjectContext = createContext<ProjectContextValue>({
  project: null,
  refetch: () => Promise.reject('ProjectContext.refetch is not implemented'),
  themeOptions: {},
});
