import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
} from '@allganize/ui-form';
import { Radio, RadioGroup } from '@allganize/ui-radio';
import { css } from '@emotion/react';
import { Fragment, forwardRef } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { FormValue } from './use-form-value-form';
import { ChatMedia } from '../chat-media/chat-media';

interface RadioFormValueProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formId: string;
  formState: UseFormStateReturn<TFieldValues>;
  readOnly?: boolean;
  value: FormValue;
}

// custom typing in order to make component generic
interface RadioFormValueType {
  <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >(
    props: React.PropsWithoutRef<RadioFormValueProps<TFieldValues, TName>> &
      React.RefAttributes<HTMLDivElement>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

// @ts-expect-error overridable component
export const RadioFormValue: RadioFormValueType = forwardRef(
  (
    { field: fieldProp, fieldState, formId, formState, readOnly, value },
    ref,
  ) => {
    const { ref: fieldRef, ...field } = fieldProp;
    const labelId = `form-${formId}-${field.name}`;

    return (
      <FormControl
        fullWidth
        required={value.mandatory}
        error={fieldState.invalid}
        disabled={readOnly}
        ref={ref}
      >
        <FormLabel id={labelId}>{value.display}</FormLabel>

        <RadioGroup
          {...field}
          aria-labelledby={labelId}
          onChange={(ev, value) => {
            field.onChange(Number(value));
          }}
        >
          {value.checkboxOptions.map(option => {
            return (
              <FormControlLabel
                key={option.value}
                control={<Radio />}
                value={option.value}
                label={
                  <Fragment>
                    {option.label}
                    {option.data.media && (
                      <ChatMedia
                        media={option.data.media}
                        rootProps={{
                          onBlur: e => e.stopPropagation(),
                        }}
                        css={css`
                          margin-top: 4px;
                          margin-bottom: 4px;
                        `}
                      />
                    )}
                  </Fragment>
                }
              />
            );
          })}
        </RadioGroup>

        {fieldState.error?.message && (
          <FormHelperText>{fieldState.error.message}</FormHelperText>
        )}
      </FormControl>
    );
  },
);
