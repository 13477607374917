import { css } from '@emotion/react';
import MuiListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { forwardRef } from 'react';
import { ListItemSecondaryActionProps } from './list-item-secondary-action-type-map';

export const ListItemSecondaryAction = forwardRef<
  HTMLDivElement,
  ListItemSecondaryActionProps
>((props, ref) => {
  return (
    <MuiListItemSecondaryAction
      data-testid="list-item-secondary-action"
      css={css`
        right: 12px;
      `}
      {...props}
      ref={ref}
    />
  );
});
