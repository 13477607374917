import { compact } from 'lodash-es';
import { KnowledgeBasesQuery } from '../../graphql/queries/knowledge-bases-query';

import type { KnowledgeBase } from '../types/types';

export const getKnowledgeBaseList = (data: KnowledgeBasesQuery | undefined) => {
  return (data?.knowledgeBasesByCursor?.edges || []).reduce<KnowledgeBase[]>(
    (acc, kb) => {
      if (!kb?.node) return acc;

      const description =
        kb.node.__typename === 'GoogleDocsKnowledgeBase'
          ? kb.node.title
          : compact([
              kb.node.owner?.name,
              new Date(kb.node.lastModifiedAt).toLocaleDateString(),
            ]).join('・');

      return [
        ...acc,
        {
          id: kb.node.id,
          type: kb.node.__typename,
          fileName: kb.node.title,
          description,
        },
      ];
    },
    [],
  );
};
