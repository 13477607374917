import { useProject } from '@allganize/alli-app-market-project';
import { UserQueryTypes } from '@allganize/alli-interfaces';
import { Outlet } from 'react-router-dom';
import { ConversationLayout } from '../conversation/conversation-layout';
import { LNBLayout } from './templates/lnb-layout';

export const Layout = () => {
  const project = useProject();
  const newHomeEnabled = project.enabledAddOns?.includes(
    UserQueryTypes.AddOnType.WA_APP_LAUNCHER_NEW_HOME,
  );

  if (!newHomeEnabled) {
    return <Outlet />;
  }

  return (
    <ConversationLayout>
      <LNBLayout>
        <Outlet />
      </LNBLayout>
    </ConversationLayout>
  );
};
