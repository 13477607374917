import {
  SuspenseQueryHookOptions,
  useSuspenseQuery,
} from '@apollo/client/react';
import { FunctionComponent } from 'react';
import { ProjectContext } from './contexts/project-context';
import {
  ProjectQuery,
  ProjectQueryDocument,
  ProjectQueryVariables,
} from './graphql/project-query';

interface ProjectProviderProps
  extends SuspenseQueryHookOptions<ProjectQuery, ProjectQueryVariables> {
  children?: React.ReactNode;
}

export const ProjectProvider: FunctionComponent<
  ProjectProviderProps
> = props => {
  const { children } = props;
  const { data } = useSuspenseQuery(ProjectQueryDocument, props);

  if (!data.project) {
    throw new Error('Project not found');
  }

  return (
    <ProjectContext.Provider value={data.project}>
      {children}
    </ProjectContext.Provider>
  );
};
