import { OverridableComponent } from '@allganize/types';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import { forwardRef } from 'react';
import { ChipBase } from '../chip-base';
import { outlinedChipClasses } from './outlined-chip.classes';
import { OutlinedChipTypeMap } from './outlined-chip.types';

// @ts-expect-error overridable component
export const OutlinedChip: OverridableComponent<OutlinedChipTypeMap> =
  forwardRef((props, ref) => {
    const { classes, clickable: clickableProp, onClick, ...other } = props;
    const theme = useTheme();
    const clickable = clickableProp !== false && onClick ? true : clickableProp;

    return (
      <ClassNames>
        {({ css, cx }) => (
          <ChipBase
            data-testid="outlined-chip"
            clickable={clickable}
            onClick={onClick}
            {...other}
            ref={ref}
            classes={{
              ...outlinedChipClasses,
              ...classes,
              root: cx(
                css`
                  ${theme.typography.body14}
                  height: 24px;
                  border-radius: ${theme.radius.xs}px;
                  transition: ${theme.transitions.create([
                    'background-color',
                    'border-color',
                    'color',
                  ])};
                  padding: 1px 5px;
                  gap: 4px;
                  color: ${theme.palette.foreground.default};
                  border: 1px solid transparent;
                  border-color: ${theme.palette.borderInteractive.gray.default};

                  &.${outlinedChipClasses.disabled} {
                    color: ${theme.palette.foreground.gray.disabled};
                    background-color: transparent;
                    border-color: ${theme.palette.border.disabled};

                    .${outlinedChipClasses.avatar} {
                      opacity: ${theme.palette.action.disabledOpacity};
                    }

                    .${outlinedChipClasses.deleteIcon} {
                      background-color: transparent;
                    }
                  }

                  .${outlinedChipClasses.avatar} {
                    width: 16px;
                    height: 16px;
                    font-size: 12px;
                  }

                  .${outlinedChipClasses.icon} {
                    font-size: 16px;
                    color: inherit;
                  }

                  .${outlinedChipClasses.deleteIcon} {
                    transition: ${theme.transitions.create([
                      'background-color',
                      'color',
                    ])};
                    background-color: transparent;
                    border-radius: ${theme.radius.xs}px;
                    font-size: 16px;

                    &:hover {
                      background-color: ${theme.palette.backgroundInteractive
                        .grayGhostAlpha.hover};
                    }

                    &:active {
                      background-color: ${theme.palette.backgroundInteractive
                        .grayGhostAlpha.pressed};
                    }
                  }
                `,
                outlinedChipClasses.root,
                classes?.root,
              ),
              disabled: cx(outlinedChipClasses.disabled, classes?.disabled),
              clickable: cx(
                css`
                  &:hover {
                    background-color: ${theme.palette.backgroundInteractive
                      .grayGhostAlpha.hover};
                    border-color: ${theme.palette.borderInteractive.gray.hover};
                  }

                  &:active {
                    background-color: ${theme.palette.backgroundInteractive
                      .grayGhostAlpha.pressed};
                    border-color: ${theme.palette.borderInteractive.gray.hover};
                  }
                `,
                outlinedChipClasses.clickable,
                classes?.clickable,
              ),
              deletable: cx(outlinedChipClasses.deletable, classes?.deletable),
              avatar: cx(outlinedChipClasses.avatar, classes?.avatar),
              icon: cx(outlinedChipClasses.icon, classes?.icon),
              label: cx(outlinedChipClasses.label, classes?.label),
              deleteIcon: cx(
                outlinedChipClasses.deleteIcon,
                classes?.deleteIcon,
              ),
              focusVisible: cx(
                outlinedChipClasses.focusVisible,
                classes?.focusVisible,
              ),
            }}
          />
        )}
      </ClassNames>
    );
  });
