import { Divider, DividerProps } from '@allganize/ui-divider';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import { forwardRef } from 'react';

export interface ChatDividerProps
  extends Omit<DividerProps<'div'>, 'children'> {
  children: NonNullable<React.ReactNode>;
}

export const ChatDivider = forwardRef<HTMLDivElement, ChatDividerProps>(
  (props, ref) => {
    const { classes, ...other } = props;
    const theme = useTheme();

    return (
      <ClassNames>
        {({ css, cx }) => (
          <Divider
            data-testid="chat-divider"
            component="div"
            {...other}
            ref={ref}
            classes={{
              ...classes,
              root: cx(
                css`
                  padding: 0 16px;
                  white-space: normal;
                `,
                classes?.root,
              ),
              wrapper: cx(
                css`
                  ${theme.typography.body12}
                  max-width: 252px;
                  flex-shrink: 0;
                  color: ${theme.palette.text.secondary};
                  // TODO: [ALL-14042] Sync the Divider component style with the design guide
                  padding-left: 8px;
                  padding-right: 8px;
                `,
                classes?.wrapper,
              ),
            }}
          />
        )}
      </ClassNames>
    );
  },
);
