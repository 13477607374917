import { Scroller, scrollerClasses } from '@allganize/ui-scroller';
import {
  Table as UiTable,
  TableBody as UiTableBody,
  TableCell as UiTableCell,
  TableHead as UITableHead,
  TableRow as UiTableRow,
} from '@allganize/ui-table';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import React, { forwardRef } from 'react';
import {
  ReactMarkdownProps,
  TableDataCellProps,
  TableHeaderCellProps,
  TableRowProps,
} from 'react-markdown/lib/ast-to-react';

export const Table = forwardRef<
  HTMLTableElement,
  React.TableHTMLAttributes<HTMLTableElement> & ReactMarkdownProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, ...other } = props;
  const theme = useTheme();

  return (
    <Scroller
      css={css`
        border: 1px solid ${theme.palette.border.divider.default};
        border-radius: ${theme.radius.sm}px;
        overflow: hidden;
        margin: 8px 0;

        .${scrollerClasses.content} {
          width: max-content;
          min-width: 100%;
        }
      `}
      forceVisible="x"
      autoHide={false}
    >
      <UiTable {...other} ref={ref} />
    </Scroller>
  );
});

export const TableHead = forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & ReactMarkdownProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, ...other } = props;

  return <UITableHead {...other} ref={ref} />;
});

export const TableBody = forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & ReactMarkdownProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, ...other } = props;

  return <UiTableBody {...other} ref={ref} />;
});

export const TableRow = forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & TableRowProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, isHeader, ...other } =
    props;

  return <UiTableRow {...other} ref={ref} />;
});

export const TableHeaderCell = forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & TableHeaderCellProps
>((props, ref) => {
  const {
    align,
    node,
    sourcePosition,
    index,
    siblingCount,
    isHeader,
    ...other
  } = props;

  return <UiTableCell component="th" {...other} ref={ref} variant="head" />;
});

export const TableCell = forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & TableDataCellProps
>((props, ref) => {
  const {
    align,
    node,
    sourcePosition,
    index,
    siblingCount,
    isHeader,
    ...other
  } = props;

  return <UiTableCell {...other} ref={ref} variant="body" />;
});
