import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type KnowledgeBaseRecommendedQuestionListClassKey = 'root';

export type KnowledgeBaseRecommendedQuestionListClasses = Record<
  KnowledgeBaseRecommendedQuestionListClassKey,
  string
>;

export const knowledgeBaseRecommendedQuestionListClasses: KnowledgeBaseRecommendedQuestionListClasses =
  generateUtilityClasses('KnowledgeBaseRecommendedQuestionList', ['root']);
