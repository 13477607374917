import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type ChatOptionInfoListClassKey = 'root' | 'carousel' | 'wrap';
export type ChatOptionInfoListClasses = Record<
  ChatOptionInfoListClassKey,
  string
>;

export const chatOptionInfoListClasses: ChatOptionInfoListClasses =
  generateUtilityClasses('ChatOptionInfoList', ['root', 'carousel', 'wrap']);
