export interface FormValueValidation {
  formValues: {
    fileValue: {
      maxSize: {
        default: number;
        image: number;
      };
    };
  };
}

export const defaultFormValueValidation: FormValueValidation = {
  formValues: {
    fileValue: {
      maxSize: {
        default: 0,
        image: 0,
      },
    },
  },
};
