import {
  inputAdornmentClasses as muiInputAdornmentClasses,
  InputAdornmentClassKey as MuiInputAdornmentClassKey,
} from '@mui/material/InputAdornment';
import { omit } from 'lodash-es';

export type InputAdornmentClassKey = Exclude<
  MuiInputAdornmentClassKey,
  'filled' | 'outlined' | 'standard' | 'hiddenLabel' | 'sizeSmall'
>;
export type InputAdornmentClasses = Record<InputAdornmentClassKey, string>;

export const inputAdornmentClasses: InputAdornmentClasses = omit(
  muiInputAdornmentClasses,
  ['filled', 'outlined', 'standard', 'hiddenLabel', 'sizeSmall'],
);
