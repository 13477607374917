import { EditorState, Modifier } from '@allganize/draft-input';
import { getSelectionForInlineStyle } from './get-selection-for-inline-style';

export const removeInlineStyles = (
  editorState: EditorState,
  styles: string[],
): EditorState => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const newSelection = getSelectionForInlineStyle(editorState);

  const newContentState = styles.reduce(
    (acc, style) => Modifier.removeInlineStyle(acc, newSelection, style),
    contentState,
  );

  return EditorState.acceptSelection(
    EditorState.push(editorState, newContentState, 'change-inline-style'),
    selection,
  );
};
