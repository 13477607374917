import { OverridableComponent } from '@allganize/types';
import { Variant, useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import MuiSkeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import { camelCase } from 'lodash-es';
import { forwardRef } from 'react';
import invariant from 'tiny-invariant';
import { skeletonClasses } from './skeleton-classes';
import { SkeletonTypeMap } from './skeleton-type-map';

const textVariantHeightMapping: Record<Variant, number> = {
  title24: 32,
  title20: 28,
  title16: 24,
  title14: 20,
  title12: 18,
  subtitle16: 24,
  subtitle14: 20,
  subtitle12: 18,
  body16: 24,
  body14: 20,
  body12: 18,
  label14: 20,
  label12: 18,
};

/**
 * Display a placeholder preview of your content before the data gets loaded
 * to reduce load-time frustration.
 */
// @ts-expect-error overridable component
export const Skeleton: OverridableComponent<SkeletonTypeMap> = forwardRef(
  (props, ref) => {
    const theme = useTheme();
    const {
      animation = 'pulse',
      classes,
      color = 'light',
      textVariant,
      variant = 'text',
      ...other
    } = props;

    if (variant !== 'text') {
      invariant(
        typeof textVariant === 'undefined',
        '`textVariant` is only valid when `variant` is "text"',
      );
    }

    return (
      <MuiSkeleton
        data-testid="skeleton"
        css={[
          css`
            background-color: ${theme.palette.background.skeleton[color]};
          `,
          variant === 'rounded' &&
            css`
              border-radius: ${theme.radius.sm}px;
            `,
          variant === 'text' &&
            css`
              border-radius: ${theme.radius.xs}px;
            `,
          animation === 'wave' &&
            css`
              &::after {
                background: linear-gradient(
                  90deg,
                  transparent,
                  ${theme.palette.action.hover},
                  transparent
                );
              }
            `,
        ]}
        animation={animation}
        classes={classes}
        height={
          variant === 'text'
            ? textVariantHeightMapping[textVariant || 'body14']
            : undefined
        }
        variant={variant}
        {...other}
        ref={ref}
        className={clsx(
          skeletonClasses[
            camelCase(`color_${color}`) as keyof typeof skeletonClasses
          ],
          classes?.[camelCase(`color_${color}`) as keyof typeof classes],
          other.className,
        )}
      />
    );
  },
);
