import {
  IcArrowNavigateBefore,
  IcArrowNavigateNext,
} from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { useSlotProps } from '@mui/base/utils';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { pickersArrowSwitcherClasses } from './pickers-arrow-switcher-classes';
import { PickersArrowSwitcherButton } from './pickers-arrow-switcher-slots';
import { PickersArrowSwitcherProps } from './pickers-arrow-switcher-type-map';

export const PickersArrowSwitcher = forwardRef<
  HTMLDivElement,
  PickersArrowSwitcherProps
>((props, ref) => {
  const {
    children,
    classes,
    className,
    slots,
    slotProps,
    isNextDisabled,
    isNextHidden,
    onGoToNext,
    nextLabel,
    isPreviousDisabled,
    isPreviousHidden,
    onGoToPrevious,
    previousLabel,
    ...other
  } = props;
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';
  const ownerState = props;

  const nextProps = {
    isDisabled: isNextDisabled,
    isHidden: isNextHidden,
    goTo: onGoToNext,
    label: nextLabel,
  };

  const previousProps = {
    isDisabled: isPreviousDisabled,
    isHidden: isPreviousHidden,
    goTo: onGoToPrevious,
    label: previousLabel,
  };

  const PreviousIconButton =
    slots?.previousIconButton ?? PickersArrowSwitcherButton;
  const previousIconButtonProps = useSlotProps({
    elementType: PreviousIconButton,
    externalSlotProps: slotProps?.previousIconButton,
    additionalProps: {
      size: 'small',
      title: previousProps.label,
      'aria-label': previousProps.label,
      disabled: previousProps.isDisabled,
      onClick: previousProps.goTo,
    },
    ownerState: { ...ownerState, hidden: previousProps.isHidden },
    className: clsx(pickersArrowSwitcherClasses.button, classes?.button),
  });

  const NextIconButton = slots?.nextIconButton ?? PickersArrowSwitcherButton;
  const nextIconButtonProps = useSlotProps({
    elementType: NextIconButton,
    externalSlotProps: slotProps?.nextIconButton,
    additionalProps: {
      size: 'small',
      edge: 'end',
      title: nextProps.label,
      'aria-label': nextProps.label,
      disabled: nextProps.isDisabled,
      onClick: nextProps.goTo,
    },
    ownerState: { ...ownerState, hidden: nextProps.isHidden },
    className: clsx(pickersArrowSwitcherClasses.button, classes?.button),
  });

  const LeftArrowIcon = slots?.leftArrowIcon ?? IcArrowNavigateBefore;
  // The spread is here to avoid this bug mui/material-ui#34056
  const { ownerState: leftArrowIconOwnerState, ...leftArrowIconProps } =
    useSlotProps({
      elementType: LeftArrowIcon,
      externalSlotProps: slotProps?.leftArrowIcon,
      additionalProps: {
        fontSize: 'medium',
      },
      ownerState: undefined,
    });

  const RightArrowIcon = slots?.rightArrowIcon ?? IcArrowNavigateNext;
  // The spread is here to avoid this bug mui/material-ui#34056
  const { ownerState: rightArrowIconOwnerState, ...rightArrowIconProps } =
    useSlotProps({
      elementType: RightArrowIcon,
      externalSlotProps: slotProps?.rightArrowIcon,
      additionalProps: {
        fontSize: 'medium',
      },
      ownerState: undefined,
    });

  return (
    <div
      data-testid="pickers-arrow-switcher"
      css={css`
        display: flex;
      `}
      {...other}
      ref={ref}
      className={clsx(
        pickersArrowSwitcherClasses.root,
        classes?.root,
        className,
      )}
    >
      <PreviousIconButton {...previousIconButtonProps}>
        {isRTL ? (
          <RightArrowIcon {...rightArrowIconProps} />
        ) : (
          <LeftArrowIcon {...leftArrowIconProps} />
        )}
      </PreviousIconButton>

      {children ? (
        <Text variant="title14" component="span">
          {children}
        </Text>
      ) : (
        <div
          css={css`
            width: 4px;
          `}
          className={clsx(pickersArrowSwitcherClasses.spacer, classes?.spacer)}
        />
      )}

      <NextIconButton {...nextIconButtonProps}>
        {isRTL ? (
          <LeftArrowIcon {...leftArrowIconProps} />
        ) : (
          <RightArrowIcon {...rightArrowIconProps} />
        )}
      </NextIconButton>
    </div>
  );
});
