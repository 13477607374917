import { toast } from '@allganize/ui-toast';
import { useMutation, useQuery } from '@apollo/client/react';
import { compact } from 'lodash-es';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import { AppContext, Mode } from './app-context';
import { SingleActionAppFromTokenQueryDocument } from '../../graphql/queries/single-action-app-from-token-query';
import { GenerateSingleActionAppAccessTokenPublicMutationDocument } from '../../graphql/mutations/generate-single-action-app-access-token-public-mutation';
import { getMallyErrorMessageDescriptor } from '../utils/error';

interface AppProviderProps {
  mode: Mode;
  publicToken: string;
  children?: React.ReactNode;
  onLoadApp?: (appId: string | number) => void;
}

export const AppProvider = ({
  mode,
  publicToken,
  children,
  onLoadApp,
}: AppProviderProps) => {
  const intl = useIntl();

  const [generateAccessToken, { data: accessTokenData }] = useMutation(
    GenerateSingleActionAppAccessTokenPublicMutationDocument,
    {
      variables: { publicToken },
      onCompleted: res => {
        const error = compact(
          res.generateSingleActionAppAccessTokenPublic?.errors,
        )[0];

        if (error) {
          toast.error(
            intl.formatMessage(getMallyErrorMessageDescriptor(error)),
          );
        }
      },
      onError: err =>
        toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(err))),
    },
  );

  const accessToken =
    accessTokenData?.generateSingleActionAppAccessTokenPublic?.accessToken ||
    '';

  const { data, error, loading, refetch } = useQuery(
    SingleActionAppFromTokenQueryDocument,
    {
      variables: {
        token: publicToken,
      },
      onCompleted: ({ singleActionAppFromToken }) => {
        if (singleActionAppFromToken) {
          generateAccessToken();
          onLoadApp?.(singleActionAppFromToken.id);
        }
      },
      onError: err =>
        toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(err))),
    },
  );

  const appData = useMemo(() => {
    if (!data?.singleActionAppFromToken) {
      return null;
    }

    const singleActionInputs = (
      data.singleActionAppFromToken.singleActionInputs || []
    ).map(input => {
      const options = (input.options || []).map(option => ({
        label: option.name,
        value: option.value,
      }));

      const knowledgeBases = (input.knowledgeBases || []).map(kb => ({
        label: kb.title,
        value: kb.id,
      }));

      return {
        ...input,
        placeholder: input.placeholder || '',
        options,
        knowledgeBases,
      };
    });

    return {
      id: data.singleActionAppFromToken.id,
      projectId: data.singleActionAppFromToken.project.id,
      name: data.singleActionAppFromToken.name,
      description: data.singleActionAppFromToken.description || '',
      singleActionInputs,
    };
  }, [data?.singleActionAppFromToken]);

  const app = useMemo(
    () => ({
      mode,
      data: appData,
      error: !!error || !publicToken,
      loading,
      accessToken,
      publicToken,
      refetch,
      generateAccessToken,
    }),
    [
      mode,
      appData,
      error,
      loading,
      publicToken,
      accessToken,
      refetch,
      generateAccessToken,
    ],
  );

  return <AppContext.Provider value={app}>{children}</AppContext.Provider>;
};
