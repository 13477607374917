import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import MuiTableCell from '@mui/material/TableCell';
import { alpha, darken } from '@mui/material/styles';
import { forwardRef } from 'react';
import { tableCellClasses } from './table-cell-classes';
import { TableCellProps } from './table-cell-type-map';

export const TableCell = forwardRef<unknown, TableCellProps>((props, ref) => {
  const { classes, padding = 'normal', ...other } = props;
  const theme = useTheme();

  return (
    <ClassNames>
      {({ css, cx }) => (
        <MuiTableCell
          data-testid="table-cell"
          padding={padding as any}
          {...other}
          ref={ref}
          classes={{
            ...classes,
            root: cx(
              css`
                ${theme.typography.body14}
                border-bottom: 1px solid ${theme.palette.mode === 'light'
                  ? theme.palette.border.divider.default
                  : darken(alpha(theme.palette.divider, 1), 0.68)};
                padding: 16px;
                height: 56px;
              `,
              padding === 'button' &&
                css`
                  padding: 12px 16px;

                  &.${tableCellClasses.sizeSmall} {
                    padding: 4px 16px;
                  }
                `,
              padding === 'input' &&
                css`
                  padding: 10px 16px;

                  &.${tableCellClasses.sizeSmall} {
                    padding: 2px 16px;
                  }
                `,
              classes?.root,
            ),
            sizeSmall: cx(
              css`
                padding: 8px 16px;
                height: 40px;
              `,
              classes?.sizeSmall,
            ),
            paddingCheckbox: cx(
              css`
                width: 56px;
                padding: 16px;

                &.${tableCellClasses.sizeSmall} {
                  width: 56px;
                  padding: 8px 16px;

                  & > * {
                    padding: 2px;
                  }
                }
              `,
              classes?.paddingCheckbox,
            ),
            paddingNone: cx(
              css`
                padding: 0;
              `,
              classes?.paddingNone,
            ),
            head: cx(
              css`
                ${theme.typography.subtitle12}
                color: ${theme.palette.foreground.default};
                padding: 8px 16px;
                height: 40px;

                &.${tableCellClasses.paddingButton} {
                  padding: 4px 16px;

                  &.${tableCellClasses.sizeSmall} {
                    padding: 4px 16px;
                  }
                }

                &.${tableCellClasses.paddingInput} {
                  padding: 2px 16px;

                  &.${tableCellClasses.sizeSmall} {
                    padding: 2px 16px;
                  }
                }

                &.${tableCellClasses.paddingCheckbox} {
                  padding: 8px 16px;

                  &.${tableCellClasses.sizeSmall} {
                    padding: 8px 16px;
                  }
                }
              `,
              classes?.head,
            ),
            body: cx(
              css`
                color: ${theme.palette.foreground.default};
              `,
              classes?.body,
            ),
            footer: cx(
              css`
                color: ${theme.palette.text.secondary};
                line-height: ${theme.typography.pxToRem(21)};
                font-size: ${theme.typography.pxToRem(12)};
              `,
              classes?.footer,
            ),
            stickyHeader: cx(
              css`
                background-color: ${theme.palette.unstable_background.graySubtle
                  .default};
              `,
              classes?.stickyHeader,
            ),
          }}
        />
      )}
    </ClassNames>
  );
});
