import { css } from '@emotion/react';
import clsx from 'clsx';
import { FunctionComponent, HTMLAttributes, memo, useContext } from 'react';

import { Root } from '../components/root';
import { InnerWrapper } from '../components/styled-components';
import { AppContext } from '../contexts/app-context';
import { SingleActionForm } from '../single-action-form/single-action-form';
import { ErrorState } from '../single-action-state/error-state';
import { LoadingState } from '../single-action-state/loading-state';
import { Output } from '../output/output';
import { SingleActionHeader } from './single-action-header';

export interface SingleActionAppProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export const SingleActionApp: FunctionComponent<SingleActionAppProps> = memo(
  ({ className, ...others }) => {
    const { error, loading, data, refetch } = useContext(AppContext);

    if (error) {
      return <ErrorState onRetry={refetch} />;
    }

    if (loading || !data) {
      return <LoadingState />;
    }

    const { name, description, singleActionInputs } = data;

    return (
      <Root
        className={clsx(className, 'single-action')}
        css={css`
          justify-content: flex-start;
        `}
        {...others}
      >
        <SingleActionHeader name={name} description={description || ''} />
        <InnerWrapper>
          <SingleActionForm singleActionInputs={singleActionInputs} />
          <Output />
        </InnerWrapper>
      </Root>
    );
  },
);
