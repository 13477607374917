import { useCombinedRef } from '@allganize/hooks';
import { OverridableComponent } from '@allganize/types';
import { ButtonBase } from '@allganize/ui-button';
import { IcClose } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { cloneElement, forwardRef, isValidElement, useRef } from 'react';
import { chipBaseClasses } from './chip-base.classes';
import { ChipBaseTypeMap } from './chip-base.types';

function isDeleteKeyboardEvent(keyboardEvent: React.KeyboardEvent) {
  return keyboardEvent.key === 'Backspace' || keyboardEvent.key === 'Delete';
}

// @ts-expect-error overridable component
export const ChipBase: OverridableComponent<ChipBaseTypeMap> = forwardRef(
  (props, ref) => {
    const {
      avatar: avatarProp,
      classes,
      className,
      clickable: clickableProp,
      // @ts-expect-error overridable component
      component: componentProp,
      deleteIcon: deleteIconProp,
      disabled = false,
      icon: iconProp,
      label,
      onClick,
      onDelete,
      onKeyDown,
      onKeyUp,
      tabIndex,
      skipFocusWhenDisabled = false,
      ...other
    } = props;
    const theme = useTheme();
    const chipRef = useRef<HTMLDivElement>(null);
    const handleRef = useCombinedRef(chipRef, ref);

    const handleDeleteIconClick = (event: React.MouseEvent) => {
      // Stop the event from bubbling up to the `Chip`
      event.stopPropagation();
      onDelete?.(event);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      // Ignore events from children of `Chip`.
      if (
        event.currentTarget === event.target &&
        isDeleteKeyboardEvent(event)
      ) {
        // Will be handled in keyUp, otherwise some browsers
        // might init navigation
        event.preventDefault();
      }

      onKeyDown?.(event);
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
      // Ignore events from children of `Chip`.
      if (event.currentTarget === event.target) {
        if (onDelete && isDeleteKeyboardEvent(event)) {
          onDelete(event);
        } else if (event.key === 'Escape' && chipRef.current) {
          chipRef.current?.blur();
        }
      }

      onKeyUp?.(event);
    };

    const clickable = clickableProp !== false && onClick ? true : clickableProp;

    const Component =
      clickable || onDelete ? ButtonBase : componentProp || 'div';

    const moreProps =
      Component === ButtonBase
        ? {
            component: componentProp || 'div',
            focusVisibleClassName: clsx(
              chipBaseClasses.focusVisible,
              classes?.focusVisible,
            ),
          }
        : {};

    let deleteIcon: React.ReactNode = null;

    if (onDelete) {
      deleteIcon =
        deleteIconProp && isValidElement(deleteIconProp) ? (
          cloneElement(deleteIconProp, {
            // @ts-expect-error internal prop
            className: clsx(
              chipBaseClasses.deleteIcon,
              classes?.deleteIcon,
              // @ts-expect-error internal prop
              deleteIconProp.props?.className,
            ),
            onClick: handleDeleteIconClick,
          })
        ) : (
          <IcClose
            className={clsx(chipBaseClasses.deleteIcon, classes?.deleteIcon)}
            onClick={handleDeleteIconClick}
          />
        );
    }

    let avatar: React.ReactNode = null;

    if (avatarProp && isValidElement(avatarProp)) {
      avatar = cloneElement(avatarProp, {
        // @ts-expect-error internal prop
        className: clsx(
          chipBaseClasses.avatar,
          classes?.avatar,
          // @ts-expect-error internal prop
          avatarProp.props?.className,
        ),
      });
    }

    let icon: React.ReactNode = null;

    if (iconProp && isValidElement(iconProp)) {
      icon = cloneElement(iconProp, {
        // @ts-expect-error internal prop
        className: clsx(
          chipBaseClasses.icon,
          classes?.icon,
          // @ts-expect-error internal prop
          iconProp.props?.className,
        ),
      });
    }

    return (
      <Component
        data-testid="chip-base"
        css={[
          css`
            max-width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            cursor: unset;
            outline: 0;
            text-decoration: none;
            border: 0;
            padding: 0;
            vertical-align: middle;
            box-sizing: border-box;

            &.${chipBaseClasses.disabled} {
              pointer-events: none;
            }

            .${chipBaseClasses.deleteIcon} {
              -webkit-tap-highlight-color: transparent;
              cursor: pointer;
            }
          `,
          onDelete &&
            css`
              &.${chipBaseClasses.focusVisible} {
                outline: ${theme.palette.border.focused} solid 2px;
                outline-offset: 2px;
              }
            `,
          clickable &&
            css`
              user-select: none;
              -webkit-tap-highlight-color: transparent;
              cursor: pointer;

              &.${chipBaseClasses.focusVisible} {
                outline: ${theme.palette.border.focused} solid 2px;
                outline-offset: 2px;
              }
            `,
        ]}
        className={clsx(
          chipBaseClasses.root,
          {
            [chipBaseClasses.disabled]: disabled,
            [chipBaseClasses.clickable]: clickable,
            [chipBaseClasses.deletable]: onDelete,
          },
          classes?.root,
          {
            [classes?.disabled ?? '']: disabled,
            [classes?.clickable ?? '']: clickable,
            [classes?.deletable ?? '']: onDelete,
          },
          className,
        )}
        disabled={clickable && disabled ? true : undefined}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        tabIndex={skipFocusWhenDisabled && disabled ? -1 : tabIndex}
        {...moreProps}
        {...other}
        ref={handleRef}
      >
        {avatar || icon}

        <span
          css={css`
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
          className={clsx(chipBaseClasses.label, classes?.label)}
        >
          {label}
        </span>

        {deleteIcon}
      </Component>
    );
  },
);
