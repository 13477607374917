import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type DraftToolbarSeparatorClassKey = 'root';
export type DraftToolbarSeparatorClasses = Record<
  DraftToolbarSeparatorClassKey,
  string
>;

export const draftToolbarSeparatorClasses: DraftToolbarSeparatorClasses =
  generateUtilityClasses('DraftToolbarSeparator', ['root']);
