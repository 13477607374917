import {
  getListSubheaderUtilityClass,
  listSubheaderClasses as muiListSubheaderClasses,
  ListSubheaderClassKey as MuiListSubheaderClassKey,
} from '@mui/material/ListSubheader';
import { omit } from 'lodash-es';

export type ListSubheaderClassKey =
  | Exclude<MuiListSubheaderClassKey, 'inset'>
  | 'insetLarge'
  | 'insetSmall';
export type ListSubheaderClasses = Record<ListSubheaderClassKey, string>;

export const listSubheaderClasses: ListSubheaderClasses = {
  ...omit(muiListSubheaderClasses, ['inset']),
  insetLarge: getListSubheaderUtilityClass('insetLarge'),
  insetSmall: getListSubheaderUtilityClass('insetSmall'),
};
