import { Button } from '@allganize/ui-button';
import {
  DialogActions,
  DialogCloseButton,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@allganize/ui-dialog';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FunctionComponent, useId } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dialog } from '../dialog/dialog';

interface UploadBlockModalProps extends DialogProps {
  docLimit: number;
  docNum: number;
}

export const UploadBlockModal: FunctionComponent<UploadBlockModalProps> = ({
  docNum,
  docLimit,
  open,
  onClose,
  ...other
}) => {
  const titleId = useId();
  return (
    <Dialog
      {...other}
      aria-labelledby={titleId}
      open={open}
      PaperProps={{
        css: css`
          width: 406px;
        `,
      }}
    >
      <DialogTitle id={titleId}>
        <FormattedMessage
          id="single-action.upload-block-dialog.title"
          defaultMessage="Document upload limit"
          description="single action upload block dialog title"
        />
        <DialogCloseButton />
      </DialogTitle>
      <DialogContent>
        <Text>
          {docNum >= docLimit ? (
            <FormattedMessage
              id="single-action.upload-block-dialog.exceed-error"
              defaultMessage="During the trial period, you can upload only up to {docLimit} documents.
              There are already {docNum} documents uploaded."
              description="single action upload block dialog document limit exceed error description"
              values={{
                docLimit,
                docNum,
              }}
            />
          ) : (
            <FormattedMessage
              id="single-action.upload-block-dialog.limit-error"
              defaultMessage="During the trial period, there is a limit of uploading up to {docLimit} documents. {docNum} documents are already uploaded, <highlight>you can only upload {docSub} more.</highlight>"
              description="single action upload block dialog document limit error description"
              values={{
                docLimit,
                docNum,
                docSub: docLimit - docNum,
                highlight(chunks) {
                  return (
                    <span
                      css={css`
                        font-weight: 700;
                      `}
                    >
                      {chunks}
                    </span>
                  );
                },
              }}
            />
          )}
        </Text>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="filled"
          size="large"
          onClick={e => onClose?.(e, 'backdropClick')}
        >
          <FormattedMessage
            id="single-action.upload-block-dialog.confirm"
            defaultMessage="Confirm"
            description="single action upload block dialog confirm button text"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
