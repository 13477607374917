import { CamelCase } from '@allganize/types';
import generateUtilityClass from '@mui/material/generateUtilityClass';
import generateUtilityClasses from '@mui/material/generateUtilityClasses';
import { ToastPosition, TypeOptions } from 'react-toastify';

export const getToastUtilityClass = (slot: string) =>
  generateUtilityClass('Toast', slot);

export type ToastClassKey =
  | 'root'
  | CamelCase<`position-${ToastPosition}`>
  | 'rtl'
  | 'toast'
  | CamelCase<`toast-${TypeOptions}`>
  | 'body'
  | 'progress'
  | 'progressAnimated'
  | 'progressControlled';
export type ToastClasses = Record<ToastClassKey, string>;

export const toastClasses: ToastClasses = generateUtilityClasses('Toast', [
  'root',
  'positionTopRight',
  'positionTopCenter',
  'positionTopLeft',
  'positionBottomRight',
  'positionBottomCenter',
  'positionBottomLeft',
  'rtl',
  'toast',
  'toastInfo',
  'toastSuccess',
  'toastWarning',
  'toastError',
  'toastDefault',
  'body',
  'progress',
  'progressAnimated',
  'progressControlled',
]);
