import { BlockRendererProps } from '@allganize/draft-input';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { DraftImageBase } from './draft-image-base';
import { DraftImageClasses } from './draft-image-classes';

const responsiveWidthStyles = (width?: number) => css`
  min-width: ${width === undefined || width > 232 ? 232 : width}px;
  max-width: ${width === undefined || width > 519 ? 519 : width}px;

  @media (min-width: 640px) {
    min-width: ${width === undefined || width > 350 ? 350 : width}px;
    max-width: ${width === undefined || width > 530 ? 530 : width}px;
  }

  @media (min-width: 980px) {
    min-width: ${width === undefined || width > 500 ? 500 : width}px;
    max-width: ${width === undefined || width > 560 ? 560 : width}px;
  }
`;

export interface DraftImageBlockProps {
  classes?: Partial<DraftImageClasses>;
  data?: {
    id?: string | number | null;
    metaInfo?: string | null;
    src?: string | null;
    width?: number | null;
    height?: number | null;
    isOptionType?: boolean;
  };
  preserveRatio?: boolean;
  onDragStart?(ev: React.DragEvent<HTMLDivElement>, blockKey: string): void;
}

const getAlt = (
  blockProps?: DraftImageBlockProps,
): string | null | undefined => {
  const metaInfo = blockProps?.data?.metaInfo;

  if (!metaInfo) {
    return blockProps?.data?.src;
  }

  try {
    const parsedMetaInfo = JSON.parse(metaInfo);
    return parsedMetaInfo.name || parsedMetaInfo.filename;
  } catch {
    return blockProps?.data?.src;
  }
};

export const DraftImage = forwardRef<
  HTMLDivElement,
  BlockRendererProps<DraftImageBlockProps>
>((props, ref) => {
  const { block, blockProps } = props;
  const blockKey = block?.getKey();

  const handleDragStart = (ev: React.DragEvent<HTMLDivElement>) => {
    if (!blockKey) {
      return;
    }

    blockProps?.onDragStart?.(ev, blockKey);
  };

  return (
    <DraftImageBase
      data-testid="draft-image"
      css={[
        !blockProps?.data?.isOptionType &&
          responsiveWidthStyles(blockProps?.data?.width || undefined),
      ]}
      classes={blockProps?.classes}
      src={blockProps?.data?.src || undefined}
      alt={getAlt(blockProps) || undefined}
      width={blockProps?.data?.width ?? undefined}
      height={blockProps?.data?.height ?? undefined}
      preserveRatio={blockProps?.preserveRatio}
      onDragStart={handleDragStart}
      ref={ref}
    />
  );
});
