import {
  AssistantDocumentUploadButton,
  AssistantDocumentUploadSource,
} from '@allganize/alli-sdk-chat';
import {
  ChatFormInputAdornment,
  ChatFormInputField,
  ChatFormSubmitButton,
} from '@allganize/alli-sdk-ui';
import { combineRefs, useEventCallback } from '@allganize/hooks';
import { IconButton } from '@allganize/ui-button';
import { IcClose } from '@allganize/ui-icons';
import { InputFieldProps } from '@allganize/ui-input';
import styled from '@emotion/styled';
import { omit } from 'lodash-es';
import { forwardRef, Fragment, useMemo } from 'react';
import { Control, FormState, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ChatFormValues } from '../hooks/use-chat-form';
import { UseChatFormAutocompleteReturn } from '../hooks/use-chat-form-autocomplete';
import { ChatFormDatePickerButton } from './chat-form-date-picker';
import { ChatFormErrorMessage } from './chat-form-error-message';
import { ChatFormFileUploadButton } from './chat-form-file-dropzone';
import {
  AssistantIntentSelect,
  IntentValueType,
} from './chat-form-intent-selector';

const stopPropagation = (ev: React.MouseEvent) => ev.stopPropagation();

const InputWrapper = styled.div`
  position: relative;
`;

interface ChatFormMessageFieldProps extends InputFieldProps {
  control: Control<ChatFormValues>;
  formState: FormState<ChatFormValues>;
  autocomplete?: UseChatFormAutocompleteReturn;
  isSubmitting?: boolean;
  assistantEnabled?: boolean;
  messageFieldEnabled?: boolean;
  fileFieldEnabled?: boolean;
  dateFieldEnabled?: boolean;
  onKeyDown?: React.KeyboardEventHandler;
  onClear?: React.MouseEventHandler;
  onFileUpload?: React.MouseEventHandler;
  onClearError?: () => void;
  onOpenKnowledgeBaseDialog?: (type: AssistantDocumentUploadSource) => void;
  onClickKnowledgeBaseButton?: () => void;
  onClickIntentSelector?: React.MouseEventHandler;
  onChangeIntentValue?: (type: IntentValueType) => void;
}

export const ChatFormMessageField = forwardRef<
  HTMLDivElement,
  ChatFormMessageFieldProps & { ownerState?: any }
>(
  (
    {
      ownerState,
      control,
      autocomplete,
      isSubmitting: isSubmittingProp,
      formState: { isSubmitting: isSubmittingForm, isValid, errors },
      InputLabelProps,
      InputProps,
      inputProps: inputPropsProp,
      assistantEnabled,
      messageFieldEnabled,
      fileFieldEnabled,
      dateFieldEnabled,
      onKeyDown,
      onClear,
      onFileUpload,
      onClearError,
      onOpenKnowledgeBaseDialog,
      onClickKnowledgeBaseButton,
      onClickIntentSelector,
      onChangeIntentValue,
      ...others
    },
    ref,
  ) => {
    const intl = useIntl();
    const { state } = useLocation();
    const {
      field: { ref: messageFieldRef, ...messageField },
    } = useController({ control, name: 'message' });
    const isSubmitting = isSubmittingProp || isSubmittingForm;
    const autocompleteInputProps = autocomplete?.getInputProps();
    const inputProps = {
      ...inputPropsProp,
      ...omit(autocompleteInputProps, ['ref', 'value']),
    };
    const inputRef = combineRefs(
      messageFieldRef,
      autocompleteInputProps?.ref ?? null,
      inputPropsProp?.ref ?? null,
      others.inputRef ?? null,
    );
    const InputRef = combineRefs(
      InputProps?.ref ?? null,
      autocomplete?.setAnchorEl ?? null,
    );

    const errorMessage = useMemo(() => {
      if (!others.value) return;
      if (messageFieldEnabled && errors.message?.message)
        return errors.message.message;
      if (fileFieldEnabled && errors.file?.message) return errors.file.message;
      if (dateFieldEnabled && errors.date?.message) return errors.date.message;
      return (
        errors.file?.message || errors.date?.message || errors.message?.message,
        errors.root?.message
      );
    }, [
      others.value,
      errors.message,
      errors.file,
      errors.date,
      errors.root,
      messageFieldEnabled,
      fileFieldEnabled,
      dateFieldEnabled,
    ]);

    const handleMessageInputClick = useEventCallback(
      (ev: React.MouseEvent<HTMLInputElement>) => {
        if (ev.target === ev.currentTarget) {
          autocompleteInputProps?.onMouseDown?.(ev);
        }
        InputProps?.onClick?.(ev);
      },
    );

    const handleMessageInputKeyDown = useEventCallback(
      (ev: React.KeyboardEvent) => {
        if (ev.key === 'Enter' && ev.shiftKey) {
          // to avoid autocomplete hooks new line enter
          ev.stopPropagation();
        }
        if (errorMessage) {
          onClearError?.();
        }
      },
    );

    const handleClearButtonClick = useEventCallback((ev: React.MouseEvent) => {
      ev.stopPropagation();
      onClear?.(ev);
    });

    return (
      <InputWrapper {...autocomplete?.getRootProps()}>
        <ChatFormInputField
          ref={ref}
          {...messageField}
          inputRef={inputRef}
          {...others}
          onKeyDown={handleMessageInputKeyDown}
          InputLabelProps={{
            ...autocomplete?.getInputLabelProps(),
            ...InputLabelProps,
          }}
          InputProps={{
            ...InputProps,
            ref: InputRef,
            onClick: handleMessageInputClick,
            startAdornment: (
              <Fragment>
                {(assistantEnabled || fileFieldEnabled || dateFieldEnabled) && (
                  <ChatFormInputAdornment position="start">
                    {assistantEnabled && (
                      <AssistantIntentSelect
                        control={control}
                        disabled={others.disabled}
                        onClick={onClickIntentSelector}
                        onChange={onChangeIntentValue}
                      />
                    )}
                    {fileFieldEnabled && (
                      <ChatFormFileUploadButton
                        onClick={onFileUpload}
                        disabled={others.disabled}
                      />
                    )}
                    {dateFieldEnabled && (
                      <ChatFormDatePickerButton
                        control={control}
                        edge={fileFieldEnabled ? undefined : 'start'}
                        disabled={others.disabled}
                      />
                    )}
                  </ChatFormInputAdornment>
                )}
                {InputProps?.startAdornment}
              </Fragment>
            ),
            endAdornment: (
              <Fragment>
                {InputProps?.endAdornment}
                <ChatFormInputAdornment
                  position="end"
                  onClick={stopPropagation}
                >
                  {assistantEnabled && (
                    <AssistantDocumentUploadButton
                      buttonType="icon"
                      onClick={onClickKnowledgeBaseButton}
                      onSelect={onOpenKnowledgeBaseDialog}
                      disabled={isSubmitting}
                      menuProps={{
                        open: state?.openDocumentMenu,
                      }}
                    />
                  )}
                  {onClear && (
                    <IconButton
                      onClick={handleClearButtonClick}
                      aria-label={intl.formatMessage({
                        id: 'chat-form.message-field.clear-button.aria-label',
                        defaultMessage: 'Clear the value you entered',
                        description: 'Clear chat button aria-label',
                      })}
                    >
                      <IcClose />
                    </IconButton>
                  )}
                  {errorMessage ? (
                    <ChatFormErrorMessage edge="end" title={errorMessage} />
                  ) : (
                    <ChatFormSubmitButton
                      disabled={others.disabled || !isValid}
                      isSubmitting={isSubmitting}
                    />
                  )}
                </ChatFormInputAdornment>
              </Fragment>
            ),
          }}
          inputProps={inputProps}
        />
      </InputWrapper>
    );
  },
);
