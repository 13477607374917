import { Popover, PopoverPaper } from '@allganize/ui-modal';
import styled from '@emotion/styled';
import { MenuList } from '../menu-list';

export const MenuRoot = Popover;

export const MenuPaper = styled(PopoverPaper)`
  max-height: calc(100% - 96px);
  -webkit-overflow-scrolling: touch;
`;

export const MenuMenuList = styled(MenuList)`
  outline: 0;
`;
