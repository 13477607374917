export type Locale = 'en' | 'ja' | 'ko';
export const defaultLocale: Locale = 'en';
export const LOCALES: Locale[] = ['en', 'ja', 'ko'];

export type MessageIds =
  | keyof typeof import('./en.json')
  | keyof typeof import('./ja.json')
  | keyof typeof import('./ko.json');

export const loadMessages = async (locale: Locale) => {
  switch (locale) {
    case 'en':
      return import('./en.json').then(mod => mod.default);
    case 'ja':
      return import('./ja.json').then(mod => mod.default);
    case 'ko':
      return import('./ko.json').then(mod => mod.default);
    default:
      throw new Error(`Unsupported locale: ${locale}`);
  }
};
