import { css } from '@emotion/react';
import clsx from 'clsx';
import { ContainerProps, GroupBase } from 'react-select';
import { selectClasses } from './select-classes';

export const SelectContainer = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: ContainerProps<Option, IsMulti, Group>,
) => {
  const { className, children, getClassNames, innerProps, isDisabled, isRtl } =
    props;

  return (
    <div
      css={[
        css`
          position: relative;
        `,
        isDisabled &&
          css`
            pointer-events: none;
          `,
        isRtl &&
          css`
            direction: rtl;
          `,
      ]}
      {...innerProps}
      className={clsx(
        selectClasses.container,
        {
          [selectClasses.disabled]: isDisabled,
          [selectClasses.rtl]: isRtl,
        },
        getClassNames('container', props),
        className,
        innerProps?.className,
      )}
    >
      {children}
    </div>
  );
};
