import { Skeleton } from '@allganize/ui-skeleton';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { DAY_MARGIN, DAY_SIZE } from '../internals/constants';
import { dayCalendarSkeletonClasses } from './day-calendar-skeleton-classes';
import { DayCalendarSkeletonProps } from './day-calendar-skeleton-type-map';

const monthMap = [
  [0, 0, 0, 0, 0, 1, 1],
  [1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1],
  [1, 0, 0, 0, 0, 0, 0],
];

export const DayCalendarSkeleton = forwardRef<
  HTMLDivElement,
  DayCalendarSkeletonProps
>((props, ref) => {
  const { classes, ...other } = props;

  return (
    <div
      date-testid="day-calendar-skeleton"
      css={css`
        align-self: start;
      `}
      {...other}
      ref={ref}
      className={clsx(
        dayCalendarSkeletonClasses.root,
        classes?.root,
        other.className,
      )}
    >
      {monthMap.map((week, index) => (
        <div
          css={css`
            padding: ${DAY_MARGIN}px 0;
            display: flex;
            justify-content: center;
          `}
          key={index}
          className={clsx(dayCalendarSkeletonClasses.week, classes?.week)}
        >
          {week.map((day, index2) => (
            <Skeleton
              css={[
                css`
                  margin: 0 ${DAY_MARGIN}px;
                `,
                day === 0 &&
                  css`
                    visibility: hidden;
                  `,
              ]}
              key={index2}
              variant="circular"
              width={DAY_SIZE}
              height={DAY_SIZE}
              className={clsx(
                dayCalendarSkeletonClasses.daySkeleton,
                classes?.daySkeleton,
              )}
            />
          ))}
        </div>
      ))}
    </div>
  );
});
