import { createContext } from 'react';

export interface AccordionContextValue {
  expanded?: boolean;
  disabled?: boolean;
  toggle?(event: React.SyntheticEvent): void;
}

export const AccordionContext = createContext<AccordionContextValue>({});

if (process.env.NODE_ENV !== 'production') {
  AccordionContext.displayName = 'AccordionContext';
}
