import { IconButton, iconButtonClasses } from '@allganize/ui-button';
import { IcAlliSymbol, IcClose } from '@allganize/ui-icons';
import { useTheme as useBaseTheme } from '@allganize/ui-theme';
import { Fade, Zoom } from '@allganize/ui-transition';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { ariaLabels } from '../i18n/aria-labels';
import { useTheme } from '../styles/use-theme';

interface Logo {
  src?: string;
  srcSet?: string;
  alt?: string;
}

export interface LauncherProps {
  className?: string;
  open?: boolean;
  logo?: Logo;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
`;

const Logo = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  user-select: none;
`;

const twist = keyframes`
  from {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(0deg);
  }
`;

export const Launcher: FunctionComponent<LauncherProps> = ({
  open,
  logo,
  className,
  onClick,
  ...other
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const baseTheme = useBaseTheme();

  return (
    <IconButton
      className={className}
      onClick={onClick}
      css={css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: ${theme.launcher.size}px;
        height: ${theme.launcher.size}px;
        padding: 0;

        ${!open &&
        css`
          &:hover,
          &.${iconButtonClasses.focusVisible}, &:active {
            background-color: transparent;
          }
        `}
      `}
      aria-label={intl.formatMessage(
        open
          ? ariaLabels['chatbot-close-button']
          : ariaLabels['chatbot-open-button'],
      )}
      {...other}
    >
      <Zoom in={open}>
        <Wrapper>
          <IcClose
            css={
              open &&
              css`
                animation: ${twist}
                  ${baseTheme.transitions.duration.enteringScreen}ms
                  ${baseTheme.transitions.easing.easeInOut};
              `
            }
            fontSize="large"
          />
        </Wrapper>
      </Zoom>
      {logo?.src ? (
        <Fade in={!open}>
          <Wrapper>
            <Logo src={logo.src} srcSet={logo.srcSet} alt={logo.alt} />
          </Wrapper>
        </Fade>
      ) : (
        <Zoom in={!open}>
          <Wrapper>
            <IcAlliSymbol
              fontSize="inherit"
              css={css`
                color: ${baseTheme.palette.primary.main};
              `}
            />
          </Wrapper>
        </Zoom>
      )}
    </IconButton>
  );
};
