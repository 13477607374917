import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { GroupBase, PlaceholderProps } from 'react-select';
import { selectClasses } from './select-classes';

export const Placeholder = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: PlaceholderProps<Option, IsMulti, Group>,
) => {
  const { children, className, getClassNames, innerProps, isDisabled } = props;
  const theme = useTheme();

  return (
    <div
      css={[
        css`
          grid-area: 1 / 1 / 2 / 3;
          color: ${theme.palette.foreground.placeholder};
          margin: 2px;
        `,
        isDisabled &&
          css`
            color: ${theme.palette.text.disabled};
          `,
      ]}
      {...innerProps}
      className={clsx(
        selectClasses.placeholder,
        getClassNames('placeholder', props),
        className,
        innerProps?.className,
      )}
    >
      {children}
    </div>
  );
};
