import { OverridableComponent } from '@allganize/types';
import { formControlState, FormLabel } from '@allganize/ui-form';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { useFormControl } from '@mui/material/FormControl';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { inputLabelClasses } from './input-label-classes';
import { InputLabelTypeMap } from './input-label-type-map';

// @ts-expect-error overridable component
export const InputLabel: OverridableComponent<InputLabelTypeMap> = forwardRef(
  (props, ref) => {
    const { classes, ...other } = props;
    const theme = useTheme();
    const muiFormControl = useFormControl();
    const fcs = formControlState({
      props,
      muiFormControl,
      states: ['required', 'focused'],
    });

    return (
      <FormLabel
        data-testid="input-label"
        css={css`
          display: block;
          max-width: 100%;
          margin-bottom: 6px;
          transition: ${theme.transitions.create(['color', 'max-width'], {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.easeOut,
          })};
        `}
        {...other}
        ref={ref}
        {...fcs}
        classes={{
          ...classes,
          root: clsx(inputLabelClasses.root, classes?.root),
          focused: clsx(inputLabelClasses.focused, classes?.focused),
          disabled: clsx(inputLabelClasses.disabled, classes?.disabled),
          error: clsx(inputLabelClasses.error, classes?.error),
          required: clsx(inputLabelClasses.required, classes?.required),
          asterisk: clsx(inputLabelClasses.asterisk, classes?.asterisk),
        }}
        className={clsx(
          {
            [inputLabelClasses.formControl]: muiFormControl,
            [props.classes?.formControl ?? '']: muiFormControl,
          },
          props.className,
        )}
      />
    );
  },
);
