import { Truncate } from '@allganize/truncate';
import { IconButton } from '@allganize/ui-button';
import { IcDownload, IcFile, IcOpenInNew } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { DraftFileClasses, draftFileClasses } from './draft-file-classes';

export interface DraftFileBaseProps
  extends React.HTMLAttributes<HTMLDivElement> {
  action?: 'download' | 'open';
  classes?: Partial<DraftFileClasses>;
  href?: string;
}

export const DraftFileBase = forwardRef<HTMLDivElement, DraftFileBaseProps>(
  (props, ref) => {
    const {
      action = 'download',
      children,
      classes,
      href,
      title,
      ...other
    } = props;
    const theme = useTheme();
    const filename =
      (typeof children === 'string' && children) ||
      (Array.isArray(children) &&
        children.length === 1 &&
        typeof children[0] === 'string' &&
        children[0]) ||
      title ||
      href;

    return (
      <div
        data-testid="draft-file-base"
        css={css`
          display: inline-flex;
          align-items: center;
          border: 1px solid ${theme.palette.grayAlpha[300]};
          border-radius: ${theme.radius.sm}px;
          background-color: ${theme.palette.common.white};
          color: ${theme.palette.text.primary};
          padding: 4px 12px;
          min-height: 44px;
          width: 232px;
          max-width: 100%;
        `}
        title={title}
        {...other}
        ref={ref}
        className={clsx(draftFileClasses.root, classes?.root, other.className)}
      >
        <IcFile />

        <div
          css={css`
            flex-grow: 1;
            padding: 0 4px;
            word-break: break-all;
            ${theme.typography.title14}
          `}
          title={filename}
        >
          <Truncate clamp={1}>{children || href}</Truncate>
        </div>

        <IconButton
          css={css`
            margin-left: 4px;
          `}
          data-testid="draft-file__action"
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          size="small"
          edge="end"
          href={href}
          download={action === 'download' ? filename : undefined}
        >
          {action === 'download' ? <IcDownload /> : <IcOpenInNew />}
        </IconButton>
      </div>
    );
  },
);
