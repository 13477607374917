import { createContext } from 'react';
import { AlliClient } from '../core/alli-client/alli-client';

interface AlliClientContextValue {
  client: AlliClient | null;
}

export const AlliClientContext = createContext<AlliClientContextValue>({
  client: null,
});
