import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { GroupBase, ValueContainerProps } from 'react-select';
import { selectClasses } from './select-classes';

export const ValueContainer = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: ValueContainerProps<Option, IsMulti, Group>,
) => {
  const {
    children,
    className,
    getClassNames,
    innerProps,
    isDisabled,
    isMulti,
    hasValue,
    selectProps: { controlShouldRenderValue },
  } = props;
  const theme = useTheme();

  return (
    <div
      css={css`
        display: ${isMulti && hasValue && controlShouldRenderValue
          ? 'flex'
          : 'grid'};
        align-items: center;
        flex: 1;
        flex-wrap: wrap;
        -webkit-overflow-scrolling: touch;
        position: relative;
        overflow: hidden;
        padding: 3px 9px;
        ${theme.typography.body14}
        color: ${theme.palette.text.primary};

        &.${selectClasses.disabled} {
          color: ${theme.palette.text.disabled};
        }
      `}
      {...innerProps}
      className={clsx(
        selectClasses.valueContainer,
        {
          [selectClasses.disabled]: isDisabled,
          [selectClasses.multiple]: isMulti,
          [selectClasses.empty]: !hasValue,
        },
        getClassNames('valueContainer', props),
        className,
        innerProps?.className,
      )}
    >
      {children}
    </div>
  );
};
