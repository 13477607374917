import { DraftImagePlugin } from '@allganize/draft-image-plugin';
import {
  useDraftToolbarEditorStateGetter,
  useDraftToolbarEditorStateSetter,
} from '@allganize/draft-toolbar-plugin';
import { zodResolver } from '@hookform/resolvers/zod';
import { filesize } from 'filesize';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, defineMessages, useIntl } from 'react-intl';
import { z } from 'zod';
import { DraftFilePlugin } from '../draft-file-plugin';

export interface DraftToolbarFileFormValues {
  file: File | null;
}

const defaultDraftToolbarFileFormValues: DraftToolbarFileFormValues = {
  file: null,
};

const errorMessages = defineMessages({
  'file-required': {
    id: 'Errors.file-required',
    defaultMessage: 'File is a required field',
    description: 'File required error message',
  },
  'file-maxSize': {
    id: 'Errors.file-max-size',
    defaultMessage: 'File too large (Max. {maxSize})',
    description: 'File max size error message',
  },
});

interface ValidationSchemaOptions {
  file: {
    maxSize(file: File): number;
  };
  intl: IntlShape;
}

const createValidationSchema = ({ file, intl }: ValidationSchemaOptions) => {
  return z.object({
    file: z
      .instanceof(File)
      .nullable()
      .refine(value => {
        return !!value;
      }, intl.formatMessage(errorMessages['file-required']))
      .refine(
        value => {
          if (!value) {
            return true;
          }

          return value.size <= file.maxSize(value);
        },
        value => {
          if (!value) {
            return {};
          }

          return {
            message: intl.formatMessage(errorMessages['file-maxSize'], {
              maxSize: filesize(file.maxSize(value), {
                output: 'string',
                locale: intl.locale,
                localeOptions: intl.formats.date,
              }),
            }),
          };
        },
      ),
  }) satisfies z.ZodSchema<DraftToolbarFileFormValues>;
};

export interface UseDraftToolbarFileFormOptions<
  T extends { type: 'file' | 'image'; url: string },
> {
  defaultValues?: DraftToolbarFileFormValues;
  validation: Pick<ValidationSchemaOptions, 'file'>;
  onSubmit(values: DraftToolbarFileFormValues): Promise<T>;
}

export const useDraftToolbarFileForm = <
  T extends { type: 'file' | 'image'; url: string },
>({
  defaultValues = defaultDraftToolbarFileFormValues,
  validation,
  onSubmit,
}: UseDraftToolbarFileFormOptions<T>) => {
  const intl = useIntl();
  const validationSchema = useMemo(
    () => createValidationSchema({ intl, ...validation }),
    [intl, validation],
  );
  const getEditorState = useDraftToolbarEditorStateGetter();
  const setEditorState = useDraftToolbarEditorStateSetter();

  const form = useForm<DraftToolbarFileFormValues>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const { reset, setError } = form;

  const submit = async (values: DraftToolbarFileFormValues) => {
    try {
      const result = await onSubmit(values);

      if (result.type === 'image') {
        setEditorState(
          DraftImagePlugin.addImage(getEditorState(), result.url, result),
        );
      } else {
        setEditorState(
          DraftFilePlugin.addFile(getEditorState(), result.url, result),
        );
      }

      reset();
    } catch (err) {
      if (err instanceof Error) {
        setError('root', { message: err.message });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }

      setError('root', {
        message: intl.formatMessage({
          id: 'MallyError.UNKNOWN',
          defaultMessage: 'Something went wrong. Please try again later.',
          description: 'MallyError.UNKNOWN',
        }),
      });
    }
  };

  return { form, submit };
};
