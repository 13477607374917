import { Types } from '@allganize/alli-sdk-interfaces';
import { EditorState } from '@allganize/draft-input';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IntlShape, defineMessages, useIntl } from 'react-intl';
import { z } from 'zod';

export interface UserFeedbackImproverFormValues {
  suggestedAnswer: EditorState;
  feedbackSelection: Types.UserAnswerFeedbackSelection[];
}

const defaultUserFeedbackImproverFormValues: UserFeedbackImproverFormValues = {
  suggestedAnswer: EditorState.createEmpty(),
  feedbackSelection: [],
};

const USER_ANSWER_FEEDBACK_SELECTIONS = [
  Types.UserAnswerFeedbackSelection.NOT_RELEVANT,
  Types.UserAnswerFeedbackSelection.INACCURATE,
  Types.UserAnswerFeedbackSelection.NOT_BEST_ANSWER,
] as const satisfies readonly Types.UserAnswerFeedbackSelection[];

const errorMessages = defineMessages({
  required: {
    id: 'user-feedback-improver-form.errors.required',
    defaultMessage: 'Please enter suggestion or provide feedback.',
    description: 'Field required error message',
  },
});

const createValidationSchema = (intl: IntlShape) =>
  z
    .object({
      suggestedAnswer: z.instanceof(EditorState),
      feedbackSelection: z.array(z.enum(USER_ANSWER_FEEDBACK_SELECTIONS)),
    })
    .refine(
      data =>
        !!data.suggestedAnswer.getCurrentContent().getPlainText('\n').trim() ||
        data?.feedbackSelection?.length > 0,
      {
        path: ['feedbackSelection'],
        message: intl.formatMessage(errorMessages.required),
      },
    );

export interface UseUserFeedbackImproverFormOptions {
  defaultValues?: Partial<UserFeedbackImproverFormValues>;
  onSubmit: SubmitHandler<UserFeedbackImproverFormValues>;
}

export const useUserFeedbackImproverForm = ({
  defaultValues,
  onSubmit,
}: UseUserFeedbackImproverFormOptions) => {
  const intl = useIntl();
  const validationSchema = useMemo(
    () => zodResolver(createValidationSchema(intl)),
    [intl],
  );
  const form = useForm<UserFeedbackImproverFormValues>({
    mode: 'all',
    defaultValues: {
      ...defaultUserFeedbackImproverFormValues,
      ...defaultValues,
    },
    resolver: validationSchema,
  });
  const { reset, setError } = form;

  const submit = async (values: UserFeedbackImproverFormValues) => {
    try {
      await onSubmit(values);
      reset();
    } catch (err) {
      if (err instanceof Error) {
        setError('root', { message: err.message });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }
    }
  };

  return {
    feedbackSelections: USER_ANSWER_FEEDBACK_SELECTIONS,
    form,
    submit,
  };
};
