import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import {
  InputBaseComponent,
  InputBaseRoot,
} from '../input-base/input-base-slots';
import { inputClasses } from './input-classes';
import { InputOwnerState, InputProps } from './input-type-map';

export interface InputRootProps
  extends NonNullable<NonNullable<InputProps['slotProps']>['root']> {
  ownerState: InputOwnerState;
}

export const InputRoot = forwardRef<HTMLDivElement, InputRootProps>(
  (props, ref) => {
    const theme = useTheme();
    const color = props.ownerState.color || 'primary';

    return (
      <InputBaseRoot
        css={[
          css`
            position: relative;
            border-radius: ${theme.radius.sm}px;

            :hover .${inputClasses.notchedOutline} {
              border-color: ${theme.palette.grayAlpha[500]};
            }

            @media (hover: none) {
              :hover .${inputClasses.notchedOutline} {
                border-color: ${theme.palette.grayAlpha[200]};
              }
            }

            &.${inputClasses.focused} .${inputClasses.notchedOutline} {
              border-color: ${theme.palette[color].main};
              border-width: 1px;
              box-shadow: 0 0 0 2px ${theme.palette.border.focused};
            }

            &.${inputClasses.error} .${inputClasses.notchedOutline} {
              border-color: ${theme.palette.error.main};
              box-shadow: 0 0 0 2px ${theme.palette.border.focused};
            }

            &.${inputClasses.disabled} {
              background-color: ${theme.palette.grayAlpha[50]};

              .${inputClasses.notchedOutline} {
                border-color: ${theme.palette.grayAlpha[100]};
                box-shadow: none;
              }
            }
          `,
          props.ownerState.startAdornment &&
            css`
              padding-left: 12px;
            `,
          props.ownerState.endAdornment &&
            css`
              padding-right: 12px;
            `,
        ]}
        {...props}
        ref={ref}
      />
    );
  },
);

export interface InputComponentProps
  extends NonNullable<NonNullable<InputProps['slotProps']>['input']> {
  ownerState: InputOwnerState;
}

export const InputComponent = forwardRef<HTMLInputElement, InputComponentProps>(
  (props, ref) => {
    return (
      <InputBaseComponent
        css={[
          css`
            &:-webkit-autofill {
              border-radius: inherit;
            }
          `,
          props.ownerState.startAdornment &&
            css`
              padding-left: 0;
            `,
          props.ownerState.endAdornment &&
            css`
              padding-right: 0;
            `,
        ]}
        {...props}
        ref={ref}
      />
    );
  },
);
