import { Dialog as UiDialog } from '@allganize/ui-dialog';
import { CacheProvider } from '@emotion/react';
import { forwardRef, useContext } from 'react';
import { ModalContext } from './modal-context';

export const Dialog: typeof UiDialog = forwardRef((props, ref) => {
  const { emotionCache, container } = useContext(ModalContext);
  const dialog = <UiDialog container={container} {...props} ref={ref} />;

  if (!emotionCache) {
    return dialog;
  }

  return <CacheProvider value={emotionCache}>{dialog}</CacheProvider>;
});
