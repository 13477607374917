import { Theme, useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import clsx from 'clsx';
import { camelCase } from 'lodash-es';
import { forwardRef } from 'react';
import { ExtendButtonBase } from '../button-base';
import { ButtonClassKey, buttonClasses } from './button-classes';
import { ButtonProps, ButtonTypeMap, ButtonVariant } from './button-type-map';

const variantMapping: Record<
  ButtonVariant,
  NonNullable<MuiButtonProps['variant']>
> = {
  ghost: 'text',
  filled: 'contained',
  outlined: 'outlined',
};

const buttonIconStyles = (size: ButtonProps['size']) =>
  css`
    display: inherit;
    font-size: ${size === 'small' ? 16 : 20}px;
  `;

const baseStyles = (theme: Theme, variant: ButtonProps['variant']) => [
  css`
    text-transform: none;
    transition: ${theme.transitions.create(
      ['background-color', 'box-shadow', 'border-color', 'color', 'opacity'],
      { duration: theme.transitions.duration.short },
    )};
    text-decoration: none;
    box-shadow: none;
    display: inline-flex;

    &.${buttonClasses.focusVisible} {
      // TODO: mixin으로 교체
      outline: ${theme.palette.border.focused} solid 2px;
      outline-offset: 2px;
    }
  `,
  variant === 'filled' &&
    css`
      &.${buttonClasses.disabled} {
        color: ${theme.palette.foreground.gray.disabled};
        background-color: ${theme.palette.unstable_background.grayDisabled
          .alpha};
      }
    `,

  variant !== 'filled' &&
    css`
      &.${buttonClasses.disabled} {
        color: ${theme.palette.foreground.gray.disabled};
        border-color: ${theme.palette.border.disabled};
      }
    `,
];

const sizeStyles = (theme: Theme, size: ButtonProps['size']) => [
  size === 'small' &&
    css`
      height: 24px;
      min-width: 56px;
      min-height: 24px;
      padding: 3px 6px;
      gap: 2px;
      ${theme.typography.label12};
    `,
  size === 'medium' &&
    css`
      height: 32px;
      min-width: 64px;
      min-height: 32px;
      padding: 6px 8px;
      gap: 4px;
      ${theme.typography.label14};
    `,
  size === 'large' &&
    css`
      height: 36px;
      min-width: 64px;
      min-height: 36px;
      max-height: 36px;
      padding: 8px 12px;
      gap: 4px;
      ${theme.typography.label14};
    `,
];

const shapeStyles = (shape: ButtonProps['shape']) => [
  shape === 'rounded' &&
    css`
      border-radius: 4px;
    `,
  shape === 'circular' &&
    css`
      border-radius: 999px;
    `,
];

const variantStyles = (variant: ButtonProps['variant']) => [
  variant === 'outlined' &&
    css`
      background-color: transparent;
      border-style: solid;
      border-width: 1px;
    `,
  variant === 'ghost' &&
    css`
      background-color: transparent;
    `,
];

const colorStyles = (
  theme: Theme,
  variant: ButtonProps['variant'],
  color: ButtonProps['color'],
) => [
  color === 'default' &&
    css`
      color: ${theme.palette.foregroundInteractive.gray.default};
      border-color: ${theme.palette.borderInteractive.gray.default};

      &:hover {
        background-color: ${theme.palette.backgroundInteractive.grayGhostAlpha
          .hover};
      }

      &:active {
        background-color: ${theme.palette.backgroundInteractive.grayGhostAlpha
          .pressed};
      }
    `,
  color === 'primary' &&
    variant === 'filled' &&
    css`
      color: ${theme.palette.foreground.inverse};
      background-color: ${theme.palette.backgroundInteractive.primary.default};

      &:hover {
        background-color: ${theme.palette.backgroundInteractive.primary.hover};
      }

      &:active {
        background-color: ${theme.palette.backgroundInteractive.primary
          .pressed};
      }
    `,
  color === 'primary' &&
    variant !== 'filled' &&
    css`
      color: ${theme.palette.foregroundInteractive.primary};
      border-color: ${theme.palette.borderInteractive.primary.default};

      &:hover {
        background-color: ${theme.palette.backgroundInteractive.primarySubtle
          .hover};
        border-color: ${theme.palette.borderInteractive.primary.hover};
      }

      &:active {
        background-color: ${theme.palette.backgroundInteractive.primarySubtle
          .pressed};
        border-color: ${theme.palette.borderInteractive.primary.pressed};
      }
    `,
  color === 'error' &&
    variant === 'filled' &&
    css`
      color: ${theme.palette.foreground.inverse};
      background-color: ${theme.palette.backgroundInteractive.error.default};

      &:hover {
        background-color: ${theme.palette.backgroundInteractive.error.hover};
      }

      &:active {
        background-color: ${theme.palette.backgroundInteractive.error.pressed};
      }
    `,
];

const edgeStyles = (
  variant?: ButtonProps['variant'],
  edge?: ButtonProps['edge'],
  size?: ButtonProps['size'],
  startIcon?: ButtonProps['startIcon'],
  endIcon?: ButtonProps['endIcon'],
) => [
  size === 'small' &&
    edge === 'start' &&
    variant === 'ghost' &&
    css`
      margin-left: ${startIcon ? '-4px' : '-6px'};
    `,

  size === 'small' &&
    edge === 'start' &&
    variant !== 'ghost' &&
    css`
      margin-left: ${startIcon ? '-6px' : '-8px'};
    `,
  size === 'small' &&
    edge === 'end' &&
    variant === 'ghost' &&
    css`
      margin-right: ${endIcon ? '-4px' : '-6px'};
    `,
  size === 'small' &&
    edge === 'end' &&
    variant !== 'ghost' &&
    css`
      margin-right: ${endIcon ? '-6px' : '-8px'};
    `,
  size === 'medium' &&
    edge === 'start' &&
    variant === 'ghost' &&
    css`
      margin-left: ${startIcon ? '-4px' : '-6px'};
    `,
  size === 'medium' &&
    edge === 'start' &&
    variant !== 'ghost' &&
    css`
      margin-left: ${startIcon ? '-8px' : '-12px'};
    `,
  size === 'medium' &&
    edge === 'end' &&
    variant === 'ghost' &&
    css`
      margin-right: ${endIcon ? '-4px' : '-6px'};
    `,
  size === 'medium' &&
    edge === 'end' &&
    variant !== 'ghost' &&
    css`
      margin-right: ${endIcon ? '-8px' : '-12px'};
    `,
  size === 'large' &&
    edge === 'start' &&
    variant === 'ghost' &&
    css`
      margin-left: ${startIcon ? '-6px' : '-8px'};
    `,
  size === 'large' &&
    edge === 'start' &&
    variant !== 'ghost' &&
    css`
      margin-left: ${startIcon ? '-12px' : '-16px'};
    `,
  size === 'large' &&
    edge === 'end' &&
    variant === 'ghost' &&
    css`
      margin-right: ${endIcon ? '-6px' : '-8px'};
    `,
  size === 'large' &&
    edge === 'end' &&
    variant !== 'ghost' &&
    css`
      margin-right: ${endIcon ? '-12px' : '-16px'};
    `,
];

// @ts-expect-error overridable component
export const Button: ExtendButtonBase<ButtonTypeMap> = forwardRef(
  (props, ref) => {
    const theme = useTheme();
    const {
      classes,
      color = 'default',
      edge = false,
      shape = 'rounded',
      size = 'medium',
      variant = 'ghost',
      startIcon,
      endIcon,
      children,
      ...other
    } = props;

    return (
      <MuiButton
        data-testid="button"
        css={[
          baseStyles(theme, variant),
          sizeStyles(theme, size),
          shapeStyles(shape),
          variantStyles(variant),
          colorStyles(theme, variant, color),
          edgeStyles(variant, edge, size, startIcon, endIcon),
        ]}
        color={color === 'default' ? 'inherit' : color}
        size={size}
        variant={variantMapping[variant]}
        {...other}
        ref={ref}
        disableElevation
        centerRipple={false}
        disableRipple
        disableTouchRipple
        focusRipple={false}
        disableFocusRipple
        classes={{
          ...classes,
          text: clsx(buttonClasses.ghost, classes?.ghost),
          contained: clsx(buttonClasses.filled, classes?.filled),
        }}
        className={clsx(
          buttonClasses[camelCase(`color_${color}`) as ButtonClassKey],
          buttonClasses[camelCase(`shape_${shape}`) as ButtonClassKey],
          {
            [buttonClasses.edgeStart]: edge === 'start',
            [buttonClasses.edgeEnd]: edge === 'end',
          },
          classes?.[camelCase(`color_${color}`) as ButtonClassKey],
          classes?.[camelCase(`shape_${shape}`) as ButtonClassKey],
          {
            [classes?.edgeStart ?? '']: edge === 'start',
            [classes?.edgeEnd ?? '']: edge === 'end',
          },
          other.className,
        )}
      >
        {startIcon && (
          <span
            data-testid="button__start-icon"
            css={buttonIconStyles(size)}
            className={clsx(
              buttonClasses.icon,
              buttonClasses.startIcon,
              classes?.icon,
              classes?.startIcon,
            )}
          >
            {startIcon}
          </span>
        )}

        {children}

        {endIcon && (
          <span
            data-testid="button__end-icon"
            css={buttonIconStyles(size)}
            className={clsx(
              buttonClasses.icon,
              buttonClasses.endIcon,
              classes?.icon,
              classes?.endIcon,
            )}
          >
            {endIcon}
          </span>
        )}
      </MuiButton>
    );
  },
);
