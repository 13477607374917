import { Checkbox } from '@allganize/ui-checkbox';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@allganize/ui-form';
import { css } from '@emotion/react';
import { Fragment, forwardRef } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { FormValue } from './use-form-value-form';
import { ChatMedia } from '../chat-media/chat-media';

interface CheckboxFormValueProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<TFieldValues>;
  readOnly?: boolean;
  value: FormValue;
}

// custom typing in order to make component generic
interface CheckboxFormValueType {
  <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >(
    props: React.PropsWithoutRef<CheckboxFormValueProps<TFieldValues, TName>> &
      React.RefAttributes<HTMLFieldSetElement>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

// @ts-expect-error overridable component
export const CheckboxFormValue: CheckboxFormValueType = forwardRef(
  ({ field, fieldState, formState, readOnly, value }, ref) => {
    return (
      <FormControl
        component="fieldset"
        fullWidth
        required={value.mandatory}
        error={fieldState.invalid}
        disabled={readOnly}
        ref={ref}
      >
        <FormLabel component="legend">{value.display}</FormLabel>

        <FormGroup>
          {value.checkboxOptions.map(option => {
            const checked = field.value?.includes(option.value) ?? false;

            return (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(e, checked) => {
                      field.onChange(
                        checked
                          ? [...field.value, option.value]
                          : field.value.filter(
                              (v: number) => v !== option.value,
                            ),
                      );
                    }}
                  />
                }
                value={option.value}
                label={
                  <Fragment>
                    {option.label}
                    {option.data.media && (
                      <ChatMedia
                        media={option.data.media}
                        rootProps={{
                          onBlur: e => e.stopPropagation(),
                        }}
                        css={css`
                          margin-top: 4px;
                          margin-bottom: 4px;
                        `}
                      />
                    )}
                  </Fragment>
                }
              />
            );
          })}
        </FormGroup>

        {fieldState.error?.message && (
          <FormHelperText>{fieldState.error.message}</FormHelperText>
        )}
      </FormControl>
    );
  },
);
