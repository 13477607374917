import { PaperProps } from '@allganize/ui-paper';
import { useSlotProps } from '@mui/base/utils';
import { PopperPlacementType } from '@mui/material/Popper';
import { forwardRef } from 'react';
import {
  PickersPopperProps,
  PickersPopperSlotsComponentsProps,
} from './pickers-popper-type-map';

export interface PickersPopperPaperProps {
  PaperComponent: React.ElementType;
  children: React.ReactNode;
  ownerState: PickersPopperProps;
  popperPlacement: PopperPlacementType;
  paperClasses: string;
  onPaperClick: React.MouseEventHandler<HTMLDivElement>;
  onPaperTouchStart: React.TouchEventHandler<HTMLDivElement>;
  paperSlotProps?: PickersPopperSlotsComponentsProps['desktopPaper'];
}

export const PickersPopperPaperWrapper = forwardRef(
  (props: PickersPopperPaperProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      PaperComponent,
      popperPlacement,
      ownerState: inOwnerState,
      children,
      paperSlotProps,
      paperClasses,
      onPaperClick,
      onPaperTouchStart,
      // picks up the style props provided by `Transition`
      // https://mui.com/material-ui/transitions/#child-requirement
      ...other
    } = props;
    const ownerState = { ...inOwnerState, placement: popperPlacement };
    const paperProps: PaperProps = useSlotProps({
      elementType: PaperComponent,
      externalSlotProps: paperSlotProps,
      additionalProps: {
        tabIndex: -1,
        elevation: 3,
        ref,
      },
      className: paperClasses,
      // @ts-expect-error paper internal component
      ownerState,
    });
    return (
      <PaperComponent
        {...other}
        {...paperProps}
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          onPaperClick(event);
          paperProps.onClick?.(event);
        }}
        onTouchStart={(event: React.TouchEvent<HTMLDivElement>) => {
          onPaperTouchStart(event);
          paperProps.onTouchStart?.(event);
        }}
        ownerState={ownerState}
      >
        {children}
      </PaperComponent>
    );
  },
);
