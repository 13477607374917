import { IframeContext } from '@allganize/react-iframe';
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { CoercedMenuPlacement, GroupBase } from 'react-select';
import { MenuPlacerProps } from 'react-select/dist/declarations/src/components/Menu';
import { coercePlacement } from '../utils/coerce-placement';
import { getMenuPlacement } from '../utils/get-menu-placement';
import { PortalPlacementContext } from './portal-placement-context';

export const useMenuPlacer = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: Omit<MenuPlacerProps<Option, IsMulti, Group>, 'children'>,
) => {
  const {
    minMenuHeight,
    maxMenuHeight,
    menuPlacement,
    menuPosition,
    menuShouldScrollIntoView,
    theme,
  } = props;

  const { window: contentWindow } = useContext(IframeContext);
  const { setPortalPlacement } = useContext(PortalPlacementContext) || {};
  const ref = useRef<HTMLDivElement | null>(null);
  const [maxHeight, setMaxHeight] = useState(maxMenuHeight);
  const [placement, setPlacement] = useState<CoercedMenuPlacement | null>(null);
  const { controlHeight } = theme.spacing;

  const updateComputedPosition = useCallback(() => {
    const menuEl = ref.current;
    if (!menuEl) return;

    // DO NOT scroll if position is fixed
    const isFixedPosition = menuPosition === 'fixed';
    const shouldScroll = menuShouldScrollIntoView && !isFixedPosition;

    const state = getMenuPlacement({
      maxHeight: maxMenuHeight,
      menuEl,
      minHeight: minMenuHeight,
      placement: menuPlacement,
      shouldScroll,
      isFixedPosition,
      controlHeight,
      window: contentWindow,
    });

    setMaxHeight(state.maxHeight);
    setPlacement(state.placement);
    setPortalPlacement?.(state.placement);
  }, [
    contentWindow,
    controlHeight,
    maxMenuHeight,
    menuPlacement,
    menuPosition,
    menuShouldScrollIntoView,
    minMenuHeight,
    setPortalPlacement,
  ]);

  useLayoutEffect(() => {
    updateComputedPosition();
  }, [updateComputedPosition]);

  return {
    ref,
    placerProps: {
      ...props,
      placement: placement || coercePlacement(menuPlacement),
      maxHeight,
    },
    updateComputedPosition,
  };
};
