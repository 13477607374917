import { createSvgIcon } from '@allganize/ui-icons';

export const IcFileHWPX = createSvgIcon(
  <>
    <path
      d="M6 22.5469H20.3077C21.1361 22.5469 21.8077 21.8753 21.8077 21.0469V7.69265C21.8077 7.29885 21.6528 6.92085 21.3765 6.64024L16.7099 1.90072C16.428 1.61437 16.0429 1.45312 15.6411 1.45312H6C5.17157 1.45312 4.5 2.1247 4.5 2.95312V21.0469C4.5 21.8753 5.17157 22.5469 6 22.5469Z"
      fill="white"
    />
    <path
      d="M5.0625 2.95312C5.0625 2.43536 5.48223 2.01562 6 2.01562H15.6411C15.6631 2.01562 15.685 2.0164 15.7068 2.01793V5.57812C15.7068 6.71721 16.6302 7.64062 17.7693 7.64062H21.2437C21.2447 7.6579 21.2452 7.67525 21.2452 7.69265V21.0469C21.2452 21.5646 20.8255 21.9844 20.3077 21.9844H6C5.48223 21.9844 5.0625 21.5646 5.0625 21.0469V2.95312ZM20.4644 6.51562L16.8318 2.82621V5.57812C16.8318 6.09589 17.2515 6.51562 17.7693 6.51562H20.4644ZM6 0.890625C4.86091 0.890625 3.9375 1.81404 3.9375 2.95312V21.0469C3.9375 22.186 4.86092 23.1094 6 23.1094H20.3077C21.4468 23.1094 22.3702 22.186 22.3702 21.0469V7.69265C22.3702 7.15117 22.1573 6.63142 21.7774 6.24559L17.1108 1.50607C16.7231 1.11234 16.1936 0.890625 15.6411 0.890625H6Z"
      fill="black"
      fillOpacity="0.2"
      fillRule="evenodd"
    />
    <path
      d="M0.75 12.75C0.75 11.0931 2.09315 9.75 3.75 9.75H19.5C21.1569 9.75 22.5 11.0931 22.5 12.75V17.25C22.5 18.9069 21.1569 20.25 19.5 20.25H3.75C2.09315 20.25 0.75 18.9069 0.75 17.25V12.75Z"
      fill="#0070E0"
    />
    <path
      d="M2.6272 12.8184V17.182H3.68188V15.4284H5.40134V17.182H6.4539V12.8184H5.40134V14.5698H3.68188V12.8184H2.6272ZM7.92174 17.182L6.65186 12.8184H7.81734L8.47572 15.6777H8.51194L9.26407 12.8184H10.2144L10.9665 15.6841H11.0027L11.6632 12.8184H12.8266L11.5588 17.182H10.5425L9.75626 14.5357H9.72217L8.93595 17.182H7.92174ZM13.0217 12.8184V17.182H14.0764V15.8269H14.7923C15.1261 15.8269 15.4145 15.7651 15.6574 15.6415C15.9003 15.5179 16.0871 15.3439 16.2178 15.1195C16.3499 14.8936 16.4159 14.6301 16.4159 14.329C16.4159 14.0293 16.3513 13.7665 16.222 13.5407C16.0928 13.3134 15.9088 13.1365 15.6702 13.0101C15.433 12.8823 15.151 12.8184 14.8243 12.8184H13.0217ZM14.0764 14.9959H14.5963C14.7554 14.9959 14.8882 14.9682 14.9947 14.9128C15.1027 14.8574 15.1844 14.78 15.2398 14.6806C15.2952 14.5797 15.3229 14.4625 15.3229 14.329C15.3229 14.1941 15.2952 14.0776 15.2398 13.9796C15.1844 13.8801 15.1027 13.8034 14.9947 13.7495C14.8868 13.6955 14.754 13.6685 14.5963 13.6685H14.0764V14.9959ZM18.5199 14.1969L17.723 12.8184H16.5341L17.853 15.0002L16.5 17.182H17.7017L18.5199 15.7885H18.554L19.3722 17.182H20.5824L19.223 15.0002L20.5398 12.8184H19.3594L18.554 14.1969H18.5199Z"
      fill="white"
      fillRule="evenodd"
    />
  </>,
  'ic-file-hwpx',
);
