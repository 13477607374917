import { Divider, dividerClasses } from '@allganize/ui-divider';
import { IcDocumentSearch } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { draftDocumentListClasses } from './draft-document-list-classes';
import { DraftDocumentListItem } from './draft-document-list-item';
import { DraftDocumentListProps } from './draft-document-list-type-map';

export const DraftDocumentList: FunctionComponent<
  DraftDocumentListProps
> = props => {
  const theme = useTheme();
  const { classes, data, ...other } = props;

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <div
      data-testid="draft-document-list"
      css={css`
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: -2px;
        margin-top: 24px;

        > .${draftDocumentListClasses.document} {
          padding: 2px;
        }
      `}
      {...other}
      className={clsx(
        draftDocumentListClasses.root,
        classes?.root,
        other.className,
      )}
    >
      <Divider
        textAlign="left"
        css={css`
          ${theme.typography.body12}
          width: 100%;
          margin-bottom: 8px;
          color: ${theme.palette.text.secondary};

          // TODO: [ALL-14042] remove below. Sync the Divider component style with the design guide
          &::after {
            border-color: ${theme.palette.grayAlpha[200]};
          }

          .${dividerClasses.wrapper} {
            display: flex;
            align-items: center;
            gap: 4px;
            // TODO: [ALL-14042] remove below. Sync the Divider component style with the design guide
            padding-right: 8px;
          }
        `}
      >
        <IcDocumentSearch fontSize="small" />
        <FormattedMessage
          id="draft-document-list.learn-more"
          defaultMessage="LEARN MORE"
          description="DraftDocumentList divider text"
        />
      </Divider>
      {data.map(entity => {
        return (
          <div
            key={entity.key}
            css={css`
              max-width: 100%;
            `}
            className={clsx(
              draftDocumentListClasses.document,
              classes?.document,
            )}
          >
            <DraftDocumentListItem
              css={css`
                max-width: 100%;
              `}
              entity={entity}
            />
          </div>
        );
      })}
    </div>
  );
};
