import { Global, css } from '@emotion/react';
import { FunctionComponent } from 'react';

export const ScrollerStyles: FunctionComponent = () => {
  return (
    <Global
      styles={css`
        .simplebar-hide-scrollbar {
          position: fixed;
          left: 0;
          visibility: hidden;
          overflow-y: scroll;
          scrollbar-width: none;
          -ms-overflow-style: none;

          &::-webkit-scrollbar {
            display: none;
            width: 0;
            height: 0;
          }
        }

        .simplebar-dummy-scrollbar-size {
          direction: rtl;
          position: fixed;
          opacity: 0;
          visibility: hidden;
          height: 500px;
          width: 500px;
          overflow-y: hidden;
          overflow-x: scroll;
          -ms-overflow-style: scrollbar !important;

          > div {
            width: 200%;
            height: 200%;
            margin: 10px 0;
          }
        }
      `}
    />
  );
};
