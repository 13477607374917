export interface ClampOptions {
  /**
   * The number of lines that should be displayed, a css pixel value for height
   * as a string (i.e. "100px"), or "auto" to try and fill the available space
   * @default 2
   */
  clamp?: string | number | 'auto';
  /**
   * Use -webkit-line-clamp available in WebKit (Chrome, Safari) only
   * @default true
   */
  useNativeClamp?: boolean;
  /**
   * Split on sentences (periods), hyphens, en-dashes, em-dashes, and words (spaces).
   * @default ['.', '-', '–', '—', ' ']
   */
  splitOnChars?: string[];
  /**
   * Animate clamp
   * @default false
   */
  animate?: boolean;
  /**
   * The character to insert at the end of the HTML element after truncation is performed.
   * @default '…'
   */
  truncationChar?: string;
  /**
   * String of HTML to use instead of `truncationChar`
   */
  truncationHTML?: string;
}

/**
 * Clamps a text node.
 * @param {HTMLElement} element. Element containing the text node to clamp.
 * @param {Object} options. Options to pass to the clamper.
 */
export function clamp(
  element: HTMLElement,
  {
    clamp = 2,
    useNativeClamp = true,
    splitOnChars: splitOnCharsOpt = ['.', '-', '–', '—', ' '],
    animate = false,
    truncationChar = '…',
    truncationHTML,
  }: ClampOptions = {},
) {
  const sty = element.style;
  const originalText = element.innerHTML;
  const supportsNativeClamp =
    typeof element.style.webkitLineClamp != 'undefined';
  let truncationHTMLContainer: HTMLSpanElement | undefined;

  if (truncationHTML) {
    truncationHTMLContainer = document.createElement('span');
    truncationHTMLContainer.innerHTML = truncationHTML;
  }

  // UTILITY FUNCTIONS __________________________________________________________

  /**
   * Return the current style for an element.
   * @param {HTMLElement} elem The element to compute.
   * @param {string} prop The style property.
   * @returns {string}
   */
  function computeStyle(elem: Element, prop: string) {
    const computedStyle = window.getComputedStyle(elem, null);
    return computedStyle?.getPropertyValue(prop) ?? null;
  }

  /**
   * Returns the maximum number of lines of text that should be rendered based
   * on the current height of the element and the line-height of the text.
   */
  function getMaxLines(height?: number) {
    const availHeight =
      height ||
      ((element.parentNode as HTMLElement | null)?.clientHeight ?? 0) -
        element.offsetTop;
    const lineHeight = getLineHeight(element);

    return Math.max(Math.floor(availHeight / lineHeight), 0);
  }

  /**
   * Returns the maximum height a given element should have based on the line-
   * height of the text and the given clamp value.
   */
  function getMaxHeight(clmp: number) {
    const lineHeight = getLineHeight(element);
    return lineHeight * clmp;
  }

  /**
   * Returns the line-height of an element as an integer.
   */
  function getLineHeight(elem: Element) {
    let lh: string | number = computeStyle(elem, 'line-height');

    if (lh === 'normal') {
      // Normal line heights vary from browser to browser. The spec recommends
      // a value between 1.0 and 1.2 of the font size. Using 1.1 to split the diff.
      lh = Number.parseFloat(computeStyle(elem, 'font-size')) * 1.2;
    }

    return Math.round(typeof lh === 'number' ? lh : Number.parseFloat(lh));
  }

  // MEAT AND POTATOES (MMMM, POTATOES...) ______________________________________
  let splitOnChars = splitOnCharsOpt.slice(0);
  let splitChar: string | undefined = splitOnChars[0];
  let chunks: string[] | null;
  let lastChunk: string | null | undefined;

  /**
   * Gets an element's last child. That may be another node or a node's contents.
   */
  function getLastChild(elem: Node | undefined): Node | undefined {
    if (!elem?.lastChild) {
      return;
    }

    // Current element has children, need to go deeper and get last child as a text node
    if (
      (elem.lastChild as Element).children &&
      (elem.lastChild as Element).children.length > 0
    ) {
      return getLastChild(
        Array.prototype.slice.call((elem as ParentNode).children).pop(),
      );
    }

    if (
      !elem.lastChild ||
      !elem.lastChild.nodeValue ||
      elem.lastChild.nodeValue === truncationChar ||
      elem.lastChild.nodeType === Node.COMMENT_NODE
    ) {
      // Handle scenario where the last child is white-space node
      let sibling: Node | null = elem.lastChild;
      do {
        if (!sibling) {
          return;
        }
        // TEXT_NODE
        if (
          sibling.nodeType === 3 &&
          ['', truncationChar].indexOf(sibling.nodeValue as any) === -1 &&
          elem.lastChild.nodeType !== Node.COMMENT_NODE
        ) {
          return sibling;
        }
        if (sibling.lastChild) {
          const lastChild = getLastChild(sibling);
          if (lastChild) {
            return lastChild;
          }
        }
        //Current sibling is pretty useless
        sibling.parentNode?.removeChild(sibling);
      } while ((sibling = sibling.previousSibling));
    }

    // This is the last child we want, return it
    return elem.lastChild;
  }

  /**
   * Removes one character at a time from the text until its width or
   * height is beneath the passed-in max param.
   */
  function truncate(
    target: Node | undefined,
    maxHeight: number,
  ): string | undefined {
    if (!target || !maxHeight) {
      return;
    }

    /**
     * Resets global variables.
     */
    function reset() {
      splitOnChars = splitOnCharsOpt.slice(0);
      splitChar = splitOnChars[0];
      chunks = null;
      lastChunk = null;
    }

    const nodeValue = target.nodeValue?.replace(truncationChar, '') ?? '';

    //Grab the next chunks
    if (!chunks) {
      //If there are more characters to try, grab the next one
      if (splitOnChars.length > 0) {
        splitChar = splitOnChars.shift();
      }
      //No characters to chunk by. Go character-by-character
      else {
        splitChar = '';
      }

      chunks = nodeValue.split(splitChar ?? '');
    }

    //If there are chunks left to remove, remove the last one and see if
    // the nodeValue fits.
    if (chunks.length > 1) {
      lastChunk = chunks.pop();
      applyEllipsis(target, chunks.join(splitChar));
    }
    //No more chunks can be removed using this character
    else {
      chunks = null;
    }

    //Insert the custom HTML before the truncation character
    if (truncationHTMLContainer) {
      target.nodeValue = target.nodeValue?.replace(truncationChar, '') ?? null;
      element.innerHTML =
        target.nodeValue +
        ' ' +
        truncationHTMLContainer.innerHTML +
        truncationChar;
    }

    //Search produced valid chunks
    if (chunks) {
      //It fits
      if (element.clientHeight <= maxHeight) {
        //There's still more characters to try splitting on, not quite done yet
        if (splitOnChars.length >= 0 && splitChar !== '') {
          applyEllipsis(target, chunks.join(splitChar) + splitChar + lastChunk);
          chunks = null;
        }
        //Finished!
        else {
          return element.innerHTML;
        }
      }
    }
    //No valid chunks produced
    else {
      //No valid chunks even when splitting by letter, time to move
      //on to the next node
      if (splitChar === '') {
        applyEllipsis(target, '');
        target = getLastChild(element);

        reset();
      }
    }

    //If you get here it means still too big, let's keep truncating
    if (animate) {
      setTimeout(
        function () {
          truncate(target, maxHeight);
        },
        animate === true ? 10 : animate,
      );
    }

    return truncate(target, maxHeight);
  }

  function applyEllipsis(elem: Node, str: string) {
    elem.nodeValue = str + truncationChar;
  }

  // CONSTRUCTOR ________________________________________________________________

  let clampValue = clamp;
  const isCSSValue =
    typeof clampValue === 'string' &&
    (clampValue.indexOf('px') > -1 || clampValue.indexOf('em') > -1);

  if (clampValue === 'auto') {
    clampValue = getMaxLines();
  } else if (isCSSValue) {
    clampValue = getMaxLines(
      typeof clampValue === 'number'
        ? clampValue
        : Number.parseInt(clampValue, 10),
    );
  }

  let clampedText: string | undefined;

  if (supportsNativeClamp && useNativeClamp) {
    sty.overflow = 'hidden';
    sty.textOverflow = 'ellipsis';
    sty.webkitBoxOrient = 'vertical';
    sty.display = '-webkit-box';
    sty.webkitLineClamp = `${clampValue}`;

    if (isCSSValue) {
      sty.height = clamp + 'px';
    }
  } else {
    const height = getMaxHeight(clampValue as number);

    if (height < element.clientHeight) {
      clampedText = truncate(getLastChild(element), height);
    }
  }

  return {
    original: originalText,
    clamped: clampedText,
  };
}
