import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type AssistantCreatedLLMAppOptionClassKey = 'root' | 'button';
export type AssistantCreatedLLMAppOptionClasses = Record<
  AssistantCreatedLLMAppOptionClassKey,
  string
>;

export const assistantCreatedLLMAppOptionClasses: AssistantCreatedLLMAppOptionClasses =
  generateUtilityClasses('AssistantCreatedLLMAppOption', ['root', 'button']);
