import {
  ApolloClient,
  ApolloLink,
  NormalizedCacheObject,
  split,
} from '@apollo/client/core';
import { ContextSetter, setContext } from '@apollo/client/link/context';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { AuthClient } from '../auth/auth-client';
import { logger } from '../core/logger/logger';
import { environment } from '../environments/environment';
import { getRegion } from '../utils/get-region';
import { cache } from './cache';
import { httpLink } from './http-link';
import {
  SubscriptionClient,
  createSubscriptionClient,
} from './subscription-client';

export class GraphQLClient {
  private static async getSubscriptionUrl() {
    const region = getRegion();

    return region === 'ja' || region === 'kr'
      ? environment.api[region].ws
      : environment.api.default.ws;
  }

  public static getProjectTokenParams() {
    const params = new URLSearchParams(window.location.search);
    const projectToken = params.get('project_token');

    return {
      'PROJECT-TOKEN': projectToken,
    };
  }

  public readonly client: ApolloClient<NormalizedCacheObject>;
  public readonly subscriptionClient: SubscriptionClient;

  constructor(private authClient: AuthClient) {
    this.subscriptionClient = createSubscriptionClient({
      lazy: true,
      shouldRetry: () => true,
      url: GraphQLClient.getSubscriptionUrl,
      connectionParams: this.subscriptionClientConnectionParams,
    });

    const requestLink = split(
      op => {
        const definition = getMainDefinition(op.query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      new GraphQLWsLink(this.subscriptionClient),
      httpLink,
    );

    this.client = new ApolloClient<NormalizedCacheObject>({
      version: 'version',
      cache,
      link: ApolloLink.from([
        onError(this.errorHandler),
        setContext(this.contextSetter),
        requestLink,
      ]),
      connectToDevTools: logger.enabled,
      defaultOptions: {
        watchQuery: {
          notifyOnNetworkStatusChange: true,
        },
      },
    });
  }

  private errorHandler: ErrorHandler = error => {
    const { graphQLErrors, networkError } = error;

    if (graphQLErrors) {
      graphQLErrors.forEach(graphQLError => {
        logger.error(
          `[GraphQL error]: Message: ${graphQLError.message}, Location: ${graphQLError.locations}, Path: ${graphQLError.path}`,
        );
      });
    }

    if (networkError) {
      logger.error(`[Network error]: ${networkError}`);

      if ('statusCode' in networkError && networkError.statusCode === 403) {
        this.authClient.signOut();
      }
    }
  };

  private contextSetter: ContextSetter = async (operation, prev) => {
    const region = getRegion();

    return {
      ...prev,
      uri:
        region === 'ja' || region === 'kr'
          ? environment.api[region].http
          : environment.api.default.http,
      headers: {
        ...prev.headers,
        ...(await this.authClient.getHeaders()),
        ...GraphQLClient.getProjectTokenParams(),
      },
    };
  };

  private subscriptionClientConnectionParams = async () => {
    return {
      ...(await this.authClient.getHeaders()),
      ...GraphQLClient.getProjectTokenParams(),
    };
  };
}
