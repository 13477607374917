import { useId } from '@allganize/hooks';
import { FormControl, FormHelperText } from '@allganize/ui-form';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { Input } from '../input';
import { InputLabel } from '../input-label';
import { inputFieldClasses } from './input-field-classes';
import { InputFieldProps } from './input-field-type-map';

export const InputField = forwardRef<HTMLDivElement, InputFieldProps>(
  (props, ref) => {
    const {
      autoComplete,
      autoFocus = false,
      children,
      classes,
      className,
      color = 'primary',
      defaultValue,
      disabled = false,
      error = false,
      FormHelperTextProps,
      fullWidth = false,
      helperText,
      id: idProp,
      InputLabelProps,
      inputProps,
      InputProps,
      inputRef,
      label,
      maxRows,
      minRows,
      multiline = false,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      required = false,
      rows,
      type,
      value,
      ...other
    } = props;

    const reactId = useId();
    const id = idProp || `input-field-${reactId}`;
    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
    const inputLabelId = label && id ? `${id}-label` : undefined;

    return (
      <FormControl
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        required={required}
        color={color}
        {...other}
        ref={ref}
        className={clsx(inputFieldClasses.root, classes?.root, className)}
      >
        {label !== null && label !== '' && (
          <InputLabel htmlFor={id} id={inputLabelId} {...InputLabelProps}>
            {label}
          </InputLabel>
        )}

        <Input
          aria-describedby={helperTextId}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          fullWidth={fullWidth}
          multiline={multiline}
          name={name}
          rows={rows}
          maxRows={maxRows}
          minRows={minRows}
          type={type}
          value={value}
          id={id}
          inputRef={inputRef}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          inputProps={inputProps}
          {...InputProps}
          ref={InputProps?.ref as React.Ref<HTMLDivElement> | undefined | null}
        />

        {helperText && (
          <FormHelperText id={helperTextId} {...FormHelperTextProps}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);
