import { combineRefs } from '@allganize/hooks';
import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
} from '@allganize/ui-button';
import {
  IcAdd,
  IcAttachment,
  IcFile,
  IcKnowledgeBase,
} from '@allganize/ui-icons';
import { ListItemIcon, ListItemText } from '@allganize/ui-list';
import { Menu, MenuItem, MenuProps } from '@allganize/ui-menu';
import { generateUtilityClasses } from '@mui/material';
import clsx from 'clsx';
import { forwardRef, Fragment, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AssistantDocumentUploadSource } from './assistant-document-fallback-type-map';

export const assistantDocumentUploadButtonClasses = generateUtilityClasses(
  'assistantDocumentUploadButton',
  ['root', 'menu'],
);

interface AssistantDocumentUploadButtonProps
  extends Omit<ButtonProps, 'onSelect' | 'classes'>,
    Omit<IconButtonProps, 'onSelect' | 'classes'> {
  menuProps?: Partial<Omit<MenuProps, 'anchorEl'>>;
  onSelect?: (
    value: AssistantDocumentUploadSource,
    ev: React.MouseEvent,
  ) => void;
  classes?: Partial<typeof assistantDocumentUploadButtonClasses>;
  buttonType?: 'icon' | 'default';
}

export const AssistantDocumentUploadButton = forwardRef<
  HTMLButtonElement,
  AssistantDocumentUploadButtonProps
>(
  (
    {
      menuProps,
      onSelect,
      onClick,
      buttonType = 'default',
      classes,
      ...others
    },
    ref,
  ) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const mergedRef = combineRefs(buttonRef, ref);
    const [open, setOpen] = useState(menuProps?.open ?? false);
    const handleOpen = (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      setOpen(true);
      onClick?.(ev);
    };
    const handleClose = (ev: React.MouseEvent) => {
      ev.stopPropagation();
      setOpen(false);
    };
    const handleClickItem = (
      value: AssistantDocumentUploadSource,
      ev: React.MouseEvent,
    ) => {
      ev.stopPropagation();
      onSelect?.(value, ev);
      setOpen(false);
    };

    return (
      <Fragment>
        {buttonType === 'icon' ? (
          <IconButton
            size="large"
            ref={mergedRef}
            onClick={handleOpen}
            {...others}
            className={clsx(
              assistantDocumentUploadButtonClasses.root,
              classes?.root,
              others.className,
            )}
          >
            <IcAttachment />
          </IconButton>
        ) : (
          <Button
            size="small"
            startIcon={<IcAdd />}
            variant="outlined"
            ref={mergedRef}
            onClick={handleOpen}
            {...others}
            className={clsx(
              assistantDocumentUploadButtonClasses.root,
              classes?.root,
              others.className,
            )}
          >
            <FormattedMessage
              id="assistant-document-fallback.add-documents-button"
              defaultMessage="Add documents"
              description="assistant document fallback add documents button label text"
            />
          </Button>
        )}
        {buttonRef.current && (
          <AssistantDocumentUploadMenu
            {...menuProps}
            className={clsx(
              assistantDocumentUploadButtonClasses.menu,
              menuProps?.className,
            )}
            open={open}
            onClose={handleClose}
            anchorEl={buttonRef.current}
            onSelect={handleClickItem}
          />
        )}
      </Fragment>
    );
  },
);

interface AssistantDocumentUploadMenuProps extends Omit<MenuProps, 'onSelect'> {
  onSelect?: (
    value: AssistantDocumentUploadSource,
    ev: React.MouseEvent,
  ) => void;
}

const AssistantDocumentUploadMenu = ({
  onSelect,
  ...others
}: AssistantDocumentUploadMenuProps) => {
  return (
    <Menu
      disableScrollLock
      disableAutoFocusItem
      anchorOrigin={{
        vertical: -4,
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      {...others}
    >
      <MenuItem
        onClick={ev =>
          onSelect?.(AssistantDocumentUploadSource.KnowledgeBase, ev)
        }
      >
        <ListItemIcon>
          <IcKnowledgeBase />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage
            id="chatform.assistant.document.upload.knowledgebase"
            defaultMessage="Add from Knowledge base"
            description="Add document from knowledge base menu label"
          />
        </ListItemText>
      </MenuItem>
      <MenuItem
        onClick={ev => onSelect?.(AssistantDocumentUploadSource.Computer, ev)}
      >
        <ListItemIcon>
          <IcFile />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage
            id="chatform.assistant.document.upload.computer"
            defaultMessage="Upload from computer"
            description="Upload document from computer menu label"
          />
        </ListItemText>
      </MenuItem>
    </Menu>
  );
};
