import { OverridableComponent } from '@allganize/types';
import MuiFormControl from '@mui/material/FormControl';
import { forwardRef } from 'react';
import { FormControlTypeMap } from './form-control-type-map';

// @ts-expect-error overridable component
export const FormControl: OverridableComponent<FormControlTypeMap> = forwardRef(
  (props, ref) => {
    return (
      <MuiFormControl
        data-testid="form-control"
        margin="none"
        variant="outlined"
        {...props}
        ref={ref}
      />
    );
  },
);
