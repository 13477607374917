import { OverridableComponent } from '@allganize/types';
import MuiTableContainer from '@mui/material/TableContainer';
import { forwardRef } from 'react';
import { TableContainerTypeMap } from './table-container-type-map';

// @ts-expect-error overridable component
export const TableContainer: OverridableComponent<TableContainerTypeMap> =
  forwardRef((props, ref) => {
    return (
      <MuiTableContainer data-testid="table-container" {...props} ref={ref} />
    );
  });
