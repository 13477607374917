import { CamelCase } from '@allganize/types';
import generateUtilityClasses from '@mui/material/generateUtilityClasses';
import { CoreDraftBlockType } from './draft-input-base-type-map';

export type DraftInputInternalClassKey =
  | 'root'
  | 'alignLeft'
  | 'alignCenter'
  | 'alignRight'
  | 'editorContainer'
  | 'notranslate'
  | 'content'
  | 'placeholderRoot'
  | 'placeholderInner';

export type DraftInputInternalClasses = Record<
  DraftInputInternalClassKey,
  string
>;
export const draftInputInternalClasses: DraftInputInternalClasses = {
  root: 'DraftEditor-root',
  alignLeft: 'DraftEditor-alignLeft',
  alignCenter: 'DraftEditor-alignCenter',
  alignRight: 'DraftEditor-alightRight',
  editorContainer: 'DraftEditor-editorContainer',
  notranslate: 'notranslate',
  content: 'public-DraftEditor-content',
  placeholderRoot: 'public-DraftEditorPlaceholder-root',
  placeholderInner: 'public-DraftEditorPlaceholder-inner',
};

export type DraftInputBaseClassKey =
  | 'root'
  | 'block'
  | CamelCase<`block-${CoreDraftBlockType}`>
  | 'disabled';

export type DraftInputBaseClasses = Record<DraftInputBaseClassKey, string>;

export const draftInputBaseClasses: DraftInputBaseClasses =
  generateUtilityClasses('DraftInputBase', [
    'root',
    'block',
    'blockHeaderOne',
    'blockHeaderTwo',
    'blockHeaderThree',
    'blockHeaderFour',
    'blockHeaderFive',
    'blockHeaderSix',
    'blockSection',
    'blockArticle',
    'blockUnorderedListItem',
    'blockOrderedListItem',
    'blockBlockquote',
    'blockAtomic',
    'blockCodeBlock',
    'blockUnstyled',
    'disabled',
  ]);
