import {
  KnowledgeBase,
  KnowledgeBaseCard,
  KnowledgeBaseCardContainer,
} from '@allganize/knowledge-base-assistant';
import { css } from '@emotion/react';
import { forwardRef, HTMLAttributes, useMemo } from 'react';
import { ChatKnowledgeBaseFragment } from '../graphql/fragments/chat-knowledge-base-fragment';

export interface ChatKnowledgeBaseProps extends HTMLAttributes<HTMLDivElement> {
  data: ChatKnowledgeBaseFragment[];
}

export const ChatKnowledgebase = forwardRef<
  HTMLDivElement,
  ChatKnowledgeBaseProps
>(({ data, ...others }, ref) => {
  const cardData = useMemo(
    () =>
      data.map(kb => {
        return {
          id: kb.id,
          type: kb.__typename,
          fileName: kb.title,
        } satisfies KnowledgeBase;
      }),
    [data],
  );
  return (
    <KnowledgeBaseCardContainer
      css={css`
        margin-top: 12px;
      `}
      ref={ref}
      {...others}
    >
      {cardData.map(kb => (
        <KnowledgeBaseCard key={kb.id} data={kb} />
      ))}
    </KnowledgeBaseCardContainer>
  );
});
