import { AlliDeviceIdManager } from '@allganize/alli-sdk/core/alli-auth-client/alli-device-id-manager';
import { ApolloClient } from '@apollo/client/core';
import {
  AuthState,
  OktaAuth,
  SigninWithRedirectOptions,
} from '@okta/okta-auth-js';
import { EventEmitter } from 'eventemitter3';
import {
  UserLoginMutation,
  UserLoginMutationDocument,
  UserLoginMutationVariables,
} from '../graphql/mutations/user-login-mutation';
import { EntraIdAuthClient } from '../services/entra-id/entra-id-auth-client';
import { ApiKeyManager } from './api-key-manager';
import { AuthClientEvents } from './auth-client.types';
import { AuthTokenManager } from './auth-token-manager';

type AuthClientProvider = 'entra-id' | 'okta';

interface AuthClientOptions {
  apiKeyManager: ApiKeyManager;
  authTokenManager: AuthTokenManager;
  deviceIdManager: AlliDeviceIdManager;
}

export class AuthClient extends EventEmitter<AuthClientEvents> {
  private oktaAuth: OktaAuth | null = null;
  private entraIdAuth: EntraIdAuthClient | null = null;

  public readonly apiKeyManager: ApiKeyManager;
  public readonly authTokenManager: AuthTokenManager;
  public readonly deviceIdManager: AlliDeviceIdManager;

  constructor({
    apiKeyManager,
    authTokenManager,
    deviceIdManager,
  }: AuthClientOptions) {
    super();
    this.apiKeyManager = apiKeyManager;
    this.authTokenManager = authTokenManager;
    this.deviceIdManager = deviceIdManager;
  }

  public getOktaAuth() {
    return this.oktaAuth;
  }

  public setOktaAuth(oktaAuth: OktaAuth | null) {
    if (this.oktaAuth === oktaAuth) {
      return;
    }

    this.oktaAuth?.authStateManager.unsubscribe(
      this.handleOktaAuthStateChanged,
    );
    this.oktaAuth = oktaAuth;
    this.oktaAuth?.authStateManager.subscribe(this.handleOktaAuthStateChanged);
    this.emit('oktaAuthChanged', oktaAuth);
  }

  public getEntraIdAuth() {
    return this.entraIdAuth;
  }

  public setEntraIdAuth(entraIdAuth: EntraIdAuthClient | null) {
    if (this.entraIdAuth === entraIdAuth) {
      return;
    }

    this.entraIdAuth = entraIdAuth;
  }

  public getProviders() {
    const result: AuthClientProvider[] = [];

    if (this.oktaAuth) {
      result.push('okta');
    }

    if (this.entraIdAuth) {
      result.push('entra-id');
    }

    return result;
  }

  public async signInWithOkta(options?: SigninWithRedirectOptions) {
    if (!this.oktaAuth) {
      throw new Error('OktaAuth is not available');
    }

    await this.oktaAuth.signInWithRedirect(options);
  }

  public async signInWithOktaToken(client: ApolloClient<any>) {
    if (!this.oktaAuth) {
      throw new Error('OktaAuth is not available');
    }

    const idToken = this.oktaAuth.getIdToken();

    if (!idToken) {
      throw new Error('IdToken is not available');
    }

    const result = await client.mutate<
      UserLoginMutation,
      UserLoginMutationVariables
    >({
      mutation: UserLoginMutationDocument,
      variables: { oktaSsoIdToken: idToken },
    });

    if (result.data?.userLogin?.token) {
      await this.authTokenManager.setAuthToken(result.data.userLogin.token);
      return;
    }

    throw new Error('Failed to sign in with Okta token');
  }

  public async signInWithEntraId(relayState?: string) {
    if (!this.entraIdAuth) {
      throw new Error('EntraIdAuth is not available');
    }

    this.entraIdAuth.signInWithRedirect(relayState);
  }

  public async signInWithEntraIdToken(
    token: string,
    client: ApolloClient<any>,
  ) {
    if (!this.entraIdAuth) {
      throw new Error('EntraIdAuth is not available');
    }

    const result = await client.mutate<
      UserLoginMutation,
      UserLoginMutationVariables
    >({
      mutation: UserLoginMutationDocument,
      variables: { userEntraIdToken: token },
    });

    if (result.data?.userLogin?.token) {
      await this.authTokenManager.setAuthToken(result.data.userLogin.token);
      return;
    }

    throw new Error('Failed to sign in with Entra ID token');
  }

  public signOut() {
    return this.authTokenManager.setAuthToken(null);
  }

  public async getHeaders() {
    const apiKey = this.apiKeyManager.getApiKey();
    const deviceId = await this.deviceIdManager.getDeviceId();
    const authToken = await this.authTokenManager.getAuthToken();

    const result: Record<string, string> = {
      'finger-print': deviceId,
    };

    if (authToken) {
      result['user-token'] = authToken;
    }

    if (apiKey) {
      result['api-key'] = apiKey;
    }

    return result;
  }

  private handleOktaAuthStateChanged = (authState: AuthState) => {
    this.emit('oktaAuthStateChanged', authState);
  };
}
