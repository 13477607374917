/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { ErrorFragment } from '../fragments/error-fragment';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GenerateSingleActionAppResultPublicMutationVariables =
  UserQueryTypes.Exact<{
    where: UserQueryTypes.ProjectWhereUniqueInput;
    appId: UserQueryTypes.Scalars['ID']['input'];
    accessToken: UserQueryTypes.Scalars['String']['input'];
    publicToken: UserQueryTypes.Scalars['String']['input'];
    userInput?: UserQueryTypes.InputMaybe<
      UserQueryTypes.Scalars['JSONString']['input']
    >;
    singleActionAppSelectedDocumentInputInfo?: UserQueryTypes.InputMaybe<
      Array<UserQueryTypes.SingleActionAppSelectedDocumentInputInfo>
    >;
  }>;

export type GenerateSingleActionAppResultPublicMutation = {
  __typename: 'UserMutation';
} & {
  generateSingleActionAppResultPublic: UserQueryTypes.Maybe<
    { __typename: 'GenerateSingleActionAppResultPublic' } & {
      errors: UserQueryTypes.Maybe<
        Array<UserQueryTypes.Maybe<{ __typename: 'Error' } & ErrorFragment>>
      >;
    }
  >;
};

export const GenerateSingleActionAppResultPublicMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'GenerateSingleActionAppResultPublicMutation',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectWhereUniqueInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accessToken' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'publicToken' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userInput' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JSONString' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'singleActionAppSelectedDocumentInputInfo',
            },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'SingleActionAppSelectedDocumentInputInfo',
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'generateSingleActionAppResultPublic',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectWhere' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'singleActionAppId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'appId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accessToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accessToken' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'publicToken' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userInput' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'singleActionAppSelectedDocumentInputInfo',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'singleActionAppSelectedDocumentInputInfo',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ErrorFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ErrorFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'field' } },
          { kind: 'Field', name: { kind: 'Name', value: 'info' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateSingleActionAppResultPublicMutation,
  GenerateSingleActionAppResultPublicMutationVariables
>;
