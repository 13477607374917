import {
  dialogContentClasses as muiDialogContentClasses,
  DialogContentClassKey as MuiDialogContentClassKey,
} from '@mui/material/DialogContent';
import { omit } from 'lodash-es';

export type DialogContentClassKey = Exclude<
  MuiDialogContentClassKey,
  'dividers'
>;
export type DialogContentClasses = Record<DialogContentClassKey, string>;

export const dialogContentClasses: DialogContentClasses = omit(
  muiDialogContentClasses,
  ['dividers'],
);
