import { useMediaQuery } from '@allganize/hooks';
import { forwardRef } from 'react';
import { DesktopDateTimePicker } from '../desktop-date-time-picker';
import { DEFAULT_DESKTOP_MODE_MEDIA_QUERY } from '../internals/utils/utils';
import {
  MobileDateTimePicker,
  MobileDateTimePickerProps,
} from '../mobile-date-time-picker';
import { DateTimePickerProps } from './date-time-picker-type-map';

export const DateTimePicker = forwardRef<HTMLDivElement, DateTimePickerProps>(
  (props, ref) => {
    const {
      desktopModeMediaQuery = DEFAULT_DESKTOP_MODE_MEDIA_QUERY,
      ...other
    } = props;

    // defaults to `true` in environments where `window.matchMedia` would not be available (i.e. test/jsdom)
    const isDesktop = useMediaQuery(desktopModeMediaQuery, {
      defaultMatches: true,
    });

    if (isDesktop) {
      return <DesktopDateTimePicker ref={ref} {...other} />;
    }

    return (
      <MobileDateTimePicker
        ref={ref}
        {...(other as MobileDateTimePickerProps)}
      />
    );
  },
);
