import {
  listItemClasses as muiListItemClasses,
  ListItemClassKey as MuiListItemClassKey,
} from '@mui/material/ListItem';
import { omit } from 'lodash-es';

export type ListItemClassKey = Exclude<
  MuiListItemClassKey,
  | 'focusVisible'
  | 'dense'
  | 'disabled'
  | 'gutters'
  | 'button'
  | 'selected'
  | 'divider'
>;
export type ListItemClasses = Record<ListItemClassKey, string>;

export const listItemClasses: ListItemClasses = omit(muiListItemClasses, [
  'focusVisible',
  'dense',
  'disabled',
  'gutters',
  'button',
  'selected',
  'divider',
]);
