import { CoreDraftBlockType } from '@allganize/draft-input';

export const headerStyleMap = {
  'header-one': {
    fontSize: 26,
  },
  'header-two': {
    fontSize: 24,
  },
  'header-three': {
    fontSize: 22,
  },
  'header-four': {
    fontSize: 20,
  },
  'header-five': {
    fontSize: 18,
  },
  'header-six': {
    fontSize: 16,
  },
} satisfies Partial<Record<CoreDraftBlockType, React.CSSProperties>>;
