import { ContentState } from '@allganize/draft-input';
import { DraftAutoLinkPlugin } from '@allganize/draft-link-plugin';
import { useMemo } from 'react';
import { defaultPlugins } from '../rich-text/use-rich-text';

const plugins = defaultPlugins.filter(
  plugin => plugin instanceof DraftAutoLinkPlugin,
);

interface UsePlainTextOptions {
  data: string | null;
}

export const usePlainText = ({ data }: UsePlainTextOptions) => {
  const contentState = useMemo<ContentState | null>(() => {
    if (!data) {
      return null;
    }

    return ContentState.createFromText(data, '\n');
  }, [data]);

  return { contentState, plugins };
};
