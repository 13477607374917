import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PickersInputLocaleText } from '@mui/x-date-pickers/locales/utils/pickersLocaleTextApi';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FunctionComponent } from 'react';
import { enUS } from '../locales/en-us';
import { LocalizationProviderProps } from './localization-provider-type-map';

export const LocalizationProvider: FunctionComponent<
  LocalizationProviderProps
> = props => {
  const { localeText = enUS, ...other } = props;

  return (
    <MuiLocalizationProvider
      localeText={localeText as PickersInputLocaleText<Date>}
      {...other}
      dateAdapter={AdapterDateFns}
    />
  );
};
