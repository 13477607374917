import { Divider, DividerProps } from '@allganize/ui-divider';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef, memo } from 'react';
import {
  DraftToolbarSeparatorClasses,
  draftToolbarSeparatorClasses,
} from './draft-toolbar-separator.classes';

interface DraftToolbarSeparatorProps extends DividerProps {
  classes?: Partial<DraftToolbarSeparatorClasses>;
}

export const DraftToolbarSeparator = memo(
  forwardRef<HTMLHRElement, DraftToolbarSeparatorProps>((props, ref) => {
    const { classes, ...other } = props;

    return (
      <Divider
        data-testid="draft-toolbar-separator"
        css={css`
          margin: 0 4px;
          height: 16px;
          align-self: center;
        `}
        flexItem
        orientation="vertical"
        {...other}
        ref={ref}
        className={clsx(
          draftToolbarSeparatorClasses.root,
          classes?.root,
          other.className,
        )}
      />
    );
  }),
);
