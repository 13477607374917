import {
  DraftField,
  EditorState,
  isDraftInputEmpty,
} from '@allganize/draft-input';
import { useId } from '@allganize/hooks';
import { LoadingButton } from '@allganize/ui-button';
import { DatePicker, PickersModalDialogRoot } from '@allganize/ui-date-picker';
import { DialogProps } from '@allganize/ui-dialog';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  formControlLabelClasses,
} from '@allganize/ui-form';
import { NumberField } from '@allganize/ui-number-input';
import { SelectField } from '@allganize/ui-select';
import { Switch } from '@allganize/ui-switch';
import { CacheProvider, ClassNames, css } from '@emotion/react';
import { FunctionComponent, forwardRef, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, useFieldArray } from 'react-hook-form';
import { AgentSelectOption } from '../agent-select/agent-select-option';
import { useAgentSelect } from '../agent-select/use-agent-select';
import { ModalContext } from '../modal/modal-context';
import { CheckboxFormValue } from './checkbox-form-value';
import { FileFormValue } from './file-form-value';
import { FormValueReadOnly } from './form-value-read-only';
import { RadioFormValue } from './radio-form-value';
import {
  FormValueOptionType,
  UseFormValueFormOptions,
  useFormValueForm,
} from './use-form-value-form';
import { TypingContext } from '../typing-context/typing-context';

const DatePickerDialog = forwardRef<HTMLDivElement, DialogProps>(
  (props, ref) => {
    const modal = useContext(ModalContext);
    const dialog = <PickersModalDialogRoot {...props} ref={ref} />;

    if (!modal.emotionCache) {
      return dialog;
    }

    return <CacheProvider value={modal.emotionCache}>{dialog}</CacheProvider>;
  },
);

interface FormValueFormProps extends UseFormValueFormOptions {
  className?: string;
  readOnly?: boolean;
}

export const FormValueForm: FunctionComponent<FormValueFormProps> = ({
  className,
  defaultValues,
  onSubmit,
  readOnly,
}) => {
  const formId = useId();
  const modal = useContext(ModalContext);
  const { form, formRef, submit } = useFormValueForm({
    defaultValues,
    onSubmit,
  });
  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    watch,
  } = form;
  const { fields } = useFieldArray({ control, name: 'formValues' });
  const agentSelectProps = useAgentSelect<AgentSelectOption, true>({});
  const [agentSelectEnabled, agentSelectOptions] = watch([
    'agentSelect.enabled',
    'agentSelect.options',
  ]);

  const { onTyping } = useContext(TypingContext);
  const hasText = (value: EditorState | null) => {
    return !isDraftInputEmpty(value);
  };

  useEffect(() => {
    if (!readOnly) {
      const subscription = watch(({ formValues }) => {
        onTyping(
          !!formValues?.some(v => {
            return (
              v?.dateValue !== null ||
              v.fileValue !== null ||
              v.numberValue !== null ||
              hasText(
                v.stringValue instanceof EditorState ? v.stringValue : null,
              )
            );
          }),
        );
      });

      return () => subscription.unsubscribe();
    }
  }, [onTyping, watch, readOnly]);

  return (
    <form
      css={css`
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
      `}
      className={className}
      onSubmit={handleSubmit(submit)}
      ref={formRef}
    >
      {fields.map((item, i) => {
        if (readOnly) {
          return <FormValueReadOnly key={item.id} formValue={item} />;
        }

        if (item.fieldType === 'checkbox') {
          return (
            <Controller
              key={item.id}
              control={control}
              name={`formValues.${i}.checkboxValue`}
              render={props => {
                return (
                  <CheckboxFormValue
                    {...props}
                    readOnly={readOnly}
                    value={item}
                  />
                );
              }}
            />
          );
        }

        if (item.fieldType === 'radio') {
          return (
            <Controller
              key={item.id}
              control={control}
              name={`formValues.${i}.radioValue`}
              render={props => {
                return (
                  <RadioFormValue
                    {...props}
                    formId={formId}
                    readOnly={readOnly}
                    value={item}
                  />
                );
              }}
            />
          );
        }

        if (item.fieldType === 'multi-select') {
          return (
            <Controller
              key={item.id}
              control={control}
              name={`formValues.${i}.multiSelectValue`}
              render={({ field: { ref, ...field }, fieldState }) => {
                return (
                  <SelectField<FormValueOptionType, true>
                    {...field}
                    isMulti
                    label={item.display}
                    options={item.multiSelectOptions}
                    fullWidth
                    required={item.mandatory}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    disabled={readOnly}
                  />
                );
              }}
            />
          );
        }

        if (item.fieldType === 'select') {
          return (
            <Controller
              key={item.id}
              control={control}
              name={`formValues.${i}.selectValue`}
              render={({ field: { ref, ...field }, fieldState }) => {
                return (
                  <SelectField<FormValueOptionType>
                    {...field}
                    label={item.display}
                    options={item.selectOptions}
                    fullWidth
                    required={item.mandatory}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    disabled={readOnly}
                  />
                );
              }}
            />
          );
        }

        if (item.fieldType === 'number') {
          return (
            <Controller
              key={item.id}
              control={control}
              name={`formValues.${i}.numberValue`}
              render={({ field: { onChange, ref, ...field }, fieldState }) => {
                return (
                  <NumberField
                    {...field}
                    inputRef={ref}
                    label={item.display}
                    onValueChange={values => {
                      onChange(values.floatValue ?? null);
                    }}
                    fullWidth
                    required={item.mandatory}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    disabled={readOnly}
                  />
                );
              }}
            />
          );
        }

        if (item.fieldType === 'boolean') {
          return (
            <Controller
              key={item.id}
              control={control}
              name={`formValues.${i}.booleanValue`}
              render={({ field, fieldState }) => {
                return (
                  <FormControl
                    component="fieldset"
                    fullWidth
                    required={item.mandatory}
                    error={fieldState.invalid}
                    disabled={readOnly}
                  >
                    <FormGroup>
                      <ClassNames>
                        {({ css }) => (
                          <FormControlLabel
                            classes={{
                              labelPlacementStart: css`
                                margin-left: 0;

                                .${formControlLabelClasses.label} {
                                  flex-grow: 1;
                                }
                              `,
                            }}
                            labelPlacement="start"
                            control={
                              <Switch
                                name={field.name}
                                checked={field.value ?? false}
                                onChange={(e, checked) => {
                                  field.onChange(checked);
                                }}
                              />
                            }
                            label={
                              <FormLabel component="legend">
                                {item.display}
                              </FormLabel>
                            }
                          />
                        )}
                      </ClassNames>
                    </FormGroup>

                    {fieldState.error?.message && (
                      <FormHelperText>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />
          );
        }

        if (item.fieldType === 'file') {
          return (
            <Controller
              key={item.id}
              control={control}
              name={`formValues.${i}.fileValue`}
              render={props => {
                return (
                  <FileFormValue {...props} readOnly={readOnly} value={item} />
                );
              }}
            />
          );
        }

        if (item.fieldType === 'date') {
          return (
            <Controller
              key={item.id}
              control={control}
              name={`formValues.${i}.dateValue`}
              render={({ field: { ref, ...field }, fieldState }) => {
                return (
                  <DatePicker
                    inputRef={ref}
                    label={item.display}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={readOnly}
                    readOnly={readOnly}
                    slots={{
                      // @ts-expect-error internal prop
                      dialog: DatePickerDialog,
                    }}
                    slotProps={{
                      dialog: {
                        container: modal.container,
                      },
                      field: {
                        clearable: true,
                      },
                      inputField: {
                        name: field.name,
                        fullWidth: true,
                        required: item.mandatory,
                        error: fieldState.invalid,
                        helperText: fieldState.error?.message,
                        onBlur: field.onBlur,
                      },
                    }}
                  />
                );
              }}
            />
          );
        }

        return (
          <Controller
            key={item.id}
            control={control}
            name={`formValues.${i}.stringValue`}
            render={({ field: { ref, ...field }, fieldState }) => {
              return (
                <DraftField
                  {...field}
                  label={item.display}
                  fullWidth
                  required={item.mandatory}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  disabled={readOnly}
                />
              );
            }}
          />
        );
      })}

      {agentSelectEnabled && (
        <Controller
          control={control}
          name="agentSelect.value"
          render={({ field, fieldState }) => {
            return (
              <SelectField<AgentSelectOption, true>
                {...field}
                isMulti
                label={
                  <FormattedMessage
                    id="agent"
                    defaultMessage="{count, plural, one {Agent} other {Agents}}"
                    description="form value form agent field label"
                    values={{ count: 0 }}
                  />
                }
                options={agentSelectOptions}
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                disabled={readOnly}
                SelectProps={agentSelectProps}
              />
            );
          }}
        />
      )}

      {errors.root?.message && (
        <FormHelperText error>{errors.root?.message}</FormHelperText>
      )}

      {!readOnly && (
        <LoadingButton
          type="submit"
          fullWidth
          color="primary"
          size="large"
          variant="filled"
          disabled={!isValid}
          loading={isSubmitting}
        >
          <FormattedMessage
            id="actions.submit"
            defaultMessage="Submit"
            description="Submit button text"
          />
        </LoadingButton>
      )}
    </form>
  );
};
