import { useMutation } from '@apollo/client/react';
import { UserQueryTypes } from '@allganize/alli-interfaces';
import { Button } from '@allganize/ui-button';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { knowledgeBaseRecommendedQuestionListClasses } from './knowledge-base-recommended-question-list-classes';
import { KnowledgeBaseRecommendedQuestionListProps } from './knowledge-base-recommended-question-list-type-map';
import { RecommendDocumentActionMutationDocument } from '../../graphql/mutations/recommend-document-actions';

const defaultMessage = {
  [UserQueryTypes.RecommendedDocumentActions.SUMMARIZE]: 'Summarize',
  [UserQueryTypes.RecommendedDocumentActions.EXPLAIN_WORDS]:
    'Explain difficult words in the file',
};

export const KnowledgeBaseRecommendedQuestionList = ({
  classes,
  knowledgeBaseIds,
  onClickQuestion,
  ...others
}: KnowledgeBaseRecommendedQuestionListProps) => {
  const intl = useIntl();
  const [getRecommendedQuestions, { data, called }] = useMutation(
    RecommendDocumentActionMutationDocument,
  );
  const actions = data?.recommendDocumentActions?.actions ?? [];

  useEffect(() => {
    // TODO: remove `!called` condition after [ALL-17269] is resolved
    if (!called) {
      getRecommendedQuestions({
        variables: {
          knowledgeBaseIds,
        },
      });
    }
  }, [called, knowledgeBaseIds, getRecommendedQuestions]);

  if (actions.length === 0) return null;

  return (
    <ul
      {...others}
      className={clsx(
        knowledgeBaseRecommendedQuestionListClasses.root,
        classes?.root,
        others.className,
      )}
      css={css`
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;

        li:not(:last-of-type) {
          margin-right: 8px;
        }
      `}
    >
      {actions.map((action, index) => {
        const question = intl.formatMessage({
          id: `knowledge-base.recommended-question.${action}`,
          defaultMessage: defaultMessage[action],
          description: 'knowledge base recommended question',
        });

        return (
          <li key={action}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => onClickQuestion?.(question, action, index)}
            >
              {question}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};
