export {
  DIALOG_WIDTH,
  DIGITAL_CLOCK_VIEW_HEIGHT,
  MULTI_SECTION_CLOCK_SECTION_WIDTH,
} from '@mui/x-date-pickers/internals/constants/dimensions';

export const DAY_SIZE = 32;
export const DAY_MARGIN = 4;
export const MAX_CALENDAR_HEIGHT = 312;
export const VIEW_HEIGHT = 372;
