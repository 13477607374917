import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { documentViewerHeaderHeight } from './constants';
import { documentViewerHeaderClasses } from './document-viewer-header-classes';
import { DocumentViewerHeaderProps } from './document-viewer-header-type-map';

export const DocumentViewerHeader = forwardRef<
  HTMLDivElement,
  DocumentViewerHeaderProps
>((props, ref) => {
  const { classes, hidden, ...other } = props;
  const theme = useTheme();

  return (
    <div
      data-testid="document-viewer-header"
      css={[
        css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          padding: 0 20px;
          height: ${documentViewerHeaderHeight}px;
          transform: translateY(0);
          opacity: 1;
          transition: ${theme.transitions.create(['transform', 'opacity'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.enteringScreen,
          })};
        `,
        hidden &&
          css`
            transform: translateY(-100%);
            opacity: 0;
            transition: ${theme.transitions.create(['transform', 'opacity'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.leavingScreen,
            })};
          `,
      ]}
      {...other}
      ref={ref}
      className={clsx(
        documentViewerHeaderClasses.root,
        { [documentViewerHeaderClasses.hidden]: hidden },
        classes?.root,
        { [classes?.hidden ?? '']: hidden },
        other.className,
      )}
    />
  );
});
