import { CircularProgress } from '@allganize/ui-circular-progress';
import { css } from '@emotion/react';
import { FunctionComponent, HTMLAttributes } from 'react';

export const LoadingState: FunctionComponent<
  HTMLAttributes<HTMLDivElement>
> = props => (
  <div
    css={css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    `}
    {...props}
  >
    <CircularProgress size="lg" />
  </div>
);
