import { ClassNames } from '@emotion/react';
import { forwardRef } from 'react';
import { ListItemIcon } from '../list-item-icon';
import { ListItemCheckboxProps } from './list-item-checkbox-type-map';

export const ListItemCheckbox = forwardRef<
  HTMLDivElement,
  ListItemCheckboxProps
>((props, ref) => {
  const { classes, position = 'start', ...other } = props;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <ListItemIcon
          data-testid="list-item-checkbox"
          {...other}
          ref={ref}
          classes={{
            ...classes,
            root: cx(
              position === 'start' &&
                css`
                  margin-right: 4px;
                `,
              position === 'end' &&
                css`
                  margin-left: 4px;
                `,
              classes?.root,
            ),
            alignItemsFlexStart: cx(
              css`
                margin-top: 0;
              `,
              classes?.alignItemsFlexStart,
            ),
          }}
        />
      )}
    </ClassNames>
  );
});
