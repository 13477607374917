import {
  AtomicBlockUtils,
  ContentBlock,
  DraftHandleValue,
  DraftPlugin,
  DraftPluginFunctions,
  EditorState,
  RichUtils,
} from '@allganize/draft-input';
import { DraftImage } from './draft-image';
import { DraftImageClasses } from './draft-image-classes';

export interface DraftImagePluginOptions {
  classes?: Partial<DraftImageClasses>;
  preserveRatio?: boolean;
  onDragStart?(
    ev: React.DragEvent<HTMLDivElement>,
    blockKey: string,
    pluginFunctions: DraftPluginFunctions,
  ): void;
}

export class DraftImagePlugin extends DraftPlugin {
  public static readonly ENTITY_TYPE = 'IMAGE';

  public static addImage(
    editorState: EditorState,
    url: string,
    extraData?: any,
  ) {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      DraftImagePlugin.ENTITY_TYPE,
      'IMMUTABLE',
      { ...extraData, src: url },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      ' ',
    );

    return EditorState.forceSelection(
      newEditorState,
      newEditorState.getCurrentContent().getSelectionAfter(),
    );
  }

  constructor(private options: DraftImagePluginOptions = {}) {
    super();
  }

  private handleDragStart = (pluginFunctions: DraftPluginFunctions) => {
    if (!this.options.onDragStart) {
      return undefined;
    }

    return (ev: React.DragEvent<HTMLDivElement>, blockKey: string) => {
      this.options.onDragStart?.(ev, blockKey, pluginFunctions);
    };
  };

  public blockRendererFn(
    block: ContentBlock,
    pluginFunctions: DraftPluginFunctions,
  ) {
    if (block.getType() !== 'atomic') {
      return null;
    }

    const { getEditorState } = pluginFunctions;
    const contentState = getEditorState().getCurrentContent();
    const entityKey = block.getEntityAt(0);

    if (!entityKey) {
      return null;
    }

    const entity = contentState.getEntity(entityKey);

    if (!entity) {
      return null;
    }

    const type = entity.getType();

    if (type !== DraftImagePlugin.ENTITY_TYPE) {
      return null;
    }

    const data = entity.getData();

    return {
      component: DraftImage,
      editable: false,
      props: {
        data,
        preserveRatio: this.options.preserveRatio,
        classes: this.options.classes,
        onDragStart: this.handleDragStart(pluginFunctions),
      },
    };
  }

  public handleKeyCommand(
    command: string,
    editorState: EditorState,
    eventTimeStamp: number,
    { setEditorState }: DraftPluginFunctions,
  ): DraftHandleValue {
    if (command === 'backspace') {
      const newState = RichUtils.handleKeyCommand(editorState, command);

      if (newState) {
        setEditorState(newState);
        return 'handled';
      }
    }

    return 'not-handled';
  }
}
