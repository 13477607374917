import { useCallback, useRef } from 'react';

export interface UseRefCallback<T> {
  (value: T | null): void;
  readonly current: T | null;
}

export const useRefs = <K extends keyof any, V>() => {
  const refs = useRef<Partial<Record<K, V | null>>>({});

  const getRefByKey = useCallback((key: K) => {
    // @ts-expect-error current not defined yet
    const result: UseRefCallback<V> = (value: V | null) => {
      refs.current[key] = value;
    };

    Object.defineProperty(result, 'current', {
      get: () => refs.current[key] ?? null,
    });

    return result;
  }, []);

  return { refs, getRefByKey };
};
