import { DraftPlugin, DraftStyleMap } from '@allganize/draft-input';
import { defaultTheme } from '@allganize/ui-theme';

export class DraftTextHighlightPlugin extends DraftPlugin {
  public getCustomStyleMap(): DraftStyleMap {
    return {
      HIGHLIGHT: {
        backgroundColor: defaultTheme.palette.unstable_background.highlight,
      },
    };
  }
}
