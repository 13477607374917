import { Security } from '@okta/okta-react';
import { FunctionComponent } from 'react';
import { useOktaSecurity } from './use-okta-security';

interface OktaSecurityProps {
  children?: React.ReactNode;
}

export const OktaSecurity: FunctionComponent<OktaSecurityProps> = ({
  children,
}) => {
  const { oktaAuth, restoreOriginalUri } = useOktaSecurity();

  if (!oktaAuth) {
    return children;
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};
