import { Types } from '@allganize/alli-sdk-interfaces';
import { DraftLinkContext } from '@allganize/draft-link-plugin';
import { useSuspenseQuery } from '@apollo/client/react';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { analytics } from '../analytics';
import { ProjectQueryDocument } from '../graphql/queries/project-query';
import { ThemeOptions } from '../styles/theme';
import { ProjectContext, ProjectContextValue } from './project-context';
import { useAlliClient } from '../alli-client/use-alli-client';
import {
  AssistantCampaignContext,
  AssistantCampaignContextValue,
} from './assistant-campaign-context';

interface ProjectProviderProps {
  children?: React.ReactNode;
}

export const ProjectProvider: FunctionComponent<ProjectProviderProps> = ({
  children,
}) => {
  const { data, refetch } = useSuspenseQuery(ProjectQueryDocument, {
    fetchPolicy: 'cache-and-network',
  });
  const project = data.project;
  const sdkSettings = project?.sdkSettings;
  const sdkUrlPatterns = project?.sdkUrlPatterns;
  const client = useAlliClient();

  const themeOptions = useMemo<
    Pick<
      ThemeOptions,
      | 'alignment'
      | 'primary'
      | 'horizontalPadding'
      | 'verticalPadding'
      | 'messenger'
      | 'messagePreview'
      | 'bgColor'
    >
  >(
    () => ({
      alignment:
        sdkSettings?.chatBubblePosition ===
        Types.ChatBubblePositionEnum.LEFT_BOTTOM
          ? 'left'
          : 'right',
      primary: sdkSettings?.themeColor ?? undefined,
      horizontalPadding: sdkSettings?.chatBubbleOffsetX ?? undefined,
      verticalPadding: sdkSettings?.chatBubbleOffsetY ?? undefined,
      messenger: {
        width: sdkSettings?.chatWindowWidth ?? undefined,
        height: sdkSettings?.chatWindowHeight ?? undefined,
        bgColor: sdkSettings?.bgColor ?? undefined,
      },
      messagePreview: {
        width: sdkSettings?.popUpWidth ?? undefined,
      },
      bgColor: sdkSettings?.bgColor ?? undefined,
    }),
    [
      sdkSettings?.chatBubbleOffsetX,
      sdkSettings?.chatBubbleOffsetY,
      sdkSettings?.chatBubblePosition,
      sdkSettings?.chatWindowHeight,
      sdkSettings?.chatWindowWidth,
      sdkSettings?.popUpWidth,
      sdkSettings?.themeColor,
      sdkSettings?.bgColor,
    ],
  );

  const getLinkTarget = useCallback(
    (href?: string) => {
      return href &&
        sdkUrlPatterns &&
        sdkUrlPatterns.findIndex(
          pattern => pattern && new RegExp(pattern.regex).test(href),
        ) >= 0
        ? '_self'
        : '_blank';
    },
    [sdkUrlPatterns],
  );

  const contextValue = useMemo<ProjectContextValue>(
    () => ({
      project,
      refetch,
      themeOptions,
    }),
    [project, refetch, themeOptions],
  );

  const assistantCampaignContextValue = useMemo<AssistantCampaignContextValue>(
    () => ({
      assistantCampaignToken:
        project?.assistantEntryLlmApp?.campaign?.campaignToken || null,
      isAssistantCampaign: campaignId => {
        return (
          campaignId !== undefined &&
          project?.assistantEntryLlmApp?.campaign?.id === campaignId
        );
      },
    }),
    [project?.assistantEntryLlmApp?.campaign],
  );

  useEffect(() => {
    if (project?.id) {
      analytics.mixpanel?.register(
        { projectId: project.id, entryType: client.entryType },
        { persistent: false },
      );
    }
  }, [project?.id, client.entryType]);

  if (!project) {
    throw new Error('Failed to fetch project');
  }

  return (
    <ProjectContext.Provider value={contextValue}>
      <AssistantCampaignContext.Provider value={assistantCampaignContextValue}>
        <DraftLinkContext.Provider value={{ getLinkTarget }}>
          {children}
        </DraftLinkContext.Provider>
      </AssistantCampaignContext.Provider>
    </ProjectContext.Provider>
  );
};
