import {
  filesize as _filesize,
  FileSizeOptionsArray,
  FileSizeOptionsBase,
  FileSizeOptionsExponent,
  FileSizeOptionsObject,
  FileSizeOptionsString,
  FileSizeReturnArray,
  FileSizeReturnObject,
} from 'filesize';

export const filesize = (
  arg: Parameters<typeof _filesize>[0],
  opts?:
    | FileSizeOptionsString
    | FileSizeOptionsBase
    | FileSizeOptionsArray
    | FileSizeOptionsExponent
    | FileSizeOptionsObject,
): string | number => {
  const result = (opts ? _filesize(arg, opts) : _filesize(arg)) as
    | string
    | number
    | FileSizeReturnObject
    | FileSizeReturnArray;

  if (typeof result === 'string' || typeof result === 'number') {
    return result;
  }

  if (Array.isArray(result)) {
    return result.join(opts?.spacer ?? ' ');
  }

  return [result.value, result.symbol].join(opts?.spacer ?? ' ');
};

export const MAX_KB_FILE_SIZE = 50 * 1048576;
export const maxFileSize = filesize(MAX_KB_FILE_SIZE);
