import { useEventCallback } from '@allganize/hooks';
import { Button } from '@allganize/ui-button';
import { IcAppMarket } from '@allganize/ui-icons';
import styled from '@emotion/styled';
import { HTMLAttributes, ReactNode, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { analytics } from '../../analytics';
import { useAuth } from '../../hooks/use-auth';
import { AlliBranding } from '../alli-branding/alli-branding';
import { HistoryList } from './history-list';
import { MenuList } from './menu-list';
import { MenuListItem } from './menu-list-item';

const LNB_WIDTH = 216;

const Wrapper = styled.aside`
  flex: 0 0 ${LNB_WIDTH}px;
  width: ${LNB_WIDTH}px;
  display: flex;
  flex-direction: column;
  padding: 24px 12px 8px 12px;
  background-color: ${props => props.theme.palette.unstable_background.default};
  overflow: hidden;
`;

const LogoArea = styled(Link)`
  text-decoration: none;
  padding: 0 4px;
  margin-bottom: 24px;
`;

const MenuArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
`;

const ContentArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

const FooterArea = styled.footer`
  text-align: center;
`;

const AuthArea = styled.div`
  margin-bottom: 10px;
`;

interface LNBProps extends HTMLAttributes<HTMLDivElement> {
  logo: ReactNode;
}

export const LNB = ({ logo, children, ...others }: LNBProps) => {
  const auth = useAuth();

  const handleClickApps = useEventCallback(() => {
    analytics.track('lnb::all-apps.click');
  });
  const handleClickLogo = useEventCallback(() => {
    analytics.track('lnb::logo.click');
  });

  return (
    <Wrapper {...others}>
      <LogoArea to="/" onClick={handleClickLogo}>
        {logo}
      </LogoArea>
      <MenuArea>
        <MenuList>
          <MenuListItem
            to="/apps"
            icon={<IcAppMarket />}
            onClick={handleClickApps}
          >
            <FormattedMessage
              id="AppMarket.LNB.MenuList.Apps"
              defaultMessage="Apps"
              description="app market lnb apps menu text"
            />
          </MenuListItem>
        </MenuList>
      </MenuArea>
      <ContentArea>
        <Suspense fallback={null}>
          <HistoryList />
        </Suspense>
      </ContentArea>
      <FooterArea>
        {auth.isEnabled && (
          <AuthArea>
            {auth.isAuthenticated && (
              <Button
                onClick={() => {
                  auth.signOut();
                }}
              >
                <FormattedMessage
                  id="sign-out"
                  defaultMessage="Sign out"
                  description="Sign out text"
                />
              </Button>
            )}
          </AuthArea>
        )}
        <AlliBranding />
      </FooterArea>
    </Wrapper>
  );
};
