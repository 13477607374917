import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type ToastContentClassKey =
  | 'root'
  | 'directionRow'
  | 'directionColumn'
  | 'contents'
  | 'icon'
  | 'message'
  | 'actions';
export type ToastContentClasses = Record<ToastContentClassKey, string>;

export const toastContentClasses: ToastContentClasses = generateUtilityClasses(
  'ToastContent',
  [
    'root',
    'directionRow',
    'directionColumn',
    'contents',
    'icon',
    'message',
    'actions',
  ],
);
