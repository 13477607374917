import { EditorState, Modifier } from '@allganize/draft-input';
import {
  useDraftToolbarEditorStateGetter,
  useDraftToolbarEditorStateSetter,
  useDraftToolbarFocus,
} from '@allganize/draft-toolbar-plugin';
import { useCombinedRef, useId } from '@allganize/hooks';
import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { IcFormatSize } from '@allganize/ui-icons';
import { ListItemText } from '@allganize/ui-list';
import { Menu, MenuItem } from '@allganize/ui-menu';
import { Tooltip } from '@allganize/ui-tooltip';
import { raf } from '@allganize/utils-timeout';
import { Fragment, forwardRef, memo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DraftTextSizeInlineStyleKey,
  draftTextSizeInlineStyleLabels,
  draftTextSizeInlineStyles,
} from '../draft-text-size-plugin/draft-text-size-inline-styles';
import { getSelectedInlineStyles } from '../utils/get-selected-inline-styles';
import { getSelectionForInlineStyle } from '../utils/get-selection-for-inline-style';
import { toggleInlineStyle } from '../utils/toggle-inline-style';

const textSizes = Object.keys(draftTextSizeInlineStyles).reduce<
  { style: DraftTextSizeInlineStyleKey; label: string }[]
>((acc, style) => {
  return [
    ...acc,
    {
      label:
        draftTextSizeInlineStyleLabels[style as DraftTextSizeInlineStyleKey],
      style: style as DraftTextSizeInlineStyleKey,
    },
  ];
}, []);

interface DraftToolbarTextSizeButtonProps extends IconButtonProps {}

export const DraftToolbarTextSizeButton = memo(
  forwardRef<HTMLButtonElement, DraftToolbarTextSizeButtonProps>(
    (props, ref) => {
      const focus = useDraftToolbarFocus();
      const getEditorState = useDraftToolbarEditorStateGetter();
      const setEditorState = useDraftToolbarEditorStateSetter();
      const buttonRef = useRef<HTMLButtonElement>(null);
      const combinedRef = useCombinedRef(buttonRef, ref);
      const [menuOpen, setMenuOpen] = useState(false);
      const menuId = useId();
      const selectedInlineStyles = getSelectedInlineStyles(getEditorState());

      const closeMenu = () => {
        setMenuOpen(false);
        focus();
      };

      const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
        setMenuOpen(prev => !prev);
        props.onClick?.(ev);
      };

      return (
        <Fragment>
          <Tooltip
            title={
              <FormattedMessage
                id="TextArea.TextSizeButton"
                defaultMessage="Text Size"
                description="Text area text size button tooltip text"
              />
            }
          >
            <IconButton
              aria-owns={menuOpen ? menuId : undefined}
              aria-haspopup="true"
              size="small"
              {...props}
              ref={combinedRef}
              onClick={handleClick}
            >
              <IcFormatSize fontSize="small" />
            </IconButton>
          </Tooltip>

          <Menu
            id={menuId}
            anchorEl={buttonRef.current}
            open={menuOpen}
            onClose={closeMenu}
          >
            {textSizes.map(size => {
              return (
                <MenuItem
                  key={size.style}
                  selected={selectedInlineStyles.includes(size.style)}
                  onClick={async ev => {
                    ev.preventDefault();
                    const editorState = getEditorState();
                    const selection = editorState.getSelection();
                    const contentState = editorState.getCurrentContent();
                    const newSelection =
                      getSelectionForInlineStyle(editorState);

                    const sanitizedContentState = textSizes.reduce(
                      (cs, opt) =>
                        Modifier.removeInlineStyle(cs, newSelection, opt.style),
                      contentState,
                    );

                    const sanitizedEditorState = EditorState.push(
                      editorState,
                      sanitizedContentState,
                      'change-inline-style',
                    );

                    const styleApplied = toggleInlineStyle(
                      sanitizedEditorState,
                      size.style,
                      newSelection,
                    );

                    setEditorState(
                      EditorState.setInlineStyleOverride(
                        EditorState.forceSelection(styleApplied, selection),
                        styleApplied.getInlineStyleOverride(),
                      ),
                    );

                    await raf();
                    closeMenu();
                  }}
                >
                  <ListItemText primary={size.label} />
                </MenuItem>
              );
            })}
          </Menu>
        </Fragment>
      );
    },
  ),
);
