import { createFilter as defaultCreateFilter } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

const defaultStringify = <Option>(
  option: FilterOptionOption<Option>,
): string => {
  return [
    option.value,
    option.label,
    typeof (option.data as { description?: unknown })?.description === 'string'
      ? (option.data as { description?: unknown }).description
      : undefined,
  ]
    .filter(Boolean)
    .join(' ');
};

export interface CreateFilterConfig<Option> {
  readonly ignoreCase?: boolean;
  readonly ignoreAccents?: boolean;
  readonly stringify?: (option: FilterOptionOption<Option>) => string;
  readonly trim?: boolean;
  readonly matchFrom?: 'any' | 'start';
}

export const createFilter = <Option>(config?: CreateFilterConfig<Option>) => {
  return defaultCreateFilter<Option>({
    stringify: defaultStringify,
    ...config,
  });
};
