import { ExtendButtonBase } from '@allganize/ui-button';
import { IcArrowDownward } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import MuiTableSortLabel from '@mui/material/TableSortLabel';
import { forwardRef } from 'react';
import { tableSortLabelClasses } from './table-sort-label-classes';
import { TableSortLabelTypeMap } from './table-sort-label-type-map';

// @ts-expect-error overridable component
export const TableSortLabel: ExtendButtonBase<TableSortLabelTypeMap> =
  forwardRef((props, ref) => {
    const { classes, IconComponent = IcArrowDownward, ...other } = props;
    const theme = useTheme();

    return (
      <ClassNames>
        {({ css, cx }) => (
          <MuiTableSortLabel
            data-testid="table-sort-label"
            IconComponent={IconComponent}
            {...other}
            ref={ref}
            classes={{
              ...classes,
              root: cx(
                css`
                  &:focus {
                    color: ${theme.palette.text.secondary};
                  }

                  &:hover {
                    color: ${theme.palette.text.secondary};
                  }

                  &.${tableSortLabelClasses.active} {
                    color: ${theme.palette.text.primary};

                    .${tableSortLabelClasses.icon} {
                      color: ${theme.palette.text.secondary};
                    }
                  }
                `,
                classes?.root,
              ),
              icon: cx(
                css`
                  transition: ${theme.transitions.create(
                    ['opacity', 'transform'],
                    {
                      duration: theme.transitions.duration.shorter,
                    },
                  )};
                `,
                classes?.icon,
              ),
            }}
          />
        )}
      </ClassNames>
    );
  });
