import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type KnowledgeBaseFileDropzoneClassKey =
  | 'root'
  | 'title'
  | 'dropzone'
  | 'description';

export type KnowledgeBaseFileDropzoneClasses = Record<
  KnowledgeBaseFileDropzoneClassKey,
  string
>;

export const knowledgeBaseFileDropzoneClasses: KnowledgeBaseFileDropzoneClasses =
  generateUtilityClasses('KnowledgeBaseFileDropzone', [
    'root',
    'title',
    'dropzone',
    'description',
  ]);
