import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type DialogCloseButtonClassKey = 'root';
export type DialogCloseButtonClasses = Record<
  DialogCloseButtonClassKey,
  string
>;

export const dialogCloseButtonClasses: DialogCloseButtonClasses =
  generateUtilityClasses('DialogCloseButton', ['root']);
