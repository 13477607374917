import { useTheme } from '@allganize/ui-theme';
import { ClockNumber as MuiClockNumber } from '@mui/x-date-pickers/TimeClock/ClockNumber';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { clockNumberClasses } from './clock-number-classes';
import { ClockNumberProps } from './clock-number-type-map';

export const ClockNumber: FunctionComponent<ClockNumberProps> = props => {
  const { inner, ...other } = props;
  const theme = useTheme();

  return (
    <MuiClockNumber
      data-testid="clock-number"
      css={[
        css`
          color: ${theme.palette.text.primary};
          font-family: ${theme.typography.fontFamily};

          &:focus {
            background-color: ${theme.palette.background.paper};
          }

          &.${clockNumberClasses.selected} {
            color: ${theme.palette.primary.contrastText};
          }

          &.${clockNumberClasses.disabled} {
            color: ${theme.palette.text.disabled};
          }
        `,
        inner &&
          css`
            ${theme.typography.body14}
            color: ${theme.palette.text.secondary};
          `,
      ]}
      inner={inner}
      {...other}
    />
  );
};
