import { CamelCase } from '@allganize/types';
import {
  getButtonUtilityClass,
  buttonClasses as muiButtonClasses,
} from '@mui/material/Button';
import { pick } from 'lodash-es';
import {
  ButtonColor,
  ButtonShape,
  ButtonSize,
  ButtonVariant,
} from './button-type-map';

export type ButtonClassKey =
  | 'root'
  | ButtonVariant
  | CamelCase<`color_${ButtonColor}`>
  | CamelCase<`shape_${ButtonShape}`>
  | CamelCase<`size_${ButtonSize}`>
  | 'focusVisible'
  | 'disabled'
  | 'fullWidth'
  | 'icon'
  | 'startIcon'
  | 'endIcon'
  | 'edgeStart'
  | 'edgeEnd';

export type ButtonClasses = Record<ButtonClassKey, string>;

export const buttonClasses: ButtonClasses = {
  ...pick(muiButtonClasses, [
    'root',
    'outlined',
    'sizeSmall',
    'sizeMedium',
    'sizeLarge',
    'focusVisible',
    'disabled',
    'fullWidth',
    'icon',
    'startIcon',
    'endIcon',
  ]),
  ghost: getButtonUtilityClass('ghost'),
  filled: getButtonUtilityClass('filled'),
  colorDefault: getButtonUtilityClass('colorDefault'),
  colorPrimary: getButtonUtilityClass('colorPrimary'),
  colorError: getButtonUtilityClass('colorError'),
  shapeRounded: getButtonUtilityClass('shapeRounded'),
  shapeCircular: getButtonUtilityClass('shapeCircular'),
  edgeStart: getButtonUtilityClass('edgeStart'),
  edgeEnd: getButtonUtilityClass('edgeEnd'),
};
