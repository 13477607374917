import {
  Client,
  ClientOptions,
  ConnectionInitMessage,
  createClient,
} from 'graphql-ws';
import { logger } from '../core/logger/logger';

export interface SubscriptionClient extends Client {
  restart(): void;
}

export const createSubscriptionClient = <
  P extends ConnectionInitMessage['payload'] = ConnectionInitMessage['payload'],
>(
  options: ClientOptions<P>,
): SubscriptionClient => {
  let restartRequested = false;

  let restart = () => {
    restartRequested = true;
  };

  const client = createClient({
    ...options,
    on: {
      ...options.on,
      error(error) {
        options.on?.error?.(error);
        logger.error(`[Subscription error]: ${error}`);
      },
      opened(socket) {
        options.on?.opened?.(socket);

        restart = () => {
          if ((socket as WebSocket).readyState === WebSocket.OPEN) {
            // if the socket is still open for the restart, do the restart
            (socket as WebSocket).close(4205, 'Client Restart');
          } else {
            // otherwise the socket might've closed, indicate that you want
            // a restart on the next opened event
            restartRequested = true;
          }
        };

        // just in case you were eager to restart
        if (restartRequested) {
          restartRequested = false;
          restart();
        }
      },
    },
  });

  return {
    ...client,
    restart: () => restart(),
  };
};
