import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { IcArrowForward } from '@allganize/ui-icons';
import { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { ariaLabels } from '../i18n/aria-labels';

interface ChatFormSubmitButtonProps extends IconButtonProps {
  isSubmitting?: boolean;
}

export const ChatFormSubmitButton = forwardRef<
  HTMLButtonElement,
  ChatFormSubmitButtonProps
>(({ disabled, isSubmitting, ...other }, ref) => {
  const intl = useIntl();
  return (
    <IconButton
      type="submit"
      color="primary"
      variant="filled"
      edge="end"
      disabled={disabled}
      size="large"
      {...other}
      ref={ref}
      aria-label={intl.formatMessage(ariaLabels['send-chat-button'])}
    >
      {isSubmitting ? (
        <CircularProgress size="sm" color="inherit" />
      ) : (
        <IcArrowForward />
      )}
    </IconButton>
  );
});
