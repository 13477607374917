import { FunctionComponent, ReactNode } from 'react';
import { UIContext } from './ui-context';

interface UIProviderProps {
  modalContainerId?: string;
  children?: ReactNode;
}

export const UIProvider: FunctionComponent<UIProviderProps> = ({
  modalContainerId,
  children,
}) => {
  const modalContainer = modalContainerId
    ? document.getElementById(modalContainerId)
    : null;
  return (
    <UIContext.Provider value={{ modalContainer }}>
      {children}
    </UIContext.Provider>
  );
};
