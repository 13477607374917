import { DialogProps } from '@allganize/ui-dialog';
import { KnowledgeBaseUploadDialogClasses } from './knowledge-base-upload-dialog-classes';

import type { UserQueryTypes } from '@allganize/alli-interfaces';
import type { KnowledgeBase } from '../types/types';

export interface KnowledgeBaseUploadDialogProps extends DialogProps {
  /**
   * Override or extend the styles applied to the component.
   */
  classes?: Partial<KnowledgeBaseUploadDialogClasses>;
  onConfirm?: (knowledgeBases: KnowledgeBase[]) => void;
}

export interface UploadingFile extends Partial<KnowledgeBase> {
  processState?: UserQueryTypes.ProcessStateEnum | null;
  file?: File;
  loading?: boolean;
  error?: boolean;
}

export type UploadingFileList = Record<string, UploadingFile>;

export const enum UploadErrorType {
  type = 'type',
  size = 'size',
  process = 'process',
}

export type UploadError = {
  type: UploadErrorType;
  fileNames: string[];
};
