import { gray } from './gray';
import { grayToAlpha } from './gray-to-alpha';

export const grayAlpha = {
  50: grayToAlpha(gray[50]),
  100: grayToAlpha(gray[100]),
  200: grayToAlpha(gray[200]),
  300: grayToAlpha(gray[300]),
  400: grayToAlpha(gray[400]),
  500: grayToAlpha(gray[500]),
  600: grayToAlpha(gray[600]),
  700: grayToAlpha(gray[700]),
  800: grayToAlpha(gray[800]),
  900: grayToAlpha(gray[900]),
};
