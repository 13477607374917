import { DraftInputRef } from '@allganize/draft-input';
import { forwardRef } from 'react';
import { RichText, RichTextProps } from '../rich-text/rich-text';
import { usePlainText } from './use-plain-text';

export interface PlainTextProps
  extends Omit<RichTextProps, 'plugins' | 'value'> {
  value?: string | null;
}

export const PlainText = forwardRef<DraftInputRef, PlainTextProps>(
  (props, ref) => {
    const { contentState, plugins } = usePlainText({
      data: props.value ?? null,
    });

    return (
      <RichText {...props} ref={ref} plugins={plugins} value={contentState} />
    );
  },
);
