import { ExtendList } from '@allganize/ui-list';
import { css } from '@emotion/react';
import MuiMenuList from '@mui/material/MenuList';
import { forwardRef } from 'react';
import { MenuListTypeMap } from './menu-list-type-map';

// @ts-expect-error overridable component
export const MenuList: ExtendList<MenuListTypeMap> = forwardRef(
  (props, ref) => {
    const { subheader, ...other } = props;

    return (
      <MuiMenuList
        data-testid="menu-list"
        // copied from @allganize/ui-list
        css={[
          css`
            padding: 8px 0;
          `,
          subheader &&
            css`
              padding-top: 0;
            `,
        ]}
        subheader={subheader}
        {...other}
        ref={ref}
      />
    );
  },
);
