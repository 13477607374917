import { alpha } from '@mui/material/styles';
import { HighlightLayerProps } from './highlight-layer-type-map';

const BORDER_HIGHLIGHT_PADDING = 8;

export const draw = (
  context: CanvasRenderingContext2D | undefined | null,
  highlights: HighlightLayerProps['highlights'],
  color: string,
) => {
  if (!context || !highlights || highlights.length === 0) {
    return;
  }

  const canvasWidth = context.canvas.width;
  const canvasHeight = context.canvas.height;

  highlights?.forEach(({ type, bbox: { top, right, bottom, left } }) => {
    const x = left * canvasWidth;
    const y = top * canvasHeight;
    const width = right * canvasWidth - left * canvasWidth;
    const height = bottom * canvasHeight - top * canvasHeight;

    if (type === 'PARAGRAPH') {
      context.fillStyle = color;
      context.fillRect(x, y, width, height);
    } else {
      context.strokeStyle = alpha(color, 1);
      context.lineWidth = 3;
      context.strokeRect(
        x - BORDER_HIGHLIGHT_PADDING,
        y - BORDER_HIGHLIGHT_PADDING,
        width + BORDER_HIGHLIGHT_PADDING * 2,
        height + BORDER_HIGHLIGHT_PADDING * 2,
      );
    }
  });
};
