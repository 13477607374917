import { ClassNames } from '@emotion/react';
import { forwardRef } from 'react';
import { ListItemIcon } from '../list-item-icon';
import { ListItemSwitchProps } from './list-item-switch-type-map';

export const ListItemSwitch = forwardRef<HTMLDivElement, ListItemSwitchProps>(
  (props, ref) => {
    const { classes, ...other } = props;

    return (
      <ClassNames>
        {({ css, cx }) => (
          <ListItemIcon
            data-testid="list-item-switch"
            css={css``}
            {...other}
            ref={ref}
            classes={{
              ...classes,
              root: cx(
                css`
                  margin-right: 0;
                `,
                classes?.root,
              ),
              alignItemsFlexStart: cx(
                css`
                  margin-top: 0;
                `,
                classes?.alignItemsFlexStart,
              ),
            }}
          />
        )}
      </ClassNames>
    );
  },
);
