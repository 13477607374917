import { IconButton } from '@allganize/ui-button';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/material/utils';
import {
  CLOCK_HOUR_WIDTH,
  getHours,
  getMinutes,
} from '@mui/x-date-pickers/TimeClock/shared';
import clsx from 'clsx';
import { Fragment, forwardRef, useMemo, useRef } from 'react';
import { ClockPointer } from '../clock-pointer';
import { PickerSelectionState } from '../internals/hooks/use-picker';
import { useLocaleText, useUtils } from '../internals/hooks/use-utils';
import { formatMeridiem } from '../internals/utils/date-utils';
import { clockClasses } from './clock-classes';
import { ClockProps } from './clock-type-map';

export const Clock = forwardRef<HTMLDivElement, ClockProps>((props, ref) => {
  const {
    ampm,
    ampmInClock,
    autoFocus,
    children,
    className,
    classes,
    value,
    handleMeridiemChange,
    isTimeDisabled,
    meridiemMode,
    minutesStep = 1,
    onChange,
    selectedId,
    type,
    viewValue,
    disabled,
    readOnly,
    ...other
  } = props;
  const theme = useTheme();

  const utils = useUtils<Date>();
  const localeText = useLocaleText<Date>();
  const isMoving = useRef(false);

  const isSelectedTimeDisabled = isTimeDisabled(viewValue, type);
  const isPointerInner =
    !ampm && type === 'hours' && (viewValue < 1 || viewValue > 12);

  const handleValueChange = (
    newValue: number,
    isFinish: PickerSelectionState,
  ) => {
    if (disabled || readOnly) {
      return;
    }
    if (isTimeDisabled(newValue, type)) {
      return;
    }

    onChange(newValue, isFinish);
  };

  const setTime = (
    event: MouseEvent | React.TouchEvent,
    isFinish: PickerSelectionState,
  ) => {
    let { offsetX, offsetY } = event as MouseEvent;

    if (offsetX === undefined) {
      const rect = (
        (event as React.TouchEvent).target as HTMLElement
      ).getBoundingClientRect();

      offsetX =
        (event as React.TouchEvent).changedTouches[0].clientX - rect.left;
      offsetY =
        (event as React.TouchEvent).changedTouches[0].clientY - rect.top;
    }

    const newSelectedValue =
      type === 'seconds' || type === 'minutes'
        ? getMinutes(offsetX, offsetY, minutesStep)
        : getHours(offsetX, offsetY, Boolean(ampm));

    handleValueChange(newSelectedValue, isFinish);
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    isMoving.current = true;
    setTime(event, 'shallow');
  };

  const handleTouchEnd = (event: React.TouchEvent) => {
    if (isMoving.current) {
      setTime(event, 'finish');
      isMoving.current = false;
    }
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    // event.buttons & PRIMARY_MOUSE_BUTTON
    if (event.buttons > 0) {
      setTime(event.nativeEvent, 'shallow');
    }
  };

  const handleMouseUp = (event: React.MouseEvent) => {
    if (isMoving.current) {
      isMoving.current = false;
    }

    setTime(event.nativeEvent, 'finish');
  };

  const hasSelected = useMemo(() => {
    if (type === 'hours') {
      return true;
    }

    return viewValue % 5 === 0;
  }, [type, viewValue]);

  const keyboardControlStep = type === 'minutes' ? minutesStep : 1;

  const listboxRef = useRef<HTMLDivElement>(null);

  // Since this is rendered when a Popper is opened we can't use passive effects.
  // Focusing in passive effects in Popper causes scroll jump.
  useEnhancedEffect(() => {
    if (autoFocus) {
      // The ref not being resolved would be a bug in MUI.
      listboxRef.current!.focus();
    }
  }, [autoFocus]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    // TODO: Why this early exit?
    if (isMoving.current) {
      return;
    }

    switch (event.key) {
      case 'Home':
        // annulate both hours and minutes
        handleValueChange(0, 'partial');
        event.preventDefault();
        break;
      case 'End':
        handleValueChange(type === 'minutes' ? 59 : 23, 'partial');
        event.preventDefault();
        break;
      case 'ArrowUp':
        handleValueChange(viewValue + keyboardControlStep, 'partial');
        event.preventDefault();
        break;
      case 'ArrowDown':
        handleValueChange(viewValue - keyboardControlStep, 'partial');
        event.preventDefault();
        break;
      default:
      // do nothing
    }
  };

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        margin: ${theme.spacing(2)};
      `}
      {...other}
      ref={ref}
      className={clsx(clockClasses.root, classes?.root, className)}
    >
      <div
        css={css`
          background-color: rgba(0, 0, 0, 0.07);
          border-radius: ${theme.radius.round}px;
          height: 220px;
          width: 220px;
          flex-shrink: 0;
          position: relative;
          pointer-events: none;
        `}
        className={clsx(clockClasses.clock, classes?.clock)}
      >
        <div
          css={[
            css`
              width: 100%;
              height: 100%;
              position: absolute;
              pointer-events: auto;
              outline: 0;
              touch-action: none;
              user-select: none;
            `,
            !disabled &&
              css`
                @media (pointer: fine) {
                  cursor: pointer;
                  border-radius: ${theme.radius.round}px;
                }

                &:active {
                  cursor: move;
                }
              `,
          ]}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          className={clsx(clockClasses.squareMask, classes?.squareMask)}
        />

        {!isSelectedTimeDisabled && (
          <Fragment>
            <div
              css={css`
                width: 6px;
                height: 6px;
                border-radius: ${theme.radius.round}px;
                background-color: ${theme.palette.primary.main};
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              `}
              className={clsx(clockClasses.pin, classes?.pin)}
            />

            {value != null && (
              <ClockPointer
                type={type}
                viewValue={viewValue}
                isInner={isPointerInner}
                hasSelected={hasSelected}
              />
            )}
          </Fragment>
        )}

        <div
          css={css`
            &:focus {
              outline: none;
            }
          `}
          aria-activedescendant={selectedId}
          aria-label={localeText.clockLabelText(type, value, utils)}
          ref={listboxRef}
          role="listbox"
          onKeyDown={handleKeyDown}
          tabIndex={0}
          className={clsx(clockClasses.wrapper, classes?.wrapper)}
        >
          {children}
        </div>
      </div>

      {ampm && ampmInClock && (
        <Fragment>
          <IconButton
            css={css`
              z-index: 1;
              position: absolute;
              bottom: 8px;
              left: 8px;
              padding-left: 4px;
              padding-right: 4px;
              width: ${CLOCK_HOUR_WIDTH}px;
            `}
            onClick={readOnly ? undefined : () => handleMeridiemChange('am')}
            disabled={disabled || meridiemMode === null}
            className={clsx(clockClasses.amButton, classes?.amButton)}
            title={formatMeridiem(utils, 'am')}
            variant={meridiemMode === 'am' ? 'filled' : 'ghost'}
            color={meridiemMode === 'am' ? 'primary' : 'default'}
          >
            <Text
              variant="body12"
              css={css`
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              `}
              className={clsx(clockClasses.meridiemText, classes?.meridiemText)}
            >
              {formatMeridiem(utils, 'am')}
            </Text>
          </IconButton>

          <IconButton
            css={css`
              z-index: 1;
              position: absolute;
              bottom: 8px;
              right: 8px;
              padding-left: 4px;
              padding-right: 4px;
              width: ${CLOCK_HOUR_WIDTH}px;
            `}
            disabled={disabled || meridiemMode === null}
            onClick={readOnly ? undefined : () => handleMeridiemChange('pm')}
            className={clsx(clockClasses.pmButton, classes?.pmButton)}
            title={formatMeridiem(utils, 'pm')}
            variant={meridiemMode === 'pm' ? 'filled' : 'ghost'}
            color={meridiemMode === 'pm' ? 'primary' : 'default'}
          >
            <Text
              variant="body12"
              css={css`
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              `}
              className={clsx(clockClasses.meridiemText, classes?.meridiemText)}
            >
              {formatMeridiem(utils, 'pm')}
            </Text>
          </IconButton>
        </Fragment>
      )}
    </div>
  );
});
