import { UserQueryTypes } from '@allganize/alli-interfaces';
import { Truncate } from '@allganize/truncate';
import { IconButton } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { IcClose, IcRefresh } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import {
  UploadingFileList,
  UploadingFile,
} from './knowledge-base-upload-dialog-type-map';
import { FileIcon } from '../file-icon';
import { resetUlStyles } from '../styles/reset';

interface KnowledgeBaseUploadListProps
  extends React.HTMLAttributes<HTMLUListElement> {
  data: UploadingFileList;
  onRetry: (id: string) => void;
  onDelete: (id: string) => void;
}

export const KnowledgeBaseUploadList = ({
  data,
  onRetry,
  onDelete,
  ...others
}: KnowledgeBaseUploadListProps) => {
  const theme = useTheme();
  const knowledgeBases = Object.entries(data);

  if (knowledgeBases.length === 0) {
    return null;
  }

  return (
    <ul
      {...others}
      css={[
        resetUlStyles,
        css`
          border-radius: 8px;
          background-color: ${theme.palette.unstable_background.default};
        `,
      ]}
    >
      {knowledgeBases.map(([id, knowledgeBase]) => (
        <li
          key={id}
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
            `}
          >
            <Icon {...knowledgeBase} />
          </div>
          <Truncate
            clamp={1}
            css={css`
              flex-grow: 1;
              word-break: break-word;
              color: ${knowledgeBase.error
                ? theme.palette.foreground.error
                : theme.palette.foreground.default};
            `}
          >
            <Text variant="body14">{knowledgeBase.fileName}</Text>
          </Truncate>
          <div
            css={css`
              display: flex;
            `}
          >
            {knowledgeBase.error && (
              <IconButton onClick={() => onRetry(id)}>
                <IcRefresh />
              </IconButton>
            )}
            <IconButton onClick={() => onDelete(id)}>
              <IcClose />
            </IconButton>
          </div>
        </li>
      ))}
    </ul>
  );
};

const Icon = ({ loading, processState, type, fileName }: UploadingFile) => {
  if (
    loading ||
    processState === UserQueryTypes.ProcessStateEnum.INIT ||
    processState === UserQueryTypes.ProcessStateEnum.PARSING
  ) {
    return <CircularProgress size="xs" />;
  }
  return <FileIcon type={type || ''} fileName={fileName || ''} />;
};
