import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';
import { FunctionComponent } from 'react';

type HighlightedTextClassKey = 'part' | 'highlight';

type HighlightedTextClasses = Record<HighlightedTextClassKey, string>;

type MatchOptions = Parameters<typeof match>[2];

interface HighlightedTextProps {
  classes?: Partial<HighlightedTextClasses>;
  text: string;
  query: string;
  options?: MatchOptions;
}

export const HighlightedText: FunctionComponent<HighlightedTextProps> = ({
  classes,
  text,
  query,
  options,
}) => {
  const theme = useTheme();
  const matches = match(text, query, options);
  const parts = parse(text, matches);

  return parts.map((part, i) => (
    <span
      css={
        part.highlight &&
        css`
          font-weight: ${theme.typography.fontWeightBold};
        `
      }
      key={[i, part.text, part.highlight].join('-')}
      className={clsx(classes?.part, {
        [classes?.highlight ?? '']: part.highlight,
      })}
    >
      {part.text}
    </span>
  ));
};
