import {
  svgIconClasses as muiSvgIconClasses,
  SvgIconClassKey as MuiSvgIconClassKey,
} from '@mui/material/SvgIcon';
import { omit } from 'lodash-es';

export type SvgIconClassKey = Exclude<
  MuiSvgIconClassKey,
  'colorSecondary' | 'colorAction' | 'colorDisabled'
>;
export type SvgIconClasses = Record<SvgIconClassKey, string>;

export const svgIconClasses: SvgIconClasses = omit(muiSvgIconClasses, [
  'colorSecondary',
  'colorAction',
  'colorDisabled',
]);
