class Logger {
  constructor(public enabled = false) {}

  public log(message?: any, ...optionalParams: any[]) {
    if (this.enabled) {
      console.log(message, ...optionalParams);
    }
  }

  public info(message?: any, ...optionalParams: any[]) {
    if (this.enabled) {
      console.info(message, ...optionalParams);
    }
  }

  public error(message?: any, ...optionalParams: any[]) {
    if (this.enabled) {
      console.error(message, ...optionalParams);
    }
  }
}

export const logger = new Logger();
