import {
  formControlLabelClasses as muiFormControlLabelClasses,
  FormControlLabelClassKey as MuiFormControlLabelClassKey,
} from '@mui/material/FormControlLabel';
import { omit } from 'lodash-es';

export type FormControlLabelClassKey = Exclude<
  MuiFormControlLabelClassKey,
  'labelPlacementTop' | 'labelPlacementBottom'
>;
export type FormControlLabelClasses = Record<FormControlLabelClassKey, string>;

export const formControlLabelClasses: FormControlLabelClasses = omit(
  muiFormControlLabelClasses,
  ['labelPlacementTop', 'labelPlacementBottom'],
);
