import { Modal } from '@allganize/ui-modal';
import { Paper } from '@allganize/ui-paper';
import { Theme, useTheme } from '@allganize/ui-theme';
import { Slide, SlideProps } from '@allganize/ui-transition';
import { css } from '@emotion/react';
import { capitalize } from '@mui/material/utils';
import clsx from 'clsx';
import { forwardRef, useEffect, useRef } from 'react';
import { drawerClasses } from './drawer-classes';
import { DrawerProps } from './drawer-type-map';

const oppositeDirection = {
  left: 'right',
  right: 'left',
  top: 'down',
  bottom: 'up',
} as const satisfies Record<
  NonNullable<DrawerProps['anchor']>,
  NonNullable<SlideProps['direction']>
>;

const isHorizontal = (
  anchor: NonNullable<DrawerProps['anchor']>,
): anchor is 'left' | 'right' => {
  return ['left', 'right'].indexOf(anchor) !== -1;
};

const getAnchor = (
  theme: Theme,
  anchor: NonNullable<DrawerProps['anchor']>,
) => {
  return theme.direction === 'rtl' && isHorizontal(anchor)
    ? oppositeDirection[anchor]
    : anchor;
};

export const Drawer = forwardRef<HTMLDivElement, DrawerProps>((props, ref) => {
  const theme = useTheme();
  const defaultTransitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const {
    anchor: anchorProp = 'left',
    children,
    classes,
    className,
    container,
    elevation = 4,
    hideBackdrop = false,
    ModalProps,
    onClose,
    open = false,
    paperRef,
    PaperProps = {},
    SlideProps,
    transitionDuration = defaultTransitionDuration,
    variant = 'temporary',
    ...other
  } = props;

  // Let's assume that the Drawer will always be rendered on user space.
  // We use this state is order to skip the appear transition during the
  // initial mount of the component.
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
  }, []);

  const anchorInvariant = getAnchor(theme, anchorProp);
  const anchor = anchorProp;

  const drawer = (
    <Paper
      css={[
        css`
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          height: 100%;
          flex: 1 0 auto;
          z-index: ${theme.zIndex.drawer};
          /* Add iOS momentum scrolling for iOS < 13.0 */
          -webkit-overflow-scrolling: touch;
          position: fixed;
          top: 0;
          /**
           * We disable the focus ring for mouse, touch and keyboard users.
           * At some point, it would be better to keep it for keyboard users.
           * :focus-ring CSS pseudo-class will help.
           */
          outline: 0;
        `,
        anchor === 'left' &&
          css`
            left: 0;
          `,
        anchor === 'top' &&
          css`
            top: 0;
            left: 0;
            right: 0;
            height: auto;
            max-height: 100%;
          `,
        anchor === 'right' &&
          css`
            right: 0;
          `,
        anchor === 'bottom' &&
          css`
            top: auto;
            left: 0;
            bottom: 0;
            right: 0;
            height: auto;
            max-height: 100%;
          `,
        anchor === 'left' &&
          variant !== 'temporary' &&
          css`
            border-right: 1px solid ${theme.palette.divider};
          `,
        anchor === 'top' &&
          variant !== 'temporary' &&
          css`
            border-bottom: 1px solid ${theme.palette.divider};
          `,
        anchor === 'right' &&
          variant !== 'temporary' &&
          css`
            border-left: 1px solid ${theme.palette.divider};
          `,
        anchor === 'bottom' &&
          variant !== 'temporary' &&
          css`
            border-top: 1px solid ${theme.palette.divider};
          `,
      ]}
      elevation={variant === 'temporary' ? elevation : 0}
      square
      {...PaperProps}
      className={clsx(
        drawerClasses.paper,
        drawerClasses[
          `paperAnchor${capitalize(anchor)}` as keyof typeof drawerClasses
        ],
        variant !== 'temporary' &&
          drawerClasses[
            `paperAnchorDocked${capitalize(
              anchor,
            )}` as keyof typeof drawerClasses
          ],
        classes?.paper,
        PaperProps.className,
      )}
      ref={paperRef}
    >
      {children}
    </Paper>
  );

  if (variant === 'permanent') {
    return (
      <div
        data-testid="drawer"
        css={css`
          flex: 0 0 auto;
        `}
        {...other}
        ref={ref}
        className={clsx(
          drawerClasses.root,
          drawerClasses.docked,
          classes?.root,
          classes?.docked,
          className,
        )}
      >
        {drawer}
      </div>
    );
  }

  const slidingDrawer = (
    <Slide
      in={open}
      direction={oppositeDirection[anchorInvariant]}
      timeout={transitionDuration}
      appear={mounted.current}
      {...SlideProps}
    >
      {drawer}
    </Slide>
  );

  if (variant === 'persistent') {
    return (
      <div
        data-testid="drawer"
        css={css`
          flex: 0 0 auto;
        `}
        {...other}
        ref={ref}
        className={clsx(
          drawerClasses.root,
          drawerClasses.docked,
          classes?.root,
          classes?.docked,
          className,
        )}
      >
        {slidingDrawer}
      </div>
    );
  }

  // variant === temporary
  return (
    <Modal
      data-testid="drawer"
      className={clsx(
        drawerClasses.root,
        drawerClasses.modal,
        classes?.root,
        classes?.modal,
        className,
      )}
      open={open}
      onClose={onClose}
      hideBackdrop={hideBackdrop}
      container={container}
      ref={ref}
      {...other}
      {...ModalProps}
      slotProps={{
        backdrop: {
          ...other.slotProps,
          ...ModalProps?.slotProps,
          transitionDuration,
        },
      }}
    >
      {slidingDrawer}
    </Modal>
  );
});
