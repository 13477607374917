import type { ManifestOptions } from 'vite-plugin-pwa';

const getStartUrl = () => {
  const currentUrl = new URL(window.location.href);
  const projectToken = currentUrl.searchParams.get('project_token');
  const region = currentUrl.searchParams.get('region');
  const startUrl = new URL(window.location.origin);

  // for analytics
  startUrl.searchParams.set('pwa', 'true');

  if (projectToken) {
    startUrl.searchParams.set('project_token', projectToken);
  }

  if (region) {
    startUrl.searchParams.set('region', region);
  }

  return startUrl.toString();
};

const getBaseManifest = (): Partial<ManifestOptions> => ({
  name: 'Alli App Market',
  short_name: 'Alli',
  icons: [
    {
      src: `${window.location.origin}/pwa-192x192.png`,
      sizes: '192x192',
      type: 'image/png',
      purpose: 'any',
    },
    {
      src: `${window.location.origin}/pwa-512x512.png`,
      sizes: '512x512',
      type: 'image/png',
      purpose: 'any',
    },
    {
      src: `${window.location.origin}/pwa-maskable-192x192.png`,
      sizes: '192x192',
      type: 'image/png',
      purpose: 'maskable',
    },
    {
      src: `${window.location.origin}/pwa-maskable-512x512.png`,
      sizes: '512x512',
      type: 'image/png',
      purpose: 'maskable',
    },
  ],
  start_url: getStartUrl(),
  display: 'standalone',
  background_color: '#FFFFFF',
  description: 'App Market for Alli, the inteligent AI assistant',
});

export const setManifest = (opts?: Partial<ManifestOptions>) => {
  const manifest = {
    ...getBaseManifest(),
    ...opts,
  };
  const blob = new Blob([JSON.stringify(manifest)], {
    type: 'application/json',
  });
  const link = document.createElement('link');
  link.rel = 'manifest';
  link.href = URL.createObjectURL(blob);
  document.head.appendChild(link);
};
