import { Button } from '@allganize/ui-button';
import { IcAdd } from '@allganize/ui-icons';
import { List, ListItem, ListProps } from '@allganize/ui-list';
import { css } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { Link, useMatch } from 'react-router-dom';
import { analytics } from '../../analytics';

const menuListStyle = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MenuList = ({ children, ...others }: ListProps) => {
  const matchIndex = useMatch('/');
  const handleClickNewChat = () => {
    analytics.track('lnb::new-chat.click');
  };

  return (
    <List css={menuListStyle} disablePadding {...others}>
      <ListItem disablePadding>
        <Button
          size="large"
          shape="rounded"
          variant="outlined"
          startIcon={<IcAdd />}
          component={Link}
          to="/"
          disabled={!!matchIndex}
          onClick={handleClickNewChat}
        >
          <FormattedMessage
            id="AppMarket.LNB.MenuList.NewChat"
            defaultMessage="New chat"
            description="app market lnb new chat button text"
          />
        </Button>
      </ListItem>
      {children}
    </List>
  );
};
