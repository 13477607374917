import { ListSubheader } from '@allganize/ui-list';
import clsx from 'clsx';
import { GroupBase, GroupHeadingProps } from 'react-select';
import { selectClasses } from './select-classes';

export const GroupHeading = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: GroupHeadingProps<Option, IsMulti, Group>,
) => {
  const {
    className,
    cx,
    data,
    getStyles,
    getClassNames,
    selectProps,
    theme,
    ...innerProps
  } = props;

  return (
    <ListSubheader
      {...innerProps}
      color="default"
      component="div"
      className={clsx(
        selectClasses.groupHeading,
        getClassNames('groupHeading', props),
        className,
      )}
      disableSticky
      ref={null}
    />
  );
};
