import { Truncate } from '@allganize/truncate';
import { useTheme } from '@allganize/ui-theme';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import clsx from 'clsx';

import { FileIcon } from '../file-icon/file-icon';
import { knowledgeBaseCardClasses } from './knowledge-base-card-classes';
import { KnowledgeBaseCardProps } from './knowledge-base-card-type-map';

export const KnowledgeBaseCard = ({
  data,
  startAdornment,
  endAdornment,
  classes,
  ...others
}: KnowledgeBaseCardProps) => {
  const theme = useTheme();
  const { type, fileName, description } = data;
  return (
    <div
      {...others}
      className={clsx(
        knowledgeBaseCardClasses.root,
        classes?.root,
        others.className,
      )}
      css={css`
        display: flex;
        padding: ${theme.spacing(1)};
        border-radius: ${theme.radius.sm}px;
        border: 1px solid ${theme.palette.border.divider.subtle};
        background: ${theme.palette.unstable_background.white};
      `}
    >
      {startAdornment && (
        <div
          className={clsx(
            knowledgeBaseCardClasses.startAdornment,
            classes?.startAdornment,
          )}
          css={css`
            margin-right: 8px;
          `}
        >
          {startAdornment}
        </div>
      )}

      <div
        className={clsx(knowledgeBaseCardClasses.content, classes?.content)}
        css={css`
          flex-grow: 1;
          display: flex;
        `}
      >
        <FileIcon
          type={type}
          fileName={fileName}
          fontSize="large"
          css={css`
            margin-right: 6px;
          `}
        />
        <div
          css={css`
            word-break: break-word;
          `}
        >
          <Truncate clamp={1}>
            <Text
              variant="title12"
              css={[
                css`
                  color: ${theme.palette.foreground.secondary};
                `,
                !description &&
                  css`
                    line-height: 24px;
                  `,
              ]}
            >
              {fileName}
            </Text>
          </Truncate>
          {description && (
            <Truncate clamp={1}>
              <Text
                variant="body12"
                css={css`
                  color: ${theme.palette.foreground.helper};
                `}
              >
                {description}
              </Text>
            </Truncate>
          )}
        </div>
      </div>

      {endAdornment && (
        <div
          className={clsx(
            knowledgeBaseCardClasses.endAdornment,
            classes?.endAdornment,
          )}
          css={css`
            margin-left: 8px;
          `}
        >
          {endAdornment}
        </div>
      )}
    </div>
  );
};
