import { noop } from 'lodash-es';
import { createContext } from 'react';

export interface TypingContextValue {
  onTyping: (isTyping: boolean) => void;
}

export const TypingContext = createContext<TypingContextValue>({
  onTyping: noop,
});
