export interface Converter<T> {
  write(value: T, name: string): string;
  read(value: string | undefined, name: string): T | undefined;
}

export const defaultConverter: Converter<string> = {
  write(value) {
    return value;
  },
  read(value) {
    return value;
  },
};

export interface Storage<T = string> {
  getItem(key: string): Promise<T | undefined>;
  setItem(key: string, value: T): Promise<void>;
  removeItem(key: string): Promise<void>;
}
