import { useMediaQuery } from '@allganize/hooks';
import { forwardRef } from 'react';
import { DesktopDatePicker } from '../desktop-date-picker';
import { DEFAULT_DESKTOP_MODE_MEDIA_QUERY } from '../internals/utils/utils';
import { MobileDatePicker } from '../mobile-date-picker';
import { DatePickerProps } from './date-picker-type-map';

export const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
  (props, ref) => {
    const {
      desktopModeMediaQuery = DEFAULT_DESKTOP_MODE_MEDIA_QUERY,
      ...other
    } = props;

    // defaults to `true` in environments where `window.matchMedia` would not be available (i.e. test/jsdom)
    const isDesktop = useMediaQuery(desktopModeMediaQuery, {
      defaultMatches: true,
    });

    if (isDesktop) {
      return <DesktopDatePicker ref={ref} {...other} />;
    }

    return <MobileDatePicker ref={ref} {...other} />;
  },
);
