import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type FaqCarouselOptionClassKey = 'root' | 'button' | 'checkbox';
export type FaqCarouselOptionClasses = Record<
  FaqCarouselOptionClassKey,
  string
>;

export const faqCarouselOptionClasses: FaqCarouselOptionClasses =
  generateUtilityClasses('FaqCarouselOption', ['root', 'button', 'checkbox']);
