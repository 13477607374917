import { useId } from '@allganize/hooks';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { GroupBase } from 'react-select';
import { Creatable } from '../creatable';
import { SelectFieldBase } from '../select-field-base';
import { creatableFieldClasses } from './creatable-field-classes';
import { CreatableFieldProps } from './creatable-field-type-map';

interface CreatableFieldType {
  <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: React.PropsWithoutRef<CreatableFieldProps<Option, IsMulti, Group>> &
      React.RefAttributes<HTMLDivElement>,
  ): React.ReactNode;

  readonly $$typeof: symbol;
  displayName?: string;
}

// @ts-expect-error overridable component
export const CreatableField: CreatableFieldType = forwardRef(
  <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: CreatableFieldProps<Option, IsMulti, Group>,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const {
      autoFocus = false,
      children,
      classes,
      className,
      color = 'primary',
      disabled = false,
      error = false,
      FormHelperTextProps,
      fullWidth = false,
      helperText,
      id: idProp,
      InputLabelProps,
      isMulti,
      label,
      name,
      onBlur,
      onChange,
      onFocus,
      options,
      placeholder,
      required = false,
      value,
      SelectProps,
      selectRef,
      ...other
    } = props;
    const reactId = useId();
    const id = idProp || `creatable-field-${reactId}`;
    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
    const inputLabelId = label && id ? `${id}-label` : undefined;

    return (
      <SelectFieldBase
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        required={required}
        color={color}
        label={label}
        InputLabelProps={{
          htmlFor: id,
          id: inputLabelId,
          ...InputLabelProps,
        }}
        helperText={helperText}
        FormHelperTextProps={{
          id: helperTextId,
          ...FormHelperTextProps,
        }}
        {...other}
        ref={ref}
        className={clsx(creatableFieldClasses.root, classes?.root, className)}
      >
        <Creatable<Option, IsMulti, Group>
          aria-labelledby={inputLabelId}
          autoFocus={autoFocus}
          name={name}
          value={value}
          inputId={id}
          isMulti={isMulti}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          options={options}
          placeholder={placeholder}
          {...SelectProps}
          ref={selectRef}
        />
      </SelectFieldBase>
    );
  },
);
