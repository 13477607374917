/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AutoCompletionQueryVariables = Types.Exact<{
  partial: Types.Scalars['String']['input'];
  where: Types.ConversationWhereUniqueInput;
}>;

export type AutoCompletionQuery = { __typename: 'UserQuery' } & {
  autoCompletion: Types.Maybe<
    { __typename: 'AutoCompletion' } & Pick<Types.AutoCompletion, 'questions'>
  >;
};

export const AutoCompletionQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AutoCompletionQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'partial' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ConversationWhereUniqueInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoCompletion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partial' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'partial' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'questions' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AutoCompletionQuery, AutoCompletionQueryVariables>;
