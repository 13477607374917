import { AgentQueryTypes } from '@allganize/alli-interfaces';
import { ApolloError, isApolloError } from '@apollo/client/errors';
import { ServerParseError } from '@apollo/client/link/http';
import { ServerError } from '@apollo/client/link/utils';
import { compact } from 'lodash-es';
import { ReactNode } from 'react';
import { IntlShape, MessageDescriptor } from 'react-intl';

import { ErrorFragment } from '../../graphql/fragments/error-fragment';
import { SingleActionInputKnowledgeBaseFragment } from '../../graphql/fragments/single-action-input-knowledge-base-fragment';
import { NETWORK_ERROR_STATUS_CODES, mallyErrors } from './mally-errors';

export const getMallyErrorMessageDescriptor = (
  err: ErrorFragment | ApolloError,
): MessageDescriptor & { values?: Record<string, any> } => {
  if (err instanceof ApolloError) {
    if (isNetworkError(err)) {
      return mallyErrors.NETWORK;
    }

    const graphQLError = compact(err.graphQLErrors)[0];

    if (graphQLError) {
      const message =
        typeof graphQLError === 'string' ? graphQLError : graphQLError.message;
      return {
        id: `MallyError.${message}`,
        defaultMessage: message,
        description: `MallyError.${message}`,
      };
    }

    return mallyErrors.UNKNOWN;
  }

  let values: Record<string, any> | undefined = undefined;

  if (err.info) {
    try {
      values = JSON.parse(err.info);
    } catch {
      // nothing to do
    }
  }

  return {
    id: `MallyError.${err.key}`,
    defaultMessage:
      err.message ||
      (
        mallyErrors as Partial<
          Record<AgentQueryTypes.MallyError, MessageDescriptor>
        >
      )[err.key]?.defaultMessage ||
      err.key,
    description: `MallyError.${err.key}`,
    values,
  };
};

export const getKBProcessStateErrorMessage = (
  knowledgeBase: SingleActionInputKnowledgeBaseFragment | null | undefined,
  intl: IntlShape,
): ReactNode => {
  if (
    !knowledgeBase ||
    knowledgeBase.__typename === 'GoogleDocsKnowledgeBase'
  ) {
    return null;
  }

  if (
    knowledgeBase.processState === AgentQueryTypes.ProcessStateEnum.COMPLETED
  ) {
    return null;
  } else if (
    knowledgeBase.processState === AgentQueryTypes.ProcessStateEnum.RETRYING
  ) {
    return intl.formatMessage(
      {
        id: 'single-action.upload-file.retrying-error-message',
        defaultMessage:
          'Temporary error occurred, will resume in {processRetryAt}.',
        description:
          'single action upload file process state RETRYING error message',
      },
      {
        processRetryAt:
          knowledgeBase.processRetryAt !== null &&
          intl.formatRelativeTime(knowledgeBase.processRetryAt),
      },
    );
  } else if (
    knowledgeBase.processState === AgentQueryTypes.ProcessStateEnum.PARSING_FAIL
  ) {
    return intl.formatMessage({
      id: 'single-action.upload-file.parsing-fail-error-message',
      defaultMessage: 'Parsing failed',
      description:
        'single action upload file process state parsing file error message',
    });
  } else {
    return null;
  }
};

const getErrorStatusCode = (err: Error): number | null => {
  if (
    isApolloError(err) &&
    err.networkError &&
    'statusCode' in err.networkError &&
    typeof (err.networkError as ServerParseError | ServerError).statusCode ===
      'number'
  ) {
    return (err.networkError as ServerParseError | ServerError).statusCode;
  }
  return null;
};

export const isNetworkError = (err: Error): boolean => {
  const statusCode = getErrorStatusCode(err);
  return statusCode !== null && NETWORK_ERROR_STATUS_CODES.includes(statusCode);
};
