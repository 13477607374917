import {
  DraftInputBase,
  DraftInputProps,
  DraftInputRef,
} from '@allganize/draft-input';
import { forwardRef } from 'react';
import { UseRichTextOptions, useRichText } from './use-rich-text';

export interface RichTextProps
  extends Omit<DraftInputProps, 'onChange' | 'value'>,
    Pick<UseRichTextOptions, 'onChange'> {
  value?: UseRichTextOptions['data'];
}

export const RichText = forwardRef<DraftInputRef, RichTextProps>(
  (props, ref) => {
    const { classes, onChange: onChangeProp, ...other } = props;
    const { onChange, plugins, value } = useRichText({
      data: props.value ?? null,
      onChange: onChangeProp,
      plugins: props.plugins,
    });

    if (!value) {
      return null;
    }

    return (
      <DraftInputBase
        disabled
        {...other}
        ref={ref}
        onChange={onChange}
        plugins={plugins}
        value={value}
      />
    );
  },
);
