import {
  convertFromRaw as _convertFromRaw,
  ContentState,
  RawDraftContentState,
} from 'draft-js';

export const convertFromRaw = (
  input: string | RawDraftContentState,
): ContentState => {
  let contentState: RawDraftContentState;

  if (typeof input === 'string') {
    contentState = JSON.parse(input);
  } else {
    contentState = input;
  }

  return _convertFromRaw(contentState);
};
