import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { forwardRef } from 'react';
import { AccordionDetailsProps } from './accordion-details-type-map';

export const AccordionDetails = forwardRef<
  HTMLDivElement,
  AccordionDetailsProps
>((props, ref) => {
  const theme = useTheme();

  return (
    <MuiAccordionDetails
      data-testid="accordion-details"
      css={css`
        padding: ${theme.spacing(1, 2, 2)};
      `}
      {...props}
      ref={ref}
    />
  );
});
