import { DraftInputRef } from '@allganize/draft-input';
import { forwardRef } from 'react';
import { RichText, RichTextProps } from '../rich-text/rich-text';
import { UseChatFileOptions, useChatFile } from './use-chat-file';

export interface ChatFileProps
  extends Omit<RichTextProps, 'plugins' | 'value'>,
    Pick<UseChatFileOptions, 'file'> {}

export const ChatFile = forwardRef<DraftInputRef, ChatFileProps>(
  ({ file, ...other }, ref) => {
    const { contentState } = useChatFile({ file });
    return <RichText {...other} value={contentState} ref={ref} />;
  },
);
