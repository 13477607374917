export const green = {
  50: 'hsla(132, 100%, 90%, 1)',
  100: 'hsla(132, 100%, 80%, 1)',
  200: 'hsla(132, 100%, 70%, 1)',
  300: 'hsla(132, 100%, 60%, 1)',
  400: 'hsla(132, 100%, 50%, 1)',
  500: 'hsla(132, 100%, 40%, 1)',
  600: 'hsla(132, 100%, 30%, 1)',
  700: 'hsla(132, 100%, 20%, 1)',
  800: 'hsla(132, 100%, 10%, 1)',
  900: 'hsla(132, 100%, 5%, 1)',
};
