import { css } from '@emotion/react';
import { Toolbar } from '@mui/material';
import { HTMLAttributes, forwardRef } from 'react';
import { conversationDetailFooterMinHeight } from '../conversation-detail/conversation-detail-footer';

export const ChatFormRoot = forwardRef<
  HTMLFormElement,
  HTMLAttributes<HTMLFormElement>
>((props, ref) => {
  return (
    <Toolbar
      css={css`
        min-height: ${conversationDetailFooterMinHeight}px;
        align-items: flex-start;
        padding: 6px 16px;
      `}
      disableGutters
    >
      <form
        css={css`
          margin: 0;
          flex-grow: 1;
          position: relative;
        `}
        {...props}
        ref={ref}
      />
    </Toolbar>
  );
});
