import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type DocumentViewerToolbarSpacerClassKey = 'root';
export type DocumentViewerToolbarSpacerClasses = Record<
  DocumentViewerToolbarSpacerClassKey,
  string
>;

export const documentViewerToolbarSpacerClasses: DocumentViewerToolbarSpacerClasses =
  generateUtilityClasses('DocumentViewerToolbarSpacer', ['root']);
