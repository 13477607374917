import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { MultiSectionDigitalClockSection as MuiMultiSectionDigitalClockSection } from '@mui/x-date-pickers/MultiSectionDigitalClock/MultiSectionDigitalClockSection';
import { forwardRef } from 'react';
import { DIGITAL_CLOCK_VIEW_HEIGHT } from '../internals/constants';
import { DigitalClockSectionItem } from './multi-section-digital-clock-section-slots';
import { MultiSectionDigitalClockSectionProps } from './multi-section-digital-clock-section-type-map';

interface MultiSectionDigitalClockSectionType {
  <TValue>(
    props: React.PropsWithoutRef<MultiSectionDigitalClockSectionProps<TValue>> &
      React.RefAttributes<HTMLUListElement>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

// @ts-expect-error overridable component
export const MultiSectionDigitalClockSection: MultiSectionDigitalClockSectionType =
  forwardRef((props, ref) => {
    const { slots, ...other } = props;
    const theme = useTheme();

    return (
      <MuiMultiSectionDigitalClockSection
        data-testid="multi-section-digital-clock-section"
        css={css`
          max-height: ${DIGITAL_CLOCK_VIEW_HEIGHT}px;

          &:not(:first-of-type) {
            border-left: 1px solid ${theme.palette.divider};
          }
        `}
        {...other}
        ref={ref}
        slots={{
          digitalClockSectionItem: DigitalClockSectionItem,
          ...slots,
        }}
      />
    );
  });
