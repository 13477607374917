import { Chip } from '@allganize/ui-chip';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { Fragment, FunctionComponent, HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { FileIcon } from '../file-icon';
import { resetUlStyles } from '../styles/reset';

import type { KnowledgeBase } from '../types/types';

interface KnowledgeBaseChipListProps extends HTMLAttributes<HTMLDivElement> {
  knowledgeBases: KnowledgeBase[];
  onDelete: (knowledgeBase: KnowledgeBase) => void;
}

export const KnowledgeBaseChipList: FunctionComponent<
  KnowledgeBaseChipListProps
> = ({ knowledgeBases, onDelete, ...other }) => {
  const theme = useTheme();

  if (knowledgeBases.length === 0) {
    return null;
  }

  return (
    <div
      css={css`
        margin-bottom: 16px;
      `}
      {...other}
    >
      <Text
        variant="subtitle14"
        css={css`
          margin-bottom: 8px;
          color: ${theme.palette.foreground.secondary};
        `}
      >
        <FormattedMessage
          id="knowledge-base.search-dialog.selected-file-description"
          defaultMessage="{count} {count, plural, one {file} other {files}} selected"
          description="knowledge base search dialog selected file chip list description"
          values={{ count: knowledgeBases.length }}
        />
      </Text>
      <ul
        css={[
          resetUlStyles,
          css`
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            max-height: 74px;
            overflow-y: auto;

            li {
              max-width: 100%;
            }
          `,
        ]}
      >
        {knowledgeBases.map(kb => (
          <li key={kb.id}>
            <Chip
              label={
                <Fragment>
                  <FileIcon
                    type={kb.type}
                    fileName={kb.fileName}
                    fontSize="small"
                    css={css`
                      margin-right: 4px;
                    `}
                  />
                  {kb.fileName}
                </Fragment>
              }
              onDelete={() => onDelete(kb)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
