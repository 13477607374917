import { css } from '@emotion/react';
import { forwardRef } from 'react';
import {
  OrderedListProps,
  UnorderedListProps,
} from 'react-markdown/lib/ast-to-react';

export const UnorderedList = forwardRef<
  HTMLUListElement,
  React.HTMLAttributes<HTMLUListElement> & UnorderedListProps
>((props, ref) => {
  const {
    node,
    sourcePosition,
    index,
    siblingCount,
    depth,
    ordered,
    ...other
  } = props;

  return (
    <ul
      css={[
        css`
          margin: 0 0 0 16px;
          padding: 0 0 0 8px;
        `,
        depth === 0 &&
          css`
            margin-top: 16px;
            margin-bottom: 16px;
          `,
        depth > 0 &&
          css`
            margin-right: 32px;
          `,
      ]}
      {...other}
      ref={ref}
    />
  );
});

export const OrderedList = forwardRef<
  HTMLOListElement,
  React.OlHTMLAttributes<HTMLOListElement> & OrderedListProps
>((props, ref) => {
  const {
    node,
    sourcePosition,
    index,
    siblingCount,
    depth,
    ordered,
    ...other
  } = props;

  return (
    <ol
      css={[
        css`
          margin: 0 0 0 16px;
          padding: 0 0 0 8px;
        `,
        depth === 0 &&
          css`
            margin-top: 16px;
            margin-bottom: 16px;
          `,
        depth > 0 &&
          css`
            margin-right: 32px;
          `,
      ]}
      {...other}
      ref={ref}
    />
  );
});
