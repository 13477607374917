import { createSvgIcon } from '@allganize/ui-icons';

export const IcFileTXT = createSvgIcon(
  <>
    <path
      d="M6 22.5469H20.3077C21.1361 22.5469 21.8077 21.8753 21.8077 21.0469V7.69265C21.8077 7.29885 21.6528 6.92085 21.3765 6.64024L16.7099 1.90072C16.428 1.61437 16.0429 1.45312 15.6411 1.45312H6C5.17157 1.45312 4.5 2.1247 4.5 2.95312V21.0469C4.5 21.8753 5.17157 22.5469 6 22.5469Z"
      fill="white"
    />
    <path
      d="M5.0625 2.95312C5.0625 2.43536 5.48223 2.01562 6 2.01562H15.6411C15.6631 2.01562 15.685 2.0164 15.7068 2.01793V5.57812C15.7068 6.71721 16.6302 7.64062 17.7693 7.64062H21.2437C21.2447 7.6579 21.2452 7.67525 21.2452 7.69265V21.0469C21.2452 21.5646 20.8255 21.9844 20.3077 21.9844H6C5.48223 21.9844 5.0625 21.5646 5.0625 21.0469V2.95312ZM20.4644 6.51562L16.8318 2.82621V5.57812C16.8318 6.09589 17.2515 6.51562 17.7693 6.51562H20.4644ZM6 0.890625C4.86091 0.890625 3.9375 1.81404 3.9375 2.95312V21.0469C3.9375 22.186 4.86092 23.1094 6 23.1094H20.3077C21.4468 23.1094 22.3702 22.186 22.3702 21.0469V7.69265C22.3702 7.15117 22.1573 6.63142 21.7774 6.24559L17.1108 1.50607C16.7231 1.11234 16.1936 0.890625 15.6411 0.890625H6Z"
      fill="black"
      fillOpacity="0.2"
      fillRule="evenodd"
    />
    <path
      d="M0.75 12.75C0.75 11.0931 2.09315 9.75 3.75 9.75H13.5C15.1569 9.75 16.5 11.0931 16.5 12.75V17.25C16.5 18.9069 15.1569 20.25 13.5 20.25H3.75C2.09315 20.25 0.75 18.9069 0.75 17.25V12.75Z"
      fill="#666666"
    />
    <path
      d="M2.77588 12.8184V13.6749H4.10116V17.182H5.14094V13.6749H6.46409V12.8184H2.77588ZM7.80602 12.8184L8.60289 14.1969H8.63698L9.44238 12.8184H10.6228L9.30602 15.0002L10.6654 17.182H9.45517L8.63698 15.7885H8.60289L7.78471 17.182H6.58301L7.93599 15.0002L6.6171 12.8184H7.80602ZM10.7856 13.6749V12.8184H14.4739V13.6749H13.1507V17.182H12.1109V13.6749H10.7856Z"
      fill="white"
      fillRule="evenodd"
    />
  </>,
  'ic-file-txt',
);
