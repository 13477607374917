import { FormControl, FormHelperText } from '@allganize/ui-form';
import { InputLabel } from '@allganize/ui-input';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { selectFieldBaseClasses } from './select-field-base-classes';
import { SelectFieldBaseProps } from './select-field-base-type-map';

export const SelectFieldBase = forwardRef<HTMLDivElement, SelectFieldBaseProps>(
  (props, ref) => {
    const {
      children,
      classes,
      className,
      FormHelperTextProps,
      helperText,
      InputLabelProps,
      label,
      ...other
    } = props;

    return (
      <FormControl
        {...other}
        ref={ref}
        className={clsx(selectFieldBaseClasses.root, classes?.root, className)}
      >
        {label !== null && label !== '' && (
          <InputLabel {...InputLabelProps}>{label}</InputLabel>
        )}

        {children}

        {helperText && (
          <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  },
);
