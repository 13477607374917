import { gfmAutolinkLiteral } from 'micromark-extension-gfm-autolink-literal';
import { gfmTable } from 'micromark-extension-gfm-table';
import { combineExtensions } from 'micromark-util-combine-extensions';
import type { Root } from 'mdast';
import {
  gfmAutolinkLiteralFromMarkdown,
  gfmAutolinkLiteralToMarkdown,
} from 'mdast-util-gfm-autolink-literal';
import {
  gfmTableFromMarkdown,
  gfmTableToMarkdown,
  Options as GfmTableOptions,
} from 'mdast-util-gfm-table';
import type { Processor } from 'unified';

interface RemarkGfmOptions extends GfmTableOptions {}

export function remarkGfm(
  this: Processor<void, Root>,
  options?: RemarkGfmOptions,
) {
  const data = this.data();

  add('micromarkExtensions', combineExtensions([gfmAutolinkLiteral, gfmTable]));
  add('fromMarkdownExtensions', [
    gfmAutolinkLiteralFromMarkdown,
    gfmTableFromMarkdown,
  ]);
  add('toMarkdownExtensions', {
    extensions: [gfmAutolinkLiteralToMarkdown, gfmTableToMarkdown(options)],
  });

  function add(field: string, value: unknown) {
    const list =
      // Other extensions
      /* c8 ignore next 2 */
      data[field] ? data[field] : (data[field] = []);

    // @ts-expect-error: hush
    list.push(value);
  }
}
