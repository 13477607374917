import { Backdrop, Modal } from '@allganize/ui-modal';
import { Paper, isPaperElevation } from '@allganize/ui-paper';
import styled from '@emotion/styled';
import MuiPaper from '@mui/material/Paper';
import { forwardRef } from 'react';
import invariant from 'tiny-invariant';

export const DialogBackdrop = styled(Backdrop)`
  z-index: -1;
`;

export const DialogRoot = styled(Modal)`
  @media print {
    position: absolute !important;
  }
`;

// @ts-expect-error overridable component
export const DialogPaper: typeof MuiPaper = forwardRef((props, ref) => {
  invariant(
    typeof props.elevation === 'undefined' || isPaperElevation(props.elevation),
    `elevation ${props.elevation} is not valid.`,
  );
  return <Paper {...props} ref={ref} elevation={props.elevation} />;
});
