import EventEmitter from 'eventemitter3';

export type AlliPageErrorType =
  | 'chat-token-expired'
  | 'project-query-fail'
  | 'unknown';

interface AlliPageErrorManagerEvents {
  clear(): void;
  error(type: AlliPageErrorType): void;
}

export class AlliPageErrorManager extends EventEmitter<AlliPageErrorManagerEvents> {
  private _error: AlliPageErrorType | null = null;

  public get error(): AlliPageErrorType | null {
    return this._error;
  }

  private updateError(error: AlliPageErrorType | null) {
    if (error !== this._error) {
      this._error = error;

      if (error) {
        this.emit('error', error);
      } else {
        this.emit('clear');
      }
    }
  }

  public report(error: AlliPageErrorType) {
    this.updateError(error);
  }

  public clearError() {
    this.updateError(null);
  }
}
