export const yellow = {
  50: 'hsla(37, 100%, 97%, 1)',
  100: 'hsla(37, 100%, 92%, 1)',
  200: 'hsla(37, 100%, 87%, 1)',
  300: 'hsla(37, 100%, 77%, 1)',
  400: 'hsla(37, 100%, 67%, 1)',
  500: 'hsla(37, 100%, 57%, 1)',
  600: 'hsla(37, 100%, 47%, 1)',
  700: 'hsla(37, 100%, 37%, 1)',
  800: 'hsla(37, 100%, 27%, 1)',
  900: 'hsla(37, 100%, 17%, 1)',
};
