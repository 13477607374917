import { OverridableComponent } from '@allganize/types';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import { forwardRef } from 'react';
import { ChipBase } from '../chip-base';
import { chipClasses } from './chip.classes';
import { ChipTypeMap } from './chip.types';

// @ts-expect-error overridable component
export const Chip: OverridableComponent<ChipTypeMap> = forwardRef(
  (props, ref) => {
    const {
      classes,
      className,
      clickable: clickableProp,
      color = 'gray-subtle',
      onClick,
      ...other
    } = props;
    const theme = useTheme();
    const clickable = clickableProp !== false && onClick ? true : clickableProp;

    return (
      <ClassNames>
        {({ css, cx }) => (
          <ChipBase
            data-testid="chip"
            clickable={clickable}
            onClick={onClick}
            {...other}
            ref={ref}
            classes={{
              ...chipClasses,
              ...classes,
              root: cx(
                css`
                  ${theme.typography.body14}
                  height: 24px;
                  border-radius: ${theme.radius.xs}px;
                  transition: ${theme.transitions.create([
                    'background-color',
                    'color',
                  ])};
                  padding: 2px 6px;
                  gap: 4px;

                  &.${chipClasses.disabled} {
                    color: ${theme.palette.foreground.gray.disabled};
                    background-color: ${theme.palette.unstable_background
                      .grayDisabled.alpha};

                    .${chipClasses.avatar} {
                      opacity: ${theme.palette.action.disabledOpacity};
                    }

                    .${chipClasses.deleteIcon} {
                      background-color: transparent;
                    }
                  }

                  .${chipClasses.avatar} {
                    width: 16px;
                    height: 16px;
                    font-size: 12px;
                  }

                  .${chipClasses.icon} {
                    font-size: 16px;
                    color: inherit;
                  }

                  .${chipClasses.deleteIcon} {
                    transition: ${theme.transitions.create([
                      'background-color',
                      'color',
                    ])};
                    background-color: transparent;
                    border-radius: ${theme.radius.xs}px;
                    font-size: 16px;
                  }
                `,

                color === 'gray-subtle' &&
                  css`
                    color: ${theme.palette.foreground.default};
                    background-color: ${theme.palette.backgroundInteractive
                      .graySubtleAlpha.default};

                    .${chipClasses.deleteIcon} {
                      &:hover {
                        background-color: ${theme.palette.backgroundInteractive
                          .grayGhostAlpha.hover};
                      }

                      &:active {
                        background-color: ${theme.palette.backgroundInteractive
                          .grayGhostAlpha.pressed};
                      }
                    }
                  `,

                color === 'gray-dark' &&
                  css`
                    color: ${theme.palette.foreground.inverse};
                    background-color: ${theme.palette.backgroundInteractive
                      .grayDarkAlpha.default};

                    .${chipClasses.deleteIcon} {
                      &:hover {
                        color: ${theme.palette.foregroundInteractive.inverse};
                        background-color: ${theme.palette.backgroundInteractive
                          .inverseAlpha.hover};
                      }

                      &:active {
                        background-color: ${theme.palette.backgroundInteractive
                          .inverseAlpha.pressed};
                      }
                    }
                  `,
                chipClasses.root,
                classes?.root,
              ),
              disabled: cx(chipClasses.disabled, classes?.disabled),
              clickable: cx(
                color === 'gray-subtle' &&
                  css`
                    &:hover {
                      background-color: ${theme.palette.backgroundInteractive
                        .graySubtleAlpha.hover};
                    }

                    &:active {
                      background-color: ${theme.palette.backgroundInteractive
                        .graySubtleAlpha.pressed};
                    }
                  `,
                color === 'gray-dark' &&
                  css`
                    &:hover {
                      background-color: ${theme.palette.backgroundInteractive
                        .grayDarkAlpha.hover};
                    }

                    &:active {
                      background-color: ${theme.palette.backgroundInteractive
                        .grayDarkAlpha.pressed};
                    }
                  `,
                chipClasses.clickable,
                classes?.clickable,
              ),
              deletable: cx(chipClasses.deletable, classes?.deletable),
              avatar: cx(chipClasses.avatar, classes?.avatar),
              icon: cx(chipClasses.icon, classes?.icon),
              label: cx(chipClasses.label, classes?.label),
              deleteIcon: cx(chipClasses.deleteIcon, classes?.deleteIcon),
              focusVisible: cx(chipClasses.focusVisible, classes?.focusVisible),
            }}
            className={cx(
              {
                [chipClasses.colorGraySubtle]: color === 'gray-subtle',
                [chipClasses.colorGrayDark]: color === 'gray-dark',
                [classes?.colorGraySubtle ?? '']: color === 'gray-subtle',
                [classes?.colorGrayDark ?? '']: color === 'gray-dark',
              },
              className,
            )}
          />
        )}
      </ClassNames>
    );
  },
);
