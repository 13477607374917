import {
  useDraftToolbarEditorStateGetter,
  useDraftToolbarEditorStateSetter,
} from '@allganize/draft-toolbar-plugin';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, defineMessages, useIntl } from 'react-intl';
import { z } from 'zod';
import { DraftVideoPlugin } from '../draft-video-plugin';

export interface DraftToolbarVideoFormValues {
  url: string;
}

const defaultDraftToolbarVideoFormValues = {
  url: '',
};

const errorMessages = defineMessages({
  'url-required': {
    id: 'TextArea.VideoButton.URL.errors.required',
    defaultMessage: 'URL is a required field',
    description: 'Text area insert video button URL required error message',
  },
  'url-format': {
    id: 'TextArea.VideoButton.URL.errors.format',
    defaultMessage: 'Must be a valid URL',
    description: 'Text area insert video button URL format error message',
  },
  'url-source': {
    id: 'TextArea.VideoButton.URL.errors.source',
    defaultMessage: 'URL must be from YouTube or Vimeo',
    description: 'Text area insert video button URL source error message',
  },
});

const createValidationSchema = (intl: IntlShape) => {
  return z.object({
    url: z
      .string({
        required_error: intl.formatMessage(errorMessages['url-required']),
        invalid_type_error: intl.formatMessage(errorMessages['url-required']),
      })
      .min(1, intl.formatMessage(errorMessages['url-required']))
      .url(intl.formatMessage(errorMessages['url-format']))
      .refine(value => {
        return !!DraftVideoPlugin.getSrc(value);
      }, intl.formatMessage(errorMessages['url-source'])),
  }) satisfies z.ZodSchema<DraftToolbarVideoFormValues>;
};

export interface UseDraftToolbarVideoFormOptions {
  defaultValues?: DraftToolbarVideoFormValues;
  onSubmit?(): void;
}

export const useDraftToolbarVideoForm = ({
  defaultValues = defaultDraftToolbarVideoFormValues,
  onSubmit,
}: UseDraftToolbarVideoFormOptions) => {
  const intl = useIntl();
  const getEditorState = useDraftToolbarEditorStateGetter();
  const setEditorState = useDraftToolbarEditorStateSetter();
  const validationSchema = useMemo(() => createValidationSchema(intl), [intl]);

  const form = useForm<DraftToolbarVideoFormValues>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const { reset, setError } = form;

  const submit = async (values: DraftToolbarVideoFormValues) => {
    try {
      setEditorState(DraftVideoPlugin.addVideo(getEditorState(), values.url));
      onSubmit?.();
      reset();
    } catch (err) {
      if (err instanceof Error) {
        setError('root', { message: err.message });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }

      setError('root', {
        message: intl.formatMessage({
          id: 'MallyError.UNKNOWN',
          defaultMessage: 'Something went wrong. Please try again later.',
          description: 'MallyError.UNKNOWN',
        }),
      });
    }
  };

  return { form, submit };
};
