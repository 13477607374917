import { LoginCallback } from '@okta/okta-react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import { App } from './app/app';
import { SecureRoute } from './auth/secure-route';
import { ConversationDetailLayout } from './components/conversation/conversation-detail-layout';
import { Layout } from './components/layout/layout';
import { convertRouter } from './utils/convert-router';
import { Component as SkillPage } from './pages/skills';

const browserRouter = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        element: (
          <SecureRoute>
            <Outlet />
          </SecureRoute>
        ),
        children: [
          {
            element: <Layout />,
            children: [
              {
                index: true,
                lazy: () => import('./pages/home'),
              },
              {
                path: 'apps',
                lazy: () => import('./pages/apps'),
              },
              {
                path: 'single-actions/:publicToken',
                lazy: () => import('./pages/single-actions/[publicToken]'),
              },
              {
                path: 'conversations/new',
                lazy: () => import('./pages/conversations/new'),
              },
              {
                path: 'conversations/:conversationId',
                element: <ConversationDetailLayout />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import('./pages/conversations/[conversationId]'),
                  },
                ],
              },
            ],
          },
          {
            path: 'skills',
            element: <SkillPage />,
          },
        ],
      },
      {
        path: 'auth/sign-in',
        lazy: () => import('./pages/auth/sign-in'),
      },
      {
        path: 'auth/entra-id/callback',
        lazy: () => import('./pages/auth/entra-id/callback'),
      },
      {
        path: 'auth/okta/sign-in',
        lazy: () => import('./pages/auth/okta/sign-in'),
      },
      {
        path: 'auth/okta/callback',
        element: <LoginCallback />,
      },
      {
        path: 'error',
        lazy: () => import('./pages/error'),
      },
      { path: '*', element: <Navigate to="/" replace /> },
    ],
  },
]);

export const router = convertRouter(browserRouter);
