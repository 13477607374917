import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type RecommendationCarouselClassKey = 'root' | 'itemWrapper';
export type RecommendationCarouselClasses = Record<
  RecommendationCarouselClassKey,
  string
>;

export const recommendationCarouselClasses: RecommendationCarouselClasses =
  generateUtilityClasses('RecommendationCarousel', ['root', 'itemWrapper']);
