import { useId } from '@allganize/hooks';
import { FormControl, FormHelperText } from '@allganize/ui-form';
import { InputLabel } from '@allganize/ui-input';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { DraftInput } from '../draft-input';
import { draftFieldClasses } from './draft-field-classes';
import { DraftFieldProps } from './draft-field-type-map';

export const DraftField = forwardRef<HTMLDivElement, DraftFieldProps>(
  (props, ref) => {
    const {
      autoComplete,
      autoFocus = false,
      children,
      classes,
      className,
      color = 'primary',
      disabled = false,
      error = false,
      FormHelperTextProps,
      fullWidth = false,
      helperText,
      id: idProp,
      InputLabelProps,
      label,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      required = false,
      value,
      InputProps,
      inputRef,
      ...other
    } = props;
    const reactId = useId();
    const id = idProp || `draft-field-${reactId}`;
    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
    const inputLabelId = label && id ? `${id}-label` : undefined;

    return (
      <FormControl
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        required={required}
        color={color}
        {...other}
        ref={ref}
        className={clsx(draftFieldClasses.root, classes?.root, className)}
      >
        {label !== null && label !== '' && (
          <InputLabel htmlFor={id} id={inputLabelId} {...InputLabelProps}>
            {label}
          </InputLabel>
        )}

        <DraftInput
          ariaLabelledBy={inputLabelId}
          ariaDescribedBy={helperTextId}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          {...InputProps}
          ref={inputRef}
        />

        {helperText && (
          <FormHelperText id={helperTextId} {...FormHelperTextProps}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);
