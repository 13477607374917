import { OverridableComponent } from '@allganize/types';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import MuiTableRow from '@mui/material/TableRow';
import { forwardRef } from 'react';
import { TableRowTypeMap } from './table-row-type-map';
import { tableRowClasses } from './table-row-classes';

// @ts-expect-error overridable component
export const TableRow: OverridableComponent<TableRowTypeMap> = forwardRef(
  (props, ref) => {
    const { classes, ...other } = props;
    const theme = useTheme();

    return (
      <ClassNames>
        {({ css, cx }) => (
          <MuiTableRow
            data-testid="table-row"
            {...other}
            ref={ref}
            classes={{
              ...classes,
              root: cx(
                css`
                  &.${tableRowClasses.hover}:hover {
                    background-color: ${theme.palette.unstable_background
                      .default};
                  }

                  &.${tableRowClasses.selected} {
                    background-color: ${theme.palette.backgroundInteractive
                      .primarySubtle.selected};

                    &:hover {
                      background-color: ${theme.palette.unstable_background
                        .default};
                    }
                  }
                `,
                classes?.root,
              ),
              head: cx(
                css`
                  background-color: ${theme.palette.unstable_background
                    .graySubtle.alpha};
                `,
                classes?.head,
              ),
            }}
          />
        )}
      </ClassNames>
    );
  },
);
