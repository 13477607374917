import { IcArrowDropDown } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { DropdownIndicatorProps, GroupBase } from 'react-select';
import { selectClasses } from './select-classes';

export const DropdownIndicator = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => {
  const { children, className, getClassNames, innerProps, isDisabled } = props;
  const theme = useTheme();

  return (
    <div
      css={[
        css`
          display: flex;
          color: ${theme.palette.text.primary};

          .${selectClasses.loadingIndicator} + & {
            margin-left: 4px;
          }
        `,
        isDisabled &&
          css`
            color: ${theme.palette.text.disabled};
          `,
      ]}
      {...innerProps}
      className={clsx(
        selectClasses.dropdownIndicator,
        getClassNames('dropdownIndicator', props),
        className,
        innerProps?.className,
      )}
    >
      {children || <IcArrowDropDown />}
    </div>
  );
};
