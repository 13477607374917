import { forwardRef } from 'react';
import { GroupBase } from 'react-select';
import ReactCreatable from 'react-select/creatable';
import { SelectRef } from '../select';
import { useSelect } from '../select/use-select';
import { CreatableProps } from './creatable-type-map';

interface CreatableType {
  <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: React.PropsWithoutRef<CreatableProps<Option, IsMulti, Group>> &
      React.RefAttributes<SelectRef<Option, IsMulti, Group>>,
  ): React.ReactNode;

  readonly $$typeof: symbol;
  displayName?: string;
}

// @ts-expect-error overridable component
export const Creatable: CreatableType = forwardRef(
  <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: CreatableProps<Option, IsMulti, Group>,
    ref: React.ForwardedRef<SelectRef<Option, IsMulti, Group>>,
  ) => {
    const selectProps = useSelect(props);

    return (
      <ReactCreatable<Option, IsMulti, Group> {...selectProps} ref={ref} />
    );
  },
);
