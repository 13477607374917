import { CamelCase } from '@allganize/types';
import {
  getTooltipUtilityClass,
  tooltipClasses as muiTooltipClasses,
  TooltipClassKey as MuiTooltipClassKey,
} from '@mui/material/Tooltip';
import { TooltipColor } from './tooltip-type-map';

export type TooltipClassKey =
  | MuiTooltipClassKey
  | CamelCase<`color_${TooltipColor}`>;
export type TooltipClasses = Record<TooltipClassKey, string>;

export const tooltipClasses: TooltipClasses = {
  ...muiTooltipClasses,
  colorDefault: getTooltipUtilityClass('colorDefault'),
  colorError: getTooltipUtilityClass('colorError'),
};
