import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { IcArrowHideSide, IcArrowOpenSide, IcClose } from '@allganize/ui-icons';
import { FunctionComponent, useContext } from 'react';
import { useIntl } from 'react-intl';
import { PreviewContext } from '../preview/preview-context';

export const PreviewCloseButton: FunctionComponent<IconButtonProps> = props => {
  const intl = useIntl();
  const { previewSidebarAnchor, shouldOpenPreviewInDialog } =
    useContext(PreviewContext);

  return (
    <IconButton
      aria-label={intl.formatMessage({
        id: 'preview-close-button.aria-label',
        defaultMessage: 'Close preview',
        description: 'close preview button aria-label',
      })}
      {...props}
    >
      {shouldOpenPreviewInDialog && <IcClose />}
      {!shouldOpenPreviewInDialog &&
        (previewSidebarAnchor === 'left' ? (
          <IcArrowHideSide />
        ) : (
          <IcArrowOpenSide />
        ))}
    </IconButton>
  );
};
