import { IcCalendar } from '@allganize/ui-icons';
import { resolveComponentProps } from '@mui/base/utils';
import { forwardRef } from 'react';
import { useDesktopPicker } from '../internals/hooks/use-desktop-picker';
import { PickerViewRendererLookup } from '../internals/hooks/use-picker';
import { useLocaleText, useUtils } from '../internals/hooks/use-utils';
import { TimeViewWithMeridiem } from '../internals/models';
import { resolveTimeViewsResponse } from '../internals/utils/date-time-utils';
import { resolveTimeFormat } from '../internals/utils/time-utils';
import { extractValidationProps } from '../internals/utils/validation/extract-validation-props';
import { validateTime } from '../internals/utils/validation/validate-time';
import { singleItemValueManager } from '../internals/utils/value-managers';
import { TimeView } from '../models';
import { PickersActionBarAction } from '../pickers-action-bar';
import { TimeField } from '../time-field';
import { useTimePickerDefaultizedProps } from '../time-picker/time-picker.shared';
import {
  renderDigitalClockTimeView,
  renderMultiSectionDigitalClockTimeView,
} from '../time-view-renderers';
import { DesktopTimePickerProps } from './desktop-time-picker-type-map';

export const DesktopTimePicker = forwardRef<
  HTMLDivElement,
  DesktopTimePickerProps
>((inProps, ref) => {
  const localeText = useLocaleText<Date>();
  const utils = useUtils<Date>();

  // Props with the default values common to all time pickers
  const defaultizedProps = useTimePickerDefaultizedProps<
    TimeViewWithMeridiem,
    DesktopTimePickerProps
  >(inProps, 'MuiDesktopTimePicker');

  const {
    shouldRenderTimeInASingleColumn,
    views: resolvedViews,
    timeSteps,
  } = resolveTimeViewsResponse<Date, TimeView, TimeViewWithMeridiem>(
    defaultizedProps,
  );

  const renderTimeView = shouldRenderTimeInASingleColumn
    ? renderDigitalClockTimeView
    : renderMultiSectionDigitalClockTimeView;

  const viewRenderers: PickerViewRendererLookup<
    Date | null,
    TimeViewWithMeridiem,
    any,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  > = {
    hours: renderTimeView,
    minutes: renderTimeView,
    seconds: renderTimeView,
    meridiem: renderTimeView,
    ...defaultizedProps.viewRenderers,
  };

  const ampmInClock = defaultizedProps.ampmInClock ?? true;
  const actionBarActions: PickersActionBarAction[] =
    shouldRenderTimeInASingleColumn ? [] : ['accept'];
  // Need to avoid adding the `meridiem` view when unexpected renderer is specified
  const shouldHoursRendererContainMeridiemView =
    viewRenderers.hours?.name === renderMultiSectionDigitalClockTimeView.name;
  const views = !shouldHoursRendererContainMeridiemView
    ? resolvedViews.filter(view => view !== 'meridiem')
    : resolvedViews;

  // Props with the default values specific to the desktop variant
  const props = {
    ...defaultizedProps,
    ampmInClock,
    timeSteps,
    viewRenderers,
    format: resolveTimeFormat(utils, defaultizedProps),
    // Setting only `hours` time view in case of single column time picker
    // Allows for easy view lifecycle management
    views: shouldRenderTimeInASingleColumn
      ? ['hours' as TimeViewWithMeridiem]
      : views,
    slots: {
      field: TimeField,
      openPickerIcon: IcCalendar,
      ...defaultizedProps.slots,
    },
    slotProps: {
      ...defaultizedProps.slotProps,
      field: (ownerState: any) => ({
        ...resolveComponentProps(defaultizedProps.slotProps?.field, ownerState),
        ...extractValidationProps(defaultizedProps),
        ref,
      }),
      toolbar: {
        hidden: true,
        ampmInClock,
        ...defaultizedProps.slotProps?.toolbar,
      },
      actionBar: {
        actions: actionBarActions,
        ...defaultizedProps.slotProps?.actionBar,
      },
    },
  };

  // @ts-expect-error internal prop
  const { renderPicker } = useDesktopPicker<TimeViewWithMeridiem, typeof props>(
    {
      props,
      valueManager: singleItemValueManager,
      valueType: 'time',
      getOpenDialogAriaText: localeText.openTimePickerDialogue,
      validator: validateTime,
    },
  );

  return renderPicker();
});
