import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type ChatFormClassKey = 'root' | 'header' | 'body';

export type ChatFormClasses = Record<ChatFormClassKey, string>;

export const chatFormClasses: ChatFormClasses = generateUtilityClasses(
  'ChatForm',
  ['root', 'header', 'body'],
);
