import { getWindow } from './get-window';
import { isDocumentElement } from './is-document-element';

export const scrollTo = (
  el: HTMLElement | typeof window,
  top: number,
): void => {
  // with a scroll distance, we perform scroll on the element
  if (isDocumentElement(el)) {
    getWindow(el).scrollTo(0, top);
    return;
  }

  el.scrollTop = top;
};
