import './styles/class-name';
import './polyfills';
import {
  ProjectQuery,
  ProjectQueryDocument,
} from '@allganize/alli-app-market-project';
import { AlliDeviceIdManager } from '@allganize/alli-sdk/core/alli-auth-client/alli-device-id-manager';
import { CookieStorage } from '@allganize/utils-storage';
import { ApolloProvider } from '@apollo/client/react';
import { OktaAuth } from '@okta/okta-auth-js';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import './sentry';

import { analytics } from './analytics';
import { ApiKeyManager } from './auth/api-key-manager';
import { AuthClient } from './auth/auth-client';
import { AuthClientContext } from './auth/auth-client-context';
import { AuthTokenManager } from './auth/auth-token-manager';
import { GraphQLClient } from './graphql/graphql-client';
import { LocaleProvider } from './i18n/locale-provider';
import { setManifest } from './manifest';
import { router } from './router';
import { EntraIdAuthClient } from './services/entra-id/entra-id-auth-client';
import { ThemeProvider } from './styles/theme-provider';
import { getBrowserLocale } from './utils/get-browser-locale';
import { getCookieOptions } from './utils/get-cookie-options';
import { getRegion } from './utils/get-region';

const main = async () => {
  const apiKeyManager = new ApiKeyManager();
  const storage = new CookieStorage(undefined, getCookieOptions());
  const authTokenManager = new AuthTokenManager(storage);
  const deviceIdManager = new AlliDeviceIdManager(storage);
  const locale = getBrowserLocale(window.navigator);
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );
  let themeColor = '';

  authTokenManager.load();

  window.addEventListener('focus', () => {
    authTokenManager.load();
  });

  const authClient = new AuthClient({
    apiKeyManager,
    authTokenManager,
    deviceIdManager,
  });
  const graphqlClient = new GraphQLClient(authClient);

  try {
    const result = await graphqlClient.client.query<ProjectQuery>({
      query: ProjectQueryDocument,
    });

    if (result.data.project) {
      analytics.register(
        { projectId: result.data.project.id },
        { mixpanel: { persistent: false } },
      );
    } else {
      analytics.unregister('projectId', { mixpanel: { persistent: false } });
    }

    if (result.data.project?.apiKey) {
      apiKeyManager.setApiKey(result.data.project.apiKey);
    }

    if (result.data.project?.appCollectionSettings?.themeColor) {
      themeColor = result.data.project.appCollectionSettings.themeColor;
    }

    if (result.data.project?.userEntraIdInfo) {
      const entraIdAuthClient = new EntraIdAuthClient({
        loginUrl: result.data.project.userEntraIdInfo.loginUrl,
        samlRequest: result.data.project.userEntraIdInfo.samlRequest,
      });

      authClient.setEntraIdAuth(entraIdAuthClient);
    }

    if (result.data.project?.oktaOidcInfo) {
      const searchParams = new URLSearchParams(window.location.search);
      const projectToken = searchParams.get('project_token');
      const region = getRegion(searchParams);
      const newSearchParams = new URLSearchParams();

      if (projectToken) {
        newSearchParams.set('project_token', projectToken);
      }

      if (region) {
        newSearchParams.set('region', region);
      }

      const search = newSearchParams.toString();

      authClient.setOktaAuth(
        new OktaAuth({
          clientId: result.data.project.oktaOidcInfo.clientId,
          issuer: result.data.project.oktaOidcInfo.issuerUrl,
          redirectUri: `${window.location.origin}/auth/okta/callback${
            search ? `?${search}` : ''
          }`,
          postLogoutRedirectUri: `${window.location.origin}${
            search ? `?${search}` : ''
          }`,
          pkce: true,
        }),
      );
    }
  } catch {
    // do nothing
  }

  const reset = () => {
    graphqlClient.subscriptionClient.restart();
    graphqlClient.client.resetStore();
  };

  apiKeyManager.on('change', reset);
  authTokenManager.on('change', reset);

  setManifest({
    theme_color: themeColor || '#7420FF',
  });

  root.render(
    <StrictMode>
      <HelmetProvider>
        <AuthClientContext.Provider value={authClient}>
          <ApolloProvider client={graphqlClient.client}>
            <LocaleProvider initialValue={locale}>
              <ThemeProvider themeColor={themeColor}>
                <RouterProvider router={router} />
              </ThemeProvider>
            </LocaleProvider>
          </ApolloProvider>
        </AuthClientContext.Provider>
      </HelmetProvider>
    </StrictMode>,
  );
};

main();
