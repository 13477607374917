import { IconButton } from '@allganize/ui-button';
import { IcClose } from '@allganize/ui-icons';
import clsx from 'clsx';
import { ClearIndicatorProps, GroupBase } from 'react-select';
import { selectClasses } from './select-classes';

export const ClearIndicator = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: ClearIndicatorProps<Option, IsMulti, Group>,
) => {
  const { children, className, getClassNames, innerProps } = props;

  return (
    <IconButton
      component="div"
      {...innerProps}
      size="small"
      ref={null}
      color="default"
      className={clsx(
        selectClasses.clearIndicator,
        getClassNames('clearIndicator', props),
        className,
        innerProps?.className,
      )}
    >
      {children || <IcClose />}
    </IconButton>
  );
};
