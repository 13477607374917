import {
  KnowledgeBaseSearchDialog,
  KnowledgeBaseSearchDialogProps,
} from '@allganize/knowledge-base-assistant';
import { useMediaQuery } from '@allganize/hooks';
import { useTheme } from '@emotion/react';
import { emotionCacheOptions } from '../styles/emotion-cache';
import { EmotionCacheProvider } from '../styles/emotion-cache-provider';

export const ChatFormKnowledgeBaseSearchDialog = (
  props: KnowledgeBaseSearchDialogProps,
) => {
  const theme = useTheme();
  const dialogFullScreen = useMediaQuery(
    `(max-width: ${theme.breakpoints.values.sm}px)`,
  );
  return (
    <EmotionCacheProvider options={emotionCacheOptions}>
      <KnowledgeBaseSearchDialog
        {...props}
        container={() => document.getElementById(theme.modalContainer.id)}
        fullScreen={dialogFullScreen}
      />
    </EmotionCacheProvider>
  );
};
