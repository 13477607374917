import {
  ContentBlock,
  ContentState,
  DraftDecorator,
  DraftHandleValue,
  DraftPlugin,
  DraftPluginFunctions,
  EditorState,
  Modifier,
  SelectionState,
} from '@allganize/draft-input';
import { linkifyBlock } from '../utils/linkify-block';
import { DraftLink, DraftLinkProps } from './draft-link';
import { DraftLinkClasses } from './draft-link-classes';

export interface DraftLinkPluginOptions {
  classes?: Partial<DraftLinkClasses>;
}

export class DraftLinkPlugin extends DraftPlugin {
  public static readonly ENTITY_TYPE = 'LINK';

  public static strategy(
    block: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
  ) {
    block.findEntityRanges(character => {
      const entityKey = character.getEntity();

      if (!entityKey) {
        return false;
      }

      const entity = contentState.getEntity(entityKey);

      if (!entity) {
        return false;
      }

      return entity.getType() === DraftLinkPlugin.ENTITY_TYPE;
    }, callback);
  }

  constructor(private options: DraftLinkPluginOptions = {}) {
    super();
  }

  public getDecorators(): DraftDecorator<DraftLinkProps>[] | null {
    return [
      {
        strategy: DraftLinkPlugin.strategy,
        component: DraftLink,
        props: {
          classes: this.options.classes,
        },
      },
    ];
  }

  public keyBindingFn(e: React.KeyboardEvent): string | null {
    if (e.code === 'Space') {
      return 'space';
    }

    return null;
  }

  public handleKeyCommand(
    command: string,
    editorState: EditorState,
    eventTimeStamp: number,
    { setEditorState }: DraftPluginFunctions,
  ): DraftHandleValue {
    if (command === 'space') {
      const prevContentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const blockKey = selection.getStartKey();
      const offset = selection.getStartOffset();

      const inlineStyle = prevContentState
        .getBlockForKey(blockKey)
        .getInlineStyleAt(Math.max(0, offset - 1));

      const contentState = Modifier.replaceText(
        prevContentState,
        selection,
        ' ',
        inlineStyle,
      );

      const newEs = EditorState.setInlineStyleOverride(
        EditorState.push(editorState, contentState, 'insert-characters'),
        inlineStyle,
      );

      const block = contentState.getBlockForKey(blockKey);

      if (block) {
        const match = linkifyBlock(block, offset);

        if (match === null) {
          setEditorState(newEs);
        } else {
          const newSelection = selection
            .set('anchorKey', blockKey)
            .set('focusKey', blockKey)
            .set('anchorOffset', offset + 1)
            .set('focusOffset', offset + 1) as SelectionState;

          const newContentState = match.links.reduce((cs, link) => {
            const linkSelection: SelectionState = SelectionState.createEmpty(
              blockKey,
            )
              .set('anchorKey', blockKey)
              .set('focusKey', blockKey)
              .set('anchorOffset', match.range.start + link.index)
              .set(
                'focusOffset',
                match.range.start + link.lastIndex,
              ) as SelectionState;

            const newCS = cs.createEntity(
              DraftLinkPlugin.ENTITY_TYPE,
              'MUTABLE',
              {
                url: link.url,
              },
            );
            const entityKey = newCS.getLastCreatedEntityKey();
            return link.styles.reduce(
              (ccs, style) =>
                Modifier.applyInlineStyle(ccs, linkSelection, style),
              Modifier.applyEntity(newCS, linkSelection, entityKey),
            );
          }, contentState);

          setEditorState(
            EditorState.forceSelection(
              EditorState.push(newEs, newContentState, 'apply-entity'),
              newSelection,
            ),
          );
        }

        return 'handled';
      }
    }

    return 'not-handled';
  }

  public handleReturn(
    e: React.KeyboardEvent,
    editorState: EditorState,
    { setEditorState }: DraftPluginFunctions,
  ): DraftHandleValue {
    const selection = editorState.getSelection();
    const currentCS = editorState.getCurrentContent();
    const blockKey = selection.getStartKey();
    const block = currentCS.getBlockForKey(blockKey);
    const offset = selection.getStartOffset();

    if (block) {
      const match = linkifyBlock(block, offset);

      if (match !== null) {
        const collapsedSelection = selection
          .set('anchorKey', blockKey)
          .set('focusKey', blockKey)
          .set('anchorOffset', offset)
          .set('focusOffset', offset) as SelectionState;

        const contentState = Modifier.splitBlock(
          Modifier.removeRange(currentCS, selection, 'backward'),
          collapsedSelection,
        );
        const newBlockKey = contentState.getKeyAfter(blockKey);
        const newEs = EditorState.push(
          editorState,
          contentState,
          'split-block',
        );

        const newContentState = match.links.reduce((cs, link) => {
          const linkSelection: SelectionState = SelectionState.createEmpty(
            blockKey,
          )
            .set('anchorKey', blockKey)
            .set('focusKey', blockKey)
            .set('anchorOffset', match.range.start + link.index)
            .set(
              'focusOffset',
              match.range.start + link.lastIndex,
            ) as SelectionState;

          const newCS = cs.createEntity(
            DraftLinkPlugin.ENTITY_TYPE,
            'MUTABLE',
            { url: link.url },
          );
          const entityKey = newCS.getLastCreatedEntityKey();
          return link.styles.reduce(
            (ccs, style) =>
              Modifier.applyInlineStyle(ccs, linkSelection, style),
            Modifier.applyEntity(newCS, linkSelection, entityKey),
          );
        }, contentState);

        setEditorState(
          EditorState.forceSelection(
            EditorState.push(newEs, newContentState, 'apply-entity'),
            selection
              .set('anchorKey', newBlockKey)
              .set('focusKey', newBlockKey)
              .set('anchorOffset', 0)
              .set('focusOffset', 0) as SelectionState,
          ),
        );

        return 'handled';
      }
    }

    return 'not-handled';
  }
}
