import { Button, buttonClasses } from '@allganize/ui-button';
import { IcAlliSymbolMark } from '@allganize/ui-icons';
import { useTheme as useBaseTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';

export const alliBrandingHeight = 36;

export interface AlliBrandingProps {
  className?: string;
  onClick?: () => void;
}

export const AlliBranding: FunctionComponent<AlliBrandingProps> = ({
  className,
  onClick,
}) => {
  const theme = useBaseTheme();

  return (
    <div
      className={className}
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: ${alliBrandingHeight}px;
      `}
    >
      <Button
        css={css`
          color: ${theme.palette.foreground.placeholder};
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 0.6px;
          font-feature-settings: 'clig' off, 'liga' off;
          text-transform: uppercase;

          &:hover,
          &.${buttonClasses.focusVisible} {
            color: ${theme.palette.text.primary};
            background-color: transparent;

            .${buttonClasses.startIcon} {
              color: ${theme.palette.primary.main};
            }
          }
        `}
        data-testid="alli-branding"
        href="https://allganize.ai"
        target="_blank"
        rel="noopener noreferrer"
        startIcon={<IcAlliSymbolMark fontSize="small" />}
        color="default"
        size="small"
        onClick={onClick}
      >
        Powered by Alli
      </Button>
    </div>
  );
};
