import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type KnowledgeBaseUploadDialogClassKey =
  | 'root'
  | 'title'
  | 'content'
  | 'dropzone'
  | 'uploadList'
  | 'uploadErrorMessage'
  | 'actions';

export type KnowledgeBaseUploadDialogClasses = Record<
  KnowledgeBaseUploadDialogClassKey,
  string
>;

export const knowledgeBaseUploadDialogClasses: KnowledgeBaseUploadDialogClasses =
  generateUtilityClasses('KnowledgeBaseUploadDialog', [
    'root',
    'title',
    'content',
    'dropzone',
    'uploadList',
    'uploadErrorMessage',
    'actions',
  ]);
