import { OverridableComponent } from '@allganize/types';
import MuiTableHead from '@mui/material/TableHead';
import { forwardRef } from 'react';
import { TableHeadTypeMap } from './table-head-type-map';

// @ts-expect-error overridable component
export const TableHead: OverridableComponent<TableHeadTypeMap> = forwardRef(
  (props, ref) => {
    return <MuiTableHead data-testid="table-head" {...props} ref={ref} />;
  },
);
