import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { mapValues } from 'lodash-es';
import { lighten } from 'polished';
import { forwardRef } from 'react';
import { SwitchBase } from '../switch-base';
import { switchClasses } from './switch-classes';
import { SwitchProps } from './switch-type-map';

export const Switch = forwardRef<HTMLSpanElement, SwitchProps>((props, ref) => {
  const {
    classes: classesProp,
    className,
    color = 'primary',
    edge = false,
    ...other
  } = props;
  const theme = useTheme();
  const classes = mapValues(switchClasses, (value, key) => {
    return clsx(value, classesProp?.[key as keyof typeof switchClasses]);
  });
  const icon = (
    <span
      data-testid="switch-thumb"
      css={css`
        background-color: currentColor;
        width: 14px;
        height: 14px;
        border-radius: ${theme.radius.round}px;
      `}
      className={classes.thumb}
    />
  );

  return (
    <span
      data-testid="switch"
      css={[
        css`
          display: inline-flex;
          width: 52px;
          height: 28px;
          overflow: hidden;
          padding: 4px 8px;
          box-sizing: border-box;
          position: relative;
          flex-shrink: 0;
          z-index: 0;
          vertical-align: middle;

          @media print {
            color-adjust: exact;
          }
        `,
        edge === 'start' &&
          css`
            margin-left: -8px;
          `,
        edge === 'end' &&
          css`
            margin-right: -8px;
          `,
      ]}
      className={clsx(classes.root, className)}
    >
      <SwitchBase
        data-testid="switch-switch-base"
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          padding: 7px 11px;
          color: ${theme.palette.common.white};
          transition: ${theme.transitions.create(['left', 'transform'], {
            duration: theme.transitions.duration.shortest,
          })};

          &.${switchClasses.checked} {
            transform: translateX(16px);
          }

          &.${switchClasses.checked} + .${switchClasses.track} {
            background-color: ${theme.palette[color].main};
          }

          &.${switchClasses.disabled} + .${switchClasses.track} {
            background-color: ${theme.palette.grayAlpha[100]};
          }

          &.${switchClasses.checked}.${switchClasses.disabled}
            + .${switchClasses.track} {
            background-color: ${lighten(0.3, theme.palette[color].main)};
          }

          & .${switchClasses.input} {
            left: -100%;
            width: 300%;
          }

          &:hover
            + .${switchClasses.track},
            &.${switchClasses.focusVisible}
            + .${switchClasses.track} {
            box-shadow: 0 0 0 4px ${theme.palette.grayAlpha[100]};
          }

          &:hover.${switchClasses.checked}
            + .${switchClasses.track},
            &.${switchClasses.focusVisible}.${switchClasses.checked}
            + .${switchClasses.track} {
            box-shadow: 0 0 0 4px ${lighten(0.4, theme.palette[color].main)};
          }

          &:active + .${switchClasses.track} {
            box-shadow: 0 0 0 4px ${theme.palette.grayAlpha[200]};
          }

          &:active.${switchClasses.checked} + .${switchClasses.track} {
            box-shadow: 0 0 0 4px ${lighten(0.3, theme.palette[color].main)};
          }
        `}
        type="checkbox"
        icon={icon}
        checkedIcon={icon}
        {...other}
        classes={{
          ...classes,
          root: clsx(classes.switchBase),
        }}
        ref={ref}
        focusVisibleClassName={clsx(
          classes.focusVisible,
          other.focusVisibleClassName,
        )}
      />

      <span
        data-testid="switch-track"
        css={css`
          height: 100%;
          width: 100%;
          border-radius: ${theme.radius.round}px;
          z-index: -1;
          transition: ${theme.transitions.create(
            ['background-color', 'box-shadow'],
            { duration: theme.transitions.duration.shortest },
          )};
          background-color: ${theme.palette.grayAlpha[300]};
        `}
        className={classes.track}
      />
    </span>
  );
});
