import { CircularProgress } from '@allganize/ui-circular-progress';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { GroupBase, LoadingIndicatorProps } from 'react-select';
import { selectClasses } from './select-classes';

export const LoadingIndicator = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: LoadingIndicatorProps<Option, IsMulti, Group>,
) => {
  const { className, getClassNames, innerProps } = props;

  return (
    <div
      css={css`
        line-height: 0;
      `}
      {...innerProps}
      className={clsx(
        selectClasses.loadingIndicator,
        getClassNames('loadingIndicator', props),
        className,
        innerProps?.className,
      )}
    >
      <CircularProgress size="sm" />
    </div>
  );
};
