import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { IcUpload, IcWarning } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { uniq } from 'lodash-es';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { Fragment } from 'react';

import { acceptFiles } from './accept-files';
import { knowledgeBaseFileDropzoneClasses } from './knowledge-base-file-dropzone-classes';
import { KnowledgeBaseFileDropzoneProps } from './knowledge-base-file-dropzone-type-map';
import { MAX_KB_FILE_SIZE, maxFileSize } from '../utils/file-size';
import { resetUlStyles } from '../styles/reset';

export const KnowledgeBaseFileDropzone = ({
  classes,
  errorMessage,
  onDropFile,
  ...others
}: KnowledgeBaseFileDropzoneProps) => {
  const theme = useTheme();

  return (
    <div
      {...others}
      className={clsx(
        knowledgeBaseFileDropzoneClasses.root,
        classes?.root,
        others.className,
      )}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 8px;
      `}
    >
      <Text
        variant="title12"
        className={clsx(knowledgeBaseFileDropzoneClasses.title, classes?.title)}
      >
        <FormattedMessage
          id="knowledge-base.file-dropzone.upload-file"
          defaultMessage="Upload file"
          description="knowledge base upload file dialog dropzone label"
        />
      </Text>

      <Dropzone
        multiple
        accept={acceptFiles}
        maxSize={MAX_KB_FILE_SIZE}
        disabled={!!errorMessage}
        onDrop={onDropFile}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className={clsx(
              knowledgeBaseFileDropzoneClasses.dropzone,
              classes?.dropzone,
            )}
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 8px;
              height: 148px;
              border-radius: ${theme.radius.sm}px;
              padding: 10px;
              background-color: ${theme.palette.unstable_background.default};
              text-align: center;
              white-space: break-spaces;
            `}
            {...getRootProps()}
          >
            {errorMessage ? (
              <Fragment>
                <IcWarning
                  fontSize="large"
                  css={css`
                    color: ${theme.palette.foreground.error};
                  `}
                />
                <Text variant="body14">{errorMessage}</Text>
              </Fragment>
            ) : (
              <Fragment>
                <IcUpload
                  fontSize="large"
                  css={css`
                    color: ${theme.palette.foreground.secondary};
                  `}
                />
                <Text variant="body14">
                  <FormattedMessage
                    id="knowledge-base.file-dropzone.description"
                    defaultMessage="Drag and drop files here or <highlight>Browse files</highlight>"
                    description="knowledge base file dropzone description text"
                    values={{
                      highlight: (chunks: ReadonlyArray<React.ReactNode>) => (
                        <span
                          css={css`
                            color: ${theme.palette.foregroundInteractive
                              .primary};
                            font-weight: 500;
                            cursor: pointer;
                          `}
                        >
                          {chunks}
                        </span>
                      ),
                    }}
                  />
                </Text>
              </Fragment>
            )}
            <input
              {...getInputProps({
                name: 'files',
              })}
            />
          </div>
        )}
      </Dropzone>
      <Text
        component="ul"
        variant="body12"
        className={clsx(
          knowledgeBaseFileDropzoneClasses.description,
          classes?.description,
        )}
        css={[
          resetUlStyles,
          css`
            li {
              position: relative;
              padding-inline-start: 12px;
              color: ${theme.palette.foreground.secondary};

              &::before {
                content: '・';
                position: absolute;
                left: 0;
              }
            }
          `,
        ]}
      >
        <li>
          <FormattedMessage
            id="knowledge-base.file-dropzone.max-file-size"
            defaultMessage="Max file size: {maxSize}"
            description="knowledge base file dropzone max file size help text"
            values={{
              maxSize: maxFileSize,
            }}
          />
        </li>
        <li>
          <FormattedMessage
            id="knowledge-base.file-dropzone.supported-file-type"
            defaultMessage="Supported file type: {acceptFiles}"
            description="knowledge base file dropzone supported file type help text"
            values={{
              acceptFiles: uniq(Object.values(acceptFiles).flat()).join(', '),
            }}
          />
        </li>
      </Text>
    </div>
  );
};
