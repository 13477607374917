import generateUtilityClasses from '@mui/material/generateUtilityClasses';
import { DraftInputBaseClassKey } from '../draft-input-base';

export type DraftInputClassKey = DraftInputBaseClassKey | 'focused' | 'error';
export type DraftInputClasses = Record<DraftInputClassKey, string>;

export const draftInputClasses: DraftInputClasses = generateUtilityClasses(
  'DraftInput',
  [
    'root',
    'block',
    'blockHeaderOne',
    'blockHeaderTwo',
    'blockHeaderThree',
    'blockHeaderFour',
    'blockHeaderFive',
    'blockHeaderSix',
    'blockSection',
    'blockArticle',
    'blockUnorderedListItem',
    'blockOrderedListItem',
    'blockBlockquote',
    'blockAtomic',
    'blockCodeBlock',
    'blockUnstyled',
    'focused',
    'error',
    'disabled',
  ],
);
