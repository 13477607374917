import { OverridableComponent } from '@allganize/types';
import { Paper } from '@allganize/ui-paper';
import { useTheme } from '@allganize/ui-theme';
import { Collapse } from '@allganize/ui-transition';
import { css } from '@emotion/react';
import { useControlled } from '@mui/material/utils';
import useSlot from '@mui/material/utils/useSlot';
import clsx from 'clsx';
import {
  Children,
  forwardRef,
  isValidElement,
  useCallback,
  useMemo,
} from 'react';
import invariant from 'tiny-invariant';
import { accordionClasses } from './accordion-classes';
import { AccordionContext, AccordionContextValue } from './accordion-context';
import { AccordionTypeMap } from './accordion-type-map';

// @ts-expect-error overridable component
export const Accordion: OverridableComponent<AccordionTypeMap> = forwardRef(
  (props, ref) => {
    const {
      children: childrenProp,
      classes,
      className,
      defaultExpanded = false,
      disabled = false,
      expanded: expandedProp,
      onChange,
      slots = {},
      slotProps = {},
      ...other
    } = props;
    const theme = useTheme();
    const transition = {
      duration: theme.transitions.duration.shortest,
    };

    const [expanded, setExpandedState] = useControlled({
      controlled: expandedProp,
      default: defaultExpanded,
      name: 'Accordion',
      state: 'expanded',
    });

    const handleChange = useCallback(
      (event: React.SyntheticEvent) => {
        setExpandedState(!expanded);

        if (onChange) {
          onChange(event, !expanded);
        }
      },
      [expanded, onChange, setExpandedState],
    );

    const [summary, ...children] = Children.toArray(childrenProp);

    invariant(
      isValidElement(summary),
      'The first child of an Accordion must be a valid element.',
    );

    const contextValue = useMemo<AccordionContextValue>(
      () => ({
        expanded,
        disabled,
        toggle: handleChange,
      }),
      [expanded, disabled, handleChange],
    );

    const ownerState = {
      ...props,
      disabled,
      expanded,
    };

    const [TransitionSlot, transitionProps] = useSlot('transition', {
      elementType: Collapse,
      externalForwardedProps: {
        slots,
        // @ts-expect-error internal prop
        slotProps,
      },
      className: undefined,
      ownerState,
    });

    return (
      <Paper
        data-testid="accordion"
        css={css`
          position: relative;
          transition: ${theme.transitions.create(['margin'], transition)};
          /* Keep the same scrolling position */
          overflow-anchor: none;

          &::before {
            position: absolute;
            left: 0;
            top: -1px;
            right: 0;
            height: 1px;
            content: '';
            opacity: 1;
            background-color: ${theme.palette.divider};
            transition: ${theme.transitions.create(
              ['opacity', 'background-color'],
              transition,
            )};
          }

          &:first-of-type {
            &::before {
              display: none;
            }
          }

          &.${accordionClasses.expanded} {
            &::before {
              opacity: 0;
            }

            &:first-of-type {
              margin-top: 0;
            }

            &:last-of-type {
              margin-bottom: 0;
            }

            & + & {
              &::before {
                display: none;
              }
            }
          }

          &.${accordionClasses.disabled} {
            background-color: ${theme.palette.action.disabledBackground};
          }
        `}
        elevation={0}
        square
        {...other}
        ref={ref}
        className={clsx(
          accordionClasses.root,
          {
            [accordionClasses.expanded]: expanded,
            [accordionClasses.disabled]: disabled,
          },
          classes?.root,
          {
            [classes?.expanded ?? '']: expanded,
            [classes?.disabled ?? '']: disabled,
          },
          className,
        )}
      >
        <AccordionContext.Provider value={contextValue}>
          {summary}
        </AccordionContext.Provider>

        {/* @ts-expect-error slot component */}
        <TransitionSlot in={expanded} timeout="auto" {...transitionProps}>
          <div
            aria-labelledby={summary.props.id}
            id={summary.props['aria-controls']}
            role="region"
            className={clsx(accordionClasses.region, classes?.region)}
          >
            {children}
          </div>
        </TransitionSlot>
      </Paper>
    );
  },
);
