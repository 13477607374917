import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type KnowledgeBaseCardClassKey =
  | 'root'
  | 'content'
  | 'startAdornment'
  | 'endAdornment';

export type KnowledgeBaseCardClasses = Record<
  KnowledgeBaseCardClassKey,
  string
>;

export const knowledgeBaseCardClasses: KnowledgeBaseCardClasses =
  generateUtilityClasses('KnowledgeBaseCard', [
    'root',
    'content',
    'startAdornment',
    'endAdornment',
  ]);
