import { useEffect, useState } from 'react';
import { useAlliClient } from '../alli-client/use-alli-client';
import { AlliUserManagerUpdateEventPayload } from '../core/alli-user-manager/alli-user-manager';

export const useUser = () => {
  const client = useAlliClient();
  const [user, _setUser] = useState(client.user);

  useEffect(() => {
    const handleUpdate = ({ next }: AlliUserManagerUpdateEventPayload) => {
      _setUser(next);
    };

    client.userManager.on('new-user', handleUpdate);
    client.userManager.on('update', handleUpdate);

    return () => {
      client.userManager.off('new-user', handleUpdate);
      client.userManager.off('update', handleUpdate);
    };
  }, [client.userManager]);

  return {
    user,
    setUser: client.setUser,
    setUserId: client.setUserId,
    setUserVariable: client.setUserVariable,
    setUserVariables: client.setUserVariables,
  };
};
