import MuiAccordionActions from '@mui/material/AccordionActions';
import { forwardRef } from 'react';
import { AccordionActionsProps } from './accordion-actions-type-map';

export const AccordionActions = forwardRef<
  HTMLDivElement,
  AccordionActionsProps
>((props, ref) => {
  return (
    <MuiAccordionActions data-testid="accordion-actions" {...props} ref={ref} />
  );
});
