import type { AlliNamespace } from '@allganize/alli-sdk';
import { useCallback, useEffect, useState } from 'react';
import { environment } from '../environments/environment';

interface UseAlliSDKModuleOptions {
  version?: string;
}

export const useAlliSDKModule = ({
  version = '2',
}: UseAlliSDKModuleOptions) => {
  const [state, setState] = useState<{
    Alli: AlliNamespace | null;
    loading: boolean;
    error: any | null;
  }>({
    Alli: null,
    loading: true,
    error: null,
  });

  const refetch = useCallback(async () => {
    setState(prev => ({
      ...prev,
      loading: true,
      error: null,
    }));

    try {
      const sdkUrl = environment.sdk.entrypoint(version);
      const mod = await import(
        /* @vite-ignore */
        sdkUrl
      );

      setState(prev => ({
        ...prev,
        Alli: mod,
        loading: false,
        error: null,
      }));
    } catch (err) {
      setState(prev => ({
        ...prev,
        loading: false,
        error: err || null,
      }));
    }
  }, [version]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return {
    ...state,
    refetch,
  };
};
