import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { documentViewerToolbarHeight } from './constants';
import { documentViewerToolbarClasses } from './document-viewer-toolbar-classes';
import { DocumentViewerToolbarProps } from './document-viewer-toolbar-type-map';

export const DocumentViewerToolbar = forwardRef<
  HTMLDivElement,
  DocumentViewerToolbarProps
>((props, ref) => {
  const { classes, hidden, ...other } = props;
  const theme = useTheme();

  return (
    <div
      data-testid="document-viewer-toolbar"
      css={[
        css`
          height: ${documentViewerToolbarHeight}px;
          display: flex;
          align-items: center;
          justify-content: center;
          ${theme.typography.title12}
          padding: 0 8px;
          border-radius: ${theme.radius.round}px;
          min-width: 212px;
          opacity: 1;
          transition: ${theme.transitions.create(['transform', 'opacity'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.enteringScreen,
          })};
        `,
        hidden &&
          css`
            opacity: 0;
            transition: ${theme.transitions.create(['transform', 'opacity'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.leavingScreen,
            })};
          `,
      ]}
      {...other}
      ref={ref}
      className={clsx(
        documentViewerToolbarClasses.root,
        { [documentViewerToolbarClasses.hidden]: hidden },
        classes?.root,
        { [classes?.hidden ?? '']: hidden },
        other.className,
      )}
    />
  );
});
