import { useTheme } from '@allganize/ui-theme';
import { css, keyframes } from '@emotion/react';
import {
  // @ts-expect-error internal component
  InputBaseRoot as MuiInputBaseRoot,
  // @ts-expect-error internal component
  InputBaseComponent as MuiInputBaseComponent,
} from '@mui/material/InputBase/InputBase';
import { forwardRef } from 'react';
import { inputBaseClasses } from './input-base-classes';
import { InputBaseOwnerState, InputBaseProps } from './input-base-type-map';

const autoFill = keyframes`
  from {
    display: block;
  }
`;

export interface InputBaseRootProps
  extends NonNullable<NonNullable<InputBaseProps['slotProps']>['root']> {
  ownerState: InputBaseOwnerState;
}

export const InputBaseRoot = forwardRef<HTMLDivElement, InputBaseRootProps>(
  (props, ref) => {
    const theme = useTheme();

    return (
      <MuiInputBaseRoot
        css={[
          css`
            ${theme.typography.body14}
            color: ${theme.palette.text.primary};

            &.${inputBaseClasses.disabled} {
              color: ${theme.palette.text.disabled};
            }
          `,
          props.ownerState.multiline &&
            css`
              padding: 8px 12px;
            `,
        ]}
        {...props}
        ref={ref}
      />
    );
  },
);

export interface InputBaseComponentProps
  extends NonNullable<NonNullable<InputBaseProps['slotProps']>['input']> {
  ownerState: InputBaseOwnerState;
}

export const InputBaseComponent = forwardRef<
  HTMLInputElement,
  InputBaseComponentProps
>((props, ref) => {
  const theme = useTheme();

  return (
    <MuiInputBaseComponent
      css={[
        css`
          padding: 8px 12px;
          height: auto;

          &::-webkit-input-placeholder,
          &::-moz-placeholder,
          &:-ms-input-placeholder,
          &::-ms-input-placeholder,
          &::placeholder {
            color: ${theme.palette.text.secondary};
          }

          &.${inputBaseClasses.disabled} {
            -webkit-text-fill-color: ${theme.palette.text.disabled};
          }

          &:-webkit-autofill {
            animation-name: ${autoFill};
          }
        `,
        props.ownerState.multiline &&
          css`
            padding: 0;
          `,
      ]}
      {...props}
      ref={ref}
    />
  );
});
