import { OverridableComponent } from '@allganize/types';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import MuiInputAdornment from '@mui/material/InputAdornment';
import { forwardRef } from 'react';
import { InputAdornmentTypeMap } from './input-adornment-type-map';

// @ts-expect-error overridable component
export const InputAdornment: OverridableComponent<InputAdornmentTypeMap> =
  forwardRef((props, ref) => {
    const { children, disableTypography, position, ...other } = props;
    const theme = useTheme();

    return (
      <MuiInputAdornment
        data-testid="input-adornment"
        css={[
          css`
            color: ${theme.palette.action.active};
            column-gap: 4px;
          `,
          position === 'start' &&
            css`
              margin-right: 4px;

              > .notranslate {
                margin-right: -4px;
              }
            `,
          position === 'end' &&
            css`
              margin-left: 4px;

              > .notranslate {
                margin-left: -4px;
              }
            `,
        ]}
        disableTypography
        position={position}
        {...other}
        ref={ref}
      >
        {typeof children === 'string' && !disableTypography ? (
          <Text variant="inherit">{children}</Text>
        ) : (
          children
        )}
      </MuiInputAdornment>
    );
  });
