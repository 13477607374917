import {
  DropzoneRef,
  FileDropzone,
  fileDropzoneClasses,
  FileDropzoneProps,
} from '@allganize/ui-file-input';
import { IcAttachment, IcUpload } from '@allganize/ui-icons';
import styled from '@emotion/styled';
import { MouseEvent, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { useCombinedRef, useEventCallback } from '@allganize/hooks';
import { Tooltip } from '@allganize/ui-tooltip';
import { Control, useController } from 'react-hook-form';
import { ChatFormValues } from '../hooks/use-chat-form';
import { DropEvent } from 'react-dropzone';

export const useChatFormFileDropzone = () => {
  const dropzoneRef = useRef<DropzoneRef | null>(null);
  const openFilePicker = useEventCallback((ev: MouseEvent) => {
    ev.stopPropagation();
    dropzoneRef.current?.open();
  });

  return { dropzoneRef, openFilePicker };
};

const StyledFileDropzone = styled(FileDropzone)`
  border: 0;
  background-color: transparent;
  border-radius: ${({ theme }) => theme.radius.round}px;

  .${fileDropzoneClasses.dragOverlay} {
    border: 1px dashed transparent;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-color: ${({ theme }) => theme.palette.grayAlpha[500]};
    border-radius: ${({ theme }) => theme.radius.round}px;
    color: ${({ theme }) => theme.palette.text.primary};
    transition: ${({ theme }) =>
      theme.transitions.create(['color', 'opacity', 'border-color'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen,
      })};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &.${fileDropzoneClasses.dragActive} .${fileDropzoneClasses.dragOverlay} {
    transition: ${({ theme }) =>
      theme.transitions.create(['color', 'opacity', 'border-color'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      })};
  }

  &.${fileDropzoneClasses.dragReject} .${fileDropzoneClasses.dragOverlay} {
    color: ${({ theme }) => theme.palette.error.main};
    border-color: ${({ theme }) => theme.palette.error.main};
  }
`;

const StyledIcUpload = styled(IcUpload)`
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
`;

interface ChatFormFileDropzoneProps extends FileDropzoneProps {
  control: Control<ChatFormValues>;
  name?: string;
  onClearError?: () => void;
}

export const ChatFormFileDropzone = ({
  control,
  onClearError,
  onDropAccepted,
  inputRef: inputRefProp,
  inputProps,
  dragOverlay,
  ...others
}: ChatFormFileDropzoneProps) => {
  const { field } = useController({ control, name: 'file' });
  const inputRef = useCombinedRef(field.ref, inputRefProp ?? null);
  const handleFileFieldChange = useEventCallback(
    (files: File[], ev: DropEvent) => {
      const file = files[0];

      if (!file) {
        return;
      }

      onClearError?.();
      field.onChange(file);
      onDropAccepted?.(files, ev);
    },
  );

  return (
    <StyledFileDropzone
      {...others}
      inputRef={inputRef}
      inputProps={{
        ...inputProps,
        name: field.name,
        disabled: others.disabled,
      }}
      dragOverlay={
        <div>
          <StyledIcUpload />
          <FormattedMessage
            id="form.file.placeholder"
            defaultMessage="Click or drag & drop{br}a file to upload"
            description="File input placeholder"
            values={{
              br: ' ',
            }}
          />
          {dragOverlay}
        </div>
      }
      onDropAccepted={handleFileFieldChange}
    />
  );
};

export const ChatFormFileUploadButton = ({
  children,
  ...others
}: IconButtonProps) => {
  const intl = useIntl();

  return (
    <Tooltip
      title={intl.formatMessage(
        {
          id: 'form.file.placeholder',
          defaultMessage: 'Click or drag & drop{br}a file to upload',
          description: 'File input placeholder',
        },
        { br: ' ' },
      )}
    >
      <IconButton
        edge="start"
        aria-label={intl.formatMessage({
          id: 'chat-form.file-upload-button.aria-label',
          defaultMessage: 'Attach file',
          description: 'Chat form file upload button aria label',
        })}
        {...others}
      >
        <IcAttachment />
        {children}
      </IconButton>
    </Tooltip>
  );
};
