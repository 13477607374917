import { useEffect, useState } from 'react';
import { useAuthClient } from '../auth/use-auth-client';
import { useCurrentUser } from '../auth/use-current-user';

export const useAuth = () => {
  const authClient = useAuthClient();
  const currentUser = useCurrentUser();
  const [providers, setProviders] = useState(authClient?.getProviders() ?? []);
  const isEnabled = providers.length > 0;
  const isAuthenticated = currentUser.me !== null;

  const signInWithEntraId = () => {
    const url = new URL(window.location.toString());
    url.pathname = '/auth/entra-id/callback';

    authClient?.signInWithEntraId(
      JSON.stringify({ redirection_url: url.toString() }),
    );
  };

  const signInWithOkta = () => {
    const currentUrl = window.location.toString();
    const originalUri = new URL(currentUrl);
    originalUri.pathname = '/auth/okta/sign-in';

    return authClient?.signInWithOkta({
      originalUri: originalUri.toString(),
    });
  };

  const signOut = () => {
    return authClient?.signOut();
  };

  useEffect(() => {
    const handleOktaAuthChanged = () => {
      setProviders(authClient?.getProviders() ?? []);
    };

    authClient?.on('oktaAuthChanged', handleOktaAuthChanged);

    return () => {
      authClient?.off('oktaAuthChanged', handleOktaAuthChanged);
    };
  }, [authClient]);

  return {
    authClient,
    isAuthenticated,
    isEnabled,
    providers,
    signInWithEntraId,
    signInWithOkta,
    signOut,
  };
};
