import { ListItemIconClassKey } from '@allganize/ui-list';
import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type FileListItemIconClassKey = ListItemIconClassKey;
export type FileListItemIconClasses = Record<FileListItemIconClassKey, string>;

export const fileListItemIconClasses: FileListItemIconClasses =
  generateUtilityClasses('FileListItemIcon', [
    'root',
    'alignItemsFlexStart',
    'disabled',
  ]);
