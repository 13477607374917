import { Button } from '@allganize/ui-button';
import {
  Dialog,
  DialogActions,
  DialogCloseButton,
  DialogContent,
  DialogTitle,
} from '@allganize/ui-dialog';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useKnowledgeBaseSearch } from './use-knowledge-base-search';
import { KnowledgeBaseSearchInput } from './knowledge-base-search-input';
import { KnowledgeBaseSelectableList } from './knowledge-base-selectable-list';
import { KnowledgeBaseChipList } from './knowledge-base-chip-list';
import { KnowledgeBaseSearchDialogProps } from './knowledge-base-search-dialog-type-map';
import { knowledgeBaseSearchDialogClasses } from './knowledge-base-search-dialog-classes';
import { KnowledgeBaseHashtagList } from './knowledge-base-hashtag-list';

import type { KnowledgeBase } from '../types/types';

export const KnowledgeBaseSearchDialog: FunctionComponent<
  KnowledgeBaseSearchDialogProps
> = ({
  classes,
  open,
  selectedKnowledgeBases: selectedKnowledgeBasesProp = [],
  onClose,
  onConfirm,
  ...others
}) => {
  const theme = useTheme();
  const {
    query,
    setQuery,
    loadNextPage,
    hashtagFilter,
    setHashtagFilter,
    clearHashtagFilter,
    hashtagList,
    knowledgeBases,
    knowledgeBaseFetchStatus,
  } = useKnowledgeBaseSearch({ open });
  const [selectedKnowledgeBases, setSelectedKnowledgeBases] = useState<
    KnowledgeBase[]
  >(selectedKnowledgeBasesProp);

  const handleAddKnowledgeBase = (knowledgeBase: KnowledgeBase) =>
    setSelectedKnowledgeBases(prev => [...prev, knowledgeBase]);
  const handleDeleteKnowledgeBase = (knowledgeBase: KnowledgeBase) =>
    setSelectedKnowledgeBases(prev =>
      prev.filter(kb => kb.id !== knowledgeBase.id),
    );

  useEffect(() => {
    setSelectedKnowledgeBases(selectedKnowledgeBasesProp);
  }, [selectedKnowledgeBasesProp]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        css: css`
          width: 600px;
        `,
      }}
      {...others}
      className={clsx(
        knowledgeBaseSearchDialogClasses.root,
        classes?.root,
        others.className,
      )}
      onClose={onClose}
    >
      <DialogTitle
        className={clsx(knowledgeBaseSearchDialogClasses.title, classes?.title)}
      >
        <FormattedMessage
          id="knowledge-base.search-dialog.title"
          defaultMessage="Add from Knowledge base"
          description="knowledge base search dialog title"
        />
        <DialogCloseButton />
      </DialogTitle>
      <DialogContent
        className={clsx(
          knowledgeBaseSearchDialogClasses.content,
          classes?.content,
        )}
        css={css`
          display: flex;
          flex-direction: column;
          padding-top: 4px;
        `}
      >
        <KnowledgeBaseSearchInput
          query={query}
          hashtagFilter={hashtagFilter}
          onSearch={setQuery}
          clearHashtagFilter={clearHashtagFilter}
          className={clsx(
            knowledgeBaseSearchDialogClasses.searchInput,
            classes?.searchInput,
          )}
        />
        <KnowledgeBaseChipList
          knowledgeBases={selectedKnowledgeBases}
          onDelete={handleDeleteKnowledgeBase}
          className={clsx(
            knowledgeBaseSearchDialogClasses.chipList,
            classes?.chipList,
          )}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            border-radius: ${theme.radius.sm}px;
            border: 1px solid ${theme.palette.border.divider.default};
          `}
        >
          <KnowledgeBaseHashtagList
            data={hashtagList}
            onClickHashtag={(hashtag: string) => setHashtagFilter(hashtag)}
            className={clsx(
              knowledgeBaseSearchDialogClasses.hashtagList,
              classes?.hashtagList,
            )}
          />
          <KnowledgeBaseSelectableList
            status={knowledgeBaseFetchStatus}
            knowledgeBases={knowledgeBases}
            selectedKnowledgeBases={selectedKnowledgeBases}
            onAdd={handleAddKnowledgeBase}
            onDelete={handleDeleteKnowledgeBase}
            onLoadMore={loadNextPage}
            className={clsx(
              knowledgeBaseSearchDialogClasses.selectableList,
              classes?.selectableList,
            )}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="ghost"
          onClick={e => onClose?.(e, 'closeButtonClick')}
        >
          <FormattedMessage
            id="knowledge-base.search-dialog.cancel"
            defaultMessage="Cancel"
            description="knowledge base search dialog cancel button"
          />
        </Button>
        <Button
          size="large"
          variant="filled"
          color="primary"
          disabled={selectedKnowledgeBases.length === 0}
          onClick={() => onConfirm?.(selectedKnowledgeBases)}
        >
          <FormattedMessage
            id="knowledge-base.search-dialog.add"
            defaultMessage="Add"
            description="knowledge base search dialog add button"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
