import { useTheme } from '@allganize/ui-theme';
import { Fade } from '@allganize/ui-transition';
import { css } from '@emotion/react';
import { useSlotProps } from '@mui/base/utils';
import clsx from 'clsx';
import { forwardRef } from 'react';
import {
  useNextMonthDisabled,
  usePreviousMonthDisabled,
} from '../internals/hooks/date-helpers-hooks';
import { useLocaleText, useUtils } from '../internals/hooks/use-utils';
import { PickersArrowSwitcher } from '../pickers-arrow-switcher';
import { PickersFadeTransitionGroup } from '../pickers-fade-transition-group';
import { pickersCalendarHeaderClasses } from './pickers-calendar-header-classes';
import {
  PickersCalendarHeaderSwitchViewButton,
  PickersCalendarHeaderSwitchViewIcon,
} from './pickers-calendar-header-slots';
import { PickersCalendarHeaderProps } from './pickers-calendar-header-type-map';

export const PickersCalendarHeader = forwardRef<
  HTMLDivElement,
  PickersCalendarHeaderProps
>((props, ref) => {
  const {
    classes,
    slots,
    slotProps,
    currentMonth: month,
    disabled,
    disableFuture,
    disablePast,
    maxDate,
    minDate,
    onMonthChange,
    onViewChange,
    view,
    reduceAnimations,
    views,
    labelId,
    className,
    timezone,
    // @ts-expect-error TODO: remove from DateCalendar
    ownerState: _,
    ...other
  } = props;
  const theme = useTheme();
  const localeText = useLocaleText<Date>();
  const utils = useUtils<Date>();
  const ownerState = props;

  const SwitchViewButton =
    slots?.switchViewButton ?? PickersCalendarHeaderSwitchViewButton;
  const switchViewButtonProps = useSlotProps({
    elementType: SwitchViewButton,
    externalSlotProps: slotProps?.switchViewButton,
    additionalProps: {
      size: 'small',
      'aria-label': localeText.calendarViewSwitchingButtonAriaLabel(view),
    },
    ownerState,
    className: clsx(
      pickersCalendarHeaderClasses.switchViewButton,
      classes?.switchViewButton,
    ),
  });

  const SwitchViewIcon =
    slots?.switchViewIcon ?? PickersCalendarHeaderSwitchViewIcon;
  // The spread is here to avoid this bug mui/material-ui#34056
  const { ownerState: switchViewIconOwnerState, ...switchViewIconProps } =
    useSlotProps({
      elementType: SwitchViewIcon,
      externalSlotProps: slotProps?.switchViewIcon,
      ownerState: undefined,
      className: clsx(
        pickersCalendarHeaderClasses.switchViewIcon,
        classes?.switchViewIcon,
      ),
    });

  const selectNextMonth = () =>
    onMonthChange(utils.addMonths(month, 1), 'left');
  const selectPreviousMonth = () =>
    onMonthChange(utils.addMonths(month, -1), 'right');

  const isNextMonthDisabled = useNextMonthDisabled(month, {
    disableFuture,
    maxDate,
    timezone,
  });
  const isPreviousMonthDisabled = usePreviousMonthDisabled(month, {
    disablePast,
    minDate,
    timezone,
  });

  const handleToggleView = () => {
    if (views.length === 1 || !onViewChange || disabled) {
      return;
    }

    if (views.length === 2) {
      onViewChange(views.find(el => el !== view) || views[0]);
    } else {
      // switching only between first 2
      const nextIndexToOpen = views.indexOf(view) !== 0 ? 0 : 1;
      onViewChange(views[nextIndexToOpen]);
    }
  };

  // No need to display more information
  if (views.length === 1 && views[0] === 'year') {
    return null;
  }

  return (
    <div
      data-testid="pickers-calendar-header"
      css={css`
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 8px;
        padding-left: 24px;
        padding-right: 24px;
        max-height: 32px;
        min-height: 32px;
      `}
      {...other}
      className={clsx(
        pickersCalendarHeaderClasses.root,
        classes?.root,
        className,
      )}
      ref={ref}
    >
      <div
        css={css`
          display: flex;
          overflow: hidden;
          align-items: center;
          cursor: pointer;
          margin-right: auto;
          ${theme.typography.title16}
        `}
        role="presentation"
        onClick={handleToggleView}
        // putting this on the label item element below breaks when using transition
        aria-live="polite"
        className={clsx(
          pickersCalendarHeaderClasses.labelContainer,
          classes?.labelContainer,
        )}
      >
        <PickersFadeTransitionGroup
          reduceAnimations={reduceAnimations}
          transKey={utils.format(month, 'monthAndYear')}
        >
          <div
            css={css`
              margin-right: 4px;
            `}
            id={labelId}
            className={clsx(pickersCalendarHeaderClasses.label, classes?.label)}
          >
            {utils.format(month, 'monthAndYear')}
          </div>
        </PickersFadeTransitionGroup>

        {views.length > 1 && !disabled && (
          <SwitchViewButton {...switchViewButtonProps}>
            <SwitchViewIcon {...switchViewIconProps} />
          </SwitchViewButton>
        )}
      </div>

      <Fade in={view === 'day'}>
        <PickersArrowSwitcher
          slots={slots}
          slotProps={slotProps}
          onGoToPrevious={selectPreviousMonth}
          isPreviousDisabled={isPreviousMonthDisabled}
          previousLabel={localeText.previousMonth}
          onGoToNext={selectNextMonth}
          isNextDisabled={isNextMonthDisabled}
          nextLabel={localeText.nextMonth}
        />
      </Fade>
    </div>
  );
});
