import { DraftFileBase } from '@allganize/draft-file-plugin';
import { DraftLinkBase } from '@allganize/draft-link-plugin';
import { forwardRef } from 'react';
import { ReactMarkdownProps } from 'react-markdown/lib/ast-to-react';

export const Anchor = forwardRef<
  HTMLAnchorElement,
  React.AnchorHTMLAttributes<HTMLAnchorElement> & ReactMarkdownProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, ...other } = props;

  if (other.download) {
    return <DraftFileBase href={other.href}>{other.children}</DraftFileBase>;
  }

  return <DraftLinkBase {...other} ref={ref} />;
});
