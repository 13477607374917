import EventEmitter from 'eventemitter3';

interface AlliPlacementManagerOptions {
  useUrl?: boolean;
}

export interface AlliPlacementManagerUpdateEventPayload {
  prev: string | null;
  next: string | null;
}

interface AlliPlacementManagerEvents {
  update(payload: AlliPlacementManagerUpdateEventPayload): void;
}

export class AlliPlacementManager extends EventEmitter<AlliPlacementManagerEvents> {
  private _placement: string | null = null;

  constructor(private options: AlliPlacementManagerOptions = {}) {
    super();
    this.setPlacement(undefined, true);
  }

  public get placement() {
    return this._placement;
  }

  public setPlacement(_placement?: string | null, silent = false) {
    const prev = this._placement;
    let placement =
      typeof _placement === 'undefined' ? this.placement : _placement;

    if (this.options.useUrl && placement === null) {
      placement = document.URL || 'http://';
    }

    this._placement = placement;

    if (prev !== this._placement && !silent) {
      this.emit('update', { prev, next: this._placement });
    }
  }
}
