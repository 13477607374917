export const raf = (count = 1): Promise<void> => {
  if (count < 1) {
    return Promise.resolve();
  }

  return new Promise<void>(resolve => {
    let rafId: number | null = null;

    rafId = window.requestAnimationFrame(() => {
      if (rafId !== null) {
        window.cancelAnimationFrame(rafId);
        rafId = null;
      }

      if (count < 2) {
        resolve();
      } else {
        resolve(raf(count - 1));
      }
    });
  });
};
