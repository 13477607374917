import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type DraftDocumentListItemClassKey = 'root' | 'loading' | 'selected';
export type DraftDocumentListItemClasses = Record<
  DraftDocumentListItemClassKey,
  string
>;

export const draftDocumentListItemClasses: DraftDocumentListItemClasses =
  generateUtilityClasses('DraftDocumentListItem', [
    'root',
    'loading',
    'selected',
  ]);
