import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type MarkdownClassKey =
  | 'root'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'blockquote'
  | 'code'
  | 'codeInline'
  | 'mark'
  | 'pre'
  | 'paragraph'
  | 'image'
  | 'video';
export type MarkdownClasses = Record<MarkdownClassKey, string>;

export const markdownClasses: MarkdownClasses = generateUtilityClasses(
  'Markdown',
  [
    'root',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'code',
    'codeInline',
    'mark',
    'pre',
    'paragraph',
    'image',
    'video',
  ],
);
