import { noop } from 'lodash-es';
import { getScrollTop } from './get-scroll-top';
import { scrollTo } from './scroll-to';

/**
  @param t: time (elapsed)
  @param b: initial value
  @param c: amount of change
  @param d: duration
*/
const easeOutCubic = (t: number, b: number, c: number, d: number): number => {
  return c * ((t = t / d - 1) * t * t + 1) + b;
};

export const animatedScrollTo = (
  element: HTMLElement | typeof window,
  to: number,
  duration = 200,
  callback: (element: HTMLElement | typeof window) => void = noop,
) => {
  const start = getScrollTop(element);
  const change = to - start;
  const increment = 10;
  let currentTime = 0;

  function animateScroll() {
    currentTime += increment;
    const val = easeOutCubic(currentTime, start, change, duration);
    scrollTo(element, val);
    if (currentTime < duration) {
      window.requestAnimationFrame(animateScroll);
    } else {
      callback(element);
    }
  }

  animateScroll();
};
