import {
  KnowledgeBaseFileIcon,
  KnowledgeBaseFileIconVariant,
} from '@allganize/knowledge-base-icons';
import clsx from 'clsx';
import { fileIconClasses } from './file-icon-classes';
import { FileIconProps } from './file-icon-type-map';

const getIconVariant = (
  type: string,
  fileName: string,
): KnowledgeBaseFileIconVariant => {
  switch (type) {
    case 'CSVKnowledgeBase':
      return 'csv';

    case 'PDFKnowledgeBase':
      return 'pdf';

    case 'TextKnowledgeBase':
      return 'txt';

    case 'MSDocsKnowledgeBase':
    case 'OldMSDocsKnowledgeBase':
      return fileName.endsWith('.docx') ? 'docx' : 'doc';

    case 'HWPKnowledgeBase':
      return fileName.endsWith('.hwpx') ? 'hwpx' : 'hwp';

    case 'MSPPTKnowledgeBase':
      return fileName.endsWith('.pptx') ? 'pptx' : 'ppt';

    case 'MSExcelKnowledgeBase':
      if (fileName.endsWith('.xlsx')) return 'xlsx';
      if (fileName.endsWith('.xlsm')) return 'xlsm';
      return 'xls';

    case 'ImageKnowledgeBase':
      if (fileName.endsWith('.png')) return 'png';
      if (fileName.endsWith('.jpeg')) return 'jpeg';
      return 'jpg';

    default:
      return 'fallback';
  }
};

export const FileIcon = ({
  type,
  fileName,
  classes,
  ...others
}: FileIconProps) => {
  const variant = getIconVariant(type, fileName);
  return (
    <KnowledgeBaseFileIcon
      {...others}
      className={clsx(fileIconClasses.root, classes?.root, others.className)}
      variant={variant}
    />
  );
};
