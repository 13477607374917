import { createSvgIcon } from '@allganize/ui-icons';

export const IcFilePDF = createSvgIcon(
  <>
    <path
      d="M6 22.5469H20.3077C21.1361 22.5469 21.8077 21.8753 21.8077 21.0469V7.69265C21.8077 7.29885 21.6528 6.92085 21.3765 6.64024L16.7099 1.90072C16.428 1.61437 16.0429 1.45312 15.6411 1.45312H6C5.17157 1.45312 4.5 2.1247 4.5 2.95312V21.0469C4.5 21.8753 5.17157 22.5469 6 22.5469Z"
      fill="white"
    />
    <path
      d="M5.0625 2.95312C5.0625 2.43536 5.48223 2.01562 6 2.01562H15.6411C15.6631 2.01562 15.685 2.0164 15.7068 2.01793V5.57812C15.7068 6.71721 16.6302 7.64062 17.7693 7.64062H21.2437C21.2447 7.6579 21.2452 7.67525 21.2452 7.69265V21.0469C21.2452 21.5646 20.8255 21.9844 20.3077 21.9844H6C5.48223 21.9844 5.0625 21.5646 5.0625 21.0469V2.95312ZM20.4644 6.51562L16.8318 2.82621V5.57812C16.8318 6.09589 17.2515 6.51562 17.7693 6.51562H20.4644ZM6 0.890625C4.86091 0.890625 3.9375 1.81404 3.9375 2.95312V21.0469C3.9375 22.186 4.86092 23.1094 6 23.1094H20.3077C21.4468 23.1094 22.3702 22.186 22.3702 21.0469V7.69265C22.3702 7.15117 22.1573 6.63142 21.7774 6.24559L17.1108 1.50607C16.7231 1.11234 16.1936 0.890625 15.6411 0.890625H6Z"
      fill="black"
      fillOpacity="0.2"
      fillRule="evenodd"
    />
    <path
      d="M0.75 12.75C0.75 11.0931 2.09315 9.75 3.75 9.75H12.75C14.4069 9.75 15.75 11.0931 15.75 12.75V17.25C15.75 18.9069 14.4069 20.25 12.75 20.25H3.75C2.09315 20.25 0.75 18.9069 0.75 17.25V12.75Z"
      fill="#E00000"
    />
    <path
      d="M2.85245 12.8184V17.182H3.90714V15.8269H4.62304C4.95685 15.8269 5.2452 15.7651 5.4881 15.6415C5.731 15.5179 5.91779 15.3439 6.04847 15.1195C6.18057 14.8936 6.24662 14.6301 6.24662 14.329C6.24662 14.0293 6.18199 13.7665 6.05273 13.5407C5.92347 13.3134 5.73952 13.1365 5.50089 13.0101C5.26367 12.8823 4.98171 12.8184 4.655 12.8184H2.85245ZM3.90714 14.9959H4.42702C4.58611 14.9959 4.71892 14.9682 4.82546 14.9128C4.93341 14.8574 5.01509 14.78 5.07049 14.6806C5.12589 14.5797 5.15358 14.4625 5.15358 14.329C5.15358 14.1941 5.12589 14.0776 5.07049 13.9796C5.01509 13.8801 4.93341 13.8034 4.82546 13.7495C4.7175 13.6955 4.58469 13.6685 4.42702 13.6685H3.90714V14.9959ZM8.1392 17.182H6.52628V12.8184H8.13707C8.58167 12.8184 8.96449 12.9057 9.28551 13.0804C9.60795 13.2537 9.85653 13.5037 10.0312 13.8304C10.206 14.1557 10.2933 14.5449 10.2933 14.998C10.2933 15.4526 10.206 15.8432 10.0312 16.1699C9.85795 16.4966 9.61008 16.7473 9.28764 16.9221C8.9652 17.0953 8.58238 17.182 8.1392 17.182ZM7.58096 16.2828H8.09872C8.34304 16.2828 8.54971 16.2417 8.71875 16.1593C8.8892 16.0755 9.01775 15.9398 9.1044 15.7523C9.19247 15.5634 9.2365 15.312 9.2365 14.998C9.2365 14.6841 9.19247 14.4341 9.1044 14.248C9.01633 14.0605 8.88636 13.9256 8.71449 13.8432C8.54403 13.7594 8.33381 13.7175 8.0838 13.7175H7.58096V16.2828ZM10.6689 17.182V12.8184H13.6475V13.6749H11.7235V14.5698H13.4579V15.4284H11.7235V17.182H10.6689Z"
      fill="white"
      fillRule="evenodd"
    />
  </>,
  'ic-file-pdf',
);
