import { List } from '@allganize/ui-list';
import { Scroller } from '@allganize/ui-scroller';
import clsx from 'clsx';
import { GroupBase, MenuListProps } from 'react-select';
import { selectClasses } from './select-classes';

export const MenuList = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: MenuListProps<Option, IsMulti, Group>,
) => {
  const { children, className, getClassNames, innerProps, innerRef } = props;

  return (
    <Scroller
      forceVisible="y"
      autoHide={false}
      scrollableNodeProps={{ ...innerProps, ref: innerRef }}
      className={clsx(
        selectClasses.menuList,
        getClassNames('menuList', props),
        className,
        innerProps?.className,
      )}
    >
      <List component="div">{children}</List>
    </Scroller>
  );
};
