import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type KnowledgeBaseCardContainerClassKey = 'root';

export type KnowledgeBaseCardContainerClasses = Record<
  KnowledgeBaseCardContainerClassKey,
  string
>;

export const knowledgeBaseCardContainerClasses: KnowledgeBaseCardContainerClasses =
  generateUtilityClasses('KnowledgeBaseCard', ['root']);
