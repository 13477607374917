import { EditorState } from '@allganize/draft-input';
import { getCurrentEntity } from './get-current-entity';

export const hasEntity = (
  editorState: EditorState,
  entityType: string,
): boolean => {
  try {
    const entity = getCurrentEntity(editorState);
    return !!entity && entity.getType() === entityType;
  } catch (err) {
    return false;
  }
};
