import { Types } from '@allganize/alli-sdk-interfaces';
import { ChatAgentFragment } from '../graphql/fragments/chat-agent-fragment';

export interface AgentSelectOption {
  label: string;
  value: Types.Scalars['ID']['output'];
  description?: React.ReactNode;
  data: ChatAgentFragment;
}

export const getAgentSelectOption = (
  agent: ChatAgentFragment,
): AgentSelectOption => {
  return {
    label: agent.displayName || agent.firstName || agent.lastName || '',
    value: agent.id,
    data: agent,
  };
};

export const getAgentSelectOptions = (
  agents: Types.Maybe<ChatAgentFragment>[],
) => {
  const options = agents.reduce<AgentSelectOption[]>((acc, curr) => {
    if (!curr) {
      return acc;
    }

    return [...acc, getAgentSelectOption(curr)];
  }, []);

  return options;
};
