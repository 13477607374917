import {
  AccordionClassKey as MuiAccordionClassKey,
  accordionClasses as muiAccordionClasses,
} from '@mui/material/Accordion';

export type AccordionClassKey = Exclude<
  MuiAccordionClassKey,
  'rounded' | 'gutters'
>;
export type AccordionClasses = Record<AccordionClassKey, string>;

export const accordionClasses: AccordionClasses = muiAccordionClasses;
