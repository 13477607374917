import {
  useDraftToolbarEditorState,
  useDraftToolbarEditorStateSetter,
} from '@allganize/draft-toolbar-plugin';
import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { IcFormatBold } from '@allganize/ui-icons';
import { Tooltip } from '@allganize/ui-tooltip';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { getSelectedInlineStyles } from '../utils/get-selected-inline-styles';
import { toggleInlineStyleForSelection } from '../utils/toggle-inline-style-for-selection';

interface DraftToolbarTextBoldButtonProps extends IconButtonProps {}

export const DraftToolbarTextBoldButton = forwardRef<
  HTMLButtonElement,
  DraftToolbarTextBoldButtonProps
>((props, ref) => {
  const editorState = useDraftToolbarEditorState();
  const setEditorState = useDraftToolbarEditorStateSetter();
  const active = getSelectedInlineStyles(editorState).includes('BOLD');

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    setEditorState(toggleInlineStyleForSelection(editorState, 'BOLD'));
    props.onClick?.(ev);
  };

  return (
    <Tooltip
      title={
        <FormattedMessage
          id="TextArea.Bold"
          defaultMessage="Bold"
          description="Text area bold style tooltip text"
        />
      }
    >
      <IconButton
        color={active ? 'primary' : 'default'}
        size="small"
        {...props}
        onClick={handleClick}
        ref={ref}
      >
        <IcFormatBold fontSize="small" />
      </IconButton>
    </Tooltip>
  );
});
