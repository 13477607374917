import { CamelCase } from '@allganize/types';
import {
  getCircularProgressUtilityClass,
  circularProgressClasses as muiCircularProgressClasses,
  CircularProgressClassKey as MuiCircularProgressClassKey,
} from '@mui/material/CircularProgress';
import { omit } from 'lodash-es';
import { CircularProgressSize } from './circular-progress-type-map';

export type CircularProgressClassKey =
  | Exclude<MuiCircularProgressClassKey, 'colorSecondary'>
  | CamelCase<`size_${CircularProgressSize}`>;
export type CircularProgressClasses = Record<CircularProgressClassKey, string>;

export const circularProgressClasses: CircularProgressClasses = {
  ...omit(muiCircularProgressClasses, ['colorSecondary']),
  sizeXs: getCircularProgressUtilityClass('sizeXs'),
  sizeSm: getCircularProgressUtilityClass('sizeSm'),
  sizeMd: getCircularProgressUtilityClass('sizeMd'),
  sizeLg: getCircularProgressUtilityClass('sizeLg'),
  sizeXl: getCircularProgressUtilityClass('sizeXl'),
};
