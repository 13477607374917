import { ListItemTextClassKey } from '@allganize/ui-list';
import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type FileListItemTextClassKey = ListItemTextClassKey;
export type FileListItemTextClasses = Record<FileListItemTextClassKey, string>;

export const fileListItemTextClasses: FileListItemTextClasses =
  generateUtilityClasses('FileListItemText', [
    'root',
    'multiline',
    'primary',
    'secondary',
    'disabled',
    'insetSmall',
    'insetLarge',
  ]);
