import {
  getRadioUtilityClass,
  radioClasses as muiRadioClasses,
  RadioClassKey as MuiRadioClassKey,
} from '@mui/material/Radio';
import { omit } from 'lodash-es';

export type RadioClassKey =
  | Exclude<MuiRadioClassKey, 'colorPrimary' | 'colorSecondary'>
  | 'focusVisible';
export type RadioClasses = Record<RadioClassKey, string>;

export const radioClasses: RadioClasses = {
  ...omit(muiRadioClasses, ['colorPrimary', 'colorSecondary']),
  focusVisible: getRadioUtilityClass('focusVisible'),
};
