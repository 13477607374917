import { RichUtils } from '@allganize/draft-input';
import {
  useDraftToolbarEditorState,
  useDraftToolbarEditorStateSetter,
  useDraftToolbarFocus,
} from '@allganize/draft-toolbar-plugin';
import { useCombinedRef, useId } from '@allganize/hooks';
import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { IcLink, IcLinkOff } from '@allganize/ui-icons';
import { Popover, PopoverOrigin, popoverClasses } from '@allganize/ui-modal';
import { Tooltip } from '@allganize/ui-tooltip';
import { raf } from '@allganize/utils-timeout';
import { css } from '@emotion/react';
import { Fragment, forwardRef, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DraftLinkPlugin } from '../draft-link-plugin';
import { hasEntity } from '../utils/has-entity';
import { DraftToolbarLinkForm } from './draft-toolbar-link-form';

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const transformOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

interface DraftToolbarLinkButtonProps extends IconButtonProps {}

export const DraftToolbarLinkButton = forwardRef<
  HTMLButtonElement,
  DraftToolbarLinkButtonProps
>((props, ref) => {
  const focus = useDraftToolbarFocus();
  const editorState = useDraftToolbarEditorState();
  const setEditorState = useDraftToolbarEditorStateSetter();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverId = useId();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const combinedRef = useCombinedRef(buttonRef, ref);
  const hasLinkSelected = hasEntity(editorState, DraftLinkPlugin.ENTITY_TYPE);

  const closePopover = () => {
    setPopoverOpen(false);
    focus();
  };

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (hasEntity(editorState, DraftLinkPlugin.ENTITY_TYPE)) {
      setEditorState(
        RichUtils.toggleLink(editorState, editorState.getSelection(), null),
      );
    } else {
      ev.preventDefault();
      setPopoverOpen(prev => !prev);
    }

    props.onClick?.(ev);
  };

  const handleFormSubmit = async () => {
    await raf();
    closePopover();
  };

  return (
    <Fragment>
      <Tooltip
        title={
          hasLinkSelected ? (
            <FormattedMessage
              id="TextArea.LinkButton.Tooltip.Remove"
              defaultMessage="Remove Link"
              description="Text area remove link button tooltip text"
            />
          ) : (
            <FormattedMessage
              id="TextArea.LinkButton.Tooltip"
              defaultMessage="Insert Link"
              description="Text area insert link button tooltip text"
            />
          )
        }
      >
        <IconButton
          aria-owns={popoverOpen ? popoverId : undefined}
          aria-haspopup="true"
          size="small"
          color={hasLinkSelected ? 'primary' : 'default'}
          {...props}
          ref={combinedRef}
          onClick={handleClick}
        >
          {hasLinkSelected ? (
            <IcLinkOff fontSize="small" />
          ) : (
            <IcLink fontSize="small" />
          )}
        </IconButton>
      </Tooltip>

      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={buttonRef.current}
        onClose={closePopover}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        // Popover's forced focus causes draftjs selection to behave in a weird way sometimes on firefox and safari.
        disableEnforceFocus
        css={css`
          .${popoverClasses.paper} {
            width: 320px;
          }
        `}
      >
        <DraftToolbarLinkForm onSubmit={handleFormSubmit} />
      </Popover>
    </Fragment>
  );
});
