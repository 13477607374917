import { EditorState } from '@allganize/draft-input';
import { useEventCallback } from '@allganize/hooks';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { DraftToolbarContextValue } from '../draft-toolbar-context';
import { DraftToolbarProvider } from '../draft-toolbar-provider';
import {
  DraftToolbarClasses,
  draftToolbarClasses,
} from './draft-toolbar.classes';

const useUnpropagatedCallback = <T extends React.SyntheticEvent>(
  callback?: (ev: T) => void,
) => {
  return useEventCallback((ev: T) => {
    ev.stopPropagation();
    callback?.(ev);
  });
};

export interface DraftToolbarProps
  extends Pick<DraftToolbarContextValue, 'focus' | 'blur'>,
    Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  classes?: Partial<DraftToolbarClasses>;
  value: EditorState;
  onChange(value: EditorState): void;
  disabled?: boolean;
}

export const DraftToolbar = forwardRef<HTMLDivElement, DraftToolbarProps>(
  (props, ref) => {
    const {
      blur,
      classes,
      disabled = false,
      focus,
      onChange,
      value,
      ...other
    } = props;
    const theme = useTheme();

    const getEditorState = useEventCallback(() => value);
    const getDisabled = useEventCallback(() => disabled);

    const onClick = useUnpropagatedCallback(props.onClick);
    const onTouchStart = useUnpropagatedCallback(props.onTouchStart);
    const onTouchEnd = useUnpropagatedCallback(props.onTouchEnd);
    const onTouchMove = useUnpropagatedCallback(props.onTouchMove);
    const onTouchCancel = useUnpropagatedCallback(props.onTouchCancel);
    const onMouseOut = useUnpropagatedCallback(props.onMouseOut);
    const onMouseOver = useUnpropagatedCallback(props.onMouseOver);
    const onMouseEnter = useUnpropagatedCallback(props.onMouseEnter);
    const onMouseMove = useUnpropagatedCallback(props.onMouseMove);
    const onMouseDown = useUnpropagatedCallback(props.onMouseDown);
    const onMouseUp = useUnpropagatedCallback(props.onMouseUp);
    const onMouseLeave = useUnpropagatedCallback(props.onMouseLeave);
    const onFocus = useUnpropagatedCallback(props.onFocus);
    const onBlur = useUnpropagatedCallback(props.onBlur);
    const onSubmit = useUnpropagatedCallback(props.onSubmit);

    return (
      <DraftToolbarProvider
        value={{
          focus,
          blur,
          getEditorState,
          setEditorState: onChange,
          getDisabled,
          editorState: value,
        }}
      >
        <div
          data-testid="draft-toolbar"
          css={css`
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            order: -1;
            padding: 0 11px;
            min-height: 32px;
            background-color: ${theme.palette.unstable_background.default};
            border-top-left-radius: ${theme.radius.sm}px;
            border-top-right-radius: ${theme.radius.sm}px;
          `}
          {...other}
          ref={ref}
          className={clsx(
            draftToolbarClasses.root,
            classes?.root,
            other.className,
          )}
          onClick={onClick}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          onTouchMove={onTouchMove}
          onTouchCancel={onTouchCancel}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
          onMouseEnter={onMouseEnter}
          onMouseMove={onMouseMove}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseLeave}
          onFocus={onFocus}
          onBlur={onBlur}
          onSubmit={onSubmit}
        />
      </DraftToolbarProvider>
    );
  },
);
