import { IcFile } from '@allganize/ui-icons';
import { ListItemIcon } from '@allganize/ui-list';
import { ClassNames } from '@emotion/react';
import { forwardRef } from 'react';
import { fileListItemIconClasses } from './file-list-item-icon-classes';
import { FileListItemIconProps } from './file-list-item-icon-type-map';

export const FileListItemIcon = forwardRef<
  HTMLDivElement,
  FileListItemIconProps
>((props, ref) => {
  const { children, classes, position = 'start', ...other } = props;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <ListItemIcon
          data-testid="file-list-item-icon"
          position={position}
          {...other}
          ref={ref}
          classes={{
            ...classes,
            root: cx(
              css`
                padding: 6px 0;
              `,
              position === 'start' &&
                css`
                  margin-right: 4px;
                `,
              position === 'end' &&
                css`
                  margin-left: 4px;
                `,
              fileListItemIconClasses.root,
              classes?.root,
            ),
            alignItemsFlexStart: cx(
              css`
                margin-top: 6px;
              `,
              fileListItemIconClasses.alignItemsFlexStart,
              classes?.alignItemsFlexStart,
            ),
            disabled: cx(fileListItemIconClasses.disabled, classes?.disabled),
          }}
        >
          {children || <IcFile />}
        </ListItemIcon>
      )}
    </ClassNames>
  );
});
