import { useCombinedRef } from '@allganize/hooks';
import { IframeContext } from '@allganize/react-iframe';
import { useTheme } from '@allganize/ui-theme';
import { Fade, Grow } from '@allganize/ui-transition';
import { css } from '@emotion/react';
import { useSlotProps } from '@mui/base/utils';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import BaseFocusTrap from '@mui/material/Unstable_TrapFocus';
import clsx from 'clsx';
import { FunctionComponent, useContext, useEffect, useRef } from 'react';
import { useDefaultReduceAnimations } from '../internals/hooks/use-default-reduce-animations';
import { getActiveElement } from '../internals/utils/utils';
import { pickersPopperClasses } from './pickers-popper-classes';
import { PickersPopperPaperWrapper } from './pickers-popper-paper-wrapper';
import { PickersPopperPaper, PickersPopperRoot } from './pickers-popper-slots';
import { PickersPopperProps } from './pickers-popper-type-map';
import { useClickAwayListener } from './use-click-away-listener';

export const PickersPopper: FunctionComponent<PickersPopperProps> = props => {
  const {
    anchorEl,
    children,
    classes,
    containerRef = null,
    shouldRestoreFocus,
    onBlur,
    onDismiss,
    open,
    role,
    placement,
    slots,
    slotProps,
    reduceAnimations: inReduceAnimations,
  } = props;
  const theme = useTheme();
  const muiTheme = useMuiTheme();
  const { document: doc, window: contentWindow } = useContext(IframeContext);

  useEffect(() => {
    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Blink?) use 'Esc'
      if (open && (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc')) {
        onDismiss();
      }
    }

    doc.addEventListener('keydown', handleKeyDown);

    return () => {
      doc.removeEventListener('keydown', handleKeyDown);
    };
  }, [doc, onDismiss, open]);

  const lastFocusedElementRef = useRef<Element | null>(null);

  useEffect(() => {
    if (role === 'tooltip' || (shouldRestoreFocus && !shouldRestoreFocus())) {
      return;
    }

    if (open) {
      lastFocusedElementRef.current = getActiveElement(doc);
    } else if (
      lastFocusedElementRef.current &&
      (lastFocusedElementRef.current instanceof HTMLElement ||
        lastFocusedElementRef.current instanceof contentWindow.HTMLElement)
    ) {
      // make sure the button is flushed with updated label, before returning focus to it
      // avoids issue, where screen reader could fail to announce selected date after selection
      setTimeout(() => {
        if (
          lastFocusedElementRef.current instanceof HTMLElement ||
          lastFocusedElementRef.current instanceof contentWindow.HTMLElement
        ) {
          lastFocusedElementRef.current.focus();
        }
      });
    }
  }, [contentWindow, doc, open, role, shouldRestoreFocus]);

  const [clickAwayRef, onPaperClick, onPaperTouchStart] = useClickAwayListener(
    open,
    onBlur ?? onDismiss,
  );
  const paperRef = useRef<HTMLDivElement>(null);
  const handleRef = useCombinedRef(paperRef, containerRef);
  const handlePaperRef = useCombinedRef(
    handleRef,
    clickAwayRef as React.Ref<HTMLDivElement>,
  );

  const ownerState = props;
  const defaultReduceAnimations = useDefaultReduceAnimations();
  const reduceAnimations = inReduceAnimations ?? defaultReduceAnimations;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      // stop the propagation to avoid closing parent modal
      event.stopPropagation();
      onDismiss();
    }
  };

  const Transition = slots?.desktopTransition ?? reduceAnimations ? Fade : Grow;
  const FocusTrap = slots?.desktopTrapFocus ?? BaseFocusTrap;

  const Paper = slots?.desktopPaper ?? PickersPopperPaper;
  const Popper = slots?.popper ?? PickersPopperRoot;
  const popperProps = useSlotProps({
    elementType: Popper,
    externalSlotProps: slotProps?.popper,
    additionalProps: {
      transition: true,
      role,
      open,
      anchorEl,
      placement,
      onKeyDown: handleKeyDown,
    },
    className: clsx(pickersPopperClasses.root, classes?.root),
    // @ts-expect-error paper internal component
    ownerState: props,
  });

  return (
    <ThemeProvider theme={{ ...muiTheme, direction: theme.direction }}>
      <Popper
        data-testid="pickers-popper"
        css={css`
          z-index: ${theme.zIndex.modal};
        `}
        {...popperProps}
      >
        {({ TransitionProps, placement: popperPlacement }) => (
          <FocusTrap
            open={open}
            disableAutoFocus
            // pickers are managing focus position manually
            // without this prop the focus is returned to the button before `aria-label` is updated
            // which would force screen readers to read too old label
            disableRestoreFocus
            disableEnforceFocus={role === 'tooltip'}
            isEnabled={() => true}
            {...slotProps?.desktopTrapFocus}
          >
            <Transition {...TransitionProps} {...slotProps?.desktopTransition}>
              <PickersPopperPaperWrapper
                PaperComponent={Paper}
                ownerState={ownerState}
                popperPlacement={popperPlacement}
                ref={handlePaperRef}
                onPaperClick={onPaperClick}
                onPaperTouchStart={onPaperTouchStart}
                paperClasses={clsx(pickersPopperClasses.paper, classes?.paper)}
                paperSlotProps={slotProps?.desktopPaper}
              >
                {children}
              </PickersPopperPaperWrapper>
            </Transition>
          </FocusTrap>
        )}
      </Popper>
    </ThemeProvider>
  );
};
