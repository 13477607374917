import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { Backdrop } from '../backdrop';
import { ModalOwnerState, ModalTypeMap } from './modal-type-map';

const ModalRootRoot = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
`;

export const ModalRoot = forwardRef<
  HTMLDivElement,
  NonNullable<NonNullable<ModalTypeMap['props']['slotProps']>['root']> & {
    ownerState: ModalOwnerState;
  }
>((props, ref) => {
  const { ownerState, ...other } = props;
  const theme = useTheme();

  return (
    <ModalRootRoot
      css={[
        css`
          z-index: ${theme.zIndex.modal};
        `,
        !ownerState.open &&
          ownerState.exited &&
          css`
            visibility: hidden;
          `,
      ]}
      {...other}
      ref={ref}
    />
  );
});

export const ModalBackdrop = styled(Backdrop, {
  shouldForwardProp: prop => prop !== 'ownerState',
})<{ ownerState: ModalOwnerState }>`
  z-index: -1;
`;
