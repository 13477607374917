import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { Fragment, FunctionComponent, useMemo } from 'react';
import { MULTI_SECTION_CLOCK_SECTION_WIDTH } from '../internals/constants';
import { useMeridiemMode } from '../internals/hooks/date-helpers-hooks';
import { useLocaleText, useUtils } from '../internals/hooks/use-utils';
import { formatMeridiem } from '../internals/utils/date-utils';
import { PickersToolbar } from '../pickers-toolbar';
import { PickersToolbarButton } from '../pickers-toolbar-button';
import { dateTimePickerToolbarClasses } from './date-time-picker-toolbar-classes';
import { DateTimePickerToolbarSeparator } from './date-time-picker-toolbar-slots';
import { DateTimePickerToolbarProps } from './date-time-picker-toolbar-type-map';

export const DateTimePickerToolbar: FunctionComponent<
  DateTimePickerToolbarProps
> = props => {
  const {
    ampm,
    ampmInClock,
    classes,
    value,
    onChange,
    view,
    isLandscape,
    onViewChange,
    toolbarFormat,
    toolbarPlaceholder = '––',
    views,
    disabled,
    readOnly,
    toolbarVariant = 'mobile',
    ...other
  } = props;

  const ownerState = props;
  const utils = useUtils<Date>();
  const { meridiemMode, handleMeridiemChange } = useMeridiemMode(
    value,
    ampm,
    onChange,
  );

  const showAmPmControl = Boolean(ampm && !ampmInClock);
  const isDesktop = toolbarVariant === 'desktop';

  const localeText = useLocaleText<Date>();
  const theme = useTheme();

  const formatHours = (time: Date) =>
    ampm ? utils.format(time, 'hours12h') : utils.format(time, 'hours24h');

  const dateText = useMemo(() => {
    if (!value) {
      return toolbarPlaceholder;
    }

    if (toolbarFormat) {
      return utils.formatByString(value, toolbarFormat);
    }

    return utils.format(value, 'shortDate');
  }, [value, toolbarFormat, toolbarPlaceholder, utils]);

  return (
    <PickersToolbar
      data-testid="date-time-picker-toolbar"
      css={[
        css`
          padding-left: ${toolbarVariant === 'desktop' && !isLandscape
            ? 24
            : 16}px;
          padding-right: ${toolbarVariant === 'desktop' && !isLandscape
            ? 0
            : 16}px;
          justify-content: space-around;
          position: relative;
        `,
        toolbarVariant === 'desktop' &&
          css`
            border-bottom: 1px solid ${theme.palette.divider};

            ${isLandscape &&
            css`
              border-right: 1px solid ${theme.palette.divider};
            `}
          `,
      ]}
      toolbarTitle={localeText.dateTimePickerToolbarTitle}
      isLandscape={isLandscape}
      {...other}
      className={clsx(
        dateTimePickerToolbarClasses.root,
        classes?.root,
        other.className,
      )}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        `}
        className={clsx(
          dateTimePickerToolbarClasses.dateContainer,
          classes?.dateContainer,
        )}
      >
        {views.includes('year') && (
          <PickersToolbarButton
            tabIndex={-1}
            variant="title14"
            onClick={() => onViewChange('year')}
            selected={view === 'year'}
            value={value ? utils.format(value, 'year') : '–'}
          />
        )}

        {views.includes('day') && (
          <PickersToolbarButton
            tabIndex={-1}
            variant={isDesktop ? 'title14' : 'title16'}
            onClick={() => onViewChange('day')}
            selected={view === 'day'}
            value={dateText}
          />
        )}
      </div>

      <div
        css={[
          css`
            display: flex;
            flex-direction: ${isLandscape && toolbarVariant !== 'desktop'
              ? 'column'
              : 'row'};
          `,
          toolbarVariant === 'desktop' &&
            !isLandscape &&
            css`
              gap: 9px;
              margin-right: 4px;
              align-self: flex-end;
            `,
          theme.direction === 'rtl' &&
            css`
              flex-direction: ${isLandscape && toolbarVariant !== 'desktop'
                ? 'column'
                : 'row'}-reverse;
            `,
        ]}
        className={clsx(
          dateTimePickerToolbarClasses.timeContainer,
          {
            [dateTimePickerToolbarClasses.timeLabelReverse]:
              theme.direction === 'rtl',
          },
          classes?.timeContainer,
          { [classes?.timeLabelReverse ?? '']: theme.direction === 'rtl' },
        )}
      >
        <div
          css={[
            css`
              display: flex;
            `,
            toolbarVariant === 'desktop' &&
              css`
                gap: 1.5px;
              `,
            theme.direction === 'rtl' &&
              css`
                flex-direction: row-reverse;
              `,
          ]}
          className={clsx(
            dateTimePickerToolbarClasses.timeDigitsContainer,
            {
              [dateTimePickerToolbarClasses.timeLabelReverse]:
                theme.direction === 'rtl',
            },
            classes?.timeDigitsContainer,
            { [classes?.timeLabelReverse ?? '']: theme.direction === 'rtl' },
          )}
        >
          {views.includes('hours') && (
            <PickersToolbarButton
              variant={isDesktop ? 'title14' : 'title16'}
              width={
                isDesktop && !isLandscape
                  ? MULTI_SECTION_CLOCK_SECTION_WIDTH
                  : undefined
              }
              onClick={() => onViewChange('hours')}
              selected={view === 'hours'}
              value={value ? formatHours(value) : '--'}
            />
          )}

          {views.includes('minutes') && (
            <Fragment>
              <DateTimePickerToolbarSeparator
                variant={isDesktop ? 'title14' : 'title16'}
                value=":"
                className={clsx(
                  dateTimePickerToolbarClasses.separator,
                  classes?.separator,
                )}
                ownerState={ownerState}
              />
              <PickersToolbarButton
                variant={isDesktop ? 'title14' : 'title16'}
                width={
                  isDesktop && !isLandscape
                    ? MULTI_SECTION_CLOCK_SECTION_WIDTH
                    : undefined
                }
                onClick={() => onViewChange('minutes')}
                selected={view === 'minutes'}
                value={value ? utils.format(value, 'minutes') : '--'}
              />
            </Fragment>
          )}

          {views.includes('seconds') && (
            <Fragment>
              <DateTimePickerToolbarSeparator
                variant={isDesktop ? 'title14' : 'title16'}
                value=":"
                className={clsx(
                  dateTimePickerToolbarClasses.separator,
                  classes?.separator,
                )}
                ownerState={ownerState}
              />
              <PickersToolbarButton
                variant={isDesktop ? 'title14' : 'title16'}
                width={
                  isDesktop && !isLandscape
                    ? MULTI_SECTION_CLOCK_SECTION_WIDTH
                    : undefined
                }
                onClick={() => onViewChange('seconds')}
                selected={view === 'seconds'}
                value={value ? utils.format(value, 'seconds') : '--'}
              />
            </Fragment>
          )}
        </div>

        {showAmPmControl && !isDesktop && (
          <div
            css={[
              css`
                display: flex;
                flex-direction: column;
                margin-right: auto;
                margin-left: 12px;

                .${dateTimePickerToolbarClasses.ampmLabel} {
                  font-size: 17px;
                }
              `,
              isLandscape &&
                css`
                  margin: 4px 0 auto;
                  flex-direction: row;
                  justify-content: space-around;
                  width: 100%;
                `,
            ]}
            className={clsx(
              dateTimePickerToolbarClasses.ampmSelection,
              { [dateTimePickerToolbarClasses.ampmLandscape]: isLandscape },
              classes?.ampmSelection,
              { [classes?.ampmLandscape ?? '']: isLandscape },
            )}
          >
            <PickersToolbarButton
              variant="title12"
              selected={meridiemMode === 'am'}
              textClassName={clsx(
                dateTimePickerToolbarClasses.ampmLabel,
                classes?.ampmLabel,
              )}
              value={formatMeridiem(utils, 'am')}
              onClick={readOnly ? undefined : () => handleMeridiemChange('am')}
              disabled={disabled}
            />
            <PickersToolbarButton
              variant="title12"
              selected={meridiemMode === 'pm'}
              textClassName={clsx(
                dateTimePickerToolbarClasses.ampmLabel,
                classes?.ampmLabel,
              )}
              value={formatMeridiem(utils, 'pm')}
              onClick={readOnly ? undefined : () => handleMeridiemChange('pm')}
              disabled={disabled}
            />
          </div>
        )}

        {ampm && isDesktop && (
          <PickersToolbarButton
            variant="title14"
            onClick={() => onViewChange('meridiem')}
            selected={view === 'meridiem'}
            value={
              value && meridiemMode ? formatMeridiem(utils, meridiemMode) : '--'
            }
            width={MULTI_SECTION_CLOCK_SECTION_WIDTH}
          />
        )}
      </div>
    </PickersToolbar>
  );
};
