import { ValidationContextValue } from '@allganize/alli-sdk-chat';
import { ChatFormValidation } from '../hooks/use-chat-form';

export const validation: ValidationContextValue & {
  chatForm: ChatFormValidation;
} = {
  chatForm: {
    file: {
      maxSize: {
        default: 75 * 1048576,
        image: 10 * 1048576,
      },
    },
  },
  contactAgentByEmail: {
    attachments: {
      maxCount: 5,
      maxSize: 10 * 1048576,
    },
  },
  formValue: {
    formValues: {
      fileValue: {
        maxSize: {
          default: 75 * 1048576,
          image: 10 * 1048576,
        },
      },
    },
  },
};
