import { ListClassKey } from '@allganize/ui-list';
import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type FileListClassKey = ListClassKey;
export type FileListClasses = Record<FileListClassKey, string>;

export const fileListClasses: FileListClasses = generateUtilityClasses(
  'FileList',
  ['root', 'subheader'],
);
