import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent, HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

interface EmptyStateProps extends HTMLAttributes<HTMLDivElement> {
  isSearch?: boolean;
}

export const EmptyState: FunctionComponent<EmptyStateProps> = ({
  isSearch,
  ...others
}) => {
  const theme = useTheme();
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 8px 16px;
        color: ${theme.palette.foreground.secondary};
      `}
      {...others}
    >
      <svg width="112" height="112" viewBox="0 0 112 112" fill="none">
        <g clipPath="url(#clip0_623_5511)">
          <rect width="112" height="112" rx="56" fill="white" />
          <path
            d="M83.3145 84.7141H81.1025L80.3185 83.9581C83.0625 80.7661 84.7145 76.6221 84.7145 72.1141C84.7145 62.0621 76.5665 53.9141 66.5145 53.9141C56.4625 53.9141 48.3145 62.0621 48.3145 72.1141C48.3145 82.1661 56.4625 90.3141 66.5145 90.3141C71.0225 90.3141 75.1665 88.6621 78.3585 85.9181L79.1145 86.7021V88.9141L93.1145 102.886L97.2865 98.7141L83.3145 84.7141ZM66.5145 84.7141C59.5425 84.7141 53.9145 79.0861 53.9145 72.1141C53.9145 65.1421 59.5425 59.5141 66.5145 59.5141C73.4865 59.5141 79.1145 65.1421 79.1145 72.1141C79.1145 79.0861 73.4865 84.7141 66.5145 84.7141Z"
            fill="black"
            fillOpacity="0.3"
          />
          <path
            d="M36.8663 -3.5C13.7038 -1.67541 5.19083 16.5686 12.1648 27.2033C17.0859 34.7065 25.4246 44.847 40.9688 42.4147C56.5123 39.9817 57.8219 26.5955 49.6358 24.7746C41.4497 22.9544 12.2866 31.4465 15.5825 51.0648C17.9617 65.2199 38.5836 69.3001 38.5836 69.3001"
            stroke="#DFDFE3"
            strokeWidth="2"
            strokeDasharray="6 4"
          />
        </g>
        <defs>
          <clipPath id="clip0_623_5511">
            <rect width="112" height="112" rx="56" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <Text
        variant="title14"
        css={css`
          margin-top: 16px;
          margin-bottom: 4px;
        `}
      >
        {isSearch ? (
          <FormattedMessage
            id="knowledge-base.search-dialog.search-empty-state.title"
            defaultMessage="No search results found"
            description="knowledge base search dialog empty search state title text"
          />
        ) : (
          <FormattedMessage
            id="knowledge-base.search-dialog.empty-state.title"
            defaultMessage="No document available"
            description="knowledge base search dialog empty state title text"
          />
        )}
      </Text>
      <Text variant="body14">
        {isSearch ? (
          <FormattedMessage
            id="knowledge-base.search-dialog.search-empty-state.description"
            defaultMessage="Please try another keyword."
            description="knowledge base search dialog empty search state description text"
          />
        ) : (
          <FormattedMessage
            id="knowledge-base.search-dialog.empty-state.description"
            defaultMessage="Please contact your administrator"
            description="knowledge base search dialog empty state description text"
          />
        )}
      </Text>
    </div>
  );
};
