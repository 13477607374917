import { useEventCallback, useUnmount } from '@allganize/hooks';
import { raf } from '@allganize/utils-timeout';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DocumentViewerPageInfo } from '../document-viewer/document-viewer-type-map';
import { closestNumber } from '../utils/closest-number';
import { uaParser } from '../utils/ua-parser';

const BUFFER_FOR_BLEND_MODE = 100;

export interface PageProxy {
  getKey(): string | number;
  getSize(): { width: number; height: number } | null;
}

export interface ScaleOption {
  value: number | 'fit';
  label: string;
}

interface UseScaleOptions {
  containerRef?: React.RefObject<HTMLElement>;
  page?: DocumentViewerPageInfo | null;
  initialValue?: number;
  min?: number;
  max?: number;
  step?: number;
  getContentOffset?(): [number, number];
  contentOffsetX?: number;
  contentOffsetY?: number;
  onInitialPageScale?(): void;
}

export const useScale = ({
  containerRef,
  page,
  initialValue = 1,
  min = 0.4,
  max = 2,
  step = 0.2,
  getContentOffset,
  onInitialPageScale,
}: UseScaleOptions) => {
  const [scale, _setScale] = useState(initialValue);
  const [pageProxy, setPageProxy] = useState<PageProxy | null>(null);
  const pageFitCalled = useRef(false);
  const pageKeyRef = useRef<string | number | null>(null);
  const canIncrementScale = scale < max;
  const canDecrementScale = scale > min;
  const disableScale = page?.type === 'draft';
  const scales = useMemo(() => {
    const scales: number[] = [];

    for (let i = min; i <= max; i += step) {
      const factor = 10 ** 12;
      scales.push(Math.round(i * factor) / factor);
    }

    return scales;
  }, [max, min, step]);

  const blendMode = useMemo(() => {
    return (
      !uaParser.hasBlendModeBug ||
      (containerRef?.current?.clientWidth ?? 0) >
        scale * (pageProxy?.getSize()?.width || 0) + BUFFER_FOR_BLEND_MODE
    );
  }, [containerRef, pageProxy, scale]);

  const clampScale = useEventCallback((value: number) => {
    const closest = closestNumber(value, scales);

    if (closest === null) {
      return Math.min(Math.max(value, min), max);
    }

    return closest;
  });

  const setScale = useCallback<typeof _setScale>(
    newValue => {
      _setScale(prev =>
        clampScale(typeof newValue === 'function' ? newValue(prev) : newValue),
      );
    },
    [clampScale],
  );

  const updateScale = useEventCallback(async (value: number) => {
    const clampedValue = clampScale(value);

    if (clampedValue === scale) {
      return;
    }

    const sl = containerRef?.current?.scrollLeft ?? 0;
    const st = containerRef?.current?.scrollTop ?? 0;
    const cw = containerRef?.current?.clientWidth ?? 0;
    const ch = containerRef?.current?.clientHeight ?? 0;

    // get the point on the page
    const x = sl + cw / 2;
    const y = st + ch / 2;

    // get the point on the page before scaling
    const nx = x / scale;
    const ny = y / scale;

    // get the point on the page after scaling
    const nnx = nx * clampedValue;
    const nny = ny * clampedValue;

    // get the new scroll position
    const nsl = nnx - cw / 2;
    const nst = nny - ch / 2;

    setScale(clampedValue);

    await raf();
    containerRef?.current?.scrollTo(nsl, nst);
  });

  const incrementScale = () => updateScale(scale + step);
  const decrementScale = () => updateScale(scale - step);
  const resetScale = () => updateScale(initialValue);

  const getFitPageScale = () => {
    if (!pageProxy) {
      return null;
    }

    const pageSize = pageProxy.getSize();
    const pageKey = pageProxy.getKey();

    if (!pageSize || !containerRef?.current) {
      return null;
    }

    if (pageKey !== pageKeyRef.current) {
      return null;
    }

    const offsets = getContentOffset?.() ?? [0, 0];
    const { height, width } = pageSize;
    const pageAreaWidth = containerRef.current.clientWidth + offsets[0];
    const pageAreaHeight = containerRef.current.clientHeight + offsets[1];
    const fitScales = [pageAreaWidth / width, pageAreaHeight / height].filter(
      num => !Number.isNaN(num) && num > 0 && Number.isFinite(num),
    );

    if (fitScales.length === 0) {
      return null;
    }

    const scale = Math.min(...fitScales);
    return { key: pageKey, scale };
  };

  const resetFitPage = useEventCallback(() => {
    pageFitCalled.current = false;
    pageKeyRef.current = null;
  });

  const getPageStyle = useCallback(
    (size: { width?: number; height?: number }): React.CSSProperties => {
      return {
        width:
          typeof size.width === 'undefined'
            ? size.width
            : Math.ceil(size.width * scale),
        height:
          typeof size.height === 'undefined'
            ? size.height
            : Math.ceil(size.height * scale),
      };
    },
    [scale],
  );

  useEffect(() => {
    pageKeyRef.current = page?.key ?? null;
  }, [page?.key]);

  useLayoutEffect(() => {
    if (pageFitCalled.current) {
      return;
    }

    const fitPageScale = getFitPageScale();

    if (fitPageScale === null) {
      return;
    }

    pageFitCalled.current = true;
    updateScale(fitPageScale.scale);
    onInitialPageScale?.();
  });

  useUnmount(() => {
    resetFitPage();
  });

  return {
    canDecrementScale,
    canIncrementScale,
    scale,
    setScale,
    clampScale,
    incrementScale,
    decrementScale,
    updateScale,
    getFitPageScale,
    setPageProxy,
    resetScale,
    resetFitPage,
    getPageStyle,
    disableScale,
    scales,
    blendMode,
  };
};
