import invariant from 'tiny-invariant';
import { AlliAuthClient } from './alli-auth-client';
import { AlliAuthClientOptions } from './alli-auth-client-options';

export interface AlliChatAuthClientOptions extends AlliAuthClientOptions {
  chatToken: string;
}

export class AlliChatAuthClient extends AlliAuthClient {
  public readonly chatToken: string;

  constructor({
    chatToken,
    endpoints,
    sdkType,
    deviceIdManager,
    persistedUserIdManager,
    userManager,
  }: AlliChatAuthClientOptions) {
    super({
      endpoints,
      sdkType,
      deviceIdManager,
      persistedUserIdManager,
      userManager,
    });
    invariant(chatToken, 'chatToken is required');
    this.chatToken = chatToken;
  }

  public async getGraphQLHeaders(): Promise<Record<string, string>> {
    const base = await super.getGraphQLHeaders();

    const result: Record<string, string> = {
      ...base,
      'chat-token': this.chatToken,
    };

    return result;
  }
}
