import { DependencyList, useEffect } from 'react';
import { useTimeoutFn } from '../use-timeout-fn/use-timeout-fn';

export const useDebounce = (
  fn: () => void,
  ms = 0,
  deps: DependencyList = [],
) => {
  const [isReady, cancel, reset] = useTimeoutFn(fn, ms);

  useEffect(reset, deps);

  return [isReady, cancel] as const;
};
