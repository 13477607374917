import { Types } from '@allganize/alli-sdk-interfaces';
import { FunctionComponent, useContext } from 'react';
import { ConversationContext } from '../conversation-detail/conversation-context';
import { UseChatListOptions, useChatList } from '../hooks/use-chat-list';
import { ChatListContext } from './chat-list-context';

interface ChatListProviderProps extends UseChatListOptions {
  children?: React.ReactNode;
}

export const ChatListProvider: FunctionComponent<
  ChatListProviderProps
> = props => {
  const {
    data: { conversation },
  } = useContext(ConversationContext);
  const state = conversation?.state;
  const skipSubscription = state && state === Types.ConversationStateEnum.INIT;
  const chatList = useChatList({ skipSubscription, ...props });

  return (
    <ChatListContext.Provider value={chatList}>
      {props.children}
    </ChatListContext.Provider>
  );
};
