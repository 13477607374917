import { DocumentViewerRef } from '@allganize/alli-document';
import { DraftDocumentEntity } from '@allganize/draft-document-plugin';
import { noop } from 'lodash-es';
import { createContext } from 'react';
import { DocumentPreviewState } from '../document-preview/document-preview-state';
import { ChatFragment } from '../graphql/fragments/chat-fragment';
import { ClosePreviewReason } from '../preview/preview-state';

interface DocumentPreviewContextValue {
  documentViewerRef?: React.Ref<DocumentViewerRef>;
  documentPreviewOpen: boolean;
  documentPreviewState: DocumentPreviewState | null;
  openDocumentPreview(options: {
    chat: ChatFragment;
    entity: DraftDocumentEntity;
    trigger?: DocumentPreviewState['trigger'];
  }): Promise<void>;
  closeDocumentPreview(reason: ClosePreviewReason): void;
}

export const DocumentPreviewContext =
  createContext<DocumentPreviewContextValue>({
    documentViewerRef: undefined,
    documentPreviewOpen: false,
    documentPreviewState: null,
    openDocumentPreview: () =>
      Promise.reject('DocumentPreviewContext is not initialized'),
    closeDocumentPreview: noop,
  });
