import { DraftDecoratorComponentProps } from '@allganize/draft-input';
import { Link } from '@allganize/ui-link';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { DraftLink, DraftLinkProps } from '../draft-link-plugin';

export const DraftTooltipLink = forwardRef<
  HTMLAnchorElement,
  DraftDecoratorComponentProps & DraftLinkProps
>((props, ref) => {
  const { contentState, entityKey } = props;
  const entity = entityKey ? contentState.getEntity(entityKey) : null;
  const entityData = entity?.getData();
  const href = entityData?.url || undefined;

  const link = <DraftLink {...props} ref={ref} />;

  if (!href) {
    return link;
  }

  return (
    <Tooltip
      title={
        <Link
          css={css`
            color: inherit;
          `}
          underline="always"
          variant="inherit"
          href={href}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage
            id="TextArea.Link.VisitLink"
            defaultMessage="Open link"
            description="Text area open link text"
          />
        </Link>
      }
    >
      {link}
    </Tooltip>
  );
});
