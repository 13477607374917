import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client/react';
import { UserQueryTypes } from '@allganize/alli-interfaces';
import { useEventCallback } from '@allganize/hooks';
import { toast } from '@allganize/ui-toast';
import { compact, findKey, isEmpty, omit } from 'lodash-es';
import { ReactNode, useMemo, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { v4 } from 'uuid';
import { UploadKnowledgeFileWithTokenMutationDocument } from '../../graphql/mutations/upload-knowledge-file-with-token-mutation';
import { KnowledgeBasesPublicSubscriptionDocument } from '../../graphql/subscriptions/knowledge-bases-public-subscription';
import { KnowledgeBasesCountQueryDocument } from '../../graphql/queries/knowledge-bases-count-query';
import { ProjectMaxKnowledgeBaseCountQueryDocument } from '../../graphql/queries/project-max-knowledge-base-count-query';
import { KnowledgeBase } from '../types/types';
import {
  KnowledgeBaseUploadDialogProps,
  UploadError,
  UploadErrorType,
  UploadingFile,
  UploadingFileList,
} from './knowledge-base-upload-dialog-type-map';

interface UseKnowledgeBaseUploadProps {
  onConfirm: KnowledgeBaseUploadDialogProps['onConfirm'];
}

export const useKnowledgeBaseUpload = ({
  onConfirm,
}: UseKnowledgeBaseUploadProps) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [knowledgeBases, _setKnowledgeBases] = useState<UploadingFileList>({});
  const setKnowledgeBase = (key: string, data: UploadingFile) => {
    _setKnowledgeBases(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        ...data,
      },
    }));
  };

  const { uploadErrors, loadingFileIds } = useMemo(() => {
    const uploadErrors: string[] = [];
    const loadingFileIds: (string | number)[] = [];

    Object.values(knowledgeBases).forEach(kb => {
      if (!kb || kb.type === 'GoogleDocsKnowledgeBase') {
        return;
      }
      const isErrorFile =
        kb.error ||
        kb.processState === UserQueryTypes.ProcessStateEnum.RETRYING ||
        kb.processState === UserQueryTypes.ProcessStateEnum.PARSING_FAIL;

      const isLoadingFile =
        kb.loading ||
        kb.processState === UserQueryTypes.ProcessStateEnum.INIT ||
        kb.processState === UserQueryTypes.ProcessStateEnum.PARSING;

      if (isErrorFile && kb.fileName) {
        uploadErrors.push(kb.fileName);
      }

      if (isLoadingFile && kb.id) {
        loadingFileIds.push(kb.id);
      }
    });

    return {
      uploadErrors,
      loadingFileIds,
    };
  }, [knowledgeBases]);

  const { data: projectData } = useQuery(
    ProjectMaxKnowledgeBaseCountQueryDocument,
  );

  const [getKnowledgeBasesCount] = useLazyQuery(
    KnowledgeBasesCountQueryDocument,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [mutate] = useMutation(UploadKnowledgeFileWithTokenMutationDocument);

  useSubscription(KnowledgeBasesPublicSubscriptionDocument, {
    skip: loadingFileIds.length === 0,
    variables: {
      ids: loadingFileIds,
    },
    onData: ({ data }) => {
      const knowledgeBase = data.data?.knowledgeBasesPublic;
      const key = findKey(knowledgeBases, kb => kb.id === knowledgeBase?.id);

      if (knowledgeBase && key) {
        setKnowledgeBase(key, {
          id: knowledgeBase.id,
          type: knowledgeBase.__typename,
          loading: false,
          error: false,
          fileName: knowledgeBase.title,
          processState:
            knowledgeBase.__typename !== 'GoogleDocsKnowledgeBase'
              ? knowledgeBase.processState
              : null,
        });
      }
    },
  });

  const [rejectErrors, setRejectErrors] = useState<UploadError[]>([]);

  const handleRejected = (rejected: FileRejection[]) => {
    const typeErrors: string[] = [];
    const sizeErrors: string[] = [];

    rejected.forEach(file => {
      const error = file.errors[0];
      if (error.code === 'file-invalid-type') {
        typeErrors.push(file.file.name);
      }
      if (error.code === 'file-too-large') {
        sizeErrors.push(file.file.name);
      }
    });

    setRejectErrors([
      { type: UploadErrorType.type, fileNames: typeErrors },
      { type: UploadErrorType.size, fileNames: sizeErrors },
    ]);
  };

  const [maxKnowledgeBaseErrorMessage, setMaxKnowledgeBaseErrorMessage] =
    useState<ReactNode>(null);

  const handleUpload = useEventCallback(async (key: string, file: File) => {
    setLoading(true);
    setKnowledgeBase(key, { loading: true, fileName: file.name, file });

    try {
      if (
        projectData?.project?.trialExpiredAt &&
        projectData.project.maxTrialKbFileCount
      ) {
        const { data: kbCount } = await getKnowledgeBasesCount();
        if (
          kbCount?.knowledgeBases?.count &&
          kbCount.knowledgeBases.count >=
            projectData.project.maxTrialKbFileCount
        ) {
          setKnowledgeBase(key, { error: true, loading: false });
          setMaxKnowledgeBaseErrorMessage(
            intl.formatMessage(
              {
                id: 'knowledge-base.upload-file-dialog.max-knowledge-base-error',
                defaultMessage:
                  'During the trial period, you can upload only up to {docLimit} documents.\nThere are already {docNum} documents uploaded.',
                description:
                  'knowledge base upload file dialog max knowledge base error',
              },
              {
                docLimit: projectData.project.maxTrialKbFileCount,
                docNum: kbCount.knowledgeBases.count,
              },
            ),
          );
          return;
        }
      }
      await mutate({
        variables: {
          file,
          fileName: file.name,
          status: false,
        },
        onError: err => {
          setKnowledgeBase(key, { error: true, loading: false });
          toast.error(err.message);
        },
        onCompleted: ({ uploadKnowledgeFileWithToken }) => {
          const knowledgeBase = uploadKnowledgeFileWithToken?.knowledgeBase;
          const serverError = compact(uploadKnowledgeFileWithToken?.errors);

          if (serverError) {
            serverError.forEach(err => toast.error(err.message));
          }

          if (!knowledgeBase) {
            return;
          }

          setKnowledgeBase(key, {
            id: knowledgeBase.id,
            type: knowledgeBase.__typename,
            loading: false,
            error: false,
            fileName: knowledgeBase.title,
            processState:
              knowledgeBase.__typename !== 'GoogleDocsKnowledgeBase'
                ? knowledgeBase.processState
                : null,
          });
        },
      });
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }

      if (typeof err === 'string') {
        toast.error(err);
      }
    } finally {
      setLoading(false);
    }
  });

  const handleDropFile = (accepted: File[], rejected: FileRejection[]) => {
    handleRejected(rejected);
    accepted.map(file => handleUpload(v4(), file));
  };

  const handleDelete = (key: string) =>
    _setKnowledgeBases(prev => omit(prev, key));

  const handleRetry = (key: string) => {
    const file = knowledgeBases[key].file;
    if (file) {
      handleUpload(key, file);
    } else {
      // TODO: toast error. please delete file and re-upload
    }
  };

  const handleConfirm = () => {
    const data = Object.values(knowledgeBases).reduce<KnowledgeBase[]>(
      (acc, { id, type, fileName }) => {
        if (id && type && fileName) {
          return [...acc, { id, type, fileName }];
        }
        return acc;
      },
      [],
    );
    onConfirm?.(data);
  };

  const errors = useMemo<UploadError[]>(() => {
    return [
      ...rejectErrors,
      { type: UploadErrorType.process, fileNames: uploadErrors },
    ].filter(err => err.fileNames.length > 0);
  }, [uploadErrors, rejectErrors]);

  const disableConfirm =
    loading ||
    loadingFileIds.length > 0 ||
    uploadErrors.length > 0 ||
    isEmpty(knowledgeBases);

  return {
    errors,
    maxKnowledgeBaseErrorMessage,
    knowledgeBases,
    disableConfirm,
    handleConfirm,
    handleDelete,
    handleDropFile,
    handleRetry,
  };
};
