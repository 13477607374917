import { useEventCallback } from '@allganize/hooks';
import { toast } from '@allganize/ui-toast';
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client/react';
import { compact, findKey, map, omit } from 'lodash-es';
import { FunctionComponent, ReactNode, useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { AppContext } from './app-context';
import {
  ID,
  KnowledgeBaseList,
  MAX_KB_FILE_SIZE,
  maxFileSize,
  UploadBlock,
  UploadFileContext,
} from './upload-file-context';
import { UploadKnowledgeFileWithTokenMutationDocument } from '../../graphql/mutations/upload-knowledge-file-with-token-mutation';
import { KnowledgeBasesCountQueryDocument } from '../../graphql/queries/knowledge-bases-count-query';
import { ProjectMaxKBFileCountQueryDocument } from '../../graphql/queries/project-max-kb-file-count-query';
import { KnowledgeBasesPublicSubscriptionDocument } from '../../graphql/subscriptions/knowledge-bases-public-subscription';
import {
  getKBProcessStateErrorMessage,
  getMallyErrorMessageDescriptor,
} from '../utils/error';
import { getIsDocumentInput } from '../utils/misc';

interface UploadFileProviderProps {
  children?: ReactNode;
}

export const UploadFileProvider: FunctionComponent<UploadFileProviderProps> = ({
  children,
}) => {
  const intl = useIntl();
  const { data: app, accessToken } = useContext(AppContext);
  const projectId = app?.projectId || '';
  const hasDocumentUploadInput =
    !!app &&
    app?.singleActionInputs.findIndex(({ inputType, knowledgeBases }) => {
      const { isDocumentInput, selectMode } = getIsDocumentInput(
        inputType,
        knowledgeBases,
      );
      return isDocumentInput && !selectMode;
    }) >= 0;

  const [knowledgeBases, setKnowledgeBases] = useState<KnowledgeBaseList>({});
  const [uploadBlock, setUploadBlock] = useState<UploadBlock | null>(null);

  const openUploadBlockDialog = (uploadBlock: UploadBlock) =>
    setUploadBlock(uploadBlock);
  const closeUploadBlockDialog = () => setUploadBlock(null);

  const setError = (id: ID, error: ReactNode) => {
    setKnowledgeBases(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        error,
      },
    }));
  };

  const setLoading = (id: ID, loading: boolean) => {
    setKnowledgeBases(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        loading,
      },
    }));
  };

  const [mutate] = useMutation(UploadKnowledgeFileWithTokenMutationDocument, {
    onError: err =>
      toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(err))),
  });

  const { data: projectData } = useQuery(ProjectMaxKBFileCountQueryDocument, {
    skip: !hasDocumentUploadInput,
    variables: {
      where: {
        id: projectId,
      },
    },
  });

  const [getKnowledgeBasesCount] = useLazyQuery(
    KnowledgeBasesCountQueryDocument,
    {
      variables: {
        where: {
          id: projectId,
        },
      },
      fetchPolicy: 'no-cache',
    },
  );

  const knowledgeBasesIdList = map(knowledgeBases, 'id');

  useSubscription(KnowledgeBasesPublicSubscriptionDocument, {
    variables: {
      accessToken,
      ids: knowledgeBasesIdList,
    },
    skip: knowledgeBasesIdList.length === 0,
    onData: ({ data }) => {
      const kb = data.data?.knowledgeBasesPublic;
      const kbKey = findKey(
        knowledgeBases,
        knowledgeBases => knowledgeBases.id === kb?.id,
      );
      if (kb && kbKey) {
        setKnowledgeBases(prev => ({
          ...prev,
          [kbKey]: {
            ...prev[kbKey],
            ...kb,
            error: getKBProcessStateErrorMessage(kb, intl),
          },
        }));
      }
    },
  });

  const uploadFile = useEventCallback(async (id: ID, file: File) => {
    if (file.size > MAX_KB_FILE_SIZE) {
      setError(
        id,
        intl.formatMessage(
          {
            id: 'single-action.upload-file.file-max-size-error-message',
            defaultMessage: 'File too large (Max. {maxSize})',
            description: 'single action upload file max size error message',
          },
          {
            maxSize: maxFileSize,
          },
        ),
      );
      return;
    }

    setLoading(id, true);

    try {
      if (
        projectData?.project?.trialExpiredAt &&
        projectData.project.maxTrialKbFileCount
      ) {
        const { data: kbCount } = await getKnowledgeBasesCount();
        if (
          kbCount?.knowledgeBases?.count &&
          kbCount.knowledgeBases.count >=
            projectData.project.maxTrialKbFileCount
        ) {
          openUploadBlockDialog({
            docLimit: projectData.project.maxTrialKbFileCount,
            docNum: kbCount.knowledgeBases.count,
          });
          return;
        }
      }
    } catch {
      // do nothing
    }

    await mutate({
      variables: {
        where: {
          id: projectId,
        },
        file,
        fileName: file.name,
        accessToken,
      },
      onCompleted: ({ uploadKnowledgeFileWithToken }) => {
        const kb = uploadKnowledgeFileWithToken?.knowledgeBase;
        const serverError = compact(uploadKnowledgeFileWithToken?.errors)[0];
        const processStateError = getKBProcessStateErrorMessage(kb, intl);
        const error = serverError
          ? intl.formatMessage(getMallyErrorMessageDescriptor(serverError))
          : processStateError;

        setKnowledgeBases(prev => ({
          ...prev,
          [id]: {
            ...prev[id],
            ...kb,
            error,
            loading: false,
          },
        }));
      },
    });
  });

  const deleteFile = (id: ID) => {
    setKnowledgeBases(prev => omit(prev, id));
  };

  return (
    <UploadFileContext.Provider
      value={{
        knowledgeBases,
        uploadBlock,
        uploadFile,
        deleteFile,
        setError,
        closeUploadBlockDialog,
      }}
    >
      {children}
    </UploadFileContext.Provider>
  );
};
