import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { ChatFormClasses, chatFormClasses } from './chat-form-classes';

export interface ChatFormProps extends React.HTMLAttributes<HTMLDivElement> {
  classes?: Partial<ChatFormClasses>;
  header?: React.ReactNode;
}

export const ChatForm = forwardRef<HTMLDivElement, ChatFormProps>(
  (props, ref) => {
    const { children, classes, header, ...other } = props;
    const theme = useTheme();

    return (
      <div
        data-testid="chat-form"
        css={css`
          background-color: ${theme.palette.common.white};
          border-radius: ${theme.radius.md}px;
          border: 1px solid ${theme.palette.grayAlpha[200]};
          width: 292px;
          max-width: 100%;
        `}
        {...other}
        ref={ref}
        className={clsx(chatFormClasses.root, classes?.root, other.className)}
      >
        {header && (
          <div
            data-testid="chat-form__header"
            css={css`
              padding: 16px 16px 0;
            `}
            className={clsx(chatFormClasses.header, classes?.header)}
          >
            <Text variant="title14">{header}</Text>
          </div>
        )}

        <div
          css={css`
            padding: 16px;
          `}
          className={clsx(chatFormClasses.body, classes?.body)}
        >
          {children}
        </div>
      </div>
    );
  },
);
