import { Link, LinkProps } from '@allganize/ui-link';
import { css } from '@emotion/react';
import { forwardRef } from 'react';

export interface DraftLinkBaseProps extends LinkProps {}

export const DraftLinkBase = forwardRef<HTMLAnchorElement, DraftLinkBaseProps>(
  (props, ref) => {
    const { target, ...other } = props;

    return (
      <Link
        css={css`
          word-break: break-all;
        `}
        data-testid="draft-link-base"
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
        target={target}
        {...other}
        ref={ref}
      />
    );
  },
);
