import {
  ClearWaitingQueueParams,
  Id,
  ToastContent as ToastifyContent,
  ToastOptions as ToastifyOptions,
  UpdateOptions as ToastifyUpdateOptions,
  toast as toastify,
} from 'react-toastify';
import { OnChangeCallback } from 'react-toastify/dist/core';
import { ToastContent, ToastContentProps } from './toast-content';

export interface ToastOptions
  extends Omit<
    ToastifyOptions,
    | 'closeButton'
    | 'closeOnClick'
    | 'data'
    | 'draggable'
    | 'draggableDirection'
    | 'draggablePercent'
    | 'hideProgressBar'
    | 'isLoading'
    | 'progress'
    | 'rtl'
    | 'theme'
  > {
  action?: ToastifyContent;
  contentDirection?: ToastContentProps['direction'];
}

interface UpdateOptions<T = unknown> extends ToastifyUpdateOptions<T> {
  action?: ToastifyContent<T>;
  contentDirection?: ToastContentProps<T>['direction'];
}

interface Toast {
  <TData = unknown>(
    content: ToastifyContent<TData>,
    options?: ToastOptions,
  ): Id;
  success<TData = unknown>(
    content: ToastifyContent<TData>,
    options?: ToastOptions,
  ): Id;
  error<TData = unknown>(
    content: ToastifyContent<TData>,
    options?: ToastOptions,
  ): Id;
  warning<TData = unknown>(
    content: ToastifyContent<TData>,
    options?: ToastOptions,
  ): Id;
  info<TData = unknown>(
    content: ToastifyContent<TData>,
    options?: ToastOptions,
  ): Id;
  dismiss(id?: Id): void;
  clearWaitingQueue(params?: ClearWaitingQueueParams): void;
  isActive(id: Id, containerId?: Id): boolean;
  update<TData = unknown>(toastId: Id, options?: UpdateOptions<TData>): void;
  done(id: Id): void;
  onChange(callback: OnChangeCallback): () => void;
}

export const toast: Toast = ((): Toast => {
  const result = function <TData = unknown>(
    content: ToastifyContent<TData>,
    options?: ToastOptions,
  ): Id {
    return toastify<TData>(
      props =>
        typeof content === 'function' ? (
          content(props)
        ) : (
          <ToastContent<TData>
            {...props}
            action={
              typeof options?.action === 'function'
                ? options.action(props)
                : options?.action
            }
            direction={options?.contentDirection}
          >
            {content}
          </ToastContent>
        ),
      options,
    );
  };

  result.success = function <TData = unknown>(
    content: ToastifyContent<TData>,
    options?: ToastOptions,
  ): Id {
    return toastify.success<TData>(
      props =>
        typeof content === 'function' ? (
          content(props)
        ) : (
          <ToastContent<TData>
            {...props}
            action={
              typeof options?.action === 'function'
                ? options.action(props)
                : options?.action
            }
            direction={options?.contentDirection}
          >
            {content}
          </ToastContent>
        ),
      options,
    );
  };

  result.error = function <TData = unknown>(
    content: ToastifyContent<TData>,
    options?: ToastOptions,
  ): Id {
    return toastify.error<TData>(
      props =>
        typeof content === 'function' ? (
          content(props)
        ) : (
          <ToastContent<TData>
            {...props}
            action={
              typeof options?.action === 'function'
                ? options.action(props)
                : options?.action
            }
            direction={options?.contentDirection}
          >
            {content}
          </ToastContent>
        ),
      options,
    );
  };

  result.warning = function <TData = unknown>(
    content: ToastifyContent<TData>,
    options?: ToastOptions,
  ): Id {
    return toastify.warning<TData>(
      props =>
        typeof content === 'function' ? (
          content(props)
        ) : (
          <ToastContent<TData>
            {...props}
            action={
              typeof options?.action === 'function'
                ? options.action(props)
                : options?.action
            }
            direction={options?.contentDirection}
          >
            {content}
          </ToastContent>
        ),
      options,
    );
  };

  result.info = function <TData = unknown>(
    content: ToastifyContent<TData>,
    options?: ToastOptions,
  ): Id {
    return toastify.info<TData>(
      props =>
        typeof content === 'function' ? (
          content(props)
        ) : (
          <ToastContent<TData>
            {...props}
            action={
              typeof options?.action === 'function'
                ? options.action(props)
                : options?.action
            }
            direction={options?.contentDirection}
          >
            {content}
          </ToastContent>
        ),
      options,
    );
  };

  result.dismiss = toastify.dismiss;
  result.clearWaitingQueue = toastify.clearWaitingQueue;
  result.isActive = toastify.isActive;

  result.update = function <TData = unknown>(
    toastId: Id,
    options?: UpdateOptions<TData>,
  ) {
    return toastify.update(toastId, {
      ...options,
      render: options?.render
        ? props =>
            typeof options.render === 'function' ? (
              options.render(props)
            ) : (
              <ToastContent<TData>
                {...props}
                action={
                  typeof options?.action === 'function'
                    ? options.action(props)
                    : options?.action
                }
                direction={options.contentDirection}
              >
                {options.render}
              </ToastContent>
            )
        : options?.render,
    });
  };

  result.done = toastify.done;
  result.onChange = toastify.onChange;
  return result;
})();
