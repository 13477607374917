import { UseSuspenseQueryResult } from '@apollo/client/react';
import { createContext } from 'react';
import {
  ConversationQuery,
  ConversationQueryVariables,
} from '../graphql/queries/conversation-query';

export const ConversationContext = createContext<
  Pick<
    UseSuspenseQueryResult<ConversationQuery, ConversationQueryVariables>,
    'data' | 'refetch'
  >
>({
  data: {
    __typename: 'UserQuery',
    conversation: null,
  },
  refetch: () =>
    Promise.reject('refetch called outside ChatListContext provider'),
});
