import { createContext } from 'react';
import {
  ContactAgentByEmailValidation,
  defaultContactAgentByEmailValidation,
} from '../contact-agent-by-email-chat/contact-agent-by-email-validation';
import {
  FormValueValidation,
  defaultFormValueValidation,
} from '../form-value/form-value-validation';

export interface ValidationContextValue {
  contactAgentByEmail: ContactAgentByEmailValidation;
  formValue: FormValueValidation;
}

export const ValidationContext = createContext<ValidationContextValue>({
  contactAgentByEmail: defaultContactAgentByEmailValidation,
  formValue: defaultFormValueValidation,
});
