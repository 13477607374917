import { OverridableComponent } from '@allganize/types';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import MuiListSubheader from '@mui/material/ListSubheader';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { listSubheaderClasses } from './list-subheader-classes';
import { ListSubheaderTypeMap } from './list-subheader-type-map';

// @ts-expect-error overridable component
export const ListSubheader: OverridableComponent<ListSubheaderTypeMap> =
  forwardRef((props, ref) => {
    const {
      classes,
      color = 'default',
      disableGutters = false,
      disableSticky = false,
      inset = false,
      ...other
    } = props;
    const theme = useTheme();

    return (
      <MuiListSubheader
        data-testid="list-subheader"
        css={[
          css`
            ${theme.typography.title12}
            line-height: 36px;
          `,
          color === 'default' &&
            css`
              color: ${theme.palette.text.secondary};
            `,
          color === 'primary' &&
            css`
              color: ${theme.palette.primary.main};
            `,
          color === 'inherit' &&
            css`
              color: inherit;
            `,
          !disableSticky &&
            css`
              background-color: ${theme.palette.background.paper};
            `,
          !disableGutters &&
            css`
              padding-left: 12px;
              padding-right: 12px;
            `,
          inset === 'large' &&
            css`
              padding-left: 56px;
            `,
          inset === 'small' &&
            css`
              padding-left: 40px;
            `,
        ]}
        classes={classes}
        color={color}
        disableGutters={disableGutters}
        disableSticky={disableSticky}
        {...other}
        ref={ref}
        className={clsx(
          {
            [listSubheaderClasses.insetLarge]: inset === 'large',
            [listSubheaderClasses.insetSmall]: inset === 'small',
            [classes?.insetLarge ?? '']: inset === 'large',
            [classes?.insetSmall ?? '']: inset === 'small',
          },
          other.className,
        )}
      />
    );
  });
