import { IcCalendar } from '@allganize/ui-icons';
import { resolveComponentProps } from '@mui/base/utils';
import { forwardRef } from 'react';
import { DateTimeField } from '../date-time-field';
import { useDateTimePickerDefaultizedProps } from '../date-time-picker/date-time-picker.shared';
import { renderDesktopDateTimeView } from '../date-time-view-renderers';
import { renderDateViewCalendar } from '../date-view-renderers';
import { useDesktopPicker } from '../internals/hooks/use-desktop-picker';
import { PickerViewRendererLookup } from '../internals/hooks/use-picker';
import { useLocaleText, useUtils } from '../internals/hooks/use-utils';
import { DateOrTimeViewWithMeridiem } from '../internals/models';
import {
  resolveDateTimeFormat,
  resolveTimeViewsResponse,
} from '../internals/utils/date-time-utils';
import { extractValidationProps } from '../internals/utils/validation/extract-validation-props';
import { validateDateTime } from '../internals/utils/validation/validate-date-time';
import { singleItemValueManager } from '../internals/utils/value-managers';
import { PickersActionBarAction } from '../pickers-action-bar';
import { DesktopDateTimePickerProps } from './desktop-date-time-picker-type-map';

export const DesktopDateTimePicker = forwardRef<
  HTMLDivElement,
  DesktopDateTimePickerProps
>((inProps, ref) => {
  const localeText = useLocaleText<Date>();
  const utils = useUtils<Date>();

  // Props with the default values common to all date time pickers
  const defaultizedProps = useDateTimePickerDefaultizedProps<
    DateOrTimeViewWithMeridiem,
    DesktopDateTimePickerProps
  >(inProps, 'MuiDesktopDateTimePicker');

  const {
    shouldRenderTimeInASingleColumn,
    thresholdToRenderTimeInASingleColumn,
    views,
    timeSteps,
  } = resolveTimeViewsResponse<Date>(defaultizedProps);
  const shouldUseNewRenderer =
    !defaultizedProps.viewRenderers ||
    Object.keys(defaultizedProps.viewRenderers).length === 0;

  const viewRenderers: PickerViewRendererLookup<
    Date | null,
    DateOrTimeViewWithMeridiem,
    any,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  > =
    // we can only ensure the expected two-column layout if none of the renderers are overridden
    shouldUseNewRenderer
      ? {
          day: renderDesktopDateTimeView,
          month: renderDesktopDateTimeView,
          year: renderDesktopDateTimeView,
          hours: renderDesktopDateTimeView,
          minutes: renderDesktopDateTimeView,
          seconds: renderDesktopDateTimeView,
          meridiem: renderDesktopDateTimeView,
        }
      : {
          day: renderDateViewCalendar,
          month: renderDateViewCalendar,
          year: renderDateViewCalendar,
          hours: null,
          minutes: null,
          seconds: null,
          meridiem: null,
          ...defaultizedProps.viewRenderers,
        };
  const ampmInClock = defaultizedProps.ampmInClock ?? true;
  // add "accept" action only when the new date time view renderers are used
  const actionBarActions: PickersActionBarAction[] = shouldUseNewRenderer
    ? ['accept']
    : [];

  // Props with the default values specific to the desktop variant
  const props = {
    ...defaultizedProps,
    viewRenderers,
    format: resolveDateTimeFormat(utils, defaultizedProps),
    views,
    yearsPerRow: defaultizedProps.yearsPerRow ?? 4,
    ampmInClock,
    timeSteps,
    thresholdToRenderTimeInASingleColumn,
    shouldRenderTimeInASingleColumn,
    slots: {
      field: DateTimeField,
      openPickerIcon: IcCalendar,
      ...defaultizedProps.slots,
    },
    slotProps: {
      ...defaultizedProps.slotProps,
      field: (ownerState: any) => ({
        ...resolveComponentProps(defaultizedProps.slotProps?.field, ownerState),
        ...extractValidationProps(defaultizedProps),
        ref,
      }),
      toolbar: {
        hidden: true,
        ampmInClock,
        toolbarVariant: shouldUseNewRenderer ? 'desktop' : 'mobile',
        ...defaultizedProps.slotProps?.toolbar,
      },
      tabs: {
        hidden: true,
        ...defaultizedProps.slotProps?.tabs,
      },
      actionBar: {
        actions: actionBarActions,
        ...defaultizedProps.slotProps?.actionBar,
      },
    },
  };

  const { renderPicker } = useDesktopPicker<
    DateOrTimeViewWithMeridiem,
    // @ts-expect-error internal prop
    typeof props
  >({
    props,
    valueManager: singleItemValueManager,
    valueType: 'date-time',
    getOpenDialogAriaText: localeText.openDatePickerDialogue,
    validator: validateDateTime,
  });

  return renderPicker();
});
