import { DraftPluginFunctions, EditorState } from '@allganize/draft-input';
import { noop } from 'lodash-es';
import { createContext } from 'react';

export interface DraftToolbarContextValue extends DraftPluginFunctions {
  editorState: EditorState;
}

export const DraftToolbarFocusContext =
  createContext<DraftToolbarContextValue['focus']>(noop);
export const DraftToolbarBlurContext =
  createContext<DraftToolbarContextValue['blur']>(noop);
export const DraftToolbarEditorStateContext = createContext<
  DraftToolbarContextValue['editorState']
>(EditorState.createEmpty());
export const DraftToolbarEditorStateGetterContext = createContext<
  DraftToolbarContextValue['getEditorState']
>(() => {
  return EditorState.createEmpty();
});
export const DraftToolbarEditorStateSetterContext =
  createContext<DraftToolbarContextValue['setEditorState']>(noop);
export const DraftToolbarDisabledGetterContext = createContext<
  DraftToolbarContextValue['getDisabled']
>(() => {
  return false;
});
