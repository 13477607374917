import {
  AtomicBlockUtils,
  ContentBlock,
  DraftHandleValue,
  DraftPlugin,
  DraftPluginFunctions,
  EditorState,
  RichUtils,
} from '@allganize/draft-input';
import { DraftVideo } from './draft-video';
import { DraftVideoClasses } from './draft-video-classes';

export interface DraftVideoPluginOptions {
  classes?: Partial<DraftVideoClasses>;
}

export class DraftVideoPlugin extends DraftPlugin {
  public static readonly ENTITY_TYPE = 'draft-js-video-plugin-video';

  public static getYoutubeSrc(url: string) {
    const match = url.match(
      /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
    );

    if (match) {
      const id = match[1];

      return {
        srcID: id,
        srcType: 'youtube' as const,
        url,
      };
    }

    return null;
  }

  public static getVimeoSrc(url: string) {
    const match = url.match(
      /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/,
    );

    if (match) {
      const id = match[3];

      return {
        srcID: id,
        srcType: 'vimeo' as const,
        url,
      };
    }

    return null;
  }

  public static getSrc(src: string) {
    if (!src) {
      return null;
    }

    try {
      const youtube = DraftVideoPlugin.getYoutubeSrc(src);

      if (youtube) {
        return `https://www.youtube.com/embed/${youtube.srcID}`;
      }

      const vimeo = DraftVideoPlugin.getVimeoSrc(src);

      if (vimeo) {
        return `https://player.vimeo.com/video/${vimeo.srcID}`;
      }
    } catch {
      // nothing to do
    }

    return null;
  }

  public static addVideo(editorState: EditorState, src: string): EditorState {
    if (RichUtils.getCurrentBlockType(editorState) === 'atomic') {
      return editorState;
    }

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      DraftVideoPlugin.ENTITY_TYPE,
      'IMMUTABLE',
      { src },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
  }

  constructor(private options: DraftVideoPluginOptions = {}) {
    super();
  }

  public blockRendererFn(
    block: ContentBlock,
    { getEditorState }: DraftPluginFunctions,
  ) {
    if (block.getType() !== 'atomic') {
      return null;
    }

    const contentState = getEditorState().getCurrentContent();
    const entityKey = block.getEntityAt(0);

    if (!entityKey) {
      return null;
    }

    const entity = contentState.getEntity(entityKey);

    if (!entity) {
      return null;
    }

    const type = entity.getType();

    if (type !== DraftVideoPlugin.ENTITY_TYPE) {
      return null;
    }

    const data = entity.getData();

    return {
      component: DraftVideo,
      editable: false,
      props: {
        data,
        src: DraftVideoPlugin.getSrc(data?.src),
        classes: this.options.classes,
      },
    };
  }

  public handleKeyCommand(
    command: string,
    editorState: EditorState,
    eventTimeStamp: number,
    { setEditorState }: DraftPluginFunctions,
  ): DraftHandleValue {
    if (command === 'backspace') {
      const newState = RichUtils.handleKeyCommand(editorState, command);

      if (newState) {
        setEditorState(newState);
        return 'handled';
      }
    }

    return 'not-handled';
  }
}
