import { css } from '@emotion/react';
import MuiDialogActions from '@mui/material/DialogActions';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { dialogTitleClasses } from '../dialog-title';
import { dialogActionsClasses } from './dialog-actions-classes';
import { DialogActionsProps } from './dialog-actions-type-map';

export const DialogActions = forwardRef<HTMLDivElement, DialogActionsProps>(
  (props, ref) => {
    const { classes, disableSpacing = false, ...other } = props;

    return (
      <MuiDialogActions
        data-testid="dialog-actions"
        css={[
          css`
            padding: 20px 24px;

            .${dialogTitleClasses.root} + & {
              padding-top: 0;
            }
          `,
          !disableSpacing &&
            css`
              & > :not(style) ~ :not(style) {
                margin-left: 8px;
              }
            `,
        ]}
        classes={classes}
        disableSpacing
        {...other}
        ref={ref}
        className={clsx(
          {
            [dialogActionsClasses.spacing]: !disableSpacing,
            [classes?.spacing ?? '']: !disableSpacing,
          },
          other.className,
        )}
      />
    );
  },
);
