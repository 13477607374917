export const getCookieOptions = (): CookieAttributes => {
  const isLocalEnvironment =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';

  // Turn off cookie options for a localhost & file protocol
  // chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=1056543&q=localhost%20secure%20cookie&can=2)
  if (isLocalEnvironment) {
    return { expires: 365 };
  }

  return {
    expires: 365,
    sameSite: 'None',
    secure: true,
  };
};
