import {
  PickersInputLocaleText,
  locales as datePickerlocale,
} from '@allganize/ui-date-picker';
import type { Locale as DateFnsLocale } from 'date-fns';
import DateFnsEn from 'date-fns/locale/en-US';
import DateFnsJa from 'date-fns/locale/ja';
import DateFnsKo from 'date-fns/locale/ko';
import en from './en.json';
import ja from './ja.json';
import ko from './ko.json';

export const locales = { en, ja, ko };
export type MessageIds = keyof typeof en | keyof typeof ja | keyof typeof ko;
export type Locale = keyof typeof locales;
export const defaultLocale: Locale = 'en';
export const LOCALES: Locale[] = Object.keys(locales) as Locale[];

export const dateFnsLocales: Record<Locale, DateFnsLocale> = {
  en: DateFnsEn,
  ja: DateFnsJa,
  ko: DateFnsKo,
};

export const datePickerLocales: Record<Locale, PickersInputLocaleText> = {
  en: datePickerlocale.enUS,
  ja: datePickerlocale.jaJP,
  ko: datePickerlocale.koKR,
};
