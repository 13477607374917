import { headerStyleMap } from './header-style-map';

export type DraftTextSizeInlineStyleKey =
  | 'HEADER_ONE'
  | 'HEADER_TWO'
  | 'HEADER_THREE'
  | 'HEADER_FOUR'
  | 'HEADER_FIVE'
  | 'HEADER_SIX';

export const draftTextSizeInlineStyles = {
  HEADER_ONE: headerStyleMap['header-one'],
  HEADER_TWO: headerStyleMap['header-two'],
  HEADER_THREE: headerStyleMap['header-three'],
  HEADER_FOUR: headerStyleMap['header-four'],
  HEADER_FIVE: headerStyleMap['header-five'],
  HEADER_SIX: headerStyleMap['header-six'],
} satisfies Record<DraftTextSizeInlineStyleKey, React.CSSProperties>;

export const draftTextSizeInlineStyleLabels: Record<
  DraftTextSizeInlineStyleKey,
  string
> = {
  HEADER_ONE: 'h1',
  HEADER_TWO: 'h2',
  HEADER_THREE: 'h3',
  HEADER_FOUR: 'h4',
  HEADER_FIVE: 'h5',
  HEADER_SIX: 'h6',
};
