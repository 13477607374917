/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { ErrorFragment } from '../fragments/error-fragment';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UserLoginMutationVariables = UserQueryTypes.Exact<{
  oktaSsoIdToken?: UserQueryTypes.InputMaybe<
    UserQueryTypes.Scalars['String']['input']
  >;
  userEntraIdToken?: UserQueryTypes.InputMaybe<
    UserQueryTypes.Scalars['String']['input']
  >;
}>;

export type UserLoginMutation = { __typename: 'UserMutation' } & {
  userLogin: UserQueryTypes.Maybe<
    { __typename: 'UserLogin' } & Pick<UserQueryTypes.UserLogin, 'token'> & {
        errors: UserQueryTypes.Maybe<
          Array<UserQueryTypes.Maybe<{ __typename: 'Error' } & ErrorFragment>>
        >;
      }
  >;
};

export const UserLoginMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserLoginMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'oktaSsoIdToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userEntraIdToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLogin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'oktaSsoIdToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'oktaSsoIdToken' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userEntraIdToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userEntraIdToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ErrorFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ErrorFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'field' } },
          { kind: 'Field', name: { kind: 'Name', value: 'info' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserLoginMutation, UserLoginMutationVariables>;
