import generateUtilityClasses from '@mui/material/generateUtilityClasses';
import { ChipBaseClassKey } from '../chip-base';

export type ChipClassKey =
  | ChipBaseClassKey
  | 'colorGrayDark'
  | 'colorGraySubtle';
export type ChipClasses = Record<ChipClassKey, string>;

export const chipClasses: ChipClasses = generateUtilityClasses('Chip', [
  'root',
  'disabled',
  'clickable',
  'deletable',
  'avatar',
  'icon',
  'label',
  'deleteIcon',
  'focusVisible',
  'colorGrayDark',
  'colorGraySubtle',
]);
