import { FunctionComponent, useMemo } from 'react';
import { Theme } from './theme';
import { ThemeContext } from './theme-context';
import { useTheme } from './use-theme';

export interface ThemeProviderProps {
  theme?: Partial<Theme> | ((outerTheme: Theme) => Theme);
  children: React.ReactNode;
}

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({
  theme: themeProp,
  children,
}) => {
  const contextTheme = useTheme();

  const theme = useMemo(() => {
    if (contextTheme === themeProp) {
      return contextTheme;
    }

    if (typeof themeProp === 'function') {
      return themeProp(contextTheme);
    }

    return {
      ...contextTheme,
      ...themeProp,
    };
  }, [contextTheme, themeProp]);

  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};
