import { noop } from 'lodash-es';
import { createContext, ReactNode } from 'react';
import { SingleActionInputKnowledgeBaseFragment } from '../../graphql/fragments/single-action-input-knowledge-base-fragment';
import { filesize } from '../utils/filesize';

export const MAX_KB_FILE_SIZE = 50 * 1048576;
export const maxFileSize = filesize(MAX_KB_FILE_SIZE);

export type ID = string | number;

export type KnowledgeBase = {
  loading: boolean;
  error: ReactNode;
} & SingleActionInputKnowledgeBaseFragment;

export type KnowledgeBaseList = Record<ID, KnowledgeBase>;

export type UploadBlock = {
  docLimit: number;
  docNum: number;
};

interface UploadFile {
  knowledgeBases: KnowledgeBaseList;
  uploadBlock: UploadBlock | null;
  uploadFile: (id: ID, file: File) => void;
  deleteFile: (id: ID) => void;
  setError: (id: ID, error: ReactNode) => void;
  closeUploadBlockDialog: () => void;
}

export const UploadFileContext = createContext<UploadFile>({
  knowledgeBases: {},
  uploadBlock: null,
  uploadFile: noop,
  deleteFile: noop,
  setError: noop,
  closeUploadBlockDialog: noop,
});
