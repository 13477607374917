import { useProject } from '@allganize/alli-app-market-project';
import {
  AlliBrandingProps,
  AlliBranding as AlliBrandingUI,
} from '@allganize/alli-sdk-ui';
import clsx from 'clsx';
import { FunctionComponent } from 'react';

export const AlliBranding: FunctionComponent<AlliBrandingProps> = ({
  className,
  ...others
}) => {
  const { hideAlliBranding } = useProject();

  if (hideAlliBranding) {
    return null;
  }

  return (
    <AlliBrandingUI className={clsx(className, 'alli-branding')} {...others} />
  );
};
