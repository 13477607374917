import { DraftInputRef } from '@allganize/draft-input';
import { forwardRef } from 'react';
import { RichText, RichTextProps } from '../rich-text/rich-text';
import { UseChatMediaOptions, useChatMedia } from './use-chat-media';

export interface ChatMediaProps
  extends Omit<RichTextProps, 'plugins' | 'value'>,
    Pick<UseChatMediaOptions, 'media' | 'isOptionType'> {}

export const ChatMedia = forwardRef<DraftInputRef, ChatMediaProps>(
  ({ media, isOptionType, ...other }, ref) => {
    const { contentState } = useChatMedia({ media, isOptionType });
    return <RichText {...other} value={contentState} ref={ref} />;
  },
);
