/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type KnowledgeBasesCountQueryVariables = UserQueryTypes.Exact<{
  [key: string]: never;
}>;

export type KnowledgeBasesCountQuery = { __typename: 'UserQuery' } & {
  knowledgeBases: UserQueryTypes.Maybe<
    { __typename: 'KnowledgeBaseList' } & Pick<
      UserQueryTypes.KnowledgeBaseList,
      'count'
    >
  >;
};

export const KnowledgeBasesCountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'KnowledgeBasesCountQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'knowledgeBases' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  KnowledgeBasesCountQuery,
  KnowledgeBasesCountQueryVariables
>;
