import { LoadingButton } from '@allganize/ui-button';
import { IcError } from '@allganize/ui-icons';
import { InputAdornment, InputField } from '@allganize/ui-input';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  UseDraftToolbarLinkFormOptions,
  useDraftToolbarLinkForm,
} from './use-draft-toolbar-link-form';

interface DraftToolbarLinkFormProps
  extends UseDraftToolbarLinkFormOptions,
    Omit<React.FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> {}

export const DraftToolbarLinkForm = forwardRef<
  HTMLFormElement,
  DraftToolbarLinkFormProps
>((props, ref) => {
  const { defaultValues, onSubmit, ...other } = props;
  const intl = useIntl();
  const {
    form: {
      control,
      formState: { isSubmitting, isValid },
      handleSubmit,
    },
    submit,
  } = useDraftToolbarLinkForm({ defaultValues, onSubmit });

  return (
    <form {...other} ref={ref} onSubmit={handleSubmit(submit)}>
      <div
        css={css`
          display: flex;
          gap: 8px;
          padding: 12px;
        `}
      >
        <Controller
          control={control}
          name="url"
          render={({
            field: { ref: fieldRef, ...field },
            fieldState,
            formState,
          }) => {
            const errorMessage =
              fieldState.error?.message || formState.errors.root?.message;

            return (
              <InputField
                {...field}
                inputRef={fieldRef}
                autoFocus
                required
                fullWidth
                label={null}
                error={!formState.isValid}
                placeholder={intl.formatMessage({
                  id: 'CampaignBuild.Sidebar.TextArea.LinkButton.URL',
                  defaultMessage: 'URL',
                  description:
                    'Text area insert link form url placeholder text',
                })}
                InputProps={{
                  endAdornment: errorMessage && (
                    <InputAdornment position="end">
                      <Tooltip color="error" title={errorMessage}>
                        <IcError color="error" />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
        />

        <LoadingButton
          variant="filled"
          color="primary"
          type="submit"
          size="large"
          loading={isSubmitting}
          disabled={!isValid}
        >
          <FormattedMessage
            id="CampaignBuild.Sidebar.TextArea.LinkButton.SubmitButton"
            defaultMessage="Submit"
            description="Text area insert link form submit button text"
          />
        </LoadingButton>
      </div>
    </form>
  );
});
