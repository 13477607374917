import { OverridableComponent } from '@allganize/types';
import MuiTableFooter from '@mui/material/TableFooter';
import { forwardRef } from 'react';
import { TableFooterTypeMap } from './table-footer-type-map';

// @ts-expect-error overridable component
export const TableFooter: OverridableComponent<TableFooterTypeMap> = forwardRef(
  (props, ref) => {
    return <MuiTableFooter data-testid="table-footer" {...props} ref={ref} />;
  },
);
