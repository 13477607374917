import {
  ListItem,
  ListItemButton,
  listItemButtonClasses,
} from '@allganize/ui-list';
import clsx from 'clsx';
import { GroupBase, OptionProps } from 'react-select';
import { selectClasses } from './select-classes';

export const Option = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: OptionProps<Option, IsMulti, Group>,
) => {
  const {
    children,
    className,
    getClassNames,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
  } = props;

  return (
    <ListItem
      disablePadding
      component="div"
      {...innerProps}
      ref={innerRef}
      className={clsx(
        selectClasses.option,
        {
          [selectClasses.focused]: isFocused,
          [selectClasses.selected]: isSelected,
          [selectClasses.disabled]: isDisabled,
        },
        getClassNames('option', props),
        className,
        innerProps?.className,
      )}
    >
      <ListItemButton
        component="div"
        disabled={isDisabled}
        selected={isSelected}
        className={clsx({ [listItemButtonClasses.focusVisible]: isFocused })}
      >
        {children}
      </ListItemButton>
    </ListItem>
  );
};
