import styled from '@emotion/styled';

export const Layout = styled.div`
  width: 100%;
  height: 100%;
`;

export const Grow = styled.div`
  flex-grow: 1;
`;
