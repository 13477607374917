import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type LoadingButtonClassKey = 'root' | 'loading' | 'loadingIndicator';
export type LoadingButtonClasses = Record<LoadingButtonClassKey, string>;

export const loadingButtonClasses: LoadingButtonClasses =
  generateUtilityClasses('LoadingButton', [
    'root',
    'loading',
    'loadingIndicator',
  ]);
