import { Dialog, DialogContent, dialogClasses } from '@allganize/ui-dialog';
import styled from '@emotion/styled';
import { DIALOG_WIDTH } from '../internals/constants';

export const PickersModalDialogRoot = styled(Dialog)`
  .${dialogClasses.container} {
    outline: 0;
  }

  .${dialogClasses.paper} {
    outline: 0;
    min-width: ${DIALOG_WIDTH}px;
  }
`;

export const PickersModalDialogContent = styled(DialogContent)`
  &:first-of-type {
    padding: 0;
  }
`;
