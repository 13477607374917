import { Drawer, drawerClasses } from '@allganize/ui-drawer';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { documentViewerSidebarWidth } from './constants';
import { documentViewerSidebarClasses } from './document-viewer-sidebar-classes';
import { DocumentViewerSidebarProps } from './document-viewer-sidebar-type-map';

export const DocumentViewerSidebar = forwardRef<
  HTMLDivElement,
  DocumentViewerSidebarProps
>((props, ref) => {
  const {
    anchor = 'left',
    classes,
    open,
    variant = 'temporary',
    ...other
  } = props;
  const theme = useTheme();

  return (
    <Drawer
      data-testid="document-viewer-sidebar"
      css={[
        css`
          width: 0;
          transition: ${theme.transitions.create('width', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.leavingScreen,
          })};

          &.${drawerClasses.modal} {
            position: absolute;
          }

          .${drawerClasses.paper} {
            position: absolute;
            width: ${documentViewerSidebarWidth}px;
            background-color: ${theme.palette.grey[100]};
            color: ${theme.palette.text.primary};
          }

          .${drawerClasses.paperAnchorLeft} {
            border-right: 1px solid ${theme.palette.grayAlpha[200]};
          }

          .${drawerClasses.paperAnchorTop} {
            border-bottom: 1px solid ${theme.palette.grayAlpha[200]};
          }

          .${drawerClasses.paperAnchorRight} {
            border-left: 1px solid ${theme.palette.grayAlpha[200]};
          }

          .${drawerClasses.paperAnchorBottom} {
            border-top: 1px solid ${theme.palette.grayAlpha[200]};
          }
        `,
        open &&
          css`
            &.${drawerClasses.docked} {
              width: ${documentViewerSidebarWidth}px;
              transition: ${theme.transitions.create('width', {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.enteringScreen,
              })};
            }
          `,
      ]}
      anchor={anchor}
      elevation={0}
      hideBackdrop
      open={open}
      variant={variant}
      {...other}
      ref={ref}
      className={clsx(
        documentViewerSidebarClasses.root,
        classes?.root,
        other.className,
      )}
    />
  );
});
