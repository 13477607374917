import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  listItemButtonClasses,
  listItemIconClasses,
  listItemTextClasses,
} from '@allganize/ui-list';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

const MenuLink = styled(NavLink)`
  text-decoration: none;

  .${listItemButtonClasses.root} {
    border-radius: ${props => props.theme.radius.sm}px;

    &.${listItemButtonClasses.selected} {
      background-color: ${props =>
        props.theme.palette.unstable_background.white};
    }
  }

  .${listItemIconClasses.root} {
    color: ${props => props.theme.palette.foreground.secondary};
  }

  .${listItemTextClasses.root} {
    color: ${props => props.theme.palette.foreground.default};
  }
`;

interface MenuListItemProps extends Omit<NavLinkProps, 'children'> {
  children?: ReactNode;
  icon?: ReactNode;
}

export const MenuListItem = ({
  icon,
  children,
  ...others
}: MenuListItemProps) => {
  return (
    <MenuLink {...others}>
      {({ isActive }) => (
        <ListItem disablePadding>
          <ListItemButton selected={isActive}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText>{children}</ListItemText>
          </ListItemButton>
        </ListItem>
      )}
    </MenuLink>
  );
};
