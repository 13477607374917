import { useDraftToolbarFocus } from '@allganize/draft-toolbar-plugin';
import { useCombinedRef, useId } from '@allganize/hooks';
import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { IcVideoLibrary } from '@allganize/ui-icons';
import { Popover, PopoverOrigin, popoverClasses } from '@allganize/ui-modal';
import { Tooltip } from '@allganize/ui-tooltip';
import { raf } from '@allganize/utils-timeout';
import { css } from '@emotion/react';
import { Fragment, forwardRef, memo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DraftToolbarVideoForm } from './draft-toolbar-video-form';

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const transformOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

interface DraftToolbarVideoButtonProps extends IconButtonProps {}

export const DraftToolbarVideoButton = memo(
  forwardRef<HTMLButtonElement, DraftToolbarVideoButtonProps>((props, ref) => {
    const focus = useDraftToolbarFocus();
    const [popoverOpen, setPopoverOpen] = useState(false);
    const popoverId = useId();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const combinedRef = useCombinedRef(buttonRef, ref);

    const closePopover = () => {
      setPopoverOpen(false);
      focus();
    };

    const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      setPopoverOpen(prev => !prev);
      props.onClick?.(ev);
    };

    const handleFormSubmit = async () => {
      await raf();
      closePopover();
    };

    return (
      <Fragment>
        <Tooltip
          title={
            <FormattedMessage
              id="TextArea.VideoButton.Tooltip"
              defaultMessage="Insert Video"
              description="Text area insert video button tooltip text"
            />
          }
        >
          <IconButton
            aria-owns={popoverOpen ? popoverId : undefined}
            aria-haspopup="true"
            size="small"
            {...props}
            ref={combinedRef}
            onClick={handleClick}
          >
            <IcVideoLibrary fontSize="small" />
          </IconButton>
        </Tooltip>

        <Popover
          id={popoverId}
          open={popoverOpen}
          anchorEl={buttonRef.current}
          onClose={closePopover}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          css={css`
            .${popoverClasses.paper} {
              width: 320px;
            }
          `}
        >
          <DraftToolbarVideoForm onSubmit={handleFormSubmit} />
        </Popover>
      </Fragment>
    );
  }),
);
