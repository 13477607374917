interface EntraIdAuthClientOptions {
  loginUrl: string;
  samlRequest?: string;
}

export class EntraIdAuthClient {
  private loginUrl: string;
  private samlRequest?: string;

  constructor(options: EntraIdAuthClientOptions) {
    this.loginUrl = options.loginUrl;
    this.samlRequest = options.samlRequest;
  }

  public setSamlRequest(samlRequest: string) {
    this.samlRequest = samlRequest;
  }

  public signInWithRedirect(relayState?: string) {
    if (!this.samlRequest) {
      throw new Error('SAMLRequest is not set');
    }

    const url = new URL(this.loginUrl);
    url.searchParams.set('SAMLRequest', this.samlRequest);

    if (relayState) {
      url.searchParams.set('RelayState', relayState);
    }

    window.location.href = url.toString();
  }
}
