import { Button } from '@allganize/ui-button';
import { DialogActions } from '@allganize/ui-dialog';
import { forwardRef } from 'react';
import { useLocaleText } from '../internals/hooks/use-utils';
import { PickersActionBarProps } from './pickers-action-bar-type-map';

export const PickersActionBar = forwardRef<
  HTMLDivElement,
  PickersActionBarProps
>((props, ref) => {
  const { onAccept, onClear, onCancel, onSetToday, actions, ...other } = props;
  const localeText = useLocaleText<Date>();

  if (actions == null || actions.length === 0) {
    return null;
  }

  const buttons = actions?.map(actionType => {
    switch (actionType) {
      case 'clear':
        return (
          <Button
            data-testid="pickers-action-bar__clear"
            onClick={onClear}
            key={actionType}
            size="large"
          >
            {localeText.clearButtonLabel}
          </Button>
        );

      case 'cancel':
        return (
          <Button
            data-testid="pickers-action-bar__cancel"
            onClick={onCancel}
            key={actionType}
            variant="outlined"
            size="large"
          >
            {localeText.cancelButtonLabel}
          </Button>
        );

      case 'accept':
        return (
          <Button
            data-testid="pickers-action-bar__accept"
            onClick={onAccept}
            key={actionType}
            variant="filled"
            color="primary"
            size="large"
          >
            {localeText.okButtonLabel}
          </Button>
        );

      case 'today':
        return (
          <Button
            data-testid="pickers-action-bar__today"
            onClick={onSetToday}
            key={actionType}
            size="large"
          >
            {localeText.todayButtonLabel}
          </Button>
        );

      default:
        return null;
    }
  });
  return (
    <DialogActions data-testid="pickers-action-bar" {...other} ref={ref}>
      {buttons}
    </DialogActions>
  );
});
