import { useTheme } from '@allganize/ui-theme';
import MuiCollapse from '@mui/material/Collapse';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import { forwardRef } from 'react';
import { CollapseProps } from './collapse-type-map';

export const Collapse = forwardRef<unknown, CollapseProps>((props, ref) => {
  const theme = useTheme();
  const muiTheme = useMuiTheme();

  return (
    <ThemeProvider theme={{ ...muiTheme, transitions: theme.transitions }}>
      <MuiCollapse data-testid="collapse" {...props} ref={ref} />
    </ThemeProvider>
  );
});
