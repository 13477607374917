import { FunctionComponent, useEffect } from 'react';
import { useAlliClient } from '../alli-client/use-alli-client';
import {
  UseConversationOptions,
  useConversation,
} from '../hooks/use-conversation';
import { ConversationContext } from './conversation-context';

interface ConversationProviderProps extends UseConversationOptions {
  children?: React.ReactNode;
}

export const ConversationProvider: FunctionComponent<
  ConversationProviderProps
> = props => {
  const client = useAlliClient();
  const conversation = useConversation(props);

  useEffect(() => {
    if (conversation.data?.conversation?.user?.ownUserId) {
      client.authClient.persistedUserIdManager.setUserId(
        conversation.data?.conversation?.user?.ownUserId,
      );
    }
  }, [
    client.authClient.persistedUserIdManager,
    conversation.data?.conversation?.user?.ownUserId,
  ]);

  return (
    <ConversationContext.Provider value={conversation}>
      {props.children}
    </ConversationContext.Provider>
  );
};
