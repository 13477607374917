import { useCombinedRef } from '@allganize/hooks';
import { OverridableComponent } from '@allganize/types';
import { forwardRef, useImperativeHandle } from 'react';
import { TruncateTypeMap } from './truncate-type-map';
import { useTruncate } from './use-truncate';

// @ts-expect-error overridable component
export const Truncate: OverridableComponent<TruncateTypeMap> = forwardRef(
  (props, ref) => {
    const {
      // @ts-expect-error pass through as
      component: Component = 'div',
      truncateRef,
      ...other
    } = props;
    const { ref: rootRef, truncate } = useTruncate(props);
    const combinedRef = useCombinedRef(rootRef, ref);
    useImperativeHandle(truncateRef, () => ({ truncate }), [truncate]);
    return <Component {...other} ref={combinedRef} />;
  },
);
