import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type DocumentViewerPageStateClassKey = 'root' | 'loading' | 'error';
export type DocumentViewerPageStateClasses = Record<
  DocumentViewerPageStateClassKey,
  string
>;

export const documentViewerPageStateClasses: DocumentViewerPageStateClasses =
  generateUtilityClasses('DocumentViewerPageState', [
    'root',
    'loading',
    'error',
  ]);
