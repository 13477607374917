export interface ContactAgentByEmailValidation {
  attachments: {
    maxCount: number;
    maxSize: number;
  };
}

export const defaultContactAgentByEmailValidation: ContactAgentByEmailValidation =
  {
    attachments: {
      maxCount: 5,
      maxSize: 0,
    },
  };
