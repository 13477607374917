import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { ReactMarkdownProps } from 'react-markdown/lib/ast-to-react';
import { markdownClasses } from '../markdown/markdown-classes';

export const Blockquote = forwardRef<
  HTMLQuoteElement,
  React.BlockquoteHTMLAttributes<HTMLQuoteElement> & ReactMarkdownProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, ...other } = props;
  const theme = useTheme();

  return (
    <blockquote
      css={css`
        margin: 0;
        padding-left: 12px;
        color: ${theme.palette.foreground.default};
        border-left: 4px solid
          ${theme.palette.unstable_background.graySubtle.alpha};
      `}
      {...other}
      ref={ref}
      className={clsx(markdownClasses.blockquote, other.className)}
    />
  );
});
