import {
  AlliBrandingProps,
  AlliBranding as AlliBrandingUI,
} from '@allganize/alli-sdk-ui';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { useAlliClient } from '../alli-client/use-alli-client';
import { useProject } from '../project/use-project';

export const AlliBranding: FunctionComponent<AlliBrandingProps> = ({
  className,
  ...others
}) => {
  const client = useAlliClient();
  const { project } = useProject();
  const hide = project?.hideAlliBranding || client.entryType === 'market';

  if (hide) {
    return null;
  }

  return (
    <AlliBrandingUI className={clsx(className, 'alli-branding')} {...others} />
  );
};
