import { CircularProgress } from '@allganize/ui-circular-progress';
import { IcDescription } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { documentViewerPageStateClasses } from './document-viewer-page-state-classes';
import { DocumentViewerPageStateProps } from './document-viewer-page-state-type-map';

export const DocumentViewerPageState = forwardRef<
  HTMLDivElement,
  DocumentViewerPageStateProps
>((props, ref) => {
  const { classes, type, ...other } = props;
  const theme = useTheme();

  return (
    <div
      data-testid="document-viewer-page-state"
      css={css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 440px;
        height: 584px;
        background-color: ${theme.palette.grey[200]};
      `}
      {...other}
      ref={ref}
      className={clsx(
        documentViewerPageStateClasses.root,
        documentViewerPageStateClasses[type],
        classes?.root,
        classes?.[type],
        other.className,
      )}
    >
      {type === 'error' && (
        <IcDescription
          css={css`
            color: ${theme.palette.text.secondary};
            font-size: 36px;
          `}
        />
      )}

      {type === 'loading' && <CircularProgress color="primary" size="lg" />}
    </div>
  );
});
