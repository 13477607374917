import {
  CoreDraftBlockType,
  DraftBlockRenderConfig,
  DraftBlockRenderMap,
  DraftPlugin,
  DraftStyleMap,
} from '@allganize/draft-input';
import { Map } from 'immutable';
import {
  DraftHeaderFiveBlock,
  DraftHeaderFourBlock,
  DraftHeaderOneBlock,
  DraftHeaderSixBlock,
  DraftHeaderThreeBlock,
  DraftHeaderTwoBlock,
} from './draft-header-blocks';
import { draftTextSizeInlineStyles } from './draft-text-size-inline-styles';
import { headerStyleMap } from './header-style-map';

export class DraftTextSizePlugin extends DraftPlugin {
  public static readonly sizes = headerStyleMap;

  public getBlockRenderMap(): DraftBlockRenderMap {
    return Map<CoreDraftBlockType, DraftBlockRenderConfig>({
      'header-one': {
        element: DraftHeaderOneBlock,
      },
      'header-two': {
        element: DraftHeaderTwoBlock,
      },
      'header-three': {
        element: DraftHeaderThreeBlock,
      },
      'header-four': {
        element: DraftHeaderFourBlock,
      },
      'header-five': {
        element: DraftHeaderFiveBlock,
      },
      'header-six': {
        element: DraftHeaderSixBlock,
      },
    });
  }

  public getCustomStyleMap(): DraftStyleMap {
    return draftTextSizeInlineStyles;
  }
}
