import { useEventCallback } from '@allganize/hooks';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef, useCallback, useMemo } from 'react';
import { useMeridiemMode } from '../internals/hooks/date-helpers-hooks';
import { useClockReferenceDate } from '../internals/hooks/use-clock-reference-date';
import { PickerSelectionState } from '../internals/hooks/use-picker';
import { useLocaleText, useNow, useUtils } from '../internals/hooks/use-utils';
import { useControlledValueWithTimezone } from '../internals/hooks/use-value-with-timezone';
import { useViews } from '../internals/hooks/use-views';
import { TimeViewWithMeridiem } from '../internals/models';
import { formatMeridiem } from '../internals/utils/date-utils';
import {
  convertValueToMeridiem,
  createIsAfterIgnoreDatePart,
} from '../internals/utils/time-utils';
import { singleItemValueManager } from '../internals/utils/value-managers';
import { TimeStepOptions, TimeView } from '../models';
import {
  MultiSectionDigitalClockSection,
  MultiSectionDigitalClockViewProps,
} from '../multi-section-digital-clock-section';
import { PickerViewRoot } from '../picker-view-root';
import { multiSectionDigitalClockClasses } from './multi-section-digital-clock-classes';
import { MultiSectionDigitalClockProps } from './multi-section-digital-clock-type-map';
import {
  getHourSectionOptions,
  getTimeSectionOptions,
} from './multi-section-digital-clock.utils';

export const MultiSectionDigitalClock = forwardRef<
  HTMLDivElement,
  MultiSectionDigitalClockProps
>((props, ref) => {
  const utils = useUtils<Date>();
  const {
    ampm = utils.is12HourCycleInCurrentLocale(),
    timeSteps: inTimeSteps,
    autoFocus,
    classes,
    className,
    slots,
    slotProps,
    value: valueProp,
    defaultValue,
    referenceDate: referenceDateProp,
    disableIgnoringDatePartForTimeValidation = false,
    maxTime,
    minTime,
    disableFuture,
    disablePast,
    minutesStep = 1,
    shouldDisableTime,
    onChange,
    view: inView,
    views: inViews = ['hours', 'minutes'],
    openTo,
    onViewChange,
    focusedView: inFocusedView,
    onFocusedViewChange,
    disabled,
    readOnly,
    skipDisabled = false,
    timezone: timezoneProp,
    ...other
  } = props;
  const theme = useTheme();

  const {
    value,
    handleValueChange: handleRawValueChange,
    timezone,
  } = useControlledValueWithTimezone({
    name: 'MultiSectionDigitalClock',
    timezone: timezoneProp,
    value: valueProp,
    defaultValue,
    onChange,
    valueManager: singleItemValueManager,
  });

  const localeText = useLocaleText<Date>();
  const now = useNow<Date>(timezone);

  const timeSteps = useMemo<Required<TimeStepOptions>>(
    () => ({
      hours: 1,
      minutes: 5,
      seconds: 5,
      ...inTimeSteps,
    }),
    [inTimeSteps],
  );

  const valueOrReferenceDate = useClockReferenceDate({
    value,
    referenceDate: referenceDateProp,
    utils,
    props,
    timezone,
  });

  const handleValueChange = useEventCallback(
    (
      newValue: Date | null,
      selectionState?: PickerSelectionState,
      selectedView?: TimeViewWithMeridiem,
    ) => handleRawValueChange(newValue, selectionState, selectedView),
  );

  const views = useMemo<readonly TimeViewWithMeridiem[]>(() => {
    if (!ampm || !inViews.includes('hours')) {
      return inViews;
    }
    return inViews.includes('meridiem') ? inViews : [...inViews, 'meridiem'];
  }, [ampm, inViews]);

  const { view, setValueAndGoToNextView, focusedView } = useViews<
    Date | null,
    TimeViewWithMeridiem
  >({
    view: inView,
    views,
    openTo,
    onViewChange,
    onChange: handleValueChange,
    focusedView: inFocusedView,
    onFocusedViewChange,
  });

  const handleMeridiemValueChange = useEventCallback(
    (newValue: Date | null) => {
      setValueAndGoToNextView(newValue, 'finish', 'meridiem');
    },
  );

  const { meridiemMode, handleMeridiemChange } = useMeridiemMode(
    valueOrReferenceDate,
    ampm,
    handleMeridiemValueChange,
    'finish',
  );

  const isTimeDisabled = useCallback(
    (rawValue: number, viewType: TimeView) => {
      const isAfter = createIsAfterIgnoreDatePart(
        disableIgnoringDatePartForTimeValidation,
        utils,
      );
      const shouldCheckPastEnd =
        viewType === 'hours' ||
        (viewType === 'minutes' && views.includes('seconds'));

      const containsValidTime = ({
        start,
        end,
      }: {
        start: Date;
        end: Date;
      }) => {
        if (minTime && isAfter(minTime, end)) {
          return false;
        }

        if (maxTime && isAfter(start, maxTime)) {
          return false;
        }

        if (disableFuture && isAfter(start, now)) {
          return false;
        }

        if (disablePast && isAfter(now, shouldCheckPastEnd ? end : start)) {
          return false;
        }

        return true;
      };

      const isValidValue = (timeValue: number, step = 1) => {
        if (timeValue % step !== 0) {
          return false;
        }

        if (shouldDisableTime) {
          switch (viewType) {
            case 'hours':
              return !shouldDisableTime(
                utils.setHours(valueOrReferenceDate, timeValue),
                'hours',
              );
            case 'minutes':
              return !shouldDisableTime(
                utils.setMinutes(valueOrReferenceDate, timeValue),
                'minutes',
              );

            case 'seconds':
              return !shouldDisableTime(
                utils.setSeconds(valueOrReferenceDate, timeValue),
                'seconds',
              );

            default:
              return false;
          }
        }

        return true;
      };

      switch (viewType) {
        case 'hours': {
          const valueWithMeridiem = convertValueToMeridiem(
            rawValue,
            meridiemMode,
            ampm,
          );
          const dateWithNewHours = utils.setHours(
            valueOrReferenceDate,
            valueWithMeridiem,
          );
          const start = utils.setSeconds(
            utils.setMinutes(dateWithNewHours, 0),
            0,
          );
          const end = utils.setSeconds(
            utils.setMinutes(dateWithNewHours, 59),
            59,
          );

          return (
            !containsValidTime({ start, end }) ||
            !isValidValue(valueWithMeridiem)
          );
        }

        case 'minutes': {
          const dateWithNewMinutes = utils.setMinutes(
            valueOrReferenceDate,
            rawValue,
          );
          const start = utils.setSeconds(dateWithNewMinutes, 0);
          const end = utils.setSeconds(dateWithNewMinutes, 59);

          return (
            !containsValidTime({ start, end }) ||
            !isValidValue(rawValue, minutesStep)
          );
        }

        case 'seconds': {
          const dateWithNewSeconds = utils.setSeconds(
            valueOrReferenceDate,
            rawValue,
          );
          const start = dateWithNewSeconds;
          const end = dateWithNewSeconds;

          return !containsValidTime({ start, end }) || !isValidValue(rawValue);
        }

        default:
          throw new Error('not supported');
      }
    },
    [
      ampm,
      valueOrReferenceDate,
      disableIgnoringDatePartForTimeValidation,
      maxTime,
      meridiemMode,
      minTime,
      minutesStep,
      shouldDisableTime,
      utils,
      disableFuture,
      disablePast,
      now,
      views,
    ],
  );

  const buildViewProps = useCallback(
    (
      viewToBuild: TimeViewWithMeridiem,
    ): MultiSectionDigitalClockViewProps<any> => {
      switch (viewToBuild) {
        case 'hours': {
          return {
            onChange: hours => {
              const valueWithMeridiem = convertValueToMeridiem(
                hours,
                meridiemMode,
                ampm,
              );
              setValueAndGoToNextView(
                utils.setHours(valueOrReferenceDate, valueWithMeridiem),
                'finish',
                'hours',
              );
            },
            items: getHourSectionOptions<Date>({
              now,
              value,
              ampm,
              utils,
              isDisabled: hours => disabled || isTimeDisabled(hours, 'hours'),
              timeStep: timeSteps.hours,
              resolveAriaLabel: localeText.hoursClockNumberText,
            }),
          };
        }

        case 'minutes': {
          return {
            onChange: minutes => {
              setValueAndGoToNextView(
                utils.setMinutes(valueOrReferenceDate, minutes),
                'finish',
                'minutes',
              );
            },
            items: getTimeSectionOptions<Date>({
              value: utils.getMinutes(valueOrReferenceDate),
              utils,
              isDisabled: minutes =>
                disabled || isTimeDisabled(minutes, 'minutes'),
              resolveLabel: minutes =>
                utils.format(utils.setMinutes(now, minutes), 'minutes'),
              timeStep: timeSteps.minutes,
              hasValue: !!value,
              resolveAriaLabel: localeText.minutesClockNumberText,
            }),
          };
        }

        case 'seconds': {
          return {
            onChange: seconds => {
              setValueAndGoToNextView(
                utils.setSeconds(valueOrReferenceDate, seconds),
                'finish',
                'seconds',
              );
            },
            items: getTimeSectionOptions<Date>({
              value: utils.getSeconds(valueOrReferenceDate),
              utils,
              isDisabled: seconds =>
                disabled || isTimeDisabled(seconds, 'seconds'),
              resolveLabel: seconds =>
                utils.format(utils.setSeconds(now, seconds), 'seconds'),
              timeStep: timeSteps.seconds,
              hasValue: !!value,
              resolveAriaLabel: localeText.secondsClockNumberText,
            }),
          };
        }

        case 'meridiem': {
          const amLabel = formatMeridiem(utils, 'am');
          const pmLabel = formatMeridiem(utils, 'pm');
          return {
            onChange: handleMeridiemChange,
            items: [
              {
                value: 'am',
                label: amLabel,
                isSelected: () => !!value && meridiemMode === 'am',
                ariaLabel: amLabel,
              },
              {
                value: 'pm',
                label: pmLabel,
                isSelected: () => !!value && meridiemMode === 'pm',
                ariaLabel: pmLabel,
              },
            ],
          };
        }

        default:
          throw new Error(`Unknown view: ${viewToBuild} found.`);
      }
    },
    [
      now,
      value,
      ampm,
      utils,
      timeSteps.hours,
      timeSteps.minutes,
      timeSteps.seconds,
      localeText.hoursClockNumberText,
      localeText.minutesClockNumberText,
      localeText.secondsClockNumberText,
      meridiemMode,
      setValueAndGoToNextView,
      valueOrReferenceDate,
      disabled,
      isTimeDisabled,
      handleMeridiemChange,
    ],
  );

  const viewTimeOptions = useMemo(() => {
    return views.reduce((result, currentView) => {
      return { ...result, [currentView]: buildViewProps(currentView) };
    }, {} as Record<TimeViewWithMeridiem, MultiSectionDigitalClockViewProps<number>>);
  }, [views, buildViewProps]);

  return (
    <PickerViewRoot
      data-testid="multi-section-digital-clock"
      css={css`
        display: flex;
        flex-direction: row;
        width: 100%;
        border-bottom: 1px solid ${theme.palette.divider};
      `}
      role="group"
      {...other}
      ref={ref}
      className={clsx(
        multiSectionDigitalClockClasses.root,
        classes?.root,
        className,
      )}
    >
      {Object.entries(viewTimeOptions).map(([timeView, viewOptions]) => (
        <MultiSectionDigitalClockSection
          key={timeView}
          items={viewOptions.items}
          onChange={viewOptions.onChange}
          active={view === timeView}
          autoFocus={autoFocus ?? focusedView === timeView}
          disabled={disabled}
          readOnly={readOnly}
          slots={slots}
          slotProps={slotProps}
          skipDisabled={skipDisabled}
          aria-label={localeText.selectViewText(
            timeView as TimeViewWithMeridiem,
          )}
        />
      ))}
    </PickerViewRoot>
  );
});
