import { DraftFilePlugin } from '@allganize/draft-file-plugin';
import { DraftImagePlugin } from '@allganize/draft-image-plugin';
import {
  DraftInputBase,
  draftInputInternalClasses,
} from '@allganize/draft-input';
import {
  DraftAutoLinkPlugin,
  DraftLinkPlugin,
} from '@allganize/draft-link-plugin';
import {
  DraftTextColorPlugin,
  DraftTextHighlightPlugin,
  DraftTextSizePlugin,
  DraftTextStylePlugin,
} from '@allganize/draft-text-format-plugin';
import { DraftVideoPlugin } from '@allganize/draft-video-plugin';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import { DocumentViewerPageWrapper } from '../document-viewer-page-wrapper';
import { draftPageClasses } from './draft-page-classes';
import { DraftPageProps } from './draft-page-type-map';

const defaultPlugins = [
  new DraftTextColorPlugin(),
  new DraftTextStylePlugin(),
  new DraftTextHighlightPlugin(),
  new DraftTextSizePlugin(),
  new DraftAutoLinkPlugin(),
  new DraftLinkPlugin(),
  new DraftFilePlugin(),
  new DraftImagePlugin({ preserveRatio: true }),
  new DraftVideoPlugin(),
];

export const DraftPage = forwardRef<HTMLDivElement, DraftPageProps>(
  (props, ref) => {
    const { classes, page, plugins = defaultPlugins, ...other } = props;
    const [editorState, setEditorState] = useState(page.editorState);

    useEffect(() => {
      setEditorState(page.editorState);
    }, [page.editorState]);

    return (
      <DocumentViewerPageWrapper
        data-testid="draft-page"
        {...other}
        ref={ref}
        className={clsx(draftPageClasses.root, classes?.root, other.className)}
      >
        <DraftInputBase
          css={css`
            .${draftInputInternalClasses.content} {
              padding: 12px 16px;
            }
          `}
          disabled
          plugins={plugins}
          value={editorState}
          onChange={setEditorState}
          className={clsx(draftPageClasses.input, classes?.input)}
        />
      </DocumentViewerPageWrapper>
    );
  },
);
