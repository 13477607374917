import { OverridableComponent } from '@allganize/types';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { textClasses } from './text-classes';
import { TextTypeMap, TextVariant } from './text-type-map';

const defaultVariantMapping: Partial<Record<TextVariant, string>> = {
  title24: 'h1',
  title20: 'h2',
  title16: 'h3',
  title14: 'h4',
  title12: 'h5',
  subtitle16: 'h6',
  subtitle14: 'h6',
  subtitle12: 'h6',
  body16: 'p',
  body14: 'p',
  body12: 'p',
  label14: 'span',
  label12: 'span',
  inherit: 'p',
};

// @ts-expect-error overridable component
export const Text: OverridableComponent<TextTypeMap> = forwardRef(
  (props, ref) => {
    const theme = useTheme();
    const {
      classes,
      variant = 'body14',
      variantMapping = defaultVariantMapping,
      ...other
    } = props;
    const component =
      (other.paragraph
        ? 'p'
        : variantMapping[variant] || defaultVariantMapping[variant]) || 'span';

    return (
      <Typography
        data-testid="text"
        css={[
          variant === 'inherit' &&
            css`
              font: inherit;
              letter-spacing: inherit;
            `,
          variant !== 'inherit' &&
            css`
              ${theme.typography[variant]}
            `,
        ]}
        // @ts-expect-error overridable component
        component={component}
        classes={classes}
        {...other}
        ref={ref}
        className={clsx(
          textClasses[variant],
          classes?.[variant],
          other.className,
        )}
      />
    );
  },
);
