import { Chip } from '@allganize/ui-chip';
import { List, ListItem } from '@allganize/ui-list';
import { css } from '@emotion/react';
import { VIEW_HEIGHT } from '../internals/constants';
import { PickersShortcutsProps } from './pickers-shortcuts-type-map';

interface PickersShortcutsType {
  <TValue>(
    props: PickersShortcutsProps<TValue>,
    context?: any,
  ): React.ReactNode;
  contextTypes?: React.ValidationMap<any> | undefined;
  displayName?: string | undefined;
}

export const PickersShortcuts: PickersShortcutsType = props => {
  const { items, changeImportance, isLandscape, onChange, isValid, ...other } =
    props;

  if (!items || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map(({ getValue, ...item }) => {
    const newValue = getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        onChange(newValue, changeImportance, item);
      },
      disabled: !isValid(newValue),
    };
  });

  return (
    <List
      data-testid="pickers-shortcuts"
      css={css`
        max-height: ${VIEW_HEIGHT}px;
        max-width: 200px;
        overflow: auto;
      `}
      {...other}
    >
      {resolvedItems.map(item => {
        return (
          <ListItem key={item.label}>
            <Chip {...item} />
          </ListItem>
        );
      })}
    </List>
  );
};
