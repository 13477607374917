import { InputAdornment, InputField } from '@allganize/ui-input';
import { css } from '@emotion/react';
import { validHex } from '@uiw/color-convert';
import { forwardRef, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ColorSwatch, ColorSwatchButton } from './color-swatch';

interface ColorPaletteProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  options: string[];
  value: string | string[];
  isOptionSelected?(option: string): boolean;
  onChange?(value: string): void;
}

export const ColorPalette = forwardRef<HTMLDivElement, ColorPaletteProps>(
  (props, ref) => {
    const {
      isOptionSelected,
      options,
      value: valueProp,
      onChange,
      ...other
    } = props;
    const intl = useIntl();
    const selected = Array.isArray(valueProp) ? valueProp : [valueProp];
    const defaultValue =
      selected.length > 1
        ? intl.formatMessage({
            id: 'mixed',
            defaultMessage: 'Mixed',
            description: 'mixed',
          })
        : selected[0] ?? '';
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      const color = ev.target.value.replace(/[^#a-fA-F0-9]+/g, '');

      setValue(
        color.length === 0 || color.startsWith('#') ? color : `#${color}`,
      );
    };

    const handleBlur = () => {
      const isValid = validHex(value);

      if (!isValid) {
        return;
      }

      onChange?.(value);
    };

    return (
      <div
        css={css`
          max-width: ${24 * 8 + 2 * 12}px;
          padding: 12px;
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
        {...other}
        ref={ref}
      >
        <InputField
          fullWidth
          label={
            <FormattedMessage
              id="CampaignBuild.Sidebar.Question.OptionMenu.ColorCode"
              defaultMessage="Color code"
              description="Ask a question button option menu component color button"
            />
          }
          required
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ color: value }}>
                <ColorSwatch />
              </InputAdornment>
            ),
          }}
        />

        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          `}
        >
          {options.map(color => {
            const active =
              isOptionSelected?.(color) ?? selected.includes(color);

            const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
              ev.preventDefault();
              onChange?.(color);
            };

            return (
              <ColorSwatchButton
                key={color}
                color={color}
                active={active}
                onClick={handleClick}
              />
            );
          })}
        </div>
      </div>
    );
  },
);
