import { DraftLinkContext } from '@allganize/draft-link-plugin';
import { IcDescription } from '@allganize/ui-icons';
import { useImageLoaded } from '@allganize/hooks';
import { useTheme } from '@allganize/ui-theme';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { Text } from '@allganize/ui-text';
import { Truncate } from '@allganize/truncate';
import { Link, linkClasses } from '@allganize/ui-link';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef, useContext } from 'react';

import { recommendedItemClasses } from './recommended-item-classes';
import { RecommendedItemProps } from './recommended-item-type-map';

export const RecommendedItem = forwardRef<
  HTMLAnchorElement,
  RecommendedItemProps
>((props, ref) => {
  const { classes, data, onClick, ...other } = props;
  const { id, media, url, primary, secondary, tertiary } = data;
  const theme = useTheme();
  const thumbnailUrl = media?.url;
  const loaded = useImageLoaded({ src: thumbnailUrl || null });
  const target = useContext(DraftLinkContext).getLinkTarget(url || undefined);

  return (
    <Link
      data-testid="recommended-item"
      href={url || undefined}
      rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      target={target}
      underline="hover"
      onClick={() => onClick?.({ id, name: primary?.text || '' })}
      css={css`
        width: 200px;
        background-color: ${theme.palette.common.white};
        display: inline-flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        border-radius: ${theme.radius.sm}px;
        border: 1px solid ${theme.palette.grayAlpha[300]};
        overflow: hidden;
        color: unset;

        &:hover,
        &.${linkClasses.focusVisible} {
          .${recommendedItemClasses.imageContainer} {
            &::after {
              background-color: ${theme.palette.grayAlpha[300]};
            }
          }

          .${recommendedItemClasses.primary},
            .${recommendedItemClasses.secondary},
            .${recommendedItemClasses.tertiary} {
            text-decoration: underline;
          }
        }

        &:active {
          .${recommendedItemClasses.imageContainer} {
            &::after {
              background-color: ${theme.palette.grayAlpha[500]};
            }
          }
        }
      `}
      {...other}
      ref={ref}
      className={clsx(
        recommendedItemClasses.root,
        classes?.root,
        other.className,
      )}
    >
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 188px;
          background-color: ${theme.palette.grey[200]};
          display: flex;
          align-items: center;
          justify-content: center;

          &::after {
            display: block;
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            pointer-events: none;
            transition: ${theme.transitions.create('background-color', {
              duration: theme.transitions.duration.short,
            })};
          }
        `}
        className={clsx(
          recommendedItemClasses.imageContainer,
          classes?.imageContainer,
        )}
      >
        {loaded === 'loaded' && (
          <img
            css={css`
              display: block;
              width: 100%;
              height: 100%;
              user-select: none;
              object-fit: cover;
              object-position: top center;
              word-break: break-all;
              background-color: ${theme.palette.common.white};
            `}
            src={thumbnailUrl}
            alt={primary?.text}
            className={clsx(recommendedItemClasses.image, classes?.image)}
          />
        )}

        {loaded === 'error' && (
          <span
            css={css`
              color: ${theme.palette.text.secondary};
              font-size: 36px;
            `}
          >
            <IcDescription color="inherit" fontSize="inherit" />
          </span>
        )}

        {!loaded && <CircularProgress color="primary" />}
      </div>
      <div
        className={clsx(
          recommendedItemClasses.contentContainer,
          classes?.contentContainer,
        )}
        css={css`
          padding: 8px 12px;
          text-align: left;
        `}
      >
        {primary && (
          <Text
            className={clsx(recommendedItemClasses.primary, classes?.primary)}
            variant="title12"
            noWrap
            css={css`
              color: ${primary.style?.color || theme.palette.text.primary};
            `}
          >
            {primary.text}
          </Text>
        )}
        {secondary && (
          <Text variant="body12" component="div">
            <Truncate
              className={clsx(
                recommendedItemClasses.secondary,
                classes?.secondary,
              )}
              clamp={2}
              css={css`
                color: ${secondary.style?.color ||
                theme.palette.text.secondary};
              `}
            >
              {secondary.text}
            </Truncate>
          </Text>
        )}
        {tertiary && (
          <Text
            className={clsx(recommendedItemClasses.tertiary, classes?.tertiary)}
            variant="title14"
            component="div"
            css={css`
              color: ${tertiary.style?.color || theme.palette.text.primary};
            `}
            noWrap
          >
            {tertiary.text}
          </Text>
        )}
      </div>
    </Link>
  );
});
