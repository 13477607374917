import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import {
  ChatOptionInfoListItemClasses,
  chatOptionInfoListItemClasses,
} from './chat-option-info-list-item-classes';

export interface ChatOptionInfoListItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  carousel?: boolean;
  classes?: Partial<ChatOptionInfoListItemClasses>;
  className?: string;
}

export const ChatOptionInfoListItem = forwardRef<
  HTMLDivElement,
  ChatOptionInfoListItemProps
>((props, ref) => {
  const { carousel, classes, ...other } = props;

  return (
    <div
      css={[
        css`
          padding: 4px;
        `,
        carousel &&
          css`
            padding: 0 4px;
          `,
      ]}
      {...other}
      ref={ref}
      className={clsx(
        chatOptionInfoListItemClasses.root,
        { [chatOptionInfoListItemClasses.carousel]: carousel },
        classes?.root,
        { [classes?.carousel ?? '']: carousel },
        other.className,
      )}
    />
  );
});
