import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type RecommendedAppClassKey = 'root' | 'icon' | 'title' | 'description';
export type RecommendedAppClasses = Record<RecommendedAppClassKey, string>;

export const recommendedAppClasses: RecommendedAppClasses =
  generateUtilityClasses('RecommendedApp', [
    'root',
    'icon',
    'title',
    'description',
  ]);
