import { UserQueryTypes } from '@allganize/alli-interfaces';
import { ApolloQueryResult } from '@apollo/client/core';
import { useLazyQuery } from '@apollo/client/react';
import { compact, uniqBy } from 'lodash-es';
import { useEffect, useState, useMemo } from 'react';

import {
  KnowledgeBasesQuery,
  KnowledgeBasesQueryDocument,
} from '../../graphql/queries/knowledge-bases-query';
import { KnowledgeBaseHashtagsQueryDocument } from '../../graphql/queries/knowledge-base-hashtags-query';
import { KnowledgeBaseFetchStatus } from './knowledge-base-search-dialog-type-map';
import { getKnowledgeBaseList } from '../utils/knowledge-base';

export const useKnowledgeBaseSearch = ({ open }: { open: boolean }) => {
  const [query, setQuery] = useState('');
  const [hasTurnedOnKnowledgeBases, setHasTurnedOnKnowledgeBases] =
    useState(false);
  const [hashtagFilter, setHashtagFilter] = useState<string | null>(null);
  const clearHashtagFilter = () => setHashtagFilter(null);

  const [
    getKnowledgeBases,
    { data, loading, variables, previousData, called, fetchMore, refetch },
  ] = useLazyQuery(KnowledgeBasesQueryDocument, {
    onCompleted: data => {
      if (query === '') {
        setHasTurnedOnKnowledgeBases(
          data.knowledgeBasesByCursor?.edges.length !== 0,
        );
      }
    },
  });

  const knowledgeBases = useMemo(
    () => getKnowledgeBaseList(data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.knowledgeBasesByCursor?.edges],
  );

  const [getHashtags, { data: hashtagData }] = useLazyQuery(
    KnowledgeBaseHashtagsQueryDocument,
  );

  const hashtagList = useMemo(() => {
    if (!hasTurnedOnKnowledgeBases) {
      return [];
    }

    return compact(
      (hashtagData?.knowledgeBaseHashtags?.hashtags ?? []).map(
        data => data?.hashtag,
      ),
    );
  }, [hasTurnedOnKnowledgeBases, hashtagData]);

  const loadNextPage =
    async (): Promise<ApolloQueryResult<KnowledgeBasesQuery> | void> => {
      if (!data?.knowledgeBasesByCursor?.pageInfo.hasNextPage) {
        return undefined;
      }

      return fetchMore({
        variables: {
          ...variables,
          after: data.knowledgeBasesByCursor.pageInfo.endCursor || null,
        },
        updateQuery(prev, { fetchMoreResult }) {
          if (
            !(
              fetchMoreResult.knowledgeBasesByCursor?.edges &&
              fetchMoreResult.knowledgeBasesByCursor.edges.length > 0
            ) ||
            !prev.knowledgeBasesByCursor?.edges
          ) {
            return prev;
          }

          return {
            ...prev,
            knowledgeBasesByCursor: {
              ...prev.knowledgeBasesByCursor,
              edges: uniqBy(
                [
                  ...(prev.knowledgeBasesByCursor.edges || []),
                  ...fetchMoreResult.knowledgeBasesByCursor.edges,
                ],
                edge => edge?.node?.id,
              ),
              pageInfo: {
                ...prev.knowledgeBasesByCursor.pageInfo,
                hasNextPage:
                  fetchMoreResult.knowledgeBasesByCursor.pageInfo.hasNextPage,
                endCursor:
                  fetchMoreResult.knowledgeBasesByCursor.pageInfo.endCursor,
              },
            },
          };
        },
      });
    };

  useEffect(() => {
    if (open && called) {
      refetch();
    }
  }, [open, called, refetch]);

  useEffect(() => {
    getKnowledgeBases({
      variables: {
        filter: {
          searchTerm: query,
          hashtags: hashtagFilter ? [hashtagFilter] : undefined,
          processState: UserQueryTypes.ProcessStateEnum.COMPLETED,
        },
        first: 20,
      },
    });
  }, [query, hashtagFilter, getKnowledgeBases]);

  useEffect(() => {
    getHashtags({
      variables: {
        searchTerm: query,
        limit: 5,
      },
    });
  }, [query, getHashtags]);

  const knowledgeBaseFetchStatus: KnowledgeBaseFetchStatus = useMemo(() => {
    if (loading) {
      return previousData === undefined ? 'initial-loading' : 'loading';
    }
    if (query === '' && knowledgeBases.length === 0) {
      return 'empty';
    }

    if (!loading && knowledgeBases.length === 0) {
      return 'search-empty';
    }
    return 'result';
  }, [loading, previousData, knowledgeBases, query]);

  return {
    query,
    setQuery,
    loadNextPage,
    hashtagFilter,
    setHashtagFilter,
    clearHashtagFilter,
    hashtagList,
    knowledgeBases,
    knowledgeBaseFetchStatus,
  };
};
