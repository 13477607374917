import { EditorState } from '@allganize/draft-input';

export const getCurrentEntityKey = (
  editorState: EditorState,
): string | null => {
  const selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  const contentState = editorState.getCurrentContent();
  const anchorBlock = contentState.getBlockForKey(anchorKey);
  const offset = selection.getAnchorOffset();
  const index = selection.getIsBackward() ? offset - 1 : offset;
  return anchorBlock?.getEntityAt(index) ?? null;
};
