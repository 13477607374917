import { useEventCallback } from '@allganize/hooks';
import { IconButton, LoadingButton } from '@allganize/ui-button';
import {
  DropzoneRef,
  FileDropzone,
  fileDropzoneClasses,
} from '@allganize/ui-file-input';
import { IcAttachment, IcClose, IcError, IcUpload } from '@allganize/ui-icons';
import { InputAdornment, InputField } from '@allganize/ui-input';
import { useTheme } from '@allganize/ui-theme';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  UseDraftToolbarFileFormOptions,
  useDraftToolbarFileForm,
} from './use-draft-toolbar-file-form';

export interface DraftToolbarFileFormActions {
  setFile(file: File | null): void;
}

export interface DraftToolbarFileFormProps<
  T extends { type: 'image' | 'file'; url: string },
> extends UseDraftToolbarFileFormOptions<T>,
    Omit<React.FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  actions?: React.Ref<DraftToolbarFileFormActions>;
}

interface DraftToolbarFileFormType {
  <T extends { type: 'image' | 'file'; url: string }>(
    props: React.PropsWithoutRef<DraftToolbarFileFormProps<T>> &
      React.RefAttributes<HTMLFormElement>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

export const DraftToolbarFileForm: DraftToolbarFileFormType = forwardRef(
  (props, ref) => {
    const { actions, defaultValues, validation, onSubmit, ...other } = props;
    const theme = useTheme();
    const intl = useIntl();
    const {
      form: {
        control,
        formState: { isSubmitting, isValid },
        handleSubmit,
        setError,
        setValue,
      },
      submit,
    } = useDraftToolbarFileForm({ defaultValues, validation, onSubmit });
    const dropzoneRef = useRef<DropzoneRef>(null);

    const setFile = useEventCallback((file: File | null) => {
      setValue('file', file);
    });

    const openFilePicker = () => {
      dropzoneRef.current?.open();
    };

    useImperativeHandle(actions, () => ({ setFile }), [setFile]);

    return (
      <form {...other} ref={ref} onSubmit={handleSubmit(submit)}>
        <div
          css={css`
            display: flex;
            gap: 8px;
            padding: 12px;
          `}
        >
          <Controller
            control={control}
            name="file"
            render={({
              field: { ref: fieldRef, ...field },
              fieldState,
              formState,
            }) => {
              const errorMessage =
                fieldState.error?.message || formState.errors.root?.message;

              return (
                <FileDropzone
                  css={css`
                    border: 0;
                    background-color: transparent;

                    .${fileDropzoneClasses.dragOverlay} {
                      border: 1px dashed transparent;
                      background-color: ${theme.palette.grey[100]};
                      border-color: ${theme.palette.grayAlpha[500]};
                      color: ${theme.palette.text.primary};
                      transition: ${theme.transitions.create(
                        ['color', 'opacity', 'border-color'],
                        {
                          easing: theme.transitions.easing.easeInOut,
                          duration: theme.transitions.duration.leavingScreen,
                        },
                      )};
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-wrap: wrap;
                    }

                    &.${fileDropzoneClasses.dragActive}
                      .${fileDropzoneClasses.dragOverlay} {
                      transition: ${theme.transitions.create(
                        ['color', 'opacity', 'border-color'],
                        {
                          easing: theme.transitions.easing.easeInOut,
                          duration: theme.transitions.duration.enteringScreen,
                        },
                      )};
                    }

                    &.${fileDropzoneClasses.dragReject}
                      .${fileDropzoneClasses.dragOverlay} {
                      color: ${theme.palette.error.main};
                      border-color: ${theme.palette.error.main};
                    }
                  `}
                  dragOverlay={
                    <div>
                      <IcUpload
                        css={css`
                          vertical-align: middle;
                        `}
                      />{' '}
                      <FormattedMessage
                        id="form.file.placeholder.drag"
                        defaultMessage="Drop file here"
                        description="file drop placeholder text"
                      />
                    </div>
                  }
                  dropzoneRef={dropzoneRef}
                  maxFiles={1}
                  noClick
                  inputProps={{ name: field.name }}
                  disabled={field.disabled}
                  onDropAccepted={files => {
                    const file = files[0];

                    if (!file) {
                      return;
                    }

                    field.onChange(file);
                  }}
                  onDropRejected={rejections => {
                    if (rejections.length === 0) {
                      return;
                    }

                    setError('file', {
                      message: intl.formatMessage({
                        id: 'TextArea.FileButton.File.errors.invalid',
                        defaultMessage: 'Invalid file rejected',
                        description:
                          'Text area insert file button rejected error message',
                      }),
                    });
                  }}
                  onBlur={field.onBlur}
                >
                  <InputField
                    {...field}
                    value={field.value?.name ?? ''}
                    inputRef={fieldRef}
                    autoFocus
                    required
                    fullWidth
                    label={null}
                    error={fieldState.invalid}
                    placeholder={intl.formatMessage(
                      {
                        id: 'form.file.placeholder',
                        defaultMessage:
                          'Click or drag & drop{br}a file to upload',
                        description: 'file field placeholder text',
                      },
                      { br: ' ' },
                    )}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="start" onClick={openFilePicker}>
                            <IcAttachment />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (field.value || errorMessage) && (
                        <InputAdornment position="end">
                          {errorMessage && (
                            <Tooltip color="error" title={errorMessage}>
                              <IcError color="error" />
                            </Tooltip>
                          )}

                          {field.value && (
                            <IconButton
                              edge="end"
                              onClick={() => {
                                field.onChange(null);
                              }}
                            >
                              <IcClose />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FileDropzone>
              );
            }}
          />

          <LoadingButton
            variant="filled"
            color="primary"
            type="submit"
            size="large"
            loading={isSubmitting}
            disabled={!isValid}
          >
            <FormattedMessage
              id="TextArea.FileButton.SubmitButton"
              defaultMessage="Submit"
              description="Text area insert file form submit button text"
            />
          </LoadingButton>
        </div>
      </form>
    );
  },
);
