import { AlliAppMarketAnalytics } from '@allganize/alli-app-market-analytics';

import { environment } from './environments/environment';

export const analytics = new AlliAppMarketAnalytics({
  mixpanel: environment.mixpanel
    ? {
        token: environment.mixpanel.token,
        name: 'alli-app-market',
        config: {
          track_pageview: false,
        },
      }
    : undefined,
});
