import { getDocument } from './get-document';
import { getDocumentElement } from './get-document-element';
import { getWindow } from './get-window';

export const isDocumentElement = (
  el: HTMLElement | typeof window,
): el is typeof window => {
  return (
    [
      document.documentElement,
      document.body,
      window,
      getDocumentElement(el),
      getDocument(el).body,
      getWindow(el),
    ].indexOf(el) > -1
  );
};
