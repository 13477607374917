import { Button } from '@allganize/ui-button';
import { IcError, IcRefresh } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Root } from '../components/root';

interface ErrorStateProps {
  onRetry?: () => void;
}

export const ErrorState: FunctionComponent<ErrorStateProps> = ({ onRetry }) => {
  return (
    <Root className="single-action__error-state">
      <IcError
        css={css`
          margin-bottom: 4px;
        `}
        fontSize="large"
        color="error"
      />

      <Text variant="title14">
        <FormattedMessage
          id="single-action.error-state.title"
          defaultMessage="Something went wrong!"
          description="single action error screen title"
        />
      </Text>

      <Text variant="body14">
        <FormattedMessage
          id="single-action.error-state.description"
          defaultMessage="Please try again later."
          description="single action error screen description"
        />
      </Text>

      {onRetry && (
        <Button
          css={css`
            margin-top: 12px;
          `}
          color="default"
          variant="outlined"
          startIcon={<IcRefresh />}
          onClick={() => onRetry?.()}
        >
          <FormattedMessage
            id="single-action.error-state.retry"
            defaultMessage="Retry"
            description="single action error screen retry button text"
          />
        </Button>
      )}
    </Root>
  );
};
