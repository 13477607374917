/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CurrentUserQueryVariables = UserQueryTypes.Exact<{
  [key: string]: never;
}>;

export type CurrentUserQuery = { __typename: 'UserQuery' } & {
  me: UserQueryTypes.Maybe<
    { __typename: 'User' } & Pick<
      UserQueryTypes.User,
      | 'id'
      | 'email'
      | 'phone'
      | 'firstName'
      | 'lastName'
      | 'name'
      | 'avatar'
      | 'createdAt'
      | 'locale'
      | 'ownUserId'
      | 'isTry'
      | 'temporary'
    >
  >;
};

export const CurrentUserQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrentUserQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isTry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'temporary' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentUserQuery, CurrentUserQueryVariables>;
