import { OverridableComponent } from '@allganize/types';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import MuiTable from '@mui/material/Table';
import { forwardRef } from 'react';
import { TableTypeMap } from './table-type-map';

// @ts-expect-error overridable component
export const Table: OverridableComponent<TableTypeMap> = forwardRef(
  (props, ref) => {
    const { ...other } = props;
    const theme = useTheme();

    return (
      <MuiTable
        data-testid="table"
        css={css`
          caption {
            ${theme.typography.body14}
            padding: ${theme.spacing(2)};
            color: ${theme.palette.text.secondary};
          }
        `}
        {...other}
        ref={ref}
      />
    );
  },
);
