import { Types } from '@allganize/alli-sdk-interfaces';
import {
  SubscriptionHookOptions,
  SuspenseQueryHookOptions,
  useSubscription,
  useSuspenseQuery,
} from '@apollo/client/react';
import {
  ConversationQuery,
  ConversationQueryDocument,
  ConversationQueryVariables,
} from '../graphql/queries/conversation-query';
import {
  ConversationSubscription,
  ConversationSubscriptionDocument,
  ConversationSubscriptionVariables,
} from '../graphql/subscriptions/conversation-subscription';

export interface UseConversationOptions {
  conversationId: Types.Scalars['ID']['input'];
  query?: Omit<
    SuspenseQueryHookOptions<ConversationQuery, ConversationQueryVariables>,
    'variables'
  >;
  subscription?: Omit<
    SubscriptionHookOptions<
      ConversationSubscription,
      ConversationSubscriptionVariables
    >,
    'variables'
  >;
}

export const useConversation = ({
  conversationId,
  query,
  subscription,
}: UseConversationOptions) => {
  const result = useSuspenseQuery(ConversationQueryDocument, {
    ...query,
    variables: { where: { id: conversationId } },
  });

  useSubscription(ConversationSubscriptionDocument, {
    ...subscription,
    variables: { where: { id: conversationId } },
    skip: !!result.data.conversation?.isTry || subscription?.skip,
  });

  return result;
};
