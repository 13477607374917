import { DraftDocumentPlugin } from '@allganize/draft-document-plugin';
import { DraftFilePlugin } from '@allganize/draft-file-plugin';
import { DraftImagePlugin } from '@allganize/draft-image-plugin';
import {
  CompositeDecorator,
  ContentState,
  DraftDecorator,
  DraftPlugin,
  EditorState,
  RawDraftContentState,
  convertFromRaw,
} from '@allganize/draft-input';
import {
  DraftAutoLinkPlugin,
  DraftLinkPlugin,
} from '@allganize/draft-link-plugin';
import {
  DraftTextColorPlugin,
  DraftTextSizePlugin,
  DraftTextStylePlugin,
} from '@allganize/draft-text-format-plugin';
import { DraftVideoPlugin } from '@allganize/draft-video-plugin';
import { useEffect, useMemo, useState } from 'react';

export const defaultPlugins = [
  new DraftTextStylePlugin(),
  new DraftTextColorPlugin(),
  new DraftTextSizePlugin(),
  new DraftDocumentPlugin(),
  new DraftLinkPlugin(),
  new DraftAutoLinkPlugin(),
  new DraftImagePlugin({ preserveRatio: true }),
  new DraftVideoPlugin(),
  new DraftFilePlugin(),
];

const getValue = (
  data: UseRichTextOptions['data'],
  decorators: DraftDecorator[] = [],
): EditorState | null => {
  if (data instanceof EditorState) {
    return data;
  }

  if (!data) {
    return null;
  }

  if (data instanceof ContentState) {
    return EditorState.createWithContent(
      data,
      decorators.length > 0 ? new CompositeDecorator(decorators) : undefined,
    );
  }

  try {
    const contentState = convertFromRaw(data);
    const editorState = EditorState.createWithContent(
      contentState,
      decorators.length > 0 ? new CompositeDecorator(decorators) : undefined,
    );

    return editorState;
  } catch {
    return null;
  }
};

export interface UseRichTextOptions {
  data: string | RawDraftContentState | ContentState | EditorState | null;
  onChange?(value: EditorState): void;
  plugins?: DraftPlugin[];
}

export const useRichText = ({
  data,
  onChange,
  plugins = defaultPlugins,
}: UseRichTextOptions) => {
  const decorators = useMemo(
    () =>
      plugins.reduce<DraftDecorator[]>((acc, plugin) => {
        const decorators = plugin.getDecorators();

        if (decorators && decorators.length > 0) {
          return [...acc, ...decorators];
        }

        return acc;
      }, []),
    [plugins],
  );

  const [value, setValue] = useState(getValue(data, decorators));

  useEffect(() => {
    setValue(getValue(data, decorators));
  }, [data, decorators]);

  const handleChange = (newValue: EditorState) => {
    setValue(newValue);
    onChange?.(newValue);
  };

  return {
    value,
    plugins,
    onChange: handleChange,
  };
};
