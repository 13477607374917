import {
  getTableCellUtilityClass,
  TableCellClassKey as MuiTableCellClassKey,
  tableCellClasses as muiTableCellClasses,
} from '@mui/material/TableCell';

export type TableCellClassKey =
  | MuiTableCellClassKey
  | 'paddingButton'
  | 'paddingInput';
export type TableCellClasses = Record<TableCellClassKey, string>;

export const tableCellClasses: TableCellClasses = {
  ...muiTableCellClasses,
  paddingButton: getTableCellUtilityClass('paddingButton'),
  paddingInput: getTableCellUtilityClass('paddingInput'),
};
