import { createContext } from 'react';
import { noop } from 'lodash-es';
import { PreviewType } from './preview-state';

export interface PreviewContextValue {
  previewOpen: boolean;
  previewType: PreviewType | null;
  setPreviewType: React.Dispatch<React.SetStateAction<PreviewType | null>>;
  previewSidebarAnchor: 'left' | 'right';
  previewSidebarWidth: number;
  messengerWidthWhenPreviewOpen: number;
  messengerHeightWhenPreviewOpen: number;
  shouldOpenPreviewInDialog: boolean;
}

export const PreviewContext = createContext<PreviewContextValue>({
  previewOpen: false,
  previewType: null,
  setPreviewType: noop,
  previewSidebarAnchor: 'left',
  previewSidebarWidth: 0,
  messengerWidthWhenPreviewOpen: 0,
  messengerHeightWhenPreviewOpen: 0,
  shouldOpenPreviewInDialog: true,
});

export type PreviewContextProps = Pick<
  PreviewContextValue,
  'previewSidebarAnchor' | 'setPreviewType' | 'shouldOpenPreviewInDialog'
>;
