import { ListItemSecondaryAction } from '@allganize/ui-list';
import { ClassNames } from '@emotion/react';
import { forwardRef } from 'react';
import { fileListItemSecondaryActionClasses } from './file-list-item-secondary-action-classes';
import { FileListItemSecondaryActionProps } from './file-list-item-secondary-action-type-map';

export const FileListItemSecondaryAction = forwardRef<
  HTMLDivElement,
  FileListItemSecondaryActionProps
>((props, ref) => {
  const { classes, ...other } = props;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <ListItemSecondaryAction
          data-testid="file-list-item-secondary-action"
          {...other}
          ref={ref}
          classes={{
            ...classes,
            root: cx(
              css`
                top: 5px;
                transform: none;
              `,
              fileListItemSecondaryActionClasses.root,
              classes?.root,
            ),
          }}
        />
      )}
    </ClassNames>
  );
});
