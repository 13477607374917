import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type ChipBaseClassKey =
  | 'root'
  | 'disabled'
  | 'clickable'
  | 'deletable'
  | 'avatar'
  | 'icon'
  | 'label'
  | 'deleteIcon'
  | 'focusVisible';

export type ChipBaseClasses = Record<ChipBaseClassKey, string>;

export const chipBaseClasses: ChipBaseClasses = generateUtilityClasses(
  'ChipBase',
  [
    'root',
    'disabled',
    'clickable',
    'deletable',
    'avatar',
    'icon',
    'label',
    'deleteIcon',
    'focusVisible',
  ],
);
