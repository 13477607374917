import {
  listItemSecondaryActionClasses as muiListItemSecondaryActionClasses,
  ListItemSecondaryActionClassKey as MuiListItemSecondaryActionClassKey,
} from '@mui/material/ListItemSecondaryAction';
import { omit } from 'lodash-es';

export type ListItemSecondaryActionClassKey = Exclude<
  MuiListItemSecondaryActionClassKey,
  'disableGutters'
>;
export type ListItemSecondaryActionClasses = Record<
  ListItemSecondaryActionClassKey,
  string
>;

export const listItemSecondaryActionClasses: ListItemSecondaryActionClasses =
  omit(muiListItemSecondaryActionClasses, ['disableGutters']);
