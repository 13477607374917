import mixpanel, {
  Mixpanel,
  RegisterOptions,
  RequestOptions,
  Response,
} from 'mixpanel-browser';
import { AlliAppMarketAnalyticsEventTypeMap } from './alli-app-market-analytics-event-type-map';
import { AlliAppMarketAnalyticsOptions } from './alli-app-market-analytics-options';

export class AlliAppMarketAnalytics {
  private static defaultName = 'alli-app-market-analytics';
  private mixpanel: Mixpanel | null = null;

  constructor(options: AlliAppMarketAnalyticsOptions) {
    if (options.mixpanel) {
      this.mixpanel = mixpanel.init(
        options.mixpanel.token,
        options.mixpanel.config ?? {},
        options.mixpanel.name || AlliAppMarketAnalytics.defaultName,
      );
    }
  }

  public setUserId(id: string | null) {
    if (id === null) {
      this.mixpanel?.reset();
    } else {
      this.mixpanel?.identify(id);
    }
  }

  public get user() {
    return {
      mixpanel: this.mixpanel?.people,
    };
  }

  public async track<K extends keyof AlliAppMarketAnalyticsEventTypeMap>(
    name: K,
    properties?: AlliAppMarketAnalyticsEventTypeMap[K],
    options?: { mixpanel?: RequestOptions },
  ) {
    const mixpanel = await new Promise<Response | void>(resolve => {
      if (this.mixpanel) {
        this.mixpanel?.track(
          name,
          properties || undefined,
          options?.mixpanel,
          resolve,
        );
      } else {
        resolve();
      }
    });

    return { mixpanel };
  }

  public async register(
    props: { [key: string]: any },
    options?: { mixpanel?: number | Partial<RegisterOptions> },
  ) {
    this.mixpanel?.register(props, options?.mixpanel);
  }

  public async unregister(
    property: string,
    options?: { mixpanel?: Partial<RegisterOptions> },
  ) {
    this.mixpanel?.unregister(property, options?.mixpanel);
  }

  public reset() {
    this.mixpanel?.reset();
  }
}
