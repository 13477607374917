import { Divider } from '@allganize/ui-divider';
import { ClassNames } from '@emotion/react';
import { resolveComponentProps } from '@mui/base/utils';
import { DateTimeViewRendererProps as MuiDateTimeViewRendererProps } from '@mui/x-date-pickers/dateTimeViewRenderers';
import { Fragment } from 'react';
import { DateCalendar } from './date-calendar';
import { DateTimePickerProps } from './date-time-picker';
import { DateTimeViewWrapper } from './date-time-view-wrapper';
import { digitalClockClasses } from './digital-clock';
import { VIEW_HEIGHT } from './internals/constants/dimensions';
import { isDatePickerView } from './internals/utils/date-utils';
import { isInternalTimeView } from './internals/utils/time-utils';
import { multiSectionDigitalClockSectionClasses } from './multi-section-digital-clock-section';
import {
  renderDigitalClockTimeView,
  renderMultiSectionDigitalClockTimeView,
} from './time-view-renderers';

export interface DateTimeViewRendererProps
  extends Omit<
      MuiDateTimeViewRendererProps<Date>,
      | 'shouldDisableClock'
      | 'sx'
      | 'components'
      | 'componentsProps'
      | 'slots'
      | 'slotProps'
    >,
    Pick<DateTimePickerProps, 'slots' | 'slotProps'> {}

export const renderDesktopDateTimeView = ({
  view,
  onViewChange,
  views,
  focusedView,
  onFocusedViewChange,
  value,
  defaultValue,
  referenceDate,
  onChange,
  className,
  classes,
  disableFuture,
  disablePast,
  minDate,
  minTime,
  maxDate,
  maxTime,
  shouldDisableDate,
  shouldDisableMonth,
  shouldDisableYear,
  shouldDisableTime,
  reduceAnimations,
  minutesStep,
  ampm,
  onMonthChange,
  monthsPerRow,
  onYearChange,
  yearsPerRow,
  defaultCalendarMonth,
  slots,
  slotProps,
  loading,
  renderLoading,
  disableHighlightToday,
  readOnly,
  disabled,
  showDaysOutsideCurrentMonth,
  dayOfWeekFormatter,
  autoFocus,
  fixedWeekNumber,
  displayWeekNumber,
  timezone,
  disableIgnoringDatePartForTimeValidation,
  timeSteps,
  skipDisabled,
  timeViewsCount,
  shouldRenderTimeInASingleColumn,
}: DateTimeViewRendererProps) => {
  const isActionBarVisible = !!resolveComponentProps(
    slotProps?.actionBar,
    {} as any,
  )?.actions?.length;
  const commonTimeProps = {
    view: isInternalTimeView(view) ? view : 'hours',
    onViewChange,
    focusedView:
      focusedView && isInternalTimeView(focusedView) ? focusedView : null,
    onFocusedViewChange,
    views: views.filter(isInternalTimeView),
    value,
    defaultValue,
    referenceDate,
    onChange,
    classes,
    disableFuture,
    disablePast,
    minTime,
    maxTime,
    shouldDisableTime,
    minutesStep,
    ampm,
    slots,
    slotProps,
    readOnly,
    disabled,
    autoFocus,
    disableIgnoringDatePartForTimeValidation,
    timeSteps,
    skipDisabled,
    timezone,
  };

  return (
    <Fragment>
      <DateTimeViewWrapper>
        <DateCalendar
          view={isDatePickerView(view) ? view : 'day'}
          onViewChange={onViewChange}
          views={views.filter(isDatePickerView)}
          focusedView={
            focusedView && isDatePickerView(focusedView) ? focusedView : null
          }
          onFocusedViewChange={onFocusedViewChange}
          value={value}
          defaultValue={defaultValue}
          referenceDate={referenceDate}
          onChange={onChange}
          className={className}
          classes={classes}
          disableFuture={disableFuture}
          disablePast={disablePast}
          minDate={minDate}
          maxDate={maxDate}
          shouldDisableDate={shouldDisableDate}
          shouldDisableMonth={shouldDisableMonth}
          shouldDisableYear={shouldDisableYear}
          reduceAnimations={reduceAnimations}
          onMonthChange={onMonthChange}
          monthsPerRow={monthsPerRow}
          onYearChange={onYearChange}
          yearsPerRow={yearsPerRow}
          defaultCalendarMonth={defaultCalendarMonth}
          slots={slots}
          slotProps={slotProps}
          loading={loading}
          renderLoading={renderLoading}
          disableHighlightToday={disableHighlightToday}
          readOnly={readOnly}
          disabled={disabled}
          showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
          dayOfWeekFormatter={dayOfWeekFormatter}
          autoFocus={autoFocus}
          fixedWeekNumber={fixedWeekNumber}
          displayWeekNumber={displayWeekNumber}
          timezone={timezone}
        />
        {timeViewsCount > 0 && (
          <Fragment>
            <Divider orientation="vertical" />

            <ClassNames>
              {({ css, cx }) => {
                return shouldRenderTimeInASingleColumn
                  ? renderDigitalClockTimeView({
                      ...commonTimeProps,
                      view: 'hours',
                      views: ['hours'],
                      focusedView:
                        focusedView && isInternalTimeView(focusedView)
                          ? 'hours'
                          : null,
                      className: cx(
                        css`
                          width: auto;

                          &.${digitalClockClasses.root} {
                            max-height: ${VIEW_HEIGHT}px;
                          }
                        `,
                        className,
                      ),
                    })
                  : renderMultiSectionDigitalClockTimeView({
                      ...commonTimeProps,
                      view: isInternalTimeView(view) ? view : 'hours',
                      views: views.filter(isInternalTimeView),
                      focusedView:
                        focusedView && isInternalTimeView(focusedView)
                          ? focusedView
                          : null,
                      className: cx(
                        css`
                          border-bottom: 0;
                          width: auto;

                          .${multiSectionDigitalClockSectionClasses.root} {
                            max-height: 100%;
                          }
                        `,
                        className,
                      ),
                    });
              }}
            </ClassNames>
          </Fragment>
        )}
      </DateTimeViewWrapper>

      {isActionBarVisible && <Divider />}
    </Fragment>
  );
};
