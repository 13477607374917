import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type DocumentViewerHeaderClassKey = 'root' | 'hidden';
export type DocumentViewerHeaderClasses = Record<
  DocumentViewerHeaderClassKey,
  string
>;

export const documentViewerHeaderClasses: DocumentViewerHeaderClasses =
  generateUtilityClasses('DocumentViewerHeader', ['root', 'hidden']);
