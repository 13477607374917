import { Chip } from '@allganize/ui-chip';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent, HTMLAttributes } from 'react';
import { resetUlStyles } from '../styles/reset';

interface KnowledgeBaseHashtagListProps
  extends HTMLAttributes<HTMLUListElement> {
  data: string[];
  onClickHashtag?: (hashtag: string) => void;
}

export const KnowledgeBaseHashtagList: FunctionComponent<
  KnowledgeBaseHashtagListProps
> = ({ data, onClickHashtag, ...other }) => {
  const theme = useTheme();

  if (data.length === 0) {
    return null;
  }

  return (
    <ul
      css={[
        resetUlStyles,
        css`
          display: flex;
          gap: 8px;
          flex-wrap: wrap;
          padding: 8px;
          border-bottom: 1px solid ${theme.palette.border.divider.subtle};

          li {
            max-width: 100%;
          }
        `,
      ]}
      {...other}
    >
      {data.map(hashtag => (
        <li key={hashtag}>
          <Chip
            label={`# ${hashtag}`}
            onClick={() => onClickHashtag?.(hashtag)}
          />
        </li>
      ))}
    </ul>
  );
};
