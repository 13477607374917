import { useImageLoaded } from '@allganize/hooks';
import { Truncate } from '@allganize/truncate';
import { ButtonBase, buttonBaseClasses } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { IcDescription } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { FormattedNumber } from 'react-intl';
import { thumbnailHeight, thumbnailWidth } from './constants';
import { documentViewerPageThumbnailClasses } from './document-viewer-page-thumbnail-classes';
import { DocumentViewerPageThumbnailProps } from './document-viewer-page-thumbnail-type-map';

export const DocumentViewerPageThumbnail = forwardRef<
  HTMLButtonElement,
  DocumentViewerPageThumbnailProps
>((props, ref) => {
  const { classes, index, page, selected = false, ...other } = props;
  const theme = useTheme();
  const loaded = useImageLoaded({ src: page.src });

  return (
    <ButtonBase
      data-testid="document-viewer-page-thumbnail"
      css={[
        css`
          flex-direction: column;
          align-items: stretch;
          width: ${thumbnailWidth}px;
          height: ${thumbnailHeight}px;
          padding: 4px;
          padding-bottom: 0;
          transition: ${theme.transitions.create('background-color', {
            duration: theme.transitions.duration.short,
          })};

          &:hover,
          &.${buttonBaseClasses.focusVisible} {
            background-color: ${theme.palette.grayAlpha[200]};
          }

          &:active {
            background-color: ${theme.palette.grayAlpha[300]};
          }

          &.${buttonBaseClasses.disabled} {
            background-color: transparent;
          }
        `,
        selected &&
          css`
            &,
            &:hover,
            &.${buttonBaseClasses.focusVisible}, &:active {
              background-color: ${theme.palette.grayAlpha[400]};
            }
          `,
      ]}
      {...other}
      ref={ref}
      className={clsx(
        documentViewerPageThumbnailClasses.root,
        {
          [documentViewerPageThumbnailClasses.loaded]: loaded === 'loaded',
          [documentViewerPageThumbnailClasses.error]: loaded === 'error',
          [documentViewerPageThumbnailClasses.loading]: !loaded,
          [documentViewerPageThumbnailClasses.selected]: selected,
        },
        classes?.root,
        {
          [classes?.loaded ?? '']: loaded === 'loaded',
          [classes?.error ?? '']: loaded === 'error',
          [classes?.loading ?? '']: !loaded,
          [classes?.selected ?? '']: selected,
        },
        other.className,
      )}
    >
      <div
        css={css`
          background-color: ${theme.palette.grey[200]};
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: ${thumbnailWidth - 8}px;
          height: 79px;
        `}
        className={clsx(
          documentViewerPageThumbnailClasses.image,
          classes?.image,
        )}
      >
        {loaded === 'loaded' && (
          <img
            css={css`
              display: block;
              width: 100%;
              height: 100%;
              user-select: none;
              object-fit: contain;
              word-break: break-all;
              background-color: ${theme.palette.common.white};
            `}
            src={page.src}
            alt={page.alt || `Page ${index + 1}`}
          />
        )}

        {loaded === 'error' && (
          <span
            css={css`
              color: ${theme.palette.text.secondary};
            `}
          >
            <IcDescription color="inherit" fontSize="large" />
          </span>
        )}

        {!loaded && <CircularProgress color="primary" />}
      </div>

      <Text
        css={css`
          word-break: break-all;
        `}
        component="div"
        variant="body12"
        className={clsx(
          documentViewerPageThumbnailClasses.image,
          classes?.image,
        )}
      >
        <Truncate clamp={1}>
          <FormattedNumber value={index + 1} />
        </Truncate>
      </Text>
    </ButtonBase>
  );
});
