import { ContentBlock, ContentState } from 'draft-js';

export const removeBlockIfEmpty = (
  contentState: ContentState,
  blockOrKey: string | ContentBlock,
) => {
  const contentBlock =
    typeof blockOrKey === 'string'
      ? contentState.getBlockForKey(blockOrKey)
      : blockOrKey;

  if (!contentBlock) {
    return contentState;
  }

  const blockKey = contentBlock.getKey();
  const text = contentBlock.getText();

  if (text.length > 0) {
    return contentState;
  }

  const entityMap = contentState.getEntityMap();
  const blocks = contentState
    .getBlocksAsArray()
    .filter(block => block.getKey() !== blockKey);
  return ContentState.createFromBlockArray(blocks, entityMap);
};
