import { ModalProps } from '@allganize/ui-modal';
import { EmotionCache } from '@emotion/cache';
import { createContext } from 'react';

export interface ModalContextValue {
  emotionCache?: EmotionCache;
  container?: ModalProps['container'];
}

export const ModalContext = createContext<ModalContextValue>({});
