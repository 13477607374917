import { OverridableComponent } from '@allganize/types';
import { createSvgIcon } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import MuiAvatar from '@mui/material/Avatar';
import { camelCase } from 'lodash-es';
import { forwardRef } from 'react';
import { avatarClasses } from './avatar-classes';
import { AvatarTypeMap } from './avatar-type-map';

const Person = createSvgIcon(
  <path d="M11.9987 14.6634C13.4715 14.6634 14.6654 13.4694 14.6654 11.9967C14.6654 10.5239 13.4715 9.33002 11.9987 9.33002C10.5259 9.33002 9.33203 10.5239 9.33203 11.9967C9.33203 13.4694 10.5259 14.6634 11.9987 14.6634ZM11.9987 15.9967C8.3168 15.9967 5.33203 18.9815 5.33203 22.6634V23.9967H18.6654V22.6634C18.6654 18.9815 15.6806 15.9967 11.9987 15.9967Z" />,
  'Person',
);

// @ts-expect-error overridable component
export const Avatar: OverridableComponent<AvatarTypeMap> = forwardRef(
  (props, ref) => {
    const theme = useTheme();
    const {
      children,
      classes,
      size = 'medium',
      variant = 'rounded',
      ...other
    } = props;
    const hasImg = other.src || other.srcSet;

    return (
      <ClassNames>
        {({ css, cx }) => (
          <MuiAvatar
            data-testid="avatar"
            variant={variant}
            {...other}
            ref={ref}
            className={cx(
              avatarClasses[
                camelCase(`size_${size}`) as keyof typeof avatarClasses
              ],
              classes?.[camelCase(`size_${size}`) as keyof typeof classes],
              other.className,
            )}
            classes={{
              ...classes,
              root: cx(
                size === 'smallest' &&
                  css`
                    ${theme.typography.title12}
                    width: 20px;
                    height: 20px;
                  `,
                size === 'small' &&
                  css`
                    ${theme.typography.title12}
                    width: 24px;
                    height: 24px;
                  `,
                size === 'medium' &&
                  css`
                    ${theme.typography.title14}
                    width: 36px;
                    height: 36px;
                  `,
                size === 'large' &&
                  css`
                    ${theme.typography.title16}
                    width: 48px;
                    height: 48px;
                  `,
                classes?.root,
              ),
              colorDefault: cx(
                css`
                  color: ${theme.palette.foregroundInteractive.gray.subtle};
                  background-color: ${theme.palette.backgroundInteractive.gray
                    .default};
                `,
                classes?.colorDefault,
              ),
              rounded: cx(
                css`
                  border-radius: ${theme.radius.sm}px;
                `,
                classes?.rounded,
              ),
            }}
          >
            {children || (hasImg && other.alt?.[0]) || (
              <Person
                className={cx(
                  avatarClasses.fallback,
                  css`
                    width: 100%;
                    height: 100%;
                  `,
                  classes?.fallback,
                )}
              />
            )}
          </MuiAvatar>
        )}
      </ClassNames>
    );
  },
);
