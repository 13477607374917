/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type KnowledgeBasesCountQueryVariables = UserQueryTypes.Exact<{
  where: UserQueryTypes.ProjectWhereUniqueInput;
}>;

export type KnowledgeBasesCountQuery = { __typename: 'UserQuery' } & {
  knowledgeBases: UserQueryTypes.Maybe<
    { __typename: 'KnowledgeBaseList' } & Pick<
      UserQueryTypes.KnowledgeBaseList,
      'count'
    >
  >;
};

export const KnowledgeBasesCountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'KnowledgeBasesCountQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectWhereUniqueInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'knowledgeBases' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  KnowledgeBasesCountQuery,
  KnowledgeBasesCountQueryVariables
>;
