import { useDraftToolbarFocus } from '@allganize/draft-toolbar-plugin';
import { useCombinedRef, useEventCallback, useId } from '@allganize/hooks';
import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { IcAttachment } from '@allganize/ui-icons';
import { Popover, PopoverOrigin, popoverClasses } from '@allganize/ui-modal';
import { Tooltip } from '@allganize/ui-tooltip';
import { raf } from '@allganize/utils-timeout';
import { css } from '@emotion/react';
import {
  Fragment,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DraftToolbarFileForm,
  DraftToolbarFileFormActions,
  DraftToolbarFileFormProps,
} from './draft-toolbar-file-form';
import { DraftToolbarFileFormValues } from './use-draft-toolbar-file-form';

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const transformOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

export interface DraftToolbarFileButtonActions
  extends DraftToolbarFileFormActions {
  open(): void;
}

interface DraftToolbarFileButtonProps<
  T extends { type: 'image' | 'file'; url: string },
> extends Pick<DraftToolbarFileFormProps<T>, 'validation' | 'onSubmit'>,
    Omit<IconButtonProps, 'onSubmit'> {
  actions?: React.Ref<DraftToolbarFileButtonActions>;
}

interface DraftToolbarFileButtonType {
  <T extends { type: 'image' | 'file'; url: string }>(
    props: React.PropsWithoutRef<DraftToolbarFileButtonProps<T>> &
      React.RefAttributes<HTMLButtonElement>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

export const DraftToolbarFileButton: DraftToolbarFileButtonType = forwardRef(
  (props, ref) => {
    const { actions, validation, onSubmit, ...other } = props;
    const focus = useDraftToolbarFocus();
    const [popoverOpen, setPopoverOpen] = useState(false);
    const popoverId = useId();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const combinedRef = useCombinedRef(buttonRef, ref);
    const formActionsRef = useRef<DraftToolbarFileFormActions>(null);

    const openPopover = useEventCallback(() => {
      setPopoverOpen(true);
    });

    const closePopover = () => {
      setPopoverOpen(false);
      focus();
    };

    const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      setPopoverOpen(prev => !prev);
      props.onClick?.(ev);
    };

    const handleFormSubmit = async (values: DraftToolbarFileFormValues) => {
      const result = await onSubmit(values);
      await raf();
      closePopover();
      return result;
    };

    useImperativeHandle(
      actions,
      () => ({
        open: openPopover,
        async setFile(file) {
          openPopover();
          await raf();
          formActionsRef.current?.setFile(file);
        },
      }),
      [openPopover],
    );

    return (
      <Fragment>
        <Tooltip
          title={
            <FormattedMessage
              id="TextArea.FileButton.Tooltip"
              defaultMessage="Insert File"
              description="Text area insert file button tooltip text"
            />
          }
        >
          <IconButton
            aria-owns={popoverOpen ? popoverId : undefined}
            aria-haspopup="true"
            size="small"
            {...other}
            ref={combinedRef}
            onClick={handleClick}
          >
            <IcAttachment fontSize="small" />
          </IconButton>
        </Tooltip>

        <Popover
          id={popoverId}
          open={popoverOpen}
          anchorEl={buttonRef.current}
          onClose={closePopover}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          css={css`
            .${popoverClasses.paper} {
              width: 320px;
            }
          `}
        >
          <DraftToolbarFileForm
            validation={validation}
            onSubmit={handleFormSubmit}
            actions={formActionsRef}
          />
        </Popover>
      </Fragment>
    );
  },
);
