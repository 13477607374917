import { AgentQueryTypes } from '@allganize/alli-interfaces';
import { noop } from 'lodash-es';
import { createContext } from 'react';
import { DownloadFileFormValues } from '../download-file-dialog/download-file-form-values';

interface DownloadFile {
  status: AgentQueryTypes.PrepareFileStatusEnum | null;
  download: (values: DownloadFileFormValues) => void;
  downloadFile: () => void;
}

export const DownloadFileContext = createContext<DownloadFile>({
  status: null,
  download: noop,
  downloadFile: noop,
});
