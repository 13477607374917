import { Ref, RefCallback } from 'react';

export const combineRefs = <T>(...refs: Ref<T>[]): RefCallback<T> => {
  return (val: T | null) => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(val);
      } else if (ref) {
        // @ts-expect-error read only property
        ref.current = val;
      }
    });
  };
};
