import {
  DraftDocumentContext,
  DraftDocumentEntity,
  DraftDocumentLinkBase,
} from '@allganize/draft-document-plugin';
import { DraftLinkBase, DraftLinkContext } from '@allganize/draft-link-plugin';
import { forwardRef, useContext } from 'react';
import { ReactMarkdownProps } from 'react-markdown/lib/ast-to-react';

export const Cite = forwardRef<
  HTMLElement,
  React.HTMLAttributes<HTMLElement> & ReactMarkdownProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, ...other } = props;
  const { onClick } = useContext(DraftDocumentContext);
  const draftLink = useContext(DraftLinkContext);

  if (!node.data?.citation) {
    return <cite {...other} ref={ref} />;
  }

  if (!onClick) {
    const href =
      (node.data.citation as DraftDocumentEntity).data.url ?? undefined;

    return (
      <DraftLinkBase href={href} target={draftLink.getLinkTarget(href)}>
        {other.children}
      </DraftLinkBase>
    );
  }

  return (
    <DraftDocumentLinkBase
      entity={node.data.citation as DraftDocumentEntity}
      label={other.children}
    />
  );
});
