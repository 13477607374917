import { useTheme } from '@emotion/react';
import { useMemo } from 'react';
import { emotionCacheOptions } from '../styles/emotion-cache';
import { memoizedCreateCache } from '../styles/emotion-cache-provider';

export const useAlliModalContainer = () => {
  const theme = useTheme();

  return useMemo(
    () => ({
      emotionCache: memoizedCreateCache(emotionCacheOptions),
      container: () => document.getElementById(theme.modalContainer.id),
    }),
    [theme.modalContainer.id],
  );
};
