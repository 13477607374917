import MuiFormGroup from '@mui/material/FormGroup';
import { forwardRef } from 'react';
import { FormGroupProps } from './form-group-type-map';
import { css } from '@emotion/react';

export const FormGroup = forwardRef<HTMLDivElement, FormGroupProps>(
  (props, ref) => {
    return (
      <MuiFormGroup
        css={css`
          gap: 8px;
        `}
        data-testid="form-group"
        {...props}
        ref={ref}
      />
    );
  },
);
