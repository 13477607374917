import { ChatForm } from '@allganize/alli-sdk/chat-form/chat-form';
import { css, useTheme } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { useProject } from '@allganize/alli-app-market-project';
import { contentMaxWidth } from './conversation-detail-layout';

export const Footer = () => {
  const theme = useTheme();
  const project = useProject();

  return (
    <footer
      css={css`
        position: sticky;
        bottom: 0;
        max-width: ${contentMaxWidth}px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 12px;
        background-color: ${theme.palette.unstable_background.white};
      `}
    >
      <ChatForm />
      {project.useCautionMessage && (
        <div
          css={css`
            text-align: center;
            font-size: 10px;
            line-height: 10px;
            font-weight: 500;
            color: ${theme.palette.foreground.placeholder};
          `}
        >
          <FormattedMessage
            id="caution-message.text"
            defaultMessage="Please one more check the answer, it may be inaccurate."
            description="caution message to check AI answer"
          />
        </div>
      )}
    </footer>
  );
};
