import { useCombinedRef, useEnhancedEffect } from '@allganize/hooks';
import { formControlState } from '@allganize/ui-form';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { useFormControl } from '@mui/material/FormControl';
import clsx from 'clsx';
import { mapValues } from 'lodash-es';
import { lighten } from 'polished';
import React, { forwardRef, useRef, useState } from 'react';
import {
  DraftInputBase,
  DraftInputBaseRef,
  draftInputInternalClasses,
} from '../draft-input-base';
import { draftInputClasses } from './draft-input-classes';
import { DraftInputProps, DraftInputRef } from './draft-input-type-map';

export const DraftInput = forwardRef<DraftInputRef, DraftInputProps>(
  (props, ref) => {
    const {
      autoFocus = false,
      classes,
      color: colorProp,
      disabled: disabledProp,
      error: errorProp,
      onBlur,
      onFocus,
      rootProps,
      ...other
    } = props;
    const theme = useTheme();
    const baseRef = useRef<DraftInputBaseRef>(null);
    const combinedRef = useCombinedRef(baseRef, ref);
    const muiFormControl = useFormControl();
    const [_focused, setFocused] = useState(false);
    const focused = _focused || muiFormControl?.focused;
    const fcs = formControlState({
      props,
      muiFormControl,
      states: ['color', 'disabled', 'error'],
    });
    const color = fcs.color || 'primary';
    const disabled = fcs.disabled || false;

    useEnhancedEffect(() => {
      if (!autoFocus) {
        return;
      }

      baseRef.current?.focus();
    }, [autoFocus]);

    const handleFocus = (e: React.SyntheticEvent) => {
      setFocused(true);
      onFocus?.(e);
    };

    const handleBlur = (e: React.SyntheticEvent) => {
      setFocused(false);
      onBlur?.(e);
    };

    return (
      <DraftInputBase
        webDriverTestID="draft-input__content"
        css={css`
          display: flex;
          flex-direction: column;
          ${theme.typography.body14}
          color: ${theme.palette.text.primary};
          border-radius: ${theme.radius.sm}px;
          border: 1px solid ${theme.palette.grayAlpha[200]};

          .${draftInputInternalClasses.content} {
            padding: 7px 11px;
          }

          .${draftInputInternalClasses.placeholderRoot} {
            position: absolute;
            padding: 8px 12px;
            top: 0;
            left: 0;
            right: 0;
            color: ${theme.palette.text.secondary};
            z-index: -1;
            user-select: none;
          }

          .${draftInputInternalClasses.placeholderInner} {
            overflow: hidden;
          }

          :hover {
            border-color: ${theme.palette.grayAlpha[500]};

            @media (hover: none) {
              border-color: ${theme.palette.grayAlpha[200]};
            }
          }

          &.${draftInputClasses.focused} {
            border-color: ${theme.palette[color].main};
            box-shadow: 0 0 0 2px ${lighten(0.3, theme.palette[color].main)};
          }

          &.${draftInputClasses.error} {
            border-color: ${theme.palette.error.main};
            box-shadow: 0 0 0 2px ${lighten(0.3, theme.palette.error.main)};
          }

          &.${draftInputClasses.disabled} {
            background-color: ${theme.palette.grayAlpha[50]};
            border-color: ${theme.palette.grayAlpha[100]};
            box-shadow: none;

            [data-contents='true'] {
              opacity: 0.4;
            }
          }
        `}
        {...other}
        classes={mapValues(
          draftInputClasses,
          <T extends keyof typeof draftInputClasses>(
            val: (typeof draftInputClasses)[T],
            key: T,
          ) => clsx(val, classes?.[key]),
        )}
        className={clsx(
          {
            [draftInputClasses.focused]: focused,
            [draftInputClasses.error]: fcs.error,
          },
          {
            [classes?.focused ?? '']: focused,
            [classes?.error ?? '']: fcs.error,
          },
          other.className,
        )}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={combinedRef}
        rootProps={{
          'data-testid': 'draft-input',
          ...rootProps,
        }}
      />
    );
  },
);
