import { List } from '@allganize/ui-list';
import clsx from 'clsx';
import { GroupBase, GroupProps } from 'react-select';
import { selectClasses } from './select-classes';

export const Group = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: GroupProps<Option, IsMulti, Group>,
) => {
  const {
    children,
    className,
    cx,
    getStyles,
    getClassNames,
    Heading,
    headingProps,
    innerProps,
    label,
    theme,
    selectProps,
  } = props;

  return (
    <List
      component="div"
      disablePadding
      {...innerProps}
      subheader={
        <Heading
          {...headingProps}
          selectProps={selectProps}
          theme={theme}
          getStyles={getStyles}
          getClassNames={getClassNames}
          cx={cx}
        >
          {label}
        </Heading>
      }
      ref={null}
      className={clsx(
        selectClasses.group,
        getClassNames('group', props),
        className,
        innerProps?.className,
      )}
    >
      {children}
    </List>
  );
};
