import {
  InputField as InternalInputField,
  InputFieldProps as InternalInputFieldProps,
} from '@allganize/ui-input';
import { forwardRef } from 'react';
import { TimeFieldOwnerState } from './time-field-type-map';

interface InputFieldProps extends InternalInputFieldProps {
  ownerState: TimeFieldOwnerState;
}

export const InputField = forwardRef<HTMLDivElement, InputFieldProps>(
  (props, ref) => {
    const { ownerState, ...other } = props;

    return <InternalInputField {...other} ref={ref} />;
  },
);
