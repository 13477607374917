import { DraftPlugin, DraftStyleMap } from '@allganize/draft-input';

export class DraftTextStylePlugin extends DraftPlugin {
  public getCustomStyleMap(): DraftStyleMap {
    return {
      BOLD: {
        fontWeight: 'bold',
      },
      ITALIC: {
        fontStyle: 'italic',
      },
    };
  }
}
