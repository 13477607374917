import { AgentQueryTypes } from '@allganize/alli-interfaces';
import { Option } from '../contexts/app-context';

export const getIsDocumentInput = (
  inputType: AgentQueryTypes.SingleActionInputType,
  knowledgeBases?: Option[],
) => {
  return {
    isDocumentInput:
      inputType === AgentQueryTypes.SingleActionInputType.DOCUMENT_BASIC ||
      inputType === AgentQueryTypes.SingleActionInputType.DOCUMENT_ADVANCED ||
      inputType === AgentQueryTypes.SingleActionInputType.IMAGE_BASIC ||
      inputType === AgentQueryTypes.SingleActionInputType.IMAGE_ADVANCED,
    selectMode: !!knowledgeBases && knowledgeBases.length > 0,
  };
};
