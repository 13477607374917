import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type SelectClassKey =
  | 'clearIndicator'
  | 'container'
  | 'control'
  | 'controlOpen'
  | 'dropdownIndicator'
  | 'group'
  | 'groupHeading'
  | 'indicatorsContainer'
  | 'input'
  | 'inputHidden'
  | 'loadingIndicator'
  | 'loadingMessage'
  | 'menu'
  | 'menuPlacementTop'
  | 'menuPlacementBottom'
  | 'menuList'
  | 'menuPortal'
  | 'multiValue'
  | 'multiValueLabel'
  | 'multiValueRemove'
  | 'noOptionsMessage'
  | 'option'
  | 'placeholder'
  | 'singleValue'
  | 'valueContainer'
  | 'empty'
  | 'focused'
  | 'selected'
  | 'disabled'
  | 'multiple'
  | 'rtl'
  | 'error';
export type SelectClasses = Record<SelectClassKey, string>;

export const selectClasses: SelectClasses = generateUtilityClasses('Select', [
  'clearIndicator',
  'container',
  'control',
  'controlOpen',
  'dropdownIndicator',
  'group',
  'groupHeading',
  'indicatorsContainer',
  'input',
  'inputHidden',
  'loadingIndicator',
  'loadingMessage',
  'menu',
  'menuPlacementTop',
  'menuPlacementBottom',
  'menuList',
  'menuPortal',
  'multiValue',
  'multiValueLabel',
  'multiValueRemove',
  'noOptionsMessage',
  'option',
  'placeholder',
  'singleValue',
  'valueContainer',
  'empty',
  'focused',
  'selected',
  'disabled',
  'multiple',
  'rtl',
  'error',
]);
