import { Types } from '@allganize/alli-sdk-interfaces';
import EventEmitter from 'eventemitter3';

export type AlliUserVariableValue =
  | string
  | number
  | boolean
  | undefined
  | null;
export type AlliUserVariables = Record<string, AlliUserVariableValue>;

export interface AlliUser {
  id: string | number | null;
  variables: AlliUserVariables;
}

export interface AlliUserManagerUpdateEventPayload {
  prev: AlliUser;
  next: AlliUser;
}

interface AlliUserManagerEvents {
  ['new-user'](payload: AlliUserManagerUpdateEventPayload): void;
  update(payload: AlliUserManagerUpdateEventPayload): void;
}

export class AlliUserManager extends EventEmitter<AlliUserManagerEvents> {
  public static variablesToInput(
    variables: AlliUserVariables,
  ): Types.VariableInput[] {
    return Object.entries(variables).map<Types.VariableInput>(
      ([key, value]) => ({
        name: key,
        value: value === null || typeof value === 'undefined' ? '' : `${value}`,
      }),
    );
  }

  private static isSameUser(prev: AlliUser, next: AlliUser) {
    return prev.id === next?.id;
  }

  private _user: AlliUser = { id: null, variables: {} };

  public get user() {
    return this._user;
  }

  public setId(id: string | number | null) {
    this.setUser({ ...this._user, id });
  }

  public setVariable(key: string, value: AlliUserVariableValue) {
    this.setVariables({ ...this._user.variables, [key]: value });
  }

  public setVariables(variables: AlliUserVariables) {
    this.setUser({
      ...this._user,
      variables: { ...this._user.variables, ...variables },
    });
  }

  public setUser(user: AlliUser | null) {
    const prev = this._user;
    this._user = user ?? { id: null, variables: {} };

    if (AlliUserManager.isSameUser(prev, this._user)) {
      this.emit('update', { prev, next: this._user });
    } else {
      this.emit('new-user', { prev, next: this._user });
    }
  }
}
