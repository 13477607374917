import { useTheme } from '@allganize/ui-theme';
import { useSlotProps } from '@mui/base/utils';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import { forwardRef } from 'react';
import { useClearableField } from '../hooks/use-clearable-field';
import { InputField as InternalInputField } from './date-field-slots';
import {
  DateFieldProps,
  DateFieldSlotsComponent,
  DateFieldSlotsComponentsProps,
} from './date-field-type-map';
import { useDateField } from './use-date-field';

export const DateField = forwardRef<HTMLDivElement, DateFieldProps>(
  (props, ref) => {
    const { slots, slotProps, InputProps, inputProps, ...other } = props;
    const theme = useTheme();
    const muiTheme = useMuiTheme();
    const ownerState = props;

    const InputField = slots?.inputField ?? InternalInputField;
    const { inputRef: externalInputRef, ...inputFieldProps }: DateFieldProps =
      useSlotProps<
        typeof InputField,
        DateFieldSlotsComponentsProps['inputField'],
        DateFieldProps,
        DateFieldProps
      >({
        elementType: InputField,
        externalSlotProps: slotProps?.inputField,
        externalForwardedProps: other,
        ownerState,
      });

    // TODO: Remove when mui/material-ui#35088 will be merged
    inputFieldProps.inputProps = {
      ...inputProps,
      ...inputFieldProps.inputProps,
    };
    inputFieldProps.InputProps = {
      ...InputProps,
      ...inputFieldProps.InputProps,
    };

    const {
      ref: inputRef,
      onPaste,
      onKeyDown,
      inputMode,
      readOnly,
      clearable,
      onClear,
      ...fieldProps
    } = useDateField<typeof inputFieldProps>({
      props: inputFieldProps,
      inputRef: externalInputRef,
    });

    const { InputProps: ProcessedInputProps, fieldProps: processedFieldProps } =
      useClearableField<
        typeof fieldProps,
        typeof fieldProps.InputProps,
        DateFieldSlotsComponent,
        DateFieldSlotsComponentsProps
      >({
        onClear,
        clearable,
        fieldProps,
        InputProps: fieldProps.InputProps,
        slots,
        slotProps,
      });

    return (
      <ThemeProvider theme={{ ...muiTheme, direction: theme.direction }}>
        <InputField
          data-testid="date-field"
          ref={ref}
          {...processedFieldProps}
          InputProps={{ ...ProcessedInputProps, readOnly }}
          inputProps={{
            ...fieldProps.inputProps,
            inputMode,
            onPaste,
            onKeyDown,
            ref: inputRef,
          }}
        />
      </ThemeProvider>
    );
  },
);
