export type RadiusVariant = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'round';

export type Radius = Record<RadiusVariant, number>;

export const radius: Radius = {
  xs: 4,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 20,
  round: 9999,
};
