/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UserTypingMutationVariables = Types.Exact<{
  isTyping: Types.Scalars['Boolean']['input'];
  where: Types.ConversationWhereUniqueInput;
}>;

export type UserTypingMutation = { __typename: 'UserMutation' } & {
  userTyping: Types.Maybe<
    { __typename: 'UserTyping' } & Pick<Types.UserTyping, 'ok'>
  >;
};

export const UserTypingMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserTypingMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isTyping' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ConversationWhereUniqueInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTyping' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isTyping' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isTyping' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserTypingMutation, UserTypingMutationVariables>;
