import { OverridableComponent } from '@allganize/types';
import { useCombinedRef } from '@allganize/hooks';
import { Text } from '@allganize/ui-text';
import { Theme, useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { useIsFocusVisible } from '@mui/material/utils';
import clsx from 'clsx';
import { camelCase } from 'lodash-es';
import { forwardRef, useState } from 'react';
import { linkClasses } from './link-classes';
import { LinkTypeMap } from './link-type-map';

const linkBaseStyles = (theme: Theme) => css`
  color: ${theme.palette.foregroundInteractive.primary};

  &.${linkClasses.disabled} {
    color: ${theme.palette.foreground.gray.disabled};
    pointer-events: none;
    text-decoration: none;
  }

  &.${linkClasses.focusVisible} {
    // TODO: mixin으로 교체
    outline: ${theme.palette.border.focused} solid 2px;
    outline-offset: 2px;
    border-radius: ${theme.radius.xs}px;
  }
`;

const underlineStyles = (underline: LinkTypeMap['props']['underline']) => [
  underline === 'none' &&
    css`
      text-decoration: none;
    `,
  underline === 'hover' &&
    css`
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    `,
  underline === 'always' &&
    css`
      text-decoration: underline;
    `,
];

const componentStyles = (component: string) => [
  component === 'button' &&
    css`
      position: relative;
      -webkit-tap-highlight-color: transparent;
      background-color: transparent;
      outline: 0;
      border: 0;
      margin: 0;
      border-radius: 0;
      padding: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      -moz-appearance: none;
      -webkit-appearance: none;

      &::-moz-focus-inner {
        border-style: none;
      }

      &.${linkClasses.focusVisible} {
        outline: auto;
      }
    `,
];

// @ts-expect-error overridable component
export const Link: OverridableComponent<LinkTypeMap> = forwardRef(
  (props, ref) => {
    const {
      classes,
      // @ts-expect-error overridable component
      component = 'a',
      onBlur,
      onFocus,
      TextClasses,
      underline = 'hover',
      variant = 'subtitle14',
      disabled = false,
      ...other
    } = props;
    const theme = useTheme();
    const {
      isFocusVisibleRef,
      onBlur: handleBlurVisible,
      onFocus: handleFocusVisible,
      ref: focusVisibleRef,
    } = useIsFocusVisible();
    const [focusVisible, setFocusVisible] = useState(false);
    const handlerRef = useCombinedRef(focusVisibleRef, ref);

    const handleBlur: typeof onBlur = event => {
      handleBlurVisible(event);
      if (isFocusVisibleRef.current === false) {
        setFocusVisible(false);
      }

      onBlur?.(event);
    };

    const handleFocus: typeof onFocus = event => {
      handleFocusVisible(event);
      if (isFocusVisibleRef.current === true) {
        setFocusVisible(true);
      }

      onFocus?.(event);
    };

    return (
      <Text
        data-testid="link"
        css={[
          linkBaseStyles(theme),
          underlineStyles(underline),
          componentStyles(component),
        ]}
        classes={TextClasses}
        component={component}
        variant={variant}
        {...other}
        ref={handlerRef}
        className={clsx(
          linkClasses.root,
          linkClasses[
            camelCase(`underline_${underline}`) as keyof typeof linkClasses
          ],
          {
            [linkClasses.button]: component === 'button',
            [linkClasses.focusVisible]: focusVisible,
            [linkClasses.disabled]: disabled,
          },
          classes?.root,
          classes?.[
            camelCase(`underline_${underline}`) as keyof typeof classes
          ],
          {
            [classes?.button ?? '']: component === 'button',
            [classes?.focusVisible ?? '']: focusVisible,
          },
          other.className,
        )}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    );
  },
);
