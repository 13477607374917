import { BlockRendererProps } from '@allganize/draft-input';
import { forwardRef } from 'react';
import { DraftFileBase, DraftFileBaseProps } from './draft-file-base';

export interface DraftFileBlockProps
  extends Pick<DraftFileBaseProps, 'action' | 'classes'> {
  data?: {
    id?: string | number | null;
    metaInfo?: string | null;
    url?: string | null;
    createdAt?: string | null;
  };
  onDragStart?(ev: React.DragEvent<HTMLDivElement>, blockKey: string): void;
}

const getFilename = (blockProps?: DraftFileBlockProps) => {
  const metaInfo = blockProps?.data?.metaInfo;

  if (!metaInfo) {
    return null;
  }

  try {
    const parsed = JSON.parse(metaInfo);
    return parsed?.filename || parsed?.name;
  } catch {
    return null;
  }
};

export const DraftFile = forwardRef<
  HTMLDivElement,
  BlockRendererProps<DraftFileBlockProps>
>((props, ref) => {
  const { block, blockProps } = props;
  const { action = 'download', classes, data, onDragStart } = blockProps || {};
  const filename = getFilename(blockProps) || data?.url || undefined;
  const blockKey = block?.getKey();

  const handleDragStart = (ev: React.DragEvent<HTMLDivElement>) => {
    if (!blockKey) {
      return;
    }

    onDragStart?.(ev, blockKey);
  };

  return (
    <DraftFileBase
      data-testid="draft-file"
      action={action}
      classes={classes}
      href={data?.url ?? undefined}
      onDragStart={handleDragStart}
      draggable={!!(onDragStart && blockKey)}
      ref={ref}
    >
      {filename}
    </DraftFileBase>
  );
});
