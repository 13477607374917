import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { useMeridiemMode } from '../internals/hooks/date-helpers-hooks';
import { useLocaleText, useUtils } from '../internals/hooks/use-utils';
import { formatMeridiem } from '../internals/utils/date-utils';
import { arrayIncludes } from '../internals/utils/utils';
import { PickersToolbar } from '../pickers-toolbar';
import { PickersToolbarButton } from '../pickers-toolbar-button';
import { PickersToolbarText } from '../pickers-toolbar-text';
import { timePickerToolbarClasses } from './time-picker-toolbar-classes';
import { TimePickerToolbarProps } from './time-picker-toolbar-type-map';

export const TimePickerToolbar: FunctionComponent<
  TimePickerToolbarProps
> = props => {
  const {
    ampm,
    ampmInClock,
    classes,
    value,
    isLandscape,
    onChange,
    view,
    onViewChange,
    views,
    disabled,
    readOnly,
    ...other
  } = props;
  const utils = useUtils<Date>();
  const localeText = useLocaleText<Date>();

  const theme = useTheme();
  const showAmPmControl = Boolean(
    ampm && !ampmInClock && views.includes('hours'),
  );
  const { meridiemMode, handleMeridiemChange } = useMeridiemMode(
    value,
    ampm,
    onChange,
  );

  const formatHours = (time: Date) =>
    ampm ? utils.format(time, 'hours12h') : utils.format(time, 'hours24h');

  const separator = (
    <PickersToolbarText
      css={css`
        outline: 0;
        margin: 0 4px 0 2px;
        cursor: default;
      `}
      tabIndex={-1}
      value=":"
      variant="title16"
      selected={false}
      className={clsx(timePickerToolbarClasses.separator, classes?.separator)}
    />
  );

  return (
    <PickersToolbar
      data-testid="time-picker-toolbar"
      landscapeDirection="row"
      toolbarTitle={localeText.timePickerToolbarTitle}
      isLandscape={isLandscape}
      {...other}
      className={clsx(
        timePickerToolbarClasses.root,
        classes?.root,
        other.className,
      )}
    >
      <div
        css={[
          css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
          `,
          isLandscape &&
            css`
              margin-top: auto;
            `,
          theme.direction === 'rtl' &&
            css`
              flex-direction: row-reverse;
            `,
        ]}
        className={clsx(
          timePickerToolbarClasses.hourMinuteLabel,
          {
            [timePickerToolbarClasses.hourMinuteLabelLandscape]: isLandscape,
            [timePickerToolbarClasses.hourMinuteLabelReverse]:
              theme.direction === 'rtl',
          },
          classes?.hourMinuteLabel,
          {
            [classes?.hourMinuteLabelLandscape ?? '']: isLandscape,
            [classes?.hourMinuteLabelReverse ?? '']: theme.direction === 'rtl',
          },
        )}
      >
        {arrayIncludes(views, 'hours') && (
          <PickersToolbarButton
            tabIndex={-1}
            variant="title16"
            onClick={() => onViewChange('hours')}
            selected={view === 'hours'}
            value={value ? formatHours(value) : '--'}
          />
        )}

        {arrayIncludes(views, ['hours', 'minutes']) && separator}
        {arrayIncludes(views, 'minutes') && (
          <PickersToolbarButton
            tabIndex={-1}
            variant="title16"
            onClick={() => onViewChange('minutes')}
            selected={view === 'minutes'}
            value={value ? utils.format(value, 'minutes') : '--'}
          />
        )}

        {arrayIncludes(views, ['minutes', 'seconds']) && separator}
        {arrayIncludes(views, 'seconds') && (
          <PickersToolbarButton
            variant="title16"
            onClick={() => onViewChange('seconds')}
            selected={view === 'seconds'}
            value={value ? utils.format(value, 'seconds') : '--'}
          />
        )}
      </div>

      {showAmPmControl && (
        <div
          css={[
            css`
              display: flex;
              flex-direction: column;
              margin-right: auto;
              margin-left: 12px;

              .${timePickerToolbarClasses.ampmLabel} {
                font-size: 17px;
              }
            `,
            isLandscape &&
              css`
                margin: 4px 0 auto;
                flex-direction: row;
                justify-content: space-around;
                flex-basis: 100%;
              `,
          ]}
          className={clsx(
            timePickerToolbarClasses.ampmSelection,
            { [timePickerToolbarClasses.ampmLandscape]: isLandscape },
            classes?.ampmSelection,
            { [classes?.ampmLandscape ?? '']: isLandscape },
          )}
        >
          <PickersToolbarButton
            variant="body12"
            selected={meridiemMode === 'am'}
            textClassName={clsx(
              timePickerToolbarClasses.ampmLabel,
              classes?.ampmLabel,
            )}
            value={formatMeridiem(utils, 'am')}
            onClick={readOnly ? undefined : () => handleMeridiemChange('am')}
            disabled={disabled}
          />

          <PickersToolbarButton
            variant="body12"
            selected={meridiemMode === 'pm'}
            textClassName={clsx(
              timePickerToolbarClasses.ampmLabel,
              classes?.ampmLabel,
            )}
            value={formatMeridiem(utils, 'pm')}
            onClick={readOnly ? undefined : () => handleMeridiemChange('pm')}
            disabled={disabled}
          />
        </div>
      )}
    </PickersToolbar>
  );
};
