import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { PickersLayout } from '../../../pickers-layout';
import { DIALOG_WIDTH } from '../../constants';

// @ts-expect-error overridable component
export const PickerStaticLayout: typeof PickersLayout = forwardRef(
  (props, ref) => {
    const theme = useTheme();

    return (
      <PickersLayout
        css={css`
          overflow: hidden;
          min-width: ${DIALOG_WIDTH}px;
          background-color: ${theme.palette.background.paper};
        `}
        {...props}
        ref={ref}
      />
    );
  },
);
