import { createSvgIcon } from '@allganize/ui-icons';

export const IcFilePPTX = createSvgIcon(
  <>
    <path
      d="M6 22.5469H20.3077C21.1361 22.5469 21.8077 21.8753 21.8077 21.0469V7.69265C21.8077 7.29885 21.6528 6.92085 21.3765 6.64024L16.7099 1.90072C16.428 1.61437 16.0429 1.45312 15.6411 1.45312H6C5.17157 1.45312 4.5 2.1247 4.5 2.95312V21.0469C4.5 21.8753 5.17157 22.5469 6 22.5469Z"
      fill="white"
    />
    <path
      d="M5.0625 2.95312C5.0625 2.43536 5.48223 2.01562 6 2.01562H15.6411C15.6631 2.01562 15.685 2.0164 15.7068 2.01793V5.57812C15.7068 6.71721 16.6302 7.64062 17.7693 7.64062H21.2437C21.2447 7.6579 21.2452 7.67525 21.2452 7.69265V21.0469C21.2452 21.5646 20.8255 21.9844 20.3077 21.9844H6C5.48223 21.9844 5.0625 21.5646 5.0625 21.0469V2.95312ZM20.4644 6.51562L16.8318 2.82621V5.57812C16.8318 6.09589 17.2515 6.51562 17.7693 6.51562H20.4644ZM6 0.890625C4.86091 0.890625 3.9375 1.81404 3.9375 2.95312V21.0469C3.9375 22.186 4.86092 23.1094 6 23.1094H20.3077C21.4468 23.1094 22.3702 22.186 22.3702 21.0469V7.69265C22.3702 7.15117 22.1573 6.63142 21.7774 6.24559L17.1108 1.50607C16.7231 1.11234 16.1936 0.890625 15.6411 0.890625H6Z"
      fill="black"
      fillOpacity="0.2"
      fillRule="evenodd"
    />
    <path
      d="M0.75 12.75C0.75 11.0931 2.09315 9.75 3.75 9.75H16.5C18.1569 9.75 19.5 11.0931 19.5 12.75V17.25C19.5 18.9069 18.1569 20.25 16.5 20.25H3.75C2.09315 20.25 0.75 18.9069 0.75 17.25V12.75Z"
      fill="#FFAD25"
    />
    <path
      d="M2.57666 12.8184V17.182H3.63135V15.8269H4.34726C4.68106 15.8269 4.96942 15.7651 5.21231 15.6415C5.45521 15.5179 5.642 15.3439 5.77268 15.1195C5.90479 14.8936 5.97084 14.6301 5.97084 14.329C5.97084 14.0293 5.90621 13.7665 5.77694 13.5407C5.64768 13.3134 5.46373 13.1365 5.2251 13.0101C4.98788 12.8823 4.70592 12.8184 4.37922 12.8184H2.57666ZM3.63135 14.9959H4.15123C4.31032 14.9959 4.44314 14.9682 4.54967 14.9128C4.65763 14.8574 4.7393 14.78 4.7947 14.6806C4.8501 14.5797 4.8778 14.4625 4.8778 14.329C4.8778 14.1941 4.8501 14.0776 4.7947 13.9796C4.7393 13.8801 4.65763 13.8034 4.54967 13.7495C4.44172 13.6955 4.3089 13.6685 4.15123 13.6685H3.63135V14.9959ZM6.25195 17.182V12.8184H8.05451C8.38121 12.8184 8.66317 12.8823 8.90039 13.0101C9.13903 13.1365 9.32298 13.3134 9.45224 13.5407C9.5815 13.7665 9.64613 14.0293 9.64613 14.329C9.64613 14.6301 9.58008 14.8936 9.44798 15.1195C9.31729 15.3439 9.1305 15.5179 8.88761 15.6415C8.64471 15.7651 8.35636 15.8269 8.02255 15.8269H7.30664V17.182H6.25195ZM7.82653 14.9959H7.30664V13.6685H7.82653C7.9842 13.6685 8.11701 13.6955 8.22496 13.7495C8.33292 13.8034 8.4146 13.8801 8.46999 13.9796C8.52539 14.0776 8.55309 14.1941 8.55309 14.329C8.55309 14.4625 8.52539 14.5797 8.46999 14.6806C8.4146 14.78 8.33292 14.8574 8.22496 14.9128C8.11843 14.9682 7.98562 14.9959 7.82653 14.9959ZM9.78223 12.8184V13.6749H11.1075V17.182H12.1473V13.6749H13.4704V12.8184H9.78223ZM15.6107 14.1969L14.8138 12.8184H13.6249L14.9438 15.0002L13.5908 17.182H14.7925L15.6107 15.7885H15.6448L16.463 17.182H17.6732L16.3138 15.0002L17.6306 12.8184H16.4502L15.6448 14.1969H15.6107Z"
      fill="white"
      fillRule="evenodd"
    />
  </>,
  'ic-file-pptx',
);
