import {
  dividerClasses as muiDividerClasses,
  DividerClassKey as MuiDividerClassKey,
} from '@mui/material/Divider';
import { omit } from 'lodash-es';

export type DividerClassKey = Exclude<MuiDividerClassKey, 'light'>;
export type DividerClasses = Record<DividerClassKey, string>;

export const dividerClasses: DividerClasses = omit(muiDividerClasses, [
  'light',
]);
