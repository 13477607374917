import { ListItemSecondaryActionClassKey } from '@allganize/ui-list';
import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type FileListItemSecondaryActionClassKey =
  ListItemSecondaryActionClassKey;
export type FileListItemSecondaryActionClasses = Record<
  FileListItemSecondaryActionClassKey,
  string
>;

export const fileListItemSecondaryActionClasses: FileListItemSecondaryActionClasses =
  generateUtilityClasses('FileListItemSecondaryAction', ['root']);
