import { useQuery } from '@apollo/client/react';
import { informationIconMap } from '@allganize/alli-app-market-icons';
import { AutocompleteOption } from '@allganize/alli-sdk-ui';
import { UserQueryTypes } from '@allganize/alli-interfaces';
import { useDebounce, useEventCallback } from '@allganize/hooks';
import { IcAppMarket, IcSearch } from '@allganize/ui-icons';
import useAutocomplete from '@mui/material/useAutocomplete';
import { compact, uniq } from 'lodash-es';
import { useContext, useEffect, useMemo } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { createFilter } from 'react-select';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { analytics } from '../analytics';
import { ChatFormValues } from './use-chat-form';
import { ChatListContext } from '../chat-list/chat-list-context';
import { ConversationContext } from '../conversation-detail/conversation-context';
import { AutoCompletionQueryDocument } from '../graphql/queries/auto-completion-query';
import { LLMAppsByCategoryQueryDocument } from '../graphql/queries/llm-apps-by-category-query';
import { useProject } from '../project/use-project';
import { useAssistantCampaign } from '../project/use-assistant-campaign';
import { SingleActionPreviewContext } from '../single-action-preview/single-action-preview-context';

type AppDetail = { appId: string | number } | null;

const autocompleteFilter = createFilter<AutocompleteOption>({
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: 'any',
  stringify(option) {
    return [option.label, option.value].join(' ');
  },
});

interface UseChatFormAutocompleteOptions {
  message: string;
  messageFieldEnabled: boolean;
  formDisabled: boolean;
  setValue: UseFormSetValue<ChatFormValues>;
}

export const useChatFormAutocomplete = ({
  message,
  messageFieldEnabled,
  formDisabled,
  setValue,
}: UseChatFormAutocompleteOptions) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { project } = useProject();
  const { isAssistantCampaign } = useAssistantCampaign();
  const {
    data: { conversation },
  } = useContext(ConversationContext);
  const { data: chatListData } = useContext(ChatListContext);
  const { openSingleActionPreview } = useContext(SingleActionPreviewContext);

  const appId = conversation?.llmApp?.id;
  const conversationId = conversation?.id || '';
  const sdkSettings = project?.sdkSettings;
  const lastEdge = chatListData?.chats?.edges
    ? chatListData.chats.edges[chatListData.chats.edges.length - 1]
    : null;
  const lastChat = lastEdge?.node;

  const useMentionApp =
    isAssistantCampaign(conversation?.llmApp?.campaign?.id) &&
    !project?.useCustomAssistantEntryLlmApp;
  const useAutoComplete =
    !useMentionApp &&
    sdkSettings?.useAutoComplete &&
    lastChat &&
    lastChat.__typename !== 'EventChat'
      ? lastChat.useAutoComplete
      : null;
  const numAutoComplete = useMentionApp
    ? undefined // show all options
    : sdkSettings?.numAutoComplete ?? 3;
  const skipAutoCompletion =
    !conversation || !useAutoComplete || !messageFieldEnabled;

  const { data: apps } = useQuery(LLMAppsByCategoryQueryDocument, {
    skip: !useMentionApp,
    variables: {
      filter: {
        type: UserQueryTypes.LLMAppTypeFilter.SINGLE_ACTION,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: autoCompletion,
    fetchMore,
    variables,
  } = useQuery(AutoCompletionQueryDocument, {
    skip: skipAutoCompletion,
    variables: {
      partial: '',
      where: { id: conversationId },
    },
  });

  const defaultOption = useMemo(() => {
    if (useMentionApp) {
      return {
        icon: <IcAppMarket />,
        title: intl.formatMessage({
          id: 'chat-form.autocomplete.go-to-app-list',
          defaultMessage: 'Go to app list',
          description: 'go to app link text in chat-form mention',
        }),
        description: '',
        onSelect: () => {
          navigate('/apps');
        },
        detail: null,
      };
    }
    return null;
  }, [intl, navigate, useMentionApp]);

  const appList = compact(
    apps?.llmAppsByCategory?.llmAppsByCategory ?? [],
  ).reduce<AutocompleteOption<AppDetail>[]>((acc, { llmApps }) => {
    if (!llmApps || llmApps.length === 0) {
      return acc;
    }
    const optionList = llmApps.reduce<AutocompleteOption<AppDetail>[]>(
      (options, app) => {
        if (!app || !app.singleActionApp) {
          return options;
        }
        const Icon = app.icon && (informationIconMap as any)[app.icon];
        const option = {
          icon: Icon ? <Icon color="primary" /> : null,
          title: app.name,
          description: app.description || '',
          onSelect: () => {
            openSingleActionPreview(app.singleActionApp);
            setValue('message', '');
          },
          detail: { appId: app.singleActionApp.id },
        };
        return [...options, option];
      },
      [],
    );
    return [...acc, ...optionList];
  }, []);

  const questionList = compact(
    autoCompletion?.autoCompletion?.questions ?? [],
  ).map(q => ({
    title: q,
    icon: <IcSearch />,
  }));

  const autocompleteOptions = useMemo<AutocompleteOption<AppDetail>[]>(
    () => (useMentionApp ? appList : questionList),
    [useMentionApp, appList, questionList],
  );

  const autocomplete = useAutocomplete<
    AutocompleteOption<AppDetail>,
    false,
    true,
    true
  >({
    autoComplete: false,
    autoHighlight: false,
    autoSelect: false,
    blurOnSelect: false,
    clearOnBlur: false,
    clearOnEscape: false,
    disableClearable: true,
    disableCloseOnSelect: false,
    disabled: formDisabled,
    freeSolo: true,
    options: autocompleteOptions,
    inputValue: message,
    getOptionLabel(option) {
      return typeof option === 'string' ? option : option.title;
    },
    onInputChange(ev, value) {
      // prevent unnecessary setValue without event
      if (ev) {
        setValue('message', value, { shouldValidate: true, shouldTouch: true });
      }
    },
    onChange(ev, value, reason) {
      if (
        reason === 'selectOption' &&
        typeof value !== 'string' &&
        value.onSelect
      ) {
        value.onSelect();
        analytics.track(
          'conversation-detail::chat-input__app-mention-selector__app.click',
          {
            conversationId,
            appId,
            type: value.detail ? 'app' : 'app-list',
            clickedAppId: value.detail?.appId,
          },
        );
      }
    },
    filterOptions: (options, state) => {
      let inputValue = state.inputValue.trim();

      if (useMentionApp) {
        const isMentioning = state.inputValue.startsWith('@');
        if (!isMentioning) {
          return [];
        }

        inputValue = inputValue.substring(1);

        if (inputValue === '') {
          return defaultOption ? [defaultOption, ...options] : options;
        }
      }

      if (inputValue === '') {
        return [];
      }

      const filteredOptions = options
        .filter(option =>
          autocompleteFilter(
            {
              value: option.title,
              label: option.description || '',
              data: option,
            },
            inputValue,
          ),
        )
        .slice(0, numAutoComplete);

      return defaultOption
        ? [defaultOption, ...filteredOptions]
        : filteredOptions;
    },
  });

  const getAutocompleteOptions = useEventCallback((inputValue: string) => {
    if (skipAutoCompletion) {
      return;
    }
    return fetchMore({
      variables: {
        ...variables,
        partial: inputValue,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (
          !(
            fetchMoreResult.autoCompletion?.questions &&
            fetchMoreResult.autoCompletion.questions.length > 0
          )
        ) {
          return prev;
        }

        return {
          ...prev,
          ...fetchMoreResult,
          autoCompletion: {
            ...prev.autoCompletion,
            ...fetchMoreResult.autoCompletion,
            questions: uniq([
              ...(prev.autoCompletion?.questions ?? []),
              ...fetchMoreResult.autoCompletion.questions,
            ]),
          },
        };
      },
    });
  });

  useDebounce(
    () => {
      getAutocompleteOptions(message);
    },
    500,
    [skipAutoCompletion, getAutocompleteOptions, message],
  );

  useEffect(() => {
    if (useMentionApp && autocomplete.popupOpen) {
      analytics.track(
        'conversation-detail::chat-input__app-mention-selector.open',
        {
          conversationId,
          appId,
        },
      );
    }
  }, [useMentionApp, autocomplete.popupOpen, conversationId, appId]);

  return useMentionApp || useAutoComplete ? autocomplete : null;
};

export type UseChatFormAutocompleteReturn = ReturnType<
  typeof useChatFormAutocomplete
>;
