import { isDraftInputEmpty } from '@allganize/draft-input';
import { useCombinedRef, useWindow } from '@allganize/hooks';
import { Truncate } from '@allganize/truncate';
import { IconButton } from '@allganize/ui-button';
import {
  FileList,
  FileListItem,
  FileListItemIcon,
  FileListItemText,
} from '@allganize/ui-file-input';
import { FormControl, FormLabel } from '@allganize/ui-form';
import { IcOpenInNew } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { ClassNames, css } from '@emotion/react';
import { noop } from 'lodash-es';
import { forwardRef, useEffect, useMemo } from 'react';
import { PlainText } from '../plain-text/plain-text';
import { getFilename } from './file-form-value';
import { FormValue } from './use-form-value-form';

interface FormValueReadOnlyProps {
  formValue: FormValue;
  children?: React.ReactNode;
}

export const FormValueReadOnly = forwardRef<
  HTMLDivElement,
  FormValueReadOnlyProps
>(({ formValue }, ref) => {
  const { ref: rootRef, window: win } = useWindow();
  const combinedRef = useCombinedRef(rootRef, ref);
  const filename =
    getFilename(formValue.fileValue, win ?? undefined) ?? undefined;
  const { cleanup, url } = useMemo(() => {
    if (!formValue.fileValue) {
      return {
        cleanup: noop,
        url: null,
      };
    }

    if (
      (win && formValue.fileValue instanceof win.File) ||
      formValue.fileValue instanceof File
    ) {
      return {
        cleanup: URL.revokeObjectURL,
        url: URL.createObjectURL(formValue.fileValue),
      };
    }

    return {
      cleanup: noop,
      url: formValue.fileValue.url,
    };
  }, [formValue.fileValue, win]);
  const hasText = !isDraftInputEmpty(formValue.stringValue);
  const hasFile = !!formValue.fileValue;

  useEffect(() => {
    return () => {
      if (url) {
        cleanup(url);
      }
    };
  }, [cleanup, url]);

  return (
    <FormControl fullWidth required={formValue.mandatory} ref={combinedRef}>
      <FormLabel
        css={css`
          margin-bottom: 4px;
        `}
      >
        {formValue.display}
      </FormLabel>

      {hasText && (
        <PlainText
          value={formValue.stringValue.getCurrentContent().getPlainText('\n')}
        />
      )}

      {hasFile && (
        <ClassNames>
          {() => (
            <FileList disablePadding>
              <FileListItem
                secondaryAction={
                  <IconButton
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                    edge="end"
                    href={url ?? undefined}
                    download={filename}
                  >
                    <IcOpenInNew />
                  </IconButton>
                }
              >
                <FileListItemIcon />

                <FileListItemText
                  primary={<Truncate clamp={1}>{filename}</Truncate>}
                />
              </FileListItem>
            </FileList>
          )}
        </ClassNames>
      )}

      {!hasText && !hasFile && <Text variant="body14">-</Text>}
    </FormControl>
  );
});
