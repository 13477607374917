import { Chip, ChipProps, chipClasses } from '@allganize/ui-chip';
import { css } from '@emotion/react';
import { forwardRef, useContext } from 'react';
import { DraftDocumentContext } from './draft-document-context';
import { DraftDocumentEntity } from './draft-document-entity';

export interface DraftDocumentLinkBaseProps extends ChipProps {
  entity?: DraftDocumentEntity;
}

export const DraftDocumentLinkBase = forwardRef<
  HTMLDivElement,
  DraftDocumentLinkBaseProps
>((props, ref) => {
  const { entity = null, onClick: onClickProp, ...other } = props;
  const { isSelected, onClick } = useContext(DraftDocumentContext);
  const selected = entity ? isSelected({ entity }) : false;
  const clickable = onClickProp || (onClick && entity);

  const handleClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    onClickProp?.(ev);

    if (!entity) {
      return;
    }

    onClick?.({ entity });
  };

  return (
    <Chip
      data-testid="draft-document-link-base"
      css={css`
        height: auto;
        font: inherit;
        letter-spacing: inherit;
        vertical-align: inherit;
        font-size: 85.71%;
        line-height: ${16 / 12};
        padding: 0 2px;

        &.${chipClasses.colorGraySubtle} {
          color: inherit;
        }
      `}
      color={selected ? 'gray-dark' : 'gray-subtle'}
      {...other}
      ref={ref}
      onClick={clickable ? handleClick : undefined}
    />
  );
});
