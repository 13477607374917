import { useMediaQuery } from '@allganize/hooks';
import { forwardRef } from 'react';
import { DesktopTimePicker } from '../desktop-time-picker';
import { DEFAULT_DESKTOP_MODE_MEDIA_QUERY } from '../internals/utils/utils';
import { MobileTimePicker, MobileTimePickerProps } from '../mobile-time-picker';
import { TimePickerProps } from './time-picker-type-map';

export const TimePicker = forwardRef<HTMLDivElement, TimePickerProps>(
  (props, ref) => {
    const {
      desktopModeMediaQuery = DEFAULT_DESKTOP_MODE_MEDIA_QUERY,
      ...other
    } = props;

    // defaults to `true` in environments where `window.matchMedia` would not be available (i.e. test/jsdom)
    const isDesktop = useMediaQuery(desktopModeMediaQuery, {
      defaultMatches: true,
    });

    if (isDesktop) {
      return <DesktopTimePicker ref={ref} {...other} />;
    }

    return <MobileTimePicker ref={ref} {...(other as MobileTimePickerProps)} />;
  },
);
