import { Text } from '@allganize/ui-text';
import { css, useTheme } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';

import error from '../../assets/error.png';
import error2x from '../../assets/error@2x.png';
import error3x from '../../assets/error@3x.png';
import linkExpired from '../../assets/link-expired.png';

interface ErrorScreenProps {
  title?: ReactNode;
  description?: ReactNode;
  src?: string;
  srcSet?: string[];
  action?: ReactNode;
}

export const ErrorScreen = (props: ErrorScreenProps) => {
  const theme = useTheme();

  const title = props.title ?? (
    <FormattedMessage
      id="something-went-wrong"
      defaultMessage="Oops! Something went wrong."
      description="something went wrong error message"
    />
  );
  const description = props.description ?? (
    <FormattedMessage
      id="try-again-later"
      defaultMessage="Something went wrong. Please try refreshing the page or come back later."
      description="try again later error message"
    />
  );
  const src = props.src ?? error;
  const srcSet = props.srcSet ?? [error, error2x, error3x];

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        background-color: ${theme.palette.unstable_background.default};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          color: ${theme.palette.foreground.secondary};
        `}
      >
        <img
          width={150}
          height={150}
          src={src}
          srcSet={srcSet.map((src, i) => `${src} ${i + 1}x`).join(', ')}
          alt="error page"
        />

        <Text variant="title20" align="center">
          {title}
        </Text>

        <Text variant="body16" align="center">
          {description}
        </Text>

        {props.action}
      </div>
    </div>
  );
};

export const LinkExpiredErrorScreen = () => {
  return (
    <ErrorScreen
      src={linkExpired}
      srcSet={[]}
      title={
        <FormattedMessage
          id="error-page.title"
          defaultMessage="Oops! It looks like this link has expired"
          description="Error page title text"
        />
      }
      description={
        <FormattedMessage
          id="error-page.description"
          defaultMessage="Please contact the administrator for assistance or request a new link."
          description="Error page description text"
        />
      }
    />
  );
};
