import { SingleAction } from '@allganize/single-action-app';
import { css, useTheme } from '@emotion/react';
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAlliClient } from '../alli-client/use-alli-client';
import { analytics } from '../analytics';
import { config } from '../config';
import { PreviewCloseButton } from '../preview/preview-close-button';
import { SingleActionPreviewContext } from './single-action-preview-context';

export const SingleActionPreview: FunctionComponent = () => {
  const theme = useTheme();
  const {
    singleActionPreviewState,
    conversationIdToSendResult,
    closeSingleActionPreview,
  } = useContext(SingleActionPreviewContext);
  const appId = singleActionPreviewState?.id || '';
  const publicToken = singleActionPreviewState?.publicToken || '';

  const client = useAlliClient();
  const region = client.authClient.getRegionFromEndpoint();
  const singleActionResultDownloadUrl =
    config.singleActionResultDownloadUrl[region];

  const [clientHashId, setClientHashId] = useState('');

  const getClientHashId = useCallback(async () => {
    const id = (await client.authClient.deviceIdManager.getDeviceId()) || '';
    setClientHashId(id);
  }, [client.authClient.deviceIdManager]);

  useEffect(() => {
    getClientHashId();
  }, [getClientHashId]);

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
      `}
    >
      <PreviewCloseButton
        onClick={() => closeSingleActionPreview('closeButtonClick')}
        css={css`
          position: absolute;
          top: 12px;
          right: 12px;
          color: ${theme.palette.foregroundInteractive.gray.default};
        `}
      />
      <SingleAction
        key={singleActionPreviewState?.key}
        publicToken={publicToken}
        clientHashId={clientHashId}
        singleActionResultDownloadUrl={singleActionResultDownloadUrl}
        modalContainerId={theme.modalContainer.id}
        conversationIdToSendResult={conversationIdToSendResult}
        onGenerate={() => {
          analytics.track('single-action::generate-button.click', {
            publicToken,
            appId,
          });
        }}
      />
    </div>
  );
};
