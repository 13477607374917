import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type AsyncCreatableFieldClassKey = 'root';
export type AsyncCreatableFieldClasses = Record<
  AsyncCreatableFieldClassKey,
  string
>;

export const asyncCreatableFieldClasses: AsyncCreatableFieldClasses =
  generateUtilityClasses('AsyncCreatableField', ['root']);
