import MuiButtonBase from '@mui/material/ButtonBase';
import { forwardRef } from 'react';
import { ButtonBaseTypeMap, ExtendButtonBase } from './button-base-type-map';

// @ts-expect-error overridable component
export const ButtonBase: ExtendButtonBase<ButtonBaseTypeMap> = forwardRef(
  (props, ref) => (
    <MuiButtonBase
      data-testid="button-base"
      {...props}
      ref={ref}
      centerRipple={false}
      disableRipple
      disableTouchRipple
      focusRipple={false}
    />
  ),
);
