import { Paper } from '@allganize/ui-paper';
import styled from '@emotion/styled';
import { Modal } from '../modal';
import { PopoverOwnerState } from './popover-type-map';

export const PopoverRoot = styled(Modal, {
  shouldForwardProp: prop => prop !== 'ownerState',
})<{ ownerState: PopoverOwnerState }>``;

export const PopoverPaper = styled(Paper, {
  shouldForwardProp: prop => prop !== 'ownerState',
})<{ ownerState: PopoverOwnerState }>`
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
  outline: 0;
`;
