import { useEffect, useState } from 'react';
import { AlliLauncherManagerMessagePreviewState } from '../core/alli-launcher-manager/alli-launcher-manager-options';
import { useAlliClient } from '../alli-client/use-alli-client';

export const useLauncher = () => {
  const client = useAlliClient();
  const [open, _setOpen] = useState(client.launcherManager.open);
  const [messagePreview, setMessagePreview] = useState(
    client.launcherManager.messagePreview,
  );

  useEffect(() => {
    const handleShow = () => {
      _setOpen(true);
    };

    const handleHide = () => {
      _setOpen(false);
    };

    const handleMessagePreviewChange = ({
      current,
    }: {
      current: AlliLauncherManagerMessagePreviewState;
    }) => {
      setMessagePreview(current);
    };

    client.launcherManager.on('show', handleShow);
    client.launcherManager.on('hide', handleHide);
    client.launcherManager.on('messagePreview', handleMessagePreviewChange);

    return () => {
      client.launcherManager.off('show', handleShow);
      client.launcherManager.off('hide', handleHide);
      client.launcherManager.off('messagePreview', handleMessagePreviewChange);
    };
  }, [client.launcherManager]);

  return {
    open,
    show: client.launcherManager.show,
    hide: client.launcherManager.hide,
    toggle: client.launcherManager.toggle,
    messagePreview,
    setMessagePreview: client.launcherManager.setMessagePreview,
  };
};
