import { FunctionComponent } from 'react';
import { Mode } from '../contexts/app-context';
import { AppProvider } from '../contexts/app-provider';
import { DownloadFileProvider } from '../contexts/download-file-provider';
import { OutputProvider } from '../contexts/output-provider';
import { UIProvider } from '../contexts/ui-provider';
import { SingleActionApp, SingleActionAppProps } from './single-action-app';
import { UploadFileProvider } from '../contexts/upload-file-provider';

export interface SingleActionProps extends SingleActionAppProps {
  mode?: Mode;
  publicToken: string;
  clientHashId: string;
  conversationIdToSendResult?: string;
  singleActionResultDownloadUrl: string;
  modalContainerId?: string;
  onGenerate?: () => void;
  onLoadApp?: (appId: string | number) => void;
}

export const SingleAction: FunctionComponent<SingleActionProps> = ({
  mode = 'user',
  publicToken,
  clientHashId,
  conversationIdToSendResult,
  singleActionResultDownloadUrl,
  modalContainerId,
  onGenerate,
  onLoadApp,
  ...others
}) => {
  return (
    <UIProvider modalContainerId={modalContainerId}>
      <AppProvider
        mode={mode}
        publicToken={publicToken || ''}
        onLoadApp={onLoadApp}
      >
        <OutputProvider
          conversationIdToSendResult={conversationIdToSendResult || ''}
          onGenerate={onGenerate}
        >
          <DownloadFileProvider
            url={singleActionResultDownloadUrl}
            clientHashId={clientHashId}
          >
            <UploadFileProvider>
              <SingleActionApp {...others} />
            </UploadFileProvider>
          </DownloadFileProvider>
        </OutputProvider>
      </AppProvider>
    </UIProvider>
  );
};
