import { ContentState, EditorState, RawDraftContentState } from 'draft-js';
import { convertFromRaw } from './convert-from-raw';

export const isDraftInputEmpty = (
  value: string | ContentState | RawDraftContentState | EditorState | null,
): boolean => {
  if (!value) {
    return true;
  }

  if (value instanceof EditorState) {
    return !value.getCurrentContent().hasText();
  }

  const contentState =
    value instanceof ContentState ? value : convertFromRaw(value);

  if (contentState.getBlockMap().count() === 0) {
    return true;
  }

  return !contentState.hasText();
};
