import { useEffect, useState } from 'react';

export interface UseImageLoadedOptions
  extends Partial<Pick<HTMLImageElement, 'crossOrigin' | 'referrerPolicy'>> {
  src?: string | null;
  srcSet?: string;
  error?: boolean;
}

export const useImageLoaded = ({
  crossOrigin,
  referrerPolicy,
  src,
  srcSet,
}: UseImageLoadedOptions) => {
  const [loaded, setLoaded] = useState<'loaded' | 'error' | false>(false);

  useEffect(() => {
    if (src === undefined && srcSet === undefined) {
      return;
    }

    setLoaded(false);
    let active = true;
    const image = new Image();

    const handleLoad = () => {
      if (!active) {
        return;
      }

      setLoaded('loaded');
    };

    const handleError = () => {
      if (!active) {
        return;
      }

      setLoaded('error');
    };

    image.addEventListener('load', handleLoad);
    image.addEventListener('error', handleError);

    if (crossOrigin) {
      image.crossOrigin = crossOrigin;
    }

    if (referrerPolicy) {
      image.referrerPolicy = referrerPolicy;
    }

    image.src = src || '';

    if (srcSet) {
      image.srcset = srcSet;
    }

    return () => {
      active = false;
    };
  }, [crossOrigin, referrerPolicy, src, srcSet]);

  return loaded;
};
