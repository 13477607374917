import { AgentQueryTypes } from '@allganize/alli-interfaces';
import { format } from 'date-fns';
import { camelCase } from 'lodash-es';

const FILE_TYPE_OPTIONS = [
  AgentQueryTypes.FileTypeEnum.TXT,
  AgentQueryTypes.FileTypeEnum.PDF,
  AgentQueryTypes.FileTypeEnum.DOCX,
] as const;

type FileType = (typeof FILE_TYPE_OPTIONS)[number];

interface DownloadFileOption {
  label: string;
  value: FileType;
}

export const fileTypeOptions = FILE_TYPE_OPTIONS.map(option => ({
  label: option,
  value: option,
}));

export interface DownloadFileFormValues {
  fileName: string;
  fileType: DownloadFileOption;
}

export const getInitialFileName = (name: string) => {
  return `${camelCase(name)}${format(new Date(), 'yyyyMMddHHmmssSSS')}`;
};
