import { To, createBrowserRouter } from 'react-router-dom';

const toStringify = (to: To): string => {
  if (typeof to === 'string') return to;
  if (to.search && !to.search.startsWith('?')) to.search = `?${to.search}`;
  return `${to.pathname ?? ''}${to.search ?? ''}${to.hash ?? ''}`;
};

const setParameter = (to: To): To => {
  const urlStr = toStringify(to);
  if (urlStr.startsWith('http')) return to;

  const currentSearch = new URLSearchParams(window.location.search);
  const url = new URL(urlStr, window.location.href);

  const projectToken = currentSearch.get('project_token') ?? '';
  const userId = currentSearch.get('user_id');
  const region = currentSearch.get('region');
  const pwa = currentSearch.get('pwa');

  if (projectToken) {
    url.searchParams.set('project_token', projectToken);
  }

  if (userId) {
    url.searchParams.set('user_id', userId);
  }

  if (region) {
    url.searchParams.set('region', region);
  }

  if (pwa) {
    url.searchParams.set('pwa', pwa);
  }

  return {
    pathname: url.pathname,
    search: url.search,
    hash: url.hash,
  };
};

type Router = ReturnType<typeof createBrowserRouter>;

export const convertRouter = (router: Router): Router => {
  const { createHref, encodeLocation, navigate } = router;

  // NOTE: Don't use spread operator here `...router`, it will cause an error in Safari. (related with https://github.com/allganize/mally-fe/pull/2367)
  return Object.assign(router, {
    // @ts-expect-error override createHref (mis-typed in library)
    createHref: to => createHref(setParameter(to)),
    encodeLocation: to => encodeLocation(setParameter(to)),
    navigate: (to, options) => {
      if (typeof to === 'number' || !to) return navigate(to, options);
      return navigate(setParameter(to), options);
    },
  } as Partial<Router>);
};
