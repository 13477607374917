import { forwardRef, memo } from 'react';
import { SvgIcon } from './svg-icon';

export const createSvgIcon = (
  path: React.ReactNode,
  displayName: string,
): typeof SvgIcon => {
  // @ts-expect-error overridable component
  return memo(
    forwardRef((props, ref) => {
      return (
        <SvgIcon data-testid={displayName} {...props} ref={ref}>
          {path}
        </SvgIcon>
      );
    }),
  );
};
