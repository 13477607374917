import { useEventCallback } from '@allganize/hooks';
import { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import {
  DocumentViewerPageInfo,
  DocumentViewerProps,
} from '../document-viewer/document-viewer-type-map';
import { ImagePageInfo } from '../image-page';

interface UsePagesOptions
  extends Pick<DocumentViewerProps, 'initialPage' | 'pages'> {}

const loadPage = async (
  page: DocumentViewerPageInfo | (() => Promise<DocumentViewerPageInfo>),
): Promise<DocumentViewerPageInfo> => {
  const loadedPage = typeof page === 'function' ? await page() : page;

  if (loadedPage.type !== 'image') {
    return loadedPage;
  }

  const image = new Image();

  return new Promise<ImagePageInfo>(resolve => {
    const handleLoad = () => {
      resolve(loadedPage);
    };

    const handleError = () => {
      resolve(loadedPage);
    };

    image.addEventListener('load', handleLoad);
    image.addEventListener('error', handleError);
    image.src = loadedPage.url;
  });
};

export const usePages = ({
  initialPage = 0,
  pages: pagesOption,
}: UsePagesOptions) => {
  const [pages, setPages] = useState(pagesOption);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [loading, setLoading] = useState(false);
  const clampedCurrentPage = Math.min(
    pages.length - 1,
    Math.max(0, currentPage),
  );
  const hasPreviousPage = clampedCurrentPage !== 0;
  const hasNextPage = clampedCurrentPage < pages.length - 1;

  const setCurrentPageWithCallback = useEventCallback(
    (newState: React.SetStateAction<number>, callback?: () => void) => {
      if (typeof callback === 'function') {
        flushSync(() => {
          setCurrentPage(newState);
        });

        callback();
      } else {
        setCurrentPage(newState);
      }
    },
  );

  const loadCurrentPageInfo = useEventCallback(async () => {
    const pageInfo = pages[clampedCurrentPage];

    if (!pageInfo) {
      setLoading(false);
      return;
    }

    if (typeof pageInfo !== 'function') {
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const loadedPage = await loadPage(pageInfo);

      setPages(prev => [
        ...prev.slice(0, clampedCurrentPage),
        loadedPage,
        ...prev.slice(clampedCurrentPage + 1),
      ]);
    } catch {
      // noop
    }

    setLoading(false);
  });

  useEffect(() => {
    setPages(pagesOption);
    setCurrentPage(prev => Math.min(pagesOption.length - 1, prev));
  }, [pagesOption]);

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  useEffect(() => {
    loadCurrentPageInfo();
  }, [clampedCurrentPage, loadCurrentPageInfo, pages]);

  const goToPreviousPage = (callback?: () => void) =>
    setCurrentPageWithCallback(p => Math.max(0, p - 1), callback);

  const goToNextPage = (callback?: () => void) =>
    setCurrentPageWithCallback(
      p => Math.min(pages.length + 1, p + 1),
      callback,
    );

  return {
    currentPage: pages[clampedCurrentPage],
    currentPageIndex: clampedCurrentPage,
    goToNextPage,
    goToPreviousPage,
    hasNextPage,
    hasPreviousPage,
    loading,
    pages,
    setCurrentPage,
    setCurrentPageWithCallback,
    totalPages: pages.length,
  };
};
