import { DraftField, draftInputInternalClasses } from '@allganize/draft-input';
import { Button, LoadingButton } from '@allganize/ui-button';
import { Checkbox } from '@allganize/ui-checkbox';
import {
  DialogActions,
  DialogCloseButton,
  DialogContent,
  DialogTitle,
  dialogClasses,
} from '@allganize/ui-dialog';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@allganize/ui-form';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Dialog } from '../modal/dialog';
import {
  UseUserFeedbackImproverFormOptions,
  useUserFeedbackImproverForm,
} from './use-user-feedback-improver-form';

const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();

interface UserFeedbackImproverFormProps
  extends Pick<UseUserFeedbackImproverFormOptions, 'onSubmit'> {
  open?: boolean;
  onClose?(): void;
}

export const UserFeedbackImproverForm: FunctionComponent<
  UserFeedbackImproverFormProps
> = ({ open = false, onClose, onSubmit }) => {
  const theme = useTheme();

  const { feedbackSelections, form, submit } = useUserFeedbackImproverForm({
    onSubmit,
  });
  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    reset,
    trigger,
  } = form;

  const handleClose = () => {
    onClose?.();
    reset();
  };

  return (
    <Dialog
      css={css`
        .${dialogClasses.paperWidthSm} {
          width: 600px;
          display: flex;
          flex-direction: column;
        }
      `}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(async values => {
          await submit(values);
          onClose?.();
        }),
        css: css`
          width: 600px;
        `,
      }}
      fullWidth={false}
      maxWidth="sm"
      fullScreen={false}
      open={open}
      onClose={handleClose}
      onClick={stopPropagation}
      onMouseDown={stopPropagation}
      onMouseMove={stopPropagation}
      onMouseUp={stopPropagation}
      onMouseEnter={stopPropagation}
      onMouseLeave={stopPropagation}
      onMouseOut={stopPropagation}
      onMouseOver={stopPropagation}
      onTouchCancel={stopPropagation}
      onTouchEnd={stopPropagation}
      onTouchMove={stopPropagation}
      onTouchStart={stopPropagation}
      onFocus={stopPropagation}
    >
      <DialogTitle padding="close-button">
        <FormattedMessage
          id="user-feedback-improver-form.header"
          defaultMessage="Your feedback directly enhances quality"
          description="User Negative Feedback header title text"
        />

        <DialogCloseButton />
      </DialogTitle>

      <DialogContent>
        <Controller
          name="suggestedAnswer"
          control={control}
          render={({ field: { ref, ...field }, fieldState }) => {
            return (
              <DraftField
                css={css`
                  .${draftInputInternalClasses.content} {
                    min-height: 96px;
                  }
                `}
                {...field}
                onBlur={() => {
                  field.onBlur();

                  if (fieldState.isTouched || fieldState.isDirty) {
                    trigger('feedbackSelection');
                  }
                }}
                onChange={value => {
                  field.onChange(value);

                  if (fieldState.isTouched) {
                    trigger('feedbackSelection');
                  }
                }}
                label={
                  <FormattedMessage
                    id="user-feedback-improver-form.suggested-answer.placeholder"
                    defaultMessage="If you have a better answer, please leave a suggestion."
                    description="User Negative Feedback suggestedAns label"
                  />
                }
                autoFocus={open}
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            );
          }}
        />

        <Controller
          name="feedbackSelection"
          control={control}
          render={({ field: { ref, ...field }, fieldState }) => {
            return (
              <FormControl
                component="fieldset"
                fullWidth
                error={fieldState.invalid}
                css={css`
                  margin-top: ${theme.spacing(2.5)};
                `}
              >
                <FormLabel component="legend">
                  <FormattedMessage
                    id="user-feedback-improver-form.feedback-selection"
                    defaultMessage="What was the issue with the response?"
                    description="User Negative Feedback Suggestions title text"
                  />
                </FormLabel>

                <FormGroup>
                  {feedbackSelections.map(option => {
                    const checked = field.value.includes(option);

                    return (
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={(e, checked) => {
                              field.onChange(
                                checked
                                  ? [...field.value, option]
                                  : field.value.filter(v => v !== option),
                              );
                            }}
                          />
                        }
                        value={option}
                        label={
                          <FormattedMessage
                            id={`UserAnswerFeedbackSelection.${option}`}
                            defaultMessage={option}
                            description={`UserAnswerFeedbackSelection.${option}`}
                          />
                        }
                      />
                    );
                  })}
                </FormGroup>

                {fieldState.error?.message && (
                  <FormHelperText>{fieldState.error.message}</FormHelperText>
                )}
              </FormControl>
            );
          }}
        />

        {errors.root?.message && (
          <FormHelperText error>{errors.root?.message}</FormHelperText>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="ghost" size="large" onClick={handleClose}>
          <FormattedMessage
            id="actions.cancel"
            defaultMessage="Cancel"
            description="Cancel button text"
          />
        </Button>

        <LoadingButton
          type="submit"
          variant="filled"
          size="large"
          color="primary"
          disabled={!isValid}
          loading={isSubmitting}
        >
          <FormattedMessage
            id="actions.submit"
            defaultMessage="Submit"
            description="Submit button text"
          />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
