export const red = {
  50: 'hsla(0, 100%, 94%, 1)',
  100: 'hsla(0, 100%, 84%, 1)',
  200: 'hsla(0, 100%, 74%, 1)',
  300: 'hsla(0, 100%, 64%, 1)',
  400: 'hsla(0, 100%, 54%, 1)',
  500: 'hsla(0, 100%, 44%, 1)',
  600: 'hsla(0, 100%, 34%, 1)',
  700: 'hsla(0, 100%, 24%, 1)',
  800: 'hsla(0, 100%, 14%, 1)',
  900: 'hsla(0, 100%, 4%, 1)',
};
