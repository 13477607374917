import { Types } from '@allganize/alli-sdk-interfaces';
import { createContext } from 'react';
import { ChatAgentFragment } from '../graphql/fragments/chat-agent-fragment';

export interface AgentSelectContextValue {
  loading: boolean;
  options: Types.Maybe<ChatAgentFragment>[];
}

export const AgentSelectContext = createContext<AgentSelectContextValue>({
  loading: false,
  options: [],
});
