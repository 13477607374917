import {
  formLabelClasses as muiFormLabelClasses,
  FormLabelClassKey as MuiFormLabelClassKey,
} from '@mui/material/FormLabel';
import { omit } from 'lodash-es';

export type FormLabelClassKey = Exclude<MuiFormLabelClassKey, 'colorSecondary'>;
export type FormLabelClasses = Record<FormLabelClassKey, string>;

export const formLabelClasses: FormLabelClasses = omit(muiFormLabelClasses, [
  'colorSecondary',
]);
