import {
  paperClasses as muiPaperClasses,
  PaperClassKey as MuiPaperClassKey,
} from '@mui/material/Paper';
import { PaperElevation, PaperVariant } from './paper-type-map';

export type PaperClassKey =
  | Extract<MuiPaperClassKey, 'root' | 'rounded'>
  | PaperVariant
  | `elevation${PaperElevation}`;
export type PaperClasses = Record<PaperClassKey, string>;

export const paperClasses: PaperClasses = muiPaperClasses;
