import {
  AccordionSummaryClassKey as MuiAccordionSummaryClassKey,
  accordionSummaryClasses as muiAccordionSummaryClasses,
} from '@mui/material/AccordionSummary';

export type AccordionSummaryClassKey = Exclude<
  MuiAccordionSummaryClassKey,
  'gutters' | 'contentGutters'
>;
export type AccordionSummaryClasses = Record<AccordionSummaryClassKey, string>;

export const accordionSummaryClasses: AccordionSummaryClasses =
  muiAccordionSummaryClasses;
