import { EditorState, Modifier, SelectionState } from '@allganize/draft-input';
import { getSelectedInlineStyles } from './get-selected-inline-styles';

export const toggleInlineStyle = (
  editorState: EditorState,
  inlineStyle: string,
  selection: SelectionState = editorState.getSelection(),
): EditorState => {
  const currentStyle = editorState.getCurrentInlineStyle();
  // If the selection is collapsed, toggle the specified style on or off and
  // set the result as the new inline style override. This will then be
  // used as the inline style for the next character to be inserted.
  if (selection.isCollapsed()) {
    const hasInlineStyle = currentStyle.has(inlineStyle);

    // Let's just allow one color at a time. Turn off all active colors.
    const colorResetStyle = inlineStyle.startsWith('color-')
      ? (currentStyle.filter(
          x => !x || !x.startsWith('color-'),
        ) as typeof currentStyle)
      : currentStyle;

    const newInlineStyle = hasInlineStyle
      ? colorResetStyle.remove(inlineStyle)
      : colorResetStyle.add(inlineStyle);

    return EditorState.setInlineStyleOverride(editorState, newInlineStyle);
  }

  let content = editorState.getCurrentContent();
  const inlineStyles = getSelectedInlineStyles(editorState);

  // Let's just allow one color at a time. Turn off all active colors.
  if (inlineStyle.startsWith('color-')) {
    content = inlineStyles.reduce(
      (cs, style) =>
        style.startsWith('color-')
          ? Modifier.removeInlineStyle(cs, selection, style)
          : cs,
      content,
    );
  }

  // If characters are selected, immediately apply or remove the
  // inline style on the document state itself.
  let newContent;
  // If the style is already present for the selection range, remove it.
  // Otherwise, apply it.
  if (inlineStyles.includes(inlineStyle)) {
    newContent = Modifier.removeInlineStyle(content, selection, inlineStyle);
  } else {
    newContent = Modifier.applyInlineStyle(content, selection, inlineStyle);
  }

  return EditorState.push(editorState, newContent, 'change-inline-style');
};
