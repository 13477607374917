import { Skeleton } from '@allganize/ui-skeleton';
import { FunctionComponent } from 'react';
import { ChatContentBubble } from '../chat-content-bubble/chat-content-bubble';
import { ChatContentBubbleClasses } from '../chat-content-bubble/chat-content-bubble-classes';
import {
  ChatContentRow,
  ChatContentRowProps,
} from '../chat-content-row/chat-content-row';

interface ChatSkeletonProps extends Omit<ChatContentRowProps, 'avatar'> {
  BubbleClasses?: Partial<ChatContentBubbleClasses>;
  avatar?: 'inset' | boolean;
}

export const ChatSkeleton: FunctionComponent<ChatSkeletonProps> = ({
  align = 'left',
  BubbleClasses,
  avatar = false,
  ...other
}) => {
  return (
    <ChatContentRow
      data-testid="chat-skeleton"
      align={align}
      avatar={
        avatar &&
        (avatar === 'inset' ? (
          'inset'
        ) : (
          <Skeleton color="dark" variant="rounded" width={20} height={20} />
        ))
      }
      {...other}
    >
      <ChatContentBubble align={align} classes={BubbleClasses}>
        <Skeleton
          color="dark"
          variant="text"
          textVariant="body14"
          width={142}
        />
        <Skeleton color="dark" variant="text" textVariant="body14" width={71} />
      </ChatContentBubble>
    </ChatContentRow>
  );
};
