/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { LLMAppItemByCategoryFragment } from '../fragments/llm-app-item-by-category-fragment';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type LLMAppsByCategoryQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.LLMAppFilter>;
}>;

export type LLMAppsByCategoryQuery = { __typename: 'UserQuery' } & {
  llmAppsByCategory: Types.Maybe<
    { __typename: 'LLMAppListByCategory' } & {
      llmAppsByCategory: Types.Maybe<
        Array<
          Types.Maybe<
            {
              __typename: 'LLMAppItemByCategory';
            } & LLMAppItemByCategoryFragment
          >
        >
      >;
    }
  >;
};

export const LLMAppsByCategoryQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LLMAppsByCategoryQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LLMAppFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'llmAppsByCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '-1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'llmAppsByCategory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'LLMAppItemByCategoryFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LLMAppFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LLMApp' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignToken' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'singleActionApp' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'editUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LLMAppItemByCategoryFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LLMAppItemByCategory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMyapps' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'llmApps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LLMAppFragment' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LLMAppsByCategoryQuery,
  LLMAppsByCategoryQueryVariables
>;
