import {
  ContentBlock,
  DraftInlineStyle,
  DraftPlugin,
  DraftPluginFunctions,
} from '@allganize/draft-input';

export class DraftTextColorPlugin extends DraftPlugin {
  public customStyleFn(
    style: DraftInlineStyle,
    block: ContentBlock,
    pluginFunctions: DraftPluginFunctions,
  ): React.CSSProperties {
    const color =
      style
        .filter(inlineStyle => !!inlineStyle?.startsWith('color-'))
        .toArray()[0]
        ?.split('-')[1] || undefined;

    return { color };
  }
}
