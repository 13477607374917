import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type DraftDocumentListClassKey = 'root' | 'document';
export type DraftDocumentListClasses = Record<
  DraftDocumentListClassKey,
  string
>;

export const draftDocumentListClasses: DraftDocumentListClasses =
  generateUtilityClasses('DraftDocumentList', ['root', 'document']);
