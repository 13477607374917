import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { ReactMarkdownProps } from 'react-markdown/lib/ast-to-react';
import { markdownClasses } from '../markdown/markdown-classes';

export const Pre = forwardRef<
  HTMLPreElement,
  React.HTMLAttributes<HTMLPreElement> & ReactMarkdownProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, ...other } = props;
  const theme = useTheme();

  return (
    <pre
      css={css`
        margin: 16px 0;
        padding: 10px 12px;
        border-radius: ${theme.radius.xs}px;
        background-color: ${theme.palette.unstable_background.graySubtle.alpha};
        color: ${theme.palette.foreground.default};
      `}
      {...other}
      ref={ref}
      className={clsx(markdownClasses.pre, other.className)}
    />
  );
});
