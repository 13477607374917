import {
  outlinedInputClasses as muiOutlinedInputClasses,
  OutlinedInputClassKey as MuiOutlinedInputClassKey,
} from '@mui/material/OutlinedInput';
import { omit } from 'lodash-es';

export type InputClassKey = Exclude<
  MuiOutlinedInputClassKey,
  'colorSecondary' | 'sizeSmall' | 'inputSizeSmall'
>;
export type InputClasses = Record<InputClassKey, string>;

export const inputClasses: InputClasses = omit(muiOutlinedInputClasses, [
  'colorSecondary',
  'sizeSmall',
  'inputSizeSmall',
]);
