import {
  getSkeletonUtilityClass,
  skeletonClasses as muiSkeletonClasses,
  SkeletonClassKey as MuiSkeletonClassKey,
} from '@mui/material/Skeleton';

export type SkeletonClassKey = MuiSkeletonClassKey | 'colorLight' | 'colorDark';
export type SkeletonClasses = Record<SkeletonClassKey, string>;

export const skeletonClasses: SkeletonClasses = {
  ...muiSkeletonClasses,
  colorLight: getSkeletonUtilityClass('colorLight'),
  colorDark: getSkeletonUtilityClass('colorDark'),
};
