import { forwardRef } from 'react';
import { GroupBase } from 'react-select';
import Async from 'react-select/async';
import { SelectRef } from '../select';
import { useSelect } from '../select/use-select';
import { AsyncSelectProps } from './async-select-type-map';

interface AsyncSelectType {
  <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: React.PropsWithoutRef<AsyncSelectProps<Option, IsMulti, Group>> &
      React.RefAttributes<SelectRef<Option, IsMulti, Group>>,
  ): React.ReactNode;

  readonly $$typeof: symbol;
  displayName?: string;
}

// @ts-expect-error overridable component
export const AsyncSelect: AsyncSelectType = forwardRef(
  <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: AsyncSelectProps<Option, IsMulti, Group>,
    ref: React.ForwardedRef<SelectRef<Option, IsMulti, Group>>,
  ) => {
    const selectProps = useSelect(props);

    return <Async<Option, IsMulti, Group> {...selectProps} ref={ref} />;
  },
);
