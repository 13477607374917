import { createContext } from 'react';

export interface AssistantCampaignContextValue {
  assistantCampaignToken: string | null;
  isAssistantCampaign(campaignId?: string | number): boolean;
}

export const AssistantCampaignContext =
  createContext<AssistantCampaignContextValue>({
    assistantCampaignToken: null,
    isAssistantCampaign: () => false,
  });
