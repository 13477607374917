export const timeout = (ms: number): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    let timeoutId: string | number | NodeJS.Timeout | null = null;

    const clear = () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };

    try {
      timeoutId = setTimeout(() => {
        try {
          resolve();
          clear();
        } catch (err) {
          clear();
          reject(err);
        }
      }, ms);
    } catch (err) {
      clear();
      reject(err);
    }
  });
