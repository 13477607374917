import { InputBase } from '@allganize/ui-input';
import { ClassNames } from '@emotion/react';
import { GroupBase, InputProps } from 'react-select';
import { selectClasses } from './select-classes';

export const Input = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: InputProps<Option, IsMulti, Group>,
) => {
  const {
    clearValue,
    cx: cxProp,
    getStyles,
    getClassNames,
    getValue,
    hasValue,
    isMulti,
    isRtl,
    options,
    selectOption,
    selectProps,
    setValue,
    theme: themeProp,
    className,

    innerRef,
    isHidden,
    isDisabled,
    inputClassName,
    ...inputProps
  } = props;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <InputBase
          classes={{
            root: cx(
              css`
                margin: 2px;
                color: inherit;
                letter-spacing: inherit;
                font: inherit;
                flex: 1 1 auto;
                display: inline-grid;
                grid-area: 1 / 1 / 2 / 3;
                grid-template-columns: 0 min-content;

                &::after {
                  content: attr(data-value) ' ';
                  visibility: hidden;
                  white-space: pre;
                  grid-area: 1 / 2;
                  font: inherit;
                  letter-spacing: inherit;
                  min-width: 2px;
                }
              `,
              isDisabled &&
                css`
                  visibility: hidden;
                `,
              !!inputProps.value &&
                css`
                  transform: translateZ(0);
                `,
            ),
            input: cx(
              css`
                padding: 0;
                display: inline-block;
                min-width: 2px;
                grid-area: 1 / 2;
              `,
              isHidden &&
                css`
                  opacity: 0;
                `,
              inputClassName,
            ),
          }}
          inputProps={inputProps}
          inputRef={innerRef}
          data-value={inputProps.value || ''}
          disabled={isDisabled}
          required={false}
          className={cx(
            selectClasses.input,
            { [selectClasses.inputHidden]: isHidden },
            getClassNames('input', props),
            className,
          )}
        />
      )}
    </ClassNames>
  );
};
