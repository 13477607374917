import {
  IconButton,
  IconButtonProps,
  iconButtonClasses,
} from '@allganize/ui-button';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';

export const ColorSwatch: FunctionComponent = () => {
  const theme = useTheme();

  return (
    <span
      css={css`
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: ${theme.radius.round}px;
        background-color: currentColor;
        box-shadow: ${theme.elevations[2]};
      `}
    />
  );
};

interface ColorSwatchButtonProps
  extends Pick<IconButtonProps, 'disabled' | 'edge' | 'onClick'> {
  active?: boolean;
  color: string;
}

export const ColorSwatchButton: FunctionComponent<
  ColorSwatchButtonProps
> = props => {
  const { active, color, disabled, edge, onClick } = props;

  return (
    <IconButton
      css={css`
        &.${iconButtonClasses.disabled} {
          color: unset;
        }
      `}
      size="small"
      disabled={disabled}
      edge={edge}
      style={{ color }}
      onClick={onClick}
      variant={active ? 'filled' : 'ghost'}
    >
      <ColorSwatch />
    </IconButton>
  );
};
