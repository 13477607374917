export const acceptImageFiles = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
};

export const acceptFiles = {
  'text/plain': ['.txt'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'application/vnd.ms-excel.sheet.macroenabled.12': ['.xlsm'],
  'application/x-hwp': ['.hwp'],
  'application/vnd.hancom.hwp': ['.hwp'],
  'application/haansofthwp': ['.hwp'],
  'application/vnd.hancom.hwpx': ['.hwpx'],
  'application/haansofthwpx': ['.hwpx'],
  'application/pdf': ['.pdf'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'application/msword': ['.doc'],
};
