import useMuiMediaQuery, {
  UseMediaQueryOptions,
} from '@mui/material/useMediaQuery';

export const useMediaQuery = (
  queryInput: string,
  options?: UseMediaQueryOptions,
) => {
  return useMuiMediaQuery(queryInput, options);
};
