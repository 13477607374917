import {
  useDraftToolbarEditorState,
  useDraftToolbarEditorStateSetter,
} from '@allganize/draft-toolbar-plugin';
import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { IcFormatItalic } from '@allganize/ui-icons';
import { Tooltip } from '@allganize/ui-tooltip';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { getSelectedInlineStyles } from '../utils/get-selected-inline-styles';
import { toggleInlineStyleForSelection } from '../utils/toggle-inline-style-for-selection';

interface DraftToolbarTextItalicButtonProps extends IconButtonProps {}

export const DraftToolbarTextItalicButton = forwardRef<
  HTMLButtonElement,
  DraftToolbarTextItalicButtonProps
>((props, ref) => {
  const editorState = useDraftToolbarEditorState();
  const setEditorState = useDraftToolbarEditorStateSetter();
  const active = getSelectedInlineStyles(editorState).includes('ITALIC');

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    setEditorState(toggleInlineStyleForSelection(editorState, 'ITALIC'));
    props.onClick?.(ev);
  };

  return (
    <Tooltip
      title={
        <FormattedMessage
          id="TextArea.Italic"
          defaultMessage="Italic"
          description="Text area italic style tooltip text"
        />
      }
    >
      <IconButton
        color={active ? 'primary' : 'default'}
        size="small"
        {...props}
        onClick={handleClick}
        ref={ref}
      >
        <IcFormatItalic fontSize="small" />
      </IconButton>
    </Tooltip>
  );
});
