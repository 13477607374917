import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type DocumentViewerPageThumbnailListClassKey = 'root' | 'thumbnail';
export type DocumentViewerPageThumbnailListClasses = Record<
  DocumentViewerPageThumbnailListClassKey,
  string
>;

export const documentViewerPageThumbnailListClasses: DocumentViewerPageThumbnailListClasses =
  generateUtilityClasses('DocumentViewerPageThumbnailList', [
    'root',
    'thumbnail',
  ]);
