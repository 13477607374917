import { useId } from '@allganize/hooks';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { Button, ExtendButton } from '../button';
import { loadingButtonClasses } from './loading-button-classes';
import { LoadingButtonTypeMap } from './loading-button-type-map';

const loadingButtonBaseStyles = css`
  &.${loadingButtonClasses.root} {
    position: relative;

    &.${loadingButtonClasses.loading} {
      pointer-events: none;
      & > :not(.${loadingButtonClasses.loadingIndicator}) {
        color: transparent;
      }
    }
  }
`;

const loadingButtonIndicatorStyles = css`
  position: absolute;
  visibility: visible;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// @ts-expect-error overridable component
export const LoadingButton: ExtendButton<LoadingButtonTypeMap> = forwardRef(
  (props, ref) => {
    const {
      children,
      classes,
      id: idProp,
      loading = false,
      loadingIndicator: loadingIndicatorProp,
      ...other
    } = props;
    const reactId = useId();
    const id = idProp || `alli-loading-button-${reactId}`;

    const loadingIndicator = loadingIndicatorProp || (
      <CircularProgress
        aria-labelledby={id}
        color="inherit"
        size={other.size === 'small' ? 'xs' : 'sm'}
      />
    );

    const loadingButtonLoadingIndicator = loading && (
      <span
        css={loadingButtonIndicatorStyles}
        className={clsx(
          loadingButtonClasses.loadingIndicator,
          classes?.loadingIndicator,
        )}
      >
        {loadingIndicator}
      </span>
    );

    return (
      <Button
        data-testid="loading-button"
        id={id}
        {...other}
        ref={ref}
        css={loadingButtonBaseStyles}
        classes={{
          ...classes,
          root: clsx(loadingButtonClasses.root, classes?.root),
        }}
        className={clsx(
          {
            [loadingButtonClasses.loading]: loading,
            [classes?.loading ?? '']: loading,
          },
          other.className,
        )}
      >
        {loadingButtonLoadingIndicator}
        <span>{children}</span>
      </Button>
    );
  },
);
