import { useEventCallback, useUpdate } from '@allganize/hooks';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { clamp, ClampOptions } from './clamp';

export interface TruncateOptions extends Omit<ClampOptions, 'clamp'> {
  clamp?: ClampOptions['clamp'] | false;
}

export const useTruncate = (options?: TruncateOptions) => {
  const update = useUpdate();
  const ref = useRef<HTMLElement>(null);

  const truncate = useEventCallback(() => {
    if (!ref?.current) {
      return;
    }

    if (options?.clamp !== false) {
      clamp(ref.current, {
        ...options,
        clamp: options?.clamp,
      });
    }
  });

  useEffect(() => {
    window.addEventListener('resize', update, false);
    window.addEventListener('load', update, false);

    return () => {
      window.removeEventListener('resize', update, false);
      window.removeEventListener('load', update, false);
    };
  }, [update]);

  useLayoutEffect(() => {
    truncate();
  });

  return {
    ref,
    truncate,
  };
};
