import { useTheme } from '@allganize/ui-theme';
import { useSlotProps } from '@mui/base/utils';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import { forwardRef } from 'react';
import { useClearableField } from '../hooks/use-clearable-field';
import { InputField as InternalInputField } from './time-field-slots';
import {
  TimeFieldProps,
  TimeFieldSlotsComponent,
  TimeFieldSlotsComponentsProps,
} from './time-field-type-map';
import { useTimeField } from './use-time-field';

export const TimeField = forwardRef<HTMLDivElement, TimeFieldProps>(
  (props, ref) => {
    const { slots, slotProps, InputProps, inputProps, ...other } = props;
    const theme = useTheme();
    const muiTheme = useMuiTheme();
    const ownerState = props;

    const InputField = slots?.inputField ?? InternalInputField;
    const { inputRef: externalInputRef, ...inputFieldProps }: TimeFieldProps =
      useSlotProps<
        typeof InputField,
        TimeFieldSlotsComponentsProps['inputField'],
        TimeFieldProps,
        TimeFieldProps
      >({
        elementType: InputField,
        externalSlotProps: slotProps?.inputField,
        externalForwardedProps: other,
        ownerState,
      });

    // TODO: Remove when mui/material-ui#35088 will be merged
    inputFieldProps.inputProps = {
      ...inputProps,
      ...inputFieldProps.inputProps,
    };
    inputFieldProps.InputProps = {
      ...InputProps,
      ...inputFieldProps.InputProps,
    };

    const {
      ref: inputRef,
      onPaste,
      onKeyDown,
      inputMode,
      readOnly,
      clearable,
      onClear,
      ...fieldProps
    } = useTimeField<typeof inputFieldProps>({
      props: inputFieldProps,
      inputRef: externalInputRef,
    });

    const { InputProps: ProcessedInputProps, fieldProps: processedFieldProps } =
      useClearableField<
        typeof fieldProps,
        typeof fieldProps.InputProps,
        TimeFieldSlotsComponent,
        TimeFieldSlotsComponentsProps
      >({
        onClear,
        clearable,
        fieldProps,
        InputProps: fieldProps.InputProps,
        slots,
        slotProps,
      });

    return (
      <ThemeProvider theme={{ ...muiTheme, direction: theme.direction }}>
        <InputField
          data-testid="time-field"
          ref={ref}
          {...processedFieldProps}
          InputProps={{ ...ProcessedInputProps, readOnly }}
          inputProps={{
            ...fieldProps.inputProps,
            inputMode,
            onPaste,
            onKeyDown,
            ref: inputRef,
          }}
        />
      </ThemeProvider>
    );
  },
);
