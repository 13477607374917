import { useContext } from 'react';
import {
  DraftToolbarBlurContext,
  DraftToolbarDisabledGetterContext,
  DraftToolbarEditorStateContext,
  DraftToolbarEditorStateGetterContext,
  DraftToolbarEditorStateSetterContext,
  DraftToolbarFocusContext,
} from './draft-toolbar-context';

export const useDraftToolbarFocus = () => useContext(DraftToolbarFocusContext);
export const useDraftToolbarBlur = () => useContext(DraftToolbarBlurContext);
export const useDraftToolbarEditorState = () =>
  useContext(DraftToolbarEditorStateContext);
export const useDraftToolbarEditorStateGetter = () =>
  useContext(DraftToolbarEditorStateGetterContext);
export const useDraftToolbarEditorStateSetter = () =>
  useContext(DraftToolbarEditorStateSetterContext);
export const useDraftToolbarDisabledGetter = () =>
  useContext(DraftToolbarDisabledGetterContext);
