import {
  inputBaseClasses as muiInputBaseClasses,
  InputBaseClassKey as MuiInputBaseClassKey,
} from '@mui/material/InputBase';
import { omit } from 'lodash-es';

export type InputBaseClassKey = Exclude<
  MuiInputBaseClassKey,
  'sizeSmall' | 'colorSecondary' | 'hiddenLabel' | 'inputSizeSmall'
>;
export type InputBaseClasses = Record<InputBaseClassKey, string>;

export const inputBaseClasses: InputBaseClasses = omit(muiInputBaseClasses, [
  'sizeSmall',
  'colorSecondary',
  'hiddenLabel',
  'inputSizeSmall',
]);
