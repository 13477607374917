import MuiInputBase from '@mui/material/InputBase';
import { forwardRef } from 'react';
import { InputBaseComponent, InputBaseRoot } from './input-base-slots';
import { InputBaseProps } from './input-base-type-map';

export const InputBase = forwardRef<HTMLDivElement, InputBaseProps>(
  (props, ref) => {
    const { slots, ...other } = props;

    return (
      <MuiInputBase
        data-testid="input-base"
        {...other}
        ref={ref}
        slots={{
          root: InputBaseRoot,
          input: InputBaseComponent,
          ...slots,
        }}
      />
    );
  },
);
