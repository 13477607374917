import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthClient } from '../../auth/use-auth-client';

export const useOktaSecurity = () => {
  const navigate = useNavigate();
  const authClient = useAuthClient();
  const [oktaAuth, setOktaAuth] = useState(authClient?.getOktaAuth() ?? null);

  const restoreOriginalUri: RestoreOriginalUriFunction = async (
    _oktaAuth,
    originalUri,
  ) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
      replace: true,
    });
  };

  useEffect(() => {
    const handleOktaAuthChanged = (_oktaAuth: OktaAuth | null) => {
      setOktaAuth(_oktaAuth);
    };

    authClient?.on('oktaAuthChanged', handleOktaAuthChanged);

    return () => {
      authClient?.off('oktaAuthChanged', handleOktaAuthChanged);
    };
  }, [authClient]);

  return {
    oktaAuth,
    restoreOriginalUri,
  };
};
