import { FunctionComponent, ProviderProps } from 'react';
import {
  DraftToolbarBlurContext,
  DraftToolbarContextValue,
  DraftToolbarDisabledGetterContext,
  DraftToolbarEditorStateContext,
  DraftToolbarEditorStateGetterContext,
  DraftToolbarEditorStateSetterContext,
  DraftToolbarFocusContext,
} from './draft-toolbar-context';

interface DraftToolbarProviderProps
  extends ProviderProps<DraftToolbarContextValue> {}

export const DraftToolbarProvider: FunctionComponent<
  DraftToolbarProviderProps
> = props => {
  return (
    <DraftToolbarFocusContext.Provider value={props.value.focus}>
      <DraftToolbarBlurContext.Provider value={props.value.blur}>
        <DraftToolbarEditorStateGetterContext.Provider
          value={props.value.getEditorState}
        >
          <DraftToolbarEditorStateSetterContext.Provider
            value={props.value.setEditorState}
          >
            <DraftToolbarDisabledGetterContext.Provider
              value={props.value.getDisabled}
            >
              <DraftToolbarEditorStateContext.Provider
                value={props.value.editorState}
              >
                {props.children}
              </DraftToolbarEditorStateContext.Provider>
            </DraftToolbarDisabledGetterContext.Provider>
          </DraftToolbarEditorStateSetterContext.Provider>
        </DraftToolbarEditorStateGetterContext.Provider>
      </DraftToolbarBlurContext.Provider>
    </DraftToolbarFocusContext.Provider>
  );
};
