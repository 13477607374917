import { css } from '@emotion/react';
import MuiDialogContent from '@mui/material/DialogContent';
import { forwardRef } from 'react';
import { dialogTitleClasses } from '../dialog-title';
import { DialogContentProps } from './dialog-content-type-map';

export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
  (props, ref) => {
    return (
      <MuiDialogContent
        data-testid="dialog-content"
        css={css`
          padding: 20px 24px;

          .${dialogTitleClasses.root} + & {
            padding-top: 0;
          }

          &:not(:last-child) {
            padding-bottom: 0;
          }
        `}
        {...props}
        ref={ref}
      />
    );
  },
);
