import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { Button } from '../button';
import { ExtendButtonBase } from '../button-base';
import { IconButtonClassKey, iconButtonClasses } from './icon-button-classes';
import { IconButtonProps, IconButtonTypeMap } from './icon-button-type-map';
import { mapValues } from 'lodash-es';

const sizeStyles = (size: IconButtonProps['size']) => [
  size === 'small' &&
    css`
      min-width: 24px;
      width: 24px;
      padding: 4px;
      font-size: 16px;
    `,
  size === 'medium' &&
    css`
      min-width: 32px;
      width: 32px;
      padding: 6px;
      font-size: 20px;
    `,
  size === 'large' &&
    css`
      min-width: 36px;
      width: 36px;
      padding: 8px;
      font-size: 20px;
    `,
];

const edgeStyles = (
  edge: IconButtonProps['edge'],
  size: IconButtonProps['size'],
) => [
  size === 'small' &&
    edge === 'start' &&
    css`
      margin-left: -4px;
    `,
  size === 'small' &&
    edge === 'end' &&
    css`
      margin-right: -4px;
    `,
  size === 'medium' &&
    edge === 'start' &&
    css`
      margin-left: -6px;
    `,
  size === 'medium' &&
    edge === 'end' &&
    css`
      margin-right: -6px;
    `,
  size === 'large' &&
    edge === 'start' &&
    css`
      margin-left: -8px;
    `,
  size === 'large' &&
    edge === 'end' &&
    css`
      margin-right: -8px;
    `,
];

// @ts-expect-error overridable component
export const IconButton: ExtendButtonBase<IconButtonTypeMap> = forwardRef(
  (props, ref) => {
    const {
      color = 'default',
      variant = 'ghost',
      shape = 'circular', // default to circular for compability with old designs
      edge = false,
      size = 'medium',
      classes: classesProp,
      ...other
    } = props;
    const classes = mapValues(iconButtonClasses, (value, key) => {
      return clsx(value, classesProp?.[key as IconButtonClassKey]);
    });

    return (
      <Button
        data-testid="icon-button"
        css={[sizeStyles(size), edgeStyles(edge, size)]}
        color={color}
        variant={variant}
        shape={shape}
        edge={edge}
        size={size}
        classes={classes}
        {...other}
        ref={ref}
        className={clsx(
          iconButtonClasses[variant],
          classes?.[variant],
          other.className,
        )}
        focusVisibleClassName={clsx(
          iconButtonClasses.focusVisible,
          classes?.focusVisible,
          other.focusVisibleClassName,
        )}
      />
    );
  },
);
