/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatAgentFragment } from '../fragments/chat-agent-fragment';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ChatAgentsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ChatAgentsQuery = { __typename: 'UserQuery' } & {
  agents: Array<Types.Maybe<{ __typename: 'Agent' } & ChatAgentFragment>>;
};

export const ChatAgentsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ChatAgentsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChatAgentFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatAgentFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Agent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatAgentsQuery, ChatAgentsQueryVariables>;
