import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type DocumentViewerPageThumbnailClassKey =
  | 'root'
  | 'image'
  | 'pageNumber'
  | 'loading'
  | 'error'
  | 'loaded'
  | 'selected';
export type DocumentViewerPageThumbnailClasses = Record<
  DocumentViewerPageThumbnailClassKey,
  string
>;

export const documentViewerPageThumbnailClasses: DocumentViewerPageThumbnailClasses =
  generateUtilityClasses('DocumentViewerPageThumbnail', [
    'root',
    'image',
    'pageNumber',
    'loading',
    'error',
    'loaded',
    'selected',
  ]);
