import { Storage } from '@allganize/utils-storage';
import { config } from '../../config';

export class AlliPersistedUserIdManager {
  private storageKey: string = config.storage.persistedUserId;

  constructor(private storage: Storage, keySuffix = '') {
    this.storageKey += keySuffix;
  }

  public async getUserId(): Promise<string | undefined> {
    const value = await this.storage.getItem(this.storageKey);
    return value;
  }

  public async setUserId(value: string) {
    return this.storage.setItem(this.storageKey, value);
  }
}
