import { ExtendIconButton, IconButton } from '@allganize/ui-button';
import { IcClose } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { forwardRef, useContext } from 'react';
import { DialogCloseButtonTypeMap } from './dialog-close-button-type-map';
import clsx from 'clsx';
import { DialogContext } from '../dialog/dialog-context';
import { dialogCloseButtonClasses } from './dialog-close-button-classes';

// @ts-expect-error overridable component
export const DialogCloseButton: ExtendIconButton<DialogCloseButtonTypeMap> =
  forwardRef((props, ref) => {
    const { classes, onClick, ...other } = props;
    const { onClose } = useContext(DialogContext);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      onClose?.(e, 'closeButtonClick');
      onClick?.(e);
    };

    return (
      <IconButton
        data-testid="dialog-close-button"
        css={css`
          position: absolute;
          top: 12px;
          right: 12px;
        `}
        classes={classes}
        {...other}
        ref={ref}
        className={clsx(dialogCloseButtonClasses.root, other.className)}
        onClick={handleClick}
      >
        <IcClose fontSize="inherit" />
      </IconButton>
    );
  });
