import { useTheme } from '@allganize/ui-theme';
import { useSlotProps } from '@mui/base/utils';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import clsx from 'clsx';
import { Children, forwardRef, isValidElement, useRef } from 'react';
import { isFragment } from 'react-is';
import { MenuListActions } from '../menu-list';
import { menuClasses } from './menu-classes';
import { MenuMenuList, MenuPaper, MenuRoot } from './menu-slots';
import { MenuProps } from './menu-type-map';

const RTL_ORIGIN = {
  vertical: 'top',
  horizontal: 'right',
} as const;

const LTR_ORIGIN = {
  vertical: 'top',
  horizontal: 'left',
} as const;

export const Menu = forwardRef<HTMLDivElement, MenuProps>((props, ref) => {
  const {
    autoFocus = true,
    children,
    className,
    classes,
    disableAutoFocusItem = false,
    MenuListProps = {},
    onClose,
    open,
    PopoverClasses,
    transitionDuration = 'auto',
    TransitionProps: { onEntering, ...TransitionProps } = {},
    variant = 'selectedMenu',
    slots = {},
    slotProps = {},
    ...other
  } = props;
  const theme = useTheme();
  const oldMuiTheme = useMuiTheme();
  const muiTheme = { ...oldMuiTheme, direction: theme.direction };
  const isRtl = theme.direction === 'rtl';

  const ownerState = {
    ...props,
    autoFocus,
    disableAutoFocusItem,
    MenuListProps,
    onEntering,
    transitionDuration,
    TransitionProps,
    variant,
  };

  const autoFocusItem = autoFocus && !disableAutoFocusItem && open;
  const menuListActionsRef = useRef<MenuListActions>(null);

  const handleEntering = (element: HTMLElement, isAppearing: boolean) => {
    if (menuListActionsRef.current) {
      menuListActionsRef.current.adjustStyleForScrollbar(element, muiTheme);
    }

    if (onEntering) {
      onEntering(element, isAppearing);
    }
  };

  const handleListKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === 'Tab') {
      event.preventDefault();

      if (onClose) {
        onClose(event, 'tabKeyDown');
      }
    }
  };

  /**
   * the index of the item should receive focus
   * in a `variant="selectedMenu"` it's the first `selected` item
   * otherwise it's the very first item.
   */
  let activeItemIndex = -1;
  // since we inject focus related props into children we have to do a lookahead
  // to check if there is a `selected` item. We're looking for the last `selected`
  // item and use the first valid item as a fallback
  Children.map(children, (child, index) => {
    if (!isValidElement(child)) {
      return;
    }

    if (process.env.NODE_ENV !== 'production') {
      if (isFragment(child)) {
        console.error(
          [
            "MUI: The Menu component doesn't accept a Fragment as a child.",
            'Consider providing an array instead.',
          ].join('\n'),
        );
      }
    }

    if (!child.props.disabled) {
      if (variant === 'selectedMenu' && child.props.selected) {
        activeItemIndex = index;
      } else if (activeItemIndex === -1) {
        activeItemIndex = index;
      }
    }
  });

  const PaperSlot = slots.paper ?? MenuPaper;

  const rootSlotProps = useSlotProps({
    elementType: slots.root,
    externalSlotProps: slotProps.root,
    // @ts-expect-error internal component
    ownerState,
    className: [menuClasses.root, classes?.root, className],
  });

  const paperSlotProps = useSlotProps({
    elementType: PaperSlot,
    externalSlotProps: slotProps.paper,
    ownerState,
    className: [menuClasses.paper, classes?.paper],
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <MenuRoot
        data-testid="menu"
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: isRtl ? 'right' : 'left',
        }}
        transformOrigin={isRtl ? RTL_ORIGIN : LTR_ORIGIN}
        {...other}
        ref={ref}
        slots={{
          paper: PaperSlot,
          root: slots.root,
        }}
        slotProps={{
          root: rootSlotProps,
          paper: paperSlotProps,
        }}
        open={open}
        transitionDuration={transitionDuration}
        TransitionProps={{ onEntering: handleEntering, ...TransitionProps }}
        classes={PopoverClasses}
      >
        <MenuMenuList
          data-test="menu__menu-list"
          onKeyDown={handleListKeyDown}
          actions={menuListActionsRef}
          autoFocus={
            autoFocus && (activeItemIndex === -1 || disableAutoFocusItem)
          }
          autoFocusItem={autoFocusItem}
          variant={variant}
          {...MenuListProps}
          className={clsx(
            menuClasses.list,
            classes?.list,
            MenuListProps.className,
          )}
        >
          {children}
        </MenuMenuList>
      </MenuRoot>
    </ThemeProvider>
  );
});
