import { Types } from '@allganize/alli-sdk-interfaces';

export const CONVERSATION_STATE_WITH_AGENT: Types.ConversationStateEnum[] = [
  Types.ConversationStateEnum.WAIT_AGENT,
  Types.ConversationStateEnum.INTERRUPTED,
];

export const CONVERSATION_STATE_WAIT_USER_ANSWER: Types.ConversationStateEnum[] =
  [
    Types.ConversationStateEnum.WAIT_USER_ANSWER,
    Types.ConversationStateEnum.WAIT_USER_ANSWER_2,
  ];

export const CONVERSATION_STATE_ENDED: Types.ConversationStateEnum[] = [
  Types.ConversationStateEnum.END_BY_USER,
  Types.ConversationStateEnum.END_OF_CHATBOT,
  Types.ConversationStateEnum.END_BY_AGENT,
  Types.ConversationStateEnum.END_OF_OBSERVER,
];
