import { Types } from '@allganize/alli-sdk-interfaces';
import { DraftFilePlugin } from '@allganize/draft-file-plugin';
import { DraftImagePlugin } from '@allganize/draft-image-plugin';
import {
  ContentState,
  EditorState,
  removeBlockIfEmpty,
} from '@allganize/draft-input';
import { useMemo } from 'react';
import { ChatMediaFragment } from '../graphql/fragments/chat-media-fragment';

export const getChatMediaContentState = ({
  media,
  isOptionType,
}: UseChatMediaOptions): ContentState => {
  const editorState = (Array.isArray(media) ? media : [media]).reduce(
    (acc, curr) => {
      if (!curr) {
        return acc;
      }

      if (curr.mediaType === 'IMAGE') {
        return DraftImagePlugin.addImage(acc, curr.url, {
          ...curr,
          isOptionType,
        });
      }

      return DraftFilePlugin.addFile(acc, curr.url, curr);
    },
    EditorState.createEmpty(),
  );
  const contentStateWithMedia = editorState.getCurrentContent();
  const firstBlockRemoved = removeBlockIfEmpty(
    contentStateWithMedia,
    contentStateWithMedia.getBlockMap().first(),
  );
  return removeBlockIfEmpty(
    firstBlockRemoved,
    firstBlockRemoved.getBlockMap().last(),
  );
};

export interface UseChatMediaOptions {
  media: Types.Maybe<ChatMediaFragment> | Types.Maybe<ChatMediaFragment>[];
  isOptionType?: boolean;
}

export const useChatMedia = ({ media, isOptionType }: UseChatMediaOptions) => {
  const contentState = useMemo(
    () => getChatMediaContentState({ media, isOptionType }),
    [media, isOptionType],
  );
  return { contentState };
};
