import { createSvgIcon } from '@allganize/ui-icons';

export const IcFilePPT = createSvgIcon(
  <>
    <path
      d="M6 22.5469H20.3077C21.1361 22.5469 21.8077 21.8753 21.8077 21.0469V7.69265C21.8077 7.29885 21.6528 6.92085 21.3765 6.64024L16.7099 1.90072C16.428 1.61437 16.0429 1.45312 15.6411 1.45312H6C5.17157 1.45312 4.5 2.1247 4.5 2.95312V21.0469C4.5 21.8753 5.17157 22.5469 6 22.5469Z"
      fill="white"
    />
    <path
      d="M5.0625 2.95312C5.0625 2.43536 5.48223 2.01562 6 2.01562H15.6411C15.6631 2.01562 15.685 2.0164 15.7068 2.01793V5.57812C15.7068 6.71721 16.6302 7.64062 17.7693 7.64062H21.2437C21.2447 7.6579 21.2452 7.67525 21.2452 7.69265V21.0469C21.2452 21.5646 20.8255 21.9844 20.3077 21.9844H6C5.48223 21.9844 5.0625 21.5646 5.0625 21.0469V2.95312ZM20.4644 6.51562L16.8318 2.82621V5.57812C16.8318 6.09589 17.2515 6.51562 17.7693 6.51562H20.4644ZM6 0.890625C4.86091 0.890625 3.9375 1.81404 3.9375 2.95312V21.0469C3.9375 22.186 4.86092 23.1094 6 23.1094H20.3077C21.4468 23.1094 22.3702 22.186 22.3702 21.0469V7.69265C22.3702 7.15117 22.1573 6.63142 21.7774 6.24559L17.1108 1.50607C16.7231 1.11234 16.1936 0.890625 15.6411 0.890625H6Z"
      fill="black"
      fillOpacity="0.2"
      fillRule="evenodd"
    />
    <path
      d="M0.75 12.75C0.75 11.0931 2.09315 9.75 3.75 9.75H12.75C14.4069 9.75 15.75 11.0931 15.75 12.75V17.25C15.75 18.9069 14.4069 20.25 12.75 20.25H3.75C2.09315 20.25 0.75 18.9069 0.75 17.25V12.75Z"
      fill="#FFAD25"
    />
    <path
      d="M2.8031 12.8184V17.182H3.85779V15.8269H4.5737C4.9075 15.8269 5.19586 15.7651 5.43875 15.6415C5.68165 15.5179 5.86844 15.3439 5.99912 15.1195C6.13123 14.8936 6.19728 14.6301 6.19728 14.329C6.19728 14.0293 6.13265 13.7665 6.00338 13.5407C5.87412 13.3134 5.69017 13.1365 5.45154 13.0101C5.21432 12.8823 4.93236 12.8184 4.60566 12.8184H2.8031ZM3.85779 14.9959H4.37767C4.53677 14.9959 4.66958 14.9682 4.77611 14.9128C4.88407 14.8574 4.96574 14.78 5.02114 14.6806C5.07654 14.5797 5.10424 14.4625 5.10424 14.329C5.10424 14.1941 5.07654 14.0776 5.02114 13.9796C4.96574 13.8801 4.88407 13.8034 4.77611 13.7495C4.66816 13.6955 4.53534 13.6685 4.37767 13.6685H3.85779V14.9959ZM6.47693 17.182V12.8184H8.27949C8.60619 12.8184 8.88815 12.8823 9.12537 13.0101C9.364 13.1365 9.54795 13.3134 9.67721 13.5407C9.80647 13.7665 9.8711 14.0293 9.8711 14.329C9.8711 14.6301 9.80505 14.8936 9.67295 15.1195C9.54227 15.3439 9.35548 15.5179 9.11258 15.6415C8.86968 15.7651 8.58133 15.8269 8.24753 15.8269H7.53162V17.182H6.47693ZM8.0515 14.9959H7.53162V13.6685H8.0515C8.20917 13.6685 8.34199 13.6955 8.44994 13.7495C8.55789 13.8034 8.63957 13.8801 8.69497 13.9796C8.75037 14.0776 8.77807 14.1941 8.77807 14.329C8.77807 14.4625 8.75037 14.5797 8.69497 14.6806C8.63957 14.78 8.55789 14.8574 8.44994 14.9128C8.34341 14.9682 8.21059 14.9959 8.0515 14.9959ZM10.0087 13.6749V12.8184H13.6969V13.6749H12.3737V17.182H11.334V13.6749H10.0087Z"
      fill="white"
      fillRule="evenodd"
    />
  </>,
  'ic-file-ppt',
);
