import { IframeContext } from '@allganize/react-iframe';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { DateTimePickerTabs as MuiDateTimePickerTabs } from '@mui/x-date-pickers/DateTimePicker/DateTimePickerTabs';
import { FunctionComponent, useContext } from 'react';
import { DateTimePickerTabsProps } from './date-time-picker-tabs-type-map';

export const DateTimePickerTabs: FunctionComponent<
  DateTimePickerTabsProps
> = props => {
  const { window } = useContext(IframeContext);
  const {
    hidden = typeof window === 'undefined' || window.innerHeight < 667,
    ...other
  } = props;
  const theme = useTheme();

  return (
    <MuiDateTimePickerTabs
      data-testid="date-time-picker-tabs"
      css={css`
        box-shadow: 0 -1px 0 0 inset ${theme.palette.divider};

        &:last-child {
          box-shadow: 0 1px 0 0 inset ${theme.palette.divider};
        }
      `}
      hidden={hidden}
      {...other}
    />
  );
};
