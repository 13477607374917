import { Types } from '@allganize/alli-sdk-interfaces';
import { defaultLocale, Locale, LOCALES } from './locales';

export const getBrowserLocale = () => {
  const navigatorLocales = (
    [
      window.navigator.language,
      ...window.navigator.languages,
      'userLanguage' in window.navigator &&
        typeof window.navigator.userLanguage === 'string' &&
        window.navigator.userLanguage,
      'browserLanguage' in window.navigator &&
        typeof window.navigator.browserLanguage === 'string' &&
        window.navigator.browserLanguage,
      'systemLanguage' in window.navigator &&
        typeof window.navigator.systemLanguage === 'string' &&
        window.navigator.systemLanguage,
    ].filter(Boolean) as string[]
  )
    .map(locale => locale.split('-')[0])
    .filter(locale => LOCALES.includes(locale as any)) as Locale[];

  return navigatorLocales[0] || defaultLocale;
};

export const localeToEnum: Record<Locale, Types.LocaleEnum> = {
  en: Types.LocaleEnum.EN,
  ko: Types.LocaleEnum.KO,
  ja: Types.LocaleEnum.JA,
};

export const enumToLocale: Record<Types.LocaleEnum, Locale> = {
  [Types.LocaleEnum.EN]: 'en',
  [Types.LocaleEnum.KO]: 'ko',
  [Types.LocaleEnum.JA]: 'ja',
  [Types.LocaleEnum.CN]: defaultLocale,
  [Types.LocaleEnum.DE]: defaultLocale,
  [Types.LocaleEnum.FR]: defaultLocale,
  [Types.LocaleEnum.ZH_CN]: defaultLocale,
  [Types.LocaleEnum.ZH_TW]: defaultLocale,
  [Types.LocaleEnum.ES]: defaultLocale,
  [Types.LocaleEnum.TH]: defaultLocale,
};
