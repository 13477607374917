import { useProject } from '@allganize/alli-app-market-project';
import { AlliClientContext } from '@allganize/alli-sdk/alli-client/alli-client-context';
import { AlliApiEndpoints } from '@allganize/alli-sdk/core/alli-auth-client/alli-auth-client-options';
import { AlliDeviceIdManager } from '@allganize/alli-sdk/core/alli-auth-client/alli-device-id-manager';
import { AlliKeyAuthClient } from '@allganize/alli-sdk/core/alli-auth-client/alli-key-auth-client';
import { AlliPersistedUserIdManager } from '@allganize/alli-sdk/core/alli-auth-client/alli-persisted-user-id-manager';
import { AlliClient } from '@allganize/alli-sdk/core/alli-client/alli-client';
import { AlliClientEntryType } from '@allganize/alli-sdk/core/alli-client/alli-client-options';
import { AlliLauncherManager } from '@allganize/alli-sdk/core/alli-launcher-manager/alli-launcher-manager';
import { AlliPageErrorManager } from '@allganize/alli-sdk/core/alli-page-error-manager/alli-page-error-manager';
import { AlliPlacementManager } from '@allganize/alli-sdk/core/alli-placement-manager/alli-placement-manager';
import { AlliUserManager } from '@allganize/alli-sdk/core/alli-user-manager/alli-user-manager';
import { CurrentUserProvider } from '@allganize/alli-sdk/current-user/current-user-provider';
import { ProjectProvider } from '@allganize/alli-sdk/project/project-provider';
import { hub, sandboxId } from '@allganize/alli-sdk/sentry';
import { CookieStorage } from '@allganize/utils-storage';
import { ApolloProvider } from '@apollo/client/react';
import { ErrorInfo, Suspense, useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { getCookieOptions } from '../../utils/get-cookie-options';

let alliClient: AlliClient | null = null;

interface InitializeOptions {
  apiKey: string;
  endpoints: AlliApiEndpoints;
  debug?: boolean;
  entryType?: AlliClientEntryType;
  fullscreen?: boolean;
  errorManager?: AlliPageErrorManager;
  hideCloseButton?: boolean;
  campaignToken?: string;
  chatToken?: string;
}

const initializeWithoutRender = async ({
  apiKey: sdkKey,
  errorManager,
  campaignToken,
  chatToken,
  debug,
  endpoints,
  entryType = 'market',
}: InitializeOptions) => {
  if (!alliClient) {
    const storage = new CookieStorage(undefined, getCookieOptions());
    const apiKeySuffix = `__${sdkKey}`;
    const deviceIdManager = new AlliDeviceIdManager(storage);
    const persistedUserIdManager = new AlliPersistedUserIdManager(
      storage,
      apiKeySuffix,
    );
    const userManager = new AlliUserManager();
    const authClient = new AlliKeyAuthClient({
      apiKey: sdkKey,
      chatToken,
      campaignToken,
      endpoints,
      sdkType: AlliKeyAuthClient.entryTypeToSdkType(entryType),
      deviceIdManager,
      persistedUserIdManager,
      userManager,
    });
    const placementManager = new AlliPlacementManager({ useUrl: true });
    const launcherManager = new AlliLauncherManager({ hidden: true });
    alliClient = new AlliClient(authClient, placementManager, launcherManager, {
      debug,
      entryType,
      fullscreen: true,
      errorManager,
      hideCloseButton: true,
    });
  }

  return alliClient.initialize();
};

const destroy = () => {
  alliClient?.destroy();
  alliClient = null;
  document.getElementById(sandboxId)?.remove();
};

export const ConversationLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const project = useProject();
  const searchParams = new URLSearchParams(window.location.search);
  const userId = searchParams.get('user_id');

  const [alliClient, setAlliClient] = useState<AlliClient | null>(null);
  const handleError = useCallback(
    (error: Error, info: ErrorInfo) => {
      if (!hub || !alliClient) return;
      alliClient.errorManager?.report('unknown');
      hub?.captureException(error, { data: { info } });
    },
    [alliClient],
  );

  useEffect(() => {
    if (!project.apiKey || !project.apiEndpoint) return;

    const errorManager = new AlliPageErrorManager();
    errorManager.on('error', destroy);

    initializeWithoutRender({
      apiKey: project.apiKey,
      errorManager,
      endpoints: {
        http: `${project.apiEndpoint}/d/user/`,
        ws: `${project.apiEndpoint.replace('http', 'ws')}/d/ws/user/`,
      },
    }).then(alliClient => {
      if (userId) {
        alliClient.setUserId(userId);
      }
      setAlliClient(alliClient);
      alliClient.launcherManager.show();
    });

    return () => {
      destroy();
      errorManager.off('error', destroy);
      setAlliClient(null);
    };
  }, [project.apiKey, project.apiEndpoint, userId]);

  if (!alliClient?.apolloClient) return null;

  return (
    <AlliClientContext.Provider value={{ client: alliClient }}>
      <ApolloProvider client={alliClient.apolloClient}>
        <ErrorBoundary fallback={null} onError={handleError}>
          <Suspense fallback={null}>
            <ProjectProvider>
              <CurrentUserProvider>{children}</CurrentUserProvider>
            </ProjectProvider>
          </Suspense>
        </ErrorBoundary>
      </ApolloProvider>
    </AlliClientContext.Provider>
  );
};
