import { defineMessages } from 'react-intl';

export const ariaLabels = defineMessages({
  'back-to-conversation-list-button': {
    id: 'back-to-conversation-list-button.aria-label',
    defaultMessage: 'Back to conversation list',
    description: 'Back to conversation list button aria-label',
  },
  'new-conversation-button': {
    id: 'new-conversation-button.aria-label',
    defaultMessage: 'Start a new conversation',
    description: 'new conversation button aria-label',
  },
  'chat-menu-button': {
    id: 'chat-menu-button.aria-label',
    defaultMessage: 'See more chat options',
    description: 'Chat menu button aria-label',
  },
  'chatbot-close-button': {
    id: 'chatbot-close-button.aria-label',
    defaultMessage: 'Close Chatbot',
    description: 'Chatbot close button aria-label',
  },
  'chatbot-open-button': {
    id: 'chatbot-open-button.aria-label',
    defaultMessage: 'Open Chatbot',
    description: 'Chatbot open button aria-label',
  },
  'send-chat-button': {
    id: 'send-chat-button.aria-label',
    defaultMessage: 'Send chat',
    description: 'Send chat button aria-label',
  },
});
