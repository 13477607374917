import {
  IcArrowNavigateBefore,
  IcArrowNavigateNext,
} from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import Slider from 'react-slick';
import { CarouselArrow } from '../carousel-arrow';
import { carouselClasses } from './carousel-classes';
import { CarouselProps, CarouselRef } from './carousel-type-map';

const dotColor = 'black';
const dotColorActive = dotColor;
const dotCharacter = '"\\2022"';
const dotSize = '6px';
const opacityDefault = 0.75;
const opacityOnHover = 1;
const opacityNotActive = 0.25;

export const Carousel = forwardRef<CarouselRef, CarouselProps>((props, ref) => {
  const {
    arrows = true,
    centerMode = false,
    classes,
    dots = false,
    infinite = false,
    slidesToShow = 1,
    vertical = false,
    afterChange,
    arrowProps,
    ...other
  } = props;
  const theme = useTheme();
  const sliderRef = useRef<Slider>(null);

  useImperativeHandle(
    ref,
    () => ({
      slickNext() {
        sliderRef.current?.slickNext();
      },
      slickPause() {
        sliderRef.current?.slickPause();
      },
      slickPlay() {
        sliderRef.current?.slickPlay();
      },
      slickPrev() {
        sliderRef.current?.slickPrev();
      },
      slickGoTo(slideNumber: number, dontAnimate?: boolean) {
        sliderRef.current?.slickGoTo(slideNumber, dontAnimate);
      },
    }),
    [],
  );

  return (
    <ClassNames>
      {({ css, cx }) => (
        <Slider
          arrows={arrows}
          centerMode={centerMode}
          dots={dots}
          infinite={infinite}
          nextArrow={
            <CarouselArrow
              centerMode={centerMode}
              direction="next"
              edge="end"
              infinite={infinite}
              slidesToShow={slidesToShow}
              {...arrowProps?.next}
            >
              <IcArrowNavigateNext />
            </CarouselArrow>
          }
          prevArrow={
            <CarouselArrow
              centerMode={centerMode}
              direction="prev"
              edge="end"
              infinite={infinite}
              slidesToShow={slidesToShow}
              {...arrowProps?.prev}
            >
              <IcArrowNavigateBefore />
            </CarouselArrow>
          }
          rtl={theme.direction === 'rtl'}
          slidesToShow={slidesToShow}
          vertical={vertical}
          afterChange={idx => {
            if (sliderRef.current && sliderRef.current.innerSlider) {
              sliderRef.current.innerSlider.clickable = true;
            }
            afterChange?.(idx);
          }}
          {...other}
          ref={sliderRef}
          className={cx(
            css`
              position: relative;
              display: block;
              box-sizing: border-box;
              -webkit-touch-callout: none;
              user-select: none;
              touch-action: pan-y;
              -webkit-tap-highlight-color: transparent;

              .${carouselClasses.track}, .${carouselClasses.list} {
                transform: translate3d(0, 0, 0);
              }
            `,
            arrows &&
              css`
                padding-top: 40px;
              `,
            dots &&
              css`
                margin-bottom: 30px;
              `,
            carouselClasses.root,
            {
              [carouselClasses.vertical]: vertical,
              [carouselClasses.dotted]: dots,
            },
            classes?.root,
            {
              [classes?.vertical ?? '']: vertical,
              [classes?.dotted ?? '']: dots,
            },
            other.className,
          )}
          dotsClass={cx(
            css`
              position: absolute;
              bottom: -25px;
              list-style: none;
              display: block;
              text-align: center;
              padding: 0;
              margin: 0;
              width: 100%;

              li {
                position: relative;
                display: inline-block;
                height: 20px;
                width: 20px;
                margin: 0 5px;
                padding: 0;
                cursor: pointer;

                button {
                  border: 0;
                  background: transparent;
                  display: block;
                  height: 20px;
                  width: 20px;
                  outline: none;
                  line-height: 0px;
                  font-size: 0px;
                  color: transparent;
                  padding: 5px;
                  cursor: pointer;

                  &:hover,
                  &:focus {
                    outline: none;

                    &:before {
                      opacity: ${opacityOnHover};
                    }
                  }

                  &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: ${dotCharacter};
                    width: 20px;
                    height: 20px;
                    font-size: ${dotSize};
                    line-height: 20px;
                    text-align: center;
                    color: ${dotColor};
                    opacity: ${opacityNotActive};
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                  }
                }

                &.${carouselClasses.dotActive} button:before {
                  color: ${dotColorActive};
                  opacity: ${opacityDefault};
                }
              }
            `,
            carouselClasses.dots,
            classes?.dots,
          )}
        />
      )}
    </ClassNames>
  );
});
