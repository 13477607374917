import styled from '@emotion/styled';
import { Layout } from '../styled-components';
import { HTMLAttributes } from 'react';

const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const FullscreenLayout = ({
  children,
  ...others
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <Layout {...others}>
      <Container>{children}</Container>
    </Layout>
  );
};
