import {
  ContentBlock,
  ContentState,
  SelectionState,
} from '@allganize/draft-input';

export const getSelectedBlocks = (
  contentState: ContentState,
  selection: SelectionState,
): ContentBlock[] => {
  const anchorKey = selection.getStartKey();
  const focusKey = selection.getEndKey();
  const isSameBlock = anchorKey === focusKey;
  const startingBlock = contentState.getBlockForKey(anchorKey);
  const selectedBlocks = startingBlock ? [startingBlock] : [];

  if (!isSameBlock) {
    let blockKey = anchorKey;

    while (blockKey !== focusKey) {
      const nextBlock = contentState.getBlockAfter(blockKey);

      if (nextBlock) {
        selectedBlocks.push(nextBlock);
        blockKey = nextBlock.getKey();
      } else {
        break;
      }
    }
  }

  return selectedBlocks;
};
