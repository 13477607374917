import { useMediaQuery } from '@allganize/hooks';
import { Dialog } from '@allganize/ui-dialog';
import { ToastContainer } from '@allganize/ui-toast';
import { ClassNames, css, useTheme } from '@emotion/react';
import { HTMLMotionProps, motion } from 'framer-motion';
import { FunctionComponent, useContext } from 'react';
import { DocumentPreview } from '../document-preview/document-preview';
import { DocumentPreviewContext } from '../document-preview/document-preview-context';
import { SingleActionPreview } from '../single-action-preview/single-action-preview';
import { SingleActionPreviewContext } from '../single-action-preview/single-action-preview-context';
import { PreviewContext } from './preview-context';

export const Preview: FunctionComponent<HTMLMotionProps<'div'>> = props => {
  const theme = useTheme();
  const {
    shouldOpenPreviewInDialog,
    previewSidebarAnchor,
    previewOpen,
    previewType,
  } = useContext(PreviewContext);
  const { closeDocumentPreview } = useContext(DocumentPreviewContext);
  const { closeSingleActionPreview } = useContext(SingleActionPreviewContext);

  const closePreview =
    previewType === 'document'
      ? closeDocumentPreview
      : closeSingleActionPreview;

  const dialogFullScreen = useMediaQuery(
    `(max-width: ${theme.sidebar.minWidth + 64}px)`,
  );

  if (shouldOpenPreviewInDialog) {
    return (
      <ClassNames>
        {({ css }) => (
          <Dialog
            classes={{
              paper: css`
                background-color: ${theme.palette.grey[100]};
                height: 100%;
              `,
            }}
            fullWidth
            maxWidth={false}
            fullScreen={dialogFullScreen}
            open={previewOpen}
            onClose={(ev, reason) => closePreview(reason)}
            container={document.getElementById(theme.modalContainer.id)}
          >
            {previewType === 'document' && <DocumentPreview />}
            {previewType === 'singleAction' && <SingleActionPreview />}
            <ToastContainer
              position="bottom-left"
              classes={{
                root: css`
                  position: absolute;
                `,
              }}
            />
          </Dialog>
        )}
      </ClassNames>
    );
  }

  return (
    <motion.div
      css={[
        css`
          overflow: hidden;
          max-width: calc(100% - ${theme.messenger.width}px);
          height: 100%;
        `,
        previewSidebarAnchor === 'left' &&
          css`
            border-right: 1px solid ${theme.palette.divider};
            margin-right: ${theme.messenger.width}px;
          `,
        previewSidebarAnchor === 'right' &&
          css`
            border-left: 1px solid ${theme.palette.divider};
            margin-left: ${theme.messenger.width}px;
          `,
        previewOpen &&
          css`
            overflow: auto;
          `,
      ]}
      variants={{
        open: {
          width: 'auto',
          opacity: 1,
        },
        closed: {
          width: 0,
          opacity: 0,
        },
      }}
      animate={previewOpen ? 'open' : 'closed'}
      transition={{ duration: theme.transitions.duration.shorter / 1000 }}
      {...props}
    >
      <div
        css={css`
          position: relative;
          height: 100%;
          overflow: auto;
          -webkit-overflow-scrolling: touch;
          background-color: ${theme.palette.grey[100]};
        `}
        style={{
          minWidth: theme.sidebar.minWidth,
        }}
      >
        {previewType === 'document' && <DocumentPreview />}
        {previewType === 'singleAction' && <SingleActionPreview />}
      </div>
    </motion.div>
  );
};
