import { Global, css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { carouselClasses } from './carousel-classes';

export const CarouselStyles: FunctionComponent = () => {
  return (
    <Global
      styles={[
        // slick-carousel/slick/slick.css
        css`
          .${carouselClasses.list} {
            position: relative;
            overflow: hidden;
            display: block;
            margin: 0;
            padding: 0;

            &:focus {
              outline: none;
            }
          }

          .${carouselClasses.track} {
            position: relative;
            left: 0;
            top: 0;
            display: flex;
            align-items: stretch;
            flex-direction: row;
            margin-left: auto;
            margin-right: auto;

            [dir='rtl'] & {
              flex-direction: row-reverse;
            }

            &:before,
            &:after {
              content: '';
              display: table;
            }

            &:after {
              clear: both;
            }
          }

          .${carouselClasses.slide} {
            min-height: 1px;

            img {
              display: block;
            }

            display: none;

            .${carouselClasses.initialized} & {
              display: block;
            }

            .${carouselClasses.vertical} & {
              display: block;
              height: auto;
              border: 1px solid transparent;
            }
          }
        `,
      ]}
    />
  );
};
