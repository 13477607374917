import { css, keyframes } from '@emotion/react';

const wave = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }

`;

export const bubbleDefaultStyles = css`
  ul:has(.alli-chat-content-row-root) {
    padding: 0 16px;
  }

  .alli-chat-content-row-root {
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .alli-chat-content-row-alignRight {
    padding: 0 0 0 40px;
  }
  .alli-carousel-root {
    margin: 8px 0 16px -10px;
    width: calc(100% + 10px);
    > .slick-list {
      padding-left: 16px;
    }
  }
  .alli-chat-content-row-content {
    width: 100%;
    max-width: unset;

    @media (min-width: 380px) {
      max-width: unset;

      .alli-chat-content-row-alignLeft & {
        max-width: calc(100% - 16px);
      }
    }
  }
  .alli-chat-content-row-horizontalMargin {
    display: none;
  }
  .alli-chat-content-bubble-root {
    background-color: transparent;
    padding: 8px 16px;
  }
`;

export const bubbleLoadingStyles = css`
  .alli-chat-content-row-root[data-testid='chat-skeleton'] {
    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 56px;
      display: block;
      width: calc(100% - 88px);
      height: 12px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.09);
      animation: ${wave} 2s ease-in-out 0.5s infinite;
    }
    &.alli-chat-content-row-alignRight::after {
      content: '';
      position: absolute;
      top: 0;
      left: 16px;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.09);
      animation: ${wave} 2s ease-in-out 0.5s infinite;
    }
    .alli-chat-content-bubble-root {
      padding-top: 20px;
    }
    .alli-skeleton-text {
      width: 100% !important;
      &:nth-of-type(2) {
        width: 50% !important;
      }
    }
  }
`;

export const bubbleStyles = css`
  li.alli-chat-UserChat {
    background-color: #f5f5f5;
    border-radius: 8px;
    margin: 8px 0;
    .alli-chat-content-row-alignRight {
      &::after {
        content: url('data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0%2010C0%204.47715%204.47715%200%2010%200C15.5228%200%2020%204.47715%2020%2010C20%2015.5228%2015.5228%2020%2010%2020C4.47715%2020%200%2015.5228%200%2010Z%22%20fill%3D%22%23B3B3B3%22%2F%3E%3Cpath%20d%3D%22M0.5%2010C0.5%204.7533%204.7533%200.5%2010%200.5C15.2467%200.5%2019.5%204.7533%2019.5%2010C19.5%2015.2467%2015.2467%2019.5%2010%2019.5C4.7533%2019.5%200.5%2015.2467%200.5%2010Z%22%20stroke%3D%22black%22%20stroke-opacity%3D%220.2%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M10%2012.2222C11.2273%2012.2222%2012.2222%2011.2273%2012.2222%209.99999C12.2222%208.77269%2011.2273%207.77777%2010%207.77777C8.77271%207.77777%207.77779%208.77269%207.77779%209.99999C7.77779%2011.2273%208.77271%2012.2222%2010%2012.2222ZM10%2013.3333C6.93176%2013.3333%204.44446%2015.8206%204.44446%2018.8889V20H15.5556V18.8889C15.5556%2015.8206%2013.0683%2013.3333%2010%2013.3333Z%22%20fill%3D%22%23F5F5F5%22%2F%3E%3C%2Fsvg%3E');
        position: absolute;
        top: 8px;
        left: 16px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .alli-chat-content-row-alignRight {
    .alli-list-root {
      padding-left: 11px;
      margin-top: -20px;
      &,
      & > li,
      & > .alli-chat-option-info-list-item-root {
        align-self: flex-start;
      }

      .alli-list-root {
        padding-left: 0;
        margin-top: 0;
        &,
        & > li,
        & > .alli-chat-option-info-list-item-root {
          align-self: initial;
        }
      }
    }
  }

  .alli-chat-content-row-root:not([data-testid='chat-skeleton']) {
    .alli-chat-content-bubble-root {
      padding: 8px 11px;
      color: rgba(0, 0, 0, 0.87);
      word-break: break-word;
    }
    .alli-chat-content-row-avatar {
      padding-top: 8px;
    }
    .alli-chat-form-root {
      margin-left: 16px;
    }
    .alli-markdown-root {
      *:first-of-type {
        margin-top: 0;
      }
      *:last-of-type {
        margin-bottom: 0;
      }
    }
    .alli-assistant-fallback-action-candidates-root {
      padding: 16px 11px;
    }
    .alli-assistant-fallback-action-candidates-message {
      text-align: left;
    }
    .alli-assistant-fallback-action-candidates-list {
      justify-content: flex-start;
    }
    .alli-assistant-document-fallback-root {
      padding: 16px 0 16px 11px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin: 0;

      .alli-accordion-summary-content {
        justify-content: start;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .alli-draft-document-list-root {
      margin-top: 16px;
    }
  }
`;
