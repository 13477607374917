import { IcVideoOff } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { DraftVideoClasses, draftVideoClasses } from './draft-video-classes';

export interface DraftVideoBaseProps
  extends React.HTMLAttributes<HTMLDivElement> {
  classes?: Partial<DraftVideoClasses>;
  src?: string | null;
  title?: string;
}

export const DraftVideoBase = forwardRef<HTMLDivElement, DraftVideoBaseProps>(
  (props, ref) => {
    const { classes, src, title, ...other } = props;
    const theme = useTheme();

    return (
      <div
        data-testid="draft-video"
        css={css`
          position: relative;
          width: 100%;
          min-width: 232px;
          max-width: 480px;
          border: 1px solid ${theme.palette.divider};
          border-radius: ${theme.radius.xs}px;
          overflow: hidden;
          background-color: ${theme.palette.grey[200]};

          &::before {
            content: '';
            display: block;
            /* 16:9 aspect ratio */
            padding-bottom: 56.25%;
          }
        `}
        {...other}
        ref={ref}
        className={clsx(draftVideoClasses.root, classes?.root, other.className)}
      >
        {src ? (
          <iframe
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: ${theme.palette.common.white};
            `}
            className={clsx(draftVideoClasses.iframe, classes?.iframe)}
            src={src}
            title={title || src}
            frameBorder="0"
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          />
        ) : (
          <span
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              user-select: none;
              color: ${theme.palette.text.secondary};
              font-size: 36px;
            `}
            className={clsx(
              draftVideoClasses.messageNotFound,
              classes?.messageNotFound,
            )}
          >
            <IcVideoOff color="inherit" fontSize="inherit" />
          </span>
        )}
      </div>
    );
  },
);
