import { useCombinedRef } from '@allganize/hooks';
import clsx from 'clsx';
import { forwardRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { iframeClasses } from './iframe-classes';
import { IframeContext } from './iframe-context';
import { IframeProps } from './iframe-type-map';

export const Iframe = forwardRef<HTMLIFrameElement, IframeProps>(
  (props, ref) => {
    const { children, classes, head, portalKey, ...other } = props;
    const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);
    const combinedRef = useCombinedRef(setIframeRef, ref);
    const contentWindow = iframeRef?.contentWindow as Window &
      typeof globalThis;
    const contentDocument = contentWindow?.document;
    const iframeHead = contentDocument?.head;
    const iframeBody = contentDocument?.body;

    return (
      <IframeContext.Provider
        value={{
          document: contentDocument ?? document,
          window: contentWindow ?? window,
        }}
      >
        <iframe
          data-testid="iframe"
          allowFullScreen
          title={other.title}
          {...other}
          ref={combinedRef}
          className={clsx(iframeClasses.root, classes?.root, other.className)}
        >
          {iframeHead &&
            createPortal(
              head,
              iframeHead,
              portalKey ? `${portalKey}-head` : undefined,
            )}

          {iframeHead &&
            iframeBody &&
            createPortal(
              children,
              iframeBody,
              portalKey ? `${portalKey}-body` : undefined,
            )}
        </iframe>
      </IframeContext.Provider>
    );
  },
);
