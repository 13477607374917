/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { ErrorFragment } from '../fragments/error-fragment';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SingleActionAppResultDownloadPublicSubscriptionVariables =
  UserQueryTypes.Exact<{
    where: UserQueryTypes.ProjectWhereUniqueInput;
    accessToken: UserQueryTypes.Scalars['String']['input'];
    token: UserQueryTypes.Scalars['String']['input'];
  }>;

export type SingleActionAppResultDownloadPublicSubscription = {
  __typename: 'UserSubscription';
} & {
  singleActionAppResultDownloadPublic: UserQueryTypes.Maybe<
    { __typename: 'DownloadFileTask' } & Pick<
      UserQueryTypes.DownloadFileTask,
      'id' | 'status' | 'fileName'
    > & {
        errors: UserQueryTypes.Maybe<
          Array<UserQueryTypes.Maybe<{ __typename: 'Error' } & ErrorFragment>>
        >;
      }
  >;
};

export const SingleActionAppResultDownloadPublicSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: {
        kind: 'Name',
        value: 'SingleActionAppResultDownloadPublicSubscription',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectWhereUniqueInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accessToken' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'singleActionAppResultDownloadPublic',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectWhere' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accessToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accessToken' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ErrorFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ErrorFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'field' } },
          { kind: 'Field', name: { kind: 'Name', value: 'info' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SingleActionAppResultDownloadPublicSubscription,
  SingleActionAppResultDownloadPublicSubscriptionVariables
>;
