import { EditorState, SelectionState } from '@allganize/draft-input';

export const getSelectionForInlineStyle = (editorState: EditorState) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const entitiesRanges: Array<{
    blockKey: string;
    start: number;
    end: number;
  }> = [];

  contentState.getBlocksAsArray().forEach(block => {
    const blockKey = block.getKey();

    block.findEntityRanges(
      character => {
        const entityKey = character.getEntity();

        if (entityKey) {
          return !!contentState.getEntity(entityKey);
        }

        return false;
      },
      (start, end) => {
        if (selection.hasEdgeWithin(blockKey, start, end)) {
          entitiesRanges.push({
            blockKey,
            start,
            end,
          });
        }
      },
    );
  });

  let newSelection: SelectionState = selection;
  const isBackward = newSelection.getIsBackward();
  const anchorKey = newSelection.getAnchorKey();
  const focusKey = newSelection.getFocusKey();
  const anchorOffset = newSelection.getAnchorOffset();
  const focusOffset = newSelection.getFocusOffset();

  const anchorEntity = entitiesRanges.find(er => er.blockKey === anchorKey);
  const focusEntity = entitiesRanges.find(er => er.blockKey === focusKey);

  if (anchorEntity) {
    if (isBackward) {
      newSelection = newSelection.set(
        'anchorOffset',
        Math.max(anchorEntity.end, anchorOffset),
      ) as SelectionState;
    } else {
      newSelection = newSelection.set(
        'anchorOffset',
        Math.min(anchorEntity.start, anchorOffset),
      ) as SelectionState;
    }
  }

  if (focusEntity) {
    if (isBackward) {
      newSelection = newSelection.set(
        'focusOffset',
        Math.min(focusEntity.start, focusOffset),
      ) as SelectionState;
    } else {
      newSelection = newSelection.set(
        'focusOffset',
        Math.max(focusEntity.end, focusOffset),
      ) as SelectionState;
    }
  }

  return newSelection;
};
