import { Types } from '@allganize/alli-sdk-interfaces';
import {
  UseRefCallback,
  useEventCallback,
  useRefs,
  useUnmount,
} from '@allganize/hooks';
import { IframeContext } from '@allganize/react-iframe';
import { MakeNonNullable } from '@allganize/types';
import { raf } from '@allganize/utils-timeout';
import { ApolloQueryResult, NetworkStatus } from '@apollo/client/core';
import {
  BaseSubscriptionOptions,
  useQuery,
  useSubscription,
} from '@apollo/client/react';
import { compact, groupBy, isEqual, omit, sortBy } from 'lodash-es';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { IntlShape, useIntl } from 'react-intl';
import { ConversationContext } from '../conversation-detail/conversation-context';
import { ChatFragment } from '../graphql/fragments/chat-fragment';
import { PageInfoFragment } from '../graphql/fragments/page-info-fragment';
import {
  ChatsQuery,
  ChatsQueryDocument,
  ChatsQueryVariables,
} from '../graphql/queries/chats-query';
import {
  ChatsSubscription,
  ChatsSubscriptionDocument,
  ChatsSubscriptionVariables,
} from '../graphql/subscriptions/chats-subscription';
import { useChatQueue } from './use-chat-queue';
import { useShouldResubscribe } from './use-should-resubscribe';

type ChatEdge = NonNullable<
  NonNullable<NonNullable<ChatsQuery['chats']>['edges']>[0]
>;
type NonNullableChatEdge = MakeNonNullable<ChatEdge, 'node'>;

export interface ChatEdgeWithRef extends NonNullableChatEdge {
  __ref: UseRefCallback<HTMLLIElement>;
  optimistic?: boolean;
}

interface ChatEdgeGroup {
  label: string;
  timestamp: number;
  edges: ChatEdgeWithRef[];
}

const uniqEdges = (
  edges: NonNullable<NonNullable<ChatsQuery['chats']>['edges']>,
) => {
  return edges.reduce<NonNullable<NonNullable<ChatsQuery['chats']>['edges']>>(
    (acc, curr) => {
      if (!curr?.node) {
        return [...acc, curr];
      }

      const index = acc.findIndex(edge => edge?.node?.id === curr?.node?.id);

      if (index < 0) {
        return [...acc, curr];
      }

      const prev = acc[index];

      if (
        curr.cursor === curr.node.id &&
        prev?.node &&
        prev.cursor !== prev.node.id
      ) {
        return [
          ...acc.slice(0, index),
          { ...curr, cursor: prev.cursor },
          ...acc.slice(index + 1),
        ];
      }

      return [...acc.slice(0, index), curr, ...acc.slice(index + 1)];
    },
    [],
  );
};

const sortEdges = <T extends NonNullableChatEdge>(edges: T[]) =>
  sortBy(edges, edge => edge.node.createdAt);

const edgeReducer = (
  acc: NonNullableChatEdge[],
  edge: Types.Maybe<ChatEdge>,
): NonNullableChatEdge[] => {
  if (!edge?.node) {
    return acc;
  }

  if (edge.node.hidden) {
    return acc;
  }

  return sortEdges([...acc, edge as NonNullableChatEdge]);
};

const groupChatEdges = (
  edges: ChatEdgeWithRef[],
  intl: IntlShape,
): ChatEdgeGroup[] => {
  const groupedObj = groupBy(edges, edge =>
    intl.formatDate(edge.node.createdAt, { dateStyle: 'long' }),
  );

  return sortBy(
    Object.entries(groupedObj).reduce<ChatEdgeGroup[]>(
      (acc, [label, edges]) => {
        const firstEdge = edges[0];

        if (!firstEdge) {
          return acc;
        }

        return [
          ...acc,
          {
            label,
            timestamp: firstEdge.node.createdAt,
            edges: sortEdges(edges),
          },
        ];
      },
      [],
    ),
    group => group.timestamp,
  );
};

const shouldResubscribe = (
  current: BaseSubscriptionOptions<
    ChatsSubscription,
    ChatsSubscriptionVariables
  >,
  next: BaseSubscriptionOptions<ChatsSubscription, ChatsSubscriptionVariables>,
) => {
  return (
    current.client !== next.client ||
    current.fetchPolicy !== next.fetchPolicy ||
    current.skip !== next.skip ||
    !isEqual(omit(current.variables, 'after'), omit(next.variables, 'after')) ||
    // resubscribe only if current variables has after and next variables doesn't
    ((current.variables?.after === null ||
      typeof current.variables?.after === 'undefined') &&
      next.variables?.after !== null &&
      typeof next.variables?.after !== 'undefined')
  );
};

export interface UseChatListOptions {
  conversationId: Types.Scalars['ID']['input'];
  perPage?: number;
  skipSubscription?: boolean;
}

export type PaginationStatus = 'success' | 'loading' | 'error';

export interface UseChatList {
  appendChatEdges(
    edge: NonNullable<NonNullable<ChatsQuery['chats']>['edges']>,
  ): void;
  data?: ChatsQuery;
  edges: ChatEdgeWithRef[];
  edgeGroups: ChatEdgeGroup[];
  loading: boolean;
  loadNextPageStatus: PaginationStatus;
  loadPreviousPageStatus: PaginationStatus;
  loadNextPage(): Promise<ApolloQueryResult<ChatsQuery>>;
  loadPreviousPage(): Promise<ApolloQueryResult<ChatsQuery>>;
  optimisticChats: ChatFragment[];
  pageInfo?: Types.Maybe<PageInfoFragment>;
  refetch(
    variables?: Partial<ChatsQueryVariables>,
  ): Promise<ApolloQueryResult<ChatsQuery>>;
  scrollBottomRef?: React.RefObject<HTMLLIElement>;
  scrollToBottom(options?: ScrollIntoViewOptions): void;
  scrollToEdge(
    cursor: string | ChatEdgeWithRef,
    options?: ScrollIntoViewOptions,
  ): Promise<void>;
  setOptimisticChats: React.Dispatch<React.SetStateAction<ChatFragment[]>>;
  hasSubscribed: boolean;
}

export const useChatList = ({
  conversationId,
  perPage = 20,
  skipSubscription,
}: UseChatListOptions): UseChatList => {
  const intl = useIntl();
  const { window: contentWindow } = useContext(IframeContext);
  const conversation = useContext(ConversationContext);
  const scrollBottomRef = useRef<HTMLLIElement>(null);
  const result = useQuery(ChatsQueryDocument, {
    variables: {
      where: {
        conversationWhere: {
          id: conversationId,
        },
      },
      last: perPage,
    },
    fetchPolicy: 'cache-and-network',
  });
  const {
    data,
    fetchMore,
    loading,
    networkStatus,
    previousData,
    refetch,
    updateQuery,
  } = result;
  const [loadNextPageStatus, setLoadingNextPage] =
    useState<PaginationStatus>('success');
  const [loadPreviousPageStatus, setLoadingPreviousPage] =
    useState<PaginationStatus>('success');
  const { getRefByKey } = useRefs<string, HTMLLIElement>();
  const [optimisticChats, setOptimisticChats] = useState<ChatFragment[]>([]);

  // const optimisticEdges = useMemo(
  //   () =>
  //     optimisticChats.map<NonNullableChatEdge>(chat => {
  //       const cursor = chat.id.toString();

  //       return {
  //         __typename: 'ChatEdge',
  //         cursor,
  //         node: chat,
  //         optimistic: true,
  //       };
  //     }),
  //   [optimisticChats],
  // );
  const edges = useMemo(
    () =>
      [
        ...(data?.chats?.edges ?? []).reduce(edgeReducer, []),
        // ...optimisticEdges, // TODO: [ALL-13864] optimistic update for a user chat
      ].map(edge => ({
        ...edge,
        __ref: getRefByKey(edge.cursor),
      })),
    [data?.chats?.edges, getRefByKey],
  );
  const edgeGroups = useMemo(() => groupChatEdges(edges, intl), [edges, intl]);
  const pageInfo = data?.chats?.pageInfo;
  const endCursor = pageInfo?.endCursor ?? undefined;
  const startCursor = pageInfo?.startCursor ?? undefined;

  const [hasSubscribed, setHasSubscribed] = useState(false);
  const dataUndefined = typeof data === 'undefined';
  const previousDataUndefined = typeof previousData === 'undefined';

  const handleQueuePop = async (edges: ChatEdge[]) => {
    if (edges.length === 0) {
      return;
    }

    const prevEdges = data?.chats?.edges;

    const firstNewEdge =
      prevEdges &&
      edges.find(edge => {
        if (!edge.node) {
          return false;
        }

        if (edge.node.hidden) {
          return false;
        }

        const edgeIndex = prevEdges.findIndex(
          prevEdge => prevEdge?.node?.id === edge.node?.id,
        );

        return edgeIndex < 0;
      });

    updateQuery(prev => {
      if (!prev.chats?.edges) {
        return prev;
      }

      const uniqueEdges = uniqEdges([...prev.chats.edges, ...edges]);
      const lastEdge = uniqueEdges[uniqueEdges.length - 1];

      return {
        ...prev,
        chats: {
          ...prev.chats,
          edges: uniqueEdges,
          pageInfo: lastEdge
            ? {
                ...prev.chats.pageInfo,
                endCursor: lastEdge.cursor,
              }
            : prev.chats.pageInfo,
        },
      };
    });

    if (!firstNewEdge) {
      return;
    }

    await raf(5);
    scrollToEdge(firstNewEdge.cursor, {
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  };

  const chatQueue = useChatQueue<ChatEdge>({ onPop: handleQueuePop });

  useSubscription(ChatsSubscriptionDocument, {
    skip: !data || networkStatus === NetworkStatus.loading || skipSubscription,
    variables: {
      where: {
        conversationWhere: {
          id: conversationId,
        },
      },
      after: startCursor,
    },
    shouldResubscribe: useShouldResubscribe(shouldResubscribe),
    async onData({ data: subscriptionData }) {
      const newEdge = subscriptionData.data?.chats;
      const newNode = newEdge?.node;

      if (!newNode) {
        return;
      }

      const prevEdges = data?.chats?.edges;

      if (!prevEdges) {
        return;
      }

      const newEdgeIndex = prevEdges.findIndex(
        edge => edge?.node?.id === newNode.id,
      );

      if (newEdgeIndex < 0) {
        setHasSubscribed(true);
        chatQueue.push(newEdge);
        return;
      }

      const isLastEdge = newEdgeIndex === prevEdges.length - 1;

      if (!isLastEdge) {
        return;
      }

      const lastEdgeContainsChange = !isEqual(
        omit(newNode, ['messageContentState']),
        omit(prevEdges[newEdgeIndex]?.node, ['messageContentState']),
      );

      if (!lastEdgeContainsChange) {
        return;
      }

      await raf(2);
      scrollToBottom();
    },
  });

  const loadNextPage = async () => {
    setLoadingNextPage('loading');

    try {
      const result = await fetchMore({
        variables: {
          where: {
            conversationWhere: { id: conversationId },
          },
          first: perPage,
          after: endCursor,
        },
        updateQuery(prev, { fetchMoreResult }) {
          if (!fetchMoreResult.chats?.edges) {
            return prev;
          }

          return {
            ...prev,
            chats: {
              __typename: 'ChatConnection',
              ...prev.chats,
              edges: uniqEdges([
                ...(prev.chats?.edges ?? []),
                ...fetchMoreResult.chats.edges,
              ]),
              pageInfo: prev.chats?.pageInfo
                ? {
                    ...prev.chats?.pageInfo,
                    hasNextPage: fetchMoreResult.chats.pageInfo.hasNextPage,
                    endCursor: fetchMoreResult.chats.pageInfo.endCursor,
                  }
                : fetchMoreResult.chats.pageInfo,
            },
          };
        },
      });

      setLoadingNextPage('success');
      return result;
    } catch (err) {
      setLoadingNextPage('error');
      throw err;
    }
  };

  const loadPreviousPage = async () => {
    const scrollBeforeLoading = {
      scrollY: contentWindow.scrollY,
      scrollHeight: contentWindow.document.documentElement.scrollHeight,
      offsetHeight: contentWindow.document.documentElement.offsetHeight,
      clientHeight: contentWindow.document.documentElement.clientHeight,
    };

    flushSync(() => {
      setLoadingPreviousPage('loading');
    });

    const scrollDuringLoading = {
      scrollY: contentWindow.scrollY,
      scrollHeight: contentWindow.document.documentElement.scrollHeight,
      offsetHeight: contentWindow.document.documentElement.offsetHeight,
      clientHeight: contentWindow.document.documentElement.clientHeight,
    };

    const scrollYDuringLoading =
      scrollBeforeLoading.scrollY +
      (scrollDuringLoading.scrollHeight - scrollBeforeLoading.scrollHeight);

    contentWindow.scrollTo(0, Math.max(0, scrollYDuringLoading));

    try {
      const fetchMoreResult = await fetchMore({
        variables: {
          where: {
            conversationWhere: { id: conversationId },
          },
          last: perPage,
          before: startCursor,
        },
      });

      const scrollBeforeSuccess = {
        scrollY: contentWindow.scrollY,
        scrollHeight: contentWindow.document.documentElement.scrollHeight,
        offsetHeight: contentWindow.document.documentElement.offsetHeight,
        clientHeight: contentWindow.document.documentElement.clientHeight,
      };

      updateQuery(prev => {
        if (!fetchMoreResult.data.chats?.edges) {
          return prev;
        }

        return {
          ...prev,
          chats: {
            __typename: 'ChatConnection',
            ...prev.chats,
            edges: uniqEdges([
              ...fetchMoreResult.data.chats.edges,
              ...(prev.chats?.edges ?? []),
            ]),
            pageInfo: prev.chats?.pageInfo
              ? {
                  ...prev.chats?.pageInfo,
                  hasPreviousPage:
                    fetchMoreResult.data.chats.pageInfo.hasPreviousPage,
                  startCursor: fetchMoreResult.data.chats.pageInfo.startCursor,
                }
              : fetchMoreResult.data.chats.pageInfo,
          },
        };
      });

      await raf();

      flushSync(() => {
        setLoadingPreviousPage('success');
      });

      const scrollAfterSuccess = {
        scrollY: contentWindow.scrollY,
        scrollHeight: contentWindow.document.documentElement.scrollHeight,
        offsetHeight: contentWindow.document.documentElement.offsetHeight,
        clientHeight: contentWindow.document.documentElement.clientHeight,
      };

      const scrollYAfterSuccess =
        scrollBeforeSuccess.scrollY +
        (scrollAfterSuccess.scrollHeight - scrollBeforeSuccess.scrollHeight);

      contentWindow.scrollTo(0, Math.max(0, scrollYAfterSuccess));

      return fetchMoreResult;
    } catch (err) {
      setLoadingPreviousPage('error');
      throw err;
    }
  };

  const getEdges = useEventCallback(() => edges);

  const scrollToBottom = useEventCallback(
    async (options?: ScrollIntoViewOptions) => {
      if (!scrollBottomRef.current) {
        return;
      }

      await raf();
      scrollBottomRef.current.scrollIntoView({
        block: 'end',
        inline: 'end',
        ...options,
      });
    },
  );

  const scrollToEdge = useEventCallback(
    async (
      cursorOrEdge: string | ChatEdgeWithRef,
      options?: ScrollIntoViewOptions,
    ) => {
      let edgeWithRef: ChatEdgeWithRef | undefined;

      if (typeof cursorOrEdge === 'string') {
        edgeWithRef = getEdges().find(edge => edge.cursor === cursorOrEdge);
      } else {
        edgeWithRef = cursorOrEdge;
      }

      if (edgeWithRef) {
        if (!edgeWithRef.__ref.current) {
          throw new Error('Edge ref is not available');
        }

        await raf();
        edgeWithRef.__ref.current.scrollIntoView(options);

        return;
      }

      if (typeof cursorOrEdge !== 'string') {
        return;
      }

      const fetchMoreUpdateQuery = (
        prev: ChatsQuery,
        {
          fetchMoreResult,
        }: { fetchMoreResult: ChatsQuery; variables: ChatsQueryVariables },
      ): ChatsQuery => {
        if (!fetchMoreResult.chats?.edges) {
          return prev;
        }

        return {
          ...prev,
          chats: {
            __typename: 'ChatConnection',
            ...prev.chats,
            edges: uniqEdges([
              ...fetchMoreResult.chats.edges,
              ...(prev.chats?.edges ?? []),
            ]),
            pageInfo: prev.chats?.pageInfo
              ? {
                  ...prev.chats?.pageInfo,
                  hasPreviousPage:
                    fetchMoreResult.chats.pageInfo.hasPreviousPage,
                  startCursor: fetchMoreResult.chats.pageInfo.startCursor,
                }
              : fetchMoreResult.chats.pageInfo,
          },
        };
      };

      // fetch pages in between
      const result = await fetchMore({
        variables: {
          where: { conversationWhere: { id: conversationId } },
          before: startCursor,
          after: cursorOrEdge,
          last: null,
        },
        updateQuery: fetchMoreUpdateQuery,
      });

      await fetchMore({
        variables: {
          where: { conversationWhere: { id: conversationId } },
          before: result.data.chats?.pageInfo.startCursor,
          last: 1,
        },
        updateQuery: fetchMoreUpdateQuery,
      });

      await raf();
      scrollToEdge(cursorOrEdge, options);
    },
  );

  const scrollToUserLastReadChat = useEventCallback(
    async (onFail?: () => void) => {
      const userLastReadChatId =
        conversation.data?.conversation?.readInfo?.userLastReadChatId ?? null;

      if (userLastReadChatId === null) {
        const firstEdge = edges[0];

        if (!firstEdge) {
          onFail?.();
          return;
        }

        try {
          await raf(5);
          scrollToEdge(firstEdge.cursor, {
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          });
        } catch {
          onFail?.();
        }

        return;
      }

      const userLastReadChatEdge = getEdges().find(
        edge => edge?.node?.id === userLastReadChatId,
      );

      if (!userLastReadChatEdge) {
        onFail?.();
        return;
      }

      try {
        await raf(5);
        scrollToEdge(userLastReadChatEdge.cursor, {
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      } catch {
        onFail?.();
      }
    },
  );

  const appendChatEdges = (
    edges: NonNullable<NonNullable<ChatsQuery['chats']>['edges']>,
  ) => {
    const compactEdges = compact(edges).filter(edge => {
      if (!edge.node) {
        return false;
      }

      return (
        (data?.chats?.edges ?? []).findIndex(
          e => e?.node?.id === edge.node?.id,
        ) >= 0
      );
    });

    if (compactEdges.length === 0) {
      return;
    }

    chatQueue.push(...compactEdges);
  };

  useEffect(() => {
    if (previousDataUndefined && !dataUndefined) {
      scrollToUserLastReadChat(scrollToBottom);
    }
  }, [
    previousDataUndefined,
    dataUndefined,
    scrollToUserLastReadChat,
    scrollToBottom,
  ]);

  useUnmount(() => {
    chatQueue.flush();
  });

  return {
    appendChatEdges,
    data,
    edges,
    edgeGroups,
    loading,
    loadNextPageStatus,
    loadPreviousPageStatus,
    loadNextPage,
    loadPreviousPage,
    optimisticChats,
    pageInfo,
    refetch,
    scrollBottomRef,
    scrollToBottom,
    scrollToEdge,
    setOptimisticChats,
    hasSubscribed,
  };
};
