import { ProjectProvider } from '@allganize/alli-app-market-project';
import { ToastContainer } from '@allganize/ui-toast';
import { ErrorBoundary } from '@sentry/react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { CurrentUserProvider } from '../auth/current-user-provider';
import {
  ErrorScreen,
  LinkExpiredErrorScreen,
} from '../components/state-screen/error-screen';
import { LoadingScreen } from '../components/state-screen/loading-screen';
import { OktaSecurity } from '../services/okta/okta-security';
import { GlobalStyles } from '../styles/global-styles';
import { Meta } from './meta';

export const App = () => {
  return (
    <OktaSecurity>
      <Meta />
      <GlobalStyles />
      <ToastContainer />
      <ErrorBoundary fallback={<ErrorScreen />}>
        <Suspense fallback={<LoadingScreen />}>
          <ErrorBoundary fallback={<LinkExpiredErrorScreen />}>
            <ProjectProvider fetchPolicy="network-only">
              <ErrorBoundary fallback={<ErrorScreen />}>
                <CurrentUserProvider>
                  <Outlet />
                </CurrentUserProvider>
              </ErrorBoundary>
            </ProjectProvider>
          </ErrorBoundary>
        </Suspense>
      </ErrorBoundary>
    </OktaSecurity>
  );
};
