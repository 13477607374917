import invariant from 'tiny-invariant';
import { AlliAuthClient } from './alli-auth-client';
import { AlliAuthClientOptions } from './alli-auth-client-options';

export interface AlliKeyAuthClientOptions extends AlliAuthClientOptions {
  apiKey: string;
  campaignToken?: string;
  chatToken?: string;
}

export class AlliKeyAuthClient extends AlliAuthClient {
  private readonly apiKey: string;
  private readonly campaignToken: string | null = null;
  public readonly chatToken: string | null = null;

  constructor({
    apiKey,
    campaignToken,
    chatToken,
    endpoints,
    sdkType,
    deviceIdManager,
    persistedUserIdManager,
    userManager,
  }: AlliKeyAuthClientOptions) {
    super({
      endpoints,
      sdkType,
      deviceIdManager,
      persistedUserIdManager,
      userManager,
    });
    invariant(apiKey, 'apiKey is required');
    this.apiKey = apiKey;

    if (campaignToken) {
      this.campaignToken = campaignToken;
    }

    if (chatToken) {
      this.chatToken = chatToken;
    }
  }

  public async getGraphQLHeaders(): Promise<Record<string, string>> {
    const base = await super.getGraphQLHeaders();

    const result: Record<string, string> = {
      ...base,
      'api-key': this.apiKey,
    };

    if (this.campaignToken) {
      result['campaign-token'] = this.campaignToken;
    }

    if (this.chatToken) {
      result['chat-token'] = this.chatToken;
    }

    return result;
  }
}
