import { createSvgIcon } from '@allganize/ui-icons';

export const IcFileHWP = createSvgIcon(
  <>
    <path
      d="M6 22.5469H20.3077C21.1361 22.5469 21.8077 21.8753 21.8077 21.0469V7.69265C21.8077 7.29885 21.6528 6.92085 21.3765 6.64024L16.7099 1.90072C16.428 1.61437 16.0429 1.45312 15.6411 1.45312H6C5.17157 1.45312 4.5 2.1247 4.5 2.95312V21.0469C4.5 21.8753 5.17157 22.5469 6 22.5469Z"
      fill="white"
    />
    <path
      d="M5.0625 2.95312C5.0625 2.43536 5.48223 2.01562 6 2.01562H15.6411C15.6631 2.01562 15.685 2.0164 15.7068 2.01793V5.57812C15.7068 6.71721 16.6302 7.64062 17.7693 7.64062H21.2437C21.2447 7.6579 21.2452 7.67525 21.2452 7.69265V21.0469C21.2452 21.5646 20.8255 21.9844 20.3077 21.9844H6C5.48223 21.9844 5.0625 21.5646 5.0625 21.0469V2.95312ZM20.4644 6.51562L16.8318 2.82621V5.57812C16.8318 6.09589 17.2515 6.51562 17.7693 6.51562H20.4644ZM6 0.890625C4.86091 0.890625 3.9375 1.81404 3.9375 2.95312V21.0469C3.9375 22.186 4.86092 23.1094 6 23.1094H20.3077C21.4468 23.1094 22.3702 22.186 22.3702 21.0469V7.69265C22.3702 7.15117 22.1573 6.63142 21.7774 6.24559L17.1108 1.50607C16.7231 1.11234 16.1936 0.890625 15.6411 0.890625H6Z"
      fill="black"
      fillOpacity="0.2"
      fillRule="evenodd"
    />
    <path
      d="M0.75 12.75C0.75 11.0931 2.09315 9.75 3.75 9.75H15.75C17.4069 9.75 18.75 11.0931 18.75 12.75V17.25C18.75 18.9069 17.4069 20.25 15.75 20.25H3.75C2.09315 20.25 0.75 18.9069 0.75 17.25V12.75Z"
      fill="#0070E0"
    />
    <path
      d="M2.85571 12.8184V17.182H3.9104V15.4284H5.62986V17.182H6.68242V12.8184H5.62986V14.5698H3.9104V12.8184H2.85571ZM8.14953 17.182L6.87964 12.8184H8.04512L8.7035 15.6777H8.73972L9.49185 12.8184H10.4421L11.1943 15.6841H11.2305L11.891 12.8184H13.0544L11.7866 17.182H10.7703L9.98404 14.5357H9.94995L9.16373 17.182H8.14953ZM13.2502 17.182V12.8184H15.0528C15.3795 12.8184 15.6615 12.8823 15.8987 13.0101C16.1373 13.1365 16.3213 13.3134 16.4505 13.5407C16.5798 13.7665 16.6444 14.0293 16.6444 14.329C16.6444 14.6301 16.5784 14.8936 16.4463 15.1195C16.3156 15.3439 16.1288 15.5179 15.8859 15.6415C15.643 15.7651 15.3546 15.8269 15.0208 15.8269H14.3049V17.182H13.2502ZM14.8248 14.9959H14.3049V13.6685H14.8248C14.9825 13.6685 15.1153 13.6955 15.2233 13.7495C15.3312 13.8034 15.4129 13.8801 15.4683 13.9796C15.5237 14.0776 15.5514 14.1941 15.5514 14.329C15.5514 14.4625 15.5237 14.5797 15.4683 14.6806C15.4129 14.78 15.3312 14.8574 15.2233 14.9128C15.1167 14.9682 14.9839 14.9959 14.8248 14.9959Z"
      fill="white"
      fillRule="evenodd"
    />
  </>,
  'ic-file-hwp',
);
