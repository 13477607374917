import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { GroupBase, NoticeProps } from 'react-select';
import { selectClasses } from './select-classes';

export const NoOptionsMessage = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: NoticeProps<Option, IsMulti, Group>,
) => {
  const { children, className, getClassNames, innerProps } = props;
  const theme = useTheme();

  return (
    <div
      css={css`
        padding: 8px 0;
        color: ${theme.palette.text.secondary};
      `}
      {...innerProps}
      className={clsx(
        selectClasses.noOptionsMessage,
        getClassNames('noOptionsMessage', props),
        className,
        innerProps?.className,
      )}
    >
      <Text variant="body14" align="center">
        {children || 'No options'}
      </Text>
    </div>
  );
};
