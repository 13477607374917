import { Button } from '@allganize/ui-button';
import {
  Dialog,
  DialogActions,
  DialogCloseButton,
  DialogContent,
  DialogTitle,
} from '@allganize/ui-dialog';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

import { knowledgeBaseUploadDialogClasses } from './knowledge-base-upload-dialog-classes';
import { KnowledgeBaseUploadDialogProps } from './knowledge-base-upload-dialog-type-map';
import { KnowledgeBaseUploadList } from './knowledge-base-upload-list';
import { useKnowledgeBaseUpload } from './use-knowledge-base-upload';
import { KnowledgeBaseFileDropzone } from '../knowledge-base-file-dropzone';
import { KnowledgeBaseUploadError } from './knowledge-base-upload-error';

export const KnowledgeBaseUploadDialog = ({
  classes,
  onClose,
  onConfirm,
  ...others
}: KnowledgeBaseUploadDialogProps) => {
  const {
    errors,
    maxKnowledgeBaseErrorMessage,
    knowledgeBases,
    disableConfirm,
    handleConfirm,
    handleDelete,
    handleDropFile,
    handleRetry,
  } = useKnowledgeBaseUpload({ onConfirm });

  return (
    <Dialog
      PaperProps={{
        css: css`
          width: 600px;
        `,
      }}
      {...others}
      className={clsx(
        knowledgeBaseUploadDialogClasses.root,
        classes?.root,
        others.className,
      )}
      onClose={onClose}
    >
      <DialogTitle
        className={clsx(knowledgeBaseUploadDialogClasses.title, classes?.title)}
      >
        <FormattedMessage
          id="knowledge-base.upload-file-dialog.title"
          defaultMessage="Upload from computer"
          description="knowledge base upload file dialog title"
        />
        <DialogCloseButton />
      </DialogTitle>

      <DialogContent
        className={clsx(
          knowledgeBaseUploadDialogClasses.content,
          classes?.content,
        )}
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
        `}
      >
        <KnowledgeBaseFileDropzone
          className={clsx(
            knowledgeBaseUploadDialogClasses.dropzone,
            classes?.dropzone,
          )}
          onDropFile={handleDropFile}
          errorMessage={maxKnowledgeBaseErrorMessage}
        />
        <KnowledgeBaseUploadList
          className={clsx(
            knowledgeBaseUploadDialogClasses.uploadList,
            classes?.uploadList,
          )}
          data={knowledgeBases}
          onDelete={handleDelete}
          onRetry={handleRetry}
        />
        <KnowledgeBaseUploadError
          className={clsx(
            knowledgeBaseUploadDialogClasses.uploadErrorMessage,
            classes?.uploadErrorMessage,
          )}
          data={errors}
        />
      </DialogContent>
      <DialogActions
        className={clsx(
          knowledgeBaseUploadDialogClasses.actions,
          classes?.actions,
        )}
      >
        <Button
          size="large"
          variant="ghost"
          onClick={e => onClose?.(e, 'closeButtonClick')}
        >
          <FormattedMessage
            id="knowledge-base.upload-file-dialog.cancel"
            defaultMessage="Cancel"
            description="knowledge base upload file dialog cancel button text"
          />
        </Button>
        <Button
          size="large"
          variant="filled"
          color="primary"
          disabled={disableConfirm}
          onClick={handleConfirm}
        >
          <FormattedMessage
            id="knowledge-base.upload-file-dialog.upload"
            defaultMessage="Upload"
            description="knowledge base upload file dialog upload button text"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
