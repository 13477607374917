import { OperationVariables } from '@apollo/client/core';
import { BaseSubscriptionOptions } from '@apollo/client/react';
import { useCallback, useRef } from 'react';

export const useShouldResubscribe = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  shouldResubscribeOption?:
    | boolean
    | ((
        current: BaseSubscriptionOptions<TData, TVariables>,
        next: BaseSubscriptionOptions<TData, TVariables>,
      ) => boolean),
) => {
  const prevRef = useRef<BaseSubscriptionOptions<TData, TVariables> | null>(
    null,
  );

  const shouldResubscribe = useCallback(
    (next: BaseSubscriptionOptions<TData, TVariables>) => {
      const prev = prevRef.current;
      prevRef.current = next;

      if (
        typeof shouldResubscribeOption === 'boolean' ||
        typeof shouldResubscribeOption === 'undefined'
      ) {
        return shouldResubscribeOption !== false;
      }

      let result = false;

      if (typeof shouldResubscribeOption === 'function') {
        result = shouldResubscribeOption(prev ?? next, next);
      }

      return result;
    },
    [shouldResubscribeOption],
  );

  return shouldResubscribe;
};
