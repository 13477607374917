import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { DateOrTimeViewWithMeridiem } from '../internals/models';
import { pickersToolbarClasses } from './pickers-toolbar-classes';
import { PickersToolbarProps } from './pickers-toolbar-type-map';

interface PickersToolbarType {
  <TValue, TView extends DateOrTimeViewWithMeridiem>(
    props: React.PropsWithoutRef<
      React.PropsWithChildren<PickersToolbarProps<TValue, TView>>
    > &
      React.RefAttributes<HTMLDivElement>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

export const PickersToolbar: PickersToolbarType = forwardRef((props, ref) => {
  const {
    children,
    classes,
    className,
    isLandscape,
    landscapeDirection = 'column',
    toolbarTitle,
    hidden,
    titleId,
  } = props;
  const theme = useTheme();
  const muiTheme = useMuiTheme();

  if (hidden) {
    return null;
  }

  return (
    <div
      data-testid="pickers-toolbar"
      css={[
        css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          padding: ${theme.spacing(2, 3)};
          background-color: ${theme.palette.primary.main};
          color: ${theme.palette.primary.contrastText};
        `,
        isLandscape &&
          css`
            height: auto;
            width: 136px;
            max-width: 136px;
            padding: 20px 24px;
            justify-content: flex-start;
            flex-wrap: wrap;
          `,
      ]}
      ref={ref}
      className={clsx(pickersToolbarClasses.root, classes?.root, className)}
    >
      <Text variant="title12" id={titleId}>
        {toolbarTitle}
      </Text>

      <ThemeProvider
        theme={{
          ...muiTheme,
          breakpoints: theme.breakpoints,
          spacing: theme.spacing,
        }}
      >
        <div
          css={[
            css`
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              flex: 1;
              margin-top: 4px;
            `,
            isLandscape &&
              css`
                justify-content: flex-start;
                flex-direction: ${landscapeDirection};
                align-items: flex-start;
                margin-top: 16px;
              `,
            !isLandscape &&
              css`
                justify-content: space-between;
                flex-direction: row;
                align-items: center;
              `,
          ]}
          className={clsx(pickersToolbarClasses.content, classes?.content)}
        >
          {children}
        </div>
      </ThemeProvider>
    </div>
  );
});
