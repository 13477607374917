import { css } from '@emotion/react';
import clsx from 'clsx';
import { HTMLAttributes, PropsWithChildren, forwardRef } from 'react';
import { uaParser } from '../utils/ua-parser';
import { recommendationCarouselClasses } from './recommendation-carousel-classes';

export const RecommendationCarouselItemWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<HTMLAttributes<HTMLDivElement>>
>(({ children, ...other }, ref) => {
  return (
    <div
      css={[
        css`
          padding: 0 4px;
        `,
        uaParser.hasCarouselHeightBug &&
          css`
            flex-grow: 1;
            display: inline-flex !important;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
          `,
      ]}
      {...other}
      ref={ref}
      className={clsx(
        recommendationCarouselClasses.itemWrapper,
        other.className,
      )}
    >
      {children}
    </div>
  );
});
