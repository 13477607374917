import { useEventCallback } from '@allganize/hooks';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { useControlled } from '@mui/material/utils';
import clsx from 'clsx';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { DIALOG_WIDTH } from '../internals/constants/dimensions';
import {
  useDefaultDates,
  useNow,
  useUtils,
} from '../internals/hooks/use-utils';
import { useControlledValueWithTimezone } from '../internals/hooks/use-value-with-timezone';
import {
  applyDefaultDate,
  getMonthsInYear,
} from '../internals/utils/date-utils';
import { SECTION_TYPE_GRANULARITY } from '../internals/utils/get-default-reference-date';
import { singleItemValueManager } from '../internals/utils/value-managers';
import { PickersMonth } from '../pickers-month';
import { monthCalendarClasses } from './month-calendar-classes';
import { MonthCalendarProps } from './month-calendar-type-map';

export const MonthCalendar = forwardRef<HTMLDivElement, MonthCalendarProps>(
  (props, ref) => {
    const {
      classes,
      value: valueProp,
      defaultValue,
      referenceDate: referenceDateProp,
      disabled,
      disableFuture = false,
      disablePast = false,
      maxDate: maxDateProp,
      minDate: minDateProp,
      onChange,
      shouldDisableMonth,
      readOnly,
      disableHighlightToday,
      autoFocus = false,
      onMonthFocus,
      hasFocus,
      onFocusedViewChange,
      monthsPerRow = 3,
      timezone: timezoneProp,
      gridLabelId,
      ...other
    } = props;
    const utils = useUtils<Date>();
    const defaultDates = useDefaultDates<Date>();
    const minDate = applyDefaultDate(utils, minDateProp, defaultDates.minDate);
    const maxDate = applyDefaultDate(utils, maxDateProp, defaultDates.maxDate);

    const { value, handleValueChange, timezone } =
      useControlledValueWithTimezone({
        name: 'MonthCalendar',
        timezone: timezoneProp,
        value: valueProp,
        defaultValue,
        onChange: onChange as (value: Date | null) => void,
        valueManager: singleItemValueManager,
      });

    const now = useNow<Date>(timezone);
    const theme = useTheme();

    const referenceDate = useMemo(
      () =>
        singleItemValueManager.getInitialReferenceValue({
          value,
          utils,
          props,
          timezone,
          referenceDate: referenceDateProp,
          granularity: SECTION_TYPE_GRANULARITY.month,
        }),
      [], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const todayMonth = useMemo(() => utils.getMonth(now), [utils, now]);

    const selectedMonth = useMemo(() => {
      if (value != null) {
        return utils.getMonth(value);
      }

      if (disableHighlightToday) {
        return null;
      }

      return utils.getMonth(referenceDate);
    }, [value, utils, disableHighlightToday, referenceDate]);
    const [focusedMonth, setFocusedMonth] = useState(
      () => selectedMonth || todayMonth,
    );

    const [internalHasFocus, setInternalHasFocus] = useControlled({
      name: 'MonthCalendar',
      state: 'hasFocus',
      controlled: hasFocus,
      default: autoFocus ?? false,
    });

    const changeHasFocus = useEventCallback((newHasFocus: boolean) => {
      setInternalHasFocus(newHasFocus);

      if (onFocusedViewChange) {
        onFocusedViewChange(newHasFocus);
      }
    });

    const isMonthDisabled = useCallback(
      (dateToValidate: Date) => {
        const firstEnabledMonth = utils.startOfMonth(
          disablePast && utils.isAfter(now, minDate) ? now : minDate,
        );

        const lastEnabledMonth = utils.startOfMonth(
          disableFuture && utils.isBefore(now, maxDate) ? now : maxDate,
        );

        const monthToValidate = utils.startOfMonth(dateToValidate);

        if (utils.isBefore(monthToValidate, firstEnabledMonth)) {
          return true;
        }

        if (utils.isAfter(monthToValidate, lastEnabledMonth)) {
          return true;
        }

        if (!shouldDisableMonth) {
          return false;
        }

        return shouldDisableMonth(monthToValidate);
      },
      [
        disableFuture,
        disablePast,
        maxDate,
        minDate,
        now,
        shouldDisableMonth,
        utils,
      ],
    );

    const handleMonthSelection = useEventCallback(
      (event: React.MouseEvent, month: number) => {
        if (readOnly) {
          return;
        }

        const newDate = utils.setMonth(value ?? referenceDate, month);
        handleValueChange(newDate);
      },
    );

    const focusMonth = useEventCallback((month: number) => {
      if (!isMonthDisabled(utils.setMonth(value ?? referenceDate, month))) {
        setFocusedMonth(month);
        changeHasFocus(true);
        if (onMonthFocus) {
          onMonthFocus(month);
        }
      }
    });

    useEffect(() => {
      setFocusedMonth(prevFocusedMonth =>
        selectedMonth !== null && prevFocusedMonth !== selectedMonth
          ? selectedMonth
          : prevFocusedMonth,
      );
    }, [selectedMonth]);

    const handleKeyDown = useEventCallback(
      (event: React.KeyboardEvent, month: number) => {
        const monthsInYear = 12;
        const monthsInRow = 3;

        switch (event.key) {
          case 'ArrowUp':
            focusMonth((monthsInYear + month - monthsInRow) % monthsInYear);
            event.preventDefault();
            break;
          case 'ArrowDown':
            focusMonth((monthsInYear + month + monthsInRow) % monthsInYear);
            event.preventDefault();
            break;
          case 'ArrowLeft':
            focusMonth(
              (monthsInYear + month + (theme.direction === 'ltr' ? -1 : 1)) %
                monthsInYear,
            );

            event.preventDefault();
            break;
          case 'ArrowRight':
            focusMonth(
              (monthsInYear + month + (theme.direction === 'ltr' ? 1 : -1)) %
                monthsInYear,
            );

            event.preventDefault();
            break;
          default:
            break;
        }
      },
    );

    const handleMonthFocus = useEventCallback(
      (event: React.FocusEvent, month: number) => {
        focusMonth(month);
      },
    );

    const handleMonthBlur = useEventCallback(
      (event: React.FocusEvent, month: number) => {
        if (focusedMonth === month) {
          changeHasFocus(false);
        }
      },
    );

    return (
      <div
        data-testid="month-calendar"
        css={css`
          display: flex;
          flex-wrap: wrap;
          align-content: stretch;
          padding: 0 20px;
          width: ${DIALOG_WIDTH}px;
          box-sizing: border-box;
        `}
        role="radiogroup"
        aria-labelledby={gridLabelId}
        {...other}
        ref={ref}
        className={clsx(
          monthCalendarClasses.root,
          classes?.root,
          other.className,
        )}
      >
        {getMonthsInYear(utils, value ?? referenceDate).map(month => {
          const monthNumber = utils.getMonth(month);
          const monthText = utils.format(month, 'monthShort');
          const monthLabel = utils.format(month, 'month');
          const isSelected = monthNumber === selectedMonth;
          const isDisabled = disabled || isMonthDisabled(month);

          return (
            <PickersMonth
              key={monthText}
              selected={isSelected}
              value={monthNumber}
              onClick={handleMonthSelection}
              onKeyDown={handleKeyDown}
              autoFocus={internalHasFocus && monthNumber === focusedMonth}
              disabled={isDisabled}
              tabIndex={monthNumber === focusedMonth ? 0 : -1}
              onFocus={handleMonthFocus}
              onBlur={handleMonthBlur}
              aria-current={todayMonth === monthNumber ? 'date' : undefined}
              aria-label={monthLabel}
              monthsPerRow={monthsPerRow}
            >
              {monthText}
            </PickersMonth>
          );
        })}
      </div>
    );
  },
);
