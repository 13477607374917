import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type IntermediateOutputClassKey =
  | 'root'
  | 'header'
  | 'title'
  | 'expandButton';

export type IntermediateOutputClasses = Record<
  IntermediateOutputClassKey,
  string
>;

export const intermediateOutputClasses: IntermediateOutputClasses =
  generateUtilityClasses('single-action__intermediate-output', [
    'root',
    'header',
    'title',
    'expandButton',
  ]);
