import {
  formHelperTextClasses as muiFormHelperTextClasses,
  FormHelperTextClassKey as MuiFormHelperTextClassKey,
} from '@mui/material/FormHelperText';
import { omit } from 'lodash-es';

export type FormHelperTextClassKey = Exclude<
  MuiFormHelperTextClassKey,
  'contained' | 'sizeSmall'
>;
export type FormHelperTextClasses = Record<FormHelperTextClassKey, string>;

export const formHelperTextClasses: FormHelperTextClasses = omit(
  muiFormHelperTextClasses,
  ['contained', 'sizeSmall'],
);
