import {
  dialogActionsClasses as muiDialogActionsClasses,
  DialogActionsClassKey as MuiDialogActionsClassKey,
} from '@mui/material/DialogActions';

export type DialogActionsClassKey = MuiDialogActionsClassKey;
export type DialogActionsClasses = Record<DialogActionsClassKey, string>;

export const dialogActionsClasses: DialogActionsClasses =
  muiDialogActionsClasses;
