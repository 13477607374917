import { useCallback, useState } from 'react';

interface UseWindow {
  ref<T extends Element>(node: T | null): void;
  window: (typeof globalThis & Window) | null;
}

export const useWindow = (doNotInit?: boolean): UseWindow => {
  const [win, setWin] = useState<(typeof globalThis & Window) | null>(
    typeof window !== 'undefined' && !doNotInit ? window : null,
  );

  const nodeRef = useCallback(<T extends Element>(node: T | null) => {
    setWin(node?.ownerDocument.defaultView ?? window);
  }, []);

  return {
    ref: nodeRef,
    window: win,
  };
};
