import { css } from '@emotion/react';
import { forwardRef } from 'react';
import {
  PickersToolbarText,
  PickersToolbarTextProps,
} from '../pickers-toolbar-text';
import { DateTimePickerToolbarProps } from './date-time-picker-toolbar-type-map';

export const DateTimePickerToolbarSeparator = forwardRef<
  HTMLSpanElement,
  PickersToolbarTextProps & { ownerState: DateTimePickerToolbarProps }
>((props, ref) => {
  const { ownerState, ...other } = props;

  return (
    <PickersToolbarText
      css={css`
        margin: ${ownerState.toolbarVariant === 'desktop' ? 0 : '0 4px 0 2px'};
        cursor: default;
      `}
      {...other}
      ref={ref}
    />
  );
});
