import { AssistantDocumentUploadSource } from '@allganize/alli-sdk-chat';
import { ChatFormAutocomplete, ChatFormRoot } from '@allganize/alli-sdk-ui';
import { useEventCallback } from '@allganize/hooks';
import { useTheme } from '@emotion/react';
import { format, isValid as isValidDate } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { analytics } from '../analytics';
import { ChatListContext } from '../chat-list/chat-list-context';
import { config } from '../config';
import { ConversationContext } from '../conversation-detail/conversation-context';
import { dateFormat, useChatForm } from '../hooks/use-chat-form';
import { useLauncher } from '../hooks/use-launcher';
import { ChatFormDatePickerField } from './chat-form-date-picker';
import {
  ChatFormFileDropzone,
  useChatFormFileDropzone,
} from './chat-form-file-dropzone';
import { ChatFormKnowledgeBasesField } from './chat-form-knowledge-bases-field';
import { ChatFormMessageField } from './chat-form-message-field';
import { IntentValueType } from './chat-form-intent-selector';

export const ChatForm = () => {
  const intl = useIntl();
  const theme = useTheme();
  const { open } = useLauncher();
  const {
    data: { conversation },
  } = useContext(ConversationContext);
  const { data: chatListData } = useContext(ChatListContext);
  const {
    autocomplete,
    dateFieldEnabled,
    dropzoneOptions,
    fileFieldEnabled,
    assistantEnabled,
    form: {
      watch,
      resetField,
      clearErrors,
      setFocus,
      handleSubmit,
      control,
      formState,
    },
    formDisabled,
    formRef,
    messageFieldEnabled,
    placeholder,
    submit,
    waitUserAnswer,
    isGenerating,
  } = useChatForm({
    validation: config.validation.chatForm,
    conversation,
    chatListData,
  });
  const { dropzoneRef, openFilePicker } = useChatFormFileDropzone();

  const [message, file, date] = watch(['message', 'file', 'date']);

  const messageInputValue = useMemo(() => {
    if (fileFieldEnabled && file) return file.name;
    if (dateFieldEnabled && date && isValidDate(date))
      return format(date, dateFormat);
    if (messageFieldEnabled) return message;
    return '';
  }, [
    messageFieldEnabled,
    fileFieldEnabled,
    dateFieldEnabled,
    message,
    file,
    date,
  ]);

  const messageInputPlaceholder = useMemo(() => {
    if (placeholder) return placeholder;
    if (fileFieldEnabled && !messageFieldEnabled)
      return intl.formatMessage(
        {
          id: 'form.file.placeholder',
          defaultMessage: 'Click or drag & drop{br}a file to upload',
          description: 'File input placeholder',
        },
        { br: ' ' },
      );
    return intl.formatMessage({
      id: 'chat-form.message.placeholder',
      defaultMessage: 'Send a message',
      description: 'Chat form message input placeholder',
    });
  }, [intl, placeholder, messageFieldEnabled, fileFieldEnabled]);

  const autoFocus =
    open && waitUserAnswer && !formDisabled && theme.deviceType === 'desktop';
  const popupOpen =
    autocomplete?.popupOpen && autocomplete.groupedOptions.length > 0;
  const dateFieldOnly =
    !messageFieldEnabled && !fileFieldEnabled && dateFieldEnabled;

  const [knowledgeBaseDialogType, setKnowledgeBaseDialogType] =
    useState<AssistantDocumentUploadSource | null>(null);

  const handleClickKnowledgeBaseButton = () => {
    analytics.track('conversation-detail::chat-input__kb-button.click', {
      conversationId: conversation?.id,
      appId: conversation?.llmApp?.id,
    });
  };

  const handleOpenKnowledgeBaseDialog = (
    type: AssistantDocumentUploadSource,
  ) => {
    setKnowledgeBaseDialogType(type);
    analytics.track(
      'conversation-detail::chat-input__kb-button__menu-item.click',
      {
        conversationId: conversation?.id,
        appId: conversation?.llmApp?.id,
        source: type,
      },
    );
  };
  const handleCloseKnowledgeBaseDialog = () => setKnowledgeBaseDialogType(null);

  const handleClearButtonClick = useEventCallback(() => {
    if (fileFieldEnabled) resetField('file');
    if (dateFieldEnabled) resetField('date');
  });

  const handleIntentSelectorClick = useEventCallback(() => {
    analytics.track('conversation-detail::chat-input__intent-selector.click', {
      conversationId: conversation?.id,
      appId: conversation?.llmApp?.id,
    });
  });

  const handleIntentValueChange = useEventCallback(
    (intent: IntentValueType) => {
      analytics.track(
        'conversation-detail::chat-input__intent-selector__intent.click',
        {
          conversationId: conversation?.id,
          appId: conversation?.llmApp?.id,
          intent,
        },
      );
    },
  );

  useEffect(() => {
    if (messageFieldEnabled && !file && !date && autoFocus) {
      setFocus('message');
    }
  }, [autoFocus, messageFieldEnabled, setFocus, file, date]);

  if (dateFieldOnly) {
    return (
      <ChatFormRoot onSubmit={handleSubmit(submit)} ref={formRef}>
        <ChatFormDatePickerField
          control={control}
          formState={formState}
          disabled={formDisabled}
          onClearError={clearErrors}
        />
      </ChatFormRoot>
    );
  }

  return (
    <ChatFormRoot onSubmit={handleSubmit(submit)} ref={formRef}>
      {assistantEnabled && (
        <ChatFormKnowledgeBasesField
          control={control}
          dialogType={knowledgeBaseDialogType}
          onCloseDialog={handleCloseKnowledgeBaseDialog}
        />
      )}
      <ChatFormFileDropzone
        {...dropzoneOptions}
        control={control}
        dropzoneRef={dropzoneRef}
        disabled={formDisabled || !fileFieldEnabled}
        noClick={messageFieldEnabled && !file}
      >
        <ChatFormMessageField
          control={control}
          formState={formState}
          value={messageInputValue}
          placeholder={messageInputPlaceholder}
          disabled={formDisabled}
          isSubmitting={isGenerating}
          autoFocus={autoFocus}
          multiline={messageFieldEnabled && !file && !date}
          autocomplete={autocomplete}
          messageFieldEnabled={messageFieldEnabled}
          fileFieldEnabled={fileFieldEnabled}
          dateFieldEnabled={dateFieldEnabled}
          assistantEnabled={assistantEnabled}
          onClear={
            (file || date) && !formDisabled ? handleClearButtonClick : undefined
          }
          onFileUpload={openFilePicker}
          onClearError={clearErrors}
          onOpenKnowledgeBaseDialog={handleOpenKnowledgeBaseDialog}
          onClickKnowledgeBaseButton={handleClickKnowledgeBaseButton}
          onClickIntentSelector={handleIntentSelectorClick}
          onChangeIntentValue={handleIntentValueChange}
        />
      </ChatFormFileDropzone>
      {popupOpen && <ChatFormAutocomplete autocomplete={autocomplete} />}
    </ChatFormRoot>
  );
};
