import { defineMessages } from 'react-intl';

export const ariaLabels = defineMessages({
  'next-button': {
    id: 'carousel.arrow.next.aria-label',
    defaultMessage: 'Move next',
    description: 'Next button aria label',
  },
  'prev-button': {
    id: 'carousel.arrow.prev.aria-label',
    defaultMessage: 'Move previous',
    description: 'Previous button aria label',
  },
});
