import EventEmitter from 'eventemitter3';
import {
  AlliLauncherManagerEvents,
  AlliLauncherManagerMessagePreviewState,
  AlliLauncherManagerOptions,
} from './alli-launcher-manager-options';

export class AlliLauncherManager extends EventEmitter<AlliLauncherManagerEvents> {
  private _open = false;
  private _messagePreview: AlliLauncherManagerMessagePreviewState = 'init';

  constructor(public readonly options: AlliLauncherManagerOptions = {}) {
    super();
  }

  public get open(): boolean {
    return this._open;
  }

  public get messagePreview(): AlliLauncherManagerMessagePreviewState {
    return this._messagePreview;
  }

  public setMessagePreview = (
    value: AlliLauncherManagerMessagePreviewState,
  ) => {
    if (this._messagePreview !== value) {
      const prev = this._messagePreview;
      this._messagePreview = value;
      this.emit('messagePreview', { prev, current: this._messagePreview });
    }
  };

  private setOpen(value: boolean) {
    if (this._open !== value) {
      this._open = value;
      this.emit(this._open ? 'show' : 'hide');
    }
  }

  public show = () => {
    this.setOpen(true);
  };

  public hide = () => {
    this.setOpen(false);
  };

  public toggle = () => {
    this.setOpen(!this._open);
  };
}
