import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import { PickersMonth as MuiPickersMonth } from '@mui/x-date-pickers/MonthCalendar/PickersMonth';
import { lighten } from 'polished';
import { FunctionComponent } from 'react';
import { pickersMonthClasses } from './pickers-month-classes';
import { PickersMonthProps } from './pickers-month-type-map';

export const PickersMonth: FunctionComponent<PickersMonthProps> = props => {
  const { classes, ...other } = props;
  const theme = useTheme();

  return (
    <ClassNames>
      {({ css, cx }) => (
        <MuiPickersMonth
          data-testid="pickers-month"
          {...other}
          classes={{
            ...classes,
            monthButton: cx(
              css`
                height: 32px;
                width: 64px;
                border-radius: ${theme.radius.round}px;
                ${theme.typography.body14}
                background-color: transparent;
                transition: ${theme.transitions.create(
                  ['background-color', 'color'],
                  { duration: theme.transitions.duration.short },
                )};

                &:hover,
                &:focus {
                  background-color: ${theme.palette.action.hover};
                }

                &:active {
                  background-color: ${theme.palette.action.selected};
                }

                &.${pickersMonthClasses.disabled} {
                  color: ${theme.palette.text.disabled};
                }

                &.${pickersMonthClasses.selected} {
                  color: ${theme.palette.primary.contrastText};
                  background-color: ${theme.palette.primary.main};

                  &:hover,
                  &:focus {
                    background-color: ${lighten(
                      -0.1,
                      theme.palette.primary.main,
                    )};
                  }

                  &:active {
                    background-color: ${lighten(
                      -0.2,
                      theme.palette.primary.main,
                    )};
                  }

                  &.${pickersMonthClasses.disabled} {
                    color: ${theme.palette.common.white};
                    background-color: ${theme.palette.action
                      .disabledBackground};
                  }
                }
              `,
              classes?.monthButton,
            ),
          }}
        />
      )}
    </ClassNames>
  );
};
