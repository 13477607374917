import { AlliSDKConfig } from './config.types';
import { messenger } from './messenger';
import { storage } from './storage';
import { validation } from './validation';

export const config: AlliSDKConfig = Object.freeze({
  api: {
    sdk: {
      default: {
        http: 'https://backend.alli.ai/d/user/',
        ws: 'wss://backend.alli.ai/d/ws/user',
      },
      ja: {
        http: 'https://backend-ja.alli.ai/d/user/',
        ws: 'wss://backend-ja.alli.ai/d/ws/user',
      },
      kr: {
        http: 'https://backend-kr.alli.ai/d/user/',
        ws: 'wss://backend-kr.alli.ai/d/ws/user',
      },
    },
  },
  fonts: {
    disableGoogleFonts: false,
  },
  messenger,
  mixpanel: {
    token: '43d30fe644d966bfb094ffc7a0ee0e00',
    apiHost: 'https://backend.alli.ai/mixpanel',
  },
  pageError: {
    imageUrls: {
      '1x': 'https://sdk.alli.ai/images/error-page.png',
      '2x': 'https://sdk.alli.ai/images/error-page@2x.png',
      '3x': 'https://sdk.alli.ai/images/error-page@3x.png',
    },
  },
  pdfjs: {
    workerSrc: `https://sdk.alli.ai/pdfjs-dist/${process.env.NX_PUBLIC_PDFJS_VERSION}/legacy/build/pdf.worker.js`,
    cMapUrl: `https://sdk.alli.ai/pdfjs-dist/${process.env.NX_PUBLIC_PDFJS_VERSION}/cmaps/`,
    standardFontDataUrl: `https://sdk.alli.ai/pdfjs-dist/${process.env.NX_PUBLIC_PDFJS_VERSION}/standard_fonts/`,
  },
  sentry: {
    dsn: 'https://efcf0aeb99e84e85a3163d8dcd885762@sentry.io/1267508',
    enabled: true,
    proxy: 'https://sdk.alli.ai/sentry-proxy.html',
  },
  storage,
  validation,
  version: process.env.NX_PUBLIC_RELEASE || '',
  documentPreviewUrl: {
    default: 'https://backend.alli.ai/webapi',
    ja: 'https://backend-ja.alli.ai/webapi',
    kr: 'https://backend-kr.alli.ai/webapi',
  },
  singleActionResultDownloadUrl: {
    default: 'https://backend.alli.ai/download/single_action_result_public',
    ja: 'https://backend-ja.alli.ai/download/single_action_result_public',
    kr: 'https://backend-kr.alli.ai/download/single_action_result_public',
  },
});
