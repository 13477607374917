import {
  getSwitchUtilityClass,
  switchClasses as muiSwitchClasses,
  SwitchClassKey as MuiSwitchClassKey,
} from '@mui/material/Switch';
import { omit } from 'lodash-es';

export type SwitchClassKey =
  | Exclude<
      MuiSwitchClassKey,
      'colorPrimary' | 'colorSecondary' | 'sizeSmall' | 'sizeMedium'
    >
  | 'focusVisible';
export type SwitchClasses = Record<SwitchClassKey, string>;

export const switchClasses: SwitchClasses = {
  ...omit(muiSwitchClasses, [
    'colorPrimary',
    'colorSecondary',
    'sizeSmall',
    'sizeMedium',
  ]),
  focusVisible: getSwitchUtilityClass('focusVisible'),
};
