import { useTheme } from '@allganize/ui-theme';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import {
  UploadError,
  UploadErrorType,
} from './knowledge-base-upload-dialog-type-map';
import { resetUlStyles } from '../styles/reset';

const messageDescriptor: Record<UploadErrorType, MessageDescriptor> = {
  [UploadErrorType.type]: {
    id: 'knowledge-base.upload-dialog.file-type-error',
    defaultMessage: 'Unsupported file formats have been excluded',
    description: 'knowledge base upload dialog file type error message',
  },
  [UploadErrorType.size]: {
    id: 'knowledge-base.upload-dialog.file-size-error',
    defaultMessage: 'Files exceeding the size limit have been excluded',
    description: 'knowledge base upload dialog file size error message',
  },
  [UploadErrorType.process]: {
    id: 'knowledge-base.upload-dialog.process-error',
    defaultMessage: 'Unable to add',
    description:
      'knowledge base upload dialog file uploading process error message',
  },
};

interface KnowledgeBaseUploadErrorProps
  extends React.HTMLAttributes<HTMLUListElement> {
  data: UploadError[];
}

export const KnowledgeBaseUploadError = ({
  data,
  ...others
}: KnowledgeBaseUploadErrorProps) => {
  const theme = useTheme();
  if (data.length === 0) {
    return null;
  }

  return (
    <Text
      component="ul"
      variant="body12"
      css={[
        resetUlStyles,
        css`
          li {
            position: relative;
            padding-inline-start: 12px;
            color: ${theme.palette.foreground.error};

            &::before {
              content: '・';
              position: absolute;
              left: 0;
            }
          }
        `,
      ]}
      {...others}
    >
      {data.map(({ type, fileNames }) => (
        <li key={type}>
          <FormattedMessage {...messageDescriptor[type]} />
          {`: ${fileNames.join(', ')}`}
        </li>
      ))}
    </Text>
  );
};
