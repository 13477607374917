import { createContext } from 'react';
import { Locale, defaultLocale } from './locales';

export interface LocaleContextValue {
  locale: Locale;
  setLocale(locale: Locale): void;
}

export const LocaleContext = createContext<LocaleContextValue>({
  locale: defaultLocale,
  setLocale: async () => {
    // do nothing
  },
});
