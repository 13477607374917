import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import { ClockPointer as MuiClockPointer } from '@mui/x-date-pickers/TimeClock/ClockPointer';
import { CLOCK_HOUR_WIDTH } from '@mui/x-date-pickers/TimeClock/shared';
import { FunctionComponent } from 'react';
import { ClockPointerProps } from './clock-pointer-type-map';

export const ClockPointer: FunctionComponent<ClockPointerProps> = props => {
  const { classes, hasSelected, ...other } = props;
  const theme = useTheme();
  const muiTheme = useMuiTheme();

  return (
    <ClassNames>
      {({ css, cx }) => (
        <ThemeProvider theme={{ ...muiTheme, transitions: theme.transitions }}>
          <MuiClockPointer
            data-testid="clock-pointer"
            hasSelected={hasSelected}
            {...other}
            classes={{
              ...classes,
              root: cx(
                css`
                  background-color: ${theme.palette.primary.main};
                `,
                classes?.root,
              ),
              thumb: cx(
                css`
                  background-color: ${theme.palette.primary.contrastText};
                  border: ${(CLOCK_HOUR_WIDTH - 4) / 2}px solid
                    ${theme.palette.primary.main};
                `,
                hasSelected &&
                  css`
                    background-color: ${theme.palette.primary.main};
                  `,
                classes?.thumb,
              ),
            }}
          />
        </ThemeProvider>
      )}
    </ClassNames>
  );
};
