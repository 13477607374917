import { Chip, ChipProps } from '@allganize/ui-chip';
import { IcClose } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { SyntheticEvent } from 'react';
import { GroupBase, MultiValueProps } from 'react-select';
import { selectClasses } from './select-classes';

const preventDefault = (event: SyntheticEvent) => event.preventDefault();

export const MultiValue = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: MultiValueProps<Option, IsMulti, Group> & Pick<ChipProps, 'avatar'>,
) => {
  const {
    avatar,
    children,
    className,
    getClassNames,
    innerProps: { children: innerChildren, ...innerProps } = {},
    isDisabled,
    removeProps,
  } = props;

  return (
    <Chip
      css={css`
        margin: 2px;
        min-width: 0;
      `}
      avatar={avatar}
      {...innerProps}
      disabled={isDisabled}
      color="gray-subtle"
      ref={null}
      label={children}
      deleteIcon={
        // @ts-expect-error removeProps.onClick is MouseEvent handler
        <IcClose
          onTouchEnd={removeProps.onClick}
          onMouseDown={preventDefault}
        />
      }
      onDelete={removeProps.onClick}
      className={clsx(
        selectClasses.multiValue,
        getClassNames('multiValue', props),
        className,
        innerProps?.className,
      )}
      classes={{
        label: clsx(
          selectClasses.multiValueLabel,
          getClassNames('multiValueLabel', props),
        ),
        deleteIcon: clsx(
          selectClasses.multiValueRemove,
          getClassNames('multiValueRemove', props),
        ),
      }}
    />
  );
};
