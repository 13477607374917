import { css } from '@emotion/react';
import { useFormControl } from '@mui/material/FormControl';
import clsx from 'clsx';
import { lighten } from 'polished';
import { ControlProps, GroupBase } from 'react-select';
import { selectClasses } from './select-classes';

export const Control = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: ControlProps<Option, IsMulti, Group>,
) => {
  const {
    children,
    className,
    getClassNames,
    isDisabled,
    isFocused,
    innerRef,
    innerProps,
    menuIsOpen,
    theme,
  } = props;
  const muiFormControl = useFormControl();

  return (
    <div
      css={css`
        align-items: center;
        cursor: default;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: ${theme.spacing.controlHeight}px;
        outline: 0;
        position: relative;
        border-radius: ${theme.borderRadius}px;
        border: 1px solid ${theme.colors.neutral20};

        &:hover {
          border-color: ${theme.colors.neutral50};

          @media (hover: none) {
            border-color: ${theme.colors.neutral20};
          }
        }

        &.${selectClasses.focused} {
          border-color: ${theme.colors.primary};
          box-shadow: 0 0 0 2px ${lighten(0.3, theme.colors.primary)};
        }

        &.${selectClasses.error} {
          border-color: ${theme.colors.danger};
          box-shadow: 0 0 0 2px ${lighten(0.3, theme.colors.danger)};
        }

        &.${selectClasses.disabled} {
          background-color: ${theme.colors.neutral5};
          border-color: ${theme.colors.neutral10};
        }
      `}
      ref={innerRef}
      {...innerProps}
      aria-disabled={isDisabled || undefined}
      className={clsx(
        selectClasses.control,
        {
          [selectClasses.disabled]: isDisabled,
          [selectClasses.focused]: muiFormControl?.focused || isFocused,
          [selectClasses.controlOpen]: menuIsOpen,
        },
        getClassNames('control', props),
        className,
        innerProps?.className,
      )}
    >
      {children}
    </div>
  );
};
