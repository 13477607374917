import {
  checkboxClasses as muiCheckboxClasses,
  CheckboxClassKey as MuiCheckboxClassKey,
  getCheckboxUtilityClass,
} from '@mui/material/Checkbox';
import { omit } from 'lodash-es';

export type CheckboxClassKey =
  | Exclude<
      MuiCheckboxClassKey,
      'colorPrimary' | 'colorSecondary' | 'sizeSmall' | 'sizeMedium'
    >
  | 'focusVisible';
export type CheckboxClasses = Record<CheckboxClassKey, string>;

export const checkboxClasses: CheckboxClasses = {
  ...omit(muiCheckboxClasses, [
    'colorPrimary',
    'colorSecondary',
    'sizeSmall',
    'sizeMedium',
  ]),
  focusVisible: getCheckboxUtilityClass('focusVisible'),
};
