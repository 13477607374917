import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type AssistantDocumentFallbackClassKey =
  | 'root'
  | 'message'
  | 'list'
  | 'item'
  | 'action';
export type AssistantDocumentFallbackClasses = Record<
  AssistantDocumentFallbackClassKey,
  string
>;

export const assistantDocumentFallbackClasses: AssistantDocumentFallbackClasses =
  generateUtilityClasses('assistantDocumentFallback', [
    'root',
    'message',
    'list',
    'item',
    'action',
  ]);
