import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type DocumentViewerHeaderSpacerClassKey = 'root';
export type DocumentViewerHeaderSpacerClasses = Record<
  DocumentViewerHeaderSpacerClassKey,
  string
>;

export const documentViewerHeaderSpacerClasses: DocumentViewerHeaderSpacerClasses =
  generateUtilityClasses('DocumentViewerHeaderSpacer', ['root']);
