import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { documentViewerSidebarWidth } from './constants';
import { documentViewerSidebarSpacerClasses } from './document-viewer-sidebar-spacer-classes';
import { DocumentViewerSidebarSpacerProps } from './document-viewer-sidebar-spacer-type-map';

export const DocumentViewerSidebarSpacer = forwardRef<
  HTMLDivElement,
  DocumentViewerSidebarSpacerProps
>((props, ref) => {
  const { classes, ...other } = props;

  return (
    <div
      data-testid="document-viewer-sidebar-spacer"
      css={css`
        width: ${documentViewerSidebarWidth}px;
      `}
      {...other}
      ref={ref}
      className={clsx(
        documentViewerSidebarSpacerClasses.root,
        classes?.root,
        other.className,
      )}
    />
  );
});
