import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { pickersToolbarTextClasses } from './pickers-toolbar-text-classes';
import { PickersToolbarTextProps } from './pickers-toolbar-text-type-map';

export const PickersToolbarText = forwardRef<
  HTMLSpanElement,
  PickersToolbarTextProps
>((props, ref) => {
  const { classes, selected, value, ...other } = props;
  const theme = useTheme();

  return (
    <Text
      data-testid="pickers-toolbar-text"
      css={css`
        transition: ${theme.transitions.create('color')};
        color: ${theme.palette.text.secondary};

        &.${pickersToolbarTextClasses.selected} {
          color: ${theme.palette.text.primary};
        }
      `}
      component="span"
      {...other}
      ref={ref}
      className={clsx(
        pickersToolbarTextClasses.root,
        { [pickersToolbarTextClasses.selected]: selected },
        classes?.root,
        { [classes?.selected ?? '']: selected },
        other.className,
      )}
    >
      {value}
    </Text>
  );
});
