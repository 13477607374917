import { Color } from '@mui/material';
import {
  alpha,
  getContrastRatio,
  darken as muiDarken,
  lighten as muiLighten,
} from '@mui/material/styles';
import createMuiPalette, {
  PaletteAugmentColorOptions,
  PaletteColor,
} from '@mui/material/styles/createPalette';
import { mapValues } from 'lodash-es';
import { lighten, mix } from 'polished';
import invariant from 'tiny-invariant';
import { blue } from '../colors/blue';
import { dark } from '../colors/dark';
import { gray } from '../colors/gray';
import { grayToAlpha } from '../colors/gray-to-alpha';
import { green } from '../colors/green';
import { purple } from '../colors/purple';
import { red } from '../colors/red';
import { white } from '../colors/white';
import { yellow } from '../colors/yellow';
import { Palette, PaletteOptions } from './palette';

const grey: Palette['grey'] = {
  ...gray,
  A100: gray[100],
  A200: gray[200],
  A400: gray[400],
  A700: gray[700],
};

const grayAlpha = mapValues(grey, grayToAlpha);
const contrastThreshold = 4.5;
const tonalOffset = 0.2;
const textPrimary = grayAlpha[900];

const getContrastText = (background: string) => {
  const contrastText =
    getContrastRatio(background, '#fff') >= contrastThreshold
      ? '#fff'
      : textPrimary;

  return contrastText;
};

const augmentColor = ({
  color: colorOption,
  mainShade = 500,
  lightShade = 300,
  darkShade = 700,
}: PaletteAugmentColorOptions): PaletteColor => {
  const tonalOffsetLight = tonalOffset;
  const tonalOffsetDark = tonalOffset * 1.5;

  let light = ('light' in colorOption && colorOption.light) || undefined;
  let main = ('main' in colorOption && colorOption.main) || undefined;
  let dark = ('dark' in colorOption && colorOption.dark) || undefined;
  let contrastText =
    ('contrastText' in colorOption && colorOption.contrastText) || undefined;

  if (
    !('main' in colorOption) &&
    colorOption[mainShade as keyof typeof colorOption]
  ) {
    main = colorOption[mainShade as keyof typeof colorOption];
  }

  invariant(main, 'The color object needs to have a `main` property.');

  if (!light) {
    if (
      lightShade in colorOption &&
      colorOption[lightShade as keyof typeof colorOption]
    ) {
      light = colorOption[lightShade as keyof typeof colorOption];
    } else {
      light = muiLighten(main, tonalOffsetLight);
    }
  }

  if (!dark) {
    if (
      darkShade in colorOption &&
      colorOption[darkShade as keyof typeof colorOption]
    ) {
      dark = colorOption[darkShade as keyof typeof colorOption];
    } else {
      dark = muiDarken(main, tonalOffsetDark);
    }
  }

  if (!contrastText) {
    contrastText = getContrastText(main);
  }

  return {
    light,
    main,
    dark,
    contrastText,
  };
};

export const createPalette = (options: PaletteOptions): Palette => {
  const muiPalette = createMuiPalette({
    grey,
    getContrastText,
    ...options,
    contrastThreshold,
    tonalOffset,
    primary: augmentColor({
      color: options.primary ?? purple,
    }),
    info: augmentColor({
      color: blue,
    }),
    success: augmentColor({
      color: {
        ...green,
        contrastText: '#fff',
      },
    }),
    warning: augmentColor({
      color: {
        ...yellow,
        contrastText: '#fff',
      },
    }),
    error: augmentColor({
      color: red,
    }),
    text: {
      primary: textPrimary,
      secondary: grayAlpha[500],
      disabled: grayAlpha[300],
    },
    action: {
      active: grayAlpha[500],
      hover: grayAlpha[100],
      hoverOpacity: 0.1,
      focus: grayAlpha[100],
      focusOpacity: 0.1,
      selected: grayAlpha[200],
      selectedOpacity: 0.2,
      disabled: grayAlpha[300],
      disabledBackground: grayAlpha[200],
      disabledOpacity: 0.5,
    },
    divider: grayAlpha[100],
    // TODO: [ALL-14042] Sync the Divider component style with the design guide
    // divider: grayAlpha[200],
  });

  const primary: Color = {
    50: mix(0.05, muiPalette.primary.main, '#fff'),
    100: mix(0.1, muiPalette.primary.main, '#fff'),
    200: mix(0.3, muiPalette.primary.main, '#fff'),
    300: mix(0.5, muiPalette.primary.main, '#fff'),
    400: mix(0.7, muiPalette.primary.main, '#fff'),
    500: muiPalette.primary.main,
    600: lighten(-0.1, muiPalette.primary.main),
    700: lighten(-0.2, muiPalette.primary.main),
    800: lighten(-0.3, muiPalette.primary.main),
    900: lighten(-0.4, muiPalette.primary.main),
    A100: mix(0.1, muiPalette.primary.main, '#fff'),
    A200: mix(0.3, muiPalette.primary.main, '#fff'),
    A400: mix(0.7, muiPalette.primary.main, '#fff'),
    A700: lighten(-0.2, muiPalette.primary.main),
  };

  return {
    ...muiPalette,
    augmentColor,
    grey,
    grayAlpha,
    background: {
      ...muiPalette.background,
      skeleton: {
        light: grayAlpha[50],
        dark: grayAlpha[100],
      },
      textHighlight: alpha('#FFE500', 0.4),
    },
    unstable_background: {
      default: gray[50],
      white: '#fff',
      gray: {
        default: gray[600],
        alpha: grayAlpha[200],
      },
      graySubtle: {
        default: gray[100],
        alpha: grayAlpha[100],
      },
      grayDisabled: {
        alpha: grayAlpha[100],
        default: gray[100],
      },
      dark: dark[800],
      primary: {
        default: primary[500],
        subtle: primary[100],
        disabled: primary[50],
      },
      success: {
        default: green[500],
        subtle: green[50],
      },
      error: {
        default: red[500],
        subtle: red[50],
      },
      alert: {
        default: yellow[500],
        subtle: yellow[100],
      },
      information: {
        default: blue[500],
        subtle: blue[50],
      },
      highlight: '#FFFE55',
    },
    backgroundInteractive: {
      primary: {
        default: primary[500],
        hover: primary[600],
        pressed: primary[700],
        selected: primary[400],
      },
      primarySubtle: {
        hover: primary[100],
        pressed: primary[200],
        selected: primary[50],
      },
      gray: {
        default: gray[300],
        hover: gray[400],
        pressed: gray[500],
      },
      graySubtleAlpha: {
        default: grayAlpha[100],
        hover: grayAlpha[200],
        pressed: grayAlpha[300],
      },
      grayDarkAlpha: {
        default: grayAlpha[500],
        hover: grayAlpha[600],
        pressed: grayAlpha[700],
      },
      grayGhostAlpha: {
        hover: grayAlpha[100],
        pressed: grayAlpha[200],
      },
      inverseAlpha: {
        hover: white[200],
        pressed: white[300],
      },
      error: {
        default: red[500],
        hover: red[600],
        pressed: red[700],
      },
    },
    border: {
      divider: {
        default: grayAlpha[200],
        subtle: grayAlpha[100],
      },
      success: green[500],
      error: {
        default: red[500],
        highlight: red[200],
      },
      information: blue[500],
      primary: {
        default: primary[500],
        highlight: primary[200],
      },
      disabled: grayAlpha[200],
      focused: primary[300],
      highlight: '#FFE500',
    },
    borderInteractive: {
      primary: {
        default: primary[400],
        hover: primary[500],
        pressed: primary[600],
      },
      gray: {
        default: grayAlpha[400],
        hover: grayAlpha[500],
        pressed: grayAlpha[600],
      },
    },
    foreground: {
      default: grayAlpha[900],
      secondary: grayAlpha[600],
      helper: grayAlpha[500],
      placeholder: grayAlpha[500],
      gray: {
        disabled: grayAlpha[500],
      },
      inverse: '#fff',
      success: green[500],
      error: red[500],
      information: blue[500],
      primary: {
        default: primary[500],
        disabled: primary[200],
      },
    },
    foregroundInteractive: {
      inverse: '#fff',
      primary: primary[500],
      gray: {
        default: grayAlpha[800],
        subtle: gray[50],
      },
    },
  };
};
