import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import { PickersYear as MuiPickersYear } from '@mui/x-date-pickers/YearCalendar/PickersYear';
import { lighten } from 'polished';
import { forwardRef } from 'react';
import { pickersYearClasses } from './pickers-year-classes';
import { PickersYearProps } from './pickers-year-type-map';

export const PickersYear = forwardRef<HTMLButtonElement, PickersYearProps>(
  (props, ref) => {
    const { classes, ...other } = props;
    const theme = useTheme();

    return (
      <ClassNames>
        {({ css, cx }) => (
          <MuiPickersYear
            data-testid="pickers-year"
            {...other}
            // @ts-expect-error internal component
            ref={ref}
            classes={{
              ...classes,
              yearButton: cx(
                css`
                  width: 64px;
                  height: 32px;
                  border-radius: ${theme.radius.round}px;
                  ${theme.typography.body14}
                  transition: ${theme.transitions.create(
                    ['background-color', 'color'],
                    { duration: theme.transitions.duration.short },
                  )};

                  &:hover,
                  &:focus {
                    background-color: ${theme.palette.action.hover};
                  }

                  &:active {
                    background-color: ${theme.palette.action.selected};
                  }

                  &.${pickersYearClasses.disabled} {
                    color: ${theme.palette.text.disabled};
                  }

                  &.${pickersYearClasses.selected} {
                    color: ${theme.palette.primary.contrastText};
                    background-color: ${theme.palette.primary.main};

                    &:hover,
                    &:focus {
                      background-color: ${lighten(
                        -0.1,
                        theme.palette.primary.main,
                      )};
                    }

                    &:active {
                      background-color: ${lighten(
                        -0.2,
                        theme.palette.primary.main,
                      )};
                    }

                    &.${pickersYearClasses.disabled} {
                      color: ${theme.palette.common.white};
                      background-color: ${theme.palette.action
                        .disabledBackground};
                    }
                  }
                `,
                classes?.yearButton,
              ),
            }}
          />
        )}
      </ClassNames>
    );
  },
);
