import { IconButton } from '@allganize/ui-button';
import {
  IcThumbsDown,
  IcThumbsDownFilled,
  IcThumbsUp,
  IcThumbsUpFilled,
} from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { forwardRef, useState } from 'react';
import { UserFeedbackImproverFormValues } from './use-user-feedback-improver-form';
import { UserFeedbackImproverForm } from './user-feedback-improver-form';

export interface UserFeedbackSubmitValues {
  rating: boolean | null;
  mrcInfoId?: string;
}

interface UserFeedbackProps extends React.HTMLAttributes<HTMLDivElement> {
  rating: boolean | null;
  improver?: boolean;
  onUserFeedbackImproverSubmit?(
    values: UserFeedbackImproverFormValues,
  ): Promise<any>;
  onUserFeedbackSubmit?(values: UserFeedbackSubmitValues): void;
}

export const UserFeedback = forwardRef<HTMLDivElement, UserFeedbackProps>(
  (props, ref) => {
    const {
      improver = false,
      onUserFeedbackImproverSubmit,
      onUserFeedbackSubmit,
      rating,
      ...other
    } = props;
    const [openFeedbackImproverDialog, setOpenFeedbackImproverDialog] =
      useState(false);

    const handleThumbsUpOnClick = () => {
      const finalRating = !rating ? true : null;
      onUserFeedbackSubmit?.({ rating: finalRating });
    };

    const handleThumbsDownOnClick = () => {
      const finalRating = rating === null || rating ? false : null;
      onUserFeedbackSubmit?.({ rating: finalRating });

      if (finalRating === false && improver) {
        setOpenFeedbackImproverDialog(true);
      }
    };

    const handleOnCloseFeedbackImproverDialog = () => {
      setOpenFeedbackImproverDialog(false);
    };

    const handleOnSubmitFeedbackImproverDialog = (
      values: UserFeedbackImproverFormValues,
    ) => {
      return onUserFeedbackImproverSubmit?.(values);
    };

    return (
      <div
        css={css`
          margin-top: 8px;
          display: flex;
          justify-content: flex-end;
        `}
        {...other}
        ref={ref}
      >
        <IconButton
          size="small"
          color={rating ? 'primary' : 'default'}
          onClick={handleThumbsUpOnClick}
        >
          {rating ? (
            <IcThumbsUpFilled fontSize="small" />
          ) : (
            <IcThumbsUp fontSize="small" />
          )}
        </IconButton>

        <IconButton
          size="small"
          color={rating === false ? 'primary' : 'default'}
          onClick={handleThumbsDownOnClick}
        >
          {rating === false ? (
            <IcThumbsDownFilled fontSize="small" />
          ) : (
            <IcThumbsDown fontSize="small" />
          )}
        </IconButton>

        <UserFeedbackImproverForm
          open={openFeedbackImproverDialog}
          onSubmit={handleOnSubmitFeedbackImproverDialog}
          onClose={handleOnCloseFeedbackImproverDialog}
        />
      </div>
    );
  },
);
