import {
  formControlClasses as muiFormControlClasses,
  FormControlClassKey as MuiFormControlClassKey,
} from '@mui/material/FormControl';
import { omit } from 'lodash-es';

export type FormControlClassKey = Exclude<
  MuiFormControlClassKey,
  'marginNormal' | 'marginDense'
>;
export type FormControlClasses = Record<FormControlClassKey, string>;

export const formControlClasses: FormControlClasses = omit(
  muiFormControlClasses,
  ['marginNormal', 'marginDense'],
);
