export const elevations = [
  'none',
  '0px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.16)',
  '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.16)',
  '0px 0px 4px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.16)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.12), 0px 8px 16px 0px rgba(0, 0, 0, 0.16)',
] as const;

export type Elevations = typeof elevations;

export type Elevation = 0 | 1 | 2 | 3 | 4;
