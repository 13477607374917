type Alignment = 'left' | 'right';

interface LauncherTheme {
  size: number;
  boxShadow: string;
}

type LauncherThemeOptions = Partial<LauncherTheme>;

interface MessengerTheme {
  width: number;
  minWidth: number;
  height: number;
  boxShadow: string;
  borderRadius: number;
  bgColor: string;
}

type MessengerThemeOptions = Partial<MessengerTheme>;

interface MessagePreviewTheme {
  width: number;
  minWidth: number;
  maxWidth: number;
  maxHeight: number;
  boxShadow: string;
  borderRadius: number;
}

type MessagePreviewThemeOptions = Partial<MessagePreviewTheme>;

export interface Theme {
  alignment: Alignment;
  launcher: LauncherTheme;
  messenger: MessengerTheme;
  messagePreview: MessagePreviewTheme;
}

export interface ThemeOptions {
  alignment?: Alignment;
  launcher?: LauncherThemeOptions;
  messenger?: MessengerThemeOptions;
  messagePreview?: MessagePreviewThemeOptions;
}

export const createTheme = (options: ThemeOptions = {}): Theme => {
  const launcher = {
    size: options.launcher?.size ?? 64,
    boxShadow: options.launcher?.boxShadow ?? 'none',
  } satisfies LauncherTheme;

  const messengerMinWidth = options.messenger?.minWidth ?? 380;
  const messengerWidth = options.messenger?.width ?? 380;
  const messenger = {
    width: Math.max(messengerMinWidth, messengerWidth),
    minWidth: messengerMinWidth,
    height: options.messenger?.height ?? 680,
    boxShadow: options.messenger?.boxShadow ?? 'none',
    borderRadius: options.messenger?.borderRadius ?? 0,
    bgColor: options.messenger?.bgColor ?? '#fff',
  } satisfies MessengerTheme;

  const messagePreviewMinWidth = options.messagePreview?.minWidth ?? 292;
  const messagePreviewMaxWidth = options.messagePreview?.maxWidth ?? 500;
  const messagePreviewWidth = options.messagePreview?.width ?? 292;

  const messagePreview = {
    width: Math.max(
      messagePreviewMinWidth,
      Math.min(messagePreviewMaxWidth, messagePreviewWidth),
    ),
    minWidth: messagePreviewMinWidth,
    maxWidth: messagePreviewMaxWidth,
    maxHeight: options.messagePreview?.maxHeight ?? 204,
    boxShadow: options.messagePreview?.boxShadow ?? 'none',
    borderRadius: options.messagePreview?.borderRadius ?? 0,
  } satisfies MessagePreviewTheme;

  return {
    alignment: options.alignment ?? 'right',
    launcher,
    messenger,
    messagePreview,
  };
};

export const defaultTheme = createTheme();
