import { Iframe, IframeProps } from '@allganize/react-iframe';
import { useTheme as useBaseTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { useTheme } from '../styles/use-theme';

export const LauncherIframe = forwardRef<HTMLIFrameElement, IframeProps>(
  (props, ref) => {
    const theme = useTheme();
    const baseTheme = useBaseTheme();

    return (
      <Iframe
        css={css`
          /* NOTE: set outline space 8px so that the outline is not cut off (outline width 2px + offset 2px) */
          width: ${theme.launcher.size + 8}px;
          height: ${theme.launcher.size + 8}px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: ${baseTheme.radius.round}px;
          border: 0 solid transparent;
        `}
        {...props}
        ref={ref}
      />
    );
  },
);
