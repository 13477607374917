import { IcCheckCircle, IcError, IcWarning } from '@allganize/ui-icons';
import { FunctionComponent } from 'react';
import { IconProps } from 'react-toastify';

export interface ToastIconProps extends IconProps {
  className?: string;
}

export const ToastIcon: FunctionComponent<ToastIconProps> = props => {
  const { className, theme, type } = props;

  if (type === 'success') {
    return <IcCheckCircle className={className} fontSize="medium" />;
  }

  if (type === 'error') {
    return <IcError className={className} fontSize="medium" />;
  }

  if (type === 'warning') {
    return <IcWarning className={className} fontSize="medium" />;
  }

  return null;
};
