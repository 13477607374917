import {
  useDraftToolbarEditorStateGetter,
  useDraftToolbarEditorStateSetter,
} from '@allganize/draft-toolbar-plugin';
import { zodResolver } from '@hookform/resolvers/zod';
import { filesize } from 'filesize';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, defineMessages, useIntl } from 'react-intl';
import { z } from 'zod';
import { DraftImagePlugin } from '../draft-image-plugin';

export type DraftToolbarImageFormValues =
  | {
      type: 'file';
      file: File;
      url: string;
    }
  | {
      type: 'url';
      file: null;
      url: string;
    };

const defaultDraftToolbarImageFormValues: DraftToolbarImageFormValues = {
  type: 'url',
  file: null,
  url: '',
};

const errorMessages = defineMessages({
  'url-required': {
    id: 'TextArea.ImageButton.URL.errors.required',
    defaultMessage: 'URL is a required field',
    description: 'Text area insert image button URL required error message',
  },
  'url-format': {
    id: 'TextArea.ImageButton.URL.errors.format',
    defaultMessage: 'Must be a valid URL',
    description: 'Text area insert image button URL format error message',
  },
  'url-invalid': {
    id: 'TextArea.ImageButton.URL.errors.invalid',
    defaultMessage: 'Invalid image URL',
    description: 'Text area insert image button URL invalid error message',
  },
  'file-type': {
    id: 'form.errors.file-type',
    defaultMessage: 'Only the following types are allowed: {types}',
    description: 'File type error message',
  },
  'file-maxSize': {
    id: 'Errors.file-max-size',
    defaultMessage: 'File too large (Max. {maxSize})',
    description: 'File max size error message',
  },
});

interface ValidationSchemaOptions {
  file: {
    maxSize: number;
    accept: string[];
  };
  intl: IntlShape;
}

const createValidationSchema = ({ file, intl }: ValidationSchemaOptions) => {
  const urlBase = z.string({
    required_error: intl.formatMessage(errorMessages['url-required']),
    invalid_type_error: intl.formatMessage(errorMessages['url-required']),
  });

  return z.discriminatedUnion('type', [
    z.object({
      type: z.literal('file'),
      file: z
        .instanceof(File)
        .refine(
          value => {
            if (!value) {
              return true;
            }

            return value.size <= file.maxSize;
          },
          intl.formatMessage(errorMessages['file-maxSize'], {
            maxSize: filesize(file.maxSize, {
              output: 'string',
              locale: intl.locale,
              localeOptions: intl.formats.date,
            }),
          }),
        )
        .refine(value => {
          if (!value) {
            return true;
          }

          return (
            value.type.startsWith('image/') &&
            file.accept.some(type => value.name.endsWith(type))
          );
        }, intl.formatMessage(errorMessages['file-type'], { types: file.accept.join(', ') })),
      url: urlBase,
    }),
    z.object({
      type: z.literal('url'),
      file: z.literal(null),
      url: urlBase
        .min(1, intl.formatMessage(errorMessages['url-required']))
        .url(intl.formatMessage(errorMessages['url-format'])),
    }),
  ]) satisfies z.ZodSchema<DraftToolbarImageFormValues>;
};

export interface UseDraftToolbarImageFormOptions<T extends { url: string }> {
  defaultValues?: DraftToolbarImageFormValues;
  validation: Pick<ValidationSchemaOptions, 'file'>;
  onSubmit(values: DraftToolbarImageFormValues): Promise<T>;
}

export const useDraftToolbarImageForm = <T extends { url: string }>({
  defaultValues = defaultDraftToolbarImageFormValues,
  validation,
  onSubmit,
}: UseDraftToolbarImageFormOptions<T>) => {
  const intl = useIntl();
  const validationSchema = useMemo(
    () => createValidationSchema({ intl, ...validation }),
    [intl, validation],
  );
  const getEditorState = useDraftToolbarEditorStateGetter();
  const setEditorState = useDraftToolbarEditorStateSetter();

  const form = useForm<DraftToolbarImageFormValues>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const { reset, setError } = form;

  const submit = async (values: DraftToolbarImageFormValues) => {
    try {
      const result = await onSubmit(values);
      setEditorState(
        DraftImagePlugin.addImage(getEditorState(), result.url, result),
      );
      reset();
    } catch (err) {
      if (err instanceof Error) {
        setError('root', { message: err.message });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }

      setError('root', {
        message: intl.formatMessage({
          id: 'MallyError.UNKNOWN',
          defaultMessage: 'Something went wrong. Please try again later.',
          description: 'MallyError.UNKNOWN',
        }),
      });
    }
  };

  return { form, submit };
};
