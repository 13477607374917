import { noop } from 'lodash-es';
import { createContext } from 'react';
import { UseChatList } from '../hooks/use-chat-list';

export const ChatListContext = createContext<UseChatList>({
  appendChatEdges: () => {
    // noop
  },
  data: undefined,
  edges: [],
  edgeGroups: [],
  loading: false,
  loadNextPageStatus: 'success',
  loadPreviousPageStatus: 'success',
  loadNextPage: () =>
    Promise.reject('loadNextPage called outside ChatListContext provider'),
  loadPreviousPage: () =>
    Promise.reject('loadPreviousPage called outside ChatListContext provider'),
  optimisticChats: [],
  pageInfo: null,
  refetch: () =>
    Promise.reject('refetch called outside ChatListContext provider'),
  setOptimisticChats: noop,
  scrollBottomRef: undefined,
  scrollToBottom: noop,
  scrollToEdge: () =>
    Promise.reject('scrollToEdge called outside ChatListContext provider'),
  hasSubscribed: false,
});
