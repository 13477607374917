import {
  IconButtonClassKey as MuiIconButtonClassKey,
  iconButtonClasses as muiIconButtonClasses,
  getIconButtonUtilityClass,
} from '@mui/material/IconButton';
import { omit } from 'lodash-es';
import { ButtonClassKey } from '../button';

export type IconButtonClassKey =
  | Exclude<
      MuiIconButtonClassKey,
      | 'colorInherit'
      | 'colorSecondary'
      | 'colorInfo'
      | 'colorSuccess'
      | 'colorWarning'
    >
  | Exclude<ButtonClassKey, 'icon' | 'startIcon' | 'endIcon' | 'fullWidth'>;
export type IconButtonClasses = Record<IconButtonClassKey, string>;

export const iconButtonClasses: IconButtonClasses = {
  ...omit(muiIconButtonClasses, [
    'colorInherit',
    'colorSecondary',
    'colorInfo',
    'colorSuccess',
    'colorWarning',
  ]),
  colorDefault: getIconButtonUtilityClass('colorDefault'),
  ghost: getIconButtonUtilityClass('ghost'),
  filled: getIconButtonUtilityClass('filled'),
  outlined: getIconButtonUtilityClass('outlined'),
  shapeCircular: getIconButtonUtilityClass('shapeCircular'),
  shapeRounded: getIconButtonUtilityClass('shapeRounded'),
  focusVisible: getIconButtonUtilityClass('focusVisible'),
};
