import { ApolloQueryResult } from '@apollo/client/core';
import { Checkbox } from '@allganize/ui-checkbox';
import { css } from '@emotion/react';
import { FunctionComponent, HTMLAttributes } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { KnowledgeBaseCard } from '../knowledge-base-card';
import { KnowledgeBaseFetchStatus } from './knowledge-base-search-dialog-type-map';
import { EmptyState, LoadingState } from '../knowledge-base-state';
import { KnowledgeBasesQuery } from '../../graphql/queries/knowledge-bases-query';

import type { KnowledgeBase } from '../types/types';

interface KnowledgeBaseSelectableListProps
  extends HTMLAttributes<HTMLDivElement> {
  status: KnowledgeBaseFetchStatus;
  knowledgeBases: KnowledgeBase[];
  selectedKnowledgeBases: KnowledgeBase[];
  onAdd: (knowledgeBase: KnowledgeBase) => void;
  onDelete: (knowledgeBase: KnowledgeBase) => void;
  onLoadMore(): Promise<ApolloQueryResult<KnowledgeBasesQuery> | void>;
}

export const KnowledgeBaseSelectableList: FunctionComponent<
  KnowledgeBaseSelectableListProps
> = ({
  status,
  knowledgeBases,
  selectedKnowledgeBases,
  onAdd,
  onDelete,
  onLoadMore,
  ...others
}) => {
  return (
    <div
      css={css`
        height: 440px;
      `}
      {...others}
    >
      {status === 'initial-loading' && <LoadingState />}
      {status === 'empty' && <EmptyState />}
      {status === 'search-empty' && <EmptyState isSearch />}
      {(status === 'result' || status === 'loading') && (
        <Virtuoso
          overscan={10}
          data={knowledgeBases}
          endReached={() => {
            if (status === 'loading') {
              return;
            }
            onLoadMore();
          }}
          itemContent={(_, knowledgeBase) => {
            return (
              <KnowledgeBaseCard
                data={knowledgeBase}
                startAdornment={
                  <Checkbox
                    checked={
                      selectedKnowledgeBases.findIndex(
                        ({ id }) => id === knowledgeBase.id,
                      ) > -1
                    }
                    onChange={(e, checked) => {
                      checked ? onAdd(knowledgeBase) : onDelete(knowledgeBase);
                    }}
                  />
                }
                css={css`
                  width: 100%;
                  padding: 16px 8px;
                  border-radius: 0;
                  border-width: 0;
                  border-bottom-width: 1px;
                `}
              />
            );
          }}
          components={{
            Footer: () => {
              return status === 'loading' ? (
                <LoadingState
                  css={css`
                    padding: 50px 0;
                  `}
                />
              ) : null;
            },
          }}
          {...others}
        />
      )}
    </div>
  );
};
