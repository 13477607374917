export const gray = {
  50: 'hsla(0, 0%, 96%, 1)',
  100: 'hsla(0, 0%, 90%, 1)',
  200: 'hsla(0, 0%, 80%, 1)',
  300: 'hsla(0, 0%, 70%, 1)',
  400: 'hsla(0, 0%, 60%, 1)',
  500: 'hsla(0, 0%, 50%, 1)',
  600: 'hsla(0, 0%, 40%, 1)',
  700: 'hsla(0, 0%, 30%, 1)',
  800: 'hsla(0, 0%, 20%, 1)',
  900: 'hsla(0, 0%, 13%, 1)',
};
