import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type DocumentViewerClassKey =
  | 'root'
  | 'header'
  | 'toolbar'
  | 'sidebar'
  | 'content'
  | 'page';
export type DocumentViewerClasses = Record<DocumentViewerClassKey, string>;

export const documentViewerClasses: DocumentViewerClasses =
  generateUtilityClasses('DocumentViewer', [
    'root',
    'header',
    'toolbar',
    'sidebar',
    'content',
    'page',
  ]);
