import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { PickersLayout as MuiPickersLayout } from '@mui/x-date-pickers/PickersLayout';
import { forwardRef } from 'react';
import { DateOrTimeViewWithMeridiem } from '../internals/models';
import { PickersActionBar } from '../pickers-action-bar';
import { PickersShortcuts } from '../pickers-shortcuts';
import { pickersLayoutClasses } from './pickers-layout-classes';
import { PickersLayoutProps } from './pickers-layout-type-map';

interface PickersLayoutType {
  <TValue, TView extends DateOrTimeViewWithMeridiem>(
    props: React.PropsWithoutRef<PickersLayoutProps<TValue, TView>> &
      React.RefAttributes<HTMLDivElement>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

// @ts-expect-error overridable component
export const PickersLayout: PickersLayoutType = forwardRef((props, ref) => {
  const { isLandscape, slots, ...other } = props;
  const theme = useTheme();

  return (
    <MuiPickersLayout
      data-testid="pickers-layout"
      css={css`
        .${pickersLayoutClasses.toolbar} {
          ${isLandscape &&
          css`
            grid-column: ${theme.direction === 'rtl' ? 3 : 1};
            grid-row: 2 / 4;
          `}
        }

        .${pickersLayoutClasses.shortcuts} {
          ${!isLandscape &&
          css`
            grid-column: ${theme.direction === 'rtl' ? 3 : 1};
          `}
        }
      `}
      isLandscape={isLandscape}
      {...other}
      ref={ref}
      slots={{
        // @ts-expect-error internal component
        actionBar: PickersActionBar,
        shortcuts: PickersShortcuts,
        ...slots,
      }}
    />
  );
});
