import { createTheme as createMuiTheme } from '@mui/material/styles';
import { elevations } from '../elevations/elevations';
import { createMixins } from '../mixins/create-mixins';
import { createPalette } from '../palette/create-palette';
import { radius } from '../radius/radius';
import { createTypography } from '../typography/create-typography';
import { Theme, ThemeOptions } from './theme';

export const createTheme = (
  { mixins: mixinsOptions, typography, ...options }: ThemeOptions = {},
  ...args: object[]
): Theme => {
  const palette = createPalette(options.palette ?? {});
  const muiTheme = createMuiTheme({ ...options, palette }, ...args);
  const mixins = createMixins(muiTheme.breakpoints, mixinsOptions ?? {});

  return {
    ...muiTheme,
    palette,
    elevations,
    mixins,
    radius,
    typography: createTypography(typography ?? {}),
  };
};
