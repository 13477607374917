import { css } from '@emotion/react';
import MuiSwitchBase from '@mui/material/internal/SwitchBase';
import { forwardRef } from 'react';
import { SwitchBaseProps } from './switch-base-type-map';

export const SwitchBase = forwardRef<HTMLSpanElement, SwitchBaseProps>(
  (props, ref) => {
    const { edge = false, ...other } = props;

    return (
      <MuiSwitchBase
        data-testid="switch-base"
        css={[
          css`
            padding: 2px;
          `,
          edge === 'start' &&
            css`
              margin-left: -2px;
            `,
          edge === 'end' &&
            css`
              margin-right: -2px;
            `,
        ]}
        edge={edge}
        {...other}
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        focusRipple={false}
      />
    );
  },
);
