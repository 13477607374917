import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { PickersDay as MuiPickersDay } from '@mui/x-date-pickers/PickersDay';
import { lighten } from 'polished';
import { forwardRef } from 'react';
import { DAY_MARGIN, DAY_SIZE } from '../internals/constants';
import { pickersDayClasses } from './pickers-day-classes';
import { PickersDayProps } from './pickers-day-type-map';

export const PickersDay = forwardRef<HTMLButtonElement, PickersDayProps>(
  (props, ref) => {
    const {
      disableHighlightToday = false,
      disableMargin = false,
      outsideCurrentMonth,
      showDaysOutsideCurrentMonth = false,
      today = false,
      ...other
    } = props;
    const theme = useTheme();

    return (
      <MuiPickersDay
        data-testid="pickers-day"
        css={[
          css`
            width: ${DAY_SIZE}px;
            height: ${DAY_SIZE}px;
            ${theme.typography.body14}
            color: ${theme.palette.text.primary};
            background-color: transparent;
            transition: ${theme.transitions.create(
              ['background-color', 'color', 'border-color', 'font-weight'],
              { duration: theme.transitions.duration.short },
            )};

            &:hover,
            &:focus {
              background-color: ${theme.palette.action.hover};
            }

            &:active {
              background-color: ${theme.palette.action.selected};
            }

            &.${pickersDayClasses.selected} {
              color: ${theme.palette.primary.contrastText};
              background-color: ${theme.palette.primary.main};
              font-weight: ${theme.typography.fontWeightMedium};

              &:hover,
              &:focus {
                background-color: ${lighten(-0.1, theme.palette.primary.main)};
              }

              &:active {
                background-color: ${lighten(-0.2, theme.palette.primary.main)};
              }
            }

            &:not(
                .${pickersDayClasses.selected}
              ).${pickersDayClasses.disabled} {
              color: ${theme.palette.text.disabled};
            }

            &.${pickersDayClasses.disabled}&.${pickersDayClasses.selected} {
              opacity: 1;
            }

            &.${pickersDayClasses.disabled}.${pickersDayClasses.selected} {
              color: ${theme.palette.common.white};
              background-color: ${theme.palette.action.disabledBackground};
            }
          `,
          !disableMargin &&
            css`
              margin: 0 ${DAY_MARGIN}px;
            `,
          outsideCurrentMonth &&
            showDaysOutsideCurrentMonth &&
            css`
              color: ${theme.palette.text.secondary};
            `,
          !disableHighlightToday &&
            today &&
            css`
              &:not(.${pickersDayClasses.selected}) {
                border: 1px solid ${theme.palette.grayAlpha[300]};
              }

              &:hover,
              &:focus,
              &:active {
                border-color: ${theme.palette.grayAlpha[500]};
              }

              &.${pickersDayClasses.disabled} {
                border-color: ${theme.palette.action.disabledBackground};
              }

              &.${pickersDayClasses.selected} {
                border: 0;
              }
            `,
        ]}
        disableHighlightToday={disableHighlightToday}
        disableMargin={disableMargin}
        outsideCurrentMonth={outsideCurrentMonth}
        showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
        today={today}
        {...other}
        ref={ref}
        centerRipple={false}
        disableRipple
        disableTouchRipple
        focusRipple={false}
      />
    );
  },
);
