import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type DocumentViewerToolbarClassKey = 'root' | 'hidden';
export type DocumentViewerToolbarClasses = Record<
  DocumentViewerToolbarClassKey,
  string
>;

export const documentViewerToolbarClasses: DocumentViewerToolbarClasses =
  generateUtilityClasses('DocumentViewerToolbar', ['root', 'hidden']);
