import { IconButton } from '@allganize/ui-button';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { canGoNext } from 'react-slick/lib/utils/innerSliderUtils';
import { carouselArrowClasses } from './carousel-arrow-classes';
import { CarouselArrowProps } from './carousel-arrow-type-map';

export const CarouselArrow = forwardRef<HTMLButtonElement, CarouselArrowProps>(
  (props, ref) => {
    const {
      classes,
      color = 'default',
      centerMode,
      currentSlide = 0,
      direction,
      disabled: inDisabled,
      infinite,
      slideCount = 0,
      slidesToShow = 1,
      style,
      variant = 'ghost',
      ...other
    } = props;
    const defaultPrevDisabled =
      !infinite && (currentSlide === 0 || slideCount <= slidesToShow);
    const defaultNextDisabled = !canGoNext({
      ...props,
      currentSlide,
      slideCount,
      slidesToShow,
    }) as unknown as boolean;
    const prevDisabled = inDisabled ?? defaultPrevDisabled;
    const nextDisabled = inDisabled ?? defaultNextDisabled;
    const disabled = direction === 'next' ? nextDisabled : prevDisabled;

    return (
      <IconButton
        css={css`
          position: absolute;
          top: 0;

          &.${carouselArrowClasses.next} {
            right: 0;
          }

          &.${carouselArrowClasses.prev} {
            right: 40px;
          }
        `}
        color={color}
        disabled={disabled}
        variant={variant}
        {...other}
        ref={ref}
        className={clsx(
          carouselArrowClasses.root,
          {
            [carouselArrowClasses.prev]: direction === 'prev',
            [carouselArrowClasses.next]: direction === 'next',
            [carouselArrowClasses.disabled]: disabled,
          },
          classes?.root,
          {
            [classes?.prev ?? '']: direction === 'prev',
            [classes?.next ?? '']: direction === 'next',
            [classes?.disabled ?? '']: disabled,
          },
          other.className,
        )}
      />
    );
  },
);
