import { useTheme } from '@allganize/ui-theme';
import { Input, InputAdornment, InputProps } from '@allganize/ui-input';
import { IcClose, IcSearch } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { IconButton } from '@allganize/ui-button';
import { Chip } from '@allganize/ui-chip';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

interface KnowledgeBaseSearchInputProps extends Omit<InputProps, 'ref'> {
  query: string;
  hashtagFilter: string | null;
  onSearch(query: string): void;
  clearHashtagFilter(): void;
}

export const KnowledgeBaseSearchInput: FunctionComponent<
  KnowledgeBaseSearchInputProps
> = ({
  query: queryProp,
  hashtagFilter,
  clearHashtagFilter,
  onSearch,
  ...other
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const [query, setQuery] = useState(queryProp);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value),
    [],
  );

  const clearQuery = useCallback(() => {
    setQuery('');
  }, []);

  useEffect(() => {
    if (query === '') {
      onSearch('');
    }
  }, [query, onSearch]);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (query.trim() !== '') {
        onSearch(query.trim());
      }
    },
    [query, onSearch],
  );

  return (
    <form
      onSubmit={handleSubmit}
      css={css`
        margin-top: 2px;
        margin-bottom: 16px;
      `}
    >
      <Input
        fullWidth
        value={query}
        onChange={handleChange}
        placeholder={intl.formatMessage({
          id: 'knowledge-base.search-dialog.search-input.placeholder',
          defaultMessage: 'Search files or #tags',
          description: 'knowledge base search input placeholder',
        })}
        startAdornment={
          <InputAdornment position="start">
            <IcSearch />
            {hashtagFilter && (
              <Chip
                label={`# ${hashtagFilter}`}
                onDelete={clearHashtagFilter}
                css={css`
                  max-width: 128px;
                  margin-right: 4px;
                `}
              />
            )}
          </InputAdornment>
        }
        endAdornment={
          query !== '' && (
            <InputAdornment position="end">
              <IconButton size="small" onClick={clearQuery}>
                <IcClose />
              </IconButton>
            </InputAdornment>
          )
        }
        css={css`
          border-radius: ${theme.radius.round}px;
          height: 40px;
        `}
        {...other}
      />
    </form>
  );
};
