import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type ChatContentBubbleClassKey =
  | 'root'
  | 'alignLeft'
  | 'alignRight'
  | 'colorPrimary'
  | 'colorDefault'
  | 'loading'
  | 'circularProgress';

export type ChatContentBubbleClasses = Record<
  ChatContentBubbleClassKey,
  string
>;

export const chatContentBubbleClasses: ChatContentBubbleClasses =
  generateUtilityClasses('ChatContentBubble', [
    'root',
    'alignLeft',
    'alignRight',
    'colorDefault',
    'colorPrimary',
    'loading',
    'circularProgress',
  ]);
