import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import { markdownClasses } from '../markdown/markdown-classes';

export const Code = forwardRef<
  HTMLElement,
  React.HTMLAttributes<HTMLElement> & CodeProps
>((props, ref) => {
  const { node, sourcePosition, index, siblingCount, inline, ...other } = props;
  const theme = useTheme();

  return (
    <code
      css={[
        css`
          padding: 0;
          background-color: transparent;
          border-radius: ${theme.radius.xs}px;
          color: ${theme.palette.foreground.default};
          white-space: break-spaces;
        `,
        inline &&
          css`
            padding: 0 4px;
            background-color: ${theme.palette.unstable_background.graySubtle
              .alpha};
            border: 1px solid ${theme.palette.border.divider.default};
          `,
      ]}
      {...other}
      ref={ref}
      className={clsx(
        markdownClasses.code,
        { [markdownClasses.codeInline]: inline },
        other.className,
      )}
    />
  );
});
