import { Button, LoadingButton } from '@allganize/ui-button';
import { InputField } from '@allganize/ui-input';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { finalOutputClasses } from './final-output-classes';
import { FinalOutputEditorProps } from './final-output-type-map';

export const FinalOutputEditor: FunctionComponent<FinalOutputEditorProps> = ({
  classes,
  output,
  updating,
  onSubmit,
  onCancel,
}) => {
  const { handleSubmit, control } = useForm({
    mode: 'all',
    defaultValues: {
      output,
    },
  });

  return (
    <form
      className={clsx(finalOutputClasses.editor, classes?.editor)}
      onSubmit={handleSubmit(values => onSubmit(values.output))}
    >
      <Controller
        control={control}
        name="output"
        render={({
          field: { onChange, onBlur, ref, ...field },
          fieldState,
        }) => {
          return (
            <InputField
              {...field}
              inputRef={ref}
              label={
                <FormattedMessage
                  id="single-action.final-output.title"
                  defaultMessage="Final Output"
                  description="single action final output title"
                />
              }
              disabled={updating}
              onChange={onChange}
              onBlur={onBlur}
              error={fieldState.invalid}
              multiline
              rows={10}
              fullWidth
              required
            />
          );
        }}
      />
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          gap: 12px;
          margin-top: 24px;
        `}
      >
        <Button color="primary" onClick={onCancel} disabled={updating}>
          <FormattedMessage
            id="single-action.final-output.cancel"
            defaultMessage="Cancel"
            description="single action final output editor cancel button text"
          />
        </Button>
        <LoadingButton
          type="submit"
          variant="filled"
          color="primary"
          loading={updating}
          disabled={updating}
        >
          <FormattedMessage
            id="single-action.final-output.update"
            defaultMessage="Update"
            description="single action final output editor update button text"
          />
        </LoadingButton>
      </div>
    </form>
  );
};
