import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type CarouselClassKey =
  | 'root'
  | 'vertical'
  | 'initialized'
  | 'dotted'
  | 'dots'
  | 'dotActive'
  | 'list'
  | 'track'
  | 'slide'
  | 'slideActive'
  | 'slideCenter'
  | 'slideCloned'
  | 'slideCurrent';
export type CarouselClasses = Record<CarouselClassKey, string>;

export const carouselClasses: CarouselClasses = {
  ...generateUtilityClasses('Carousel', ['root', 'vertical', 'dotted', 'dots']),
  initialized: 'slick-initialized',
  dotActive: 'slick-active',
  list: 'slick-list',
  track: 'slick-track',
  slide: 'slick-slide',
  slideActive: 'slick-active',
  slideCenter: 'slick-center',
  slideCloned: 'slick-cloned',
  slideCurrent: 'slick-current',
};
