import { TimeViewRendererProps as MuiTimeViewRendererProps } from '@mui/x-date-pickers/timeViewRenderers';
import { DigitalClock, DigitalClockProps } from './digital-clock';
import { BaseClockProps, TimeViewWithMeridiem } from './internals/models';
import { isTimeView } from './internals/utils/time-utils';
import { TimeView } from './models';
import {
  MultiSectionDigitalClock,
  MultiSectionDigitalClockProps,
} from './multi-section-digital-clock';
import { TimeClock, TimeClockProps } from './time-clock';
import { TimePickerProps } from './time-picker';

export type TimeViewRendererProps<
  TView extends TimeViewWithMeridiem,
  TComponentProps extends BaseClockProps<any>,
> = MuiTimeViewRendererProps<TView, TComponentProps>;

export const renderTimeViewClock = ({
  view,
  onViewChange,
  focusedView,
  onFocusedViewChange,
  views,
  value,
  defaultValue,
  referenceDate,
  onChange,
  className,
  classes,
  disableFuture,
  disablePast,
  minTime,
  maxTime,
  shouldDisableTime,
  minutesStep,
  ampm,
  ampmInClock,
  slots,
  slotProps,
  readOnly,
  disabled,
  autoFocus,
  showViewSwitcher,
  disableIgnoringDatePartForTimeValidation,
  timezone,
}: TimeViewRendererProps<TimeView, TimeClockProps<TimeView>>) => (
  <TimeClock
    view={view}
    onViewChange={onViewChange}
    focusedView={focusedView && isTimeView(focusedView) ? focusedView : null}
    onFocusedViewChange={onFocusedViewChange}
    views={views.filter(isTimeView)}
    value={value}
    defaultValue={defaultValue}
    referenceDate={referenceDate}
    onChange={onChange}
    className={className}
    classes={classes}
    disableFuture={disableFuture}
    disablePast={disablePast}
    minTime={minTime}
    maxTime={maxTime}
    shouldDisableTime={shouldDisableTime}
    minutesStep={minutesStep}
    ampm={ampm}
    ampmInClock={ampmInClock}
    slots={slots}
    slotProps={slotProps}
    readOnly={readOnly}
    disabled={disabled}
    autoFocus={autoFocus}
    showViewSwitcher={showViewSwitcher}
    disableIgnoringDatePartForTimeValidation={
      disableIgnoringDatePartForTimeValidation
    }
    timezone={timezone}
  />
);

export const renderDigitalClockTimeView = ({
  view,
  onViewChange,
  focusedView,
  onFocusedViewChange,
  views,
  value,
  defaultValue,
  referenceDate,
  onChange,
  className,
  classes,
  disableFuture,
  disablePast,
  minTime,
  maxTime,
  shouldDisableTime,
  minutesStep,
  ampm,
  slots,
  slotProps,
  readOnly,
  disabled,
  autoFocus,
  disableIgnoringDatePartForTimeValidation,
  timeSteps,
  skipDisabled,
  timezone,
}: TimeViewRendererProps<
  Extract<TimeView, 'hours'>,
  Omit<DigitalClockProps, 'timeStep'> & Pick<TimePickerProps, 'timeSteps'>
>) => (
  <DigitalClock
    view={view}
    onViewChange={onViewChange}
    focusedView={focusedView}
    onFocusedViewChange={onFocusedViewChange}
    views={views.filter(isTimeView)}
    value={value}
    defaultValue={defaultValue}
    referenceDate={referenceDate}
    onChange={onChange}
    className={className}
    classes={classes}
    disableFuture={disableFuture}
    disablePast={disablePast}
    minTime={minTime}
    maxTime={maxTime}
    shouldDisableTime={shouldDisableTime}
    minutesStep={minutesStep}
    ampm={ampm}
    slots={slots}
    slotProps={slotProps}
    readOnly={readOnly}
    disabled={disabled}
    autoFocus={autoFocus}
    disableIgnoringDatePartForTimeValidation={
      disableIgnoringDatePartForTimeValidation
    }
    timeStep={timeSteps?.minutes}
    skipDisabled={skipDisabled}
    timezone={timezone}
  />
);

export const renderMultiSectionDigitalClockTimeView = ({
  view,
  onViewChange,
  focusedView,
  onFocusedViewChange,
  views,
  value,
  defaultValue,
  referenceDate,
  onChange,
  className,
  classes,
  disableFuture,
  disablePast,
  minTime,
  maxTime,
  shouldDisableTime,
  minutesStep,
  ampm,
  slots,
  slotProps,
  readOnly,
  disabled,
  autoFocus,
  disableIgnoringDatePartForTimeValidation,
  timeSteps,
  skipDisabled,
  timezone,
}: TimeViewRendererProps<
  TimeViewWithMeridiem,
  MultiSectionDigitalClockProps
>) => (
  <MultiSectionDigitalClock
    view={view}
    onViewChange={onViewChange}
    focusedView={focusedView}
    onFocusedViewChange={onFocusedViewChange}
    views={views.filter(isTimeView)}
    value={value}
    defaultValue={defaultValue}
    referenceDate={referenceDate}
    onChange={onChange}
    className={className}
    classes={classes}
    disableFuture={disableFuture}
    disablePast={disablePast}
    minTime={minTime}
    maxTime={maxTime}
    shouldDisableTime={shouldDisableTime}
    minutesStep={minutesStep}
    ampm={ampm}
    slots={slots}
    slotProps={slotProps}
    readOnly={readOnly}
    disabled={disabled}
    autoFocus={autoFocus}
    disableIgnoringDatePartForTimeValidation={
      disableIgnoringDatePartForTimeValidation
    }
    timeSteps={timeSteps}
    skipDisabled={skipDisabled}
    timezone={timezone}
  />
);
