import { useContext } from 'react';
import { ProjectContext } from '../contexts/project-context';

export const useProject = () => {
  const contextValue = useContext(ProjectContext);

  if (!contextValue) {
    throw new Error('Project not found');
  }

  return contextValue;
};
