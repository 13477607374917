import { Avatar } from '@allganize/ui-avatar';
import { ListItemAvatar, ListItemText } from '@allganize/ui-list';
import { HighlightedText, SelectProps } from '@allganize/ui-select';
import { Fragment } from 'react';
import { FormatOptionLabelMeta, GroupBase } from 'react-select';
import { AgentSelectOption } from './agent-select-option';
import { MultiValue } from './agent-select-slots';

export const useAgentSelect = <
  Option extends AgentSelectOption = AgentSelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Pick<
    SelectProps<Option, IsMulti, Group>,
    'getOptionDescription' | 'getOptionLabel'
  >,
): Pick<
  SelectProps<Option, IsMulti, Group>,
  'components' | 'formatOptionLabel'
> => {
  const { getOptionDescription, getOptionLabel } = props;

  const formatOptionLabel = (
    data: Option,
    formatOptionLabelMeta: FormatOptionLabelMeta<Option>,
  ) => {
    const label = getOptionLabel?.(data) || data.label;

    if (formatOptionLabelMeta.context === 'menu') {
      const description = getOptionDescription?.(data) || data.description;

      return (
        <Fragment>
          <ListItemAvatar>
            <Avatar size="smallest" src={data.data.avatar?.url} alt={label}>
              {label.charAt(0)}
            </Avatar>
          </ListItemAvatar>

          <ListItemText
            primary={
              <HighlightedText
                text={label}
                query={formatOptionLabelMeta.inputValue}
                options={{ insideWords: true, findAllOccurrences: true }}
              />
            }
            secondary={
              typeof description === 'string' ? (
                <HighlightedText
                  text={description}
                  query={formatOptionLabelMeta.inputValue}
                  options={{ insideWords: true, findAllOccurrences: true }}
                />
              ) : (
                description
              )
            }
          />
        </Fragment>
      );
    }

    return label;
  };

  return {
    components: {
      MultiValue,
    },
    formatOptionLabel,
  };
};
