import { timeout } from '@allganize/utils-timeout';

export class DownloadFileError extends Error {
  constructor(
    public status: number,
    public statusText: string,
    public reason: any,
  ) {
    super(statusText);
  }
}

interface DownloadLimitation {
  downloadExceedance?: number;
  downloadLimitation?: number;
}

interface DownloadResponse extends DownloadLimitation {
  result: Blob;
}

export const downloadFile = async (
  url: string,
  download?: string,
  options?: RequestInit,
): Promise<DownloadResponse> =>
  fetch(url, options)
    .then(async resp => {
      const filename = resp.headers.get('filename') || undefined;
      const downloadExceedance =
        Number.parseInt(resp.headers.get('download-exceedance') || '', 10) ||
        undefined;
      const downloadLimitation =
        Number.parseInt(resp.headers.get('download-limitation') || '', 10) ||
        undefined;

      if (resp.ok) {
        const blob = await resp.blob();

        return {
          result: blob,
          filename,
          downloadExceedance,
          downloadLimitation,
        };
      }

      const { status, statusText } = resp;
      const body = await resp.json();
      throw new DownloadFileError(status, statusText, body.reason);
    })
    .then<DownloadResponse>(resp => {
      if ((window.navigator as any).msSaveBlob) {
        (window.navigator as any).msSaveBlob(
          resp.result,
          resp.filename || download,
        );
        return resp;
      }

      const objectUrl = URL.createObjectURL(resp.result);
      const anchorLink = document.createElement('a');
      anchorLink.style.display = 'none';
      anchorLink.href = objectUrl;
      anchorLink.download = download || 'true';
      anchorLink.click();

      return timeout(0).then(() => {
        anchorLink.remove();
        URL.revokeObjectURL(objectUrl);
        return resp;
      });
    });
