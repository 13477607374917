import { PdfPageStyles } from '@allganize/alli-document';
import { ScrollerStyles } from '@allganize/ui-scroller';
import { CssBaseline as BaseCssBaseline } from '@allganize/ui-theme';
import { Global, css } from '@emotion/react';
import { FunctionComponent } from 'react';

export const CssBaseline: FunctionComponent = () => {
  return (
    <>
      <BaseCssBaseline />

      <Global
        styles={css`
          html {
            position: relative;
          }

          html,
          body {
            margin: 0;
            min-height: 100vh;
          }
        `}
      />

      <PdfPageStyles />
      <ScrollerStyles />
    </>
  );
};
