import generateUtilityClasses from '@mui/material/generateUtilityClasses';
import { ChipBaseClassKey } from '../chip-base';

export type OutlinedChipClassKey = ChipBaseClassKey;
export type OutlinedChipClasses = Record<OutlinedChipClassKey, string>;

export const outlinedChipClasses: OutlinedChipClasses = generateUtilityClasses(
  'OutlinedChip',
  [
    'root',
    'disabled',
    'clickable',
    'deletable',
    'avatar',
    'icon',
    'label',
    'deleteIcon',
    'focusVisible',
  ],
);
