import { useEventCallback } from '@allganize/hooks';
import { IconButton, LoadingButton } from '@allganize/ui-button';
import {
  DropzoneRef,
  FileDropzone,
  fileDropzoneClasses,
} from '@allganize/ui-file-input';
import { IcAttachment, IcClose, IcError, IcUpload } from '@allganize/ui-icons';
import { InputAdornment, InputField } from '@allganize/ui-input';
import { useTheme } from '@allganize/ui-theme';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  UseDraftToolbarImageFormOptions,
  useDraftToolbarImageForm,
} from './use-draft-toolbar-image-form';

export interface DraftToolbarImageFormActions {
  setFile(file: File | null): void;
}

export interface DraftToolbarImageFormProps<T extends { url: string }>
  extends UseDraftToolbarImageFormOptions<T>,
    Omit<React.FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  actions?: React.Ref<DraftToolbarImageFormActions>;
}

interface DraftToolbarImageFormType {
  <T extends { url: string }>(
    props: React.PropsWithoutRef<DraftToolbarImageFormProps<T>> &
      React.RefAttributes<HTMLFormElement>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

export const DraftToolbarImageForm: DraftToolbarImageFormType = forwardRef(
  (props, ref) => {
    const { actions, defaultValues, validation, onSubmit, ...other } = props;
    const theme = useTheme();
    const intl = useIntl();
    const {
      form: {
        control,
        formState: { isSubmitting, isValid },
        handleSubmit,
        setValue,
        trigger,
      },
      submit,
    } = useDraftToolbarImageForm({ defaultValues, validation, onSubmit });
    const dropzoneRef = useRef<DropzoneRef>(null);

    const setFile = useEventCallback((file: File | null) => {
      if (!file) {
        setValue('type', 'url');
        setValue('file', null);
        trigger(['url']);
        return;
      }

      setValue('type', 'file');
      setValue('file', file);
      trigger(['url']);
    });

    const openFilePicker = () => {
      dropzoneRef.current?.open();
    };

    useImperativeHandle(actions, () => ({ setFile }), [setFile]);

    return (
      <form {...other} ref={ref} onSubmit={handleSubmit(submit)}>
        <div
          css={css`
            display: flex;
            gap: 8px;
            padding: 12px;
          `}
        >
          <Controller
            control={control}
            name="file"
            render={fileProps => {
              if (fileProps.field.value) {
                const {
                  field: { ref: fieldRef, ...field },
                  fieldState,
                  formState,
                } = fileProps;
                const errorMessage =
                  fieldState.error?.message || formState.errors.root?.message;

                return (
                  <FileDropzone
                    css={css`
                      border: 0;
                      background-color: transparent;

                      .${fileDropzoneClasses.dragOverlay} {
                        border: 1px dashed transparent;
                        background-color: ${theme.palette.grey[100]};
                        border-color: ${theme.palette.grayAlpha[500]};
                        color: ${theme.palette.text.primary};
                        transition: ${theme.transitions.create(
                          ['color', 'opacity', 'border-color'],
                          {
                            easing: theme.transitions.easing.easeInOut,
                            duration: theme.transitions.duration.leavingScreen,
                          },
                        )};
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: wrap;
                      }

                      &.${fileDropzoneClasses.dragActive}
                        .${fileDropzoneClasses.dragOverlay} {
                        transition: ${theme.transitions.create(
                          ['color', 'opacity', 'border-color'],
                          {
                            easing: theme.transitions.easing.easeInOut,
                            duration: theme.transitions.duration.enteringScreen,
                          },
                        )};
                      }

                      &.${fileDropzoneClasses.dragReject}
                        .${fileDropzoneClasses.dragOverlay} {
                        color: ${theme.palette.error.main};
                        border-color: ${theme.palette.error.main};
                      }
                    `}
                    dragOverlay={
                      <div>
                        <IcUpload
                          css={css`
                            vertical-align: middle;
                          `}
                        />{' '}
                        <FormattedMessage
                          id="CampaignBuild.Sidebar.TextArea.ImageButton.File"
                          defaultMessage="Select a file (png, jpg)"
                          description="Text area insert photo form file placeholder text"
                        />
                      </div>
                    }
                    accept={{
                      'image/*': validation.file.accept,
                    }}
                    dropzoneRef={dropzoneRef}
                    maxFiles={1}
                    noClick
                    inputProps={{ name: field.name }}
                    disabled={field.disabled}
                    onDropAccepted={files => {
                      const file = files[0];

                      if (!file) {
                        return;
                      }

                      setValue('type', 'file');
                      field.onChange(file);
                      trigger(['url']);
                    }}
                    onBlur={field.onBlur}
                  >
                    <InputField
                      {...field}
                      value={fileProps.field.value.name}
                      inputRef={fieldRef}
                      required
                      fullWidth
                      label={null}
                      error={fieldState.invalid}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" onClick={openFilePicker}>
                              <IcAttachment />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {errorMessage && (
                              <Tooltip color="error" title={errorMessage}>
                                <IcError color="error" />
                              </Tooltip>
                            )}

                            <IconButton
                              edge="end"
                              onClick={() => {
                                setValue('type', 'url');
                                field.onChange(null);
                                trigger(['url']);
                              }}
                            >
                              <IcClose />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FileDropzone>
                );
              }

              return (
                <Controller
                  control={control}
                  name="url"
                  render={urlProps => {
                    const {
                      field: { ref: fieldRef, ...field },
                      fieldState,
                      formState,
                    } = urlProps;
                    const errorMessage =
                      fieldState.error?.message ||
                      formState.errors.root?.message;

                    return (
                      <FileDropzone
                        css={css`
                          border: 0;
                          background-color: transparent;

                          .${fileDropzoneClasses.dragOverlay} {
                            border: 1px dashed transparent;
                            background-color: ${theme.palette.grey[100]};
                            border-color: ${theme.palette.grayAlpha[500]};
                            color: ${theme.palette.text.primary};
                            transition: ${theme.transitions.create(
                              ['color', 'opacity', 'border-color'],
                              {
                                easing: theme.transitions.easing.easeInOut,
                                duration:
                                  theme.transitions.duration.leavingScreen,
                              },
                            )};
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: wrap;
                          }

                          &.${fileDropzoneClasses.dragActive}
                            .${fileDropzoneClasses.dragOverlay} {
                            transition: ${theme.transitions.create(
                              ['color', 'opacity', 'border-color'],
                              {
                                easing: theme.transitions.easing.easeInOut,
                                duration:
                                  theme.transitions.duration.enteringScreen,
                              },
                            )};
                          }

                          &.${fileDropzoneClasses.dragReject}
                            .${fileDropzoneClasses.dragOverlay} {
                            color: ${theme.palette.error.main};
                            border-color: ${theme.palette.error.main};
                          }
                        `}
                        dragOverlay={
                          <div>
                            <IcUpload
                              css={css`
                                vertical-align: middle;
                              `}
                            />{' '}
                            <FormattedMessage
                              id="CampaignBuild.Sidebar.TextArea.ImageButton.File"
                              defaultMessage="Select a file (png, jpg)"
                              description="Text area insert photo form file placeholder text"
                            />
                          </div>
                        }
                        accept={{
                          'image/*': validation.file.accept,
                        }}
                        dropzoneRef={dropzoneRef}
                        maxFiles={1}
                        noClick
                        inputProps={{ name: fileProps.field.name }}
                        disabled={fileProps.field.disabled}
                        onDropAccepted={files => {
                          const file = files[0];

                          if (!file) {
                            return;
                          }

                          setValue('type', 'file');
                          fileProps.field.onChange(file);
                          trigger(['url']);
                        }}
                        onBlur={fileProps.field.onBlur}
                      >
                        <InputField
                          {...field}
                          inputRef={fieldRef}
                          autoFocus
                          required
                          fullWidth
                          label={null}
                          error={fieldState.invalid}
                          placeholder={intl.formatMessage({
                            id: 'CampaignBuild.Sidebar.TextArea.ImageButton.URL',
                            defaultMessage: 'URL',
                            description:
                              'Text area insert photo form url placeholder text',
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  edge="start"
                                  onClick={openFilePicker}
                                >
                                  <IcAttachment />
                                </IconButton>
                              </InputAdornment>
                            ),
                            endAdornment: errorMessage && (
                              <InputAdornment position="end">
                                <Tooltip color="error" title={errorMessage}>
                                  <IcError color="error" />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FileDropzone>
                    );
                  }}
                />
              );
            }}
          />

          <LoadingButton
            variant="filled"
            color="primary"
            type="submit"
            size="large"
            loading={isSubmitting}
            disabled={!isValid}
          >
            <FormattedMessage
              id="CampaignBuild.Sidebar.TextArea.ImageButton.SubmitButton"
              defaultMessage="Submit"
              description="Text area insert video form submit button text"
            />
          </LoadingButton>
        </div>
      </form>
    );
  },
);
