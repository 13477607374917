import { Global, css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useTheme } from './theme/use-theme';

export const CssBaseline: FunctionComponent = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        :root {
          --html-font-size: ${theme.typography.htmlFontSize}px;
          --color-text-primary: ${theme.palette.text.primary};
          --color-text-secondary: ${theme.palette.text.secondary};
          --color-text-disabled: ${theme.palette.text.disabled};
          --color-background-default: ${theme.palette.background.default};
          --color-background-paper: ${theme.palette.background.paper};
          --color-divider: ${theme.palette.divider};
          --color-primary-main: ${theme.palette.primary.main};
          --color-success-main: ${theme.palette.success.main};
          --color-warning-main: ${theme.palette.warning.main};
          --color-error-main: ${theme.palette.error.main};
        }

        html {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          box-sizing: border-box;
          -webkit-text-size-adjust: 100%;
          font-size: var(--html-font-size, ${theme.typography.htmlFontSize}px);
        }

        *,
        *::before,
        *::after {
          box-sizing: inherit;
        }

        strong,
        b {
          font-weight: ${theme.typography.fontWeightBold};
        }

        body {
          margin: 0;
          color: var(--color-text-primary, ${theme.palette.text.primary});
          ${theme.typography.body14}
          background-color: var(--color-background-default, ${theme.palette
            .background.default});

          @media print {
            background-color: ${theme.palette.common.white};
          }

          &::backdrop {
            background-color: var(
              --color-background-default,
              ${theme.palette.background.default}
            );
          }
        }
      `}
    />
  );
};
