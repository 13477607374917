import { ExtendList, List } from '@allganize/ui-list';
import { ClassNames } from '@emotion/react';
import { forwardRef } from 'react';
import { fileListItemClasses } from '../file-list-item';
import { fileListClasses } from './file-list-classes';
import { FileListTypeMap } from './file-list-type-map';

// @ts-expect-error overridable component
export const FileList: ExtendList<FileListTypeMap> = forwardRef(
  (props, ref) => {
    const { classes, ...other } = props;

    return (
      <ClassNames>
        {({ css, cx }) => (
          <List
            data-testid="file-list"
            {...other}
            ref={ref}
            classes={{
              ...classes,
              root: cx(
                css`
                  > .${fileListItemClasses.root} {
                    margin-top: 4px;

                    &:first-of-type {
                      margin-top: 0;
                    }
                  }
                `,
                fileListClasses.root,
                classes?.root,
              ),
              subheader: cx(fileListClasses.subheader, classes?.subheader),
            }}
          />
        )}
      </ClassNames>
    );
  },
);
