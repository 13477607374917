import {
  SvgIcon,
  IcBalance,
  IcArticle,
  IcFormat,
  IcSecurity,
  IcPostAdd,
  IcFolderZip,
  IcAutoMode,
  IcFace,
  IcFactCheck,
  IcCake,
  IcDomain,
  IcSchool,
  IcTravelExplore,
  IcFavorite,
  IcFitbit,
  IcCalendar,
  IcAnalytics,
  IcBusiness,
  IcOutgoingMail,
  IcDocumentSearch,
  IcFile,
  IcAutoGraph,
  IcSendMessage,
  IcUpdate,
  IcEdit,
  IcAutomation,
  IcManageSearch,
  IcPeople,
  IcEmojiSmile,
  IcVariable,
  IcOptimization,
  IcLightBulb,
  IcSaving,
  IcRoomPreference,
  IcPodcast,
} from '@allganize/ui-icons';

import {
  IcAddLocation,
  IcAlarm,
  IcAutoCreate,
  IcBadge,
  IcCampaign,
  IcPalette,
  IcBus,
  IcDescription,
  IcFileDownloadDone,
  IcFlag,
  IcFlightTakeoff,
  IcGavel,
  IcGroup,
  IcHealthAndSafety,
  IcHelp,
  IcHomeWork,
  IcHub,
  IcLaptopMac,
  IcLocalLibrary,
  IcLocalMall,
  IcTag,
  IcAccount,
  IcNotification,
  IcPerson,
  IcRocketLaunch,
  IcShoppingCart,
  IcSignpost,
  IcSource,
  IcSteppers,
  IcVerified,
  IcWysiwyg,
  IcYard,
} from './icons';

export const categoryIconMap = {
  'ic-add-location': IcAddLocation,
  'ic-alarm': IcAlarm,
  'ic-auto-create': IcAutoCreate,
  'ic-auto-graph': IcAutoGraph,
  'ic-badge': IcBadge,
  'ic-business': IcBusiness,
  'ic-campaign': IcCampaign,
  'ic-palette': IcPalette,
  'ic-bus': IcBus,
  'ic-description': IcDescription,
  'ic-file-download-done': IcFileDownloadDone,
  'ic-flag': IcFlag,
  'ic-flight-takeoff': IcFlightTakeoff,
  'ic-gavel': IcGavel,
  'ic-group': IcGroup,
  'ic-health-and-safety': IcHealthAndSafety,
  'ic-help': IcHelp,
  'ic-home-work': IcHomeWork,
  'ic-hub': IcHub,
  'ic-laptop-mac': IcLaptopMac,
  'ic-local-library': IcLocalLibrary,
  'ic-local-mall': IcLocalMall,
  'ic-tag': IcTag,
  'ic-account': IcAccount,
  'ic-notification': IcNotification,
  'ic-person': IcPerson,
  'ic-security': IcSecurity,
  'ic-rocket-launch': IcRocketLaunch,
  'ic-shopping-cart': IcShoppingCart,
  'ic-signpost': IcSignpost,
  'ic-source': IcSource,
  'ic-steppers': IcSteppers,
  'ic-verified': IcVerified,
  'ic-wysiwyg': IcWysiwyg,
  'ic-yard': IcYard,
  'ic-favorite': IcFavorite,
} satisfies Record<string, typeof SvgIcon>;

export const informationIconMap = {
  'ic-balance': IcBalance,
  'ic-variable': IcVariable,
  'ic-article': IcArticle,
  'ic-analytics': IcAnalytics,
  'ic-auto-mode': IcAutoMode,
  'ic-cake': IcCake,
  'ic-calendar': IcCalendar,
  'ic-update': IcUpdate,
  'ic-business': IcBusiness,
  'ic-document-search': IcDocumentSearch,
  'ic-domain': IcDomain,
  'ic-face': IcFace,
  'ic-fact-check': IcFactCheck,
  'ic-favorite': IcFavorite,
  'ic-fitbit': IcFitbit,
  'ic-folder-zip': IcFolderZip,
  'ic-format': IcFormat,
  'ic-people': IcPeople,
  'ic-send-message': IcSendMessage,
  'ic-file': IcFile,
  'ic-auto-graph': IcAutoGraph,
  'ic-light-bulb': IcLightBulb,
  'ic-manage-search': IcManageSearch,
  'ic-outgoing-mail': IcOutgoingMail,
  'ic-edit': IcEdit,
  'ic-podcast': IcPodcast,
  'ic-post-add': IcPostAdd,
  'ic-room-preference': IcRoomPreference,
  'ic-saving': IcSaving,
  'ic-school': IcSchool,
  'ic-security': IcSecurity,
  'ic-emoji-smile': IcEmojiSmile,
  'ic-automation': IcAutomation,
  'ic-travel-explore': IcTravelExplore,
  'ic-optimization': IcOptimization,
} satisfies Record<string, typeof SvgIcon>;
