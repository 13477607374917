import { Types } from '@allganize/alli-sdk-interfaces';
import {
  ChatFormIntentSelector,
  ChatFormIntentSelectorProps,
  IntentSelectOption,
} from '@allganize/alli-sdk-ui';
import { IframeContext } from '@allganize/react-iframe';
import {
  IcAppMarket,
  IcDocumentSearch,
  IcFace,
  IcGenerate,
} from '@allganize/ui-icons';
import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Control, useController } from 'react-hook-form';
import { defineMessage } from 'react-intl';
import { ChatFormValues } from '../hooks/use-chat-form';
import { useLocation } from 'react-router-dom';

const MemoizedIntentSelector = memo(ChatFormIntentSelector);

export type IntentValueType = Types.IntentClassificationIntents | 'default';
type SelectOption = Omit<IntentSelectOption, 'value'> & {
  value: IntentValueType;
};

const assistantIntentSelectOptions: Record<string, SelectOption> = {
  default: {
    label: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.default',
      defaultMessage: 'Ai Mode',
      description: 'Intent select default label text',
    }),
    description: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.default.description',
      defaultMessage: 'Automatically choose the best response for you',
      description: 'Intent select default description text',
    }),
    value: 'default',
    icon: <IcGenerate />,
  },
  SEARCH: {
    label: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.SEARCH',
      defaultMessage: 'Search',
      description: 'Intent select search label text',
    }),
    description: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.SEARCH.description',
      defaultMessage: 'Search from uploaded documents or websites',
      description: 'Intent select search description text',
    }),
    value: Types.IntentClassificationIntents.SEARCH,
    icon: <IcDocumentSearch />,
  },
  RECOMMEND_MORE_APPS: {
    label: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.RECOMMEND_MORE_APPS',
      defaultMessage: 'App Recommendation',
      description: 'Intent select app recommend label text',
    }),
    description: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.RECOMMEND_MORE_APPS.description',
      defaultMessage: 'Recommend the best app for your tasks',
      description: 'Intent select app recommend description text',
    }),
    value: Types.IntentClassificationIntents.RECOMMEND_MORE_APPS,
    icon: <IcAppMarket />,
  },
  SMALLTALK: {
    label: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.SMALLTALK',
      defaultMessage: 'Talk',
      description: 'Intent select smalltalk label text',
    }),
    description: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.SMALLTALK.description',
      defaultMessage: 'Talk freely with the AI assistant',
      description: 'Intent select smalltalk description text',
    }),
    value: Types.IntentClassificationIntents.SMALLTALK,
    icon: <IcFace />,
  },
} satisfies Partial<
  Record<Types.IntentClassificationIntents | 'default', SelectOption>
>;
const options = Object.values(assistantIntentSelectOptions);

interface AssistantIntentSelectProps
  extends Omit<
    ChatFormIntentSelectorProps,
    'options' | 'value' | 'defaultValue' | 'onChange'
  > {
  control: Control<ChatFormValues>;
  onChange?: (value: IntentValueType) => void;
}

export const AssistantIntentSelect = forwardRef<
  HTMLButtonElement,
  AssistantIntentSelectProps
>(({ control, onChange, ...others }, ref) => {
  const { state } = useLocation();
  const { field } = useController({
    control,
    name: 'intent',
    defaultValue: state?.intent,
  });
  const messengerIframe = useContext(IframeContext);
  const [minimize, setMinimize] = useState(
    messengerIframe.window.innerWidth < 400,
  );
  const calculateMinimize = useCallback(() => {
    setMinimize(messengerIframe.window.innerWidth < 400);
  }, [messengerIframe]);
  const handleChange = useCallback(
    (value: string | number | null) => {
      field.onChange(value);
      onChange?.((value ?? 'default') as IntentValueType);
    },
    [field, onChange],
  );

  useEffect(() => {
    messengerIframe.window.addEventListener('resize', calculateMinimize);
    return () => {
      messengerIframe.window.removeEventListener('resize', calculateMinimize);
    };
  }, [calculateMinimize, messengerIframe]);

  const selectedIntent =
    field.value && field.value in assistantIntentSelectOptions
      ? assistantIntentSelectOptions[field.value]
      : assistantIntentSelectOptions.default;

  return (
    <MemoizedIntentSelector
      menuProps={{
        anchorOrigin: {
          vertical: -10,
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
      tooltipProps={{
        placement: 'top-start',
      }}
      {...field}
      {...others}
      onChange={handleChange}
      value={selectedIntent}
      options={options}
      minimize={minimize}
      ref={ref}
    />
  );
});
