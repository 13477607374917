import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { knowledgeBaseCardContainerClasses } from './knowledge-base-card-container-classes';
import { KnowledgeBaseCardContainerProps } from './knowledge-base-card-container-type-map';

export const KnowledgeBaseCardContainer = forwardRef<
  HTMLDivElement,
  KnowledgeBaseCardContainerProps
>(({ classes, children, ...others }, ref) => {
  const theme = useTheme();
  const { breakpoints } = theme;
  return (
    <div
      {...others}
      ref={ref}
      className={clsx(
        knowledgeBaseCardContainerClasses.root,
        classes?.root,
        others.className,
      )}
      css={css`
        display: grid;
        gap: ${theme.spacing(1)};
        grid-auto-flow: row dense;
        max-height: 80px;
        overflow-y: auto;

        ${breakpoints.up(breakpoints.values.sm)} {
          grid-template-columns: repeat(2, 1fr);
        }
        ${breakpoints.up(breakpoints.values.md)} {
          grid-template-columns: repeat(3, 1fr);
        }
      `}
    >
      {children}
    </div>
  );
});
