import { OverridableComponent } from '@allganize/types';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import MuiSvgIcon from '@mui/material/SvgIcon';
import { forwardRef } from 'react';
import { SvgIconTypeMap } from './svg-icon-type-map';

// @ts-expect-error overridable component
export const SvgIcon: OverridableComponent<SvgIconTypeMap> = forwardRef(
  (props, ref) => {
    const theme = useTheme();
    const { color = 'inherit', fontSize = 'medium', ...other } = props;

    return (
      <MuiSvgIcon
        data-testid="svg-icon"
        css={[
          css`
            transition: ${theme.transitions.create('fill', {
              duration: theme.transitions.duration.shorter,
            })};
          `,
          color !== 'inherit' &&
            css`
              color: ${theme.palette[color].main};
            `,
          fontSize === 'small' &&
            css`
              font-size: ${theme.typography.pxToRem(16)};
            `,
          fontSize === 'medium' &&
            css`
              font-size: ${theme.typography.pxToRem(20)};
            `,
          fontSize === 'large' &&
            css`
              font-size: ${theme.typography.pxToRem(24)};
            `,
        ]}
        color={color}
        fontSize={fontSize}
        {...other}
        ref={ref}
      />
    );
  },
);
