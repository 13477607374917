import { useTheme } from '@allganize/ui-theme';
import { Global, css } from '@emotion/react';
import { Fragment, FunctionComponent } from 'react';
import { pdfPageClasses } from './pdf-page-classes';

export const PdfPageStyles: FunctionComponent = () => {
  const theme = useTheme();

  return (
    <Fragment>
      <Global
        styles={css`
          :root {
            --react-pdf-text-layer: 1;
          }

          .${pdfPageClasses.textLayer} {
            position: absolute;
            text-align: initial;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            line-height: 1;
            text-size-adjust: none;
            forced-color-adjust: none;

            span,
            br {
              color: transparent;
              position: absolute;
              white-space: pre;
              cursor: text;
              transform-origin: 0% 0%;
            }

            span.markedContent {
              top: 0;
              height: 0;
            }

            .highlight {
              margin: -1px;
              padding: 1px;
              background-color: rgba(180, 0, 170, 1);
              border-radius: ${theme.radius.xs}px;

              &.appended {
                position: initial;
              }

              &.begin {
                border-radius: ${theme.radius.xs}px 0 0 ${theme.radius.xs}px;
              }

              &.end {
                border-radius: 0 ${theme.radius.xs}px ${theme.radius.xs}px 0;
              }

              &.middle {
                border-radius: 0;
              }

              &.selected {
                background-color: rgba(0, 100, 0, 1);
              }
            }

            br::selection {
              background: transparent;
            }

            .endOfContent {
              display: block;
              position: absolute;
              left: 0;
              top: 100%;
              right: 0;
              bottom: 0;
              z-index: -1;
              cursor: default;
              user-select: none;

              &.active {
                top: 0;
              }
            }
          }
        `}
      />

      <Global
        styles={css`
          :root {
            --react-pdf-annotation-layer: 1;
            --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
            --input-focus-border-color: Highlight;
            --input-focus-outline: 1px solid Canvas;
            --input-unfocused-border-color: transparent;
            --input-disabled-border-color: transparent;
            --input-hover-border-color: black;
          }

          @media (forced-colors: active) {
            :root {
              --input-focus-border-color: CanvasText;
              --input-unfocused-border-color: ActiveText;
              --input-disabled-border-color: GrayText;
              --input-hover-border-color: Highlight;
            }

            .${pdfPageClasses.annotationLayer} {
              .textWidgetAnnotation input:required,
              .textWidgetAnnotation textarea:required,
              .choiceWidgetAnnotation select:required,
              .buttonWidgetAnnotation.checkBox input:required,
              .buttonWidgetAnnotation.radioButton input:required {
                outline: 1.5px solid selectedItem;
              }
            }
          }

          .${pdfPageClasses.annotationLayer} {
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            transform-origin: 0 0;
            --scale-factor: 1;

            section {
              position: absolute;
              text-align: initial;
              pointer-events: auto;
              box-sizing: border-box;
              transform-origin: 0 0;
            }

            .linkAnnotation > a,
            .buttonWidgetAnnotation.pushButton > a {
              position: absolute;
              font-size: 1em;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }

            .buttonWidgetAnnotation.pushButton > canvas {
              width: 100%;
              height: 100%;
            }

            .linkAnnotation > a:hover,
            .buttonWidgetAnnotation.pushButton > a:hover {
              opacity: 0.2;
              background: rgba(255, 255, 0, 1);
              box-shadow: 0 2px 10px rgba(255, 255, 0, 1);
            }

            .textAnnotation img {
              position: absolute;
              cursor: pointer;
              width: 100%;
              height: 100%;
            }

            .textWidgetAnnotation input,
            .textWidgetAnnotation textarea,
            .choiceWidgetAnnotation select,
            .buttonWidgetAnnotation.checkBox input,
            .buttonWidgetAnnotation.radioButton input {
              background-image: var(--annotation-unfocused-field-background);
              border: 2px solid var(--input-unfocused-border-color);
              box-sizing: border-box;
              font: calc(9px * var(--scale-factor)) sans-serif;
              height: 100%;
              margin: 0;
              vertical-align: top;
              width: 100%;
            }

            .textWidgetAnnotation input:required,
            .textWidgetAnnotation textarea:required,
            .choiceWidgetAnnotation select:required,
            .buttonWidgetAnnotation.checkBox input:required,
            .buttonWidgetAnnotation.radioButton input:required {
              outline: 1.5px solid red;
            }

            .choiceWidgetAnnotation select option {
              padding: 0;
            }

            .buttonWidgetAnnotation.radioButton input {
              border-radius: 50%;
            }

            .textWidgetAnnotation textarea {
              resize: none;
            }

            .textWidgetAnnotation input[disabled],
            .textWidgetAnnotation textarea[disabled],
            .choiceWidgetAnnotation select[disabled],
            .buttonWidgetAnnotation.checkBox input[disabled],
            .buttonWidgetAnnotation.radioButton input[disabled] {
              background: none;
              border: 2px solid var(--input-disabled-border-color);
              cursor: not-allowed;
            }

            .textWidgetAnnotation input:hover,
            .textWidgetAnnotation textarea:hover,
            .choiceWidgetAnnotation select:hover,
            .buttonWidgetAnnotation.checkBox input:hover,
            .buttonWidgetAnnotation.radioButton input:hover {
              border: 2px solid var(--input-hover-border-color);
            }

            .textWidgetAnnotation input:hover,
            .textWidgetAnnotation textarea:hover,
            .choiceWidgetAnnotation select:hover,
            .buttonWidgetAnnotation.checkBox input:hover {
              border-radius: 2px;
            }

            .textWidgetAnnotation input:focus,
            .textWidgetAnnotation textarea:focus,
            .choiceWidgetAnnotation select:focus {
              background: none;
              border: 2px solid var(--input-focus-border-color);
              border-radius: 2px;
              outline: var(--input-focus-outline);
            }

            .buttonWidgetAnnotation.checkBox :focus,
            .buttonWidgetAnnotation.radioButton :focus {
              background-image: none;
              background-color: transparent;
            }

            .buttonWidgetAnnotation.checkBox :focus {
              border: 2px solid var(--input-focus-border-color);
              border-radius: 2px;
              outline: var(--input-focus-outline);
            }

            .buttonWidgetAnnotation.radioButton :focus {
              border: 2px solid var(--input-focus-border-color);
              outline: var(--input-focus-outline);
            }

            .buttonWidgetAnnotation.checkBox input:checked:before,
            .buttonWidgetAnnotation.checkBox input:checked:after,
            .buttonWidgetAnnotation.radioButton input:checked:before {
              background-color: CanvasText;
              content: '';
              display: block;
              position: absolute;
            }

            .buttonWidgetAnnotation.checkBox input:checked:before,
            .buttonWidgetAnnotation.checkBox input:checked:after {
              height: 80%;
              left: 45%;
              width: 1px;
            }

            .buttonWidgetAnnotation.checkBox input:checked:before {
              transform: rotate(45deg);
            }

            .buttonWidgetAnnotation.checkBox input:checked:after {
              transform: rotate(-45deg);
            }

            .buttonWidgetAnnotation.radioButton input:checked:before {
              border-radius: 50%;
              height: 50%;
              left: 30%;
              top: 20%;
              width: 50%;
            }

            .textWidgetAnnotation input.comb {
              font-family: monospace;
              padding-left: 2px;
              padding-right: 0;
            }

            .textWidgetAnnotation input.comb:focus {
              /*
               * Letter spacing is placed on the right side of each character. Hence, the
               * letter spacing of the last character may be placed outside the visible
               * area, causing horizontal scrolling. We avoid this by extending the width
               * when the element has focus and revert this when it loses focus.
               */
              width: 103%;
            }

            .buttonWidgetAnnotation.checkBox input,
            .buttonWidgetAnnotation.radioButton input {
              appearance: none;
            }

            .popupTriggerArea {
              height: 100%;
              width: 100%;
            }

            .popupWrapper {
              position: absolute;
              font-size: calc(9px * var(--scale-factor));
              width: 100%;
              min-width: calc(180px * var(--scale-factor));
              pointer-events: none;
            }

            .popup {
              position: absolute;
              max-width: calc(180px * var(--scale-factor));
              background-color: rgba(255, 255, 153, 1);
              box-shadow: 0 calc(2px * var(--scale-factor))
                calc(5px * var(--scale-factor)) rgba(136, 136, 136, 1);
              border-radius: calc(2px * var(--scale-factor));
              padding: calc(6px * var(--scale-factor));
              margin-left: calc(5px * var(--scale-factor));
              cursor: pointer;
              font: message-box;
              white-space: normal;
              word-wrap: break-word;
              pointer-events: auto;

              > * {
                font-size: calc(9px * var(--scale-factor));
              }

              h1 {
                display: inline-block;
              }
            }

            .popupDate {
              display: inline-block;
              margin-left: calc(5px * var(--scale-factor));
            }

            .popupContent {
              border-top: 1px solid rgba(51, 51, 51, 1);
              margin-top: calc(2px * var(--scale-factor));
              padding-top: calc(2px * var(--scale-factor));
            }

            .richText > * {
              white-space: pre-wrap;
              font-size: calc(9px * var(--scale-factor));
            }

            .highlightAnnotation,
            .underlineAnnotation,
            .squigglyAnnotation,
            .strikeoutAnnotation,
            .freeTextAnnotation,
            .lineAnnotation svg line,
            .squareAnnotation svg rect,
            .circleAnnotation svg ellipse,
            .polylineAnnotation svg polyline,
            .polygonAnnotation svg polygon,
            .caretAnnotation,
            .inkAnnotation svg polyline,
            .stampAnnotation,
            .fileAttachmentAnnotation {
              cursor: pointer;
            }

            section svg {
              position: absolute;
              width: 100%;
              height: 100%;
            }

            .annotationTextContent {
              position: absolute;
              width: 100%;
              height: 100%;
              opacity: 0;
              color: transparent;
              user-select: none;
              pointer-events: none;
            }

            .annotationTextContent span {
              width: 100%;
              display: inline-block;
            }
          }
        `}
      />
    </Fragment>
  );
};
