import { css } from '@emotion/react';
import MuiList from '@mui/material/List';
import { forwardRef } from 'react';
import { ExtendList, ListTypeMap } from './list-type-map';

// @ts-expect-error overridable component
export const List: ExtendList<ListTypeMap> = forwardRef((props, ref) => {
  const { disablePadding, subheader, ...other } = props;

  return (
    <MuiList
      data-testid="list"
      css={[
        css`
          padding: 8px 0;
        `,
        disablePadding &&
          css`
            padding: 0;
          `,
        subheader &&
          css`
            padding-top: 0;
          `,
      ]}
      disablePadding={disablePadding}
      subheader={subheader}
      {...other}
      ref={ref}
    />
  );
});
