import { defaultTheme } from '@allganize/ui-theme';
import { rgba } from 'polished';
import { blendColorWithWhite } from '../utils/blend-color-with-white';

export const defaultColor = blendColorWithWhite(
  defaultTheme.palette.foreground.default,
);

export const colors = [
  rgba('#ff0400', 0.5),
  rgba('#ff9900', 0.5),
  rgba('#ffff01', 0.5),
  rgba('#00ff00', 0.5),
  rgba('#01ffff', 0.5),
  rgba('#2074ff', 0.5),
  rgba('#9900ff', 0.5),
  defaultTheme.palette.foreground.primary.default,

  '#e50300',
  '#e58900',
  '#e5e500',
  '#00e500',
  '#01e5e5',
  '#1c69e5',
  '#8900e5',
  '#FF5777',

  '#7f0100',
  '#7f4d00',
  '#7f8000',
  '#007f00',
  '#007f7f',
  '#0f397f',
  '#4c007f',
  defaultTheme.palette.foreground.information,

  defaultColor,
  defaultTheme.palette.grayAlpha[800],
  defaultTheme.palette.grayAlpha[700],
  defaultTheme.palette.grayAlpha[600],
  defaultTheme.palette.grayAlpha[500],
  defaultTheme.palette.grayAlpha[400],
  defaultTheme.palette.grayAlpha[300],
  defaultTheme.palette.common.white,
].map(blendColorWithWhite);
