import { DraftImageBase } from '@allganize/draft-image-plugin';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { ReactMarkdownProps } from 'react-markdown/lib/ast-to-react';
import { markdownClasses } from '../markdown/markdown-classes';

const parseImageSize = (
  size: number | string | undefined,
): number | undefined => {
  if (typeof size === 'number') {
    return size;
  }

  if (typeof size === 'undefined') {
    return size;
  }

  const parsed = Number.parseFloat(size);

  if (Number.isNaN(parsed)) {
    return undefined;
  }

  if (`${parsed}` !== size) {
    return undefined;
  }

  if (`${parsed}px` !== size) {
    return undefined;
  }

  return parsed;
};

export const Image = forwardRef<
  HTMLImageElement,
  React.ImgHTMLAttributes<HTMLImageElement> & ReactMarkdownProps
>((props, ref) => {
  const { node, sourcePosition, height, index, siblingCount, width, ...other } =
    props;

  return (
    <DraftImageBase
      {...other}
      imageRef={ref}
      preserveRatio
      width={parseImageSize(width)}
      height={parseImageSize(height)}
      className={clsx(markdownClasses.image, other.className)}
    />
  );
});
