import { InputField } from '@allganize/ui-input';
import { FunctionComponent } from 'react';
import { NumericFormat } from 'react-number-format';
import { NumberFieldProps } from './number-field-type-map';

export const NumberField: FunctionComponent<NumberFieldProps> = props => {
  return (
    <NumericFormat displayType="input" {...props} customInput={InputField} />
  );
};
