import { useTheme } from '@allganize/ui-theme';
import MuiSlide from '@mui/material/Slide';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import { forwardRef } from 'react';
import { SlideProps } from './slide-type-map';

export const Slide = forwardRef<unknown, SlideProps>((props, ref) => {
  const theme = useTheme();
  const muiTheme = useMuiTheme();

  return (
    <ThemeProvider theme={{ ...muiTheme, transitions: theme.transitions }}>
      <MuiSlide {...props} ref={ref} />
    </ThemeProvider>
  );
});
