export const purple = {
  50: 'hsla(263, 100%, 98%, 1)',
  100: 'hsla(263, 100%, 96%, 1)',
  200: 'hsla(263, 100%, 86%, 1)',
  300: 'hsla(263, 100%, 76%, 1)',
  400: 'hsla(263, 100%, 66%, 1)',
  500: 'hsla(263, 100%, 56%, 1)',
  600: 'hsla(263, 100%, 46%, 1)',
  700: 'hsla(263, 100%, 36%, 1)',
  800: 'hsla(263, 100%, 26%, 1)',
  900: 'hsla(263, 100%, 16%, 1)',
};
