import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type RecommendedItemClassKey =
  | 'root'
  | 'imageContainer'
  | 'image'
  | 'contentContainer'
  | 'primary'
  | 'secondary'
  | 'tertiary';
export type RecommendedItemClasses = Record<RecommendedItemClassKey, string>;

export const recommendedItemClasses: RecommendedItemClasses =
  generateUtilityClasses('RecommendedItem', [
    'root',
    'imageContainer',
    'image',
    'contentContainer',
    'primary',
    'secondary',
    'tertiary',
  ]);
