import { Breakpoints } from '@mui/material/styles';
import { Mixins, MixinsOptions } from './mixins';

export const createMixins = (
  breakpoints: Breakpoints,
  mixins: MixinsOptions,
): Mixins => {
  return {
    toolbar: {
      minHeight: 58,
    },
    ...mixins,
  };
};
