import { NetworkStatus } from '@apollo/client/core';
import { UseSuspenseQueryResult } from '@apollo/client/react';
import { createContext } from 'react';
import {
  CurrentUserQuery,
  CurrentUserQueryVariables,
} from '../graphql/queries/current-user-query';

type CurrentUserContextValue = Pick<
  UseSuspenseQueryResult<CurrentUserQuery, CurrentUserQueryVariables>,
  'data' | 'refetch' | 'networkStatus' | 'error'
>;

export const CurrentUserContext = createContext<CurrentUserContextValue>({
  data: {
    __typename: 'UserQuery',
    me: null,
  },
  error: undefined,
  networkStatus: NetworkStatus.ready,
  refetch() {
    return Promise.reject(new Error('Not initialized'));
  },
});
