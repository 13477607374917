import { DraftDecoratorComponentProps } from '@allganize/draft-input';
import { Link } from '@allganize/ui-link';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DraftAutoLink,
  DraftAutoLinkProps,
} from '../draft-auto-link-plugin/draft-auto-link';

export const DraftTooltipAutoLink = forwardRef<
  HTMLAnchorElement,
  DraftDecoratorComponentProps & DraftAutoLinkProps
>((props, ref) => {
  const { decoratedText, extractor } = props;
  const links = extractor?.(decoratedText);
  const href = links?.[0]?.url ?? undefined;

  const link = <DraftAutoLink {...props} ref={ref} />;

  if (!href) {
    return link;
  }

  return (
    <Tooltip
      title={
        <Link
          css={css`
            color: inherit;
          `}
          underline="always"
          variant="inherit"
          href={href}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage
            id="TextArea.Link.VisitLink"
            defaultMessage="Open link"
            description="Text area open link text"
          />
        </Link>
      }
    >
      {link}
    </Tooltip>
  );
});
