/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ProjectMaxKnowledgeBaseCountQueryVariables = UserQueryTypes.Exact<{
  [key: string]: never;
}>;

export type ProjectMaxKnowledgeBaseCountQuery = { __typename: 'UserQuery' } & {
  project: UserQueryTypes.Maybe<
    { __typename: 'Project' } & Pick<
      UserQueryTypes.Project,
      'id' | 'maxTrialKbFileCount' | 'trialExpiredAt'
    >
  >;
};

export const ProjectMaxKnowledgeBaseCountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectMaxKnowledgeBaseCountQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxTrialKbFileCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialExpiredAt' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectMaxKnowledgeBaseCountQuery,
  ProjectMaxKnowledgeBaseCountQueryVariables
>;
