import { forwardRef } from 'react';
import ReactSelect, { GroupBase } from 'react-select';
import { SelectProps, SelectRef } from './select-type-map';
import { useSelect } from './use-select';

interface SelectType {
  <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: React.PropsWithoutRef<SelectProps<Option, IsMulti, Group>> &
      React.RefAttributes<SelectRef<Option, IsMulti, Group>>,
  ): React.ReactNode;

  readonly $$typeof: symbol;
  displayName?: string;
}

// @ts-expect-error overridable component
export const Select: SelectType = forwardRef(function <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectProps<Option, IsMulti, Group>,
  ref: React.ForwardedRef<SelectRef<Option, IsMulti, Group>>,
) {
  const selectProps = useSelect(props);

  return <ReactSelect<Option, IsMulti, Group> {...selectProps} ref={ref} />;
});
