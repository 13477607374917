import { ContentBlock } from '@allganize/draft-input';
import { uniq } from 'lodash-es';
import { linkify } from './linkify';

export const linkifyBlock = (block: ContentBlock, offset: number) => {
  const text = block.getText();
  let textSlice = '';

  for (let i = offset; i >= 0; i -= 1) {
    const char = text.slice(i - 1, i);

    if (char.match(/\s/)) {
      break;
    }

    textSlice = char + textSlice;
  }

  const links = linkify.match(textSlice);

  if (links && links.length > 0) {
    const textSliceStart = offset - textSlice.length;
    const entityRanges: Array<{ start: number; end: number }> = [];

    block.findEntityRanges(
      character => {
        return !!character.getEntity();
      },
      (start, end) => {
        entityRanges.push({ start, end });
      },
    );

    const filtered = links
      .filter(link => {
        const linkStart = textSliceStart + link.index;
        const linkEnd = textSliceStart + link.lastIndex;

        const entityIndex = entityRanges.findIndex(range => {
          if (range.start <= linkStart && linkStart < range.end) {
            return true;
          }

          if (linkStart <= range.start && range.end <= linkEnd) {
            return true;
          }

          if (range.start < linkEnd && linkEnd <= range.end) {
            return true;
          }

          return false;
        });

        return entityIndex < 0;
      })
      .map(link => {
        const styles: string[] = [];

        for (let i = link.index; i < link.lastIndex; i += 1) {
          const style = block.getInlineStyleAt(i + textSliceStart).toArray();
          styles.push(...style);
        }

        return {
          index: link.index,
          lastIndex: link.lastIndex,
          raw: link.raw,
          schema: link.schema,
          text: link.text,
          url: link.url,
          styles: uniq(styles),
        };
      });

    if (filtered.length > 0) {
      return {
        textSlice,
        range: {
          start: textSliceStart,
          end: offset,
        },
        links: filtered,
      };
    }
  }

  return null;
};
