import { CircularProgress } from '@allganize/ui-circular-progress';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { alpha } from '@mui/material/styles';
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import {
  ChatContentBubbleClasses,
  chatContentBubbleClasses,
} from './chat-content-bubble-classes';

export interface ChatContentBubbleProps
  extends React.HTMLAttributes<HTMLDivElement> {
  align: 'left' | 'right';
  color?: 'default' | 'primary';
  classes?: Partial<ChatContentBubbleClasses>;
  loading?: boolean;
}

export const ChatContentBubble = forwardRef<
  HTMLDivElement,
  ChatContentBubbleProps
>((props, ref) => {
  const {
    align,
    children,
    classes,
    color = 'default',
    loading = false,
    ...other
  } = props;
  const theme = useTheme();

  return (
    <div
      data-testid="chat-content-bubble"
      css={[
        css`
          position: relative;
          padding: 12px 16px;
          border-radius: ${theme.radius.md}px;
          max-width: 1000px;
        `,
        color === 'default' &&
          css`
            background-color: ${theme.palette.grey[50]};
            color: ${theme.palette.text.primary};
          `,
        color === 'primary' &&
          css`
            background-color: ${theme.palette.primary.main};
            color: ${theme.palette.primary.contrastText};
          `,
        align === 'left' &&
          css`
            border-bottom-left-radius: 0;
          `,
        align === 'right' &&
          css`
            border-bottom-right-radius: 0;
          `,
        loading &&
          css`
            ${color === 'default' &&
            css`
              background-color: ${alpha(theme.palette.grey[50], 0.2)};
            `}

            ${color === 'primary' &&
            css`
              background-color: ${alpha(theme.palette.primary.main, 0.2)};
            `}
          `,
      ]}
      {...other}
      ref={ref}
      className={clsx(
        chatContentBubbleClasses.root,
        {
          [chatContentBubbleClasses.colorDefault]: color === 'default',
          [chatContentBubbleClasses.colorPrimary]: color === 'primary',
          [chatContentBubbleClasses.alignLeft]: align === 'left',
          [chatContentBubbleClasses.alignRight]: align === 'right',
          [chatContentBubbleClasses.loading]: loading,
        },
        classes?.root,
        {
          [classes?.colorDefault ?? '']: color === 'default',
          [classes?.colorPrimary ?? '']: color === 'primary',
          [classes?.alignLeft ?? '']: align === 'left',
          [classes?.alignRight ?? '']: align === 'right',
          [classes?.loading ?? '']: loading,
        },
        other.className,
      )}
    >
      <div
        css={
          loading &&
          css`
            opacity: 0.2;
            pointer-events: none;
          `
        }
        tabIndex={0}
      >
        {children}
      </div>

      {loading && (
        <div
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 0;
          `}
          className={clsx(
            chatContentBubbleClasses.circularProgress,
            classes?.circularProgress,
          )}
        >
          <CircularProgress size="sm" />
        </div>
      )}
    </div>
  );
});
