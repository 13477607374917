import { Carousel, CarouselRef, carouselClasses } from '@allganize/ui-carousel';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { ariaLabels } from '../aria-labels';
import { uaParser } from '../utils/ua-parser';
import { recommendationCarouselClasses } from './recommendation-carousel-classes';
import { RecommendationCarouselProps } from './recommendation-carousel-type-map';

const CAROUSEL_BUTTON_AREA_WIDTH = 82;

export const RecommendationCarousel = forwardRef<
  CarouselRef,
  RecommendationCarouselProps
>(({ classes, title, children, ...other }, ref) => {
  const intl = useIntl();

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      {title && (
        <div
          css={css`
            position: absolute;
            top: 6px;
            left: 16px;
            width: calc(100% - 16px - ${CAROUSEL_BUTTON_AREA_WIDTH}px);
          `}
        >
          {title}
        </div>
      )}
      <Carousel
        data-testid="recommendation-carousel"
        css={[
          uaParser.hasCarouselHeightBug &&
            css`
              .${carouselClasses.slide} {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: flex-start;

                > div {
                  flex-grow: 1;
                  display: flex;
                  flex-direction: column;
                  align-items: stretch;
                  justify-content: flex-start;
                }
              }
            `,
        ]}
        variableWidth
        arrowProps={{
          next: {
            'aria-label': intl.formatMessage(ariaLabels['next-button']),
          },
          prev: {
            'aria-label': intl.formatMessage(ariaLabels['prev-button']),
          },
        }}
        {...other}
        ref={ref}
        className={clsx(
          recommendationCarouselClasses.root,
          classes?.root,
          other.className,
        )}
      >
        {children}
      </Carousel>
    </div>
  );
});
