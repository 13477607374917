import { Avatar } from '@allganize/ui-avatar';
import { components } from '@allganize/ui-select';
import { GroupBase, MultiValueProps } from 'react-select';
import { AgentSelectOption } from './agent-select-option';

export const MultiValue = <
  Option extends AgentSelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: MultiValueProps<Option, IsMulti, Group>,
) => {
  return (
    <components.MultiValue<Option, IsMulti, Group>
      {...props}
      avatar={
        <Avatar
          size="smallest"
          src={props.data.data.avatar?.url}
          alt={typeof props.children === 'string' ? props.children : undefined}
        >
          {typeof props.children === 'string' && props.children.charAt(0)}
        </Avatar>
      }
    />
  );
};
