import { forwardRef } from 'react';
import { PickerViewRendererLookup } from '../internals/hooks/use-picker';
import { useStaticPicker } from '../internals/hooks/use-static-picker';
import { validateTime } from '../internals/utils/validation/validate-time';
import { singleItemValueManager } from '../internals/utils/value-managers';
import { TimeView } from '../models';
import { useTimePickerDefaultizedProps } from '../time-picker/time-picker.shared';
import { renderTimeViewClock } from '../time-view-renderers';
import { StaticTimePickerProps } from './static-time-picker-type-map';

export const StaticTimePicker = forwardRef<
  HTMLDivElement,
  StaticTimePickerProps
>((inProps, ref) => {
  const defaultizedProps = useTimePickerDefaultizedProps<
    TimeView,
    StaticTimePickerProps
  >(inProps, 'MuiStaticTimePicker');

  const displayStaticWrapperAs =
    defaultizedProps.displayStaticWrapperAs ?? 'mobile';
  const ampmInClock =
    defaultizedProps.ampmInClock ?? displayStaticWrapperAs === 'desktop';

  const viewRenderers: PickerViewRendererLookup<
    Date | null,
    TimeView,
    any,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  > = {
    hours: renderTimeViewClock,
    minutes: renderTimeViewClock,
    seconds: renderTimeViewClock,
    ...defaultizedProps.viewRenderers,
  };

  // Props with the default values specific to the static variant
  const props = {
    ...defaultizedProps,
    viewRenderers,
    displayStaticWrapperAs,
    ampmInClock,
    slotProps: {
      ...defaultizedProps.slotProps,
      toolbar: {
        hidden: displayStaticWrapperAs === 'desktop',
        ampmInClock,
        ...defaultizedProps.slotProps?.toolbar,
      },
    },
  };

  const { renderPicker } = useStaticPicker<TimeView, typeof props>({
    props,
    valueManager: singleItemValueManager,
    valueType: 'time',
    validator: validateTime,
    ref,
  });

  return renderPicker();
});
