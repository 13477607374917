import { AgentSelectContextValue } from '@allganize/alli-sdk-chat';
import { useQuery } from '@apollo/client/react';
import { ChatAgentsQueryDocument } from '../graphql/queries/chat-agents-query';

export const useAgentSelect = (): AgentSelectContextValue => {
  const { data, loading } = useQuery(ChatAgentsQueryDocument);

  return {
    loading,
    options: data?.agents ?? [],
  };
};
