import { Button, ButtonProps } from '@allganize/ui-button';
import { IcGenerate, IcStopGenerate } from '@allganize/ui-icons';
import { FunctionComponent, useContext, useMemo } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import {
  getGenerateInput,
  SingleActionFormValues,
} from './single-action-form-values';
import { OutputContext } from '../contexts/output-context';

interface SubmitButtonProps extends ButtonProps {
  disabled?: boolean;
  handleSubmit: UseFormHandleSubmit<SingleActionFormValues>;
}

export const SubmitButton: FunctionComponent<SubmitButtonProps> = ({
  disabled,
  handleSubmit,
  ...others
}) => {
  const { called, loading, startGenerate, stopGenerate, generateDisabled } =
    useContext(OutputContext);

  const submitType = useMemo(
    () => (!called ? 'GENERATE' : loading ? 'STOP_GENERATE' : 'REGENERATE'),
    [called, loading],
  );

  return (
    <Button
      type="submit"
      onClick={handleSubmit(formValues => {
        if (submitType === 'STOP_GENERATE') {
          stopGenerate();
        } else {
          startGenerate(getGenerateInput(formValues));
        }
      })}
      variant="filled"
      color={submitType === 'STOP_GENERATE' ? 'default' : 'primary'}
      startIcon={
        submitType === 'STOP_GENERATE' ? <IcStopGenerate /> : <IcGenerate />
      }
      size="large"
      disabled={disabled || generateDisabled}
      {...others}
    >
      {submitType === 'GENERATE' && (
        <FormattedMessage
          id="single-action.form.generate"
          defaultMessage="Generate"
          description="single action form generate button text"
        />
      )}
      {submitType === 'REGENERATE' && (
        <FormattedMessage
          id="single-action.form.regenerate"
          defaultMessage="Regenerate"
          description="single action form regenerate button text"
        />
      )}
      {submitType === 'STOP_GENERATE' && (
        <FormattedMessage
          id="single-action.form.stop-generate"
          defaultMessage="Stop Generate"
          description="single action form stop generate button text"
        />
      )}
    </Button>
  );
};
