import { IconButton } from '@allganize/ui-button';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { IcClose } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import clsx from 'clsx';

import { knowledgeBaseQueueClasses } from './knowledge-base-queue-classes';
import { KnowledgeBaseQueueProps } from './knowledge-base-queue-type-map';
import { KnowledgeBaseCard } from '../knowledge-base-card';
import { KnowledgeBaseCardContainer } from '../knowledge-base-card-container';

export const KnowledgeBaseQueue = ({
  classes,
  data,
  description,
  actions,
  onDelete,
  ...others
}: KnowledgeBaseQueueProps) => {
  const theme = useTheme();

  return (
    <div
      {...others}
      className={clsx(
        knowledgeBaseQueueClasses.root,
        classes?.root,
        others.className,
      )}
      css={css`
        display: grid;
        grid-row-gap: 8px;
        padding: 12px;
        border-radius: ${theme.radius.sm}px;
        border: 1px solid ${theme.palette.border.divider.subtle};
        background: ${theme.palette.unstable_background.default};
      `}
    >
      <KnowledgeBaseCardContainer
        className={clsx(
          knowledgeBaseQueueClasses.cardContainer,
          classes?.cardContainer,
        )}
      >
        {data.map(knowledgeBase => (
          <KnowledgeBaseCard
            key={knowledgeBase.id}
            data={knowledgeBase}
            endAdornment={
              <IconButton
                size="small"
                onClick={() => onDelete?.(knowledgeBase.id)}
              >
                <IcClose />
              </IconButton>
            }
            className={clsx(knowledgeBaseQueueClasses.card, classes?.card)}
          />
        ))}
      </KnowledgeBaseCardContainer>
      {description && (
        <Text
          variant="body12"
          className={clsx(
            knowledgeBaseQueueClasses.description,
            classes?.description,
          )}
          css={css`
            color: ${theme.palette.foreground.helper};
          `}
        >
          {description}
        </Text>
      )}
      {actions && (
        <div
          className={clsx(knowledgeBaseQueueClasses.actions, classes?.actions)}
        >
          {actions}
        </div>
      )}
    </div>
  );
};
