import { Components } from 'react-markdown/lib/ast-to-react';
import { Anchor } from './anchor';
import { Blockquote } from './blockquote';
import { Cite } from './cite';
import { Code } from './code';
import { H1, H2, H3, H4, H5, H6 } from './headings';
import { HorizontalRule } from './hr';
import { Image } from './image';
import { OrderedList, UnorderedList } from './list';
import { Mark } from './mark';
import { Paragraph } from './paragraph';
import { Pre } from './pre';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from './table';
import { Video } from './video';

export const components = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  a: Anchor,
  img: Image,
  video: Video,
  mark: Mark,
  cite: Cite,
  pre: Pre,
  code: Code,
  blockquote: Blockquote,
  table: Table,
  thead: TableHead,
  tbody: TableBody,
  th: TableHeaderCell,
  tr: TableRow,
  td: TableCell,
  p: Paragraph,
  ul: UnorderedList,
  ol: OrderedList,
  hr: HorizontalRule,
} satisfies Components;
