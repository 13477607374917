import { IcCalendar } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { DEFAULT_LOCALE } from '@mui/x-date-pickers/locales/enUS';
import { PickersInputLocaleText } from './utils/pickers-locale-text-api';

export const enUS = {
  ...DEFAULT_LOCALE,
  datePickerToolbarTitle: (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <IcCalendar
        css={css`
          margin-right: 4px;
        `}
      />
      Select date
    </div>
  ),
} satisfies PickersInputLocaleText;
