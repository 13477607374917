import { forwardRef } from 'react';
import { useDateTimePickerDefaultizedProps } from '../date-time-picker/date-time-picker.shared';
import { renderDateViewCalendar } from '../date-view-renderers';
import { PickerViewRendererLookup } from '../internals/hooks/use-picker';
import { useStaticPicker } from '../internals/hooks/use-static-picker';
import { validateDateTime } from '../internals/utils/validation/validate-date-time';
import { singleItemValueManager } from '../internals/utils/value-managers';
import { DateOrTimeView } from '../models';
import { renderTimeViewClock } from '../time-view-renderers';
import { StaticDateTimePickerProps } from './static-date-time-picker-type-map';

export const StaticDateTimePicker = forwardRef<
  HTMLDivElement,
  StaticDateTimePickerProps
>((inProps, ref) => {
  const defaultizedProps = useDateTimePickerDefaultizedProps<
    DateOrTimeView,
    StaticDateTimePickerProps
  >(inProps, 'MuiStaticDateTimePicker');

  const displayStaticWrapperAs =
    defaultizedProps.displayStaticWrapperAs ?? 'mobile';
  const ampmInClock =
    defaultizedProps.ampmInClock ?? displayStaticWrapperAs === 'desktop';

  const viewRenderers: PickerViewRendererLookup<
    Date | null,
    DateOrTimeView,
    any,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  > = {
    day: renderDateViewCalendar,
    month: renderDateViewCalendar,
    year: renderDateViewCalendar,
    hours: renderTimeViewClock,
    minutes: renderTimeViewClock,
    seconds: renderTimeViewClock,
    ...defaultizedProps.viewRenderers,
  };

  // Props with the default values specific to the static variant
  const props = {
    ...defaultizedProps,
    viewRenderers,
    displayStaticWrapperAs,
    ampmInClock,
    yearsPerRow:
      defaultizedProps.yearsPerRow ??
      (displayStaticWrapperAs === 'mobile' ? 3 : 4),
    slotProps: {
      ...defaultizedProps.slotProps,
      tabs: {
        hidden: displayStaticWrapperAs === 'desktop',
        ...defaultizedProps.slotProps?.tabs,
      },
      toolbar: {
        hidden: displayStaticWrapperAs === 'desktop',
        ampmInClock,
        ...defaultizedProps.slotProps?.toolbar,
      },
    },
  };

  const { renderPicker } = useStaticPicker<DateOrTimeView, typeof props>({
    props,
    valueManager: singleItemValueManager,
    valueType: 'date-time',
    validator: validateDateTime,
    ref,
  });

  return renderPicker();
});
