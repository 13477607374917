import { AssistantDocumentUploadSource } from '@allganize/alli-sdk-chat';
import {
  KnowledgeBase,
  KnowledgeBaseQueue,
  KnowledgeBaseRecommendedQuestionList,
} from '@allganize/knowledge-base-assistant';
import { css } from '@emotion/react';
import {
  Fragment,
  HTMLAttributes,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { Control, useController } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { analytics } from '../analytics';
import { ChatFormKnowledgeBaseSearchDialog } from './chat-form-knowledge-base-search-dialog';
import { ChatFormKnowledgeBaseUploadDialog } from './chat-form-knowledge-base-upload-dialog';
import { ConversationContext } from '../conversation-detail/conversation-context';
import { ChatFormValues } from '../hooks/use-chat-form';

interface ChatFormKnowledgeBasesFieldProps
  extends HTMLAttributes<HTMLDivElement> {
  control: Control<ChatFormValues>;
  dialogType: AssistantDocumentUploadSource | null;
  onCloseDialog: () => void;
}

export const ChatFormKnowledgeBasesField = ({
  control,
  dialogType,
  onCloseDialog,
}: ChatFormKnowledgeBasesFieldProps) => {
  const {
    data: { conversation },
  } = useContext(ConversationContext);

  const { field } = useController({
    control,
    name: 'knowledgeBases',
  });

  const {
    field: { onChange: setMessage },
    formState: { isSubmitting },
  } = useController({
    control,
    name: 'message',
  });

  const { selectedKnowledgeBases, selectedKnowledgeBaseIds } = useMemo(() => {
    const selectedKnowledgeBases = isSubmitting ? [] : field.value ?? [];
    const selectedKnowledgeBaseIds: (string | number)[] = [];

    for (const knowledgeBase of selectedKnowledgeBases) {
      knowledgeBase.description = undefined;
      selectedKnowledgeBaseIds.push(knowledgeBase.id);
    }

    return {
      selectedKnowledgeBases,
      selectedKnowledgeBaseIds,
    };
  }, [field.value, isSubmitting]);

  const deleteKnowledgeBase = (knowledgeBaseId: string | number) => {
    field.onChange(
      field.value?.filter(
        knowledgeBase => knowledgeBase.id !== knowledgeBaseId,
      ),
    );
  };

  useEffect(() => {
    if (dialogType === AssistantDocumentUploadSource.KnowledgeBase) {
      analytics.track('conversation-detail::chat-input__kb-search-modal.view', {
        conversationId: conversation?.id,
        appId: conversation?.llmApp?.id,
      });
    }
  }, [dialogType, conversation]);

  return (
    <Fragment>
      {dialogType === AssistantDocumentUploadSource.KnowledgeBase && (
        <ChatFormKnowledgeBaseSearchDialog
          open={dialogType === AssistantDocumentUploadSource.KnowledgeBase}
          selectedKnowledgeBases={selectedKnowledgeBases}
          onClose={(e, reason) => {
            onCloseDialog();
            analytics.track(
              'conversation-detail::chat-input__kb-search-modal.close',
              {
                conversationId: conversation?.id,
                appId: conversation?.llmApp?.id,
                reason,
              },
            );
          }}
          onConfirm={(knowledgeBases: KnowledgeBase[]) => {
            field.onChange(knowledgeBases);
            onCloseDialog();
            analytics.track(
              'conversation-detail::chat-input__kb-search-modal.confirm',
              {
                conversationId: conversation?.id,
                appId: conversation?.llmApp?.id,
              },
            );
          }}
        />
      )}
      {dialogType === AssistantDocumentUploadSource.Computer && (
        <ChatFormKnowledgeBaseUploadDialog
          open
          onClose={onCloseDialog}
          onConfirm={(knowledgeBases: KnowledgeBase[]) => {
            field.onChange([...knowledgeBases, ...selectedKnowledgeBases]);
            onCloseDialog();
          }}
        />
      )}
      {selectedKnowledgeBases.length !== 0 && (
        <KnowledgeBaseQueue
          data={selectedKnowledgeBases}
          description={
            <FormattedMessage
              id="chat-form.knowledge-base-queue.description"
              defaultMessage="{count} {count, plural, one {file} other {files}} added"
              description="chat form knowledge base queue selected file count description"
              values={{ count: selectedKnowledgeBases.length }}
            />
          }
          actions={
            <KnowledgeBaseRecommendedQuestionList
              knowledgeBaseIds={selectedKnowledgeBaseIds}
              onClickQuestion={(message, action, index) => {
                setMessage(message);
                analytics.track(
                  'conversation-detail::kb-queue__recommended-question.click',
                  {
                    conversationId: conversation?.id,
                    appId: conversation?.llmApp?.id,
                    action,
                    index,
                  },
                );
              }}
            />
          }
          onDelete={deleteKnowledgeBase}
          css={css`
            margin-bottom: 8px;
          `}
        />
      )}
    </Fragment>
  );
};
