import { IcCalendar } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { jaJP as muiJaJP } from '@mui/x-date-pickers/locales/jaJP';
import { PickersInputLocaleText } from './utils/pickers-locale-text-api';

export const jaJP = {
  ...muiJaJP.components.MuiLocalizationProvider.defaultProps.localeText,
  datePickerToolbarTitle: (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <IcCalendar
        css={css`
          margin-right: 4px;
        `}
      />

      {muiJaJP.components.MuiLocalizationProvider.defaultProps.localeText
        .datePickerToolbarTitle || '日付を選択'}
    </div>
  ),
} satisfies PickersInputLocaleText;
