import { MenuItem, menuItemClasses } from '@allganize/ui-menu';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { MULTI_SECTION_CLOCK_SECTION_WIDTH } from '@mui/x-date-pickers/internals/constants/dimensions';
import { lighten } from 'polished';
import { forwardRef } from 'react';

// @ts-expect-error overridable component
export const DigitalClockSectionItem: typeof MenuItem = forwardRef(
  (props, ref) => {
    const theme = useTheme();

    return (
      <MenuItem
        css={css`
          margin: 2px 4px;
          width: ${MULTI_SECTION_CLOCK_SECTION_WIDTH}px;
          justify-content: center;

          &:first-of-type {
            margin-top: 4px;
          }

          &.${menuItemClasses.selected} {
            color: ${theme.palette.primary.contrastText};
            background-color: ${theme.palette.primary.main};

            &:hover,
            &.${menuItemClasses.focusVisible} {
              background-color: ${lighten(-0.1, theme.palette.primary.main)};

              @media (hover: none) {
                background-color: ${theme.palette.primary.main};
              }
            }

            &:active {
              background-color: ${lighten(-0.2, theme.palette.primary.main)};
            }

            &.${menuItemClasses.disabled} {
              color: ${theme.palette.common.white};
              background-color: ${theme.palette.action.disabledBackground};
            }
          }

          &.${menuItemClasses.disabled} {
            color: ${theme.palette.text.disabled};
            background-color: transparent;
          }
        `}
        {...props}
        ref={ref}
      />
    );
  },
);
