import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type AssistantFallbackActionCandidatesClassKey =
  | 'root'
  | 'message'
  | 'list'
  | 'item';
export type AssistantFallbackActionCandidatesClasses = Record<
  AssistantFallbackActionCandidatesClassKey,
  string
>;

export const assistantFallbackActionCandidatesClasses: AssistantFallbackActionCandidatesClasses =
  generateUtilityClasses('assistantFallbackActionCandidates', [
    'root',
    'message',
    'list',
    'item',
  ]);
