import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type ImagePageClassKey =
  | 'root'
  | 'image'
  | 'imageLoaded'
  | 'imageError'
  | 'imageLoading';
export type ImagePageClasses = Record<ImagePageClassKey, string>;

export const imagePageClasses: ImagePageClasses = generateUtilityClasses(
  'ImagePage',
  ['root', 'image', 'imageLoaded', 'imageError', 'imageLoading'],
);
