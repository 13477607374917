import { DraftDecoratorComponentProps } from '@allganize/draft-input';
import clsx from 'clsx';
import { forwardRef, useContext } from 'react';
import { DraftLinkBase, DraftLinkBaseProps } from './draft-link-base';
import { DraftLinkClasses, draftLinkClasses } from './draft-link-classes';
import { DraftLinkContext } from './draft-link-context';

export interface DraftLinkProps extends Omit<DraftLinkBaseProps, 'classes'> {
  classes?: Partial<DraftLinkClasses>;
}

export const DraftLink = forwardRef<
  HTMLAnchorElement,
  DraftDecoratorComponentProps & DraftLinkProps
>((props, ref) => {
  const {
    blockKey,
    classes,
    contentState,
    decoratedText,
    end,
    entityKey,
    offsetKey,
    start,
    ...other
  } = props;
  const entity = entityKey ? contentState.getEntity(entityKey) : null;
  const entityData = entity?.getData();
  const href = entityData?.url || undefined;
  const target = useContext(DraftLinkContext).getLinkTarget(href);

  return (
    <DraftLinkBase
      data-testid="draft-link"
      href={href}
      target={target}
      {...other}
      ref={ref}
      className={clsx(draftLinkClasses.root, classes?.root, other.className)}
    />
  );
});
