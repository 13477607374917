import { ClassNames } from '@emotion/react';
import MuiListItemAvatar from '@mui/material/ListItemAvatar';
import { forwardRef } from 'react';
import { ListItemAvatarProps } from './list-item-avatar-type-map';

export const ListItemAvatar = forwardRef<HTMLDivElement, ListItemAvatarProps>(
  (props, ref) => {
    const { classes, ...other } = props;

    return (
      <ClassNames>
        {({ css, cx }) => (
          <MuiListItemAvatar
            data-testid="list-item-avatar"
            {...other}
            ref={ref}
            classes={{
              ...classes,
              root: cx(
                css`
                  min-width: auto;
                  margin-right: 8px;
                `,
                classes?.root,
              ),
              alignItemsFlexStart: cx(
                css`
                  margin-top: 4px;
                `,
                classes?.alignItemsFlexStart,
              ),
            }}
          />
        )}
      </ClassNames>
    );
  },
);
