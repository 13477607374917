import { EditorState, RichUtils } from '@allganize/draft-input';
import { DraftLinkPlugin } from '../draft-link-plugin';

export const createLinkAtSelection = (
  editorState: EditorState,
  url: string,
) => {
  const contentState = editorState
    .getCurrentContent()
    .createEntity(DraftLinkPlugin.ENTITY_TYPE, 'MUTABLE', { url });
  const entityKey = contentState.getLastCreatedEntityKey();
  const withLink = RichUtils.toggleLink(
    editorState,
    editorState.getSelection(),
    entityKey,
  );
  return EditorState.forceSelection(withLink, editorState.getSelection());
};
