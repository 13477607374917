import { Types } from '@allganize/alli-sdk-interfaces';
import { config, Region } from '../config';

/**
 * See [ALL-13086](https://allganize2.atlassian.net/browse/ALL-13086).
 */
interface DocumentPreviewThumbnailUrlOptions {
  projectId: Types.Scalars['ID']['output'];
  knowledgeBaseId: Types.Scalars['ID']['output'];
  region: Region;
  /**
   * If no page number is specified, the first page is used.
   * @optional
   */
  pageNo?: number;
  /**
   * Width in px
   */
  width?: number;
  /**
   * Height in px
   */
  height?: number;
}

export const getDocumentPreviewThumbnailUrl = ({
  projectId,
  knowledgeBaseId,
  region,
  ...params
}: DocumentPreviewThumbnailUrlOptions) => {
  const urlParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== null && typeof value !== 'undefined') {
      urlParams.append(key, value.toString());
    }
  });

  const urlParamsString = urlParams.toString();

  return `${
    config.documentPreviewUrl[region]
  }/projects/${projectId}/knowledge_bases/${knowledgeBaseId}/thumbnail${
    urlParamsString ? `?${urlParamsString}` : ''
  }`;
};
