import { OverridableComponent } from '@allganize/types';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import MuiDivider from '@mui/material/Divider';
import { forwardRef } from 'react';
import { DividerTypeMap } from './divider-type-map';

// @ts-expect-error overridable component
export const Divider: OverridableComponent<DividerTypeMap> = forwardRef(
  (props, ref) => {
    const { classes, orientation = 'horizontal', textAlign, ...other } = props;
    const theme = useTheme();

    return (
      <ClassNames>
        {({ css, cx }) => (
          <MuiDivider
            data-testid="divider"
            orientation={orientation}
            textAlign={textAlign}
            {...other}
            ref={ref}
            classes={{
              ...classes,
              root: cx(
                css`
                  border-color: ${theme.palette.border.divider.default};
                  width: auto;
                `,
                classes?.root,
              ),
              absolute: cx(
                css`
                  width: 100%;
                `,
                classes?.absolute,
              ),
              inset: cx(
                css`
                  margin-left: 72px;
                `,
                classes?.inset,
              ),
              middle: cx(
                orientation === 'horizontal' &&
                  css`
                    margin-left: ${theme.spacing(2)};
                    margin-right: ${theme.spacing(2)};
                  `,
                orientation === 'vertical' &&
                  css`
                    margin-top: ${theme.spacing(1)};
                    margin-bottom: ${theme.spacing(1)};
                  `,
                classes?.middle,
              ),
              withChildren: cx(
                orientation === 'horizontal' &&
                  css`
                    &::before,
                    &::after {
                      border-top: thin solid
                        ${theme.palette.border.divider.default};
                    }
                  `,
                orientation === 'horizontal' &&
                  textAlign === 'left' &&
                  css`
                    &::before {
                      width: 0;
                    }
                  `,
                orientation === 'horizontal' &&
                  textAlign === 'right' &&
                  css`
                    &::after {
                      width: 0;
                    }
                  `,
                classes?.withChildren,
              ),
              withChildrenVertical: cx(
                css`
                  &::before,
                  &::after {
                    border-left: thin solid
                      ${theme.palette.border.divider.default};
                  }
                `,
                classes?.withChildrenVertical,
              ),
              wrapper: cx(
                css`
                  padding-left: calc(${theme.spacing(1)} * 1.2);
                  padding-right: calc(${theme.spacing(1)} * 1.2);
                  /* TODO: [ALL-14042] Sync the Divider component style with the design guide */
                  /* padding-left: ${theme.spacing(1)};
                  padding-right: ${theme.spacing(1)}; */
                `,
                textAlign === 'left' &&
                  css`
                    padding-left: 0;
                  `,
                textAlign === 'right' &&
                  css`
                    padding-right: 0;
                  `,
                classes?.wrapper,
              ),
              wrapperVertical: cx(
                css`
                  padding-top: calc(${theme.spacing(1)} * 1.2);
                  padding-bottom: calc(${theme.spacing(1)} * 1.2);
                `,
                classes?.wrapperVertical,
              ),
            }}
          />
        )}
      </ClassNames>
    );
  },
);
