import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import { forwardRef } from 'react';
import SimpleBar from 'simplebar-react';
import { scrollerClasses } from './scroller-classes';
import { ScrollerProps, ScrollerRef } from './scroller-type-map';

export const Scroller = forwardRef<ScrollerRef, ScrollerProps>((props, ref) => {
  const { classes, ...other } = props;
  const theme = useTheme();

  return (
    <ClassNames>
      {({ css, cx }) => (
        <SimpleBar
          {...other}
          ref={ref}
          scrollableNodeProps={{
            ...other.scrollableNodeProps,
            className: cx(
              scrollerClasses.scrollableNode,
              classes?.scrollableNode,
              other.scrollableNodeProps?.className,
            ),
          }}
          className={cx(
            css`
              position: relative;
              flex-direction: column;
              flex-wrap: wrap;
              justify-content: flex-start;
              align-content: flex-start;
              align-items: flex-start;

              &.${scrollerClasses.dragging} {
                pointer-events: none;
                -webkit-touch-callout: none;
                user-select: none;
              }
            `,
            scrollerClasses.root,
            classes?.root,
            other.className,
          )}
          classNames={{
            ...classes,
            contentEl: cx(
              css`
                &:after,
                &:before {
                  content: ' ';
                  display: table;
                }

                .${scrollerClasses.root}.${scrollerClasses.dragging} & {
                  pointer-events: none;
                  -webkit-touch-callout: none;
                  user-select: none;
                }
              `,
              scrollerClasses.content,
              classes?.content,
            ),
            contentWrapper: cx(
              css`
                direction: inherit;
                box-sizing: border-box !important;
                position: relative;
                display: block;
                height: 100%;
                width: auto;
                max-width: 100%;
                max-height: 100%;
                overflow: auto;
                scrollbar-width: none;
                -ms-overflow-style: none;

                &::-webkit-scrollbar {
                  display: none;
                  width: 0;
                  height: 0;
                }
              `,
              scrollerClasses.contentWrapper,
              classes?.contentWrapper,
            ),
            offset: cx(
              css`
                direction: inherit !important;
                box-sizing: inherit !important;
                resize: none !important;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                padding: 0;
                margin: 0;
                -webkit-overflow-scrolling: touch;
              `,
              scrollerClasses.offset,
              classes?.offset,
            ),
            mask: cx(
              css`
                direction: inherit;
                position: absolute;
                overflow: hidden;
                padding: 0;
                margin: 0;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                width: auto !important;
                height: auto !important;
                z-index: 0;
              `,
              scrollerClasses.mask,
              classes?.mask,
            ),
            wrapper: cx(
              css`
                overflow: hidden;
                width: inherit;
                height: inherit;
                max-width: inherit;
                max-height: inherit;
              `,
              scrollerClasses.wrapper,
              classes?.wrapper,
            ),
            placeholder: cx(
              css`
                max-height: 100%;
                max-width: 100%;
                width: 100%;
                pointer-events: none;
              `,
              scrollerClasses.placeholder,
              classes?.placeholder,
            ),
            scrollbar: cx(
              css`
                position: absolute;
                left: 0;
                right: 0;
                min-height: 10px;

                &:before {
                  position: absolute;
                  content: '';
                  background: ${theme.palette.common.black};
                  border-radius: ${theme.radius.round}px;
                  top: 2px;
                  bottom: 2px;
                  left: 2px;
                  right: 2px;
                  opacity: 0;
                  transition: opacity 0.2s 0.5s linear;
                }

                &.${scrollerClasses.visible} {
                  &:before {
                    opacity: 0.24;
                    transition-delay: 0s;
                    transition-duration: 0s;
                  }

                  .${scrollerClasses.hover} &:before {
                    opacity: 0.51;
                  }
                }

                .${scrollerClasses.track}.${scrollerClasses.horizontal} & {
                  right: auto;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  min-height: 0;
                  min-width: 10px;
                  width: auto;
                }
              `,
              scrollerClasses.scrollbar,
              classes?.scrollbar,
            ),
            track: cx(
              css`
                z-index: 1;
                position: absolute;
                right: 0;
                bottom: 0;
                pointer-events: none;
                overflow: hidden;

                .${scrollerClasses.root}.${scrollerClasses.dragging} & {
                  pointer-events: all;
                }

                &.${scrollerClasses.vertical} {
                  top: 0;
                  width: 11px;

                  [data-simplebar-direction='rtl'] & {
                    right: auto;
                    left: 0;
                  }

                  ${theme.direction === 'rtl' &&
                  css`
                    right: auto;
                    left: 0;
                  `}
                }

                &.${scrollerClasses.horizontal} {
                  left: 0;
                  height: 11px;
                }
              `,
              scrollerClasses.track,
              classes?.track,
            ),
            heightAutoObserverWrapperEl: cx(
              css`
                box-sizing: inherit !important;
                height: 100%;
                width: 100%;
                max-width: 1px;
                position: relative;
                float: left;
                max-height: 1px;
                overflow: hidden;
                z-index: -1;
                padding: 0;
                margin: 0;
                pointer-events: none;
                flex-grow: inherit;
                flex-shrink: 0;
                flex-basis: 0;
              `,
              scrollerClasses.heightAutoObserverWrapper,
              classes?.heightAutoObserverWrapper,
            ),
            heightAutoObserverEl: cx(
              css`
                box-sizing: inherit;
                display: block;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                height: 1000%;
                width: 1000%;
                min-height: 1px;
                min-width: 1px;
                overflow: hidden;
                pointer-events: none;
                z-index: -1;
              `,
              scrollerClasses.heightAutoObserver,
              classes?.heightAutoObserver,
            ),
            visible: cx(scrollerClasses.visible, classes?.visible),
            horizontal: cx(scrollerClasses.horizontal, classes?.horizontal),
            vertical: cx(scrollerClasses.vertical, classes?.vertical),
            hover: cx(scrollerClasses.hover, classes?.hover),
            dragging: cx(scrollerClasses.dragging, classes?.dragging),
            scrolling: cx(scrollerClasses.scrolling, classes?.scrolling),
            scrollable: cx(scrollerClasses.scrollable, classes?.scrollable),
            mouseEntered: cx(
              scrollerClasses.mouseEntered,
              classes?.mouseEntered,
            ),
          }}
        />
      )}
    </ClassNames>
  );
});
