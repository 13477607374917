import { components as defaultComponents } from 'react-select';
import { ClearIndicator } from './clear-indicator';
import { SelectContainer } from './container';
import { Control } from './control';
import { DropdownIndicator } from './dropdown-indicator';
import { Group } from './group';
import { GroupHeading } from './group-heading';
import { IndicatorsContainer } from './indicators-container';
import { Input } from './input';
import { LoadingIndicator } from './loading-indicator';
import { LoadingMessage } from './loading-message';
import { Menu } from './menu';
import { MenuList } from './menu-list';
import { MenuPortal } from './menu-portal';
import { MultiValue } from './multi-value';
import { NoOptionsMessage } from './no-options-message';
import { Option } from './option';
import { Placeholder } from './placeholder';
import { SingleValue } from './single-value';
import { ValueContainer } from './value-container';

export const components = {
  ClearIndicator,
  Control,
  DropdownIndicator,
  Group,
  GroupHeading,
  IndicatorsContainer,
  Input,
  LoadingIndicator,
  Menu,
  MenuList,
  MenuPortal,
  LoadingMessage,
  NoOptionsMessage,
  MultiValue,
  MultiValueContainer: defaultComponents.MultiValueContainer,
  Option,
  Placeholder,
  SelectContainer,
  SingleValue,
  ValueContainer,
};
