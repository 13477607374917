/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { LLMAppFragment } from './llm-app-fragment';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ProjectQueryVariables = UserQueryTypes.Exact<{
  [key: string]: never;
}>;

export type ProjectQuery = { __typename: 'UserQuery' } & {
  project: UserQueryTypes.Maybe<
    { __typename: 'Project' } & Pick<
      UserQueryTypes.Project,
      | 'id'
      | 'enabledAddOns'
      | 'apiEndpoint'
      | 'apiKey'
      | 'hideAlliBranding'
      | 'useCautionMessage'
      | 'recommendedQuestions'
      | 'useCustomAssistantEntryLlmApp'
    > & {
        popularLlmApps: UserQueryTypes.Maybe<
          { __typename: 'LLMAppList' } & {
            llmApps: UserQueryTypes.Maybe<
              Array<
                UserQueryTypes.Maybe<{ __typename: 'LLMApp' } & LLMAppFragment>
              >
            >;
          }
        >;
        oktaOidcInfo: UserQueryTypes.Maybe<
          { __typename: 'OktaOIDCInfo' } & Pick<
            UserQueryTypes.OktaOIDCInfo,
            'clientId' | 'issuerUrl'
          >
        >;
        userEntraIdInfo: UserQueryTypes.Maybe<
          { __typename: 'UserEntraIDInfo' } & Pick<
            UserQueryTypes.UserEntraIDInfo,
            'loginUrl' | 'samlRequest'
          >
        >;
        appCollectionSettings: UserQueryTypes.Maybe<
          { __typename: 'AppCollectionSettings' } & Pick<
            UserQueryTypes.AppCollectionSettings,
            'companyName' | 'themeColor'
          > & {
              companyLogo: UserQueryTypes.Maybe<
                { __typename: 'Media' } & Pick<
                  UserQueryTypes.Media,
                  'id' | 'url'
                >
              >;
            }
        >;
      }
  >;
};

export const ProjectQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enabledAddOns' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'apiEndpoint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hideAlliBranding' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'useCautionMessage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'popularLlmApps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'llmApps' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'LLMAppFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommendedQuestions' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'useCustomAssistantEntryLlmApp',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oktaOidcInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clientId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'issuerUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userEntraIdInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loginUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'samlRequest' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appCollectionSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'themeColor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyLogo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LLMAppFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LLMApp' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'popupUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'singleActionApp' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectQuery, ProjectQueryVariables>;
