import { FunctionComponent, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { LocalizationProvider } from '@allganize/ui-date-picker';
import { LocaleContext, LocaleContextValue } from './locale-context';
import {
  Locale,
  dateFnsLocales,
  datePickerLocales,
  defaultLocale,
  locales,
} from './locales';

interface LocaleProviderProps {
  initialValue?: Locale;
  children?: React.ReactNode;
}

export const LocaleProvider: FunctionComponent<LocaleProviderProps> = ({
  initialValue,
  children,
}) => {
  const [locale, setLocale] = useState<Locale>(initialValue ?? defaultLocale);
  const contextValue = useMemo<LocaleContextValue>(
    () => ({ locale, setLocale }),
    [locale, setLocale],
  );

  return (
    <LocaleContext.Provider value={contextValue}>
      <IntlProvider
        locale={locale}
        defaultLocale={defaultLocale}
        messages={locales[locale]}
      >
        <LocalizationProvider
          adapterLocale={dateFnsLocales[locale]}
          localeText={datePickerLocales[locale]}
        >
          {children}
        </LocalizationProvider>
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
