import {
  listClasses as muiListClasses,
  ListClassKey as MuiListClassKey,
} from '@mui/material/List';
import { omit } from 'lodash-es';

export type ListClassKey = Exclude<MuiListClassKey, 'dense' | 'padding'>;
export type ListClasses = Record<ListClassKey, string>;

export const listClasses: ListClasses = omit(muiListClasses, [
  'dense',
  'padding',
]);
