import { useCombinedRef, useEventCallback, useUpdate } from '@allganize/hooks';
import { raf } from '@allganize/utils-timeout';
import { css } from '@emotion/react';
import { ScrollToOptions, useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { thumbnailHeight } from './constants';
import { DocumentViewerPageThumbnail } from './document-viewer-page-thumbnail';
import { documentViewerPageThumbnailListClasses } from './document-viewer-page-thumbnail-list-classes';
import { DocumentViewerPageThumbnailListProps } from './document-viewer-page-thumbnail-list-type-map';

const isVisible = (element: Element, options?: IntersectionObserverInit) => {
  return new Promise<boolean>(resolve => {
    const io = new IntersectionObserver(([entry]) => {
      io.disconnect();
      resolve(entry.isIntersecting);
    }, options);

    io.observe(element);
  });
};

export const DocumentViewerPageThumbnailList = forwardRef<
  HTMLDivElement,
  DocumentViewerPageThumbnailListProps
>((props, ref) => {
  const {
    actionsRef,
    classes,
    getScrollElement,
    onPageSelect,
    pages,
    selectedPage,
    ...other
  } = props;
  const rootRef = useRef<HTMLDivElement>(null);
  const combinedRootRef = useCombinedRef(rootRef, ref);
  const update = useUpdate();
  const rowVirtualizer = useVirtualizer({
    count: pages.length,
    getScrollElement,
    estimateSize: () => thumbnailHeight + 8,
    overscan: 10,
    paddingEnd: 4,
    paddingStart: 4,
  });

  const scrollToIndexIfNeeded = useEventCallback(
    async (index: number, options?: ScrollToOptions) => {
      const elem = rootRef.current?.querySelector(`[data-index="${index}"]`);

      if (elem) {
        const visible = await isVisible(elem, {
          root: getScrollElement(),
          threshold: [0],
        });

        if (visible) {
          return;
        }
      }

      rowVirtualizer.scrollToIndex(index, options);

      if (elem) {
        return;
      }

      await raf();
      await scrollToIndexIfNeeded(index, options);
    },
  );

  useImperativeHandle(
    actionsRef,
    () => ({
      forceUpdate: update,
      scrollToIndex(index, options) {
        rowVirtualizer.scrollToIndex(index, options);
      },
      scrollToIndexIfNeeded,
    }),
    [rowVirtualizer, scrollToIndexIfNeeded, update],
  );

  return (
    <div
      data-testid="document-viewer-page-thumbnail-list"
      css={css`
        position: relative;
        width: 100%;
      `}
      {...other}
      ref={combinedRootRef}
      className={clsx(
        documentViewerPageThumbnailListClasses.root,
        classes?.root,
        other.className,
      )}
      style={{
        height: rowVirtualizer.getTotalSize(),
        ...other.style,
      }}
    >
      {rowVirtualizer.getVirtualItems().map(virtualRow => {
        const page = pages[virtualRow.index];

        return (
          <div
            key={virtualRow.index}
            data-index={virtualRow.index}
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              padding: 4px 0;
              text-align: center;
            `}
            style={{
              height: virtualRow.size,
              transform: `translateY(${virtualRow.start}px)`,
            }}
            className={clsx(
              documentViewerPageThumbnailListClasses.thumbnail,
              classes?.thumbnail,
            )}
          >
            <DocumentViewerPageThumbnail
              index={virtualRow.index}
              onClick={() => onPageSelect?.(virtualRow.index)}
              page={page}
              selected={selectedPage === virtualRow.index}
            />
          </div>
        );
      })}
    </div>
  );
});
