import { ClassNames } from '@emotion/react';
import { DigitalClock as MuiDigitalClock } from '@mui/x-date-pickers/DigitalClock';
import { forwardRef } from 'react';
import { DIGITAL_CLOCK_VIEW_HEIGHT } from '../internals/constants';
import { DigitalClockItem } from './digital-clock-slots';
import { DigitalClockProps } from './digital-clock-type-map';

export const DigitalClock = forwardRef<HTMLDivElement, DigitalClockProps>(
  (props, ref) => {
    const { classes, slots, ...other } = props;

    return (
      <ClassNames>
        {({ css, cx }) => (
          <MuiDigitalClock
            data-testid="digital-clock"
            {...other}
            ref={ref}
            classes={{
              ...classes,
              root: cx(
                css`
                  max-height: ${DIGITAL_CLOCK_VIEW_HEIGHT}px;
                `,
                classes?.root,
              ),
            }}
            slots={{
              digitalClockItem: DigitalClockItem,
              ...slots,
            }}
          />
        )}
      </ClassNames>
    );
  },
);
