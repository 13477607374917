import { useStaticPicker as useMuiStaticPicker } from '@mui/x-date-pickers/internals/hooks/useStaticPicker/useStaticPicker';
import { DateOrTimeViewWithMeridiem } from '../../models';
import { PickerStaticLayout } from './use-static-picker.slots';
import {
  UseStaticPickerParams,
  UseStaticPickerProps,
} from './use-static-picker.types';

export const useStaticPicker = <
  TView extends DateOrTimeViewWithMeridiem,
  TExternalProps extends UseStaticPickerProps<TView, any, TExternalProps>,
>(
  params: UseStaticPickerParams<TView, TExternalProps>,
) => {
  const { props, ...pickerParams } = params;
  const { slots, ...other } = props;

  return useMuiStaticPicker<Date, TView, TExternalProps>({
    ...pickerParams,
    // @ts-expect-error internal component
    props: {
      ...other,
      slots: {
        layout: PickerStaticLayout,
        ...slots,
      },
    },
  });
};
