import { ListItemText } from '@allganize/ui-list';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { fileListItemTextClasses } from './file-list-item-text-classes';
import { FileListItemTextProps } from './file-list-item-text-type-map';

// custom typing for list item in order to make it generic
interface FileListItemTextType {
  <
    PrimaryTextComponent extends React.ElementType = 'p',
    SecondaryTextComponent extends React.ElementType = 'p',
  >(
    props: React.PropsWithoutRef<
      FileListItemTextProps<PrimaryTextComponent, SecondaryTextComponent>
    > &
      React.RefAttributes<HTMLDivElement>,
  ): React.ReactNode;
  readonly $$typeof: symbol;
  displayName?: string;
}

export const FileListItemText: FileListItemTextType = forwardRef(
  (props, ref) => {
    const {
      classes,
      inset = false,
      primaryTextProps,
      secondaryTextProps,
      ...other
    } = props;

    return (
      <ListItemText
        data-testid="file-list-item-text"
        css={[
          css`
            padding: 6px 0;
          `,
          inset === 'large' &&
            css`
              padding-left: 40px;
            `,
          inset === 'small' &&
            css`
              padding-left: 24px;
            `,
        ]}
        inset={inset}
        {...other}
        ref={ref}
        classes={{
          ...classes,
          root: clsx(fileListItemTextClasses.root, classes?.root),
          multiline: clsx(
            fileListItemTextClasses.multiline,
            classes?.multiline,
          ),
          primary: clsx(fileListItemTextClasses.primary, classes?.primary),
          secondary: clsx(
            fileListItemTextClasses.secondary,
            classes?.secondary,
          ),
          disabled: clsx(fileListItemTextClasses.disabled, classes?.disabled),
          insetSmall: clsx(
            fileListItemTextClasses.insetSmall,
            classes?.insetSmall,
          ),
          insetLarge: clsx(
            fileListItemTextClasses.insetLarge,
            classes?.insetLarge,
          ),
        }}
        primaryTextProps={{
          variant: 'title14',
          ...primaryTextProps,
        }}
      />
    );
  },
);
