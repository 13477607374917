import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import { forwardRef, useContext } from 'react';
import { ListItemButtonContext } from '../list-item-button';
import { ListItemIcon } from '../list-item-icon';
import { ListItemSecondaryTextProps } from './list-item-secondary-text-type-map';

export const ListItemSecondaryText = forwardRef<
  HTMLDivElement,
  ListItemSecondaryTextProps
>((props, ref) => {
  const { classes, disabled: disabledProp, ...other } = props;
  const theme = useTheme();
  const listItemButtonContext = useContext(ListItemButtonContext);
  const disabled = disabledProp ?? listItemButtonContext.disabled ?? false;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <ListItemIcon
          data-testid="list-item-secondary-text"
          disabled={disabled}
          position="end"
          {...other}
          ref={ref}
          classes={{
            ...classes,
            root: cx(
              css`
                ${theme.typography.body12}
                color: ${theme.palette.text.secondary};
              `,
              disabled &&
                css`
                  color: ${theme.palette.action.disabled};
                `,
              classes?.root,
            ),
            alignItemsFlexStart: cx(
              css`
                margin-top: 5px;
              `,
              classes?.alignItemsFlexStart,
            ),
          }}
        />
      )}
    </ClassNames>
  );
});
