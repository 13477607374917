import { Paper } from '@allganize/ui-paper';
import styled from '@emotion/styled';

export const Container = styled(Paper)`
  padding: 24px;

  @media only screen and (max-width: 480px) {
    padding: 24px 16px;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 900px;
`;
