import { Storage } from '@allganize/utils-storage';
import { EventEmitter } from 'eventemitter3';

interface AuthTokenManagerEvents {
  change(authToken: string | null): void;
}

export class AuthTokenManager extends EventEmitter<AuthTokenManagerEvents> {
  private token: string | null = null;

  constructor(private storage: Storage) {
    super();
  }

  public async getAuthToken() {
    return this.token;
  }

  public async setAuthToken(authToken: string | null) {
    if (this.token === authToken) {
      return;
    }

    this.token = authToken;

    if (authToken === null) {
      await this.storage.removeItem('alli-user-token');
      this.emit('change', authToken);
      return;
    }

    await this.storage.setItem('alli-user-token', authToken);
    this.emit('change', authToken);
  }

  public async load() {
    const token = (await this.storage.getItem('alli-user-token')) ?? null;

    if (this.token === token) {
      return;
    }

    this.token = token;
    this.emit('change', this.token);
  }
}
