import {
  DraftVideoBase,
  DraftVideoPlugin,
} from '@allganize/draft-video-plugin';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { ReactMarkdownProps } from 'react-markdown/lib/ast-to-react';
import { markdownClasses } from '../markdown/markdown-classes';

export const Video = forwardRef<
  HTMLVideoElement,
  React.VideoHTMLAttributes<HTMLVideoElement> & ReactMarkdownProps
>((props, ref) => {
  const {
    node,
    sourcePosition,
    index,
    siblingCount,
    src: srcProp,
    ...other
  } = props;
  const src = DraftVideoPlugin.getSrc(srcProp || '');

  if (src) {
    return <DraftVideoBase src={src} title={other.title} />;
  }

  return (
    <video
      src={srcProp}
      {...other}
      ref={ref}
      className={clsx(markdownClasses.video, other.className)}
    />
  );
});
