import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type KnowledgeBaseSearchDialogClassKey =
  | 'root'
  | 'title'
  | 'content'
  | 'searchInput'
  | 'chipList'
  | 'hashtagList'
  | 'selectableList'
  | 'actions';

export type KnowledgeBaseSearchDialogClasses = Record<
  KnowledgeBaseSearchDialogClassKey,
  string
>;

export const knowledgeBaseSearchDialogClasses: KnowledgeBaseSearchDialogClasses =
  generateUtilityClasses('KnowledgeBaseSearchDialog', [
    'root',
    'title',
    'content',
    'searchInput',
    'chipList',
    'hashtagList',
    'selectableList',
    'actions',
  ]);
