import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { documentViewerHeaderHeight } from './constants';
import { documentViewerHeaderSpacerClasses } from './document-viewer-header-spacer-classes';
import { DocumentViewerHeaderSpacerProps } from './document-viewer-header-spacer-type-map';

export const DocumentViewerHeaderSpacer = forwardRef<
  HTMLDivElement,
  DocumentViewerHeaderSpacerProps
>((props, ref) => {
  const { classes, ...other } = props;

  return (
    <div
      data-testid="document-viewer-header-spacer"
      css={css`
        height: ${documentViewerHeaderHeight}px;
      `}
      {...other}
      ref={ref}
      className={clsx(
        documentViewerHeaderSpacerClasses.root,
        classes?.root,
        other.className,
      )}
    />
  );
});
