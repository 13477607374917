import { CSSObject } from '@emotion/react';
import {
  FontStyle,
  TypographyUtils,
} from '@mui/material/styles/createTypography';

const round = (value: number) => Math.round(value * 1e5) / 1e5;

export type Variant =
  | 'title24'
  | 'title20'
  | 'title16'
  | 'title14'
  | 'title12'
  | 'subtitle16'
  | 'subtitle14'
  | 'subtitle12'
  | 'body16'
  | 'body14'
  | 'body12'
  | 'label14'
  | 'label12';
export type TypographyStyle = CSSObject;
export interface TypographyStyleOptions extends TypographyStyle {}

export interface Typography
  extends Record<Variant, TypographyStyle>,
    FontStyle,
    TypographyUtils {
  fontFamilyMono: string;
}

export interface TypographyOptions extends Partial<TypographyUtils> {}

const fontFamily =
  '"Noto Sans JP Variable", "Noto Sans JP", "Noto Sans KR", -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif';
const fontFamilyMono = "'Roboto Mono Variable', 'Roboto Mono', monospace";
const fontSize = 14;
const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightBold = 700;
const htmlFontSize = 16;
const coef = fontSize / 14;

export const createTypography = (typography: TypographyOptions): Typography => {
  const { pxToRem: pxToRem2 } = typography;

  const pxToRem =
    pxToRem2 || ((size: number) => `${(size / htmlFontSize) * coef}rem`);

  const buildVariant = (
    fontWeight: TypographyStyle['fontWeight'],
    size: number,
    lineHeight: TypographyStyle['lineHeight'],
    letterSpacing: number,
  ): TypographyStyle => ({
    fontFamily,
    fontWeight,
    fontSize: pxToRem(size),
    lineHeight,
    letterSpacing: `${round(letterSpacing / size)}em`,
  });

  const variants = {
    title24: buildVariant(fontWeightMedium, 24, 32 / 24, -0.2),
    title20: buildVariant(fontWeightMedium, 20, 28 / 20, -0.2),
    title16: buildVariant(fontWeightBold, 16, 24 / 16, -0.2),
    title14: buildVariant(fontWeightBold, 14, 20 / 14, 0),
    title12: buildVariant(fontWeightBold, 12, 18 / 12, 0),
    subtitle16: buildVariant(fontWeightMedium, 16, 24 / 16, -0.2),
    subtitle14: buildVariant(fontWeightMedium, 14, 20 / 14, 0),
    subtitle12: buildVariant(fontWeightMedium, 12, 18 / 12, 0),
    body16: buildVariant(fontWeightRegular, 16, 24 / 16, -0.2),
    body14: buildVariant(fontWeightRegular, 14, 20 / 14, 0),
    body12: buildVariant(fontWeightMedium, 12, 18 / 12, 0),
    label14: buildVariant(fontWeightMedium, 14, 20 / 14, 0),
    label12: buildVariant(fontWeightMedium, 12, 18 / 12, 0),
    inherit: {
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      letterSpacing: 'inherit',
    },
  };

  return {
    htmlFontSize,
    pxToRem,
    fontFamily,
    fontFamilyMono,
    fontSize,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    ...variants,
  };
};
