import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { ReactMarkdownProps } from 'react-markdown/lib/ast-to-react';
import { markdownClasses } from '../markdown/markdown-classes';

export const Mark = forwardRef<
  HTMLElement,
  React.HTMLAttributes<HTMLElement> & ReactMarkdownProps
>((props, ref) => {
  const theme = useTheme();
  const { node, sourcePosition, index, siblingCount, ...other } = props;

  return (
    <mark
      css={css`
        color: inherit;
        background-color: ${theme.palette.background.textHighlight};
      `}
      {...other}
      ref={ref}
      className={clsx(markdownClasses.mark, other.className)}
    />
  );
});
