import EventEmitter from 'eventemitter3';

interface ApiKeyManagerEvents {
  change(apiKey: string | null): void;
}

export class ApiKeyManager extends EventEmitter<ApiKeyManagerEvents> {
  private apiKey: string | null = null;

  public getApiKey() {
    return this.apiKey;
  }

  public setApiKey(apiKey: string | null) {
    if (this.apiKey === apiKey) {
      return;
    }

    this.emit('change', apiKey);
    this.apiKey = apiKey;
  }
}
