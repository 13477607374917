import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type DocumentViewerSidebarSpacerClassKey = 'root';
export type DocumentViewerSidebarSpacerClasses = Record<
  DocumentViewerSidebarSpacerClassKey,
  string
>;

export const documentViewerSidebarSpacerClasses: DocumentViewerSidebarSpacerClasses =
  generateUtilityClasses('DocumentViewerSidebarSpacer', ['root']);
